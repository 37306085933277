import { useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { getAccessToken, getUserID } from 'core/services';
import { useTypedSelector } from 'core/hooks';
import { setRedirect } from 'core/store/actions';
import { signIn } from 'store/actions';
import { emailVerify, getProductId, sendTransactionId } from 'store/actions/api';
import { isAuthorizedSelector } from 'store/selectors';
import { useAppDispatch } from 'hooks';
import { Spinner } from 'components';
import { AccountLayout } from './AccountLayout';
import * as Paths from 'constantsLk/paths';
import { USER_STATE } from 'constantsLk';
import { PATH_ACCOUNT, PATH_ACTIVE_LOAN } from 'constantsLk/paths';

export const AccountLayoutContainer = () => {
  const isAuthorized = useTypedSelector(isAuthorizedSelector);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const isPossibleLogin = !isAuthorized && getAccessToken() && getUserID();
  const isLoanPage = location.pathname.includes(PATH_ACTIVE_LOAN);
  const search = location.search;
  const path = location.pathname.split('/').pop();

  const [searchParams] = useSearchParams();
  const token = searchParams.get('token');
  const userId = searchParams.get('user_id');
  const trId = searchParams.get('trId');
  const merchantRID = searchParams.get('merchantRID');

  useEffect(() => {
    if (token && userId)
      dispatch(emailVerify({ token, userId })).finally(() => {
        searchParams.delete('token');
        searchParams.delete('user_id');
        navigate(`${location.pathname}`, { replace: true });
      });
    if (trId && merchantRID)
      dispatch(
        sendTransactionId({ transaction_id: trId, merchant_reference_id: merchantRID }),
      ).finally(() => {
        searchParams.delete('trId');
        searchParams.delete('merchantRID');
        navigate(`${location.pathname}`, { replace: true });
      });
  }, []);

  useEffect(() => {
    if (isPossibleLogin) {
      dispatch(signIn())
        .unwrap()
        .then((res) => {
          if (res.state === USER_STATE.ACTIVE) {
            if (isLoanPage || path === PATH_ACCOUNT) {
              dispatch(
                setRedirect({ to: `${Paths.URL_ACTIVE_LOAN}${search && !token ? search : ''}` }),
              );
            }
          } else {
            dispatch(setRedirect({ to: Paths.URL_USER_REGISTRATION }));
          }
        })
        .catch(() => {
          dispatch(setRedirect({ to: `/${Paths.URL_PATH_LOGIN}` }));
        });
    }
  }, [isPossibleLogin]);

  useEffect(() => {
    dispatch(getProductId());
  }, []);

  return isPossibleLogin ? <Spinner /> : <AccountLayout />;
};
