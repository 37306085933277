import { EmailFormModel, IbanNumberFields } from '../models';

export const prepareEmail = (values: EmailFormModel): string | undefined => {
  if (!values[IbanNumberFields.Email]?.trim()) return undefined;

  const email = values[IbanNumberFields.Email]?.replaceAll(' ', '').toLowerCase();
  return values[IbanNumberFields.EmailDomain] === 'other'
    ? email
    : email + values[IbanNumberFields.EmailDomain];
};
