import { useTranslation } from 'react-i18next';
import { ReferralInfo } from 'models';
import { TooltipItem } from '../Tooltip';
import { CURRENCY } from 'constantsLk';

interface AchievementsProps {
  referralInfo: ReferralInfo;
}

export const Achievements = (props: AchievementsProps) => {
  const { bonus_amount, referrals_completed_count, referrals_count } = props.referralInfo;

  const { t } = useTranslation();

  return (
    <div className='mt-6'>
      <p className='txt-md-16 txt-sb mb-2'>{t`your_achievements`}</p>
      <div className='achievements flex-nowrap gap-2'>
        <div className='achievements-item card-bg flex-column justify-content-between gap-1'>
          <div className='flex-nowrap align-items-start'>
            <span className='mr-1'>{t`available_bonuses`}</span>
            <TooltipItem title={t`available_bonuses`} content={t`available_bonuses_text`} />
          </div>
          <p className='txt txt-md txt-b'>
            {bonus_amount} {CURRENCY}
          </p>
        </div>

        <div className='flex-nowrap w-100 gap-2'>
          <div className='achievements-item card-bg flex-column justify-content-between gap-1'>
            <div className='flex-nowrap align-items-start'>
              <span className='mr-1'>{t`registered_by_invitation`}</span>
              <TooltipItem
                title={t`registered_by_invitation`}
                content={t`registered_by_invitation_text`}
              />
            </div>
            <p className='txt txt-md txt-b'>{referrals_count}</p>
          </div>

          <div className='achievements-item card-bg flex-column justify-content-between gap-1'>
            <div className='flex-nowrap align-items-start'>
              <span className='mr-1'>{t`completed`}</span>
              <TooltipItem title={t`completed`} content={t`completed_text`} />
            </div>
            <p className='txt txt-md txt-b'>{referrals_completed_count}</p>
          </div>
        </div>
      </div>
    </div>
  );
};
