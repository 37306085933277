import { useTypedSelector } from 'core/hooks';
import { NICErrorModal } from './NICErrorModal';
import { userSelector, wpContentHeaderSelector } from 'store/selectors';
import { logOutUtil } from 'utils';
import { MatchedModalsContainer } from './MatchedModals/MatchedModalsContainer';
import { WHATSAPP_PHONE } from 'constantsLk';

export const NICErrorModalContainer = () => {
  const wpContentHeader = useTypedSelector(wpContentHeaderSelector);
  const user = useTypedSelector(userSelector);

  const isMatchedUserWithoutParams =
    !user?.matched_user_has_overdue_loan &&
    !user?.matched_user_has_sold_loan &&
    !user?.matched_user_in_black_list &&
    !user?.matched_user_has_moratorium;

  const handleLogout = () => {
    logOutUtil();
    window.location.reload();
  };

  return !isMatchedUserWithoutParams ? (
    <NICErrorModal
      onLogout={handleLogout}
      userPhone={user?.matched_user_hidden_phone}
      supportPhone={wpContentHeader?.contacts.phone}
      hasOverdueLoan={user?.matched_user_has_overdue_loan}
      hasSoldLoan={user?.matched_user_has_sold_loan}
      inBlackList={user?.matched_user_in_black_list}
      hasMoratorium={user?.matched_user_has_moratorium}
    />
  ) : (
    <MatchedModalsContainer
      onLogout={handleLogout}
      userPhone={user?.matched_user_hidden_phone}
      supportPhone={WHATSAPP_PHONE}
    />
  );
};
