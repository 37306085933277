import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';

import {
  CheckBoxFieldHookForm,
  SelectFieldHookForm,
  TextFieldHookForm,
} from 'core/components/fields';
import { Button } from 'core/components/common';
import { SubmitCoreHandler } from 'core/models';
import { maxLength } from 'core/utils/parsers';
import { useTypedSelector } from 'core/hooks';

import { useHookFormGetCache, useHookFormSetCache } from 'hooks';
import { IbanNumberFields, IbanNumberFormModel } from 'models';
import {
  EMAIL_SYMBOLS_REGEX,
  validateAccountNumber,
  validateCheckBox,
  validateEmail,
  validateEmailWithoutDomain,
  validateSelect,
} from 'utils/validates/validation';
import { onlyNumbers } from 'utils/parsers';
import { Title } from 'components';
import { onSubmitEventTagHandler } from 'utils/analitics';
import { sortOptions } from 'utils/sortOptions';
import { GTM_LABEL_SIGN_UP } from 'utils/analitics/gtmLabel';
import { userSelector } from 'store/selectors';
import * as Paths from 'constantsLk/paths';
import {
  SELECT_LIST_BANK_BRANCHES,
  SELECT_LIST_BANK_NAME,
  SELECT_LIST_IMPORTANT_BANK_NAME,
} from 'constantsLk';
import { emailValues } from 'constantsLk/SelectValues/emailValues';

export interface Step7BankAccDetails {
  onSubmit: SubmitCoreHandler<IbanNumberFormModel>;
}

export const Step7BankAccDetails = (props: Step7BankAccDetails) => {
  const { onSubmit } = props;
  const { t } = useTranslation();
  const user = useTypedSelector(userSelector);

  const defaultValues = useHookFormGetCache(Paths.PATH_REG_STEP_7);

  const {
    handleSubmit,
    watch,
    setValue,
    control,
    setError,
    clearErrors,
    formState: { isSubmitting },
  } = useForm<IbanNumberFormModel>({
    mode: 'onBlur',
    defaultValues: {
      ...defaultValues,
      [IbanNumberFields.Email]: user?.email ? user?.email : null,
      [IbanNumberFields.EmailDomain]: user?.email
        ? emailValues[2].value
        : defaultValues[IbanNumberFields.EmailDomain]
          ? defaultValues[IbanNumberFields.EmailDomain]
          : emailValues[0].value,
    },
  });

  const bank = watch(IbanNumberFields.BankName);
  const emailDomain = watch(IbanNumberFields.EmailDomain);

  const isNotOther = emailDomain !== 'other';

  const handleSelectChange = () => {
    setValue(IbanNumberFields.BankBranchName, null);
  };

  const handleChangeEmail = (name: any, value: any) => {
    const val = value.trim();

    if (val) {
      if (val.includes('@')) {
        setValue(IbanNumberFields.EmailDomain, 'other');
      }

      const isValidVal = EMAIL_SYMBOLS_REGEX.test(val);

      if (!isValidVal) {
        setError(name, { type: 'validate', message: t`email_err_not_en` });
      } else {
        clearErrors(name);
      }
    } else if (!val && !isNotOther) {
      setValue(IbanNumberFields.EmailDomain, emailValues[0].value);
    }
  };

  useHookFormSetCache(Paths.PATH_REG_STEP_7, watch);

  const onSubmitHandler = handleSubmit(
    (data) =>
      onSubmit?.(data, {
        setError,
      }),
    (errors) => onSubmitEventTagHandler(errors, GTM_LABEL_SIGN_UP),
  );
  return (
    <>
      <div className='flex-column gap-2 mb-4'>
        <Title classes={'form-title mb-0'} name={'bank_account_details'} />
        <p className='txt-r'>{t`indicate_account_to_send_money`}</p>
      </div>
      <form onSubmit={onSubmitHandler}>
        <SelectFieldHookForm
          control={control}
          label={t`bank_name`}
          name={IbanNumberFields.BankName}
          options={[...SELECT_LIST_IMPORTANT_BANK_NAME, ...sortOptions(SELECT_LIST_BANK_NAME)]}
          rules={validateSelect}
          onChange={handleSelectChange}
          withImportantBanks={!!SELECT_LIST_IMPORTANT_BANK_NAME.length}
        />

        {bank && (
          <SelectFieldHookForm
            control={control}
            label={t`bank_branch_name`}
            name={IbanNumberFields.BankBranchName}
            options={SELECT_LIST_BANK_BRANCHES[bank]}
            rules={validateSelect}
          />
        )}

        <TextFieldHookForm
          control={control}
          name={IbanNumberFields.Iban}
          label={t`account_number`}
          parsers={[onlyNumbers, maxLength(20)]}
          rules={validateAccountNumber}
          inputMode='numeric'
        />

        <div>
          <div className={`w-100 ${isNotOther && 'form-group email'}`}>
            <TextFieldHookForm
              control={control}
              name={IbanNumberFields.Email}
              label={t`email`}
              parsers={[maxLength(100)]}
              rules={isNotOther ? validateEmailWithoutDomain : validateEmail}
              onChange={handleChangeEmail}
            />

            {isNotOther && (
              <SelectFieldHookForm
                control={control}
                name={IbanNumberFields.EmailDomain}
                options={emailValues}
                rules={validateSelect}
              />
            )}
          </div>
          {/*<span className='flex txt-secondary txt-xs txt-sb mt-2'>{t`send_loan_agreement`}</span>*/}
        </div>

        <CheckBoxFieldHookForm
          control={control}
          name={IbanNumberFields.Confirm}
          id={IbanNumberFields.Confirm}
          rules={validateCheckBox}
          label={t`i_undertake_accuracy`}
          defaultValue={'true'}
        />

        <Button
          disabled={isSubmitting}
          classes={{ root: 'btn btn--primary mt-1 next-step' }}
        >{t`continue`}</Button>
      </form>
    </>
  );
};
