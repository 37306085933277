import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useForm, UseFormSetValue } from 'react-hook-form';
import { useSearchParams } from 'react-router-dom';

import { getAccessToken, getCookie, getRefreshToken, getUserID } from 'core/services';
import { SubmitCoreHandler, WpKeys } from 'core/models';
import { useTypedSelector } from 'core/hooks';
import { Button } from 'core/components/common';
import { CommonServerErrorMessage } from 'components/CommonServerErrorMessage';
import {
  CameraAccessDeniedFAQ,
  IPhotoDocuments,
  IPhotoUploadInitStateProps,
  UploadedPhoto,
} from 'components/DocumentUploader';
import { useAppDispatch } from 'hooks';
import { CameraAccessDeniedFAQModalContainer } from 'components/modals';
import { DocumentsType } from 'models';
import { sendLivenessLog } from 'store/actions/api';
import { LivenessEngines } from './LivenessEngines/LivenessEngines';
import { isFacebookWebview, isWebView } from 'utils';
import { livenessEngineSelector, userErrorCameraSelector } from 'store/selectors';
import { SelfieImg } from '../icons';

const defaultUploadFileState: IPhotoUploadInitStateProps = {
  state: '',
  file: null,
  file_type: undefined,
  error: '',
};

export interface IUploadPhotoSelfieComponentFormProps {
  [DocumentsType.Selfie]: any;
  livenessCheckId?: number;
}

export interface IUploadPhotoSelfie {
  onSubmit?: SubmitCoreHandler<IUploadPhotoSelfieComponentFormProps>;
}

export interface IUploadPhotoSelfieComponentProps extends IUploadPhotoSelfie {
  control?: any;
  setValue?: UseFormSetValue<IUploadPhotoSelfieComponentFormProps>;
  loadedFileType?: DocumentsType;
  sendFile: ({ file, type }: { file: File; type: DocumentsType }) => Promise<any>;
  documentFile: IPhotoDocuments;
  selfieLoadError: any;
}

export const UploadPhotoSelfieComponent: FC<IUploadPhotoSelfieComponentProps> = ({
  onSubmit,
  loadedFileType,
  documentFile,
  selfieLoadError,
}) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const livenessEngine = useTypedSelector(livenessEngineSelector);

  const initialFileUploadState = {
    ...defaultUploadFileState,
    file_type: loadedFileType,
  };
  const [isShowCameraAccessDialog, setIsShowCameraAccessDialog] = useState<boolean>(false);
  const [isShowSelfieInstructionsDialog, setIsShowSelfieInstructionsDialog] =
    useState<boolean>(false);
  const [stateComponent, setStateComponent] =
    useState<IPhotoUploadInitStateProps>(initialFileUploadState);
  const [isAccessDeniedAfterRequest, setIsAccessDeniedAfterRequest] = useState(false);
  const [errors, setErrors] = useState<string[]>([]);
  const [isLoadingSDK, setIsLoadingSDK] = useState(false);

  const isErrorCamera = useTypedSelector(userErrorCameraSelector);

  const isDisabled = stateComponent.state === 'done' || stateComponent.state === 'load';

  const handleSelfieInstructionsDialogOpen = () => {
    setIsShowSelfieInstructionsDialog(true);
    livenessEngine && sendLog({ event: 'Click to Take a photo button (1)' }, livenessEngine);
  };
  const handleSelfieInstructionsDialogClose = () => setIsShowSelfieInstructionsDialog(false);
  const handleAccessDeniedAfterRequest = () => setIsAccessDeniedAfterRequest(true);

  const {
    handleSubmit,
    setError,
    setValue,
    formState: { isSubmitting },
  } = useForm<IUploadPhotoSelfieComponentFormProps>({
    mode: 'onSubmit',
    shouldUnregister: true,
  });

  useEffect(() => {
    if (loadedFileType && stateComponent.state !== 'load') {
      setStateComponent({
        ...stateComponent,
        state: 'load',
      });
    }
  }, [loadedFileType]);

  useEffect(() => {
    if (selfieLoadError) {
      setStateComponent({ ...defaultUploadFileState, error: 'liveness_loading_error' });
      livenessEngine &&
        sendLog({ error: `Selfie load error: ${JSON.stringify(selfieLoadError)}` }, livenessEngine);
    }
  }, [selfieLoadError]);

  const onSubmitHandler = (livenessCheckId?: number) => {
    return handleSubmit((data) => {
      setStateComponent({ ...stateComponent, state: 'load', error: '' });
      return onSubmit?.({ ...data, livenessCheckId }, { setError });
    });
  };

  const sendLog = (context: any, engine: string) => {
    const data = {
      user: getUserID(),
      context: JSON.stringify(context),
      engine,
    };
    return dispatch(sendLivenessLog(data)).unwrap();
  };

  const handleShowIfAccessDeniedDialog = () => setIsShowCameraAccessDialog(true);

  const handleCloseIfAccessDeniedDialog = () => setIsShowCameraAccessDialog(false);

  const setErrorsArray = (errors: string[]) => setErrors(errors);

  const handleReloadPage = () => {
    window.location.reload();
    livenessEngine && sendLog({ event: 'Reload the page' }, livenessEngine);
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [searchParams, setSearchParams] = useSearchParams();

  const getImpersonateParams = () => {
    return {
      user: { id: getUserID() },
      token: getAccessToken(),
      refresh_token: getRefreshToken(),
    };
  };

  useEffect(() => {
    if (isFacebookWebview) {
      const params: any = {};
      const impersonateData = JSON.stringify(getImpersonateParams());
      const targetUrl = getCookie(WpKeys.TargetUrl);
      params.impersonate = impersonateData;
      if (targetUrl) {
        params.target_url = targetUrl;
      }
      setSearchParams(params);
    }
  }, []);

  return (
    <>
      <div className='card-secondary flex-column w-100 pb-0'>
        <span>{t`photo_example`}</span>
        <div className={`flex-center ${isLoadingSDK && 'pointer-events-none'}`}>
          <UploadedPhoto
            src={<SelfieImg />}
            fileState={stateComponent.state}
            instructionHandler={handleSelfieInstructionsDialogOpen}
          />
        </div>
      </div>

      {stateComponent.error && <CommonServerErrorMessage message={stateComponent.error} />}

      <div className='flex-column gap-4 mt-1'>
        <Button
          onClick={handleSelfieInstructionsDialogOpen}
          classes={{ root: 'btn--primary mt-0 w-100' }}
          disabled={isDisabled || isSubmitting || isLoadingSDK}
        >
          {t(isLoadingSDK ? `liveness_loading` : `selfie_card_upload_title_web`)}
        </Button>

        {isAccessDeniedAfterRequest && !isWebView() && (
          <div className='flex-column align-items-center'>
            <CommonServerErrorMessage message={'please_reload_page'} />
            <span className='mt-2 link' onClick={handleReloadPage}>{t`reload_page`}</span>
          </div>
        )}

        {errors.length ? (
          <div className='flex-column gap-1'>
            {errors.map((e) => (
              <span key={e} className='server-error-message'>
                - {t(e)}
              </span>
            ))}
          </div>
        ) : null}

        <CameraAccessDeniedFAQ handleShowIfAccessDeniedDialog={handleShowIfAccessDeniedDialog} />

        {(isShowCameraAccessDialog || isErrorCamera) && (
          <CameraAccessDeniedFAQModalContainer onClose={handleCloseIfAccessDeniedDialog} />
        )}
      </div>

      <LivenessEngines
        isShowSelfieInstructionsDialog={isShowSelfieInstructionsDialog}
        onClose={handleSelfieInstructionsDialogClose}
        defaultUploadFileState={defaultUploadFileState}
        documentFile={documentFile}
        sendLog={sendLog}
        setStateComponent={setStateComponent}
        setValue={setValue}
        handleShowIfAccessDeniedDialog={handleShowIfAccessDeniedDialog}
        onSubmitHandler={onSubmitHandler}
        handleAccessDeniedAfterRequest={handleAccessDeniedAfterRequest}
        setErrorsArray={setErrorsArray}
        setIsLoadingSDK={setIsLoadingSDK}
      />
    </>
  );
};
