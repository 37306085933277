export enum HasExternalLoans {
  Yes = 'yes',
  No = 'no',
}

export enum PersonalIdType {
  Old = 'old',
  New = 'new',
}

export enum Gender {
  Male = 'male',
  Female = 'female',
}

export enum DocumentsType {
  Any = 'any',
  IDCard = 'id_card',
  IDCardBackSide = 'id_card_back_side',
  Selfie = 'selfie',
}

export enum TabType {
  Promo = 'promo',
  Bonuses = 'bonuses',
}

export type IDocumentsType = Record<DocumentsType, string>;

export interface UploadDocument {
  title: string;
  description: string;
  cameraTitle: string;
  cameraDescription: string;
  image: string;
  type: DocumentType;
}

export enum UserIssueMethod {
  Iban = 1,
}

export interface ITimerValue {
  fullString: string;
  hoursString: string;
  secondString: string;
  minutesString: string;
  secondsNumber: number;
}

export interface LangProps {
  label: string;
  value: string;
}
