export enum ShortLinkLoanType {
  Extension = 'extension',
  PersonalAccount = 'personal_account',
}

export interface Loan {
  id: number;
  loan_id?: number;
  user_id: number;
  user_first_name: string;
  user_last_name: string;
  user_phone_number: string;
  user_bank: string;
  user_branch_name: string;
  user_account_number: string;
  bank_name: string;
  branch_name: string;
  account_number: string;
  user_personal_id_number: string;
  application_id: number;
  product_id: number;
  product_name: string;
  state: string;
  principal: number;
  tenor: number;
  created_at: string;
  disbursed_at: string;
  matured_at: string;
  closed_at: null | string;
  extension_request_allowed_from: string;
  accrued_principal: number;
  accrued_consulting_fee: number;
  accrued_consulting_fee_add: number;
  accrued_service_fee: number;
  accrued_service_fee_add: number;
  accrued_late_payment_fee: number;
  accrued_total: number;
  accrued_transaction_fee: number;
  days_past_due: number;
  is_extension_request_allowed: boolean;
  is_extension_possible: any;
  is_extension_requested: boolean;
  is_extended: boolean;
  extension_requested_at: any;
  number_of_extensions: number;
  is_repeat: boolean;
  income_amount_for_extension: number;
  promo_code: null | string;
  discount_details: any[];
  discount_amount: number;
  request_number: number;
  has_saving_book_image: boolean;
  is_ready_for_disbursement: boolean;
  last_payment_date: any | string;
  last_paid_amount: any | number;
  allowed_extension_tenors: number[];
  last_money_transfer_result_status_code: null | any;
  max_dpd: number | null;
  allowed_extension_tenors_price: { [key: string]: number };
  extension_activation_message: null | string;
  extension_activation_status: null | string;
  user_contact_name: string;
  user_contact_relationship: string;
  user_contact_phone_number: string;
  payment_link_uri: string;
  payment_link_provider: 'pay_and_go' | 'marx';
  payment_link_for_extension_uris: { [key: string]: string };
  assigned_collector: null | string;
  collection_score_priority: null | string;
  loan_personal_account_short_link_uri: string;
  extension_short_link_uri: string;
  type?: ShortLinkLoanType;
  is_closed_sold: boolean;
  last_extension_date: string | null;
  loan_index: number;
  active_promotions: PromotionModel[];
  loan?: {
    loan_sale_info?: {
      created_at: string;
      portfolio_buyer: {
        company_name: string;
        email: string;
        phone_number: string;
        bank_account_holder_name: string;
        bank_account_number: string;
        bank_branch: string;
      };
      sold_at: string;
    };
  };
  top_up_info: TopUpModel;
}

export interface TopUpModel {
  id: number;
  state: string;
  enabled_transitions: string[];
  min_amount: number;
  max_amount: number;
  applied_amount: number | null;
  applied_at: Date | null;
  start_at: Date;
  expires_at: Date;
}

export interface PromotionModel {
  agreed_payment_amount: number;
  agreement_template_key: string | null;
  available_activation_by_user: boolean;
  available_activation_from_loan_card: boolean;
  available_from: string | null;
  available_to: Date;
  calculation: {
    items: PromotionCalculationItem[];
    total_actual_debt: number;
    total_current_discount_amount: number;
    total_current_discount_percent: number;
    total_debt_after_discount: number;
    total_max_discount_amount: number;
    total_max_discount_percent: number;
  };
  customer_description: string;
  discounts?: any;
  email_template_key: string;
  header: string;
  id: number;
  loan_id: number;
  name: string;
  recipients: any;
  sms_template_key: string;
  state: string;
  type: string;
  translations: PromotionTranslations[];
}

export interface PromotionTranslations {
  locale: string;
  name: string;
  header: string;
  internal_description: string;
  customer_description: string;
}

export interface PromotionCalculationItem {
  actual_debt: number;
  current_discount_amount: number;
  current_discount_percent: number;
  debt_after_discount: number;
  max_discount_amount: number;
  max_discount_percent: number;
  transaction_type: string;
}

export interface Income {
  id: number;
  user_id: number;
  loan_id: number;
  gateway: string;
  state: string;
  amount: number;
  received_at: string;
  processed_at: string;
  bank_name: string;
  payment_type: string;
  enabled_transitions: [];
}

export interface SortedIncomesProps {
  [key: string]: Income[] | null;
}

export interface ServerLoanSettings {
  default_principal: number | null;
  default_tenor: number | null;
}
