import React from 'react';
import { useForm } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import { CheckBoxFieldHookForm } from 'core/components/fields';
import { Button, ModalLayout } from 'core/components/common';
import { SubmitCoreHandler } from 'core/models';
import { validateCheckBox } from 'utils/validates/validation';

export enum PersonalDataProcessingDialogModalFormFields {
  'IsDataProcessingConsentGiven' = 'is_data_processing_consent_given',
}

export interface PersonalDataProcessingDialogModalForm {
  [PersonalDataProcessingDialogModalFormFields.IsDataProcessingConsentGiven]: boolean;
}

export interface PersonalDataProcessingDialogModalSubmitProps {
  onSubmit: SubmitCoreHandler<PersonalDataProcessingDialogModalForm>;
}

export interface PersonalDataProcessingDialogModalProps
  extends PersonalDataProcessingDialogModalSubmitProps {
  term_conditions?: string;
}

export const PersonalDataProcessingDialogModal = (
  props: PersonalDataProcessingDialogModalProps,
) => {
  const { onSubmit, term_conditions } = props;
  const { t } = useTranslation();

  const {
    control,
    formState: { isSubmitting },
    handleSubmit,
    setError,
  } = useForm<PersonalDataProcessingDialogModalForm>({
    mode: 'onBlur',
    shouldFocusError: true,
    shouldUnregister: true,
    defaultValues: {},
  });

  const onSubmitHandler = handleSubmit((data) =>
    onSubmit?.(data, {
      setError,
    }),
  );

  return (
    <ModalLayout
      // onClose={handleCloseModal}
      isOpenModal
      title={t('processing_personal_data_title')}
      isShowClose={false}
      isUsePortal
      content={
        <>
          <p className='txt txt--center mt-4'>{t('processing_personal_data_description')}</p>
          <form
            className='mt-6'
            onSubmit={onSubmitHandler}
            // onKeyDown={e => offEnterForm(e)}
          >
            <CheckBoxFieldHookForm
              control={control}
              name={PersonalDataProcessingDialogModalFormFields.IsDataProcessingConsentGiven}
              id={PersonalDataProcessingDialogModalFormFields.IsDataProcessingConsentGiven}
              rules={validateCheckBox}
              label={
                <Trans
                  i18nKey='is_data_processing_consent_given'
                  components={{
                    a: (
                      <a href={`${term_conditions}`} rel='noreferrer' target='_blank'>
                        ${t('terms conditions')}
                      </a>
                    ),
                  }}
                />
              }
              classes={{ root: 'input-checkbox__big mb40' }}
            />
            <Button
              disabled={isSubmitting}
              classes={{ root: 'btn btn--primary' }}
            >{t`continue`}</Button>
          </form>
        </>
      }
    />
  );
};
