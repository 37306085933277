import { useEffect } from 'react';
import { RouterProvider } from 'react-router-dom';
import { router } from './pages/config';
import './assets/styles/main.scss';

export const App = () => {
  useEffect(() => {
    const loaderElement = document.querySelector('.loader-container') as HTMLElement;
    if (loaderElement) {
      loaderElement.remove();
    }
  }, []);

  return <RouterProvider router={router} />;
};
