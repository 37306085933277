import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import cn from 'classnames';

export interface IErrorMessageProps {
  message?: string;
  classError?: string;
}

export const CommonServerErrorMessage: FC<IErrorMessageProps> = (props) => {
  const { message = '', classError } = props;
  const { t } = useTranslation();
  return <span className={cn('server-error-message', classError)}>{t(message)}</span>;
};
