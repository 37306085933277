import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { emailVerify, getUserInfo, updateUser } from '../actions/api';
import { AccountBankDetailsModel, LivenessDetectionType, User } from 'models';

interface InitialUserState {
  user: User | null;
  liveness: LivenessDetectionType | null;
  bankAccountDetails: AccountBankDetailsModel;
  isShortLinkAuth: boolean;
  isErrorCamera: boolean;
  isEmailSuccessfullyVerified: boolean;
  isClickedItsClearButton: boolean;
}

const initialState: InitialUserState = {
  user: null,
  liveness: null,
  bankAccountDetails: { bank_account_number: '', bank_branch_name: '', bank_name: '' },
  isShortLinkAuth: false,
  isErrorCamera: false,
  isEmailSuccessfullyVerified: false,
  isClickedItsClearButton: false,
};

export const userSlice = createSlice({
  name: 'userSlice',
  initialState,
  reducers: {
    setBankDetails: (state, action: PayloadAction<AccountBankDetailsModel>) => {
      state.bankAccountDetails = action.payload;
    },
    setIsShortLinkAuth: (state, action: PayloadAction<boolean>) => {
      state.isShortLinkAuth = action.payload;
    },
    setIsErrorCamera: (state, action: PayloadAction<boolean>) => {
      state.isErrorCamera = action.payload;
    },
    setLivenessValue: (state, action: PayloadAction<LivenessDetectionType>) => {
      state.liveness = action.payload;
    },
    setClickedButton: (state, action: PayloadAction<boolean>) => {
      state.isClickedItsClearButton = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getUserInfo.fulfilled, (state, action) => {
      state.user = action.payload;
    });
    builder.addCase(updateUser.fulfilled, (state, action) => {
      state.user = action.payload;
    });
    builder.addCase(emailVerify.fulfilled, (state) => {
      state.isEmailSuccessfullyVerified = true;
    });
  },
});

export const {
  setBankDetails,
  setIsShortLinkAuth,
  setIsErrorCamera,
  setLivenessValue,
  setClickedButton,
} = userSlice.actions;
