import { useTranslation } from 'react-i18next';
import { ModalLayout } from 'core/components/common';
import { BrowserIcon, DotsIcon } from '../../icons';
import { WebViewAnimation } from '../../WebView';
import { isAndroid } from 'constantsLk';

interface InfoDialogWebViewProps {
  onClose?: () => void;
}

export const CameraAccessDeniedWebViewModal = (props: InfoDialogWebViewProps) => {
  const { onClose } = props;
  const { t } = useTranslation();

  return (
    <ModalLayout
      onClose={onClose}
      // isOpenModal={!!location.state?.isOpenModal}
      isOpenModal
      title={t`webview_modal_title`}
      isShowClose
      isUsePortal
      isOutSideClick
      classes={{ content: 'gap-3 flex-column mt-3', wrapper: 'webview, gap-0' }}
      content={
        <>
          <p className='txt'>{t`webview_modal_subtitle`}</p>

          <div className='webview-card flex-column gap-2'>
            <p className='txt'>
              {t(`${isAndroid ? 'webview_android_text' : 'webview_ios_text'}`, {
                dotsIcon: <DotsIcon />,
              })}
            </p>
            <p className='txt'>{t('webview_text_2', { browserIcon: <BrowserIcon /> })}</p>

            <WebViewAnimation />
          </div>
        </>
      }
    />
  );
};
