import { FocusEvent, useCallback, useEffect, useMemo } from 'react';
import { useController } from 'react-hook-form';
import InputMask from 'react-input-mask';
import { UseControllerProps } from 'react-hook-form/dist/types/controller';
import { isValid } from 'date-fns';
import { useTranslation } from 'react-i18next';
import { parseDate } from 'core/utils/parsers';
import { CalendarIcon } from 'core/components/common';
import { DatePickerField, DatePickerFieldProps } from './DatePickerField';
import { SERVER_DATE_FORMAT } from 'constantsLk';

type Controller = Pick<UseControllerProps<any>, 'name' | 'control' | 'rules'>;
type DateFieldProps = Controller & Omit<DatePickerFieldProps, 'onChange' | 'value' | 'selected'>;

export const DateField = (props: DateFieldProps) => {
  const { name, control, rules, placeholderText, maxDate, minDate, label, ...rest } = props;
  const { i18n } = useTranslation();
  const lang = i18n.language;

  const {
    field: { onChange, value },
    fieldState: { error },
  } = useController({
    name,
    control,
    rules,
  });
  const handleChange = useCallback((value: Date) => onChange(value), []);

  useEffect(() => {
    const date = new Date(value);
    isValid(date) && handleChange(date);
  }, []);

  const handleChangeRaw = useCallback((e: FocusEvent<HTMLInputElement>) => {
    const value = e.target.value;
    const date = parseDate(value);

    if (value !== undefined) {
      onChange(isValid(date) ? date : value);
    }
  }, []);

  const selected: Date | null = useMemo(() => {
    const date = new Date(value);
    return value ? (isValid(date) ? date : null) : null;
  }, [value]);

  return (
    <DatePickerField
      name={name}
      // value={value}
      error={error?.message}
      onChange={handleChange}
      onChangeRaw={handleChangeRaw}
      dateFormat={SERVER_DATE_FORMAT}
      selected={selected}
      customInput={<InputMask mask='9999.99.99' inputMode='numeric' />}
      locale={lang}
      showYearDropdown
      showMonthDropdown
      yearDropdownItemNumber={101}
      maxDate={maxDate}
      minDate={minDate}
      scrollableYearDropdown
      autoComplete='off'
      rightContent={<CalendarIcon />}
      popperPlacement='bottom-start'
      placeholderText={placeholderText}
      label={label}
      {...rest}
    />
  );
};
