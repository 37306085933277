import { useTranslation } from 'react-i18next';

import { WpMenu } from 'core/models';
import { LanguageSwitcherContainer } from 'core/components/common';

import { ClockIcon, LanguageIcon, NavLinkArrow, PhoneIcon } from 'components';
import { DEFAULT_LANGUAGE, LANGUAGES, PATH_ACCOUNT } from 'constantsLk';

export interface SidebarProps {
  headerMenu?: WpMenu[];
  workingHours?: string;
  phone?: string;
  onLogout: () => void;
}

export const SidebarBody = (props: SidebarProps) => {
  const { headerMenu, phone, workingHours, onLogout } = props;
  const { t } = useTranslation();
  const isAccount = location.pathname.includes(PATH_ACCOUNT);

  return (
    <>
      <div className='sidebar-wrapper flex-column flex-grow-1 gap-4 mr-3 ml-3'>
        <div className='phone w-100 gap-2 align-items-center'>
          <div className='icon-wrap'>
            <PhoneIcon />
          </div>
          <div className='flex-column'>
            <a href={`tel: ${phone}`} className='flex-grow-1'>
              {phone}
            </a>
            <span className='txt-base'>{t('seven_days_a_week')}</span>
          </div>
        </div>

        <div className='working-hours w-100 align-items-center gap-2'>
          <div className='icon-wrap'>
            <ClockIcon />
          </div>
          <span>{workingHours}</span>
        </div>
      </div>

      <div className='lang'>
        <div className='align-items-center gap-1'>
          <LanguageIcon />
          <span>{t`language`}:</span>
        </div>

        <LanguageSwitcherContainer
          languageOptions={LANGUAGES}
          excludeLanguage={DEFAULT_LANGUAGE.value}
          isUseLocationLanguage
        />
      </div>

      <nav role='navigation' className='navigation flex-column'>
        {headerMenu?.map((item: { url: string; title: string }) => (
          <a key={item.url} href={item.url}>
            {item.title}
            <NavLinkArrow />
          </a>
        ))}

        {isAccount && (
          <button onClick={onLogout} className='align-items-center' type='button'>
            {t`log_out`}
          </button>
        )}
      </nav>
    </>
  );
};
