import { useTranslation } from 'react-i18next';
import { onlyNumbers } from 'core/utils/parsers';
import { Slider } from 'core/components/common';
import { LoanCalculatorFields } from 'models';
import { SLIDER_THEME } from 'constantsLk';

interface NewOfferSectionProps {
  returnAmount:
    | {
        loan_amount: number;
        maturity_date: string;
      }
    | undefined;
  onChangeSlider: (name: string, value: number) => void;
  returnSliderData: { newAmountArray: number[]; indexOfAmount: number } | undefined;
}
export const NewOfferSection = (props: NewOfferSectionProps) => {
  const { returnAmount, onChangeSlider, returnSliderData } = props;

  const { t } = useTranslation();

  return (
    <>
      {returnSliderData && (
        <Slider
          colors={SLIDER_THEME}
          values={returnSliderData.newAmountArray}
          name={LoanCalculatorFields.Amount}
          prefix='RS'
          title={t('loan_amount')}
          currentValue={returnSliderData.indexOfAmount}
          onChangeSlider={onChangeSlider}
          texFieldProps={{
            name: LoanCalculatorFields.Amount,
            type: 'tel',
            parsers: [onlyNumbers],
          }}
        />
      )}

      <div className='flex-column gap-3'>
        <div className='align-items-center justify-content-between offer-item'>
          <span className='txt-xs txt-sb'>{t`payment_date`}</span>
          <span className='ml-2 txt-xs txt-sb'>{returnAmount?.maturity_date}</span>
        </div>
        <div className='align-items-center justify-content-between offer-item'>
          <span className='txt-xs txt-sb'>{t`loan_amount`}</span>
          <span className='ml-2 txt-xs txt-sb'>{`RS ${returnAmount?.loan_amount}`}</span>
        </div>
      </div>
    </>
  );
};
