import { useTranslation } from 'react-i18next';

import { formattingPhone } from 'utils/functions';
import { logOutUtil } from 'utils';
import { EditIcon } from 'components/icons';

interface SendCodeSectionProps {
  phone?: string | null;
  onPhoneReset?: () => void;
}

export const PhoneSection = ({ phone = '', onPhoneReset }: SendCodeSectionProps) => {
  const { t } = useTranslation();

  const onPhoneResetHandler = () => {
    onPhoneReset && onPhoneReset();
    logOutUtil();
  };

  return (
    <div className='flex-column'>
      <span className='txt'>{t('sent_you_sms_code')}</span>
      <span className='align-items-center mb-1 mt-1'>
        <span className='txt-primary txt txt-sb mr-2'>{formattingPhone(phone)}</span>
        {onPhoneReset && (
          <button type='button' className='btn-secondary' onClick={onPhoneResetHandler}>
            <EditIcon /> <span className='txt-r'>{t('change')}</span>
          </button>
        )}
      </span>
      <span className='txt mb-5'>{t('please_enter_code')}</span>
    </div>
  );
};
