import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { setRedirect } from 'core/store/actions';
import { useAppDispatch } from 'hooks';
import { ReferralInfoModal } from '../modals';
import { ReferralMainIcon } from '../icons';
import { User } from 'models';
import { ReferralInfoBox } from './ReferralInfoBox';
import { URL_REFERRAL_PAGE } from 'constantsLk';

interface HowToGetBonusesProps {
  withoutContent?: boolean;
  user: User | null;
}

export const HowToGetBonuses = (props: HowToGetBonusesProps) => {
  const { withoutContent, user } = props;

  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const [isOpenModal, setIsOpenModal] = useState(false);

  const handleModalOpen = () => setIsOpenModal(true);

  const handleModalClose = () => setIsOpenModal(false);

  const handleClick = () => dispatch(setRedirect({ to: URL_REFERRAL_PAGE }));

  return (
    <>
      <div className='card flex-column'>
        <ReferralMainIcon />
        <h2 className='txt-md txt-sb mt-6 mb-2'>{t`earn_for_each_friend_you_invite`}</h2>
        <p className='mb-1'>{t`earn_for_each_friend_you_invite_text`}</p>

        <div>
          <a
            onClick={withoutContent ? handleClick : handleModalOpen}
            className='link mt-1 mb-2 w-100'
          >
            <span className='txt-sb'>
              {t(`${withoutContent ? 'how_to_get_bonuses' : 'more_info'}`)}
            </span>
          </a>
        </div>

        {!withoutContent && !!user?.referral_info?.referral_link && (
          <ReferralInfoBox info={user?.referral_info} />
        )}
      </div>

      {isOpenModal && <ReferralInfoModal handleModalClose={handleModalClose} />}
    </>
  );
};
