import { useController } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { SelectField, SelectFieldProps } from 'core/components/fields';
import { SelectOptionType, UseControllerCoreProps } from 'core/models';
import { selectStyles } from 'assets/styles/select_style';

type SelectFieldHookFormProps = Omit<SelectFieldProps, 'defaultValue' | 'value'> &
  UseControllerCoreProps & {
    withImportantBanks?: boolean;
  };

export const SelectFieldHookForm = (props: SelectFieldHookFormProps) => {
  const { name, options, control, rules, onChange, ...rest } = props;
  const { t } = useTranslation();

  const {
    field,
    fieldState: { error },
  } = useController({
    name,
    control,
    rules,
  });

  const handleChange = (name: string, value: typeof field.value) => {
    field.onChange(value);
    onChange?.(name, value);
  };

  return (
    <SelectField
      error={error?.message}
      value={options.find((o: SelectOptionType) => o.value === field.value) || null}
      onChange={handleChange}
      options={options}
      styles={selectStyles}
      name={name}
      placeholder={t`placeholder_select_default`}
      classes={{ root: `__no-empty ${rest.withImportantBanks ? 'with-important-banks' : ''}` }}
      isSearchable={false}
      {...rest}
    />
  );
};
