import { useTranslation } from 'react-i18next';
import { RequestStatus } from 'components';
import { PARTNERS_LINK } from 'constantsLk';

export const StatusWithPartner = () => {
  const { t } = useTranslation();

  return (
    <RequestStatus
      title={'unfortunately_your_application'}
      text={t`money_our_partners`}
      footer={
        <div className='flex-column mt-2 w-100 flex-center tablet-mt-4'>
          <a
            className='btn btn--primary w-100'
            href={PARTNERS_LINK}
            target='_blank'
            rel='noreferrer'
          >
            <span>{t`apply_now`}</span>
          </a>
          <span className='txt-secondary mt-4 tablet-mt-6'>{t`thanks_greatly_choosing_our_service`}</span>
        </div>
      }
    />
  );
};
