import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { useTypedSelector } from 'core/hooks';

import { activeLoanSelector, shortLinkLoanSelector, userSelector } from 'store/selectors';
import { getPaymentBanks } from 'store/actions/api';
import { useAppDispatch } from 'hooks';
import { RepaymentSlipModalContainer } from 'components/modals/RepaymentSlipModal/RepaymentSlipModalContainer';
import { ContactCollectionTeam } from './ContactCollectionTeam';
import { getWhatsappLink, REPAYMENT_SLIP_PHONE_NUMBER, SENT_PAYMENT } from 'constantsLk';

const contactLinks = [
  {
    number: REPAYMENT_SLIP_PHONE_NUMBER,
    title: 'contact_repayment_slip',
    isSendFile: true,
  },
  // {
  //   number: COLLECTION_TEAM_PHONE_NUMBER,
  //   title: 'contact_collection_team',
  // },
];

interface ContactCollectionTeamContainerProps {
  showCollectionAlert: boolean | null;
}
export const ContactCollectionTeamContainer = ({
  showCollectionAlert,
}: ContactCollectionTeamContainerProps) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [isOpenModal, setIsOpenModal] = useState(false);
  const [isOpenAlert, setIsOpenAlert] = useState(showCollectionAlert);

  const activeLoan = useTypedSelector(activeLoanSelector);
  const shortLinkLoan = useTypedSelector(shortLinkLoanSelector);
  const user = useTypedSelector(userSelector);

  const loanData = activeLoan?.id ? activeLoan : shortLinkLoan;

  useEffect(() => {
    const isSentPayment = location.search.includes(SENT_PAYMENT);

    if (isSentPayment) handleOpenModal();
  }, []);

  const handleOpenModal = () => {
    navigate({ search: `?${SENT_PAYMENT}` });
    setIsOpenModal(true);
  };
  const handleCloseModal = () => {
    const searchParams = new URLSearchParams(location.search);
    searchParams.delete(SENT_PAYMENT);
    navigate(`${location.pathname}?${searchParams.toString()}`, { replace: true });

    setIsOpenModal(false);
  };

  const handleCloseAlert = () => setIsOpenAlert(false);

  useEffect(() => {
    dispatch(getPaymentBanks()).unwrap();
  }, []);

  return isOpenAlert ? (
    <>
      <div className='flex flex-sm-no-wrap gap-3 gap-sm-6'>
        {contactLinks.map((link) => (
          <ContactCollectionTeam
            isSendFile={link.isSendFile}
            onOpen={handleOpenModal}
            title={t(link.title)}
            handleCloseAlert={handleCloseAlert}
            link={getWhatsappLink(
              link.number,
              loanData?.loan_id ? loanData?.loan_id : loanData?.id,
              user?.personal_id_number ? user?.personal_id_number : '',
            )}
            key={link.number}
          />
        ))}
      </div>

      <RepaymentSlipModalContainer isOpenModal={isOpenModal} onClose={handleCloseModal} />
    </>
  ) : null;
};
