import React, { useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { format } from 'date-fns';

import { useTypedSelector } from 'core/hooks';
import { SubmitCoreHandler } from 'core/models';
import { setRedirect } from 'core/store/actions';
import { Button } from 'core/components/common';

import { useAppDispatch } from 'hooks';
import { NewOfferAlert, NewOfferSection } from 'components/NewOffer';
import { NewOfferField, NewOfferModel } from 'models';
import { activeApplicationSelector, pricesSelector } from 'store/selectors';
import { changeApplicationPrincipal, getUserInfo } from 'store/actions/api';
import { objKeysToNumber } from 'utils';
import { Spinner } from 'components';
import en from 'date-fns/locale/en-US';
import si from 'langSI';
import { formatMaturity, getRegStepPath } from 'constantsLk';

export const NewOfferContainer = () => {
  const { t, i18n } = useTranslation();
  const dispatch = useAppDispatch();
  const lang = i18n.language;
  const selectedLanguage = lang === 'en' ? en : si;

  const activeApplication = useTypedSelector(activeApplicationSelector);
  const priceList = useTypedSelector(pricesSelector);

  const [isOpen, setIsOpen] = useState(true);

  const [conditions, setConditions] = useState({
    term: activeApplication?.approved_tenor,
    amount: activeApplication?.counter_offer_default_principal,
  });

  const {
    handleSubmit,
    setValue,
    setError,
    formState: { isSubmitting },
  } = useForm<NewOfferModel>({
    mode: 'onSubmit',
    defaultValues: {
      principal: conditions.amount,
    },
  });

  const returnSliderData = useMemo(() => {
    const approvedPrincipal = activeApplication?.approved_principal;
    const counterOfferPrincipal = activeApplication?.counter_offer_principal;

    if (priceList && approvedPrincipal && counterOfferPrincipal && conditions.term) {
      const amountArray = objKeysToNumber(priceList[conditions.term]);
      const newAmountArray = amountArray.filter(
        (amount) => amount >= approvedPrincipal && amount <= counterOfferPrincipal,
      );
      const indexOfAmount = newAmountArray?.indexOf(Number(conditions?.amount)) || 0;

      return { newAmountArray, indexOfAmount };
    }
  }, [activeApplication, priceList]);

  const returnAmount = useMemo(() => {
    const term = conditions.term;

    if (conditions.amount && term && priceList) {
      const currentTerm = priceList[term];

      return {
        loan_amount: conditions.amount,
        maturity_date: format(
          new Date(currentTerm[conditions.amount].maturity_date),
          formatMaturity,
          {
            locale: selectedLanguage,
          },
        ),
      };
    }
  }, [priceList, conditions, selectedLanguage]);

  const onChangeSlider = (name: string, value: number) => {
    setConditions((prevState) => ({ ...prevState, [name]: value }));
    setValue(NewOfferField.Principal, value);
  };

  const onSubmit: SubmitCoreHandler<NewOfferModel> = async (values) => {
    if (activeApplication) {
      const data = { appId: activeApplication?.id, selectPrincipal: values };
      return (
        dispatch(changeApplicationPrincipal(data))
          .unwrap()
          .then(() => {
            return dispatch(getUserInfo())
              .unwrap()
              .then((res) => {
                dispatch(setRedirect({ to: getRegStepPath(res.registration_step) }));
              });
          })
          // eslint-disable-next-line no-console
          .catch((err) => console.log(err))
      );
    }
  };

  const onSubmitHandler = handleSubmit((data) =>
    onSubmit?.(data, {
      setError,
    }),
  );

  const onCloseAlert = () => setIsOpen(false);

  return (
    <>
      {priceList ? (
        <div className='flex-column flex-center gap-3 flex-grow-1 mw-54 m-auto w-100'>
          {isOpen && (
            <NewOfferAlert
              onClose={onCloseAlert}
              offerPrincipal={activeApplication?.counter_offer_principal}
            />
          )}

          <div className='offer-block flex-column mw-54 w-100 gap-3'>
            <NewOfferSection
              returnAmount={returnAmount}
              onChangeSlider={onChangeSlider}
              returnSliderData={returnSliderData}
            />

            <form onSubmit={onSubmitHandler}>
              <Button disabled={isSubmitting} className='btn btn--primary w-100'>
                {t('get_settlement_amount', { amount: returnAmount?.loan_amount })}
              </Button>
            </form>
          </div>
        </div>
      ) : (
        <Spinner />
      )}
    </>
  );
};
