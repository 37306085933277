import { useEffect } from 'react';

import { useTypedSelector } from 'core/hooks';
import { setRedirect } from 'core/store/actions';

import { isServerErrorSelector } from 'store/selectors';
import { ServerError } from './ServerError';
import { useAppDispatch } from 'hooks';

export const ServerErrorContainer = () => {
  const dispatch = useAppDispatch();
  const isServerError = useTypedSelector(isServerErrorSelector);

  useEffect(() => {
    if (!isServerError) dispatch(setRedirect({ to: '/', replace: true }));
  }, [isServerError]);

  return <ServerError />;
};
