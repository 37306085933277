import { RequestConfirmContainer } from 'components/RequestConfirm';
import { ActiveLoanContainer } from './ActiveLoan';
import {
  ApplicationPendingContainer,
  RejectWithMaratoriumContainer,
  NewOfferContainer,
  SendingMoneyNotifyContainer,
} from './ApplicationStatuses';
import { LoanBlackListContainer } from './LoanBlackList';
import { LoanCalculatorContainer } from './LoanCalculator';
import { autoSignInHoc } from 'hoc';

interface AccountLoanPageProps {
  isInBlackList?: boolean;
  isApplicationRejWithMoratorium: boolean;
  isAppApprovedApplication: boolean;
  isApplicationPending: boolean;
  isAppConfirmedApplication: boolean;
  isLoanPending: boolean;
  isLoanActive: boolean;
  isSelectedPrincipal: boolean;
}

//hocs to make requests every 60 sec when pending loan or application state changed
const ApplicationPendingWithPulling = autoSignInHoc<any>(ApplicationPendingContainer);
const SendingMoneyNotifyContainerWithPulling = autoSignInHoc<any>(SendingMoneyNotifyContainer);
const RequestConfirmWithPulling = autoSignInHoc<any>(RequestConfirmContainer);

export const AccountLoanPage = (props: AccountLoanPageProps) => {
  const {
    isInBlackList,
    isAppApprovedApplication,
    isApplicationPending,
    isAppConfirmedApplication,
    isLoanPending,
    isLoanActive,
    isApplicationRejWithMoratorium,
    isSelectedPrincipal,
  } = props;

  if (isInBlackList) return <LoanBlackListContainer />; //if loan sold also user goes to blacklist
  if (isApplicationPending) return <ApplicationPendingWithPulling />;
  if (isApplicationRejWithMoratorium) return <RejectWithMaratoriumContainer />;
  if (isAppApprovedApplication && !isSelectedPrincipal) return <NewOfferContainer />;
  if (isAppApprovedApplication) return <RequestConfirmWithPulling />;
  if (isAppConfirmedApplication && isLoanPending) return <SendingMoneyNotifyContainerWithPulling />;
  if (isAppConfirmedApplication && isLoanActive) return <ActiveLoanContainer />;

  return <LoanCalculatorContainer />;
};
