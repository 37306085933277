import { useState } from 'react';
import { useAppDispatch } from './useAppDispatch';

export const useUploadFiles = ({
                                 addFileAPI,
                                 deleteFileAPI,
                                 getFileAPI,
                               }: {
  addFileAPI: (data: {
    files: Blob
    type: string
    uploadProgress?: <T>(data?: T) => void;
  }) => any;
  deleteFileAPI?: (data: { type: string }) => any;
  getFileAPI: (data: { type: string }) => any;
}) => {
  const dispatch = useAppDispatch();
  const [progressAddFileAPI, setUploadProgress] = useState<number>(0);
  
  const uploadProgress = (data: any) => {
    setUploadProgress(data);
  };
  
  const sendFile = (data: { file: Blob; type: string }) => {
    const formData = new FormData();
    formData.append('image', data.file, 'image.png');
    const file = data.file;
    // formData.append('type', data.type);
    // const { file, type } = data;
    return dispatch(addFileAPI({ files: file, type: data.type, uploadProgress }))
      // return dispatch(addFileAPI({ file, type }))
      .unwrap()
      .finally(() => setUploadProgress(0));
  };
  
  const changeTypeFile = (type: string, file: File) => {
    const splitNameFile = file?.name?.split('.');
    file = new File([file], `${type}.${splitNameFile[splitNameFile.length - 1]}`, {
      type: file.type,
    });
    return { file, type };
  };
  
  const deleteFile = async (type: string) => {
    return deleteFileAPI && dispatch(deleteFileAPI({ type })).unwrap();
  };
  
  const getFile = async (type: string) => {
    return dispatch(getFileAPI({ type })).unwrap();
  };
  
  return {
    sendFile,
    changeTypeFile,
    deleteFile,
    getFile,
    progressAddFileAPI,
  };
};
