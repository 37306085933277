import { useEffect, useState } from 'react';

import { getCookie, removeCookie, setCookie } from 'core/services';
import { TIMER_KEY } from 'core/constants';
import { parseTimeForTimer } from 'core/utils/functions';
import { TimerValueReturned } from 'core/models';
import { TIMER_WITH_CODE } from 'constantsLk';

export interface UseTimerProps {
  initialTime?: number;
  timerName: string;
  separator?: string;
  step?: number;
  isUseUnmount?: boolean;
}

export interface UseTimerResponse {
  timerValue: TimerValueReturned;
  isShowTimer: boolean;
  showTimer: () => Promise<unknown>;
  hideTimer: () => void;
}

export type UseTimer = (props: UseTimerProps) => UseTimerResponse;

export const useTimer: UseTimer = (props) => {
  const {
    initialTime = TIMER_WITH_CODE,
    separator,
    step = 1000,
    timerName,
    isUseUnmount = true,
  } = props;

  const [isShowTimer, setIsShowTimer] = useState<boolean>(true);

  const getSeconds = () => {
    const memorizedSeconds = getCookie(`${TIMER_KEY}_${timerName}`);
    const isHasMemorisedSeconds = typeof memorizedSeconds === 'number';

    return isHasMemorisedSeconds ? memorizedSeconds : initialTime;
  };

  const [timerValue, setTimerValue] = useState<TimerValueReturned>({
    secondString: '',
    hoursString: '',
    secondsNumber: 0,
    minutesString: '',
    fullString: '',
  });

  const updateTimer = (seconds: number) => {
    const timerData = parseTimeForTimer(seconds, separator);
    setTimerValue(timerData);
    setCookie(`${TIMER_KEY}_${timerName}`, seconds);
  };

  useEffect(() => {
    setTimerValue(parseTimeForTimer(getSeconds(), separator));

    return () => {
      isUseUnmount && removeCookie(`${TIMER_KEY}_${timerName}`);
    };
  }, []);

  useEffect(() => {
    if (!isShowTimer) return;

    let seconds = getSeconds();
    if (seconds <= 0) return setIsShowTimer(false);

    const intervalId = setInterval(() => {
      if (seconds <= 0) {
        setIsShowTimer(false);
      } else {
        seconds = seconds - step / 1000;
        updateTimer(seconds);
      }
    }, step);

    return () => {
      clearInterval(intervalId);
    };
  }, [isShowTimer]);

  const showTimer = () =>
    new Promise((resolve) => {
      removeCookie(`${TIMER_KEY}_${timerName}`);
      setTimerValue(parseTimeForTimer(getSeconds(), separator));
      setIsShowTimer(true);
      resolve('');
    });

  const hideTimer = () => setIsShowTimer(false);

  return {
    timerValue,
    isShowTimer,
    showTimer,
    hideTimer,
  };
};
