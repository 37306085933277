import { useState } from 'react';
import { FieldValues, UseFormClearErrors } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { LoanService } from 'services';
import { ITEM_STATUS } from 'constantsLk';

interface UsePromoCodeProps<T extends FieldValues> {
  clearErrors: UseFormClearErrors<T>;
  onSuccess?: () => void;
  handlePromoCodeAction: (promoCode: string) => Promise<void>;
}

export const usePromoCode = <T extends FieldValues>({
  clearErrors,
  onSuccess,
  handlePromoCodeAction,
}: UsePromoCodeProps<T>) => {
  const { t } = useTranslation();

  const [formState, setFormState] = useState<string>(ITEM_STATUS.DEFAULT);

  const value = LoanService.promoCode;

  const onEnterPromoCode = () => {
    if (formState === ITEM_STATUS.ACTIVE) {
      setFormState(ITEM_STATUS.DEFAULT);
    } else {
      setFormState(ITEM_STATUS.ACTIVE);
    }
  };

  const onResetCode = async () => {
    clearErrors('root.commonError');
    onEnterPromoCode();
  };

  const onConfirm = async (values: { code: string }, { setError }: { setError: any }) => {
    clearErrors('root.commonError');
    const promoCode = values.code;

    try {
      await handlePromoCodeAction(promoCode);
      LoanService.promoCode = promoCode;
      setFormState(ITEM_STATUS.SUCCESS);
      if (onSuccess) onSuccess();
    } catch (err: any) {
      const message = err.message || t`promocode_is_not_valid`;
      setError('code', { type: 'validate', message });
    }
  };

  return {
    formState,
    value,
    onEnterPromoCode,
    onResetCode,
    onConfirm,
  };
};
