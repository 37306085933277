import { FC } from 'react';
import { ModalLayout } from 'core/components/common';
import { Spinner } from '../../Spinner';
import { ModalLayoutProps } from 'core/components/common/ModalLayout/ModalLayout';

interface IGetUsersModalProps extends ModalLayoutProps {
  htmlContent?: string | null;
  onClose?: () => void;
}

export const HtmlDocumentModal: FC<IGetUsersModalProps> = ({ htmlContent, onClose, title }) => {
  const onCloseHandler = () => {
    onClose && onClose();
  };

  return (
    <ModalLayout
      onClose={onCloseHandler}
      isOpenModal
      classes={{ content: 'default-css' }}
      isOutSideClick
      title={title}
      content={
        htmlContent ? <div dangerouslySetInnerHTML={{ __html: htmlContent }} /> : <Spinner />
      }
    />
  );
};
