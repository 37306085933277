import { useTranslation } from 'react-i18next';
import { useController, useForm } from 'react-hook-form';
import { addDays, parseISO } from 'date-fns';
import { useTimer } from 'core/hooks';
import { TextFieldHookForm } from 'core/components/fields';
import { formatNumber, maxLength, onlyNumbers } from 'core/utils/parsers';
import { Button } from 'core/components/common';
import { SubmitCoreHandler } from 'core/models';

import { useHookFormGetCache, useHookFormSetCache } from 'hooks';
import { LoanSmsConfirmFormModel, LoanSmsConfirmFields, Application } from 'models';
import { TooltipItem } from 'components/Tooltip';
import { TooltipInfoIcon2 } from 'components/icons';
import { ResendLoanCodeContainer } from './ResendLoanCodeContainer';
import { SmsConfirmLoanPromoCodeContainer } from './PromoCode/SmsConfirmLoanPromoCodeContainer';
import { validatePhoneCode } from 'utils/validates/validation';
import { EventName, onSubmitEventTagHandler } from 'utils/analitics';
import { GTM_LABEL_SIGN_UP } from 'utils/analitics/gtmLabel';
import { formatDate } from 'utils/parsers';
import { CURRENCY, TODAY } from 'constantsLk';

export interface SmsConfirmLoanProps {
  setConfirmWithCall?: () => void;
  onSubmit: SubmitCoreHandler<LoanSmsConfirmFormModel>;
  onPromoCodeApply: () => void;
  activeApplication: Application | null;
  isPromoCodeEnable: boolean | null;
}

export const SmsConfirmLoan = (props: SmsConfirmLoanProps) => {
  const { onSubmit, onPromoCodeApply, activeApplication, isPromoCodeEnable } = props;

  const { t } = useTranslation();
  const defaultValues = useHookFormGetCache('ConfirmLoanCode');

  const {
    handleSubmit,
    watch,
    control,
    setError,
    clearErrors,
    formState: { isSubmitting },
  } = useForm<LoanSmsConfirmFormModel>({
    mode: 'onSubmit',
    defaultValues,
    shouldUnregister: true,
  });

  const { field: confirmCodeField } = useController({
    name: LoanSmsConfirmFields.Code,
    control,
    rules: validatePhoneCode,
  });

  useHookFormSetCache('ConfirmLoanCode', watch);

  const loanValues = [
    {
      label: 'loan_amount',
      value: `${CURRENCY} ${activeApplication?.approved_principal ? formatNumber(activeApplication?.approved_principal) : ''}`,
    },
    {
      label: 'payment_date',
      value: activeApplication?.maturity_date
        ? formatDate(parseISO(activeApplication?.maturity_date))
        : formatDate(addDays(TODAY, Number(activeApplication?.approved_tenor))),
    },
    {
      label: 'settlement_amount',
      value: `${CURRENCY} ${activeApplication?.settlement_amount ? formatNumber(activeApplication?.settlement_amount) : ''}`,
    },
  ];

  const onSubmitHandler = handleSubmit(
    (data) =>
      onSubmit?.(data, {
        setError,
      }),
    (errors) => onSubmitEventTagHandler(errors, GTM_LABEL_SIGN_UP, EventName.sign_contract),
  );

  const { timerValue, isShowTimer, showTimer, hideTimer } = useTimer({
    timerName: 'LoanSmsConfirm',
  });

  return (
    <form className='gap-0' onSubmit={onSubmitHandler}>
      <TextFieldHookForm
        control={control}
        name={LoanSmsConfirmFields.Code}
        label={t`enter_code_from_sms`}
        parsers={[maxLength(4), onlyNumbers]}
        inputMode='numeric'
        rules={validatePhoneCode}
      />
      <ResendLoanCodeContainer
        showTimer={showTimer}
        timerValue={timerValue}
        isShowTimer={isShowTimer}
        hideTimer={hideTimer}
        setError={setError}
        onResendCode={() => confirmCodeField.onChange('')}
      />
      {isPromoCodeEnable && activeApplication?.id && (
        <div className='mt-3'>
          <SmsConfirmLoanPromoCodeContainer
            clearErrors={clearErrors}
            activeApplicationId={activeApplication.id}
            onPromoCodeApply={onPromoCodeApply}
          />
        </div>
      )}
      {activeApplication && (
        <div className='loan-conditions justify-content-between flex-grow-0 w-100 mt-3'>
          {loanValues.map((item: { label: string; value: string }) => (
            <div key={item.label} className='column'>
              <div className='flex-nowrap txt-base txt-secondary'>
                <span>{t(item.label)}</span>
                {item.label === 'payment_date' && (
                  <TooltipItem
                    wrapperClassName={'payment_date-tooltip payment_date-tooltip-confirm'}
                    title={t(item.label)}
                    content={t`payment_date_tooltip`}
                    icon={<TooltipInfoIcon2 />}
                  />
                )}
              </div>
              <span className='txt'>{item.value}</span>
            </div>
          ))}
        </div>
      )}
      <Button disabled={isSubmitting} classes={{ root: 'btn--primary mt-3' }}>
        {t('continue')}
      </Button>
    </form>
  );
};
