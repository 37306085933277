import { Trans, useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { SubmitCoreHandler } from 'core/models';
import { Button, ErrorMessage, ModalLayout } from 'core/components/common';
import { ErrorIcon } from '../../../icons';
import { AuthFormModel } from '../../../AuthForm';

interface NICErrorModalProps {
  userPhone?: string;
  handleAnswerNo: () => void;
  handleAnswerYes: SubmitCoreHandler<AuthFormModel>;
  errorMessage: string | null;
}

export const MatchedModal = (props: NICErrorModalProps) => {
  const { userPhone, handleAnswerNo, handleAnswerYes, errorMessage } = props;

  const { t } = useTranslation();

  const {
    handleSubmit,
    setError,
    formState: { isSubmitting },
  } = useForm<AuthFormModel>({
    mode: 'onSubmit',
    shouldUnregister: false,
  });

  const handlerAnswerYes = handleSubmit((data) => {
    return handleAnswerYes?.(data, {
      setError,
    });
  });

  return (
    <ModalLayout
      isShowClose={false}
      isOpenModal
      title={<ErrorIcon />}
      classes={{ wrapper: 'modal-core__wrapper' }}
      isUsePortal
      showAppHeader
      content={
        <div className='flex-column gap-2'>
          <p className='txt txt-md txt-sb mt-3'>{t`nic_number_is_registered`}</p>
          <p className='txt'>
            <Trans i18nKey='nic_number_used' values={{ matched_user_phone: userPhone }} />
          </p>

          {errorMessage && <ErrorMessage message={errorMessage} />}
        </div>
      }
      footer={
        <form onSubmit={handlerAnswerYes} className='form w-100'>
          <Button
            disabled={isSubmitting}
            classes={{ root: 'btn--primary w-100' }}
          >{t`yes2`}</Button>
          <Button type='button' className='link flex-center m-auto mt-4' onClick={handleAnswerNo}>
            {t`no2`}
          </Button>
        </form>
      }
    />
  );
};
