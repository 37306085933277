import { PropsWithChildren } from 'react';

import { useTypedSelector } from 'core/hooks';

import { UserGuard, UserGuardProps } from './UserGuard';
import { isAuthorizedSelector } from 'store/selectors';

export interface UserGuardContainerProps extends Pick<UserGuardProps, 'redirectTo'> {}

export const UserGuardContainer = (props: PropsWithChildren<UserGuardContainerProps>) => {
  const { children, ...rest } = props;

  const isAuthorized = useTypedSelector(isAuthorizedSelector);

  return (
    <UserGuard {...rest} isAuthorized={isAuthorized}>
      {children}
    </UserGuard>
  );
};
