import { createAsyncThunk } from '@reduxjs/toolkit';

import {
  getUserID,
  removeCookie,
  setAccessToken,
  setOldUserAccessToken,
  setOldUserID,
  setOldUserRefreshToken,
  setRefreshToken,
  setUserID,
} from 'core/services';

import { OTPLogin } from './api';
import { Actions, AuthResp } from 'models';
import { get_ga_ClientId, get_ga_SessionId } from 'utils/analitics';
import { getFingerprint } from 'utils/getFingerprint';
import { KEY_STORAGE_FROM_SHORT_LINK } from 'constantsLk';

export const OTPLoginAction = createAsyncThunk<
  AuthResp,
  { username: string; password: string; isOldUser?: boolean }
>(Actions.OTP_LOGIN_ACTION, async (data, { dispatch, rejectWithValue }) => {
  const additionalData = {
    username: data.username,
    password: data.password,
    user_additional_data: {
      google_session_id: get_ga_SessionId(),
      google_client_id: get_ga_ClientId(),
      fingerprint_js: getFingerprint() || {},
      evercookie: getUserID() || null,
      juicy_score_session_id: window?.jslabApi?.getSession
        ? window?.jslabApi?.getSession() || ''
        : '',
    },
  };
  try {
    const resp = await dispatch(OTPLogin(additionalData)).unwrap();
    if (resp) {
      const { user, token, refresh_token } = resp;

      if (data.isOldUser) {
        setOldUserRefreshToken(refresh_token);
        setOldUserAccessToken(token);
        setOldUserID(user.id.toString());
      } else {
        setRefreshToken(refresh_token);
        setAccessToken(token);
        setUserID(user.id.toString());
      }
      removeCookie(KEY_STORAGE_FROM_SHORT_LINK);
    }
    return resp;
  } catch (errors) {
    return rejectWithValue(errors);
  }
});
