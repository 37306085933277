import { useTypedSelector } from 'core/hooks';

import { LoanSoldPage } from './LoanSoldPage';
import { activeLoanSelector, userSelector } from 'store/selectors';

export const LoanSoldPageContainer = () => {
  const loan = useTypedSelector(activeLoanSelector);
  const user = useTypedSelector(userSelector);
  const isPossibleToPay = !loan?.is_closed_sold;

  const saleInfo = {
    company_name: user?.last_loan_sale_info_company_name,
    email: user?.last_loan_sale_info_email,
    phone_number: user?.last_loan_sale_info_phone_number,
    sold_at: user?.last_loan_sale_info_sold_at,
    bank_account_holder_name: user?.last_loan_sale_info_bank_account_holder_name,
    bank_account_number: user?.last_loan_sale_info_bank_account_number,
    bank_branch: user?.last_loan_sale_info_bank_branch,
  };

  return <LoanSoldPage isPossibleToPay={isPossibleToPay} saleInfo={saleInfo} loan={loan} />;
};
