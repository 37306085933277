import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CloseIcon } from 'core/components/common';
import { InfoIcon } from '../icons';

export const SuccessfulPaymentAlert = () => {
  const { t } = useTranslation();

  const [isOpen, setIsOpen] = useState(true);

  const onClose = () => setIsOpen(false);

  return isOpen ? (
    <div className='alerts-item__success flex-column'>
      <div className='alerts-item__success__message w-100'>
        <div className='alerts-item__success__icon__wrapper mr-2'>
          <InfoIcon />
        </div>
        <div className='flex-column w-100 gap-2'>
          <p className='txt-sb '>{t`payment_successful`}</p>
          <p>{t`payment_successfully_processed`}</p>
        </div>

        <div className='alerts-item__success__icon__wrapper close ml-2' onClick={onClose}>
          <CloseIcon />
        </div>
      </div>
    </div>
  ) : null;
};
