import { setRedirect } from 'core/store/actions';
import { SubmitCoreHandler } from 'core/models';

import { clearEmptyFormValues, ServerFieldErrorsHandler } from 'utils';
import { updateUser } from 'store/actions/api';
import { IbanNumberFields, IbanNumberFormModel } from 'models';
import { useAppDispatch } from 'hooks';
import { Step7BankAccDetails } from './Step7BankAccDetails';
import { getRegStepPath } from 'constantsLk';

export const Step7BankAccDetailsContainer = () => {
  const dispatch = useAppDispatch();
  const onSubmit: SubmitCoreHandler<IbanNumberFormModel> = async (values, { setError }) => {
    values = clearEmptyFormValues<IbanNumberFormModel>(values);

    if (values[IbanNumberFields.Email]?.trim()) {
      values = {
        ...values,
        email:
          values[IbanNumberFields.EmailDomain] === 'other'
            ? values[IbanNumberFields.Email]
                ?.replaceAll(' ', '')
                .toLowerCase()
                .replace(/^www\./, '')
            : values[IbanNumberFields.Email]
                ?.replaceAll(' ', '')
                .toLowerCase()
                .replace(/^www\./, '') + values[IbanNumberFields.EmailDomain],
      };
    }

    delete values[IbanNumberFields.EmailDomain];

    return dispatch(updateUser(values))
      .unwrap()
      .then((res) => {
        dispatch(setRedirect({ to: getRegStepPath(res.registration_step), replace: true }));
      })
      .catch((err) => {
        ServerFieldErrorsHandler(err, setError);
      });
  };

  return <Step7BankAccDetails onSubmit={onSubmit} />;
};
