import { useTranslation } from 'react-i18next';
import { useEffect, useMemo, useState } from 'react';
import { format } from 'date-fns';
import { PromotionModel } from '../models';
import { DATE_TIME_FORMAT } from 'constantsLk';

export const usePromotion = (availableTo: Date, activePromo?: PromotionModel) => {
  const { i18n } = useTranslation();
  const lang = i18n.language;

  const promotionTranslate = useMemo(() => {
    const translate = activePromo?.translations.find((item) => item.locale === 'si_LK');
    return lang === 'si' && translate
      ? { header: translate.header, description: translate.customer_description }
      : { header: activePromo?.header, description: activePromo?.customer_description };
  }, [activePromo?.translations, lang]);

  const calculateTimeLeft = (): { [key: string]: number } => {
    const currentTime = new Date().getTime();
    const difference = new Date(availableTo).getTime() - currentTime;

    const timeLeft: { [key: string]: number } = {
      days: difference <= 0 ? 0 : Math.floor(difference / (1000 * 60 * 60 * 24)),
      hours: difference <= 0 ? 0 : Math.floor((difference / (1000 * 60 * 60)) % 24),
      minutes: difference <= 0 ? 0 : Math.floor((difference / (1000 * 60)) % 60),
      seconds: difference <= 0 ? 0 : Math.floor((difference / 1000) % 60),
    };

    return timeLeft;
  };

  const [timeLeft, setTimeLeft] = useState<{ [key: string]: number }>(calculateTimeLeft());
  const [promoStatus, setPromoStatus] = useState('');
  const [isBlinking, setIsBlinking] = useState(false);

  const updatePromoStatus = () => {
    if (timeLeft.days > 0) {
      return 'success';
    } else if (timeLeft.hours < 24 && timeLeft.hours >= 3) {
      return 'warning';
    } else {
      setIsBlinking(true);
      return 'error';
    }
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      setTimeLeft(calculateTimeLeft());
    }, 60000);

    return () => clearTimeout(timer);
  });

  useEffect(() => {
    const newPromoStatus = updatePromoStatus();
    if (newPromoStatus !== promoStatus) {
      setPromoStatus(newPromoStatus);
    }
  }, [timeLeft, promoStatus]);

  const formattedDate = useMemo(() => {
    return availableTo ? format(new Date(availableTo), DATE_TIME_FORMAT) : '';
  }, [availableTo]);

  return {
    promotionTranslate,
    timeLeft,
    promoStatus,
    isBlinking,
    formattedDate,
  };
};
