import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getUserID } from 'core/services';
import { isFacebookWebview, isWebView } from 'utils';
import { IPhotoUploadInitStateProps } from '../UploadPhotoComponent';
import { DocumentsType } from 'models';
import { useAppDispatch } from 'hooks';
import { setIsErrorCamera } from 'store/slices';
import { siLangOZPlugin } from 'localization/ozPlugin/siLangForOzPlugin';
import { livenessProps } from './LivenessEngines';
import { LIVENESS } from 'constantsLk';

export const OzForensics = (props: livenessProps) => {
  const {
    isShowSelfieInstructionsDialog,
    onClose,
    defaultUploadFileState,
    documentFile,
    sendLog,
    setStateComponent,
    setValue,
    onSubmitHandler,
    setIsLoadingSDK,
  } = props;

  const { i18n } = useTranslation();
  const dispatch = useAppDispatch();

  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    if (i18n.language === 'si' && loaded) {
      window.OzLiveness?.add_lang('si', siLangOZPlugin);
    }
  }, [i18n.language, loaded]);

  useEffect(() => {
    setIsLoadingSDK && setIsLoadingSDK(true);
    getOzLiveness()
      .then(() => {
        setLoaded(true);
        setIsLoadingSDK && setIsLoadingSDK(false);
      })
      .catch((error: any) => {
        onClose();
        const data: IPhotoUploadInitStateProps = {
          ...defaultUploadFileState,
          error: 'liveness_loading_error',
        };
        setStateComponent(data);
        sendLog({ ...data, error: error.message }, LIVENESS.OZFORENCIS);

        // eslint-disable-next-line no-console
        console.error('OzLiveness error:', error);
      });
  }, []);

  const getOzLiveness = () => {
    if (window.OzLiveness) {
      return Promise.resolve(window.OzLiveness);
    }

    return new Promise((resolve, reject) => {
      const script: any = document.querySelector('#websdk-plugin');
      if (!script) {
        return reject(new Error('Plugin script not found'));
      }

      script.onload = () => {
        if (window.OzLiveness) {
          resolve(window.OzLiveness);
        } else {
          reject(new Error('Plugin initialization error'));
        }
      };
      script.onerror = () => {
        reject(new Error('Plugin loading error'));
      };
    });
  };

  const runFaceDetectorLibrary = () => {
    onClose();

    return window.OzLiveness.open({
      lang: i18n.language,
      action: ['video_selfie_blank'],
      meta: {
        transaction_id: 'cashx',
        iin: getUserID(),
        meta_key: 'meta_value',
      },
      on_complete: function (result: any) {
        const { analyses } = result;
        // eslint-disable-next-line no-console
        console.log('on_complete', analyses);
        const isFail = analyses?.quality.resolution !== 'success';
        if (isFail) {
          const data: IPhotoUploadInitStateProps = {
            ...defaultUploadFileState,
            error: 'please_make_new_selfie',
          };
          setStateComponent(data);
          sendLog(result, LIVENESS.OZFORENCIS);
        } else {
          sendLog(result, LIVENESS.OZFORENCIS)
            .then((res) => onSubmitHandler(res.id)())
            .catch(() => onSubmitHandler()());
        }
      },
      on_capture_complete: function (result: any) {
        // eslint-disable-next-line no-console
        console.log('on_capture_complete', result);
        const { best_frame } = result;

        const data: IPhotoUploadInitStateProps = {
          ...defaultUploadFileState,
          state: 'done',
          file: best_frame,
          file_type: documentFile.type,
        };
        setStateComponent(data);
        setValue(DocumentsType.Selfie, best_frame);
        sendLog(
          { ...data, meta: result.meta, event_session_id: result.event_session_id },
          LIVENESS.OZFORENCIS,
        );
      },
      on_result: function (result: any) {
        // eslint-disable-next-line no-console
        console.log('on_result', result);
      },
      on_submit: function (result: any) {
        // eslint-disable-next-line no-console
        console.log('on_submit', result);
      },
      on_error: function (error: any) {
        const data = {
          error,
          is_webview: isWebView(),
        };
        if (error.code.includes('error_no_camera') && isFacebookWebview) {
          dispatch(setIsErrorCamera(true));
        }
        setStateComponent({ ...defaultUploadFileState });
        // eslint-disable-next-line no-console
        console.log('on_error', data);
        sendLog(data, LIVENESS.OZFORENCIS);
      },
    });
  };

  useEffect(() => {
    if (isShowSelfieInstructionsDialog) {
      runFaceDetectorLibrary();
    }
  }, [isShowSelfieInstructionsDialog]);

  return null;
};
