import { Store } from '@reduxjs/toolkit';
import { getCookie, getUserID, sendDataLayer, sendDataLayerError, setUserID } from 'core/services';
import { RootState } from 'store';
import { Actions, DocumentsType, Loan, LoanSmsConfirmFields, PhoneConfirmFormFields } from 'models';
import {
  GTM_LABEL_LOAN_REPEAT_APPLICATION,
  GTM_LABEL_LOAN_REPEAT_LOAN,
  GTM_LABEL_LOGIN,
  GTM_LABEL_SIGN_UP,
} from './gtmLabel';
import { ServerFieldErrorsHandler } from '../serverFieldErrorsHandler';
import { EventName, findFormNameByField } from './findFormNameByField';
import { LOAN_STATE } from 'constantsLk';

interface StoreAction {
  type: string;
  payload: any;
  meta: {
    arg: any;
    requestId: string;
    requestStatus: string;
  };
}

function checkFirstLoan(loans: Loan[] | null) {
  if (!loans) {
    return true;
  } else {
    return loans?.every((loan) => loan.state !== LOAN_STATE.CLOSED);
  }
}

export const get_ga_SessionId = () => {
  let match = document.cookie.match('(?:^|;)\\s*_ga_VFKM1J20EE=([^;]*)');
  return match ? match[1].split('.')[2] : null;
};

export const get_ga_ClientId = () => {
  const clientId = getCookie('_ga');
  const clientIdSplit = clientId && clientId.split('.');
  return clientIdSplit ? `${clientIdSplit[2]}.${clientIdSplit[3]}` : null;
};

export const sendActionToGtm = (action: StoreAction, store: Store): void => {
  if (!action.meta) {
    return;
  }

  switch (action.meta.requestStatus) {
    case 'fulfilled':
      _sendSuccessActionToGtm(action, store);
      break;
    case 'rejected':
      _sendRejectedActionToGtm(action, store);
      break;
    default:
    // TODO log event?
  }
};

const _sendSuccessActionToGtm = (action: StoreAction, store: Store): void => {
  const UID = getUserID();

  switch (action.type) {
    case `${Actions.UPDATE_USER}/fulfilled`:
    case `${Actions.CREATE_USER}/fulfilled`: {
      for (const key in action.meta.arg) {
        if (action.payload?.user?.id) {
          setUserID(action.payload?.user?.id);
        }
        const event = findFormNameByField(key);
        if (event) return sendDataLayer(event, GTM_LABEL_SIGN_UP);
      }
      break;
    }
    case `${Actions.SEND_CONFIRMATION_CODE}/fulfilled`: {
      sendDataLayer(EventName.phone_confirmation, GTM_LABEL_SIGN_UP, UID, { type: 'sms_code' });
      break;
    }
    case `${Actions.UPLOAD_FILE_BY_TYPE}/fulfilled`: {
      if (action.meta.arg.type === DocumentsType.Selfie) {
        sendDataLayer(EventName.upload_doc_selfie, GTM_LABEL_SIGN_UP, UID);
      }
      break;
    }
    case `${Actions.CREATE_APPLICATION}/fulfilled`: {
      const bank = action.payload?.bank_name;
      const state = store.getState() as RootState;
      const loans = state.loanReducer.loans;
      const isFirstLoan = checkFirstLoan(loans);
      const isFirstApplication = action.payload?.request_number === 1;
      const applicationId = action.payload?.id;

      if (isFirstApplication) {
        sendDataLayer(EventName.confirmation_conditions, GTM_LABEL_SIGN_UP, UID, {
          type: bank,
          application_id: applicationId,
        });
      }
      if (isFirstLoan && !isFirstApplication) {
        sendDataLayer(EventName.confirmation_conditions, GTM_LABEL_LOAN_REPEAT_APPLICATION, UID, {
          type: bank,
          application_id: applicationId,
        });
      }
      if (!isFirstLoan) {
        sendDataLayer(EventName.confirmation_conditions, GTM_LABEL_LOAN_REPEAT_LOAN, UID, {
          type: bank,
          application_id: applicationId,
        });
      }
      break;
    }

    case `${Actions.CONFIRM_AGREE_CODE}/fulfilled`: {
      const state = store.getState() as RootState;
      const loans = state.loanReducer.loans;
      const isFirstLoan = checkFirstLoan(loans);
      const isFirstApplication =
        !state.applicationReducer.applications ||
        state.applicationReducer.applications.length === 0;
      const applicationId = state.applicationReducer.activeApplication?.id;

      if (isFirstLoan && isFirstApplication) {
        sendDataLayer(EventName.sign_contract, GTM_LABEL_SIGN_UP, UID, {
          application_id: applicationId,
        });
      }
      if (!isFirstApplication && isFirstLoan) {
        sendDataLayer(EventName.sign_contract, GTM_LABEL_LOAN_REPEAT_APPLICATION, UID, {
          application_id: applicationId,
        });
      }
      if (!isFirstLoan) {
        sendDataLayer(EventName.sign_contract, GTM_LABEL_LOAN_REPEAT_LOAN, UID, {
          application_id: applicationId,
        });
      }
      break;
    }

    //login
    case `${Actions.SEND_OTP_PASSWORD}/fulfilled`: {
      sendDataLayer(EventName.phone_number, GTM_LABEL_LOGIN, UID);
      break;
    }
    case `${Actions.OTP_LOGIN}/fulfilled`: {
      sendDataLayer(EventName.phone_confirmation, GTM_LABEL_LOGIN, action.payload?.user?.id, {
        type: 'sms_code',
      });
      break;
    }
    default:
      break;
  }
};

const _sendRejectedActionToGtm = (action: StoreAction, store: Store): void => {
  const UID = getUserID();

  switch (action.type) {
    case `${Actions.OTP_LOGIN}/rejected`: {
      const errorMessage = action.payload.message;
      sendDataLayerError(
        GTM_LABEL_LOGIN,
        UID,
        EventName.phone_confirmation,
        PhoneConfirmFormFields.Code,
        errorMessage,
      );
      return;
    }
    case `${Actions.SEND_CONFIRMATION_CODE}/rejected`: {
      const errorMessage = action.payload.message;
      sendDataLayerError(
        GTM_LABEL_SIGN_UP,
        UID,
        EventName.phone_confirmation,
        PhoneConfirmFormFields.Code,
        errorMessage,
      );
      break;
    }

    case `${Actions.UPLOAD_FILE_BY_TYPE}/rejected`: {
      const errorMessage = action.payload?.message;
      if (action.meta.arg.type === DocumentsType.Selfie) {
        sendDataLayerError(
          GTM_LABEL_SIGN_UP,
          UID,
          EventName.upload_doc_selfie,
          DocumentsType.Selfie,
          errorMessage,
        );
      }
      break;
    }

    case `${Actions.UPDATE_USER}/rejected`: {
      const dataLayerFieldErrorHandler = (fieldName: string, { message }: { message: string }) => {
        for (const key in action.meta.arg) {
          const event = findFormNameByField(key);
          if (event) return sendDataLayerError(GTM_LABEL_SIGN_UP, UID, event, fieldName, message);
        }
      };
      ServerFieldErrorsHandler(action.payload, dataLayerFieldErrorHandler);

      break;
    }
    case `${Actions.CREATE_APPLICATION}/rejected`: {
      const state = store.getState() as RootState;
      const errorMessage = action.payload.message;

      const isFirstApplication =
        !state.applicationReducer.applications ||
        state.applicationReducer.applications.length === 0;

      if (isFirstApplication) {
        sendDataLayerError(
          GTM_LABEL_SIGN_UP,
          UID,
          EventName.confirmation_conditions,
          '',
          errorMessage,
        );
      } else {
        sendDataLayerError(
          GTM_LABEL_LOAN_REPEAT_APPLICATION,
          UID,
          EventName.confirmation_conditions,
          '',
          errorMessage,
        );
      }

      break;
    }

    case `${Actions.CONFIRM_AGREE_CODE}/rejected`: {
      const state = store.getState() as RootState;
      const loans = state.loanReducer.loans;
      const isFirstLoan = checkFirstLoan(loans);
      const errorMessage = action.payload.message;
      const isFirstApplication =
        !state.applicationReducer.applications ||
        state.applicationReducer.applications.length === 0;

      if (isFirstLoan && isFirstApplication) {
        sendDataLayerError(
          GTM_LABEL_SIGN_UP,
          UID,
          EventName.sign_contract,
          LoanSmsConfirmFields.Code,
          errorMessage,
        );
      }
      if (!isFirstApplication && isFirstLoan) {
        sendDataLayerError(
          GTM_LABEL_LOAN_REPEAT_APPLICATION,
          UID,
          EventName.sign_contract,
          LoanSmsConfirmFields.Code,
          errorMessage,
        );
      }
      if (!isFirstLoan) {
        sendDataLayerError(
          GTM_LABEL_LOAN_REPEAT_LOAN,
          UID,
          EventName.sign_contract,
          LoanSmsConfirmFields.Code,
          errorMessage,
        );
      }
      break;
    }

    default:
      break;
  }
};
