import { useEffect } from 'react';

import { useTypedSelector } from 'core/hooks';
import { getUserID, removeCookie, setCookie } from 'core/services';
import { WpKeys } from 'core/models';
import { setRedirect } from 'core/store/actions';

import { AccountLoanPage } from './AccountLoanPage';
import { useAppDispatch } from 'hooks';
import {
  activeApplicationSelector,
  isAppApprovedApplicationSelector,
  isAppConfirmedApplicationSelector,
  isApplicationPendingSelector,
  isApplicationRejWithMoratoriumSelector,
  isFirstLoanSelector,
  isInBlackListSelector,
  isLoanActiveSelector,
  isLoanPendingSelector,
  isSelectedPrincipalSelector,
  productIdSelector,
} from 'store/selectors';
import { getProduct } from 'store/actions/api';
import { LoanService } from 'services';
import { Spinner } from 'components';
import { KEY_REPEAT_USER, URL_USER_REGISTRATION } from 'constantsLk';

const AccountLoanPageContainer = () => {
  const dispatch = useAppDispatch();
  const productId = useTypedSelector(productIdSelector);
  const activeApplication = useTypedSelector(activeApplicationSelector);
  const isApplicationPending = useTypedSelector(isApplicationPendingSelector);
  const isApplicationRejWithMoratorium = useTypedSelector(isApplicationRejWithMoratoriumSelector);
  const isAppApprovedApplication = useTypedSelector(isAppApprovedApplicationSelector);
  const isAppConfirmedApplication = useTypedSelector(isAppConfirmedApplicationSelector);
  const isSelectedPrincipal = useTypedSelector(isSelectedPrincipalSelector);

  const isLoanPending = useTypedSelector(isLoanPendingSelector);
  const isLoanActive = useTypedSelector(isLoanActiveSelector);
  const isFirstLoan = useTypedSelector(isFirstLoanSelector);

  const isInBlackList = useTypedSelector(isInBlackListSelector);

  useEffect(() => {
    if (productId) {
      const promoCode = LoanService.promoCode;

      const data = {
        productId,
        userId: getUserID(),
        isFirstLoan,
      };

      dispatch(getProduct({ ...data, promoCode }))
        .unwrap()
        .catch(() => {
          removeCookie(WpKeys.LoanPromoCode);
          dispatch(getProduct(data));
        });
    }

    if (!isFirstLoan) {
      setCookie(KEY_REPEAT_USER, true);
    }
  }, [isFirstLoan, productId]);

  if (!activeApplication) {
    dispatch(setRedirect({ to: URL_USER_REGISTRATION }));
  }

  if (!activeApplication) return <Spinner />;

  return (
    <AccountLoanPage
      isApplicationPending={isApplicationPending}
      isLoanPending={isLoanPending}
      isLoanActive={isLoanActive}
      isSelectedPrincipal={isSelectedPrincipal}
      isAppConfirmedApplication={isAppConfirmedApplication}
      isAppApprovedApplication={isAppApprovedApplication}
      isInBlackList={isInBlackList}
      isApplicationRejWithMoratorium={isApplicationRejWithMoratorium}
    />
  );
};

export default AccountLoanPageContainer;
