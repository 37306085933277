import { useTranslation } from 'react-i18next';

import { formatNumber } from 'core/utils/parsers';

import { formatDate } from 'utils/parsers';
import { InfoIcon } from 'components/icons';
import { User } from 'models';
import { COUNTRY_PHONE_CODE } from 'constantsLk';

interface AccountPersonalInformationPageProps {
  schedule?: string;
  phone?: string;
  user: User | null;
}

export const AccountPersonalInformationPage = (props: AccountPersonalInformationPageProps) => {
  const { schedule, phone, user } = props;
  const { t } = useTranslation();

  const userBirthday = user && formatDate(new Date(user.birthday));

  return (
    <div className='w-100'>
      <div className='row align-items-start'>
        <div className='col-12 col-md-6 mb-6'>
          <div className='personal-information card flex-column gap-3'>
            <p className='txt-b txt-md-16 mb-1'>
              {user?.first_name} {user?.last_name}
            </p>
            <div className='lined-card-row'>
              <p className='txt txt-xs txt-sb'>{t`birthday_date_acc`}</p>
              <div className='info-item'>{userBirthday}</div>
            </div>
            <div className='lined-card-row'>
              <p className='txt txt-xs txt-sb'>{t`personal_id_acc`}</p>
              <div className='info-item'>{user?.personal_id_number}</div>
            </div>
            <div className='lined-card-row'>
              <p className='txt txt-xs txt-sb'>{t`phone_number`}</p>
              {user?.phone_number && (
                <div className='info-item'>
                  {`${COUNTRY_PHONE_CODE} ${formatNumber(user?.phone_number)}`}
                </div>
              )}
            </div>
            <div className='lined-card-row flex'>
              <p className='txt txt-xs txt-sb'>{t`address`}</p>
              <div className='address info-item txt-right gap-0 w-100'>
                {`
                  ${user?.residence_province} ${user?.residence_district}, 
                  ${user?.residence_street} ${user?.residence_house}, 
                  ${user?.residence_locality} ${user?.residence_flat ? user?.residence_flat : ''}
                `}
              </div>
            </div>
          </div>
        </div>
        <div className='col-12 col-md-6 mb-6'>
          <div className='alerts-item__info w-auto flex-grow-1'>
            <div className='alerts-item__icon-wrapper'>
              <InfoIcon />
            </div>
            <div className='flex-grow-1 flex-column gap-2'>
              <p className='txt-sb'>{t`change_information`}</p>
              <span>{t`to_change_you_info`}</span>
              <p>{schedule}</p>
              <a href={`tel: ${phone?.replace(/\s/g, '')}`} className='btn btn--primary mt-2 w-100'>
                <span className='txt txt-sb txt-white'>
                  {t`call`} {phone}
                </span>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
