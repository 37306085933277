import { EmploymentType } from 'constantsLk';

//step1
export enum phoneFormFields {
  Phone = 'phone_number',
}

export interface PersonalDataFormModel {
  [phoneFormFields.Phone]: string;
}

export enum step1FormFields {
  UserName = 'username',
}

//step2
export enum PhoneConfirmFormFields {
  Code = 'confirmation_code',
  AgreementTermsConditions = 'is_data_processing_consent_given',
}

//step3
export enum PersonalDataFormFields {
  Name = 'first_name',
  LastName = 'last_name',
  PersonalIdType = 'type_personal_id',
  PersonalIdNumNew = 'personal_id_number_new',
  PersonalIdNumberOld = 'personal_id_number_old',
  PersonalIdNumberOldSerial = 'personal_id_number_old_serial',
  PersonalIdNum = 'personal_id_number',
  Gender = 'gender',
  DateOfBirth = 'birthday',
}

export interface PersonalDataFormModel {
  [PersonalDataFormFields.Name]: string;
  [PersonalDataFormFields.LastName]: string;
  [PersonalDataFormFields.PersonalIdType]?: string;
  [PersonalDataFormFields.PersonalIdNumNew]?: string;
  [PersonalDataFormFields.PersonalIdNumberOld]?: string;
  [PersonalDataFormFields.PersonalIdNumberOldSerial]?: string;
  [PersonalDataFormFields.PersonalIdNum]: string;
  [PersonalDataFormFields.DateOfBirth]: Date | string;
  [PersonalDataFormFields.Gender]: string;
}

//step4
export enum AddressFormFields {
  Province = 'residence_province',
  District = 'residence_district',
  Locality = 'residence_locality',
  Street = 'residence_street',
  House = 'residence_house',
  Flat = 'residence_flat',
  Duration = 'residence_duration',
}

export interface AddressFormModel {
  [AddressFormFields.Province]: string;
  [AddressFormFields.District]: string | number;
  [AddressFormFields.Locality]: string;
  [AddressFormFields.Street]: string;
  [AddressFormFields.House]: string;
  [AddressFormFields.Flat]?: string;
  [AddressFormFields.Duration]: string;
}

//step5
export enum AdditionalDataFields {
  LoanPurpose = 'loan_purpose',
  ExternalLoan = 'has_external_loan',
  ExternalLoanMonthlyPayment = 'external_loan_monthly_payments',
  Education = 'education',
  EmploymentType = 'employment_type',
  SocialSecurityNumber = 'employment_social_security_number',
  Industry = 'employment_industry',
  Position = 'employment_position',
  CustomPosition = 'employment_custom_position',
  Duration = 'employment_duration',
  CompanyName = 'employment_company_name',
  CompanyPhone = 'employment_company_phone_number',
  MainSourceIncome = 'main_source_of_income',
  MonthlyIncome = 'monthly_income',
}

export interface AdditionalDataFieldsFormModel {
  [AdditionalDataFields.LoanPurpose]: string;
  [AdditionalDataFields.ExternalLoan]: string | boolean;
  [AdditionalDataFields.ExternalLoanMonthlyPayment]: number;
  [AdditionalDataFields.Education]: string;
  [AdditionalDataFields.EmploymentType]: EmploymentType;
  [AdditionalDataFields.SocialSecurityNumber]: string;
  [AdditionalDataFields.Industry]: string;
  [AdditionalDataFields.Position]: string;
  [AdditionalDataFields.CustomPosition]?: string;
  [AdditionalDataFields.Duration]: number;
  [AdditionalDataFields.CompanyName]: string;
  [AdditionalDataFields.CompanyPhone]: string;
  [AdditionalDataFields.MainSourceIncome]: string;
  [AdditionalDataFields.MonthlyIncome]: number;
}

//step6
export enum MartialRelativesFields {
  MaritalStatus = 'marital_status',
  NumberChildren = 'number_of_children',
  NativeLanguage = 'native_language',
  ContactName = 'contact_name',
  ContactRelationship = 'contact_relationship',
  ContactPhone = 'contact_phone_number',
}

export interface MartialRelativesFieldsFormModel {
  [MartialRelativesFields.MaritalStatus]: string;
  [MartialRelativesFields.NumberChildren]: string;
  [MartialRelativesFields.NativeLanguage]: string;
  [MartialRelativesFields.ContactName]: string;
  [MartialRelativesFields.ContactRelationship]: string;
  [MartialRelativesFields.ContactPhone]: string;
}

//step 8
export enum IbanNumberFields {
  Iban = 'bank_account_number',
  BankName = 'bank_name',
  BankBranchName = 'bank_branch_name',
  Email = 'email',
  EmailDomain = 'email_domain',
  Confirm = 'confirm',
}

export interface EmailFormModel {
  [IbanNumberFields.Email]: string | null;
  [IbanNumberFields.EmailDomain]?: string;
}

export interface IbanNumberFormModel {
  [IbanNumberFields.Iban]: string;
  [IbanNumberFields.BankName]: string;
  [IbanNumberFields.BankBranchName]: string | null;
  [IbanNumberFields.Email]: string | null;
  [IbanNumberFields.EmailDomain]?: string;
  [IbanNumberFields.Confirm]: boolean;
}

export interface AccountBankDetailsModel {
  [IbanNumberFields.Iban]: string;
  [IbanNumberFields.BankName]: string;
  [IbanNumberFields.BankBranchName]: string | null;
}

export enum LoanCalculatorFields {
  Amount = 'amount',
  Term = 'term',
}

export interface NewOfferModel {
  principal: number | null;
}

export enum NewOfferField {
  Principal = 'principal',
}

export interface TopUpCounterOfferModel {
  amount: number;
}

export enum TopUpCounterOfferField {
  Amount = 'amount',
}

export interface PaymentSlipModel {
  file: File | null;
  bank_id: number | null;
}

export enum PaymentSlipField {
  File = 'file',
  Bank = 'bank_id',
}

export interface LoanCalculatorForm {
  [LoanCalculatorFields.Term]: number;
  [LoanCalculatorFields.Amount]: number;
}

export enum LoanSmsConfirmFields {
  Code = 'confirmation_code',
}

export interface LoanSmsConfirmFormModel {
  [LoanSmsConfirmFields.Code]: number;
}

export enum CreditBureauReportFields {
  Link = 'report_link',
  Password = 'report_password',
}

export interface CreditBureauReportFormModel {
  [CreditBureauReportFields.Link]: string;
  [CreditBureauReportFields.Password]: string;
}

export enum FieldRoles {
  IbanNew,
  IbanOld,
  PhoneNumber,
  PhoneNumberConfirmation,
  PostalCode,
  Province,
  District,
  Locality,
  City,
  Street,
  House,
  Flat,
  Duration,
  Apartment,
  FirstName,
  LastName,
  IncomeAmount,
  FirstSurname,
  SecondSurname,
  PersonalId,
  Email,
  TermCondAgree,
  Iban,
  PersonalIdType,
  PersonalIdTypeOld,
  PersonalIdDate,
  Gender,
  BankName,
  BankBranchName,
  ExternalLoan,
}
