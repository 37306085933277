import { useTranslation } from 'react-i18next';

import { AppNavLinkContainer } from 'core/components/common';

import { URL_PATH_LOGIN } from 'constantsLk';

export const NotFoundPage = () => {
  const { t } = useTranslation();
  return (
    <div className='justify-content-center'>
      <div className='form__wrapper gap-6 flex-column flex-grow-1'>
        <h1 className='gradient-title'>404</h1>
        <div className='txt txt-r txt-center'>{t`not_found_text`}</div>
        <AppNavLinkContainer className='btn btn--primary' to={URL_PATH_LOGIN}>
          <span>{t`back_to_main`}</span>
        </AppNavLinkContainer>
      </div>
    </div>
  );
};
