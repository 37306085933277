import { MAX_USER_AGE, MIN_USER_AGE } from 'constantsLk';

export const generateYearOptions = () => {
  const currentYear = new Date().getFullYear();
  const minYear = currentYear - MAX_USER_AGE;
  const maxYear = currentYear - MIN_USER_AGE;

  return Array.from({ length: maxYear - minYear + 1 }, (_, index) => {
    const year = (maxYear - index).toString();
    return { value: year, label: year };
  });
};
