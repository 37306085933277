import { useEffect, useState } from 'react';

export enum UseScriptStatus {
  Idle = 'idle',
  Loading = 'loading',
  Ready = 'ready',
  Error = 'error',
}

export interface UseScriptOptions {
  shouldPreventLoad?: boolean;
  removeOnUnmount?: boolean;
}

const cache: Record<string, UseScriptStatus | undefined> = {};

function getScriptNode(src: string) {
  const node: HTMLScriptElement | null = document.querySelector(
    `script[src="${src}"]`,
  );

  const status = node?.getAttribute('data-status') as UseScriptStatus | undefined;

  return {
    node,
    status,
  };
}


export const useScript = (src: string | null, options?: UseScriptOptions): UseScriptStatus => {
  const [status, setStatus] = useState<UseScriptStatus>(() => {
    if (!src || options?.shouldPreventLoad) {
      return UseScriptStatus.Idle;
    }

    return cache[src] ?? UseScriptStatus.Loading;
  });


  useEffect(() => {
    if (!src || options?.shouldPreventLoad) {
      return;
    }

    const cachedScriptStatus = cache[src];

    if (cachedScriptStatus === UseScriptStatus.Ready || cachedScriptStatus === UseScriptStatus.Error) {
      setStatus(cachedScriptStatus);

      return;
    }

    const script = getScriptNode(src);
    let scriptNode = script.node;

    if (!scriptNode) {
      scriptNode = document.createElement('script');
      scriptNode.src = src;
      scriptNode.async = true;
      scriptNode.setAttribute('data-status', 'loading');
      document.body.appendChild(scriptNode);

      const setAttributeFromEvent = (event: Event) => {
        const scriptStatus: UseScriptStatus = event.type === 'load' ? UseScriptStatus.Ready : UseScriptStatus.Error;

        scriptNode?.setAttribute('data-status', scriptStatus);
      };

      scriptNode.addEventListener('load', setAttributeFromEvent);
      scriptNode.addEventListener('error', setAttributeFromEvent);
    } else {
      setStatus(script.status ?? cachedScriptStatus ?? UseScriptStatus.Loading);
    }

    const setStateFromEvent = (event: Event) => {
      const newStatus = event.type === 'load' ? UseScriptStatus.Ready : UseScriptStatus.Error;

      setStatus(newStatus);

      cache[src] = newStatus;
    };

    scriptNode.addEventListener('load', setStateFromEvent);
    scriptNode.addEventListener('error', setStateFromEvent);

    return () => {
      if (scriptNode) {
        scriptNode.removeEventListener('load', setStateFromEvent);
        scriptNode.removeEventListener('error', setStateFromEvent);
      }

      if (scriptNode && options?.removeOnUnmount) {
        scriptNode.remove();
      }
    };

  }, [src, options?.shouldPreventLoad, options?.removeOnUnmount]);

  return status;
};