import React, { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Button, ModalLayout } from 'core/components/common';
import { setClickedButton } from 'store/slices';
import { useAppDispatch } from 'hooks';

export const GrantAccessCameraModal = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [isOpen, seIsOpen] = useState(true);
  const modalHandler = () => {
    seIsOpen(false);
    dispatch(setClickedButton(true));
  };

  return (
    <ModalLayout
      isOpenModal={isOpen}
      title={t`please_grant_access_to_camera`}
      onClose={modalHandler}
      isShowClose
      isUsePortal
      classes={{ wrapper: 'modal-core__wrapper-confirm' }}
      content={
        <p className='txt'>
          <Trans
            i18nKey='you_must_grant_access_to_camera_subtitle'
            components={{
              strong: <span className='txt-b'>{t`it_is_clear`}</span>,
            }}
          />
          {` ${t('dont_worry_your_data_is_secure')}.`}
        </p>
      }
      footer={
        <Button
          onClick={modalHandler}
          classes={{ root: 'btn--primary w-100' }}
        >{t`it_is_clear`}</Button>
      }
    />
  );
};
