import React, { FC, Ref } from 'react';
import { Spinner } from 'components/Spinner';

interface IUploadedPhotoProps {
  src: any;
  onDelete?: (e: React.MouseEvent<any>) => void;
  isPdf?: boolean;
  fileState: string;
  buttonDeleteRef?: Ref<any>;
  onUpload?: (file: File) => void;
  instructionHandler?: () => void;
}

export const UploadedPhoto: FC<IUploadedPhotoProps> = ({ fileState, src, instructionHandler }) => {
  const isLoad = fileState === 'load';

  return (
    <div
      className={`card-secondary-image-wrapper cursor-pointer ${isLoad ? 'pointer-events-none' : ''}`}
      onClick={instructionHandler}
    >
      {isLoad ? <Spinner /> : src}
    </div>
  );
};
