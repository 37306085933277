import { DocumentsType } from '../models';
import {
  AngleIcon,
  AvoidReflectionIcon,
  BackIdImg,
  ContrastIcon,
  FocusAndSharpnessIcon,
  FrontIdImg,
  GoodLightingIcon,
  MarginsIcon,
  ResolutionOfTheImageIcon,
  SubjectionIcon,
  UserFilledIcon,
} from '../components';
import GoodLightingFrontError from '../assets/images/id_card_front_instructions/good-lighting-2.png';
import FocusAndSharpnessFrontError from '../assets/images/id_card_front_instructions/focus-and-sharpness-2.png';
import AngleFrontError from '../assets/images/id_card_front_instructions/angle-2.png';
import AvoidReflectionFrontError from '../assets/images/id_card_front_instructions/avoid-reflection-2.png';
import MarginsTooFarFrontError from '../assets/images/id_card_front_instructions/margins-too-far-2.png';
import MarginsTooCloseFrontError from '../assets/images/id_card_front_instructions/margins-too-close-2.png';
import SubjectionFrontError from '../assets/images/id_card_front_instructions/subjection-2.png';
import ImageResolutionFrontError from '../assets/images/id_card_front_instructions/resolution-of-the-image-2.png';
import ContrastFrontError from '../assets/images/id_card_front_instructions/contrast-2.png';
import GoodLightingBackError from '../assets/images/id_card_back_instructions/good-lighting-2.png';
import FocusAndSharpnessBackError from '../assets/images/id_card_back_instructions/focus-and-sharpness-2.png';
import AngleBackError from '../assets/images/id_card_back_instructions/angle-2.png';
import AvoidReflectionBackError from '../assets/images/id_card_back_instructions/avoid-reflection-2.png';
import MarginsTooFarBackError from '../assets/images/id_card_back_instructions/margins-too-far-2.png';
import MarginsTooCloseBackError from '../assets/images/id_card_back_instructions/margins-too-close-2.png';
import SubjectionBackError from '../assets/images/id_card_back_instructions/subjection-2.png';
import ImageResolutionBackError from '../assets/images/id_card_back_instructions/resolution-of-the-image-2.png';
import ContrastBackError from '../assets/images/id_card_back_instructions/contrast-2.png';
import SuccessFrontImage from '../assets/images/id_card_front_instructions/successPhoto.png';
import SuccessBackImage from '../assets/images/id_card_back_instructions/successPhoto.png';

export const DOCUMENTS = {
  SELFIE: {
    type: DocumentsType.Selfie,
    label: 'selfieDocumentText',
    srcDefault: UserFilledIcon,
    title: '',
    subtitle: '',
    titleWithFile: '',
    infoWithFile: '',
    footerInfo: '',
    instructions: [],
  },
  FRONT: {
    type: DocumentsType.IDCard,
    label: 'selfieDocumentText',
    srcDefault: <FrontIdImg />,
    title: 'nic_front_card_upload_title',
    subtitle: 'nic_front_card_upload_subtitle',
    titleWithFile: 'nic_back_card_upload_description',
    infoWithFile: '',
    instructions: [
      {
        icon: <GoodLightingIcon />,
        name: 'good_lighting',
        success: SuccessFrontImage,
        error: GoodLightingFrontError,
      },
      {
        icon: <FocusAndSharpnessIcon />,
        name: 'focus_and_sharpness',
        success: SuccessFrontImage,
        error: FocusAndSharpnessFrontError,
      },
      {
        icon: <AngleIcon />,
        name: 'angle',
        success: SuccessFrontImage,
        error: AngleFrontError,
      },
      {
        icon: <AvoidReflectionIcon />,
        name: 'avoid_reflection',
        success: SuccessFrontImage,
        error: AvoidReflectionFrontError,
      },
      {
        icon: <MarginsIcon />,
        name: 'margins_too_far',
        success: SuccessFrontImage,
        error: MarginsTooFarFrontError,
      },
      {
        icon: <MarginsIcon />,
        name: 'margins_too_close',
        success: SuccessFrontImage,
        error: MarginsTooCloseFrontError,
      },
      {
        icon: <SubjectionIcon />,
        name: 'subjection',
        success: SuccessFrontImage,
        error: SubjectionFrontError,
      },
      {
        icon: <ResolutionOfTheImageIcon />,
        name: 'resolution_of_the_image',
        success: SuccessFrontImage,
        error: ImageResolutionFrontError,
      },
      {
        icon: <ContrastIcon />,
        name: 'contrast',
        success: SuccessFrontImage,
        error: ContrastFrontError,
      },
    ],
  },
  BACK: {
    type: DocumentsType.IDCardBackSide,
    label: 'selfieDocumentText',
    srcDefault: <BackIdImg />,
    title: 'nic_back_card_upload_title',
    subtitle: 'nic_back_card_upload_subtitle',
    titleWithFile: 'nic_back_card_upload_description',
    infoWithFile: '',
    instructions: [
      {
        icon: <GoodLightingIcon />,
        name: 'good_lighting',
        success: SuccessBackImage,
        error: GoodLightingBackError,
      },
      {
        icon: <FocusAndSharpnessIcon />,
        name: 'focus_and_sharpness',
        success: SuccessBackImage,
        error: FocusAndSharpnessBackError,
      },
      {
        icon: <AngleIcon />,
        name: 'angle',
        success: SuccessBackImage,
        error: AngleBackError,
      },
      {
        icon: <AvoidReflectionIcon />,
        name: 'avoid_reflection',
        success: SuccessBackImage,
        error: AvoidReflectionBackError,
      },
      {
        icon: <MarginsIcon />,
        name: 'margins_too_far',
        success: SuccessBackImage,
        error: MarginsTooFarBackError,
      },
      {
        icon: <MarginsIcon />,
        name: 'margins_too_close',
        success: SuccessBackImage,
        error: MarginsTooCloseBackError,
      },
      {
        icon: <SubjectionIcon />,
        name: 'subjection',
        success: SuccessBackImage,
        error: SubjectionBackError,
      },
      {
        icon: <ResolutionOfTheImageIcon />,
        name: 'resolution_of_the_image',
        success: SuccessBackImage,
        error: ImageResolutionBackError,
      },
      {
        icon: <ContrastIcon />,
        name: 'contrast',
        success: SuccessBackImage,
        error: ContrastBackError,
      },
    ],
  },
};
