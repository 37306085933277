import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { PaymentCard } from './PaymentCard';

interface Income {
  id: string | number;
  amount: number;
  bank_name: string;
  loan_id: number;
  received_at: string;
}

interface AccountPaymentHistoryProps {
  period: string;
  periodPayments: Income[] | null;
}

export const AccountPaymentHistoryPage = (props: AccountPaymentHistoryProps) => {
  const { period, periodPayments } = props;
  const { t } = useTranslation();

  const periodDate = useMemo(() => {
    let month;
    let year;
    const periodArr = period.split('_');

    month = periodArr[0].toLowerCase();
    year = periodArr[1];

    return { month, year };
  }, [period]);

  return (
    <div className='flex-column w-100'>
      <p className='txt txt-b txt-md-16 mb-4'>{`${t(periodDate.month)}, ${periodDate.year}`}</p>

      <div className='payment-history__wrap'>
        {periodPayments?.map((income: Income) => (
          <PaymentCard key={income.id} income={income} />
        ))}
      </div>
    </div>
  );
};
