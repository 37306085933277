import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ModalLayout } from 'core/components/common';
import { SuccessIcon } from '../../icons';

export const EmailConfirmationModal = () => {
  const { t } = useTranslation();
  const [isOpenModal, setIsOpenModal] = useState(true);

  const onClose = () => setIsOpenModal(false);

  return (
    <ModalLayout
      isOpenModal={isOpenModal}
      onClose={onClose}
      title={<SuccessIcon />}
      classes={{ wrapper: 'modal-core__wrapper--email-verified' }}
      isShowClose
      isUsePortal
      content={<p className='txt-md txt-sb mt-1'>{t`email_is_verified`}</p>}
    />
  );
};
