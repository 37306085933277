import { useTranslation } from 'react-i18next';

import { WpMenu } from 'core/models';
import { AppNavLinkContainer, Button, LanguageSwitcherContainer } from 'core/components/common';

import { User } from 'models';
import { BarIcon, LoginIcon } from 'components/icons';
import { Logo, UserName } from 'components';
import * as Paths from 'constantsLk/paths';
import { DEFAULT_LANGUAGE, LANGUAGES } from 'constantsLk';

export interface HeaderProps {
  headerMenu: WpMenu[] | undefined;
  phone?: string;
  user: User | null;
  onBarExpand?: () => void;
  onClickLogo: () => void;
  isAccount: boolean;
  isShowInstallAppButton: boolean;
}

export interface HeaderMenuItem {
  title: string;
  url: string;
}

export const Header = (props: HeaderProps) => {
  const { headerMenu, phone, onBarExpand, user, onClickLogo, isAccount, isShowInstallAppButton } =
    props;
  const { t } = useTranslation();

  const isLoginVisible = false;

  return (
    <header className={`header ${isShowInstallAppButton && 'show-install-app-btn'}`}>
      <div className='container align-items-center justify-content-between gap-4'>
        <Logo onClickLogo={onClickLogo} />
        {isAccount && (
          <nav role='navigation' className='navigation flex-grow-1'>
            {headerMenu?.map((item: HeaderMenuItem) => (
              <a key={item.url} href={item.url}>
                {item.title}
              </a>
            ))}
          </nav>
        )}

        <div className='align-items-center gap-4'>
          <a className={isAccount ? 'hidden-mobile phone' : 'phone'} href={`tel:${phone}`}>
            {phone}
          </a>

          {isAccount && <UserName user={user} />}

          {isLoginVisible && (
            <AppNavLinkContainer className='pill-default' to={Paths.URL_PATH_LOGIN} replace={true}>
              <>
                <LoginIcon />
                {t('log_in')}
              </>
            </AppNavLinkContainer>
          )}

          {isAccount && (
            <Button className='btn--bar' onClick={onBarExpand}>
              <BarIcon />
            </Button>
          )}

          <div className={`${isAccount ? 'hidden-mobile' : ''}`}>
            <LanguageSwitcherContainer
              languageOptions={LANGUAGES}
              excludeLanguage={DEFAULT_LANGUAGE.value}
              isUseLocationLanguage
            />
          </div>
        </div>
      </div>
    </header>
  );
};
