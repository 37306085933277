import { useEffect } from 'react';
import { getUserID, setStorageHyperSnap } from 'core/services';
import { initHyperSnap, runLiveModal } from 'utils';
import { IPhotoUploadInitStateProps } from '../UploadPhotoComponent';
import { DocumentsType } from 'models';
import { useAppDispatch } from 'hooks';
import { getTokenHyperSnap } from 'store/actions/api';
import { livenessProps } from './LivenessEngines';
import { LIVENESS } from 'constantsLk';

export const HyperVerge = (props: livenessProps) => {
  const {
    isShowSelfieInstructionsDialog,
    onClose,
    defaultUploadFileState,
    documentFile,
    sendLog,
    setStateComponent,
    setValue,
    onSubmitHandler,
  } = props;

  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getTokenHyperSnap())
      .unwrap()
      .then((res) => {
        setStorageHyperSnap(res.token);
        initHyperSnap(getUserID());
      });
  }, []);

  const runFaceDetectorLibrary = () => {
    onClose();

    runLiveModal(
      (res) => {
        const { imgBase64 } = res;
        const isUploadedFileImage = imgBase64.includes('image');

        const data: IPhotoUploadInitStateProps = {
          ...defaultUploadFileState,
          state: 'done',
          file: isUploadedFileImage ? imgBase64 : null,
          file_type: documentFile.type,
        };
        setStateComponent(data);
        setValue(DocumentsType.Selfie, imgBase64);
        sendLog(res, LIVENESS.HYPERVERGE)
          .then((res) => onSubmitHandler(res.id)())
          .catch(() => onSubmitHandler()());
      },
      (error) => {
        sendLog(error, LIVENESS.HYPERVERGE);
        const errorCode = +error.getErrorCode();

        switch (errorCode) {
          case 401: {
            dispatch(getTokenHyperSnap())
              .unwrap()
              .then((res) => {
                setStorageHyperSnap(res.token);
                initHyperSnap(getUserID());
              });

            const data: IPhotoUploadInitStateProps = {
              ...defaultUploadFileState,
              error: 'please_make_new_selfie',
            };
            setStateComponent(data);
            break;
          }
          case 423: {
            const data: IPhotoUploadInitStateProps = {
              ...defaultUploadFileState,
              error: 'please_make_new_selfie',
            };
            setStateComponent(data);
            break;
          }

          case 438: {
            const data: IPhotoUploadInitStateProps = {
              ...defaultUploadFileState,
              error: 'please_make_clear_selfie',
            };
            setStateComponent(data);
            break;
          }

          default: {
            // errorCode === 13
            //   ? setStateUpload(prev => ({ ...prev, blocked: false }))
            //   : setCommonError(t.error_hyper_snap);
            const data: IPhotoUploadInitStateProps = {
              ...defaultUploadFileState,
              error: 'error_hyper_snap',
            };
            setStateComponent(data);
          }
        }
      },
    );
  };

  useEffect(() => {
    if (isShowSelfieInstructionsDialog) {
      runFaceDetectorLibrary();
    }
  }, [isShowSelfieInstructionsDialog]);

  return null;
};
