import { useTranslation } from 'react-i18next';
import { ModalLayout } from 'core/components/common';

export const ExtendedLoanModal = ({
  isOpen,
  onClose,
}: {
  isOpen: boolean;
  onClose: () => void;
}) => {
  const { t } = useTranslation();

  return (
    <ModalLayout
      isOpenModal={isOpen}
      onClose={onClose}
      isOutSideClick
      title={t`successfully_extended_loan`}
      classes={{ wrapper: 'modal-core__wrapper' }}
      isShowClose
      isUsePortal
      content={<p className='txt'>{t`remove_the_previous_reminder`}</p>}
    />
  );
};
