import { configureStore, isPending } from '@reduxjs/toolkit';

import { coreSlice } from 'core/store/slices';
import { applicationSlice, authSlice, layoutSlice, productsSlice, userSlice, dictionarySlice } from './slices';
import { loanSlice } from './slices/loanSlice';
import { sendActionToGtm } from 'utils/analitics';

const ignoredActions = ['core/progressEnd', 'core/progressStart'];

const GTMStatisticMiddleware = (store: any) => (next: any) => (action: any) => {
  if (isPending(action)) {
    return;
  }
  const result = next(action);

  if (!ignoredActions.includes(action.type)) {
    sendActionToGtm(action, store);
  }

  return result;
};

export const store = configureStore({
  reducer: {
    coreReducer: coreSlice.reducer,
    userReducer: userSlice.reducer,
    layoutReducer: layoutSlice.reducer,
    authReducer: authSlice.reducer,
    pricesReducer: productsSlice.reducer,
    applicationReducer: applicationSlice.reducer,
    loanReducer: loanSlice.reducer,
    dictionary: dictionarySlice.reducer,
    // loanReducer: loanSlice.reducer,
    // loansReducer: loansSlice.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }).concat(GTMStatisticMiddleware),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
// export type RootState = ReturnType<typeof store.getState> & typeof appSlices;
