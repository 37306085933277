import { useTranslation } from 'react-i18next';
import { CloseIcon } from 'core/components/common';
import { MoneyIcon } from '../icons';

export const TopUpSuccessfulStatus = ({ handleCloseTopUp }: { handleCloseTopUp: () => void }) => {
  const { t } = useTranslation();

  return (
    <div className='card'>
      <div className='justify-content-between align-items-start gap-3 w-100'>
        <div className='mb-4 icon'>
          <MoneyIcon />
        </div>
        <span className='cursor-pointer align-items-center' onClick={handleCloseTopUp}>
          <CloseIcon />
        </span>
      </div>
      <h2 className='txt-md txt-sb mb-2'>{t`we_sending_money_your_account_number_right_now`}</h2>
      <div className='txt'>{t`within_15_minutes`}</div>
    </div>
  );
};
