// eslint-disable
export const translationsEN = {
  title: 'Quick Loans Online, Easy Money 24/7 - CashX',
  description:
    'Easy online cash loans on the Internet. ' +
    'Quick loan for everyone from online loan service Cash X.',
  title_login: 'Login on CashX. Quick Loans Online',
  description_login:
    'Login and get a fast cash loan online with CashX.' +
    ' Low interest, fast approval in the Sri Lanka.',
  title_step1: 'Register on CashX. Quick Loans Online',
  description_step1:
    'Register and get ' +
    'easy and quick loan online with CashX if you need fast loan  in the Sri Lanka.',
  loan_amount: 'Loan amount',
  payment_date: 'Payment date',
  payment_date_tooltip: 'The loan repayment date will be generated after your loan is activated',
  fees: 'Fees',
  settlement_amount: 'Settlement amount',
  active_loan_amount: 'Active loan amount',
  additional_amount: 'Additional amount',
  log_in: 'Log in',
  language: 'Language',
  reference_information: 'Reference Information',
  apply_for_loan: 'Apply for a loan',
  cancel: 'Cancel',
  already_you_already_registered: 'Are you already registered?',
  contact_details: 'Contact details',
  continue: 'Continue',
  sign_the_agreement: 'Sign the agreement',
  phone_number: 'Phone number',
  duration_work_err_first_0: 'This value should be between 1 and 999',
  phone_number_err_first_0: 'The phone number cannot start with the digit 0',
  phone_number_err_first_7: 'The phone number should start with the digit 7',
  phone_number_err_count_num_11: 'Phone number should consist of 11 digits, including country code',
  field_err_required: 'This field should be filled up.',
  phone_verification: 'Phone verification',
  sent_you_sms_code: 'We sent you an SMS with the code to',
  please_enter_code: 'Please enter the code in the inputs below',
  resend_code: 'Resend code',
  re_sending_code: 'Send code again in {time}',
  sms_code: 'SMS code',
  sms_code_required: 'Fill in the input with the code, please',
  min_length_field: 'Invalid code',
  field_min_length_error: 'This field should be at least 2 characters long',
  field_min_length3_error: 'This field should be at least 3 characters long',
  email_min_length3_error: 'Email address cannot contain less than 3 characters before @',
  email_err_www_prefix:
    'Email address cannot contain less than 3 characters before @ (except for www.)',
  field_min_length3_max_length20_error:
    'The length of account number should be from 3 to 20 digits',
  this_code_is_incorrect: 'Incorrect code',
  is_data_processing_consent_given: 'I agree with <a>terms conditions</a>',
  terms_conditions: 'Terms & Conditions',
  is_data_processing_consent_given_com: 'I agree with <a>Terms & Conditions</a>',
  checkbox_err_required: 'To continue activate the checkbox.',
  entered_invalid_password_call_center:
    "Oops, something's wrong with your OTP code, please contact our customer centre on {phone} and we will take care of it!",
  exceeded_code_request_limit: 'You have exceeded the code request limit',
  sending_code_loading: 'loading',
  liveness_loading: 'Loading. Please wait...',
  liveness_loading_error: 'Please reload the page and try again.',
  didnt_get_an_sms_code_yet:
    'Didn`t get an SMS code yet? Call from your number {user_phone} to our ' +
    'customer care line {wp_phone}',
  personal_data: 'Personal data',
  first_name: 'First name (in English)',
  field_err_not_en: 'The name should be in English',
  company_name_err_not_en: 'The company name should be in English',
  job_position_err_not_en: 'Job position should be in English',
  email_err_not_en: 'The email should be in English',
  field_err_punctuation_marks: 'The name should contain only English letters',
  last_name: 'Last name (in English)',
  personal_id_issue_date: 'Date of NIC issue (yyyy.mm.dd)',
  field_date_err_validate: 'Enter the date in "yyyy.mm.dd" format.',
  gender: 'Gender',
  male: 'Male',
  female: 'Female',
  birthday: 'Birthday date (yyyy.mm.dd)',
  birthday_date: 'Birthday date',
  not_email: "I don't have an email",
  email: 'Email',
  field_email_err_validate: 'Your e-mail is not valid. Try again, please.',
  email_cannot_be_applied: 'This email address cannot be applied',
  personal_id_number: 'Personal ID (NIC number)',
  personal_id_number_old_type: 'old type',
  personal_id_number_old_err_validate: 'NIC should contain 9 digits.',
  personal_id_number_new_type: 'new type',
  personal_id_number_new_err_validate: 'NIC should contain 12 digits.',
  address: 'Address',
  residence_address: 'Residence address',
  residence_province: 'Province',
  field_select_err_required: 'Choose an option.',
  residence_district: 'District',
  residence_locality: 'City or locality',
  residence_street: 'Street',
  residence_house: 'House',
  residence_flat: 'Flat (optional)',
  residence_duration: 'Residence time at current address',
  placeholder_select_default: 'Choose an option',
  additional_details: 'Additional details',
  loan_purpose: 'Loan purpose',
  has_external_loan: 'Any current external loans?',
  yes: 'Yes',
  no: 'No',
  yes2: 'Yes, I do',
  no2: 'No, I don’t',
  external_loan_monthly_payments: 'Current payment monthly',
  education: 'Education level',
  education_err_required: 'Choose one of the list items.',
  employment_type: 'Employment type',
  employment_social_security_number: 'Write down your EPF or EMP number (optional)',
  employment_industry: 'Working industry',
  employment_position: 'Job position/ designation',
  custom_job_position: 'Write down your job position',
  employment_duration: 'Duration of work in this company',
  employment_company_name: 'Company name',
  employment_company_phone_number: 'Company phone',
  phone_should_consist_11_digits:
    'Phone number should consist of 11 digits, including country code',
  fixed_line_required: 'A fixed line is required.',
  phone_should_consist_10_digits: 'Phone number should consist of 10 digits, including 0',
  main_source_of_income: 'Main source of income',
  monthly_income: 'Monthly salary or income (before deduction)',
  marital_status_contact: 'Marital status and contact person information',
  ensure_faster_credit_processing:
    'To ensure faster credit processing, enter your contact person ' + 'information below',
  additional_contact: 'Your additional contact',
  should_20_60_years: 'Should be person 20-60 years old',
  marital_status_and_contact_info: 'Marital status and contact person information',
  marital_status: 'Marital status',
  number_of_children: 'Number of children',
  native_language: 'Native language (language you speak better)',
  contact_person_hint_text:
    'To ensure faster credit processing, enter your contact person information below',
  contact_name: "Contact person/relative's name (in EN)",
  contact_relationship: 'Relationship',
  contact_phone_number: 'Contact person phone number',
  bank_account_details: 'Your bank account details',
  bank_account_details_modal_subtitle: 'Please change your bank account details',
  account_number_money: 'Please, indicate the account number to which </br> the money will be sent',
  agree_bank:
    "I undertake the accuracy of above Bank account details and confirm that it's my personal account number",
  agree_bank_err_required: 'Mark checkbox to finish the registration.',
  bank_account_number: 'Account Number',
  bank_account_number_err_required: 'Please fill in the field.',
  bank_account_number_err_validate: 'The length of account number should be from 3 to 20 digits',
  bank_branch_name: 'Branch name',
  bank_name: 'Bank name',
  please_select_the_bank: 'Please select the bank',
  confirmation_conditions: 'Confirmation of conditions',
  requested_loan: 'You requested a loan under the following conditions',
  loan_term: 'Loan term',
  days: 'days',
  hours: 'hours',
  min: 'min',
  change_conditions: 'Change loan conditions',
  please_confirm_loan: 'To complete the application proccess, please confirm the loan conditions',
  photographs: 'Document photos',
  upload_photograph: 'To complete the application process, please take photos of your NIC Card',
  upload_selfie: 'To finalize your application process, please take your selfie',
  error_not_access_web_cam: 'Provide access to the camera, please',
  error_not_web_cam: 'Connect your webcam to make a photo, please',
  selfie_card_upload_title: 'Selfie',
  selfie_tips: 'Selfie tips',
  good_lighting_on_your_face: 'Good lighting on your face',
  look_directly_into_the_webcam: 'Look directly into the webcam',
  bright_light: 'Bright light',
  no_glasses: 'No glasses',
  no_hat: 'No hat',
  selfie_card_upload_title_web: 'Take Selfie',
  selfie_card_upload_description:
    'Point the camera in front of you so that you can be seen and take a photo',
  wait_for_loading: 'Wait for loading',
  nic_back_card_upload_title: 'NIC card back',
  nic_back_card_upload_subtitle:
    'To complete the application process, please take a photo of your NIC Card back',
  nic_back_card_upload_title_web: 'Take NIC card back photo',
  nic_back_card_upload_description:
    'Take a photo of the back of the NIC card so that all the data is ' + 'clearly visible',
  nic_front_card_upload_title: 'NIC card front',
  nic_front_card_upload_subtitle:
    'To complete the application process, please take a photo of your NIC Card front',
  nic_front_card_upload_title_web: 'Take NIC card front photo',
  nic_front_card_upload_description:
    'Take a photo of the front of the NIC card so that all the data is ' + 'clearly visible',
  validate_loading: 'Your photo is being validated.',
  retake_photo: 'Retake photo',
  nic_number_used:
    'The user with this Personal ID number was previously registered and we cannot create two account on same ID number, do you have access to phone number +94 { matched_user_phone }?',
  error_validate_nic: 'The photo is of a bad quality. Please, reload the photo',
  please_upload_photo: 'Upload the photos, please.',
  account_number_used: 'This account number is already used. Contact our call center.',
  register_now: 'Register now',
  or_register_now: 'or Register now',
  enter_your_phone: 'We will send you SMS with the code',
  get_the_code: 'Get the code',
  leave_registration: 'Do you really want to leave the registration?',
  processing_personal_data_title: 'Consent to personal data processing',
  processing_personal_data_description:
    'By activating  this checkbox you agree to the personal ' + 'data processing',
  take_another_photo: 'Take another photo',
  error_occurred_please_again: 'An error occurred. Please, try again.',
  take_a_photo: 'Take a photo',
  unsupported_image_type: 'Unsupported image type. Please, try again.',
  log_out: 'Log out',
  personal_information: 'Personal information',
  birthday_date_acc: 'Birthday',
  e_mail_acc: 'E-mail',
  personal_id_acc: 'Personal ID',
  phone_number_acc: 'Phone number',
  address_acc: 'Address',
  change_information: 'Change information',
  to_change_you_info:
    'To change your phone number and other personal data please contact our support service',
  branch: 'Branch',
  personal_account: 'Personal account',
  account: 'Account',
  i_menu_credit: 'Credit',
  i_menu_history: 'Credit history',
  i_menu_bank: 'Bank account',
  i_menu_payments: 'Payment history',
  i_menu_information: 'Personal information',
  i_menu_referral: 'Referral program',
  contract_number: 'Contract number',
  payment_amount: 'Amount',
  not_payments_history: "You haven't got any payments yet.",
  payments_history_last_30_days: 'Payment history for the last 30 days',
  disbursement_date: 'Disbursement date',
  loan_sum: 'Loan sum',
  term: 'Term',
  actual_term: 'Actual term',
  documents: 'Documents',
  loan_agreement: 'Loan agreement',
  addendum: 'Addendum',
  ability_submit_new_application_not_available:
    'Unfortunately, ability to submit a new application is ' + 'not available in our service',
  thanks_greatly_choosing_our_service: 'Thanks greatly for choosing our service',
  sending_money_account: 'We are sending the money to your account number',
  usually_takes_15_minutes:
    'It usually takes up to 15 minutes for the money to be deposited into your account',
  within_15_minutes: 'Within 15 minutes the money will be deposited to your account',
  usually_takes_3_4_hours_strong:
    '<p>It usually takes from ' +
    '<strong>3 to 4 hours</strong> until the money will deposit on your account</p>',
  your_application_accepted: 'Your application is accepted',
  your_application_being:
    'Your application is being reviewed by a verifier. Keep your phone { phone } close - we might call you for additional questions.',
  keep_your_phone_close: 'KEEP YOUR PHONE CLOSE - we might call for additional questions!',
  application_review_usually:
    'The application review usually takes up to 15 minutes during working hours.',
  as_soon_decision: 'As soon as the decision is made, we’ll send you an sms.',
  not_loan_history: "You haven't got a loan yet.",
  unfortunately_your_application: 'Unfortunately your application was declined',
  you_can_apply_new: 'You can apply for a new loan in',
  thanks_greatly_choosing_service: 'Thanks greatly for choosing our service',
  time_till_repeat_request_30:
    'Time till repeat request {days} days, {hours} hours, {minutes} minutes.',
  globalDaysText: 'days',
  globalDayText: 'days',
  globalDay2Text: 'days',
  globalMinuteText: 'minutes',
  globalMinute2Text: 'minutes',
  globalMinute3Text: 'minutes',
  globalHourText: 'hours',
  globalHour2Text: 'hours',
  globalHoursText: 'hours',
  requested_loan_term: 'Requested loan term',
  bank_account_number_changing: 'Bank account number changing',
  change_bank_account_contact_support: 'To change a bank account, please contact support',
  submit: 'Apply for a loan',
  choose_loan_amount: 'Choose the loan amount',
  you_have_no_active_loans: 'You have no active loans at the moment',
  repeat_loan_subtitle: 'You can apply for up to 30 days loan now',
  to_get_first_loan_5_days: 'Get the first loan for {{value}} days with a discount!',
  to_get_first_loan_10_days: 'Get the first loan for {{value}} days!',
  to_get_first_loan_15_days:
    'Get the first loan for {{value}} days! After you repay your first ' +
    'loan, longer loan terms will be available to you!',
  transfer_account_number: 'Transfer to account number:',
  change_account_number: 'Change an account number',
  signing_loan_agreement: 'Signing a loan agreement',
  sent_sms_code: 'We sent you an SMS with the code to the number',
  please_enter_code_order_sign:
    'please enter the code in the input field below in order to ' + 'sign the contract',
  fist_loan_default_term: 'Loan term is {days} days',
  paid: 'Paid',
  active: 'Active',
  defaulted: 'Defaulted',
  pending: 'Pending',
  closed: 'Closed',
  cancelled: 'Cancelled',
  sold: 'Sold',
  overdue: 'Overdue',
  written_off: 'Written off',
  days_past: 'Days past due',
  days_till: 'Days till due date',
  extended_from_date: 'Extended from {date}',
  loan: 'Loan',
  total_repayment: 'Total sum for repayment',
  limited_time_offer: 'Limited - time offer',
  offer_is_available: 'Offer is available',
  promo: 'Promo',
  offer_is_valid_till: 'The offer is valid till',
  repayment_with_discount: 'Repayment with discount',
  body: 'The body',
  service_fee: 'Service fee',
  service_fee_add: 'Service fee (ADD)',
  transaction_fee: 'Transaction fee',
  cons_fee: 'Consulting fee',
  cons_fee_add: 'Consulting fee (ADD)',
  fines: 'Late payment fees',
  prolongation: 'Prolongation',
  prolongation_subtitle: "You can prolong your loan if you don't have enough money to pay now",
  prolong_30: 'You can prolong the loan for 30 days',
  total_prolong: 'Minimum sum for prolongation',
  total_prolong_with_discount: 'Total sum for prolongation with discount',
  prolong_date: 'Available {date}',
  prolong: 'Pay to prolong',
  select_extension_period: 'Select extension period',
  prolongation_modal: 'To prolong the loan deal our contact center {phone}, please',
  please_repay_total_sum: 'Please, repay total sum for prolongation',
  how_to_repay: 'How to repay',
  repayment: 'Repayment',
  for_information:
    'You can repay your loan online faster and more conveniently using the { provider } service',
  how_repay_online: 'Repay online',
  repay_online_discount: 'Repay',
  how_repay_offline: 'How to repay a loan offline?',
  title_slider_repay: 'Repayment sum',
  discount_code: 'Discount code',
  i_have_promocode: 'I have a promo-code',
  promocode_activated: 'Promo-code activated',
  introduce_another: 'Introduce another',
  enter_promo_code: 'Enter promo-code',
  enter_code_from_sms: 'Code from SMS',
  enter_the_code: 'Enter the code',
  you_didnt_confirm_promo_code:
    'Your promo-code is valid, but not applied yet. ' +
    'Are you sure you want to apply without promo-code?',
  apply: 'Apply',
  apply_now: 'Apply now',
  need_more_money: 'Need more money?',
  apply_loan_from_partners: 'Try to apply for a loan from our partners',
  the_promocode_has_expired: 'The promo-code has expired',
  the_promocode_used: 'The promo-code has already been used',
  promocode_not_applicable: "Promo code isn't applicable",
  promocode_not_found: 'Promo code not found',
  promocode_disabled: 'Promo code is disabled',
  an_error_occurred_try_again: 'An error has occurred. Try again, please',
  title_facebook_registration_modal:
    'Quick registration via Facebook will double your chance ' + 'for approval twice (optional) ',
  title_link_facebook_registration: 'Registration via Facebook',
  facebook: 'Facebook',
  error_hyper_snap: 'Selfie is not verified. Please, make another photo.',
  registration_progress_title_first: 'Number verification',
  registration_progress_title_second: 'Personal information',
  registration_progress_title_third: 'Receiving money',
  registration_progress_title_percent: '+ {percent}% to the probability of loan approval',
  confirm_with_call: 'Confirm with a call',
  we_will_call_you_nearest_time: 'We will call you in the nearest time',
  answer_call_please_follow_instructions: 'Answer the call, please, and follow the instructions',
  repeat_call_time: 'Repeat call in {time}',
  repeat_call_again: 'Repeat the call again',
  confirm_with_sms: 'Confirm with SMS',
  confirm_with_call_error:
    'Unfortunately, you are currently unable to make a call, please try requesting a text message again',
  phone_request_limit_reached:
    'Phone request limit has been reached, contact us at <a>{{phone}}</a>',
  your_phone_number_verified: 'Your phone number {value} is verified.',
  please_agreee_to_continue: 'Please, agree to the Terms & Conditions to continue',
  please_make_new_selfie: 'Please take a selfie again and follow the instructions.',
  plugin_not_ready: 'Please try to take a selfie in a few seconds.',
  please_make_clear_selfie: 'Please, make a clear selfie.',
  we_sending_money_your_account_number_right_now: 'We are sending the money to your account number',
  leave_feedback_our_service: 'Leave feedback on our service',
  are_you_satisfied_with_our_service: 'Are you satisfied with our service?',
  maturity_date: 'Maturity date',
  change: 'Change',
  leave_page_confirm: "Are you sure you want to leave the page? You'll lost your data",
  confirm: 'Confirm',
  // weekdays
  monday: 'Monday',
  tuesday: 'Tuesday',
  wednesday: 'Wednesday',
  thursday: 'Thursday',
  friday: 'Friday',
  saturday: 'Saturday',
  sunday: 'Sunday',
  check_your_phone_number: 'Check phone number',
  number_not_registered: 'This number is not registered',
  please_check_number_again:
    'Please change the number <a>{ phone }</a> or click "Continue" for registration',
  is_your_number: 'Is your number',
  no_change_number: 'No, change number',
  change_number: 'Change number',
  please_grant_access_to_camera: 'Please grant access to the camera',
  it_is_clear: "It's clear",
  you_must_grant_access_to_camera_subtitle:
    'In order to successfully take a photo, ' +
    'you must grant access to the camera after you click the <strong>"It\'s clear"</strong> button.',
  dont_worry_your_data_is_secure: "Don't worry, your data is secure",
  what_if_i_denied_access: 'What should I do if I have denied access to the camera?',
  how_do_you_use_our_site: 'How do you use our site?',
  try_refreshing_the_page: 'Try refreshing the page first',
  go_to_browser_settings: 'Go to your browser settings',
  select_privacy_and_security_section: 'Select the privacy and security section',
  find_site_settings_section: 'Find the "site settings" section',
  find_the_name_of_our_site: 'Find the name of our site cashx.lk and grant access to the camera',
  find_section_with_camera_settings:
    'Find the section with' + ' camera settings and allow access to our website cashx.lk',
  i_opened_in_third_party_applications: 'I opened the page in a third party application',
  using_google_chrome: 'Using google chrome',
  using_safari: 'Using Safari',
  using_another_browser: 'Using another browser',
  if_you_havenot_given_access:
    'If you have not given access' +
    ' to the camera and cannot provide it yourself, please contact our contact center',
  continue_registration_in_browser: 'Continue registration in the browser.',
  seven_days_a_week: 'Seven days a week',
  probability_of_loan_approval: 'Probability of loan approval',
  indicate_account_to_send_money:
    'Please, indicate the account number to which the money will be sent',
  account_number: 'Account number',
  i_undertake_accuracy:
    'I undertake the accuracy of above Bank account details and confirm that it’s my personal account number',
  take_photo_instructions_title: 'Take a photo following the instructions below',
  take_photo_instructions:
    'Capture the document in it’s entirety. Take it in a well-lit place and do not use the flash. Make sure the text is well readable',
  call: 'Call',
  status: 'Status',
  see_more: 'Show more',
  see_less: 'Show less',
  not_found_text:
    'The page you are looking for may have been deleted, changed its name, or is temporarily unavailable.',
  back_to_main: 'Back to the main page',
  server_error_text: 'Server is not available. Try again after 15 minutes.',
  min_date_err: 'Minimal date limit reached',
  max_date_err: 'Maximum date limit reached',
  min_birthdate_err: 'The minimum age must be 20',
  max_birthdate_err: 'The maximum age must be 60',
  your_loan_has_been_sold: 'Your loan has been sold',
  bank_details: 'Bank Details',
  sold_at: 'Sold at',
  company_name: 'Company name',
  email_sold: 'Email',
  good_lighting: 'Good lighting',
  angle: 'Angle',
  focus_and_sharpness: 'Focus and sharpness',
  avoid_reflection: 'Avoid reflection',
  margins_too_far: 'Margins (too far)',
  margins_too_close: 'Margins (too close)',
  subjection: 'Subjection',
  resolution_of_the_image: 'Resolution of the image',
  contrast: 'Contrast',
  show_more: 'Show more',
  show_less: 'Show less',
  months: 'Months',
  exit_modal_title: 'Are you sure you want to exit?',
  exit_modal_yes: 'Yes, exit',
  exit_modal_no: 'No, stay',
  users_mismatched: "User isn't matched",
  this_value_is_not_valid: 'This value is not valid',
  this_value_is_already_used: 'This value is already used',
  promocode_is_not_valid: 'Promo-code is not valid',
  contact_collection_team: 'Click here to contact Debt Collections Department',
  contact_repayment_slip: 'Have you already repaid loan?',
  metatag_login_title: 'Need cash loan online now. Login on CashX.',
  metatag_login_description:
    'Need fast money now - Login on CashX. Best online lender in Sri Lanka with fast approval and low interest.',
  metatag_reg_title: 'Need cash loan online. Register on CashX.',
  metatag_reg_description:
    'Need fast money - Register on CashX. Best online lender in Sri Lanka. Fast approval, low interest.',
  january: 'January',
  february: 'February',
  march: 'March',
  april: 'April',
  may: 'May',
  june: 'June',
  july: 'July',
  august: 'August',
  september: 'September',
  october: 'October',
  november: 'November',
  december: 'December',
  money_our_partners: 'You can get money from our partners',
  verify_your_identity: 'Verify your identity',
  please_upload_a_photo_of_your_NIC_card: 'Please upload a photo of your NIC card',
  NIC_card_description_text:
    'Take a photo of front of the NIC card so that all the data is clearly visible',
  ocr_error: 'Text data not found. Please try again',
  application_approved: 'Your application has been approved!',
  send_photo_of_receipt: 'Send photo of receipt',
  send_photo_of_receipt_whatsapp: 'Send to Whatsapp',
  send: 'Send',
  repayment_slip_title:
    'Upload a photo of your loan #{ loanId } payment slip as an attachment, then our finance department will verify it and update your credit',
  attach_your_file: 'Attach your file',
  slip_successfully_sent: 'Your repayment slip has been successfully sent.',
  get_more_money: 'Get more money',
  can_get_more_money: 'You can get more money, select the amount below',
  increase_loan_up: 'You can increase the loan up to RS { amount }',
  get_settlement_amount: 'Get { amount } RS',
  sampath_bank_instruction:
    '<p>In order to make a payment through the bank’s CDM Sampath bank, you must:</p>' +
    '<p><strong>• Step 1:</strong> Please select the language;</p>' +
    '<p><strong>• Step 2:</strong> Select “CASH DEPOSIT”;</p>' +
    '<p><strong>• Step 3:</strong> Enter your 10-digit mobile number;</p>' +
    '<p><strong>• Step 4:</strong> Enter your NIC number: { nic }</p>' +
    '<p><strong>• Step 5:</strong> Enter Sampath Bank account number as 017510007263</p>' +
    '<p><strong>• Step 6:</strong> Make sure the account name is automatically displayed as “A V BUSINESS SOLUTION (PRIVATE) LIMITED”;</p>' +
    '<p><strong>• Step 7:</strong> Enter your NIC number { nic } again as “REFERENCE NO”.</p>' +
    '<p><strong>• Step 8:</strong> Place cash notes into the deposit tray (Only Rs. 100 and higher cash notes can be used);</p>' +
    '<p><strong>• Step 9:</strong> The amount you deposited will be displayed on the screen. Press “Proceed” if correct;</p>' +
    '<p><strong>• Step 10:</strong> Take the payment receipt from the machine and keep it.</p>',
  any_bank_instruction:
    '<p>Making the payment you should enter your NIC number as well the loan agreement number you’ve received in transaction details or reference.</p>' +
    '<p>Enter the amount you need to pay and submit the payment to the teller.</p>',
  account_holder_name: 'Account holder Name',
  your_nic_number: 'Your NIC number',
  your_loan_number: 'Your Loan number',
  sampath_bank_subtitle:
    'Please fill in the Sampath Bank deposit slip with the following information and hand it over to the teller:',
  cargills_bank_subtitle:
    'Please fill in the Cargills Bank deposit slip with the following information and hand it over to the teller:',
  hatton_bank_subtitle:
    'Please fill in the Hatton National Bank PLC deposit slip with the following information and hand it over to the teller:',
  any_bank_title: 'Fund transfer through any bank',
  any_bank_subtitle:
    'You can choose to make your fund transfer through any bank using the following accounts details:',
  note: 'Note',
  repay_note_text:
    'Whenever you make a repayment, send us a clear slip photo or screenshot of your Payment Slip to our phone numbers 074-2478024 via WhatsApp with your NIC number or Loan ID.',
  repayment_on_website: 'You can choose other loan repayment options on our <a>website</a>.',
  webview_modal_title: 'Instruction',
  webview_modal_subtitle:
    'For successful identification, we recommend switching to your default mobile browser',
  webview_android_text:
    '1. On your Facebook browser click on the { dotsIcon } icon on the top right corner',
  webview_ios_text:
    '1. On your Facebook browser click on the { dotsIcon } icon in the bottom right corner',
  webview_text_2: '2. Choose { browserIcon } browser option in the bottom sheet menu',
  another_browser_instruction:
    'Try update the page and opening access to the camera in your browser or phone settings',
  go_to_mobile_settings: 'Go to your mobile settings',
  find_the_safari: 'Find the "Safari"',
  find_the_camera_section: 'Find the "Camera" section',
  choose_allow_in_camera_access: 'Choose "allow" in camera access',
  repay_pay_go:
    'Now you can quickly and easily repay your CashX loan at any time of the day through any Pay&Go Kiosks Machines Island wide or through Pay&Go Website',
  pay_online_pay_go: 'Please use this link to pay Online through Pay&Go <a>Website</a>',
  closest_kiosk_pay_go: 'Please use this <a>link</a> to find the closest kiosk',
  bank_details_modal_title: 'Please check your bank details',
  change_bank_details: 'Change bank details',
  call_support: 'Call support',
  please_call_support: 'Please call support at <a>{ phone }</a> to change your bank details',
  send_loan_agreement: 'We will send your loan agreement to this email address.',
  capture_selfie: 'Capture selfie',
  add_email: 'Add email',
  add_email_subtitle:
    'Please add your email to receive updates about discounts and individual offers',
  email_is_verified: 'Your email is verified',
  confirm_your_email: 'Please confirm your email',
  payment_successful: 'Payment successful!',
  payment_successfully_processed: 'Your payment has been successfully processed',
  we_sent_email: 'We have sent you an email to { userEmail } to complete your email verification.',
  open_email: 'Please open the email and follow the instruction',
  we_have_got_your_email_address: 'We have got your email address!',
  open_the_email: 'Please open the email and follow the instruction',
  please_confirm_your_email:
    'Please confirm your email <a>{ userEmail }</a> to receive individual offers',
  we_sent_email_and_open:
    'We have sent you an email to <a>{ userEmail }</a>. Please open the email and follow the instructions',
  confirm_an_email: 'Confirm an email',
  number_is_registered: 'Number is registered',
  number_is_registered_text:
    'Dear Customer, you previously attempted to register an account with an existing NIC, which was deemed suspicious. As a result, you are unable to proceed with registration using this phone number. We recommend logging in with your old number – <a>{ phoneNumber }</a>.',
  nic_number_is_registered: 'Account with the NIC number is registered',
  has_overdue_loan:
    'An account has already been created with your NIC number and you have an overdue loan. Please <a1>log in</a1> using your previous number {{matched_user_phone}} and pay your loan. The new account will be blocked within a minute.',
  has_sold_loan:
    'An account has already been created with your NIC number and your loan has been sold. Please <a1>log in</a1> using your previous number {{matched_user_phone}} to find out more information. The new account will be blocked within a minute',
  in_black_list:
    'An account has already been created with your NIC number. You can not apply for a loan again at this time. Try getting a loan from our partners',
  has_moratorium:
    'An account with your NIC number has already been created and you have submitted applications. Please <a1>log in</a1> using your previous number {{matched_user_phone}} for more details. The new account will be blocked within a minute.',
  please_reload_page:
    'Camera access is required. Please reload the page, grant camera access, and try taking a selfie again.',
  reload_page: 'Reload the page',
  // BioId
  NoFaceFound: 'Please ensure your face is clearly visible in the frame and try again.',
  MultipleFacesFound: 'Make sure only your face is in the frame, then take the photo again.',
  CannotCropImage:
    'Move your face to the center of the frame with enough space around it, and retake the photo.',
  IrisNotFound:
    'Position your face straight ahead so both eyes are clearly visible, and try again.',
  ImageTooSmall: 'Ensure your face is large enough in the frame, then take the photo again.',
  WrongViewingDirection:
    'Look directly into the camera and keep your head straight, then retake the photo.',
  HeadRotatedTooFar: 'Keep your head straight and facing forward, then take the photo again.',
  FaceAsymmetry: 'Ensure even lighting on your face and maintain a straight pose, then try again.',
  ImageTooBlurry:
    'Hold your camera steady and ensure the image is sharp, then take the photo again.',
  BadFaceBrightness: 'Adjust the lighting so your face is evenly lit, then retake the photo.',
  FaceContrastTooHigh:
    'Ensure your face is evenly lit without harsh shadows, and take the photo again.',
  FaceContrastTooLow: 'Improve the lighting or move to a brighter area, then retake the photo.',
  ImageOverExposure:
    'Reduce the lighting or adjust your camera settings, then take the photo again.',
  ImageUnderExposure: 'Increase the lighting or move to a brighter area, then retake the photo.',
  FaceTooDark:
    'Move to a well-lit area and ensure your face is clearly visible, then take the photo again.',
  FaceTooBright: 'Reduce the lighting on your face and retake the photo.',
  BadGrayscaleDensity:
    'Adjust the lighting so the color intensity on your face is balanced, then retake the photo.',
  ImageTooOld: 'Take a new photo that clearly shows your face.',
  MissingTimeStamp: 'Ensure your camera settings are correct, and take a new photo.',
  ImageWayTooSmall:
    'Use a higher resolution and ensure your face is large enough in the frame, then take the photo again.',
  BadImageBrightness:
    'Adjust the lighting so the image has a proper brightness distribution, then retake the photo.',
  ImageContrastTooHigh: 'Reduce the contrast in the image and try again.',
  ImageContrastTooLow: 'Increase the contrast in the image and try again.',
  ImageTooDark: 'Move to a brighter area and take the photo again.',
  ImageTooBright: 'Reduce the brightness or move to a less bright area, then retake the photo.',
  UnsuitableImage: 'Ensure that the face is fully visible in the image and try again.',
  PassiveLiveDetectionFailed:
    'Make sure you are in front of the camera, and try again with natural movement.',
  phone_confirmation: 'Phone confirmation',
  we_sent_confirmation_code:
    'We have sent a confirmation code to your old phone number, enter it to confirm your identity.',
  please_contact_call_center:
    'Please contact our call center at <a>{ support_service_phone }</a> to verify and update the data.',
  old_number: 'Old number +94 { old_number }',
  new_number: 'New number +94 { new_number }',
  select_phone_number: 'Select phone number',
  select_which_phone_number: 'Select which phone number you want to use in the future.',

  //referrals
  earn_for_each_friend_you_invite: 'Earn 1000 RS for each friend you invite!',
  earn_for_each_friend_you_invite_text:
    'Earn up to 1000 RS for each friend that uses one of your remaining invites and start spending. Share your unique referral link with them.',
  how_to_get_bonuses: 'How to get bonuses',
  more_info: 'More info',
  referral_modal_title:
    'We are excited to introduce the new Referral Program from CashX exclusively for our customers!',
  referral_modal_text1:
    '<p>Now, you can share your positive CashX experience with friends and receive bonus rewards in return.</p>' +
    '<p>Carefully review the conditions below and start inviting friends using your unique referral link. We are ready to reward you with bonuses.</p>',
  campaign_period: 'Campaign Period:',
  referral_modal_text2: '<p>September 1, 2024 - December 31, 2024</p>',
  how_to_invite_a_friend: 'How to invite a friend?',
  referral_modal_text3:
    '<p>1. You need to be our customer and have at least one active/closed loan.</p>' +
    '<p>2. In your personal account, go to the "Referral Program" section, copy your unique referral link, and send it to your friend.</p>',
  how_to_earn_a_reward: 'How to earn a reward?',
  referral_modal_text4:
    '<p>Your friend should take a first loan and pay it off on time, the maximum delay should not be more than 7 days. After this, bonus money will be credited to your bonus account.</p>' +
    '<p>Your friend will also receive a bonus on the first loan – promocode with 50% discount. Promo code will be sent along with referral link. To do this you need to use the invitation in your personal account via SMS or email.</p>',
  key_points: 'Key Points:',
  referral_modal_text5:
    '<p>• Make sure your friend has not previously registered with us.</p>' +
    '<p>• To qualify for the program and receive a reward, your friend must begin their registration using your referral link.</p>' +
    '<p>• You can monitor your sent invitations and check your bonus account balance in the "Referral Program" section of your personal account.</p>',
  how_to_use_your_bonuses: 'How to use your bonuses?',
  referral_modal_text6:
    '<p>• Bonuses can only be used when applying for a loan in the "Credit" or "Referral Program" section of your personal account.</p>' +
    '<p>• You can use bonuses to pay fees. Bonuses do not apply to the principal amount of the loan.</p>',
  important_notes: 'Important Notes:',
  referral_modal_text7:
    "<p>• If you receive bonuses while you have an active loan, you won't be able to use them for that loan. You must repay the current loan and then apply the bonuses to a new loan application.</p>" +
    '<p>• If the amount of bonuses exceeds the fees, the remaining bonuses can be used for the next loan.</p>' +
    '<p>• Bonuses and promo codes cannot be used together at the same loan.</p>' +
    '<p>• Applied bonuses will be canceled if you have a loan overdue by more than 30 days.</p>',
  start_sharing_today: 'Start sharing today and enjoy the benefits of being a CashX customer!',
  copy_the_link: 'Copy the link and share with your friends',
  copy: 'Copy',
  share_via: 'Share via:',
  share_via_email: 'Email',
  your_achievements: 'Your achievements',
  available_bonuses: 'Available bonuses',
  registered_by_invitation: 'Registered by invitation',
  invited: 'Invited',
  completed: 'Completed',
  available_bonuses_text: 'Bonuses that you can use when applying for a loan',
  registered_by_invitation_text: 'Total number of unique registered invitations',
  completed_text: 'Number of friends who received and paid the loan',
  link_is_copied: 'Link is copied',
  add_friend_email: "Add your friend's email",
  add_friend_phone: "Add your friend's phone number",
  add_friend_email_subtitle: "Please add your friend's email address so we can send an invitation.",
  add_friend_phone_subtitle: "Please add your friend's phone number so we can send an invitation.",
  send_an_invite: 'Send an invite',
  invitation_sent: 'Invitation sent!',
  invitation_sent_sms: 'Inform your friend about SMS.',
  invitation_sent_email: 'Inform your friend about email.',
  you_have_on_your_balance: 'You have on your balance:',
  apply_loan_and_use_bonuses: 'Apply for a loan and use your bonuses!',
  use_bonuses_for_fees: 'Use bonuses to pay for fees',
  or: 'or',
  apply_promocode: 'Apply promocode',
  use_bonuses: 'Use bonuses',
  nic_error: 'Make sure your NIC number is correct',
  pay_the_debt_immediately: 'To avoid legal consequences, pay the debt immediately',
  photo_example: 'Photo example',
  install_app_desktop: 'Save the CashX site shortcut to your desktop',
  install_app_mobile: 'Save the CashX site shortcut to your home screen',
  month: 'Month',
  year: 'Year',
  agreement_loading: 'Loading. Please wait...',
  do_not_have_an_account: 'Don’t have an account?',
  please_contact_call_center_login:
    'To log in and access your personal account, please contact our call center ',
  you_need_money: 'You need money?',
  can_get_a_loan: 'You can get a loan from our partners.',
  get_a_loan: 'Get a loan',
  we_sent_sms: 'We have sent you an SMS with the code to <span>{ userPhone }</span>',
  increase_your_loan_amount: 'Increase your loan amount up to { amount } RS!',
  credit_bureau_report_title: 'Improve your chances to get a loan approval!',
  credit_bureau_report_description:
    'Please add a link and password to your report from the CRIB portal (<a>{ linkText }</a>) to improve the chances of loan approval!',
  credit_bureau_report_link: 'Link to the report',
  credit_bureau_report_password: 'Password to view the report',
  credit_bureau_report_button: 'Send a report',
  credit_bureau_report_success_title: 'Successfully sent!',
  credit_bureau_report_success_description:
    'Thank you for the information! Our team will review your report shortly.',
  field_err_invalid_url: "Invalid URL format. Ensure the link starts with 'https://'",
  loan_has_been_activated: 'Congratulations! Your loan has been activated!',
  add_notification_to_calendar:
    "Add a notification to your Google calendar so you don't forget to pay your loan on time.",
  add_notification: 'Add a notification',
  paid_early: 'You paid off the loan early!',
  remove_the_reminder: "Please don't forget to remove the reminder from your calendar.",
  successfully_extended_loan: 'You have successfully extended your loan!',
  remove_the_previous_reminder: 'Please remove the previous reminder from your calendar.',
  google_notification_title: 'Attention! The due date of your loan { loanId } at CashX is today!',
  google_notification_details:
    'Hello! We remind you that today is the due date of your loan. Pay the amount of { accruedTotal } RS or if you need a little more time, extend the loan term by up to 5-30 days, paying { minSumProlongation } RS ! Choose the option that suits you best!',
};
