import { useTranslation } from 'react-i18next';
import { CameraIcon } from 'components/icons';

interface CameraAccessDeniedFAQProps {
  handleShowIfAccessDeniedDialog: () => void;
}

export const CameraAccessDeniedFAQ = ({
  handleShowIfAccessDeniedDialog,
}: CameraAccessDeniedFAQProps) => {
  const { t } = useTranslation();

  return (
    <span onClick={handleShowIfAccessDeniedDialog} className='alerts-item__info cursor-pointer'>
      <div className='flex-center'>
        <CameraIcon />
      </div>
      <span>{t('what_if_i_denied_access')}</span>
    </span>
  );
};
