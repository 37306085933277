import { useTypedSelector } from 'core/hooks';
import { loansSelector } from 'store/selectors';
import { AccountLoanHistoryPage } from './AccountLoanHistoryPage';

const AccountLoanHistoryPageContainer = () => {
  const loans = useTypedSelector(loansSelector);

  return <AccountLoanHistoryPage loans={loans} />;
};

export default AccountLoanHistoryPageContainer;
