import { useTypedSelector } from 'core/hooks';
import { setRedirect } from 'core/store/actions';
import { SubmitCoreHandler } from 'core/models';

import { useAppDispatch } from 'hooks';
import { updateUser } from 'store/actions/api';
import { PhoneConfirmFormFields } from 'models';
import { TermsConditionsForm, TermsConditionsFormFields } from './TermsConditionsForm';
import { userSelector } from 'store/selectors';
import { getRegStepPath } from 'constantsLk';

interface TermsConditionsFormContainerProps {
  onOpenModalHandler: (doc: any) => any;
}

export const TermsConditionsFormContainer = (props: TermsConditionsFormContainerProps) => {
  const { onOpenModalHandler } = props;
  const dispatch = useAppDispatch();

  const user = useTypedSelector(userSelector);

  const onSubmit: SubmitCoreHandler<TermsConditionsFormFields> = async (values) => {
    const dataUser = {
      is_data_processing_consent_given: values[PhoneConfirmFormFields.AgreementTermsConditions],
    };

    return dispatch(updateUser(dataUser))
      .unwrap()
      .then((res) => {
        dispatch(setRedirect({ to: getRegStepPath(res.registration_step), replace: true }));
      })
      .catch(() => {});
  };

  return (
    <TermsConditionsForm
      phone={user?.phone_number}
      onOpenModalHandler={onOpenModalHandler}
      onSubmit={onSubmit}
    />
  );
};
