import { useState } from 'react';
import { useLocation } from 'react-router-dom';

import { sendDataLayer } from 'core/services';
import { SubmitCoreHandler } from 'core/models';
import { setRedirect } from 'core/store/actions';

import { sendOTPassword } from 'store/actions/api';
import { createUserAction, setAuthorized, signIn } from 'store/actions';
import { useAppDispatch } from 'hooks';
import { AuthFormFields, AuthFormModel } from 'components';
import { Login } from './Login';
import { Step2ConfirmPhoneWithSMSForm } from '../Registration';
import { PhoneConfirmFormFields } from 'models';
import { logOutUtil } from 'utils/logout';
import { GTM_LABEL_LOGIN } from 'utils/analitics/gtmLabel';
import { OTPLoginAction } from 'store/actions/authActions';
import { lastKeyOfSteps, removeLoginTimer } from 'utils';
import { setShowPartnerLogin } from 'store/slices/authSlice';
import { store } from 'store';
import { ERRORS, getRegStepPath, USER_STATE } from 'constantsLk';
import * as Paths from 'constantsLk/paths';

export const LoginContainer = () => {
  const dispatch = useAppDispatch();
  const location = useLocation();

  const [oldUserPhone, setOldUserPhone] = useState(null);

  const resetOldUserPhone = () => setOldUserPhone(null);

  const handlePhoneReset = () => {
    dispatch(setRedirect({ to: `/${Paths.URL_PATH_LOGIN}`, replace: true, state: null }));
    logOutUtil();
    dispatch(setAuthorized(false));
  };

  const onLogin: SubmitCoreHandler<Step2ConfirmPhoneWithSMSForm> = (values, { setError }) => {
    const username = location.state.phone.replace(/\s/g, '');
    return dispatch(
      OTPLoginAction({ username: username, password: values[PhoneConfirmFormFields.Code] }),
    )
      .unwrap()
      .then(() =>
        dispatch(signIn())
          .unwrap()
          .then((user) => {
            if (user.state === USER_STATE.ACTIVE) {
              const activeApplication = store.getState().applicationReducer.activeApplication;

              if (
                user.registration_step < lastKeyOfSteps ||
                (user.registration_step === lastKeyOfSteps && !activeApplication)
              ) {
                dispatch(setRedirect({ to: getRegStepPath(user.registration_step) }));
              } else {
                sendDataLayer('page_view_profile', GTM_LABEL_LOGIN);
                dispatch(setRedirect({ to: Paths.URL_ACTIVE_LOAN, replace: true }));
              }
            } else {
              dispatch(setRedirect({ to: getRegStepPath(user.registration_step), replace: true }));
            }
            removeLoginTimer();
          }),
      )
      .catch((e) => {
        setError?.(PhoneConfirmFormFields.Code, {
          type: 'validate',
          message: e?.message,
        });
      });
  };

  const onSendOTP: SubmitCoreHandler<AuthFormModel> = async (values, { setError, trigger }) => {
    const phone = values[AuthFormFields.Phone];

    const redirect = (path: string, isOpenModal: boolean) =>
      dispatch(
        setRedirect({
          to: path,
          replace: true,
          state: { phone, isOpenModal },
        }),
      );

    try {
      await dispatch(sendOTPassword({ [AuthFormFields.Phone]: phone })).unwrap();
      return redirect(`/${Paths.URL_PATH_LOGIN}`, false);
    } catch (e: any) {
      switch (e.status) {
        case 403:
          return dispatch(setShowPartnerLogin(true));
        case 451:
          return redirect(getRegStepPath(2), true);
        case 401:
          logOutUtil();
          return redirect(`/${Paths.URL_PATH_LOGIN}`, false);
        case 404:
          if (!location.state?.isOpenModal) {
            return redirect(`/${Paths.URL_PATH_LOGIN}`, true);
          }
          await dispatch(createUserAction({ username: phone, user_additional_data: {} })).unwrap();
          return redirect(getRegStepPath(1), true);
        default:
          if (e.message === ERRORS.USER.ARCHIVE) {
            setOldUserPhone(e.phone_number);
            return redirect(getRegStepPath(1), false);
          }
          setError?.('root.serverError', { type: '400', message: e?.message });
          trigger && trigger().then();
      }
    }
  };

  return (
    <Login
      handlePhoneReset={handlePhoneReset}
      onLogin={onLogin}
      onSendOTP={onSendOTP}
      oldUserPhone={oldUserPhone}
      resetOldUserPhone={resetOldUserPhone}
    />
  );
};
