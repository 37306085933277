import { useEffect } from 'react';
import { useTypedSelector } from 'core/hooks';
import { setCookie } from 'core/services';

import {
  activeLoanSelector,
  isCanProlongSelector,
  isShowPartnerSelector,
  userSelector,
} from 'store/selectors';
import { Spinner } from 'components';
import { ActiveLoan } from './ActiveLoan';
import { setShowPartner } from 'store/slices/loanSlice';
import { useAppDispatch } from 'hooks';
import { KEY_OPENED_PARTNER_LINK, PROMOTION_TYPE } from 'constantsLk';

export const ActiveLoanContainer = () => {
  const dispatch = useAppDispatch();
  const isProlongable = useTypedSelector(isCanProlongSelector);
  const loan = useTypedSelector(activeLoanSelector);
  const user = useTypedSelector(userSelector);
  const isShowPartner = useTypedSelector(isShowPartnerSelector);
  const openedPartnerLink = localStorage.getItem(KEY_OPENED_PARTNER_LINK);
  loan?.id && setCookie('active_loan_id', loan.id);

  useEffect(() => {
    if (openedPartnerLink) {
      if (openedPartnerLink === 'null' && loan?.id) {
        localStorage.setItem(KEY_OPENED_PARTNER_LINK, `${loan?.id}`);
      } else if (+openedPartnerLink !== loan?.id) {
        dispatch(setShowPartner(true));
        localStorage.removeItem(KEY_OPENED_PARTNER_LINK);
      } else {
        dispatch(setShowPartner(false));
      }
    }
  }, [loan?.id]);

  const onClose = () => dispatch(setShowPartner(false));

  return loan ? (
    <ActiveLoan
      loan={loan}
      user={user}
      isProlongable={isProlongable}
      isShowPartners={loan.days_past_due < -4 && isShowPartner}
      onClose={onClose}
      activePromotion={loan?.active_promotions?.find(
        (i) => i.type !== PROMOTION_TYPE.EXTENSION_DISCOUNT,
      )}
      activeProlongationPromotion={loan?.active_promotions?.find(
        (i) => i.type === PROMOTION_TYPE.EXTENSION_DISCOUNT,
      )}
    />
  ) : (
    <Spinner />
  );
};
