export const isFacebookWebview = !!navigator.userAgent.match(/FBAN|FBAV/i);

export const isWebView = () => {
  const userAgent = navigator.userAgent.toLowerCase();
  const safari = /safari/.test(userAgent);
  const ios = /iphone|ipod|ipad|macintosh/.test(userAgent);
  const ios_ipad_webview = ios && !safari;

  return ios ? ios_ipad_webview : isFacebookWebview || userAgent.includes('wv');
};
