import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { maxLength, onlyNumbers } from 'core/utils/parsers';
import { Slider } from 'core/components/common';

import { objKeysToNumber } from 'utils';
import { IPrice, LoanCalculatorFields } from 'models';
import { SLIDER_THEME } from 'constantsLk';

interface ICalculatorProps {
  price: IPrice;
  settings: { term: number; amount: number };
  conditions: { term: number; amount: number };
  onChangeSlider: (name: LoanCalculatorFields, value: number) => void;
}

export const Calculator: FC<ICalculatorProps> = ({
  price,
  settings,
  onChangeSlider,
  conditions,
}) => {
  const { t } = useTranslation();

  const termArray = objKeysToNumber(price);
  const amountArray = (settings && objKeysToNumber(price?.[settings.term])) || [];

  const indexOfAmount = amountArray?.indexOf(Number(conditions?.amount)) || 0;
  const indexOfTerm = termArray?.indexOf(Number(conditions?.term)) || 0;

  return (
    <div className='flex-column gap-6 mb-5'>
      <Slider
        colors={SLIDER_THEME}
        values={amountArray}
        name={LoanCalculatorFields.Amount}
        prefix='RS'
        title={t('loan_amount')}
        currentValue={indexOfAmount}
        onChangeSlider={onChangeSlider}
        texFieldProps={{
          name: LoanCalculatorFields.Amount,
          type: 'tel',
          parsers: [onlyNumbers],
        }}
      />
      {termArray.length < 3 ? (
        <div className='flex gap-2'>
          {termArray.map((item) => (
            <button
              type='button'
              className={`flex-grow-1 txt-md-16 txt-sb cursor-pointer btn-tertiary ${
                item === conditions.term ? 'current' : ''
              }`}
              onClick={() => onChangeSlider(LoanCalculatorFields.Term, item)}
              key={item}
            >
              {item} {t('days')}
            </button>
          ))}
        </div>
      ) : (
        <Slider
          colors={SLIDER_THEME}
          values={termArray}
          name={LoanCalculatorFields.Term}
          prefix={t`days`}
          title={t`loan_term`}
          currentValue={indexOfTerm}
          onChangeSlider={onChangeSlider}
          texFieldProps={{
            name: LoanCalculatorFields.Term,
            type: 'tel',
            parsers: [onlyNumbers, maxLength(2)],
          }}
        />
      )}
    </div>
  );
};
