const CASES = [2, 0, 1, 1, 1, 2];

export function getDaysText(
  value: any,
  args = ['globalDayText', 'globalDay2Text', 'globalDaysText'],
) {
  return getText(value, args);
}

export function getHoursText(
  value: any,
  args = ['globalHourText', 'globalHour2Text', 'globalHoursText'],
) {
  return getText(value, args);
}

export function getMinutesText(
  value: any,
  args = ['globalMinuteText', 'globalMinute2Text', 'globalMinute3Text'],
) {
  return getText(value, args);
}

export function getText(value: any, args: Array<string>) {
  const num = Math.abs(Number(value));

  return args[num % 100 > 4 && num % 100 < 20 ? 2 : CASES[num % 10 < 5 ? num % 10 : 5]];
}
