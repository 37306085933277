import { useState } from 'react';
import { getCookie, setCookie } from 'core/services';
import { BankDetailsModal } from './BankDetailsModal';
import { UpdateAccountNumberModal } from '../UpdateAccountNumberModal';
import { KEY_CONFIRM_BANK_DETAILS } from 'constantsLk';

interface BankDetailsModalContainerProps {
  isFirstLoan: boolean;
  isReferralPage?: boolean;
}

export const BankDetailsModalContainer = (props: BankDetailsModalContainerProps) => {
  const { isFirstLoan, isReferralPage } = props;

  const isConfirmBankDetails = getCookie(KEY_CONFIRM_BANK_DETAILS);

  const [isModalOpen, setIsModalOpen] = useState(
    isFirstLoan && !isConfirmBankDetails && !isReferralPage,
  );
  const [isChangeDetailsModalOpen, setIsChangeDetailsModalOpen] = useState(false);

  const handleConfirm = () => {
    setCookie(KEY_CONFIRM_BANK_DETAILS, '1');
    setIsModalOpen(false);
  };

  const handleChangeDetailsModalOpen = () => {
    setIsModalOpen(false);
    setIsChangeDetailsModalOpen(true);
  };

  const closeChangeDetailsModal = () => setIsChangeDetailsModalOpen(false);

  return (
    <>
      <BankDetailsModal
        handleConfirm={handleConfirm}
        isOpenModal={isModalOpen}
        handleChangeDetailsModalOpen={handleChangeDetailsModalOpen}
      />

      {isChangeDetailsModalOpen && <UpdateAccountNumberModal onClose={closeChangeDetailsModal} />}
    </>
  );
};
