import { InputState } from 'react-input-mask';
import { format, parseISO, subDays, subYears } from 'date-fns';
import {
  COUNTRY_PHONE_CODE,
  COUNTRY_PHONE_CODE_NUMBER,
  DEFAULT_LANGUAGE,
  INCOME_STATE,
  KEY_NOTIFICATION_DATA,
  LANGUAGES,
} from 'constantsLk';
import { Income, SortedIncomesProps } from 'models';
import { getCookie, setCookie } from 'core/services';
import { WpKeys } from 'core/models';

export const beforeMaskedValueChangePhone = (
  newState: InputState,
  oldState: InputState,
  userInput: string,
): InputState => {
  const copy = userInput
    ?.replace(/\D/g, '')
    .replace(new RegExp(`^${COUNTRY_PHONE_CODE_NUMBER}`), '');

  if (!copy) return newState;

  const phoneValue =
    copy.length >= 9
      ? `${copy.slice(0, 3)} ${copy.slice(3, 5)} ${copy.slice(5, 7)}  ${copy.slice(7, 9)}`
      : newState.value;

  return { ...newState, value: phoneValue };
};

export const formattingPhone = (phone: string | null | undefined) => {
  if (!phone) return '';

  const p = phone.includes('*')
    ? phone.replace(new RegExp(`^${COUNTRY_PHONE_CODE_NUMBER}`), '')
    : phone.replace(/\D/g, '').replace(new RegExp(`^${COUNTRY_PHONE_CODE_NUMBER}`), '');

  return `${COUNTRY_PHONE_CODE} ${p.slice(0, 3)} ${p.slice(3, 6)} ${p.slice(6, 9)}  ${p.slice(
    9,
    12,
  )}`;
};

export const getSubYear = (year: number) => subYears(new Date(), year);
export const getSubDay = (day: number) => subDays(new Date(), day);

export const getArrayNextSibling = <T = number>(value: T, array: T[]) =>
  array.find((item) => value <= item);

export const getArrayPrevSibling = <T = number>(value: T, array: T[]) =>
  [...array].reverse().find((item) => value >= item);

export const getSuitableElementFromArray = <T = number>(
  value: T | undefined,
  array?: T[],
  siblingSide: 'prev' | 'next' = 'next',
): T | undefined => {
  if (value && Array.isArray(array) && array.length && !array.includes(value)) {
    if (siblingSide === 'next') {
      return getSuitableElementFromArray<T>(
        getArrayNextSibling<T>(value, array),
        array,
        siblingSide,
      );
    }

    if (siblingSide === 'prev') {
      return getSuitableElementFromArray<T>(
        getArrayPrevSibling<T>(value, array),
        array,
        siblingSide,
      );
    }
  }

  return value;
};

export const getElementInRange = <T = any>(value: T, min: T, max: T): T => {
  if (value > max) {
    return max;
  }

  if (value < min) {
    return min;
  }

  return value;
};

export const makeSkipDocumentType = (type?: string) => `skip_${type || ''}`;

export const compareDocumentTypes = (firstType?: string, secondType?: string) =>
  firstType === secondType || firstType === makeSkipDocumentType(secondType);

export type GetArrayReturnType<ArrayType extends readonly unknown[]> =
  ArrayType extends readonly (infer ElementType)[] ? ElementType : never;

export const getMonthVal = (val: string) => format(parseISO(val), 'LLLL_yyyy');

export const sortIncomes = (incomes: any) => {
  const sortedIncomes: SortedIncomesProps = {};

  const filteredTransactions = incomes.filter((income: Income) => {
    const maxLoanId = Math.max(...incomes.map((income: Income) => income.loan_id));
    if (income.loan_id !== maxLoanId) {
      return false;
    }

    const thirtyDaysAgo = new Date();
    thirtyDaysAgo.setDate(thirtyDaysAgo.getDate() - 30);
    const receivedDate = new Date(income.received_at);
    return receivedDate > thirtyDaysAgo;
  });

  filteredTransactions.forEach((x: Income) => {
    if (!sortedIncomes[getMonthVal(x.received_at)] && x.state !== INCOME_STATE.CANCELLED) {
      sortedIncomes[getMonthVal(x.received_at)] = [];
    }
    if (x.state !== INCOME_STATE.CANCELLED) {
      sortedIncomes[getMonthVal(x.received_at)]?.push(x);
    }
  });

  return sortedIncomes;
};

export const setStorageLanguage = (language: string) =>
  setCookie(WpKeys.CurrentLanguage, language, { expires: 999 });

export const getStorageLanguage = () => {
  const language = getCookie(WpKeys.CurrentLanguage);
  if (LANGUAGES.filter((item) => item.value === language).length) {
    return language;
  } else {
    setStorageLanguage(DEFAULT_LANGUAGE.value);
    return DEFAULT_LANGUAGE.value;
  }
};

export const setStorageGoogleNotification = (loanId: number, maturedAt: string, action: string) => {
  const data = { loanId, maturedAt, action };
  localStorage.setItem(KEY_NOTIFICATION_DATA, JSON.stringify(data));
};
