import { useEffect, useState } from 'react';

import { SubmitCoreHandler, WpKeys } from 'core/models';
import { useTypedSelector } from 'core/hooks';
import { getCookie, getUserID, removeCookie } from 'core/services';
import { INIT_VALUES_FORM_KEY } from 'core/constants';
import { setRedirect } from 'core/store/actions';

import { LoanService } from 'services';
import { useAppDispatch, useHookFormGetCache } from 'hooks';
import { LoanCalculator } from './LoanCalculator';
import { IbanNumberFields, LoanCalculatorForm } from 'models';
import {
  defaultPricesSelector,
  isFirstLoanSelector,
  pricesSelector,
  productIdSelector,
  userBankDetailsSelector,
  userSelector,
} from 'store/selectors';
import { getCalculatorConfigActions, signIn } from 'store/actions';
import { createApplication, getProduct } from 'store/actions/api';
import { setBankDetails } from 'store/slices';
import {
  AddEmailModalContainer,
  BankDetailsModalContainer,
  EmailVerificationModal,
  PaidEarlyModal,
  Spinner,
} from 'components';
import { getPromoCodeErrorMessage } from 'utils';
import {
  KEY_BANK_ACCOUNT_DETAILS,
  KEY_CLOSE_EMAIL_MODAL,
  KEY_NOTIFICATION_DATA,
  URL_ACTIVE_LOAN,
} from 'constantsLk';

export const LoanCalculatorContainer = ({ isReferralPage }: { isReferralPage?: boolean }) => {
  const dispatch = useAppDispatch();

  const [isEmailModalOpen, setIsEmailModalOpen] = useState(false);
  const [clearEmailError, setClearEmailError] = useState(false);
  const [isOpenVerificationModal, setIsOpenVerificationModal] = useState(false);
  const [referralAmount, setReferralAmount] = useState<number | null>(null);
  const [isOpenPaidEarlyModal, setIsOpenPaidEarlyModal] = useState(false);

  const productId = useTypedSelector(productIdSelector);
  const price = useTypedSelector(pricesSelector);
  const user = useTypedSelector(userSelector);
  const defaultPrices = useTypedSelector(defaultPricesSelector);
  const isFirstLoan = useTypedSelector(isFirstLoanSelector);
  const bankDetails = useTypedSelector(userBankDetailsSelector);

  const bankDetailsFromCookies = useHookFormGetCache(KEY_BANK_ACCOUNT_DETAILS);

  const isCloseEmailModal = getCookie(KEY_CLOSE_EMAIL_MODAL);

  useEffect(() => {
    const userBankDetails = {
      [IbanNumberFields.BankName]:
        bankDetailsFromCookies[IbanNumberFields.BankName] || user?.bank_name,
      [IbanNumberFields.BankBranchName]:
        bankDetailsFromCookies[IbanNumberFields.BankBranchName] || user?.bank_branch_name,
      [IbanNumberFields.Iban]:
        bankDetailsFromCookies[IbanNumberFields.Iban] || user?.bank_account_number,
    };

    dispatch(setBankDetails(userBankDetails));
  }, []);

  useEffect(() => {
    if (price) {
      dispatch(getCalculatorConfigActions());
    }
  }, [price]);

  const handleClearEmailError = () => setClearEmailError(true);
  const handleOpenEmailModal = () => setIsEmailModalOpen(true);
  const handleCloseEmailModal = () => setIsEmailModalOpen(false);
  const handleOpenVerificationEmailModal = () => setIsOpenVerificationModal(true);

  useEffect(() => {
    const notificationData = localStorage.getItem(KEY_NOTIFICATION_DATA);

    if (notificationData) {
      const data = JSON.parse(notificationData);
      if (new Date(data.maturedAt) > new Date() && data.action === 'click') {
        setIsOpenPaidEarlyModal(true);
      }
      localStorage.removeItem(KEY_NOTIFICATION_DATA);
    }
  }, []);

  const onSubmit: SubmitCoreHandler<LoanCalculatorForm> = async (values, { setError }) => {
    const promoCode = LoanService.promoCode;

    return dispatch(
      createApplication({ ...values, promoCode, productId, bankDetails, referralAmount }),
    )
      .unwrap()
      .then(() => {
        dispatch(setRedirect({ to: `${URL_ACTIVE_LOAN}` }));
        dispatch(signIn());
        LoanService.setSum = values.amount;
        LoanService.setTerm = values.term;
        removeCookie(WpKeys.LoanPromoCode);
        removeCookie(INIT_VALUES_FORM_KEY);
      })
      .catch((err) => {
        const message = getPromoCodeErrorMessage(err.message) || err.violations[0].message;
        setError && setError('root.commonError', { type: 'validate', message });
        if (err.error_code === 1) {
          handleOpenEmailModal();
        } else {
          removeCookie(WpKeys.LoanPromoCode);
          const data = {
            userId: getUserID(),
            productId,
            isFirstLoan,
          };
          dispatch(getProduct(data));
        }
      });
  };

  const onChangeReferralAmountSlider = (name: string, value: number) => {
    setReferralAmount(value);
  };

  return price && !!defaultPrices.default_principal ? (
    <>
      <LoanCalculator
        onSubmit={onSubmit}
        price={price}
        defaultPrices={defaultPrices}
        clearEmailError={clearEmailError}
        isReferralPage={isReferralPage}
        referralInfo={user?.referral_info}
        referralAmount={referralAmount}
        onChangeReferralAmountSlider={onChangeReferralAmountSlider}
      />
      <BankDetailsModalContainer isFirstLoan={isFirstLoan} isReferralPage={isReferralPage} />
      {isEmailModalOpen || (!isFirstLoan && !user?.email && !isCloseEmailModal) ? (
        <AddEmailModalContainer
          isFirstLoan={isFirstLoan}
          handleCloseEmailModal={handleCloseEmailModal}
          handleClearEmailError={handleClearEmailError}
          handleOpenVerificationEmailModal={handleOpenVerificationEmailModal}
        />
      ) : null}
      {isOpenVerificationModal && <EmailVerificationModal />}
      {isOpenPaidEarlyModal && <PaidEarlyModal />}
    </>
  ) : (
    <Spinner />
  );
};
