import { ReactElement, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { formatNumber } from 'core/utils/parsers';
import { TooltipItem } from 'components/Tooltip';
import { TooltipInfoIcon2 } from 'components/icons';
import { ILoanSettings } from 'utils';
import { BankDetails } from 'pages/account';
import { CURRENCY } from 'constantsLk';

interface AccountLoanConditionsValuesProps {
  loanSettings: ILoanSettings | { term: string; amount: string };
  isReferralPage?: boolean;
  returnAmount: {
    settlement_amount: number;
    maturity_date: string;
    settlement_amount_discounted: string;
    loan_amount: number;
  };
}

export const AccountLoanConditionsValues = ({
  loanSettings,
  returnAmount,
  isReferralPage,
}: AccountLoanConditionsValuesProps) => {
  const { t } = useTranslation();

  const { maturity_date, loan_amount } = returnAmount;

  const loanValues = useMemo(
    () => [
      {
        label: 'loan_amount',
        value: <div className='info-item'>{`${formatNumber(loan_amount)} ${CURRENCY}`}</div>,
      },
      {
        label: 'payment_date',
        value: <div className='info-item'>{`${maturity_date}`}</div>,
      },
    ],
    [loanSettings, returnAmount],
  );

  return (
    <div className={`flex-column ${isReferralPage && 'pt-2 border-top-light'}`}>
      {loanValues.map((item: { label: string; value: ReactElement }, index) => (
        <div
          key={item.label}
          className={`justify-content-between align-items-center ${!isReferralPage ? 'border-bottom-light pb-3 mb-3' : 'mt-2'} ${!isReferralPage && index === 0 ? 'hidden-tablet' : ''}`}
        >
          <div className='flex-nowrap'>
            <span className='txt-sb txt-xs'>{t(item.label)}</span>
            {item.label === 'payment_date' && (
              <TooltipItem
                wrapperClassName={'payment_date-tooltip'}
                title={t(item.label)}
                content={t`payment_date_tooltip`}
                icon={<TooltipInfoIcon2 />}
              />
            )}
          </div>
          {item.value}
        </div>
      ))}

      {!isReferralPage && <BankDetails />}
    </div>
  );
};
