import { UseFormClearErrors } from 'react-hook-form';
import { getUserID, removeCookie } from 'core/services';
import { WpKeys } from 'core/models';
import { useTypedSelector } from 'core/hooks';
import { isFirstLoanSelector, productIdSelector } from 'store/selectors';
import { useAppDispatch, usePromoCode } from 'hooks';
import { getProduct } from 'store/actions/api';
import { LoanCalculatorForm } from 'models';
import { PromoCodeFieldEnterButton } from 'components/PromoCodeField';

interface PromoCodeProps {
  clearErrors: UseFormClearErrors<LoanCalculatorForm>;
}

export const AccountLoanCalculatorPromoCodeContainer = (props: PromoCodeProps) => {
  const { clearErrors } = props;
  const dispatch = useAppDispatch();

  const productId = useTypedSelector(productIdSelector);
  const isFirstLoan = useTypedSelector(isFirstLoanSelector);

  const { formState, value, onEnterPromoCode, onResetCode, onConfirm } = usePromoCode({
    clearErrors,
    handlePromoCodeAction: async (promoCode) => {
      const data = { productId, userId: getUserID(), isFirstLoan };
      try {
        await dispatch(getProduct({ ...data, promoCode })).unwrap();
      } catch (err: any) {
        removeCookie(WpKeys.LoanPromoCode);
        await dispatch(getProduct(data));
        throw err;
      }
    },
  });

  return (
    <PromoCodeFieldEnterButton
      onResetCode={onResetCode}
      formState={formState}
      value={value}
      onConfirm={onConfirm}
      onToggle={onEnterPromoCode}
    />
  );
};
