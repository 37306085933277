import { useTranslation, Trans } from 'react-i18next';
import { ModalLayout } from 'core/components/common';
import { ReferralMainIcon } from '../../icons';

interface ReferralInfoModalProps {
  handleModalClose: () => void;
}

export const ReferralInfoModal = (props: ReferralInfoModalProps) => {
  const { handleModalClose } = props;

  const { t } = useTranslation();

  const text = [
    {
      isImportant: false,
      title: '',
      value: 'referral_modal_text1',
    },
    // {
    //   isImportant: true,
    //   title: 'campaign_period',
    //   value: 'referral_modal_text2',
    // },
    {
      isImportant: false,
      title: 'how_to_invite_a_friend',
      value: 'referral_modal_text3',
    },
    {
      isImportant: false,
      title: 'how_to_earn_a_reward',
      value: 'referral_modal_text4',
    },
    {
      isImportant: true,
      title: 'key_points',
      value: 'referral_modal_text5',
    },
    {
      isImportant: false,
      title: 'how_to_use_your_bonuses',
      value: 'referral_modal_text6',
    },
    {
      isImportant: false,
      title: 'important_notes',
      value: 'referral_modal_text7',
    },
    {
      isImportant: false,
      title: 'start_sharing_today',
      value: '',
    },
  ];

  return (
    <ModalLayout
      isOutSideClick
      onClose={handleModalClose}
      isOpenModal={true}
      classes={{ wrapper: 'referral-modal' }}
      isShowClose
      isUsePortal
      title={
        <div className='referral-modal__title'>
          <ReferralMainIcon />
        </div>
      }
      content={
        <div className='flex-column gap-4'>
          <p className='txt-md txt-sb'>{t`referral_modal_title`}</p>
          {text.map((item, index) => (
            <div
              className={`flex-column gap-2 ${item.isImportant ? 'referral-modal__important' : ''}`}
              key={index}
            >
              {!!item.title && (
                <p className={`${item.isImportant ? 'txt' : 'txt-md-16'} txt-sb`}>
                  <Trans i18nKey={item.title} />
                </p>
              )}

              {item.value && (
                <div className='flex-column gap-2'>
                  <Trans i18nKey={item.value} />
                </div>
              )}
            </div>
          ))}
        </div>
      }
    />
  );
};
