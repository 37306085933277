import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { isMobile } from 'react-device-detect';
import { ArrowRightIcon, CloseLightIcon, InstallAppIcon } from '../icons';

declare global {
  interface WindowEventMap {
    beforeinstallprompt: BeforeInstallPromptEvent;
    appinstalled: Event;
  }
}

interface BeforeInstallPromptEvent extends Event {
  prompt: () => Promise<void>;
  userChoice: Promise<{ outcome: 'accepted' | 'dismissed' }>;
}

interface InstallAppButtonProps {
  isShowInstallAppButton: boolean;
  handleShowInstallAppButton: () => void;
  handleHideInstallAppButton: () => void;
}

export const InstallAppButton = (props: InstallAppButtonProps) => {
  const { isShowInstallAppButton, handleShowInstallAppButton, handleHideInstallAppButton } = props;
  const { t } = useTranslation();

  const [deferredPrompt, setDeferredPrompt] = useState<BeforeInstallPromptEvent | null>(null);

  useEffect(() => {
    const handleBeforeInstallPrompt = (e: BeforeInstallPromptEvent) => {
      e.preventDefault();
      setDeferredPrompt(e);
      handleShowInstallAppButton();
    };

    window.addEventListener('beforeinstallprompt', handleBeforeInstallPrompt);
    window.addEventListener('appinstalled', handleHideInstallAppButton);

    if ((window.navigator as any).standalone === true) {
      handleHideInstallAppButton();
    }

    return () => {
      window.removeEventListener('beforeinstallprompt', handleBeforeInstallPrompt);
      window.removeEventListener('appinstalled', handleHideInstallAppButton);
    };
  }, []);

  const handleInstallClick = async () => {
    if (!deferredPrompt) return;

    await deferredPrompt.prompt();

    setDeferredPrompt(null);
    handleHideInstallAppButton();
  };

  return isShowInstallAppButton ? (
    <div className='pwa border-bottom-blue w-100'>
      <div className='container m-auto'>
        <div className='pwa-wrap flex-center pt-2 pb-2'>
          <div className='flex-center cursor-pointer gap-2 pwa-btn' onClick={handleInstallClick}>
            <div className='pwa-btn__icon flex-center'>
              <InstallAppIcon />
            </div>
            <span className='txt txt-md-16'>
              {t(isMobile ? 'install_app_mobile' : 'install_app_desktop')}
            </span>
            <div className='pwa-btn__arrow flex-center'>
              <ArrowRightIcon />
            </div>
          </div>
          <div
            className='flex-center cursor-pointer ml-3 pwa-btn__close'
            onClick={handleHideInstallAppButton}
          >
            <CloseLightIcon />
          </div>
        </div>
      </div>
    </div>
  ) : null;
};
