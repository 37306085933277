import { isIOS } from 'react-device-detect';
import { useAppDispatch } from 'hooks';
import { Loan } from 'models';
import { getAgreeDocument, getAgreements } from 'store/actions/api';
import { LoanCard } from './LoanCard';
import { TopUpStatus } from 'components';

interface LoanCardProps {
  loan: Loan;
  canDownloadDocument: boolean;
}

export const LoanCardContainer = (props: LoanCardProps) => {
  const { loan, canDownloadDocument } = props;
  const dispatch = useAppDispatch();

  const handleGetAgreement = (param?: string) => {
    dispatch(getAgreements({ idApp: loan?.application_id, param }))
      .unwrap()
      .then((resAgreements) => {
        dispatch(getAgreeDocument(resAgreements[resAgreements.length - 1]?.id))
          .unwrap()
          .then((resAgreementsDocument) => {
            const url = window.URL.createObjectURL(resAgreementsDocument);
            window.open(url, isIOS ? '_self' : '_blank');
          });
      });
  };

  return (
    <LoanCard
      onGetAgrees={handleGetAgreement}
      loan={loan}
      canDownloadDocument={canDownloadDocument}
      canDownloadAdditionalDocument={
        loan.top_up_info &&
        (loan.top_up_info.state === TopUpStatus.Processed ||
          loan.top_up_info.state === TopUpStatus.Successful)
      }
    />
  );
};
