import { useTranslation } from 'react-i18next';

export const ServerError = () => {
  const { t } = useTranslation();

  return (
    <div className='form__wrapper justify-content-center'>
      <div className='flex-center gap-6 flex-column flex-grow-1'>
        <h1 className='gradient-title'>500</h1>
        <div className='txt txt-r txt-center'>{t`server_error_text`}</div>
      </div>
    </div>
  );
};
