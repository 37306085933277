import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { format } from 'date-fns';
import { Title } from 'components';
import { Loan } from 'models';
import { Repayment } from '../ActiveLoan/Repayment';
import { DATE_FORMAT } from 'constantsLk';

interface LoanSoldPageProps {
  isPossibleToPay: boolean;
  saleInfo: {
    company_name?: string;
    email?: string;
    phone_number?: string;
    sold_at?: string;
    bank_account_holder_name?: string;
    bank_account_number?: string;
    bank_branch?: string;
  };
  loan?: Loan | null;
}

export const LoanSoldPage = ({ saleInfo, isPossibleToPay, loan }: LoanSoldPageProps) => {
  const { t } = useTranslation();

  const soldLoanInfo = useMemo(() => {
    return [
      {
        label: 'sold_at',
        value: saleInfo?.sold_at && format(new Date(saleInfo?.sold_at), DATE_FORMAT),
      },
      {
        label: 'company_name',
        value: saleInfo?.company_name,
      },
      {
        label: 'email_sold',
        value: saleInfo?.email,
      },
      {
        label: 'phone_number',
        value: saleInfo?.phone_number,
      },
      {
        label: 'bank_name',
        value: saleInfo?.bank_account_holder_name,
      },
      {
        label: 'bank_branch_name',
        value: saleInfo?.bank_branch,
      },
      {
        label: 'bank_account_number',
        value: saleInfo?.bank_account_number,
      },
    ];
  }, [saleInfo]);

  return (
    <>
      <Title name={'your_loan_has_been_sold'} classes={'txt-center justify-content-start mb-1'} />
      <p className='txt txt-error mb-4'>{t('pay_the_debt_immediately')}</p>

      <div className='w-100 flex mw-70 m-auto mb-6'>
        {loan && isPossibleToPay && <Repayment activeCreditData={loan} />}
      </div>

      <div className='mb-6 w-100 mw-70 m-auto'>
        <div className='sold-grid card gap-3 flex-column'>
          {soldLoanInfo.map(({ label, value }) => {
            return (
              value && (
                <div key={label} className='lined-card-row'>
                  <p>{t(label)}</p>
                  <div className='info-item'>{value}</div>
                </div>
              )
            );
          })}
        </div>
      </div>
    </>
  );
};
