export const BIRTHDAY_YEARS = Array.from(
  { length: 100 },
  (_, i) => `${new Date().getFullYear() - i}`,
);

export const MONTH_NAMES = [
  'january',
  'february',
  'march',
  'april',
  'may',
  'june',
  'july',
  'august',
  'september',
  'october',
  'november',
  'december',
];
