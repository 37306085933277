import { ModalLayout } from 'core/components/common';
import { ErrorIcon } from '../../icons';

interface ErrorShareModalProps {
  handleCloseErrorModal: () => void;
  errorMessage: string | null;
}

export const ErrorShareModal = (props: ErrorShareModalProps) => {
  const { handleCloseErrorModal, errorMessage } = props;

  return (
    <ModalLayout
      isOpenModal
      onClose={handleCloseErrorModal}
      title={<ErrorIcon />}
      classes={{ wrapper: 'modal-core__wrapper--email-verified' }}
      isShowClose
      isUsePortal
      content={
        <div className='flex-column gap-2 mt-3'>
          <p className='txt-md txt-sb'>{errorMessage}</p>
        </div>
      }
    />
  );
};
