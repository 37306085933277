import { useTranslation } from 'react-i18next';
import { WpModalInfo } from 'core/models';
import { ClockIcon, MailIcon, PhoneIcon } from 'components/icons';
import { ORIGIN_LOCATION } from 'constantsLk';

interface FooterProps {
  phone?: string;
  email?: string;
  workingHours?: string;
  copyright?: string;
  footerDocs?: WpModalInfo[];
}

const footerLinks = [
  { title: 'Privacy Policy', path: 'privacy-policy' },
  { title: 'Term & Conditions', path: 'term-conditions' },
];

export const Footer = (props: FooterProps) => {
  const { phone, email, workingHours, copyright } = props;
  const { t } = useTranslation();

  const handleRedirect = (path: string) => {
    window.open(`${ORIGIN_LOCATION}/en/${path}/`, '_blank');
  };

  return (
    <footer className='footer'>
      <div className='container'>
        <div className='flex gap-4 mb-6'>
          <div className='footer-col align-items-stretch'>
            <span className='footer-icon'>
              <PhoneIcon />
            </span>
            <div className='flex-column'>
              <a href={`tel: ${phone}`} className='txt-b txt-md-16 flex-grow-1'>
                {phone}
              </a>
              <span className='txt-base'>{t('seven_days_a_week')}</span>
            </div>
          </div>
          <div className='footer-col'>
            <div className='footer-icon'>
              <MailIcon />
            </div>
            <a href={`mailto: ${email}`} className='txt-b txt-md-16'>
              {email}
            </a>
          </div>
          <div className='footer-col'>
            <div className='footer-icon'>
              <ClockIcon />
            </div>
            <div className='txt-b'>{workingHours}</div>
          </div>
        </div>
        <div className='flex gap-6 mt-2 mb-6'>
          <div
            className='txt-base txt-r'
            dangerouslySetInnerHTML={{ __html: copyright ? copyright : '' }}
          />
        </div>
        <div className='flex gap-6'>
          {footerLinks.map((item) => (
            <span key={item.path} onClick={() => handleRedirect(item.path)} className='footer-link'>
              {item.title}
            </span>
          ))}
        </div>
      </div>
    </footer>
  );
};
