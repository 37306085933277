// import iconSI from '*.svg';
// import iconEN from '*.svg';

import { SETTINGS } from '../core/settings';
import { LangProps } from '../models';

export const API_PATH =
  process.env.API_PATH || 'https://staging-master-ke8rgq.cashx-lk.avgr.it/api';

export const WP_PATH = process.env.WP_PATH || 'https://cashx.lk';
export const ORIGIN_LOCATION = window.location.origin;

const testProd = () => ENVIRONMENT === PRODUCTION && location.origin.match(/(localhost)/);

export const ENVIRONMENT = process.env.NODE_ENV;
export const PRODUCTION = 'production';
export const DEVELOPMENT = testProd() ? 'production' : 'development';
export const TIMER_WITH_CODE = 120;
export const TIMER_WITH_CALL = 60;
export const SINHALESE_LANGUAGE: LangProps = {
  value: 'si',
  label: 'සිංහල',
  // labelGTM: 'sl',
};
export const ENGLISH_LANGUAGE: LangProps = {
  value: 'en',
  label: 'Eng',
  // labelGTM: 'eng',
};
export const DEFAULT_LANGUAGE = SINHALESE_LANGUAGE;
export const LANGUAGES: LangProps[] = [ENGLISH_LANGUAGE, SINHALESE_LANGUAGE];

export const COUNT_DAYS_SAVE_COOKIE = 30;
export const COUNT_DAYS_SAVE_FORM_VALUES = 2;

export const DEFAULT_SUM_LOAN = 5000;
export const DEFAULT_TERM_LOAN = 5;

export const PROMO_FIRST_LOAN_DEFAULT_TERM = 'firstloan';
export const ASIA_TIMEZONE = { timeZone: 'Asia/Colombo' };

export const IS_DEVELOPER_MODE = SETTINGS.isDevelop || SETTINGS.isDevelopStage;

export const INCORRECT_DATA = 'incorrectData';

export const COUNTRY_PHONE_CODE_NUMBER = '94';
export const COUNTRY_PHONE_CODE = `+${COUNTRY_PHONE_CODE_NUMBER}`;
export const DATE_FORMAT = 'dd.MM.yyyy';
export const BIRTHDAY_DATE_FORMAT = 'yyyy.MM.dd';
export const SERVER_DATE_FORMAT = 'yyyy-MM-dd';
export const formatMaturity = 'eeee dd MMM yyyy';
export const DATE_TIME_FORMAT = 'dd.MM.yyyy, hh:mm a';

export const TODAY = new Date();
export const MAX_BIRTH_NIC_ISSUE_DAY = TODAY;
export const MAX_USER_AGE = 60;
export const MIN_USER_AGE = 20;

export const CURRENCY = 'RS';

export const SLIDER_THEME = ['rgb(42, 70, 147)', 'rgb(228, 239, 255)'];

export const ITEM_STATUS = {
  DEFAULT: 'default',
  ACTIVE: 'active',
  PENDING: 'pending',
  ERROR: 'error',
  SUCCESS: 'success',
};

export const LIVENESS = {
  HYPERVERGE: 'hyperverge',
  OZFORENCIS: 'ozforencis',
  BIOID: 'bio_id',
};

export const LIVENESS_DETECTIONS = [
  LIVENESS.HYPERVERGE,
  LIVENESS.OZFORENCIS,
  LIVENESS.BIOID,
] as const;

export const VARIANT = {
  A: 'A',
  B: 'B',
};

export const HYPERVERGE_URL =
  'https://hv-camera-web-sg.s3-ap-southeast-1.amazonaws.com/hyperverge-web-sdk@3.3.5/src/sdk.min.js';
export const OZFORENCIS_URL_SANDBOX =
  'https://web-sdk.cdn.sandbox.ozforensics.com/cashx/plugin_liveness.php';
export const OZFORENCIS_URL = 'https://web-sdk.cdn.prod.ozforensics.com/cashx/plugin_liveness.php';

export const RECAPTCHA_KEY = '6LfLzucoAAAAADA7MUjeUB5zhEp1J-9JZ5dMc3-j';
export const RECAPTCHA_KEY_TEST = '6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI';

export const COLLECTION_TEAM_PHONE_NUMBER = '94763106244';
export const REPAYMENT_SLIP_PHONE_NUMBER = '94742478024';
export const WHATSAPP_PHONE = '+94742477523';
export const PARTNERS_LINK = 'https://track.leadbazaar.co/click?pid=5376&offer_id=407';
export const getLoanPlusLink = (screen: number) => {
  return `https://loanplus.lk/?utm_source=loanplus_screen${screen}&utm_medium=web&utm_campaign=cashx_loan_active&utm_term=2024 `;
};

export const SENT_PAYMENT = 'sent_payment';

export const getWhatsappLink = (phone: string, loanId: number | undefined, personalId: string) => {
  return `https://api.whatsapp.com/send/?phone=%2B${phone}&text=loanID:%20${loanId}${
    personalId.length ? `,%20NIC:%20${personalId}` : ''
  }&type=phone_number&app_absent=0`;
};

export const userAgent = navigator.userAgent.toLowerCase();
export const isAndroid = navigator.userAgent.includes('Android');
