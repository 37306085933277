import { useState } from 'react';
import { UseFormSetError } from 'react-hook-form';
import { UseFormTrigger } from 'react-hook-form/dist/types/form';
import { useLocation } from 'react-router-dom';
import { SETTINGS } from 'core/settings';
import { SubmitCoreHandler } from 'core/models';
import { getAttempts } from 'store/actions/api';
import { useAppDispatch } from 'hooks';
import { removeAllTimerCookies } from 'utils';
import { AuthForm, AuthFormFields, AuthFormModel } from './AuthForm';
import { KEY_CAPTCHA_IGNORE } from 'constantsLk';

export interface AuthFormContainerProps {
  onSubmit: SubmitCoreHandler<AuthFormModel>;
  storageKey?: string;
  isLoginPage?: boolean;
}

export const AuthFormContainer = (props: AuthFormContainerProps) => {
  const { onSubmit, storageKey, isLoginPage } = props;

  const dispatch = useAppDispatch();
  const location = useLocation();

  const isCaptchaIgnore =
    localStorage.getItem(KEY_CAPTCHA_IGNORE) === 'true' && SETTINGS.isDevelopStage;

  const [isShowCaptcha, setIsShowCaptcha] = useState(false);
  const [captchaValue, setCaptchaValue] = useState<string | null>(null);

  const onSubmitHandler = async (
    data: AuthFormModel,
    setError: UseFormSetError<any>,
    trigger: UseFormTrigger<any>,
  ) => {
    removeAllTimerCookies();
    try {
      if (!location.state?.isOpenModal && !captchaValue && !isCaptchaIgnore) {
        await dispatch(getAttempts(data[AuthFormFields.Phone])).unwrap();
        onSubmit?.(data, { setError });
      } else {
        await onSubmit?.(data, { setError, trigger });
      }
    } catch (error) {
      setIsShowCaptcha(true);
    }
  };

  return (
    <AuthForm
      onSubmitHandler={onSubmitHandler}
      storageKey={storageKey}
      isShowCaptcha={isShowCaptcha}
      setCaptchaValue={setCaptchaValue}
      captchaValue={captchaValue}
      isLoginPage={isLoginPage}
    />
  );
};
