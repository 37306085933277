import { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { useTypedSelector } from 'core/hooks';
import { ModalLayout } from 'core/components/common';

import {
  wpContentHeaderSelector,
  userSelector,
  isAppConfirmedApplicationSelector,
  isLoanPendingSelector,
  isLoanActiveSelector,
  isFirstLoanSelector,
} from 'store/selectors';
import { logOutUtil } from 'utils';
import { SidebarBody, SidebarHead, ExitToWPModal } from 'components/modals';
import { Header } from './Header';
import * as Paths from 'constantsLk/paths';
import { ORIGIN_LOCATION } from 'constantsLk';
import { InstallAppButton } from '../InstallAppButton';

export const HeaderContainer = () => {
  const location = useLocation();
  const { i18n } = useTranslation();
  const lang = i18n.language;
  const wpContentHeader = useTypedSelector(wpContentHeaderSelector);
  const isAppConfirmedApplication = useTypedSelector(isAppConfirmedApplicationSelector);
  const isLoanPending = useTypedSelector(isLoanPendingSelector);
  const isLoanActive = useTypedSelector(isLoanActiveSelector);
  const isFirstLoan = useTypedSelector(isFirstLoanSelector);
  const user = useTypedSelector(userSelector);

  const isAccount = location.pathname.includes(Paths.PATH_ACCOUNT);

  const [isBarExpanded, setIsBarExpanded] = useState(false);
  const [isOpenExitModal, setIsOpenExitModal] = useState(false);
  const [isShowInstallAppButton, setIsShowInstallAppButton] = useState(false);

  const isShowButton =
    isShowInstallAppButton &&
    (isFirstLoan ? isAppConfirmedApplication && (isLoanPending || isLoanActive) : isAccount);

  const handleSetIsBarExpanded = () => setIsBarExpanded(true);

  const handleSetIsBarCollapsed = () => setIsBarExpanded(false);
  const handleShowInstallAppButton = () => setIsShowInstallAppButton(true);
  const handleHideInstallAppButton = () => setIsShowInstallAppButton(false);

  const handleLogout = () => {
    logOutUtil();
    window.location.reload();
  };

  const redirectToWP = () => {
    window.location.href = `${ORIGIN_LOCATION}${lang === 'en' ? '/en' : ''}`;
  };

  const handleClickOnLogo = () => {
    if (!isAccount) {
      setIsOpenExitModal(true);
    } else {
      redirectToWP();
    }
  };

  const handleCloseExitModal = () => setIsOpenExitModal(false);

  return (
    <>
      <InstallAppButton
        isShowInstallAppButton={isShowButton}
        handleShowInstallAppButton={handleShowInstallAppButton}
        handleHideInstallAppButton={handleHideInstallAppButton}
      />

      <Header
        user={user}
        headerMenu={wpContentHeader?.menu}
        phone={wpContentHeader?.contacts?.phone}
        onBarExpand={handleSetIsBarExpanded}
        onClickLogo={handleClickOnLogo}
        isAccount={isAccount}
        isShowInstallAppButton={isShowButton}
      />

      <ModalLayout
        isOutSideClick
        onClose={handleSetIsBarCollapsed}
        isOpenModal={isBarExpanded}
        classes={{ wrapper: 'sidebar' }}
        isShowClose
        isUsePortal
        title={
          <SidebarHead
            phone={wpContentHeader?.contacts?.phone}
            workingHours={wpContentHeader?.contacts?.schedule}
            onClickLogo={handleClickOnLogo}
            user={user}
          />
        }
        content={
          <SidebarBody
            headerMenu={wpContentHeader?.menu}
            phone={wpContentHeader?.contacts?.phone}
            workingHours={wpContentHeader?.contacts?.schedule}
            onLogout={handleLogout}
          />
        }
      />

      <ExitToWPModal
        isOpenModal={isOpenExitModal}
        onClose={handleCloseExitModal}
        handleClickOnLogo={handleClickOnLogo}
        redirectToWP={redirectToWP}
      />
    </>
  );
};
