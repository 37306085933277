import {
  ChangeEventHandler,
  FocusEvent,
  FocusEventHandler,
  ReactElement,
  ReactNode,
  useRef,
  useState,
} from 'react';
import ReactInputMask from 'react-input-mask';
import cn from 'classnames';

import { ErrorMessage, FieldHint } from 'core/components/common';
import { ChangeCoreEventHandler, MaskedProps } from 'core/models';
import { useHookFormFieldError } from 'core/hooks';

export interface TextFieldProps
  extends Omit<Partial<MaskedProps>, 'onChange' | 'onBlur' | 'onFocus'> {
  name: string;
  parsers?: Array<(value: string) => string>;
  onChange?: ChangeCoreEventHandler;
  onFocus?: (name: string, value: string, e: FocusEvent<HTMLInputElement>) => void;
  onBlur?: (name: string, value: string, e: FocusEvent<HTMLInputElement>) => void;
  label?: string | ReactElement;
  error?: string;
  hint?: string;
  leftContent?: string | JSX.Element;
  rightContent?: string | JSX.Element;
  innerRef?: any;
  children?: ReactNode;
  isNumberField?: boolean;
  classes?: {
    root?: string;
    label?: string;
    input?: string;
    error?: string;
    hint?: string;
    elementLeft?: string;
    elementRight?: string;
  };
}

export const TextField = (props: TextFieldProps) => {
  const {
    onChange,
    parsers,
    onBlur,
    onFocus,
    label,
    name,
    error,
    hint,
    classes = {},
    value,
    leftContent,
    rightContent,
    mask = '',
    isNumberField,
    children,
    ...rest
  } = props;

  const [isFocus, setIsFocus] = useState<boolean>(false);
  const getFieldError = useHookFormFieldError();

  const isEmpty = !!`${props.value || ''}`.length || isFocus;

  const handleBlur: FocusEventHandler<HTMLInputElement> = (e) => {
    onBlur && onBlur(name, e.target.value, e);
    setIsFocus(false);
  };

  const handleFocus: FocusEventHandler<HTMLInputElement> = (e) => {
    onFocus && onFocus(name, e.target.value, e);
    setIsFocus(true);
  };

  const handleChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    const value = e.target.value;
    let val = parsers
      ? parsers.reduce((acc: string, cur: (v: string) => string): string => cur(acc), value)
      : (value as number | string);

    if (isNumberField && !!val) {
      val = +val;
    }
    onChange?.(name, val, e);
  };

  const inputReference = useRef(null);

  const errorMessage = getFieldError(error);

  return (
    <div
      className={cn(
        'input-box',
        { 'input-box__invalid': !!error },
        { 'input-box__active': isFocus },
        { 'input-box__no-empty': isEmpty },
        { 'input-box__disabled': props.disabled },
        classes.root,
      )}
    >
      {label && (
        <label className={cn('input-box__label', classes.label)} htmlFor={name}>
          {label}
        </label>
      )}
      <div className={cn('input-box__input-wrapper')}>
        {leftContent && (
          <span className={cn('input-box__element_left', classes.elementLeft)}>{leftContent}</span>
        )}
        <ReactInputMask
          value={typeof value == 'number' ? value : value || ''}
          onChange={handleChange}
          onBlur={handleBlur}
          onFocus={handleFocus}
          className={cn(classes.input)}
          name={name}
          mask={mask}
          id={name}
          inputRef={inputReference}
          {...rest}
        />
        {rightContent && (
          <span className={cn('input-box__element_right', classes.elementRight)}>
            {rightContent}
          </span>
        )}
        {children}
      </div>
      {error ? (
        <ErrorMessage classError={classes.error} message={errorMessage} />
      ) : hint ? (
        <FieldHint classes={classes.hint} hint={hint} />
      ) : null}
    </div>
  );
};
