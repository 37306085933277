import { ReactElement } from 'react';
import { Tooltip } from 'react-tooltip';
import ReactDOMServer from 'react-dom/server';
import { TooltipInfoIcon } from '../icons';
import cn from 'classnames';

interface TooltipItemProps {
  title: string;
  content: string;
  wrapperClassName?: string;
  icon?: ReactElement;
}

export const TooltipItem = (props: TooltipItemProps) => {
  const { title, content, wrapperClassName, icon } = props;

  return (
    <div className={cn('flex', wrapperClassName)}>
      <div
        data-tooltip-id='item-1'
        className={'cursor-pointer flex-center'}
        data-tooltip-html={ReactDOMServer.renderToStaticMarkup(
          <div className='flex-column gap-1'>
            <p className='txt txt-sb'>{title}</p>
            <p className='txt'>{content}</p>
          </div>,
        )}
      >
        {icon || <TooltipInfoIcon />}
      </div>
      <Tooltip id='item-1' openOnClick place='top' closeEvents={{ click: true }} offset={5} />
    </div>
  );
};
