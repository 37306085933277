import { useTranslation } from 'react-i18next';

import { useTypedSelector } from 'core/hooks';

import { incomesSelector, isLoanActiveSelector } from 'store/selectors';
import { Income } from 'models';
import { isEmptyObject } from 'utils/isEmptyObject';
import { AccountPaymentHistoryPage } from './AccountPaymentHistoryPage';

const AccountPaymentHistoryContainer = () => {
  const { t } = useTranslation();
  const incomes = useTypedSelector(incomesSelector);
  const isLoanActive = useTypedSelector(isLoanActiveSelector);

  return (
    <>
      {incomes && !isEmptyObject(incomes) && isLoanActive ? (
        <>
          <p className='w-100 txt-center txt-md txt-b mb-8'>{t`payments_history_last_30_days`}</p>
          {Object.entries(incomes).map(
            ([period, periodPayments]: [string, Income[] | null], index) => (
              <AccountPaymentHistoryPage
                key={index}
                period={period}
                periodPayments={periodPayments}
              />
            ),
          )}
        </>
      ) : (
        <p className='w-100 txt-center txt-md txt-b mb-8'>{t`not_payments_history`}</p>
      )}
    </>
  );
};

export default AccountPaymentHistoryContainer;
