import { ReactElement } from 'react';
import { Title } from 'components';
import cn from 'classnames';

interface RequestStatusProps {
  icon?: ReactElement;
  title?: string;
  text?: JSX.Element | string;
  footer?: JSX.Element | string;
  showScheduleInfo?: boolean;
  isShowPartners?: boolean;
  isAuthPage?: boolean;
}

export const RequestStatus = (props: RequestStatusProps) => {
  const { icon, text, title, footer, isShowPartners, isAuthPage } = props;

  return (
    <section className='flex-column flex-center gap-6 flex-grow-1 w-100'>
      <div
        className={cn(
          'request-status-section ',
          isShowPartners ? 'border-r-2' : '',
          isAuthPage ? 'auth' : '',
        )}
      >
        {icon && <div className='mb-6 icon'>{icon}</div>}
        {title && <Title name={title} classes={'request-status-title mb-2'} />}
        <div className='request-status-text mb-2'>{text}</div>
        {footer && <div className='request-status-footer'>{footer}</div>}
      </div>
    </section>
  );
};
