// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const bankInstructions = (nic: string, loanNumber: number) => {
  return [
    // {
    //   name: 'CARGILLS BANK LIMITED',
    //   title: 'Cargills Bank transfer',
    //   subtitle: 'cargills_bank_subtitle',
    //   instruction: 'any_bank_instruction',
    //   data: [
    //     {
    //       title: 'Cargills Bank transfer',
    //       icon: <CargillsBankIcon />,
    //       holderName: 'A V BUSINESS SOLUTIONS (PRIVATE) LIMITED',
    //       number: '001952000011',
    //       branch: 'Head Office Branch',
    //       nic,
    //       loanNumber,
    //     },
    //   ],
    // },
    // {
    //   name: 'SAMPATH BANK PLC',
    //   title: 'Sampath Bank PLC',
    //   subtitle: 'sampath_bank_subtitle',
    //   instruction: 'sampath_bank_instruction',
    //   data: [
    //     {
    //       title: 'Sampath Bank transfer',
    //       icon: <SampathBankIcon />,
    //       holderName: 'A V BUSINESS SOLUTION (PRIVATE) LIMITED',
    //       number: '017510007263',
    //       branch: 'Colombo Super Branch',
    //       nic,
    //       loanNumber,
    //     },
    //   ],
    // },
    // {
    //   name: 'HATTON NATIONAL BANK PLC',
    //   title: 'Hatton National Bank PLC (HNB)',
    //   subtitle: 'hatton_bank_subtitle',
    //   data: [
    //     {
    //       title: 'Hatton National Bank PLC (HNB)',
    //       icon: <HattonBankIcon />,
    //       holderName: 'A V BUSINESS SOLUTIONS (PRIVATE) LIMITED',
    //       number: '039010234589',
    //       branch: 'BAMBALAPITIYA BRANCH',
    //       nic,
    //       loanNumber,
    //     },
    //   ],
    // },
    // {
    //   name: 'Commercial Bank transfer',
    //   title: 'Commercial Bank transfer',
    //   subtitle: 'Commercial Bank transfer',
    //   instruction: 'Commercial Bank transfer',
    //   data: [
    //     {
    //       title: 'Commercial Bank transfer',
    //       icon: <CommercialBankIcon />,
    //       holderName: 'A V BUSINESS SOLUTIONS (PRIVATE) LIMITED',
    //       number: '1000384886',
    //       branch: 'Colombo 07',
    //       isWeekend: isBankWeekend(),
    //       nic,
    //       loanNumber,
    //     },
    //   ],
    // },
  ];
};

export const anyBankInstruction = (nic: string, loanNumber: number) => {
  return [
    {
      title: 'any_bank_title',
      subtitle: 'any_bank_subtitle',
      data: [...bankInstructions(nic, loanNumber).map((item: any) => item.data[0])],
      instruction: 'any_bank_instruction',
      isAnyBank: true,
    },
  ];
};
