import { LogoIcon } from 'components/icons';

interface LogoProps {
  onClickLogo: () => void;
}

export const Logo = (props: LogoProps) => {
  const { onClickLogo } = props;

  return (
    <span className='cursor-pointer logo' onClick={onClickLogo}>
      <LogoIcon />
    </span>
  )
}
