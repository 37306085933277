import { createAsyncThunk } from '@reduxjs/toolkit';

import { getUserID } from 'core/services';

import { Actions, Loan, ServerLoanSettings } from 'models';
import { getDefaultCalculatorConfig } from './api';

export const getCalculatorConfigActions = createAsyncThunk<ServerLoanSettings>(
  Actions.GET_DEFAULT_CALCULATOR_CONFIG_ACTION,
  async (data, { dispatch, rejectWithValue }) => {
    try {
      const id = getUserID();
      const data = id ? { user_id: id } : {};
      return await dispatch(getDefaultCalculatorConfig(data)).unwrap();
    } catch (errors) {
      return rejectWithValue(errors);
    }
  },
);

export const getLoanBySlugAction = createAsyncThunk<Loan, Loan>(
  Actions.GET_LOAN_BY_SLUG,
  async (data, { rejectWithValue }) => {
    try {
      return data;
    } catch (errors) {
      return rejectWithValue(errors);
    }
  },
);
