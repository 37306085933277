import { formatDistance, formatRelative } from 'date-fns';
import formatLong from './_lib/formatLong';
import localize from './_lib/localize';
import match from './_lib/match';

/**
 * @type {Locale}
 * @category Locales
 * @summary Sinhala locale.
 * @language Sinhala
 * @iso-639-2 sin
 // * @author Dilshan [@dilshan-h]{@link https://github.com/dilshan-h}
 */
const locale: Locale = {
  code: 'si',
  formatDistance: formatDistance,
  formatLong: formatLong,
  formatRelative: formatRelative,
  localize: localize,
  match: match,
  options: {
    weekStartsOn: 1 /* Monday */,
    firstWeekContainsDate: 4,
  },
};

export default locale;
