import { useTranslation } from 'react-i18next';

import { Button, ModalLayout } from 'core/components/common';

interface ExitToWPModalProps {
  onClose: () => void;
  isOpenModal: boolean;
  handleClickOnLogo: () => void;
  redirectToWP: () => void;
}

export const ExitToWPModal = (props: ExitToWPModalProps) => {
  const { t } = useTranslation();

  const { onClose, isOpenModal, redirectToWP } = props;

  return (
    <ModalLayout
      isOpenModal={isOpenModal}
      onClose={onClose}
      title={t`exit_modal_title`}
      classes={{ wrapper: 'modal-core__wrapper--exit' }}
      isShowClose
      isUsePortal
      footer={
        <>
          <Button classes={{ root: 'btn--primary w-100 mb-5' }} onClick={onClose}>
            {t('exit_modal_no')}
          </Button>
          <Button className='link flex-center' onClick={redirectToWP}>
            {t('exit_modal_yes')}
          </Button>
        </>
      }
    />
  );
};
