import { FC, FunctionComponent, ReactElement, SVGProps, useState } from 'react';
import { useController, UseFormSetValue } from 'react-hook-form';
import { SubmitCoreHandler } from 'core/models';
import { setRedirect } from 'core/store/actions';
import { sendDataLayer } from 'core/services';
import { useAppDispatch } from 'hooks';
import { DocumentsType } from 'models';
import { validateRequiredPhoto } from 'utils/validates/validation';
import { UploadPhotoContainer } from './UploadPhotoContainer';
import { getUserInfo } from 'store/actions/api';
import { GTM_LABEL_SIGN_UP } from 'utils/analitics/gtmLabel';
import { DUPLICATE_ACTION, getRegStepPath } from 'constantsLk';

export interface IPhotoUploadInitStateProps {
  state: string;
  file: any;
  file_type: string | undefined;
  error: string;
}

export interface IUploadPhotoComponentFormProps {
  [DocumentsType.IDCard]: any;
  [DocumentsType.IDCardBackSide]: any;
}

export interface IUploadPhoto {
  onSubmit?: SubmitCoreHandler<IUploadPhotoComponentFormProps>;
}

export interface IUploadPhotoComponentProps extends IUploadPhoto {
  control?: any;
  setValue?: UseFormSetValue<IUploadPhotoComponentFormProps>;
  loadedFileType?: DocumentsType;
  changeTypeFile: (type: string, file: File) => { file: File; type: string };
  sendFile: ({ file, type }: { file: File; type: string }) => Promise<any>;
  document: IPhotoDocuments;
  clearErrors?: any;
  onSubmitHandler?: (e?: React.BaseSyntheticEvent<object, any, any> | undefined) => Promise<void>;
  withoutFAQ?: boolean;
  sendLog?: (action: string, additional_data: string, source_user_id?: number) => any;
}

export interface IIdCardInstructionsProps {
  icon: ReactElement;
  name: string;
  success: string;
  error: string;
}

export interface IPhotoDocuments {
  infoWithFile: string;
  titleWithFile: string;
  srcDefault: JSX.Element | FunctionComponent<SVGProps<SVGSVGElement>>;
  label: string;
  type: string;
  title: string;
  subtitle: string;
  footerInfo?: string;
  instructions: IIdCardInstructionsProps[];
}

export const defaultUploadFileState: IPhotoUploadInitStateProps = {
  state: '',
  file: null,
  file_type: undefined,
  error: '',
};

export const UploadPhotoComponent: FC<IUploadPhotoComponentProps> = ({
  control,
  loadedFileType,
  sendFile,
  document,
  onSubmitHandler,
  withoutFAQ,
  sendLog,
}) => {
  const dispatch = useAppDispatch();

  const initialFileUploadState = {
    ...defaultUploadFileState,
    file_type: loadedFileType,
  };

  const {
    field: { onChange },
    fieldState: { error },
  } = useController({
    name: document.type,
    control,
    rules: validateRequiredPhoto,
  });

  const [stateComponent, setStateComponent] =
    useState<IPhotoUploadInitStateProps>(initialFileUploadState);

  const onUpload = (file: File) => {
    setStateComponent({ ...stateComponent, state: 'load', error: '' });

    const isUploadedFileImage = file.type.includes('image');

    sendFile({ file, type: document.type })
      .then((res) => {
        const data: IPhotoUploadInitStateProps = {
          ...defaultUploadFileState,
          state: 'load',
          file: isUploadedFileImage ? URL.createObjectURL(file) : null,
          file_type: res.type,
        };
        onChange && onChange(true);
        setStateComponent(data);

        sendLog && sendLog(DUPLICATE_ACTION.SEND_IMAGE, 'Successfully sent');

        if (onSubmitHandler) {
          // 8_2 step
          onSubmitHandler().then();
        } else {
          // 8_3 step
          sendDataLayer('upload_docs_id_back', GTM_LABEL_SIGN_UP);

          return dispatch(getUserInfo())
            .unwrap()
            .then((res) => {
              dispatch(setRedirect({ to: getRegStepPath(res.registration_step), replace: true }));
            })
            .catch((err) => {
              sendLog && sendLog(DUPLICATE_ACTION.USER_INFO_ERROR, JSON.stringify(err));
            });
        }
      })
      .catch((err) => {
        onChange && onChange(false);
        setStateComponent({
          ...defaultUploadFileState,
          state: 'error',
          error: err.message ? err.message : 'errorFile',
        });
        sendLog && sendLog(DUPLICATE_ACTION.SEND_IMAGE, JSON.stringify(err));
      });
  };

  return (
    <UploadPhotoContainer
      onUpload={onUpload}
      stateComponent={stateComponent}
      setStateComponent={setStateComponent}
      key={document.type}
      error={stateComponent.error || error?.message}
      item={document}
      withoutFAQ={withoutFAQ}
      sendLog={sendLog}
    />
  );
};
