import React, { FC } from 'react';
import { useAppDispatch } from 'hooks';
import { UseFormSetError } from 'react-hook-form';

import { useTypedSelector } from 'core/hooks';

import { ITimerValue } from 'models/common';
import { resendCodeAgree } from 'store/actions/api';
import { TimerButtonItem } from 'components';
import { activeApplicationSelector } from 'store/selectors';
import './styles.scss';

interface ResendLoanCodeContainerProps {
  setError: UseFormSetError<any>;
  isShowTimer: boolean;
  showTimer: () => any;
  timerValue: ITimerValue;
  hintText?: string;
  hideTimer: () => void;
  onResendCode?: () => void;
}

export const ResendLoanCodeContainer: FC<ResendLoanCodeContainerProps> = ({
  timerValue,
  isShowTimer,
  showTimer,
  onResendCode,
}) => {
  const dispatch = useAppDispatch();
  const activeApplication = useTypedSelector(activeApplicationSelector);

  const startTimerHandler = async (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();

    if (activeApplication) {
      await showTimer();
      dispatch(resendCodeAgree(activeApplication?.id))
        .unwrap()
        .then(() => {
          if (onResendCode) {
            onResendCode();
          }
        })
        .catch(() => {})
        .finally(() => {});
    }
  };

  return (
    <div className='align-items-center gap-4'>
      <TimerButtonItem
        isShowTimer={isShowTimer}
        startTimerHandler={startTimerHandler}
        secondString={timerValue.secondString}
        minutesString={timerValue.minutesString}
        buttonText={'resend_code'}
        isCodeConfirmation
      />
    </div>
  );
};
