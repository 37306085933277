import { BaseSyntheticEvent } from 'react';
import { useTypedSelector } from 'core/hooks';
import { IPhotoDocuments, IPhotoUploadInitStateProps } from '../UploadPhotoComponent';
import { DocumentsType } from 'models';
import { HyperVerge } from './HyperVerge';
import { OzForensics } from './OzForensics';
import { BioID } from './BioID';
import { livenessEngineSelector } from 'store/selectors';
import { LIVENESS } from 'constantsLk';

export interface livenessProps {
  isShowSelfieInstructionsDialog: boolean;
  onClose: () => void;
  defaultUploadFileState: IPhotoUploadInitStateProps;
  documentFile: IPhotoDocuments;
  sendLog: (context: any, engine: string) => Promise<any>;
  setStateComponent: (value: IPhotoUploadInitStateProps) => void;
  setValue: (name: DocumentsType.Selfie, value: any) => void;
  handleShowIfAccessDeniedDialog?: () => void;
  handleCloseCameraModal?: () => void;
  onSubmitHandler: (livenessCheckId?: number) => (e?: BaseSyntheticEvent) => Promise<void>;
  handleAccessDeniedAfterRequest: () => void;
  setErrorsArray: (errors: string[]) => void;
  setIsLoadingSDK?: (val: boolean) => void;
}

export const LivenessEngines = (props: livenessProps) => {
  const livenessEngine = useTypedSelector(livenessEngineSelector);

  const selfieVendor = () => {
    switch (livenessEngine) {
      case LIVENESS.HYPERVERGE:
        return <HyperVerge {...props} />;
      case LIVENESS.BIOID:
        return <BioID {...props} />;
      case LIVENESS.OZFORENCIS:
        return <OzForensics {...props} />;
      default:
        return null;
    }
  };

  return selfieVendor();
};
