import React, { useEffect, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { useTypedSelector } from 'core/hooks';
import { livenessProps } from './LivenessEngines';
import { FileTakeSelfieModal } from '../FileTakePhotoModal';
import { userClickedItsClearButtonSelector } from 'store/selectors';
import { isWebView } from 'utils';
import { LIVENESS } from 'constantsLk';

export const BioID = (props: livenessProps) => {
  const {
    isShowSelfieInstructionsDialog,
    onClose,
    sendLog,
    handleShowIfAccessDeniedDialog,
    handleAccessDeniedAfterRequest,
  } = props;

  const [isCameraAllow, setCameraAllow] = useState(true);
  const [isShowCameraModal, setIsShowCameraModal] = useState(false);

  const userClickedItsClearButton = useTypedSelector(userClickedItsClearButtonSelector);

  const onTakeSelfie = () => {
    sendLog({ event: 'Click to Take Selfie button' }, LIVENESS.BIOID);

    onClose();
    if (isCameraAllow) {
      setIsShowCameraModal(true);
    } else if (isMobile && handleShowIfAccessDeniedDialog) {
      handleShowIfAccessDeniedDialog();
    } else {
      setIsShowCameraModal(false);
    }
  };

  const handleCloseCameraModal = () => {
    setIsShowCameraModal(false);
    onClose();
  };

  useEffect(() => {
    isMobile && userClickedItsClearButton && runFaceDetectorLibrary();
  }, [userClickedItsClearButton]);

  const runFaceDetectorLibrary = () => {
    let stream: MediaStream | undefined;

    const askCameraPermission = async (): Promise<MediaStream> => {
      return await navigator.mediaDevices.getUserMedia({ video: true });
    };

    askCameraPermission()
      .then((mediaStream) => {
        stream = mediaStream;
        setCameraAllow(true);
        sendLog({ cameraPermission: true, is_webview: isWebView() }, LIVENESS.BIOID);
      })
      .catch(() => {
        setCameraAllow(false);
        handleAccessDeniedAfterRequest();
        sendLog({ cameraPermission: false, is_webview: isWebView() }, LIVENESS.BIOID);
      })
      .finally(() => {
        if (stream) {
          stream.getTracks().forEach((track) => track.stop());
        }
      });
  };

  useEffect(() => {
    if (isShowSelfieInstructionsDialog) {
      onTakeSelfie();
    }
  }, [isShowSelfieInstructionsDialog]);

  return isShowCameraModal ? (
    <FileTakeSelfieModal handleCloseCameraModal={handleCloseCameraModal} {...props} />
  ) : null;
};
