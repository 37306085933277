import React, { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { format } from 'date-fns';
import en from 'date-fns/locale/en-US';
import si from 'langSI';
import { Button } from 'core/components/common';
import { SubmitCoreHandler, WpKeys } from 'core/models';
import { removeCookie } from 'core/services';
import { LoanService } from 'services';
import { getLoanSettings } from 'utils';
import {
  IPrice,
  LoanCalculatorFields,
  LoanCalculatorForm,
  ReferralInfo,
  ServerLoanSettings,
  TabType,
} from 'models';
import {
  AccountLoanConditionsValues,
  BonusButtons,
  Calculator,
  ErrorMessageCommon,
  ReferralSlider,
  Spinner,
} from 'components';
import { AccountLoanCalculatorPromoCodeContainer } from './PromoCode';
import { CURRENCY, formatMaturity } from 'constantsLk';

interface LoanCalculatorProps {
  price: IPrice;
  onSubmit: SubmitCoreHandler<LoanCalculatorForm>;
  defaultPrices: ServerLoanSettings;
  clearEmailError: boolean;
  isReferralPage?: boolean;
  referralInfo?: ReferralInfo;
  referralAmount: number | null;
  onChangeReferralAmountSlider: (name: string, val: number) => void;
}

export const LoanCalculator = (props: LoanCalculatorProps) => {
  const {
    onSubmit,
    price,
    defaultPrices,
    clearEmailError,
    isReferralPage,
    referralInfo,
    referralAmount,
    onChangeReferralAmountSlider,
  } = props;
  const { t, i18n } = useTranslation();
  const lang = i18n.language;

  const selectedLanguage = lang === 'en' ? en : si;

  const [bonusTabType, setBonusTabType] = useState(TabType.Promo);

  const loanSettings = useMemo(() => {
    return getLoanSettings(price, defaultPrices);
  }, [price, defaultPrices]);

  const {
    handleSubmit,
    watch,
    setValue,
    getValues,
    setError,
    clearErrors,
    formState: { isSubmitting, errors },
  } = useForm<LoanCalculatorForm>({
    mode: 'onSubmit',
    defaultValues: {
      term: loanSettings.term,
      amount: loanSettings.amount,
    },
  });

  useEffect(() => {
    if (clearEmailError) {
      clearErrors('root.commonError');
    }
  }, [clearEmailError]);

  const formTerm = watch(LoanCalculatorFields.Term);
  const formAmount = watch(LoanCalculatorFields.Amount);

  const returnAmount = useMemo(() => {
    const currentTerm = price?.[formTerm];
    const settlement_amount_discounted = currentTerm?.[formAmount]?.discount.settlement_amount
      ? (
          currentTerm?.[formAmount]?.settlement_amount -
          currentTerm?.[formAmount]?.discount.settlement_amount
        ).toString()
      : '';
    const formattedMaturityDate = currentTerm?.[formAmount]?.maturity_date
      ? format(new Date(currentTerm?.[formAmount]?.maturity_date), formatMaturity, {
          locale: selectedLanguage,
        })
      : '';

    return {
      loan_amount: formAmount,
      maturity_date: formattedMaturityDate,
      settlement_amount: currentTerm?.[formAmount]?.settlement_amount,
      settlement_amount_discounted,
      referral_calculator: currentTerm?.[formAmount]?.referral_calculator,
    };
  }, [formTerm, formAmount, price, lang]);

  const onChangeSlider = (name: LoanCalculatorFields, value: number) => {
    value && setValue(name, value);
  };

  const onSubmitHandler = handleSubmit((data) =>
    onSubmit?.(data, {
      setError,
    }),
  );

  useEffect(() => {
    if (isReferralPage && returnAmount.referral_calculator) {
      removeCookie(WpKeys.LoanPromoCode);
    }
  }, []);

  const handleChangeBonusType = (type: TabType) => {
    if (LoanService.promoCode && type === TabType.Bonuses) {
      removeCookie(WpKeys.LoanPromoCode);
    }
    if (referralAmount && type === TabType.Promo) {
      onChangeReferralAmountSlider(LoanCalculatorFields.Amount, 0);
    }
    setBonusTabType(type);
  };

  return (
    <>
      {price && !isReferralPage ? (
        <div className='card card-group'>
          <div className='card-group-item'>
            <div className='w-100 flex-column gap-6'>
              <div className='form-title txt-b'>{t`submit`}</div>
              <Calculator
                price={price}
                settings={loanSettings}
                conditions={getValues()}
                onChangeSlider={onChangeSlider}
              />
            </div>
          </div>

          <div className='card-group-item'>
            <div
              className={`w-100 flex-column ${returnAmount.referral_calculator ? 'gap-3' : 'gap-6'}`}
            >
              <AccountLoanConditionsValues
                loanSettings={loanSettings}
                returnAmount={returnAmount}
                isReferralPage={isReferralPage}
              />

              {returnAmount.referral_calculator && (
                <>
                  <BonusButtons
                    bonusTabType={bonusTabType}
                    handleChangeBonusType={handleChangeBonusType}
                  />
                  {bonusTabType === TabType.Bonuses && (
                    <ReferralSlider
                      onChangeReferralAmountSlider={onChangeReferralAmountSlider}
                      sliderData={returnAmount.referral_calculator}
                      referralAmount={referralAmount}
                    />
                  )}
                </>
              )}

              <form onSubmit={onSubmitHandler} className='flex-column gap-6'>
                {bonusTabType === TabType.Promo && (
                  <AccountLoanCalculatorPromoCodeContainer clearErrors={clearErrors} />
                )}

                {errors?.root?.commonError && (
                  <ErrorMessageCommon error={errors?.root?.commonError} />
                )}

                <Button disabled={isSubmitting} classes={{ root: 'btn--primary w-100' }}>
                  {t`apply_for_loan`}
                </Button>
              </form>
            </div>
          </div>
        </div>
      ) : price && isReferralPage ? (
        <div className='card'>
          <div className='w-100'>
            <h2 className='txt-md txt-sb mb-4'>
              {t(
                `${!referralInfo?.bonus_amount ? 'apply_for_loan' : 'apply_loan_and_use_bonuses'}`,
              )}
            </h2>
            <div className='card-bg flex-column gap-1 mb-4'>
              <span className='txt'>{t`you_have_on_your_balance`}</span>
              <p className='txt txt-md txt-b'>
                {referralInfo?.bonus_amount} {CURRENCY}
              </p>
            </div>

            <Calculator
              price={price}
              settings={loanSettings}
              conditions={getValues()}
              onChangeSlider={onChangeSlider}
            />
          </div>

          <div className='w-100 flex-column gap-4'>
            <AccountLoanConditionsValues
              loanSettings={loanSettings}
              returnAmount={returnAmount}
              isReferralPage={isReferralPage}
            />

            {returnAmount.referral_calculator && (
              <ReferralSlider
                onChangeReferralAmountSlider={onChangeReferralAmountSlider}
                sliderData={returnAmount.referral_calculator}
                referralAmount={referralAmount}
                title={t`use_bonuses_for_fees`}
              />
            )}

            <form onSubmit={onSubmitHandler} className='flex-column gap-6'>
              {!returnAmount.referral_calculator && (
                <AccountLoanCalculatorPromoCodeContainer clearErrors={clearErrors} />
              )}

              {errors?.root?.commonError && (
                <ErrorMessageCommon error={errors?.root?.commonError} />
              )}

              <Button disabled={isSubmitting} classes={{ root: 'btn--primary w-100' }}>
                {t`apply_for_loan`}
              </Button>
            </form>
          </div>
        </div>
      ) : (
        <Spinner />
      )}
    </>
  );
};
