import { ModalLayout } from 'core/components/common';

interface InfoDialogProps {
  title: string;
  content: any;
  footer?: any;
}

export const UploadIdPhotoModal = (props: InfoDialogProps) => {
  const { title, content, footer } = props;

  return (
    <ModalLayout
      isOpenModal
      title={title}
      isShowClose={false}
      isUsePortal
      classes={{ wrapper: 'form__wrapper' }}
      content={content}
      footer={footer}
      showAppHeader
    />
  );
};
