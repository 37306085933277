import { Dispatch, SetStateAction, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useForm, UseFormSetError } from 'react-hook-form';
import { useLocation } from 'react-router-dom';
import ReCAPTCHA from 'react-google-recaptcha';
import { SETTINGS } from 'core/settings';
import { Button } from 'core/components/common';
import { validatePhone } from 'utils/validates/validation';
import { useHookFormGetCache, useHookFormSetCache } from 'hooks';
import { ConfirmNumberModal } from 'components/modals';
import { CommonServerErrorMessage } from '../CommonServerErrorMessage';
import { PhoneFieldFocusValidation } from '../fields';
import { COUNTRY_PHONE_CODE, RECAPTCHA_KEY, RECAPTCHA_KEY_TEST } from 'constantsLk';
import { UseFormTrigger } from 'react-hook-form/dist/types/form';

export enum AuthFormFields {
  Phone = 'phone_number',
}

export interface AuthFormModel {
  [AuthFormFields.Phone]: string;
}

export interface AuthFormProps {
  onSubmitHandler: (
    data: AuthFormModel,
    setError: UseFormSetError<any>,
    trigger: UseFormTrigger<any>,
  ) => void;
  storageKey?: string;
  isShowCaptcha: boolean;
  setCaptchaValue: Dispatch<SetStateAction<string | null>>;
  captchaValue: string | null;
  isLoginPage?: boolean;
}

export const AuthForm = (props: AuthFormProps) => {
  const { onSubmitHandler, storageKey, isShowCaptcha, setCaptchaValue, captchaValue, isLoginPage } =
    props;

  const location = useLocation();
  const { t, i18n } = useTranslation();

  const defaultValues = useHookFormGetCache(storageKey);

  const phoneRef = useRef<HTMLInputElement | null>(null);

  const {
    handleSubmit,
    watch,
    control,
    setError,
    formState: { isSubmitting, isValid, errors },
    setValue,
    clearErrors,
    trigger,
  } = useForm<AuthFormModel>({
    mode: 'onBlur',
    defaultValues: {
      ...defaultValues,
      ...(location?.state?.phone && { [AuthFormFields.Phone]: location.state.phone }),
    },
  });

  const handlePhoneChange = (name: any, value: string | number) => {
    if (value && value.toString()[0] !== '7') {
      trigger(name).then(() => setValue(name, ''));
    } else {
      clearErrors(name);
    }
  };

  useHookFormSetCache(storageKey, watch);

  const phone = watch(AuthFormFields.Phone);

  const getPhoneRef = (elem: HTMLInputElement) => (phoneRef.current = elem);

  const onSubmitForm = handleSubmit(async (data) => {
    await onSubmitHandler(data, setError, trigger);
  });

  return (
    <form className='w-100' onSubmit={onSubmitForm}>
      <PhoneFieldFocusValidation
        leftContent={COUNTRY_PHONE_CODE}
        label={t(AuthFormFields.Phone)}
        control={control}
        inputMode='numeric'
        name={AuthFormFields.Phone}
        inputRef={getPhoneRef}
        rules={validatePhone}
        onChange={handlePhoneChange}
      />

      {isShowCaptcha && (
        <ReCAPTCHA
          className='m-auto'
          sitekey={SETTINGS.isDevelopStage ? RECAPTCHA_KEY_TEST : RECAPTCHA_KEY}
          onChange={setCaptchaValue}
          hl={i18n.language}
        />
      )}

      {isLoginPage && errors?.root && (
        <CommonServerErrorMessage message={errors?.root?.serverError.message} />
      )}

      <ConfirmNumberModal
        loading={isSubmitting}
        phone={phone}
        errors={errors}
        isLoginPage={isLoginPage}
      />

      <Button
        disabled={isSubmitting || !isValid || (!captchaValue && isShowCaptcha)}
        classes={{ root: 'btn--primary' }}
      >
        {t`continue`}
      </Button>
    </form>
  );
};
