import { useTranslation } from 'react-i18next';
import { SubmitCoreHandler } from 'core/models';
import { AddEmailModal } from '../AddEmailModal';
import { ShareViaSmsModal } from './ShareViaSmsModal';
import { EmailFormModel } from 'models';
import { prepareEmail } from 'utils';
import { sendReferralLink } from 'store/actions/api';
import { useAppDispatch } from 'hooks';
import { AuthFormFields, AuthFormModel } from '../../AuthForm';

interface ShareModalContainerProps {
  handleCloseModal: () => void;
  openModalType: 'sms' | 'email';
  handleOpenSuccessModal: () => void;
  handleOpenErrorModal: () => void;
  setErrorMessage: (val: string) => void;
}

export const ShareModalContainer = (props: ShareModalContainerProps) => {
  const {
    handleCloseModal,
    openModalType,
    handleOpenSuccessModal,
    handleOpenErrorModal,
    setErrorMessage,
  } = props;

  const dispatch = useAppDispatch();
  const { i18n } = useTranslation();

  const handleSendReferralLink = async (data: { email?: string; phone_number?: string }) => {
    return dispatch(
      sendReferralLink({ ...data, locale: i18n.language === 'si' ? 'si_SI' : 'en_EN' }),
    )
      .unwrap()
      .then(() => {
        handleOpenSuccessModal();
      })
      .catch((e) => {
        setErrorMessage(e.message || e.detail);
        handleOpenErrorModal();
      });
  };

  const onSubmitEmail: SubmitCoreHandler<EmailFormModel> = async (values) => {
    const email = prepareEmail(values);

    const data = { email };

    return handleSendReferralLink(data);
  };

  const onSubmitSms: SubmitCoreHandler<AuthFormModel> = async (values) => {
    const data = {
      phone_number: values[AuthFormFields.Phone],
    };

    return handleSendReferralLink(data);
  };

  if (openModalType === 'email') {
    return (
      <AddEmailModal
        isOpenModal
        handleClose={handleCloseModal}
        onSubmit={onSubmitEmail}
        isShareModal
      />
    );
  } else {
    return <ShareViaSmsModal isOpenModal handleClose={handleCloseModal} onSubmit={onSubmitSms} />;
  }
};
