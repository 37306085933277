import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useController } from 'react-hook-form';
import { UseControllerProps } from 'react-hook-form/dist/types/controller';
import cn from 'classnames';

import { ErrorMessage } from 'core/components/common';

import { RadioField } from './RadioField';
import { useHookFormFieldError } from 'core/hooks';

export interface RadioFieldsProps
  extends Pick<UseControllerProps<any>, 'name' | 'control' | 'rules'> {
  options: RadioField[];
  name: string;
  label?: string;
  classes?: Partial<Record<'root' | 'error' | 'radio' | 'label', string>>;
}

export const RadioFields = (props: RadioFieldsProps) => {
  const { options, classes = {}, name, label, control, rules } = props;

  const { t } = useTranslation();

  const {
    field: { onChange, value },
    fieldState: { error },
  } = useController({
    name,
    control,
    rules,
  });

  const getFieldError = useHookFormFieldError();
  const handleChange = useCallback((name: string, value: string) => onChange(value), []);

  const errorMessage = getFieldError(error?.message);

  return (
    <div className={cn('radio-box', classes.root)}>
      {label && <p className={cn('radio-box__label', classes.label)}>{t(label)}</p>}
      <div className='radio-box__options'>
        {options.map((option) => (
          <RadioField
            key={option.value}
            name={name}
            onChange={handleChange}
            className={classes.radio}
            value={value}
            option={option}
          />
        ))}
      </div>
      {error?.message && <ErrorMessage classError={classes.error} message={errorMessage} />}
    </div>
  );
};
