import cn from 'classnames';
import { ChangeEvent, FC } from 'react';
import { useController } from 'react-hook-form';
import { UseControllerProps } from 'react-hook-form/dist/types/controller';

import { trim } from 'core/utils/parsers';
import { TextField, TextFieldProps } from 'core/components/fields';

import { onlyNumeric } from 'utils';
import { beforeMaskedValueChangePhone } from 'utils/functions';
import { MASK_MOB_PHONE } from 'constantsLk';

export type PhoneFieldFocusValidationProps = TextFieldProps &
  Pick<UseControllerProps<any>, 'name' | 'control' | 'rules'>;

export const PhoneFieldFocusValidation: FC<PhoneFieldFocusValidationProps> = (props) => {
  const { name, control, rules, leftContent, onChange, ...rest } = props;

  const {
    field,
    fieldState: { error },
  } = useController({
    name,
    control,
    rules,
  });

  const handleChange = (name: string, value: string | number, e: ChangeEvent<HTMLInputElement>) => {
    e.target.value = value as string;
    field.onChange(e);
    onChange && onChange(name, value, e);
  };

  return (
    <TextField
      error={error?.message}
      value={field.value}
      onChange={handleChange}
      leftContent={leftContent}
      inputMode='tel'
      mask={MASK_MOB_PHONE}
      placeholder={''}
      maskChar=''
      type='tel'
      parsers={[trim, onlyNumeric]}
      beforeMaskedValueChange={beforeMaskedValueChangePhone}
      name={name}
      {...rest}
      onBlur={field.onBlur}
      classes={{
        ...rest?.classes,
        root: cn(leftContent ? 'input-box__prefix' : '', rest?.classes?.root),
      }}
    />
  );
};
