import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { createUser } from '../actions/api';
import { setAuthorized } from '../actions';

export interface authSliceInitialState {
  username: string | null;
  isAuthorized: boolean;
  isShowPartner: boolean;
}

const initialState: authSliceInitialState = {
  username: null,
  isAuthorized: false,
  isShowPartner: false,
};

export const authSlice = createSlice({
  name: 'authSlice',
  initialState,
  reducers: {
    setShowPartnerLogin: (state, action: PayloadAction<boolean>) => {
      state.isShowPartner = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(createUser.fulfilled, (state, action) => {
      const { user } = action.payload;
      state.username = user.username;
    });

    builder.addCase(setAuthorized, (state, action) => {
      state.isAuthorized = action.payload;
    });
  },
});

export const { setShowPartnerLogin } = authSlice.actions;
