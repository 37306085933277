import { createSlice } from '@reduxjs/toolkit';
import { getUserApplications } from '../actions/api';
import { Application } from 'models';

interface InitialUserState {
  applications: Application[] | null;
  activeApplication: Application | null;
}

const initialState: InitialUserState = {
  applications: null,
  activeApplication: null,
};

export const applicationSlice = createSlice({
  name: 'applicationSlice',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getUserApplications.fulfilled, (state, action) => {
      const applications = action.payload;
      state.applications = applications;
      if (applications.length > 0) {
        state.activeApplication = applications[applications.length - 1];
      }
    });
  },
});
