import { Outlet } from 'react-router-dom';
import { useTypedSelector } from 'core/hooks';
import { EmailConfirmationModal, LoginForbidden, SuccessfulPaymentAlert } from 'components';
import {
  isEmailVerifiedSelector,
  isShowPartnerAuthSelector,
  isSuccessfullyPaymentSelector,
} from 'store/selectors';

export const LoginLayout = () => {
  const isEmailVerified = useTypedSelector(isEmailVerifiedSelector);
  const isShowPartner = useTypedSelector(isShowPartnerAuthSelector);
  const isSuccessfullyPayment = useTypedSelector(isSuccessfullyPaymentSelector);

  if (isShowPartner) {
    return <LoginForbidden />;
  }

  return (
    <>
      {/*<NoticesContainer typeNotice={NoticeTypes.Alerts} />*/}
      {isSuccessfullyPayment && <SuccessfulPaymentAlert />}

      <div className='form__wrapper'>
        <Outlet />
      </div>
      {isEmailVerified && <EmailConfirmationModal />}
    </>
  );
};
