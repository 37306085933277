import { useTranslation } from 'react-i18next';
import { ModalLayout } from 'core/components/common';
import { SuccessIcon } from '../../icons';

export const EmailVerificationModal = () => {
  const { t } = useTranslation();

  return (
    <ModalLayout
      isOpenModal
      title={<SuccessIcon />}
      classes={{ wrapper: 'modal-core__wrapper--email-verified' }}
      isShowClose
      isUsePortal
      content={
        <div className='flex-column gap-2 mt-3'>
          <p className='txt-md txt-sb'>{t`we_have_got_your_email_address`}</p>
          <p className='txt'>{t`open_the_email`}</p>
        </div>
      }
    />
  );
};
