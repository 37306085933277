import { useTranslation } from 'react-i18next';

import { CameraAccessDeniedFAQModal } from './CameraAccessDeniedFAQModal';
import { AnotherBrowserIcon, GoogleChromeIcon, SafariIcon } from '../../icons';
import { CameraAccessDeniedWebViewModal } from './CameraAccessDeniedWebViewModal';
import { isFacebookWebview } from 'utils';
import { setIsErrorCamera } from 'store/slices';
import { useAppDispatch } from 'hooks';
import { userAgent } from 'constantsLk';
import { googleChromeInstruction, safariInstruction } from 'constantsLk/selfieInstructions';

interface InfoDialogContainerProps {
  onClose: () => void;
}

export const CameraAccessDeniedFAQModalContainer = (props: InfoDialogContainerProps) => {
  const { onClose } = props;

  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const getMenu = (menu: string[]) => {
    return menu.map((item, index) => (
      <p key={item}>
        <strong>{index + 1}. </strong>
        {t(item)}
      </p>
    ));
  };

  const contentDropdown = [
    {
      trigger: t`using_google_chrome`,
      menuItem: [
        <div className='w-100 flex-column gap-2' key='1'>
          {getMenu(googleChromeInstruction)}
        </div>,
      ],
      icon: <GoogleChromeIcon />,
      browser: /chrome/,
    },
    {
      trigger: t`using_safari`,
      menuItem: [
        <div className='w-100 flex-column gap-2' key='2'>
          {getMenu(safariInstruction)}
        </div>,
      ],
      icon: <SafariIcon />,
      browser: /safari/,
    },
    {
      trigger: t`using_another_browser`,
      menuItem: [
        <div className='w-100 flex-column gap-2' key='3'>
          <p>{t`another_browser_instruction`}</p>
        </div>,
      ],
      icon: <AnotherBrowserIcon />,
    },
  ];

  const getContent = () => {
    const content = contentDropdown.find((item) => item.browser?.test(userAgent));

    return !content ? contentDropdown[contentDropdown.length - 1] : content;
  };

  const handleClose = () => {
    dispatch(setIsErrorCamera(false));
    onClose();
  };

  return isFacebookWebview ? (
    <CameraAccessDeniedWebViewModal onClose={handleClose} />
  ) : (
    <CameraAccessDeniedFAQModal onClose={handleClose} content={getContent()} />
  );
};
