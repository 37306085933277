import { Outlet } from 'react-router-dom';
import { useTypedSelector } from 'core/hooks';
import { isShowPartnerAuthSelector } from 'store/selectors';
import { RegistrationProgress } from 'components/RegistrationProgress';
import { LoginForbidden } from 'components';

export const RegistrationLayout = () => {
  const isShowPartner = useTypedSelector(isShowPartnerAuthSelector);

  if (isShowPartner) {
    return <LoginForbidden />;
  }

  return (
    <>
      {/*<NoticesContainer typeNotice={NoticeTypes.Alerts} />*/}
      <div className='form__wrapper'>
        <RegistrationProgress />
        <Outlet />
      </div>
    </>
  );
};
