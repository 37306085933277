import { useHookFormFieldError } from 'core/hooks';
import cn from 'classnames';

export interface ErrorMessageProps {
  classError?: string;
  error: Partial<{ type: string | number; message: string }>;
}

export const ErrorMessageCommon = (props: ErrorMessageProps) => {
  const { classError, error } = props;
  const getFieldError = useHookFormFieldError();

  return <span className={cn('error-text', classError)}>{getFieldError(error?.message)}</span>;
};
