import React from 'react';
import { useTranslation } from 'react-i18next';
import { CloseIcon } from 'core/components/common';
import { LoanPlusIcon } from '../icons';
import { getLoanPlusLink, KEY_OPENED_PARTNER_LINK } from 'constantsLk';

interface NeedMoreMoneyProps {
  screen: number;
  onClose: () => void;
  loanId?: number;
}

export const NeedMoreMoney = ({ screen, onClose, loanId }: NeedMoreMoneyProps) => {
  const { t } = useTranslation();

  const handleClick = () => {
    localStorage.setItem(KEY_OPENED_PARTNER_LINK, `${loanId || null}`);
    onClose();
  };

  return (
    <section
      className={`flex-column flex-center gap-6 flex-grow-1 w-100 tablet-mb-6 ${screen === 1 ? 'mb-2' : 'mb-4'}`}
    >
      <div className='other-section flex-column border-r-2'>
        <div className='justify-content-between align-items-start gap-3 w-100'>
          <h2 className='txt-md txt-sb mb-2'>{t`need_more_money`}</h2>
          {screen === 2 && (
            <span className='cursor-pointer align-items-center' onClick={onClose}>
              <CloseIcon />
            </span>
          )}
        </div>
        <p className='txt'>{t`apply_loan_from_partners`}</p>
        <div className='flex-center w-100 info-item mt-4 gap-3'>
          <span className='icon flex-center'>
            <LoanPlusIcon />
          </span>
          <a
            className='btn btn--primary w-100'
            href={getLoanPlusLink(screen)}
            target='_blank'
            rel='noreferrer'
            onClick={handleClick}
          >
            <span>{t`apply_now`}</span>
          </a>
        </div>
      </div>
    </section>
  );
};
