import {
  removeAccessToken,
  removeCookie,
  removeRefreshToken,
  removeStorageVerificationVariantById,
  removeUserID,
} from 'core/services';
import { KEY_CLOSE_EMAIL_MODAL, KEY_REPEAT_USER, KEY_STORAGE_FROM_SHORT_LINK } from '../constantsLk';

export const logOutUtil = () => {
  removeAccessToken();
  removeRefreshToken();
  removeUserID();
  removeCookie(KEY_STORAGE_FROM_SHORT_LINK);
  removeCookie(KEY_REPEAT_USER);
  removeStorageVerificationVariantById();
  removeCookie(KEY_CLOSE_EMAIL_MODAL);
};
