import { lazy, Suspense } from 'react';
import { createBrowserRouter, Navigate, Outlet, RouteObject } from 'react-router-dom';

import * as RegPages from './Registration';
import { AccountLayoutContainer, LoginLayoutContainer, RegistrationLayoutContainer } from 'layouts';
import { LoginContainer } from './Login';
import { RootPage } from './Root';
import { RegistrationUserGuardContainer, UserGuardContainer } from 'components/UserGuard';
import { ShortLinkLoanResultContainer, ShortLinkDataSlug } from './ShortLinkLoan';
import { NotFoundPage } from './404NotFound';
import { Spinner } from '../components';
import * as Paths from 'constantsLk/paths';

const AccountLoanPageContainer = lazy(
  () => import('pages/account/AccountLoan/AccountLoanPageContainer'),
);
const AccountPaymentHistoryContainer = lazy(
  () => import('pages/account/AccountPaymentHistory/AccountPaymentHistoryContainer'),
);
const AccountLoanHistoryPageContainer = lazy(
  () => import('pages/account/AccountLoanHistory/AccountLoanHistoryContainer'),
);
const AccountPersonalInformationPageContainer = lazy(
  () => import('pages/account/AccountPersonalInformation/AccountPersonalInformationContainer'),
);
const AccountReferralProgramPageContainer = lazy(
  () => import('pages/account/AccountReferralProgram/AccountReferralProgramContainer'),
);
const AccountCardsContainer = lazy(
  () => import('pages/account/AccountCards/AccountCardsContainer'),
);

const accountRoutes: RouteObject[] = [
  {
    path: Paths.PATH_ACTIVE_LOAN,
    element: <AccountLoanPageContainer />,
  },
  {
    path: Paths.PATH_HISTORY_PAYMENT,
    element: <AccountPaymentHistoryContainer />,
  },
  {
    path: Paths.PATH_HISTORY_LOAN,
    element: <AccountLoanHistoryPageContainer />,
  },
  {
    path: Paths.PATH_PERSONAL_PAGE,
    element: <AccountPersonalInformationPageContainer />,
  },
  {
    path: Paths.PATH_REFERRAL_PAGE,
    element: <AccountReferralProgramPageContainer />,
  },
  {
    path: Paths.PATH_ACCOUNT_CARDS,
    element: <AccountCardsContainer />,
  },
];
const registrationChildren = [
  {
    index: true,
    element: <Navigate to={Paths.PATH_REG_STEP_1} replace />,
  },
  {
    path: Paths.PATH_REG_STEP_1,
    element: <RegPages.Step1Container />,
  },
  {
    path: '',
    element: (
      <RegistrationUserGuardContainer>
        <Outlet />
      </RegistrationUserGuardContainer>
    ),
    children: [
      {
        path: Paths.PATH_REG_STEP_2,
        element: <RegPages.Step2ConfirmPhoneContainer />,
      },
      {
        path: Paths.PATH_REG_STEP_3,
        element: <RegPages.Step3PersDataContainer />,
      },
      {
        path: Paths.PATH_REG_STEP_4,
        element: <RegPages.Step4AddressContainer />,
      },
      {
        path: Paths.PATH_REG_STEP_5,
        element: <RegPages.Step5AdditionalDetailsContainer />,
      },
      {
        path: Paths.PATH_REG_STEP_6,
        element: <RegPages.Step6MartialRelativesContainer />,
      },
      {
        path: Paths.PATH_REG_STEP_7,
        element: <RegPages.Step7BankAccDetailsContainer />,
      },
      {
        path: Paths.PATH_REG_STEP_8_1,
        element: <RegPages.Step8_1UploadSelfieContainer />,
      },
      {
        path: Paths.PATH_REG_STEP_8_2,
        element: <RegPages.Step8_2UploadFrontIdContainer />,
      },
      {
        path: Paths.PATH_REG_STEP_8_3,
        element: <RegPages.Step8_3UploadBackIdContainer />,
      },
      {
        path: Paths.PATH_REG_STEP_9_FIRST,
        element: <RegPages.Step9ConfirmationConditionsContainer />,
      },
    ],
  },
];
const userChildren = [
  {
    index: true,
    element: <Navigate to={Paths.PATH_LOGIN} replace />,
  },
  {
    path: Paths.PATH_LOGIN,
    element: <LoginLayoutContainer />,
    children: [
      //user/login
      {
        index: true,
        element: <LoginContainer />,
      },
    ],
  },
  //user/registration
  {
    path: Paths.PATH_REGISTRATION,
    element: <RegistrationLayoutContainer />,
    children: registrationChildren,
  },
];
const accountTabsChildren: RouteObject[] = accountRoutes.map(({ path, element }) => ({
  path,
  element: <Suspense fallback={<Spinner />}>{element}</Suspense>,
}));
const accountChildren = [
  {
    index: true,
    element: <Navigate to={Paths.PATH_ACCOUNT} replace />,
  },
  {
    path: '',
    element: (
      <UserGuardContainer>
        <Outlet />
      </UserGuardContainer>
    ),
    //lazyloading for account components
    children: accountTabsChildren,
  },
];
const routes = [
  {
    index: true,
    element: <Navigate to={Paths.URL_PATH_LOGIN} replace />,
  },

  //f/:slug (short links)
  {
    path: Paths.PATH_SHORT_LINK_LOAN,
    element: <Outlet />,
    children: [
      {
        index: true,
        element: <Navigate to={Paths.URL_USER_REGISTRATION} replace />,
      },
      {
        path: ':slug',
        element: <ShortLinkDataSlug />,
      },
    ],
  },
  //short-link-loan, path to pay Loan which received from   url: `/functional_link/${slug}/data`
  {
    path: Paths.PATH_SHORT_LINK_LOAN_RESULT,
    element: <Outlet />,
    children: [
      {
        index: true,
        element: <ShortLinkLoanResultContainer />,
      },
    ],
  },
  //user
  {
    path: Paths.PATH_USER,
    element: <Outlet />,
    children: userChildren,
  },

  {
    path: Paths.PATH_ACCOUNT,
    element: <AccountLayoutContainer />,
    children: accountChildren,
  },

  {
    path: '*',
    element: <NotFoundPage />,
  },
] satisfies RouteObject[];

export const router = createBrowserRouter([
  {
    path: '/',
    element: <RootPage />,
    children: routes,
  },
  {
    path: ':lang',
    element: <RootPage />,
    children: routes,
  },
]);
