import { useTranslation } from 'react-i18next';
import { AppNavLinkContainer, Button, ModalLayout } from 'core/components/common';
import { useTypedSelector } from 'core/hooks';
import { userBankDetailsSelector, userShortLinkAuthSelector } from 'store/selectors';
import * as Paths from 'constantsLk/paths';

interface BankDetailsModalProps {
  handleConfirm: () => void;
  isOpenModal: boolean;
  handleChangeDetailsModalOpen: () => void;
}

export const BankDetailsModal = (props: BankDetailsModalProps) => {
  const { handleConfirm, isOpenModal, handleChangeDetailsModalOpen } = props;

  const { t } = useTranslation();
  const userBankDetails = useTypedSelector(userBankDetailsSelector);
  const isShortLinkAuth = useTypedSelector(userShortLinkAuthSelector);

  return (
    <ModalLayout
      isOpenModal={isOpenModal}
      title={t`bank_details_modal_title`}
      isShowClose
      isUsePortal
      content={
        <div className='flex-column gap-1'>
          <div className='txt'>{userBankDetails?.bank_name}</div>
          <div className='txt'>{userBankDetails?.bank_account_number}</div>
          <div className='txt'>{`${t('bank_branch_name')} - ${
            userBankDetails?.bank_branch_name
          }`}</div>
        </div>
      }
      footer={
        <>
          <Button classes={{ root: 'btn--primary w-100 mb-5' }} onClick={handleConfirm}>
            {t('confirm')}
          </Button>
          {isShortLinkAuth ? (
            <AppNavLinkContainer className='link flex-center' to={`/${Paths.URL_PATH_LOGIN}`}>
              {t('change_bank_details')}
            </AppNavLinkContainer>
          ) : (
            <Button className='link flex-center' onClick={handleChangeDetailsModalOpen}>
              {t('change_bank_details')}
            </Button>
          )}
        </>
      }
    />
  );
};
