import React, { FC, useCallback, useRef } from 'react';
import Webcam from 'react-webcam';
import { useTranslation } from 'react-i18next';
import { Button } from 'core/components/common';

interface IWebcamComponentProps {
  onUpload: (imageSrc: string) => Promise<any>;
  onUserMediaError: (er: any) => void;
  onClose: () => void;
  type: string;
  fileState?: any;
}

const videoConstraints = {
  width: 1280,
  height: 720,
  facingMode: 'environment',
};

export const WebcamComponent: FC<IWebcamComponentProps> = ({
  onUpload,
  onUserMediaError,
  onClose,
  type,
}) => {
  const { t } = useTranslation();
  const webcamRef = useRef<any>(null);

  const capture = useCallback(() => {
    const imageSrc = webcamRef.current && webcamRef.current?.getScreenshot();

    onUpload(imageSrc).then(() => {
      onClose();
    });
  }, [webcamRef]);

  return (
    <div className='webcam'>
      <Webcam
        audio={false}
        ref={webcamRef}
        screenshotFormat='image/jpeg'
        videoConstraints={{
          ...videoConstraints,
          facingMode: type === 'selfie_with_id_card' ? 'user' : 'environment',
        }}
        onUserMediaError={onUserMediaError}
      />

      <div className='webcam-footer'>
        <Button
          onClick={capture}
          classes={{ root: 'btn--primary mt-0' }}
          // disabled={fileState === 'load'}
        >
          {t`take_a_photo`}
        </Button>
      </div>
    </div>
  );
};
