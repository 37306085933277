import { setAlert, setSnack } from 'core/store/actions';
import { NoticeType, NoticeTypes } from 'core/models';
import { useActions } from './useActions';

export const useSetNotice = (noticeType?: NoticeTypes) => {
  const actions = useActions({ setAlert, setSnack });

  return (type: NoticeType, message: string, data?: any, isUniq?: boolean) => {
    const isSnack = noticeType === NoticeTypes.Snacks;

    const { setSnack, setAlert } = actions;

    (isSnack ? setSnack : setAlert)({
      type,
      message,
      data,
      isUniq,
    });
  };
};
