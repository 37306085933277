import { createAsyncThunk } from '@reduxjs/toolkit';
import { $axios, getUserID } from 'core/services';
import { Actions, Loan, ServerLoanSettings } from 'models';

export const getPaymentHistory = createAsyncThunk<string[]>(
  Actions.GET_PAYMENT_HISTORY,
  async (_, { rejectWithValue, dispatch }) => {
    const config = {
      method: 'GET',
      url: `/users/${getUserID()}/incomes`,
    };

    try {
      return await $axios(config, dispatch);
    } catch (errors) {
      return rejectWithValue(errors);
    }
  },
);

export const getUserLoans = createAsyncThunk<Array<Loan>>(
  Actions.GET_USER_LOANS,
  async (_, { rejectWithValue, dispatch }) => {
    const config = {
      method: 'GET',
      url: `/users/${getUserID()}/loans`,
    };

    try {
      return await $axios(config, dispatch);
    } catch (errors) {
      return rejectWithValue(errors);
    }
  },
);

export const getDefaultCalculatorConfig = createAsyncThunk<
  ServerLoanSettings,
  { user_id: number } | object
>(Actions.GET_DEFAULT_CALCULATOR_CONFIG, async (data, { rejectWithValue, dispatch }) => {
  const config = {
    method: 'POST',
    url: '/loan_calculator_config',
    data,
  };

  try {
    return await $axios(config, dispatch);
  } catch (errors) {
    return rejectWithValue(errors);
  }
});

export const sendRepaymentSlip = createAsyncThunk<
  { id: number },
  { paymentData: { payment_slip_photo: Blob | null; bank_id: number }; loanId: number | undefined }
>(Actions.SEND_REPAYMENT_SLIP, async ({ paymentData, loanId }, { rejectWithValue, dispatch }) => {
  const config = {
    method: 'POST',
    url: `/loans/${loanId}/payment_slip`,
    data: paymentData,
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  };
  try {
    return await $axios(config, dispatch);
  } catch (errors) {
    return rejectWithValue(errors);
  }
});

export const topUpTransition = createAsyncThunk<
  any,
  {
    id: number;
    data:
      | {
          name: string;
          params: { amount: number } | { confirmation_code: string };
        }
      | any;
  }
>(Actions.TOP_UP_TRANSITION, async ({ id, data }, { rejectWithValue, dispatch }) => {
  const config = {
    method: 'POST',
    url: `/top_up_infos/${id}/apply_transition`,
    data,
  };

  try {
    return await $axios(config, dispatch);
  } catch (errors) {
    return rejectWithValue(errors);
  }
});

export const resendTopUpCode = createAsyncThunk<any, number>(
  Actions.RESEND_TOP_UP_CODE,
  async (id, { rejectWithValue, dispatch }) => {
    const config = {
      method: 'GET',
      url: `/top_up_infos/${id}/confirmation_code`,
    };

    try {
      return await $axios(config, dispatch);
    } catch (errors) {
      return rejectWithValue(errors);
    }
  },
);

export const getTopUpPriceList = createAsyncThunk<any, number>(
  Actions.GET_TOP_UP_PRICE_LIST,
  async (id, { rejectWithValue, dispatch }) => {
    const config = {
      method: 'GET',
      url: `/top_up_infos/${id}/price_list`,
    };

    try {
      return await $axios(config, dispatch);
    } catch (errors) {
      return rejectWithValue(errors);
    }
  },
);

export const sendTransactionId = createAsyncThunk<
  any,
  {
    transaction_id: string;
    merchant_reference_id: string;
  }
>(Actions.SEND_REPAYMENT_SLIP, async (data, { rejectWithValue, dispatch }) => {
  const config = {
    method: 'POST',
    url: `/payment_gateway/marx/process_callback`,
    data,
  };
  try {
    return await $axios(config, dispatch);
  } catch (errors) {
    return rejectWithValue(errors);
  }
});
