import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { AppNavLinkContainer } from 'core/components/common';
import { SubmitCoreHandler } from 'core/models';
import { AuthFormModel, CodeConfirmForm, PhoneSection, Title } from 'components';
import { AuthFormContainer } from 'components/AuthForm/AuthFormContainer';
import { ArchiveUserModal } from 'components/modals/ArchiveUserModal';
import { Step2ConfirmPhoneWithSMSForm } from '../Registration';
import * as Paths from 'constantsLk/paths';

export interface LoginProps {
  onSendOTP: SubmitCoreHandler<AuthFormModel>;
  onLogin: SubmitCoreHandler<Step2ConfirmPhoneWithSMSForm>;
  handlePhoneReset: () => void;
  oldUserPhone: string | null;
  resetOldUserPhone: () => void;
}

export const Login = (props: LoginProps) => {
  const { onSendOTP, onLogin, handlePhoneReset, oldUserPhone, resetOldUserPhone } = props;
  const location = useLocation();
  const { t } = useTranslation();

  const phone = location.state?.phone;
  const isOpenConfirmForm = phone && !location.state?.isOpenModal;

  return (
    <>
      <Title classes={`form-title phone-mt-3 ${isOpenConfirmForm && 'mb-2'}`} name={'log_in'} />
      {isOpenConfirmForm ? (
        <>
          <PhoneSection phone={phone} onPhoneReset={handlePhoneReset} />
          <CodeConfirmForm phone={phone} onSubmit={onLogin} />
        </>
      ) : (
        <AuthFormContainer onSubmit={onSendOTP} storageKey={Paths.URL_PATH_LOGIN} isLoginPage />
      )}
      <div className={`${!isOpenConfirmForm ? 'flex-center mt-4' : 'mt-5'}`}>
        {isOpenConfirmForm && <span className='mr-1'>{t`do_not_have_an_account`}</span>}
        <AppNavLinkContainer
          className='link'
          to={`${Paths.URL_USER_REGISTRATION}/${Paths.PATH_REG_STEP_1}`}
        >{t`register_now`}</AppNavLinkContainer>
      </div>

      {oldUserPhone && (
        <ArchiveUserModal phoneNumber={oldUserPhone} resetOldUserPhone={resetOldUserPhone} />
      )}
    </>
  );
};
