import { useTranslation } from 'react-i18next';

interface TimerProps {
  timeLeft: { [key: string]: number };
}

export const Timer = ({ timeLeft }: TimerProps) => {
  const { t } = useTranslation();

  const formatTime = (value: number): string => {
    return value < 10 ? `0${value}` : `${value}`;
  };

  return (
    <div className='txt-md-14 txt-sb'>
      {`${formatTime(timeLeft.days)} ${t('days')} : `}
      {`${formatTime(timeLeft.hours)} ${t('hours')} : `}
      {`${formatTime(timeLeft.minutes)} ${t('min')} `}
    </div>
  );
};
