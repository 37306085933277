import React from 'react';
import { CheckCircleFilledIcon } from '../icons';
import { CloseIcon } from '../../core/components/common';
import { useTranslation } from 'react-i18next';

interface NewOfferAlertProps {
  onClose: () => void;
  offerPrincipal: number | null | undefined;
}

export const NewOfferAlert = (props: NewOfferAlertProps) => {
  const { onClose, offerPrincipal } = props;
  const { t } = useTranslation();

  return (
    <div className='alerts-item__success flex-column'>
      <div className='alerts-item__info__message w-100'>
        <div className='alerts-item__info__icon__wrapper mr-2'>
          <CheckCircleFilledIcon />
        </div>
        <p className='txt-sb w-100'>{t`application_approved`}</p>
        <div className='alerts-item__success__icon__wrapper close' onClick={onClose}>
          <CloseIcon />
        </div>
      </div>
      <p className='ml-6 txt-md-14'>{t('increase_loan_up', { amount: offerPrincipal })}</p>
    </div>
  );
};
