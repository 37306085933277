import { ComponentType, useEffect } from 'react';
import { useAppDispatch } from 'hooks';
import { signIn } from 'store/actions';

export const autoSignInHoc =
  <P extends object>(WrappedComponent: ComponentType<P>): ComponentType<P> =>
  (props: P) => {
    const dispatch = useAppDispatch();

    useEffect(() => {
      let timer: NodeJS.Timeout;

      const fetchData = async () => {
        dispatch(signIn());
      };

      const startFetchingData = () => {
        timer = setInterval(fetchData, 60000); // Make request every 60 seconds
      };

      startFetchingData();

      return () => {
        clearInterval(timer);
      };
    }, []);

    return <WrappedComponent {...props} />;
  };
