import { useTranslation } from 'react-i18next';
import { parseISO } from 'date-fns';

import { getCookie } from 'core/services';

import { formatDate } from 'utils/parsers';
import { AddNotificationButton } from 'components';
import { Loan } from 'models';
import { LOAN_STATE } from 'constantsLk';

interface StateLoanProps {
  activeCreditData: Loan;
}

interface LoanProgressProps {
  tenor: number;
  days_past_due: number;
  color: string;
}

const LoanProgress = ({ tenor, days_past_due, color }: LoanProgressProps) => {
  const tenorArr = Array(tenor).fill(0);
  return (
    <div className='loan-progress-wrap'>
      {tenorArr.map((item, key) => (
        <div
          key={key}
          className={`loan-progress-indicator ${key + 1 <= tenor + days_past_due ? color : ''}`}
        />
      ))}
    </div>
  );
};

export const StateLoan = ({ activeCreditData }: StateLoanProps) => {
  const { t } = useTranslation();
  const {
    days_past_due,
    tenor,
    state,
    id,
    is_extended,
    matured_at,
    disbursed_at,
    last_extension_date,
    loan_id,
  } = activeCreditData;
  const formatedMaturedAt = matured_at && formatDate(parseISO(matured_at));
  const formatedDisbursedAt = disbursed_at && formatDate(parseISO(disbursed_at));
  const formatedRequestExtensionDate =
    last_extension_date && formatDate(parseISO(last_extension_date));

  const loanStateLbl =
    state === LOAN_STATE.CLOSED
      ? t`paid`
      : state === LOAN_STATE.DEFAULTED || days_past_due > 0
        ? t`overdue`
        : t(state);

  const color = days_past_due > 0 ? 'error' : 'success';

  return (
    <>
      <div className='justify-content-between align-items-center mb-2'>
        <h3 className='txt-sb txt-md'>
          {t('loan')} № {loan_id ? loan_id : id || getCookie('active_loan_id')}
        </h3>
      </div>
      <div className='flex-column gap-1'>
        <div className='align-items-center mb-2'>
          <span className={`pill-loan-${color} mr-2`}>{loanStateLbl}</span>

          {days_past_due && (
            <span className={`pill-${color}`}>
              {days_past_due > 0 ? t('days_past') : t('days_till')}&nbsp;
              {Math.abs(days_past_due)}
            </span>
          )}
        </div>

        <div className='align-items-start gap-2'>
          <div className='flex-column flex-grow-1 gap-1'>
            <LoanProgress color={color} tenor={tenor} days_past_due={days_past_due} />
            <div className='justify-content-between align-items-center'>
              <span className='txt-secondary txt-base'>
                {is_extended
                  ? t('extended_from_date', { date: formatedRequestExtensionDate })
                  : formatedDisbursedAt}
              </span>
              {matured_at && <span className='txt-secondary txt-base'>{formatedMaturedAt}</span>}
            </div>
          </div>
        </div>
        <div className='mt-2'>
          <AddNotificationButton loan={activeCreditData} variant='secondary dark' />
        </div>
      </div>
    </>
  );
};
