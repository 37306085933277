export const SELECT_LIST_IMPORTANT_BANK_NAME: { label: string; value: string }[] = [
  { label: 'BANK OF CEYLON', value: 'BANK OF CEYLON' },
  { label: 'COMMERCIAL BANK OF CEYLON PLC', value: 'COMMERCIAL BANK OF CEYLON PLC' },
  { label: 'PEOPLES BANK', value: 'PEOPLES BANK' },
  { label: 'SAMPATH BANK PLC', value: 'SAMPATH BANK PLC' },
  { label: 'NATIONAL SAVINGS BANK', value: 'NATIONAL SAVINGS BANK' },
  { label: 'HATTON NATIONAL BANK PLC', value: 'HATTON NATIONAL BANK PLC' },
];

export const SELECT_LIST_BANK_NAME: { label: string; value: string }[] = [
  { label: 'BANK OF CEYLON', value: 'BANK OF CEYLON' },
  { label: 'STANDARD CHARTED BANK', value: 'STANDARD CHARTED BANK' },
  { label: 'CITI BANK N. A.', value: 'CITI BANK N. A.' },
  { label: 'COMMERCIAL BANK OF CEYLON PLC', value: 'COMMERCIAL BANK OF CEYLON PLC' },
  { label: 'HABIB BANK LIMITED', value: 'HABIB BANK LIMITED' },
  { label: 'HATTON NATIONAL BANK PLC', value: 'HATTON NATIONAL BANK PLC' },
  {
    label: 'HONGKONG AND SHANGHAI BANKING CORPORATION',
    value: 'HONGKONG AND SHANGHAI BANKING CORPORATION',
  },
  { label: 'INDIAN BANK', value: 'INDIAN BANK' },
  { label: 'INDIAN OVERSEAS BANK', value: 'INDIAN OVERSEAS BANK' },
  { label: 'PEOPLES BANK', value: 'PEOPLES BANK' },
  { label: 'STATE BANK OF INDIA', value: 'STATE BANK OF INDIA' },
  { label: 'NATIONS TRUST BANK PLC', value: 'NATIONS TRUST BANK PLC' },
  { label: 'DEUTSCHE BANK AG', value: 'DEUTSCHE BANK AG' },
  { label: 'NATIONAL DEVELOPMENT BANK PLC', value: 'NATIONAL DEVELOPMENT BANK PLC' },
  { label: 'MCB BANK LIMITED', value: 'MCB BANK LIMITED' },
  { label: 'SAMPATH BANK PLC', value: 'SAMPATH BANK PLC' },
  { label: 'SEYLAN BANK PLC', value: 'SEYLAN BANK PLC' },
  { label: 'PUBLIC BANK BERHAD', value: 'PUBLIC BANK BERHAD' },
  { label: 'UNION BANK OF COLOMBO PLC', value: 'UNION BANK OF COLOMBO PLC' },
  { label: 'PAN ASIA BANKING CORPORATION PLC', value: 'PAN ASIA BANKING CORPORATION PLC' },
  { label: 'DFCC BANK PLC', value: 'DFCC BANK PLC' },
  { label: 'AMANA BANK PLC', value: 'AMANA BANK PLC' },
  { label: 'CARGILLS BANK LIMITED', value: 'CARGILLS BANK LIMITED' },
  { label: 'SOFTLOGIC FINANCE PLC', value: 'SOFTLOGIC FINANCE PLC' },
  { label: 'REGIONAL RURAL DEV. BANK', value: 'REGIONAL RURAL DEV. BANK' },
  { label: 'BANK OF CHINA LIMITED', value: 'BANK OF CHINA LIMITED' },
  { label: 'NATIONAL SAVINGS BANK', value: 'NATIONAL SAVINGS BANK' },
  { label: 'SANASA DEVELOPMENT BANK LTD', value: 'SANASA DEVELOPMENT BANK LTD' },
  { label: 'HDFC BANK', value: 'HDFC BANK' },
  {
    label: 'CITIZEN DEVELOPMENT BUSINESS FINANCE PLC',
    value: 'CITIZEN DEVELOPMENT BUSINESS FINANCE PLC',
  },
  { label: 'REGIONAL DEVELOPMENT BANK', value: 'REGIONAL DEVELOPMENT BANK' },
  { label: 'LB FINANCE PLC', value: 'LB FINANCE PLC' },
  { label: 'SENKADAGALA FINANCE PLC', value: 'SENKADAGALA FINANCE PLC' },
  { label: 'VALLIBEL FINANCE PLC', value: 'VALLIBEL FINANCE PLC' },
  { label: 'CENTRAL FINANCE PLC', value: 'CENTRAL FINANCE PLC' },
  { label: 'LOLC FINANCE PLC', value: 'LOLC FINANCE PLC' },
  {
    label: 'MERCHANT BANK OF SRI LANKA & FINANCE PLC',
    value: 'MERCHANT BANK OF SRI LANKA & FINANCE PLC',
  },
  { label: 'HNB FINANCE PLC', value: 'HNB FINANCE PLC' },
  { label: 'MERCANTILE INVESTMENT & FINANCE PLC', value: 'MERCANTILE INVESTMENT & FINANCE PLC' },
  { label: "PEOPLE'S LEASING & FINANCE PLC", value: "PEOPLE'S LEASING & FINANCE PLC" },
  { label: 'BIMPUTH FINANCE PLC', value: 'BIMPUTH FINANCE PLC' },
  { label: 'LOLC DEVELOPMENT FINANCE PLC', value: 'LOLC DEVELOPMENT FINANCE PLC' },
  { label: 'DIALOG FINANCE PLC', value: 'DIALOG FINANCE PLC' },
  { label: 'SINGER FINANCE (LANKA) PLC', value: 'SINGER FINANCE (LANKA) PLC' },
  { label: 'CBC FINANCE LIMITED', value: 'CBC FINANCE LIMITED' },
  { label: 'ASIA ASSET FINANCE PLC', value: 'ASIA ASSET FINANCE PLC' },
  { label: 'RICHARD PIERIS FINANCE LIMITED', value: 'RICHARD PIERIS FINANCE LIMITED' },
  {
    label: 'SARVODHAYA DEVELOPMENT FINANCE LIMITED',
    value: 'SARVODHAYA DEVELOPMENT FINANCE LIMITED',
  },
  { label: 'FINTREX FINANCE LIMITED', value: 'FINTREX FINANCE LIMITED' },
];
