import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { SubmitCoreHandler } from 'core/models';
import { maxLength } from 'core/utils/parsers';
import { Button, ModalLayout } from 'core/components/common';
import { SelectFieldHookForm, TextFieldHookForm } from 'core/components/fields';
import {
  EMAIL_SYMBOLS_REGEX,
  validateEmail,
  validateEmailWithoutDomain,
  validateSelect,
} from 'utils/validates/validation';
import { EmailFormModel, IbanNumberFields } from 'models';
import { emailValues } from 'constantsLk/SelectValues/emailValues';

interface AddEmailModalProps {
  isOpenModal: boolean;
  handleClose: () => void;
  onSubmit: SubmitCoreHandler<EmailFormModel>;
  isShareModal?: boolean;
}
export const AddEmailModal = (props: AddEmailModalProps) => {
  const { handleClose, isOpenModal, onSubmit, isShareModal } = props;
  const { t } = useTranslation();

  const {
    handleSubmit,
    watch,
    setValue,
    control,
    setError,
    clearErrors,
    formState: { isSubmitting },
  } = useForm<EmailFormModel>({
    mode: 'onBlur',
    defaultValues: {
      [IbanNumberFields.Email]: null,
      [IbanNumberFields.EmailDomain]: emailValues[0].value,
    },
  });

  const emailDomain = watch(IbanNumberFields.EmailDomain);
  const isNotOther = emailDomain !== 'other';

  const handleChangeEmail = (name: any, value: any) => {
    const val = value.trim();

    if (val) {
      if (val.includes('@')) {
        setValue(IbanNumberFields.EmailDomain, 'other');
      }

      const isValidVal = EMAIL_SYMBOLS_REGEX.test(val);

      if (!isValidVal) {
        setError(name, { type: 'validate', message: t`email_err_not_en` });
      } else {
        clearErrors(name);
      }
    } else if (!val && !isNotOther) {
      setValue(IbanNumberFields.EmailDomain, emailValues[0].value);
    }
  };

  const onSubmitHandler = handleSubmit((data) =>
    onSubmit?.(data, {
      setError,
    }),
  );

  return (
    <ModalLayout
      isOpenModal={isOpenModal}
      title={t(`${isShareModal ? 'add_friend_email' : 'add_email'}`)}
      onClose={handleClose}
      classes={{ wrapper: 'email_modal' }}
      isShowClose
      isUsePortal
      content={
        <div className='flex-column gap-5'>
          <div className='txt'>
            {t(`${isShareModal ? 'add_friend_email_subtitle' : 'add_email_subtitle'}`)}
          </div>

          <form onSubmit={onSubmitHandler}>
            <div className={`w-100 ${isNotOther && 'form-group email'}`}>
              <TextFieldHookForm
                control={control}
                name={IbanNumberFields.Email}
                label={t`email`}
                parsers={[maxLength(100)]}
                rules={isNotOther ? validateEmailWithoutDomain : validateEmail}
                onChange={handleChangeEmail}
              />

              {isNotOther && (
                <SelectFieldHookForm
                  control={control}
                  name={IbanNumberFields.EmailDomain}
                  options={emailValues}
                  rules={validateSelect}
                />
              )}
            </div>
            <Button classes={{ root: 'btn--primary w-100 mt-5' }} disabled={isSubmitting}>
              {t(`${isShareModal ? 'send_an_invite' : 'continue'}`)}
            </Button>
          </form>
        </div>
      }
    />
  );
};
