export const siLangOZPlugin = {
  accessing_camera: 'කැමරාවට ප්‍රවේශ වීම. එයට තත්පර කිහිපයක් ගත විය හැක.',
  action_camera_switch_button: 'කැමරාව මාරු කරන්න',
  action_confirm_button: 'තහවුරු කරන්න',
  action_photo_button: 'ඡායාරූපය ගන්න',
  action_repeat_button: 'නැවතත්',
  action_skip_button: 'මගහරින්න',
  alert_close_button: 'හරි',
  analysis_in_progress: 'විශ්ලේෂණය කරමින්, කරුණාකර ප්‍රතිඵලය සඳහා රැඳී සිටින්න',
  device_turn: 'කරුණාකර ඔබගේ උපාංගය හරවන්න',
  doc_button_separator: '– හෝ –',
  doc_capture_button: 'ඡායාරූපයක් ගන්න',
  doc_upload_button: 'රූපයක් උඩුගත කරන්න',
  error_bad_camera: 'දෝෂය: කැමරාව භාවිතා කළ නොහැක.',
  error_camera_not_found_by_device_id:
    'ඉල්ලන ලද කැමරාව සොයාගත නොහැකි විය, කරුණාකර උපාංග හැඳුනුම්පත නිවැරදි බව සහතික කර ගන්න',
  error_data_upload: 'උඩුගත කිරීමේ දෝෂය.',
  error_no_camera: 'කරුණාකර කැමරාව භාවිතා කිරීමට අවසර දෙන්න.',
  error_no_camera_ios:
    'දෝෂය: කැමරාවට ප්‍රවේශ විය නොහැක. <br/> කරුණාකර පෙරනිමි බ්‍රවුසරය (Safari) භාවිතා කරන්න.',
  error_no_camera_ssl: 'දෝෂය: කැමරාවට ප්‍රවේශ විය නොහැක. <br/> කරුණාකර HTTPS (SSL) භාවිතා කරන්න',
  error_no_camera_title:
    '<b>කරුණාකර කැමරාව භාවිතා කිරීමට අවසර දෙන්න.</b><br><br>ප්‍රවේශයට ඉඩ දෙන්නේ කෙසේද:',
  error_result: 'ප්‍රතිඵල දෝෂය.',
  error_slow_backend:
    'ඔබගේ වත්මන් බ්‍රවුසරය සමහර වැදගත් විශේෂාංග සඳහා සහය නොදක්වයි. <br /><br /> ඔබගේ පහසුව සඳහා කරුණාකර මෙම පිටුව වෙනත් බ්‍රවුසරයකින් විවෘත කරන්න.',
  error_unknown: 'නොදන්නා දෝෂය.',
  error_upload_too_big: 'දෝෂය: ගොනු ප්‍රමාණයේ සීමාව ඉක්මවා ඇත.',
  error_upload_unknown: 'දෝෂය: ගොනුව සැකසීමට නොහැක.',
  error_virtual_camera: 'කරුණාකර අතථ්‍ය කැමරාව භාවිතා නොකරන්න.',
  more_than_one_face: 'රාමු තුළ වෙනත් මුහුණු නොමැති බවට වග බලා ගන්න',
  network_video_analyses_status_failed: 'විශ්ලේෂණ දෝෂය',
  number_of_attempts_exhausted: 'ඉවත් වූ උත්සාහයන් ගණන',
  oz_action_blink_go: 'දැන් ඇසිපිය ගසන්න හෝ තත්පරයකට ඇස් වසා ගන්න',
  oz_action_head_down_go: 'පහළට බෑවුම',
  oz_action_head_left_go: 'ඔබේ හිස වමට හරවන්න',
  oz_action_head_right_go: 'ඔබේ හිස දකුණට හරවන්න',
  oz_action_head_up_go: 'ඉහළට ඇල කරන්න',
  oz_action_hint_OK: 'නියමයි!',
  oz_action_hint_OK_intermediate: 'නියමයි, උපාංගය ගෙන යන්න එපා...',
  oz_action_hint_bad_light: 'වඩා හොඳ ආලෝක තත්ත්වයන් සොයන්න',
  oz_action_hint_dont_left_or_right_head:
    'පසුව හිස හරවන ලෙස ඔබෙන් අසනු ඇත; කරුණාකර දැන් කැමරාව දෙස කෙලින්ම බලන්න',
  oz_action_hint_dont_smile: 'පසුව සිනාසීමට ඔබෙන් අසනු ඇත; කරුණාකර දැන් සිනාසෙන්න එපා',
  oz_action_hint_face_aligned: 'නියමයි, උපාංගය ගෙන යන්න එපා...',
  oz_action_hint_look_straight: 'ඔබේ මුහුණ කෙළින්ම කැමරාවට හරවන්න',
  oz_action_hint_low_score: 'ඔබේ මුහුණ පැහැදිලිව පෙනෙන්නට තිබිය යුතුය',
  oz_action_hint_move_away: 'ඉවතට යන්න',
  oz_action_hint_move_closer: 'සමීපවන්න',
  oz_action_hint_move_even_away: 'ඊටත් වඩා ඉදිරියට',
  oz_action_hint_move_even_closer: 'ඊටත් වඩා සමීප',
  oz_action_hint_not_centered: 'මුහුණ මැද තිබිය යුතුය',
  oz_action_hint_open_eyes_wide: 'ඔබේ ඇස් පුළුල් ලෙස විවෘත කරන්න',
  oz_action_hint_remove_mask: 'කරුණාකර වෙස්මුහුණ ගලවන්න',
  oz_action_hint_remove_object: 'ඔබේ මුහුණ පැහැදිලිව පෙනෙන විය යුතුය',
  oz_action_hint_remove_sunglasses: 'කරුණාකර අව් කණ්ණාඩි ගලවන්න',
  oz_action_hint_smile_open_mouth: 'කරුණාකර සිනාසෙන්න එපා සහ ඔබේ මුඛය විවෘත නොකරන්න',
  oz_action_hint_too_blurry: 'රූපය බොඳ වැඩියි',
  oz_action_hint_too_dark: 'වඩා හොඳ ආලෝක තත්ත්වයන් සොයන්න',
  oz_action_look_at_screen: 'තිරය දෙස බලන්න',
  oz_action_repeat: 'කරුණාකර ක්‍රියාව නැවත කරන්න',
  oz_action_scan_go: 'මෙම පෙළ අනුගමනය කරන්න',
  oz_action_smile_go: 'දැන් සිනාසෙන්න',
  oz_action_title_photo_back: 'ලේඛනයේ පිටුපස පැත්තේ ඡායාරූපයක් සාදන්න (දෙපාර්ශ්වික ලේඛන සඳහා පමණි)',
  oz_action_title_photo_confirm: 'ඔබට මෙම ඡායාරූපය සමඟ ඉදිරියට යාමට අවශ්‍යද?',
  oz_action_title_photo_front: 'ලේඛනයේ ඉදිරිපස පැත්තේ ඡායාරූපයක් සාදන්න',
  oz_action_tutorial_button: 'උපදෙස්',
  oz_alert_dialog_fail_action_title: 'ඉල්ලන ලද ක්‍රියාව අනාවරණය කර නොමැත',
  oz_check_internet_connection: 'අන්තර්ජාල සම්බන්ධතාවයක් නැත',
  oz_network_request_failed: 'ඉල්ලීමේ දෝෂය',
  oz_network_upload_status_done: 'වීඩියෝ උඩුගත කළා',
  oz_network_upload_status_done_image: 'ඡායාරූපය උඩුගත කරන ලදී',
  oz_network_upload_status_failed: 'උඩුගත කිරීමේ දෝෂය',
  oz_network_upload_status_failed_image: 'උඩුගත කිරීමේ දෝෂය',
  oz_network_upload_status_processing: 'වීඩියෝව උඩුගත කරමින්...',
  oz_network_upload_status_processing_image: 'ඡායාරූපය උඩුගත කරමින්...',
  oz_network_video_analyses_status_analyse_is: 'විශ්ලේෂණය',
  oz_network_video_analyses_status_analyse_is_processing: 'විශ්ලේෂණය සිදු වෙමින් පවතී',
  oz_tutorial_camera_android_chrome_browser: 'Google Chrome',
  oz_tutorial_camera_android_chrome_instruction:
    '<ul>' +
    '<li><div><b>Chrome</b> තුළ, මෙනු බොත්තම ඔබන්න (<b>"ලකුණු තුනක්"</b>).</div></li>' +
    '<li><div><b>සැකසීම්</b> තෝරන්න.</div></li>' +
    '<li><div>සැකසීම් පිටුව පහළට අනුචලනය කර <b>අඩවි සැකසීම්</b> තෝරන්න.</div></li>' +
    '<li><div><b>කැමරාව</b> තෝරන්න.</div></li>' +
    '<li><div><b>කැමරාව</b> රේඩියෝ බොත්තම ක්‍රියාත්මක කරන්න.</div></li>' +
    '</ul>',
  oz_tutorial_camera_android_chrome_title:
    '<b>Google Chrome</b> හි කැමරාවට ප්‍රවේශය සක්‍රීය කිරීමට, පහත පරිදි ඉදිරියට යන්න:',
  oz_tutorial_camera_android_default_browser: 'Chrome',
  oz_tutorial_camera_android_default_instruction:
    '<ul>' +
    '<li><div><b>Chrome</b> හි, මෙනු බොත්තම ක්ලික් කරන්න (<b>"ලකුණු තුනක්"</b>).</div></li>' +
    '<li><div><b>සැකසීම්</b> තෝරන්න.</div></li>' +
    '<li><div>සැකසීම් පිටුව පහළට අනුචලනය කර <b>අඩවි සැකසීම්</b> තෝරන්න.</div></li>' +
    '<li><div><b>කැමරාව</b> තෝරන්න.</div></li>' +
    '<li><div><b>කැමරාව</b> රේඩියෝ බොත්තම ක්‍රියාත්මක කරන්න.</div></li>' +
    '</ul>',
  oz_tutorial_camera_android_default_title:
    'අවාසනාවකට, අපි ඔබගේ බ්‍රවුසරය හඳුනාගෙන නොමැත. <b>Chrome</b> නම්, මෙම ගැටළුව පහත පරිදි විසඳිය හැක:',
  oz_tutorial_camera_android_edge_browser: 'Microsoft Edge',
  oz_tutorial_camera_android_edge_instruction:
    '<ul>' +
    '<li><div>බ්‍රවුසරයේ පහළ දකුණු කෙළවරේ ඇති මෙනු බොත්තම (<b>"ඉරි තුනක්"</b> හෝ <b>හැම්බර්ගර්</b>) ඔබන්න.</div></li>' +
    '<li><div><b>සැකසීම්</b> ක්ලික් කරන්න.</div></li>' +
    '<li><div><b>පෞද්ගලිකත්වය සහ ආරක්ෂාව</b> තෝරන්න.</div></li>' +
    '<li><div><b>පෞද්ගලිකත්වය</b> කොටසේ <b>අඩවි සැකසීම්</b> තෝරන්න.</div></li>' +
    '<li><div><b>අවසර</b> තුළ <b>කැමරාව</b> තෝරන්න.</div></li>' +
    '<li><div><b>කැමරාව</b> ස්විචරය <b>ක්‍රියාත්මක</b> ලෙස සකසන්න.</div></li>' +
    '</ul>',
  oz_tutorial_camera_android_edge_title:
    'ඇන්ඩ්‍රොයිඩ් උපාංගයක <b>Microsoft Edge</b> හි කැමරාවට ප්‍රවේශය සක්‍රීය කිරීමට, පහත පරිදි ඉදිරියට යන්න:',
  oz_tutorial_camera_android_facebook_browser: 'Facebook',
  oz_tutorial_camera_android_facebook_instruction:
    '<ul>' +
    '<li><div>යෙදුමෙහි ජෛවමිතික සත්‍යාපන පිටුව විවෘත කිරීමත් සමඟ, බ්‍රවුසරයේ ඉහළ දකුණු කෙළවරේ ඇති <b>“ලකුණු තුනක්”</b> බොත්තම ඔබන්න.</div></li>' +
    '<b>බ්‍රවුසරය</b> ඔබේ පෙරනිමි බ්‍රවුසරය (උදා, ක්‍රෝම් හෝ වෙනත් ඕනෑම බ්‍රවුසරයක්).</div></li>' +
    '<li><div>පෙනෙන කවුළුවේ <b>ඕනෑම බ්‍රවුසරයක්</b> තෝරන්න. ජෛවමිතික සත්‍යාපන වෙබ් අඩවියට සබැඳිය බ්‍රවුසර යෙදුම තුළ විවෘත වේ.</div></li>' +
    '</ul>',
  oz_tutorial_camera_android_facebook_title:
    'බිල්ට් <b>Facebook</b> බ්‍රවුසරයේ කැමරාව ක්‍රියාත්මක නොවන්නේ නම්, පහත පරිදි ඉදිරියට යන්න:',
  oz_tutorial_camera_android_firefox_browser: 'Firefox',
  oz_tutorial_camera_android_firefox_instruction:
    '<ul>' +
    '<li><div>ෆයර්ෆොක්ස් හි, මෙනු බොත්තම ඔබන්න (<b>"ලකුණු තුනක්"</b>).</div></li>' +
    '<li><div><b>සැකසීම්</b> ස්ථානගත කරන්න.</div></li>' +
    '<li><div><b>අඩවි අවසර</b> තෝරන්න.</div></li>' +
    '<li><div><b>කැමරාව</b> තෝරන්න.</div></li>' +
    '<li><div><b>සැමවිටම අසන්න</b> විකල්පය තෝරන්න.</div></li>' +
    '<li><div>Android තවමත් ප්‍රවේශය අවහිර කරන්නේ නම්, <b>Settings වෙත යන්න</b> බොත්තම ඔබන්න. <b>සැකසීම්</b> කවුළුව තුළ, <b>යෙදුම් අවසර</b> තෝරන්න සහ <b>කැමරාව</b> රේඩියෝ බොත්තම ක්‍රියාත්මක කරන්න.</div></li>' +
    '</ul>',
  oz_tutorial_camera_android_firefox_title:
    'Android උපාංගයක <b>Firefox</b> හි කැමරා ප්‍රවේශය සක්‍රීය කිරීමට, පහත පරිදි ඉදිරියට යන්න:',
  oz_tutorial_camera_android_instagram_browser: 'Instagram',
  oz_tutorial_camera_android_instagram_instruction:
    '<ul>' +
    '<li><div>යෙදුමෙහි ජෛවමිතික සත්‍යාපන පිටුව විවෘත කිරීමත් සමඟ, බ්‍රවුසරයේ ඉහළ දකුණු කෙළවරේ ඇති මෙනු බොත්තම (<b>“ලකුණු තුනක්”</b>) ඔබන්න.</div></li>' +
    '<b>බ්‍රවුසරය</b> ඔබේ පෙරනිමි බ්‍රවුසරය (උදා, ක්‍රෝම් හෝ ඕනෑම දෙයක් වෙනත් බ්‍රව්සරය).</div></li>' +
    '<li><div>පෙනෙන කවුළුවේ <b>ඕනෑම බ්‍රවුසරයක්</b> තෝරන්න. ජෛවමිතික සත්‍යාපන වෙබ් අඩවියට සබැඳියක් බ්‍රවුසර යෙදුම තුළ විවෘත වේ.</div></li>' +
    '</ul>',
  oz_tutorial_camera_android_instagram_title:
    'int-in <b>Instagram</b> බ්‍රවුසරයේ කැමරාව ක්‍රියාත්මක නොවන්නේ නම්, පහත පරිදි ඉදිරියට යන්න:',
  oz_tutorial_camera_android_miui_browser: 'Xiaomi Mi',
  oz_tutorial_camera_android_miui_instruction:
    '<ul>' +
    '<li><div>MI බ්‍රවුසරයේ, <b>පැතිකඩ</b> බොත්තම තට්ටු කරන්න (පහළ දකුණු කෙළවරේ මානව අයිකනයක්)</div></li>' +
    '<li><div>ඔබේ MI ගිණුමේ අංකය/නම අසල ඇති <b>සැකසීම්</b> බොත්තම (ෂඩාස්රයක්) තට්ටු කරන්න.</div></li>' +
    '<li><div>සැකසීම් පිටුව පහළට අනුචලනය කර <b>අඩවි සැකසීම්</b> තෝරන්න.</div></li>' +
    '<li><div><b>කැමරාව</b> තෝරන්න.</div></li>\n <li><div><b>කැමරාව</b> රේඩියෝ බොත්තම ක්‍රියාත්මක කරන්න.</div></li>' +
    '</ul>',
  oz_tutorial_camera_android_miui_title:
    '<b>Xiaomi Mi</b> බ්‍රවුසරයේ කැමරාවට ප්‍රවේශය සක්‍රීය කිරීමට, පහත පරිදි ඉදිරියට යන්න:',
  oz_tutorial_camera_android_opera_browser: 'ඔපෙරා',
  oz_tutorial_camera_android_opera_instruction:
    '<ul>' +
    '<li><div><b>ඔපෙරා</b> තුළ, බ්‍රවුසරයේ පහළ දකුණු කෙළවරේ ඇති <b>O</b> බොත්තම ක්ලික් කරන්න.</div></li>' +
    '<li><div><b>සැකසීම්</b> තෝරන්න.</div></li>' +
    '<li><div><b>රහස්‍යභාවය</b> කොටසේ, <b>අඩවි සැකසීම්</b> තෝරන්න.</div></li>' +
    '<li><div><b>කැමරාව</b> තෝරන්න.</div></li>' +
    '<li><div><b>කැමරාව</b> රේඩියෝ බොත්තම ක්‍රියාත්මක කර <b>සැමවිටම අසන්න</b> තෝරන්න.</div></li>' +
    '<li><div>අවසරයකින් කලින් අවසරය අවලංගු කර ඇත්නම්, අඩවි ලිපිනය <b>අවලංගු කරන ලදී</b> කොටසෙහි සංදර්ශණය වේ. අඩවි ඉල්ලීමට ප්‍රවේශ වීමට <b>අවසර ඉවත් කරන්න</b> ක්ලික් කරන්න ජෛවමිතික සත්‍යාපනය සමත් වන විට කැමරාව.</div></li>' +
    '</ul>',
  oz_tutorial_camera_android_opera_title:
    'ඇන්ඩ්‍රොයිඩ් උපාංගයක <b>ඔපෙරා</b> බ්‍රවුසරයේ කැමරා ප්‍රවේශය සබල කිරීමට, පහත පරිදි ක්‍රියා කරන්න:',
  oz_tutorial_camera_android_samsung_browser: 'Samsung',
  oz_tutorial_camera_android_samsung_instruction:
    '<ul>' +
    '<li><div><b>යාවත්කාලීන අවසර</b> බොත්තම ඔබන්න.</div></li>' +
    '<li><div>අවසර ඉල්ලීම් කවුළුවක් දිස්වනු ඇත. මෙම කවුළුවෙහි, <b>ඉඩ දෙන්න</b> ක්ලික් කරන්න. ඉන්පසු ජෛවමිතික සත්‍යාපනය නැවත කරන්න.</div></li>' +
    '</ul>' +
    '<p>ඔබ <b>අවලංගු කරන්න</b> තෝරාගෙන යම් අවස්ථාවක දී <b>"නැවත මගෙන් අසන්න එපා"</b> විකල්පය සලකුණු කළේ නම්, පහත පරිදි ඉදිරියට යන්න:</p>' +
    '<ul>' +
    '<li><div>බ්‍රවුසරයේ පහළ දකුණු කෙළවරේ ඇති මෙනු බොත්තම (<b>"ඉරි තුනක්"</b> හෝ <b>හැම්බර්ගර්</b>) ඔබන්න.</div></li>' +
    '<li><div>තෝරන්න <b>සැකසීම්</b></div></li>' +
    '<li><div>සැකසීම් පිටුව පහළට අනුචලනය කර <b>අන්තර්ජාල යෙදුම ගැන</b></div></li>' +
    '<li><div>දුරකථන සැකසුම් වලින් යෙදුම් දත්ත විවෘත කිරීමට ඉහළ දකුණු කෙළවරේ ඇති තොරතුරු බොත්තම ("i") තට්ටු කරන්න</div></li>' +
    '<li><div>තෝරන්න <b>යෙදුම් අවසර</b></div></li>' +
    '<li><div><b>කැමරාව</b> රේඩියෝ බොත්තම ක්‍රියාත්මක කරන්න</div></li>' +
    '</ul>' +
    '<p><b>Samsung බ්‍රවුසරයේ මුදල් පිරිසිදු කිරීම.</b><br><b>කැමරාව</b> රේඩියෝ බොත්තම ක්‍රියාත්මක කර ඇත්නම්, නමුත් ඔබට තවමත් ප්‍රවේශ ඉල්ලීමක් දර්ශනය වී නොමැති නම්, පහත පරිදි ඉදිරියට යන්න :</p>' +
    '<ul>' +
    '<li><div>බ්‍රවුසරයේ පහළ දකුණු කෙළවරේ ඇති මෙනු බොත්තම (<b>"ඉරි තුනක්"</b> හෝ <b>හැම්බර්ගර්</b>) ඔබන්න.</div></li>' +
    '<li><div>තෝරන්න <b>සැකසීම්</b></div></li>' +
    '<li><div>ස්ථානගත කරන්න <b>පුද්ගලික තොරතුරු</b></div></li>' +
    '<li><div><b>බ්‍රවුසින් දත්ත හිස් කරන්න</b></div></li>' +
    '<li><div>සලකුණු කරන්න <b>CashX</b> සහ <b>කුකීස් සහ අඩවි දත්ත</b> විකල්ප</div></li> ' +
    '<li><div><b>Clear</b> බොත්තම තට්ටු කරන්න </div></li>' +
    '</ul>',
  oz_tutorial_camera_android_samsung_title:
    '<b>Samsung</b> බ්‍රවුසරයට දුරකථන සැකසුම් තුළ කැමරාව භාවිතා කිරීමට ඉඩ නොදෙන්නේ නම්, ඔබ කැමරාවට ප්‍රවේශ වීමට ඉල්ලීමක් කළ පසු, පහත පණිවිඩය දිස්වනු ඇත: "පරිශීලනය කිරීමට කැමරාවට ප්‍රවේශ වීමට අන්තර්ජාල බ්‍රවුසරයට ඉඩ දෙන්න එය මෙම අඩවියේ", <b>යාවත්කාලීන අවසර</b> බොත්තම සමඟින්.',
  oz_tutorial_camera_android_webview_browser: 'බිල්ට්-ඉන් බ්‍රව්සරය',
  oz_tutorial_camera_android_webview_instruction:
    '<ul>' +
    '<li><div><strong>සැකසීම්</strong> වෙත ගොස් <strong>යෙදුම්</strong> විවෘත කරන්න. ඉන්පසු, යෙදුම් ලැයිස්තුවට ප්‍රවේශ වන්න (එය <strong>යෙදුම් කළමනාකරණය</strong> ලෙස ලේබල් කළ හැක, <strong>සියලු යෙදුම් බලන්න</strong>, ආදිය).</div></li>' +
    '<li><div>ඔබට කැමරා ප්‍රවේශය සබල කිරීමට අවශ්‍ය යෙදුම තෝරන්න.</div></li>' +
    '<li><div><strong>අවසර</strong> මත තට්ටු කර <strong>කැමරා</strong> විකල්පය සොයා ගන්න.</div></li>' +
    '<li><div>සුදුසු විකල්පය තේරීමෙන් කැමරා ප්‍රවේශයට ඉඩ දෙන්න, එය <strong>අවසරයි</strong> ලෙස ලේබල් කර ඇති පිරික්සුම් පෙට්ටියක්, ස්විචයක් හෝ මෙනුවක් විය හැකිය, <strong>යෙදුම භාවිතා කරන අතරතුර පමණක් ඉඩ දෙන්න</strong> , ආදිය.</div></li>' +
    '</ul>' +
    '<p>උපාංගය අනුව පියවර තරමක් වෙනස් විය හැක. ඔබට කිසියම් ගැටළුවක් ඇත්නම්, වැඩිදියුණු කළ ගැළපුම සඳහා Google Chrome වැනි වෙනත් බ්‍රවුසරයක් භාවිතයෙන් යෙදුම වෙත ප්‍රවේශ වීමට උත්සාහ කරන්න.</p>',
  oz_tutorial_camera_android_webview_title:
    'ඔබගේ Android උපාංගයේ යෙදුමක් සඳහා කැමරා ප්‍රවේශය සක්‍රීය කිරීමට, කරුණාකර මෙම පියවර අනුගමනය කරන්න:',
  oz_tutorial_camera_android_yandex_browser: 'Yandex බ්‍රව්සරය',
  oz_tutorial_camera_android_yandex_instruction:
    '<ul>' +
    '<li><div>යෙදුමෙහි ජෛවමිතික සත්‍යාපන පිටුව විවෘත කිරීමත් සමඟ, බ්‍රවුසරයේ පහළ දකුණු කෙළවරේ ඇති මෙනු බොත්තම (<b>“ලකුණු තුනක්”</b>) ඔබන්න.</div></li>' +
    '<li><div><b>අඩවිය ගැන</b> තෝරන්න.</div></li>' +
    '<li><div><b>කැමරාව</b> රේඩියෝ බොත්තම ක්‍රියාත්මක කරන්න.</div></li>' +
    '</ul>',
  oz_tutorial_camera_android_yandex_title:
    'ඇන්ඩ්‍රොයිඩ් උපාංගයක <b>Yandex බ්‍රව්සරය</b> තුළ කැමරා ප්‍රවේශය සබල කිරීමට, පහත පරිදි ක්‍රියා කරන්න:',
  oz_tutorial_camera_desktop_chrome_browser: 'Google Chrome',
  oz_tutorial_camera_desktop_chrome_instruction:
    '<ul>' +
    '<li><div>ඉහළ දකුණු කෙළවරේ ඇති තිත් තුන ක්ලික් කිරීමෙන් <b>අභිරුචිකරණය සහ පාලනය Google Chrome</b> මෙනුව වෙත යන්න</div></li>' +
    '<li><div>පතන මෙනුවේ <b>සැකසීම්</b> තෝරන්න</div></li>' +
    '<li><div>තෝරන්න <b>පෞද්ගලිකත්වය සහ ආරක්ෂාව</b> කොටසෙහි, <b>අඩවි සැකසීම්</b></div></li>' +
    '<li><div><b>අවසර</b> කොටසේ, <b>කැමරාව</b></div></li>' +
    '<li><div><b>පෙරනිමි හැසිරීම</b> කොටසේ, <b>අඩවි වලට ඔබේ කැමරාව භාවිතා කිරීමට ඇසිය හැක</b> විකල්පය සබල කර ඇති බව සහතික කර ගන්න</div></li>' +
    '<li><div>අමතරව, අපගේ වෙබ් අඩවිය සඳහන් කර නොමැති බවට සහතික වන්න<b>ඔබගේ කැමරාව භාවිතා කිරීමට අවසර නැත</b> ලැයිස්තුවේ</div></li>' +
    '</ul>',
  oz_tutorial_camera_desktop_chrome_title:
    '<b>Google Chrome</b> හි කැමරාවට ප්‍රවේශය සබල කිරීමට, කරුණාකර මෙම උපදෙස් අනුගමනය කරන්න:',
  oz_tutorial_camera_desktop_default_browser: 'Chrome',
  oz_tutorial_camera_desktop_default_instruction:
    '<ul>' +
    '<li><div>ඉහළ දකුණු කෙළවරේ ඇති තිත් තුන ක්ලික් කිරීමෙන් <b>අභිරුචිකරණය සහ පාලනය Google Chrome</b> මෙනුව වෙත යන්න</div></li>' +
    '<li><div>පතන මෙනුවේ <b>සැකසීම්</b> තෝරන්න</div></li>' +
    '<li><div>තෝරන්න <b>පෞද්ගලිකත්වය සහ ආරක්ෂාව</b> කොටසෙහි, <b>අඩවි සැකසීම්</b></div></li>' +
    '<li><div><b>අවසර</b> කොටසේ, <b>කැමරාව</b></div></li>' +
    '<li><div><b>පෙරනිමි හැසිරීම</b> කොටසේ, <b>අඩවි වලට ඔබේ කැමරාව භාවිතා කිරීමට ඇසිය හැක</b> විකල්පය සබල කර ඇති බව සහතික කර ගන්න</div></li>' +
    '<li><div>අමතරව, අපගේ වෙබ් අඩවිය සඳහන් කර නොමැති බවට සහතික වන්න <b>ඔබගේ කැමරාව භාවිතා කිරීමට අවසර නැත</b> ලැයිස්තුවේ</div></li>' +
    '</ul>',
  oz_tutorial_camera_desktop_default_title:
    'අවාසනාවකට, අපි ඔබගේ බ්‍රවුසරය හඳුනාගෙන නොමැත. <b>Chrome</b> නම්, මෙම ගැටළුව පහත පරිදි විසඳිය හැක:',
  oz_tutorial_camera_desktop_edge_browser: 'Microsoft Edge',
  oz_tutorial_camera_desktop_edge_instruction:
    '<ul>' +
    '<li><div>ඉහළ දකුණු කෙළවරේ ඇති තිත් තුන ක්ලික් කිරීමෙන් මෙනුව වෙත යන්න</div></li>' +
    '<li><div>පතන මෙනුවේ <b>සැකසීම්</b> තෝරන්න</div></li>' +
    '<li><div>ස්ථානගත කරන්න <b>කුකීස් සහ අඩවි අවසර</b></div></li>' +
    '<li><div>ක්ලික් කරන්න <b>කැමරාව</b></div></li>' +
    '<li><div><b>ප්‍රවේශ වීමට පෙර අසන්න (නිර්දේශිත)</b> විකල්පය සබල කර ඇති බව සහතික කර ගන්න</div></li>' +
    '<li><div>අමතරව, අපගේ වෙබ් අඩවිය සඳහන් කර නැති බව සහතික කර ගන්න <b>බ්ලොක්</b> ලැයිස්තුවේ</div></li>' +
    '</ul>',
  oz_tutorial_camera_desktop_edge_title:
    '<b>Microsoft Edge</b> හි කැමරාවට ප්‍රවේශය සබල කිරීමට, කරුණාකර මෙම උපදෙස් අනුගමනය කරන්න:',
  oz_tutorial_camera_desktop_firefox_browser: 'Mozilla Firefox',
  oz_tutorial_camera_desktop_firefox_instruction:
    '<ul>' +
    '<li><div>බ්‍රවුසරයේ ඉහළ දකුණු කෙළවරේ ඇති මෙනු බොත්තම (<b>“ඉරි තුනක්”</b> හෝ <b>හැම්බර්ගර්</b>) ඔබන්න.</div></li>' +
    '<li><div>තෝරන්න <b>සැකසීම්</b></div></li>' +
    '<li><div><b>පෞද්ගලිකත්වය සහ ආරක්ෂාව</b> කොටසේ, <b>කැමරාව</b> අසල <b>සැකසීම්</b> විවෘත කරන්න. <b>ප්‍රවේශ වීමට ඉල්ලා සිටින නව ඉල්ලීම් අවහිර කරන්න ඔබගේ කැමරාව</b> විකල්පය අබල කළ යුතුය</div></li>' +
    '<li><div>අපගේ වෙබ් අඩවිය කැමරාවට ප්‍රවේශ වීම තහනම් කර ඇත්නම්, කරුණාකර ලැයිස්තුවේ එහි තත්ත්වය මාරු කරන්න</div></li>' +
    '<li><div>ක්ලික් කරන්න <b>වෙනස්කම් සුරකින්න</b></div></li>' +
    '</ul>',
  oz_tutorial_camera_desktop_firefox_title:
    '<b>Mozilla Firefox</b> හි කැමරාවට ප්‍රවේශය සක්‍රීය කිරීමට, කරුණාකර මෙම උපදෙස් අනුගමනය කරන්න:',
  oz_tutorial_camera_desktop_opera_browser: 'ඔපෙරා',
  oz_tutorial_camera_desktop_opera_instruction:
    '<ul>' +
    '<li><div>වමට ඇති පුවරුවේ ඇති <b>සැකසීම්</b> බොත්තම (<b>“ගියර්”</b>) ඔබන්න.</div></li>' +
    '<li><div><b>පෞද්ගලිකත්වය සහ ආරක්ෂාව</b> කොටසේ, <b>අඩවි සැකසීම්</b> විවෘත කර <b>කැමරාව</b>. <b>අඩවි වලට ඇසිය හැක ඔබගේ කැමරාව භාවිතා කරන්න</b> විකල්පය සබල කළ යුතුය</div></li>' +
    '<li><div>අපගේ වෙබ් අඩවිය කැමරාවට ප්‍රවේශ වීම තහනම් කර ඇත්නම්, කරුණාකර <b>ඔබගේ කැමරාව භාවිතා කිරීමට අවසර නැත</b> ලැයිස්තුව</div></li>' +
    '</ul>',
  oz_tutorial_camera_desktop_opera_title:
    '<b>ඔපෙරා</b> හි කැමරාවට ප්‍රවේශය සබල කිරීමට, කරුණාකර මෙම උපදෙස් අනුගමනය කරන්න:',
  oz_tutorial_camera_desktop_yandex_browser: 'Yandex Browser',
  oz_tutorial_camera_desktop_yandex_instruction:
    '<ul>' +
    '<li><div>බ්‍රවුසරයේ ඉහළ දකුණු කෙළවරේ ඇති <b>Yandex බ්‍රවුසරයේ සිටුවම්</b> බොත්තම (<b>"ඉරි තුනක්"</b> හෝ <b>hamburger</b>) ඔබන්න.</div></li>' +
    '<li><div>තෝරන්න <b>සැකසීම්</b></div></li>' +
    '<li><div><b>වෙබ් අඩවි</b> ස්ථානගත කර <b>උසස් අඩවි සැකසීම්</b> කොටස විවෘත කරන්න</div></li>' +
    '<li><div><b>ඔබගේ කැමරාවට ප්‍රවේශය</b> කොටසේ, <b>අවසර ඉල්ලන්න</b> විකල්පය තෝරාගෙන ඇති බවට වග බලා ගන්න</div></li>' +
    '<li><div>මෙම විකල්පයට පහළින්, ක්ලික් කරන්න <b>අඩවි සැකසීම්</b></div></li> ' +
    '<li><div>සඳහන් කර නැති බව සහතික කර ගන්න අපගේ වෙබ් අඩවිය <b>අවසර නැති</b> කොටසේ</div></li>' +
    '</ul>',
  oz_tutorial_camera_desktop_yandex_title:
    '<b>Yandex බ්‍රව්සරයේ</b> කැමරාවට ප්‍රවේශය සක්‍රීය කිරීමට, කරුණාකර මෙම උපදෙස් අනුගමනය කරන්න:',
  oz_tutorial_camera_how_to: 'ප්‍රවේශයට ඉඩ දෙන්නේ කෙසේද',
  oz_tutorial_camera_ios_webview_browser: 'බිල්ට්-ඉන් බ්‍රව්සරය',
  oz_tutorial_camera_ios_webview_instruction:
    '<ul>' +
    '<li><div><strong>සැකසීම්</strong> වෙත යන්න.</div></li>' +
    '<li><div>ඔබට කැමරා ප්‍රවේශය සබල කිරීමට අවශ්‍ය යෙදුම තෝරන්න.</div></li>' +
    '<li><div><strong>කැමරා</strong> ස්විචය <strong>අවසර</strong> වෙත ටොගල් කරන්න.</div></li>' +
    '</ul>' +
    '<p>මෙම පියවර මඟින් ඔබගේ උපාංගයේ ඇති කැමරාවට ප්‍රවේශ වීමට යෙදුමට ඉඩ ලබා දේ. ඔබට කිසියම් ගැටළුවක් ඇත්නම්, වැඩිදියුණු කළ ගැළපුම සඳහා Google Chrome වැනි වෙනත් බ්‍රවුසරයක් භාවිතයෙන් යෙදුම වෙත ප්‍රවේශ වීමට උත්සාහ කරන්න.</p>',
  oz_tutorial_camera_ios_webview_title:
    'ඔබගේ iOS උපාංගයේ යෙදුමක් සඳහා කැමරා ප්‍රවේශය සබල කිරීමට, කරුණාකර මෙම පියවර අනුගමනය කරන්න:',
  oz_tutorial_camera_title: 'වැඩිදුර වැඩ සඳහා, කරුණාකර කැමරාවට ප්‍රවේශ වීමට ඉඩ දෙන්න',
  photo_tutorial_description:
    'ඉදිරිපත් සහ පසු පිටුවල ඇති විදේශ ගමන් බලපත්‍රය හෝ හැඳුනුම්පත නිකුත් කරන්නා සහ නිකුත් කළ දිනය පිළිබඳ තොරතුරු සමඟින් රූපයේ සම්පූර්ණයෙන්ම දෘශ්‍යමාන විය යුතුය. උසස් තත්ත්වයේ රූපයක්, දිලිසීමකින් තොරව අවශ්‍ය වේ.',
  photo_tutorial_list:
    '<ul>' +
    '<li data-icon="pages">ID කාඩ්පතේ MRZ-ක්ෂේත්‍රය ලේඛනයේ පසුපස පැත්තේ නම්, ඔබ ඉදිරියෙන් සහ පිටුපසින් රූප දෙකක් සෑදිය යුතුය.</li>' +
    '<li data-icon="angle">ඔබ ඡායාරූප ගන්නේ නිවැරදි කෝණවලින් බව සහතික කර ගන්න.</li>' +
    '<li data-icon="flash">ෆ්ලෑෂ් භාවිතා නොකරන්න. ඒකාකාර ආලෝකකරණය භාවිතා කිරීමට උත්සාහ කරන්න.</li>' +
    '<li data-icon="bstruction">රූපය ඉදිරිපිට විදේශීය වස්තූන් නොමැති බවට වග බලා ගන්න.</li>' +
    '<li data-icon="blur">රූපය නොපැහැදිලි ලෙස නොපෙන්වන ලෙස කැමරාව ස්ථාවරව තබා ගන්න.</li>' +
    '<li data-icon="contrast">ලේඛනය පරස්පර පසුබිම මත තබන්න (උදා: කළු වගුව).</li>' +
    '<li data-icon="edges">ලේඛනයේ මායිම් රාමුව තුළ ඇති බවට වග බලා ගන්න (ලේඛනයේ මායිම් රාමුවේ මායිම සමඟ සමපාත නොවිය යුතුය).</li>' +
    '</ul>',
  error_camera_timeout_instruction:
    '<ul>' +
    '<li><div>කැමරාව භාවිත කළ හැකි වෙනත් යෙදුම් වසන්න.</div></li>' +
    '<li><div>කැමරාව භාවිතා කරන අනෙකුත් බ්‍රවුසර ටැබ් වසන්න.</div></li>' +
    '<li><div>ඔබේ වත්මන් බ්‍රවුසරයට හෝ යෙදුමට බ්‍රවුසරයේ සහ OS සැකසීම් දෙකෙහිම කැමරාවට ප්‍රවේශ වීමට ඉඩ දෙන්න.</div></li>' +
    '<li><div>නැවත උත්සාහ කරන්න.</div></li>' +
    '</ul>',
  error_camera_timeout_title: 'අපට ඔබේ කැමරාවට ප්‍රවේශ විය නොහැකි විය. ඉදිරියට යාමට:',
  photo_tutorial_title: 'ලේඛනයේ ඡායාරූපය සෑදීම',
  processing_data: 'දත්ත සැකසීම',
  record_button: 'වාර්තා කරන්න',
  requesting_result: 'ප්‍රතිඵල ඉල්ලමින්',
  result_close_button: 'වසන්න',
  result_state_finished: 'විශ්ලේෂණය සම්පූර්ණයි',
  result_state_processing: 'විශ්ලේෂණය සිදුවෙමින් පවතී',
  result_wait: 'කරුණාකර තත්පර කිහිපයක් රැඳී සිටින්න',
  sdk_name: 'OzLivenessSDK',
  see_on_this_text: 'මෙම පෙළ දෙස බලන්න',
  tap_to_continue: 'ඉදිරියට යාමට හරි තට්ටු කරන්න',
  uploading_data: 'දත්ත උඩුගත කරමින්',
  error_camera_access_title: 'අපි ඔබගේ කැමරාවට පිවිසීමට නොහැකි විය',
  accessing_camera_switch_to_another_browser:
    'කැමරාවට ප්‍රවේශ වීමට වැඩි කාලයක් ගත වුවහොත්, ඉහත විස්තර කර ඇති පරිදි වෙනත් බ්‍රවුසරයකට මාරු වන්න',
  error_camera_timeout_android_facebook_instruction:
    '<ul>' +
    '<li>' +
    '<h1>මෙතන තට්ටු කරන්න</h1>' +
    '<p>' +
    'ලකුණු තුන ඔබන්න<br />' +
    'බ්‍රවුසරයේ බොත්තම<br />' +
    'ඉහළ දකුණු කෙළවරේ.' +
    '</p>' +
    '<top_right_arrow>' +
    '</li>' +
    '<li>' +
    '<h1>' +
    'විවෘත කරන්න තෝරන්න <br />' +
    'බාහිර බ්රවුසරය' +
    '</h1>' +
    '<facebook_screen>' +
    '</li>' +
    '</ul>',
};
