import Cookies, { CookieAttributes } from 'js-cookie';
import { INIT_VALUES_FORM_KEY } from 'core/constants';
import { WpKeys } from 'core/models';
import { getABTestVariantById } from 'utils';
import {
  COUNT_DAYS_SAVE_COOKIE,
  COUNT_DAYS_SAVE_FORM_VALUES,
  KEY_STORAGE_HYPER_SNAP_TOKEN,
  KEY_STORAGE_OLD_USER_ID,
  KEY_STORAGE_USER_ID,
  KEY_TEST_VERIFICATION_VARIANT,
} from 'constantsLk';

type Data = { [key: string]: any } | string | number | boolean;

const CookiesWithAttributes = Cookies.withAttributes({
  path: '/',
  domain: '.' + location.hostname,
  sameSite: 'strict',
  secure: true,
  expires: 30,
});

export const setCookie = (name: string, data: Data | Data[], options?: CookieAttributes) => {
  const isString = typeof data === 'string';

  CookiesWithAttributes.set(name, isString ? data : JSON.stringify(data), options);
};

export const getCookie = (name: string) => {
  const value = CookiesWithAttributes.get(name);

  try {
    return value ? JSON.parse(value) : null;
  } catch (e) {
    return value || null;
  }
};

export const removeCookie = (name: string) => CookiesWithAttributes.remove(name);

export const getCookies = (searchString?: string) => {
  return document.cookie.split(';').reduce((ac, cv) => {
    const arr = cv.split('=');
    const key = arr[0].trim();
    const value = arr[1];

    return !searchString
      ? {
          ...ac,
          [key]: value,
        }
      : key.includes(searchString)
        ? {
            ...ac,
            [key]: value,
          }
        : ac;
  }, {});
};

export const removeCookies = (searchString?: string) => {
  const cookies = getCookies(searchString);
  for (const key in cookies) {
    Cookies.remove(key);
  }
};

export const getAccessToken = () => getCookie(WpKeys.AccessToken);
export const getOldAccessToken = () => getCookie(WpKeys.OldUserAccessToken);
export const setAccessToken = (token: string) =>
  setCookie(WpKeys.AccessToken, token, { expires: COUNT_DAYS_SAVE_COOKIE });
export const setOldUserAccessToken = (token: string) =>
  setCookie(WpKeys.OldUserAccessToken, token, { expires: COUNT_DAYS_SAVE_COOKIE });
export const removeAccessToken = () => removeCookie(WpKeys.AccessToken);
export const removeOldAccessToken = () => removeCookie(WpKeys.OldUserAccessToken);

export const setUserID = (id: string) =>
  setCookie(KEY_STORAGE_USER_ID, id, { expires: COUNT_DAYS_SAVE_COOKIE });
export const setOldUserID = (id: string) =>
  setCookie(KEY_STORAGE_OLD_USER_ID, id, { expires: COUNT_DAYS_SAVE_COOKIE });
export const getUserID = () => getCookie(KEY_STORAGE_USER_ID);
export const getOldUserID = () => getCookie(KEY_STORAGE_OLD_USER_ID);
export const removeUserID = () => removeCookie(KEY_STORAGE_USER_ID);
export const removeOldUserID = () => removeCookie(KEY_STORAGE_OLD_USER_ID);
export const setStorageVerificationVariantById = (id: number) =>
  setCookie(KEY_TEST_VERIFICATION_VARIANT, getABTestVariantById(id));
export const removeStorageVerificationVariantById = () =>
  removeCookie(KEY_TEST_VERIFICATION_VARIANT);

export const setStorageHyperSnap = (token: string) =>
  sessionStorage.setItem(KEY_STORAGE_HYPER_SNAP_TOKEN, token);
export const getStorageHyperSnap = () => sessionStorage.getItem(KEY_STORAGE_HYPER_SNAP_TOKEN);
export const getRefreshToken = () => getCookie(WpKeys.RefreshToken);
export const getOldRefreshToken = () => getCookie(WpKeys.OldUserRefreshToken);
export const setRefreshToken = (token: string) =>
  setCookie(WpKeys.RefreshToken, token, { expires: COUNT_DAYS_SAVE_COOKIE });

export const setOldUserRefreshToken = (token: string) =>
  setCookie(WpKeys.OldUserRefreshToken, token, { expires: COUNT_DAYS_SAVE_COOKIE });
export const removeRefreshToken = () => removeCookie(WpKeys.RefreshToken);
export const removeOldRefreshToken = () => removeCookie(WpKeys.OldUserRefreshToken);

export const getI18language = () => getCookie(WpKeys.CurrentLanguage);

export const setInitStateForm = (formName: string, data: any) => {
  const initData = getCookie(INIT_VALUES_FORM_KEY);
  const currentData = {
    ...initData,
    [formName]: data,
  };
  setCookie(INIT_VALUES_FORM_KEY, currentData, { expires: COUNT_DAYS_SAVE_FORM_VALUES });
};

export const getInitStateForm = <T>(formName: string): T => {
  const initData = getCookie(INIT_VALUES_FORM_KEY) || {};
  return initData[formName] || {};
};

export const removeInitStateForm = () => removeCookies(INIT_VALUES_FORM_KEY);
