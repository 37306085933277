import { Button, ModalLayout } from 'core/components/common';
import { useTranslation } from 'react-i18next';

interface TakePhotoInstructionModalProps {
  title?: any;
  content?: any;
  onClose?: () => void;
  onOpen?: () => void;
}

export const TakePhotoInstructionModal = (props: TakePhotoInstructionModalProps) => {
  const { onClose, onOpen } = props;
  const { t } = useTranslation();

  return (
    <ModalLayout
      onClose={onClose}
      isOpenModal
      title={t`take_photo_instructions_title`}
      isShowClose
      isUsePortal
      content={<div className='txt-r'>{t`take_photo_instructions`}</div>}
      footer={
        <Button onClick={onOpen} classes={{ root: 'btn--primary w-100' }}>
          <span>{t`take_a_photo`}</span>
        </Button>
      }
    />
  );
};
