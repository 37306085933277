import { Outlet } from 'react-router-dom';
import { NoticeTypes } from 'core/models';
import { useTypedSelector } from 'core/hooks';
import { NoticesContainer, SnacksContainer } from 'core/components/common';
import {
  EmailAlert,
  EmailConfirmationModal,
  SubheaderContainer,
  SuccessfulPaymentAlert,
} from 'components';
import { ContactCollectionTeamContainer } from 'pages/account/AccountLoan/ActiveLoan/ContactCollectionTeam/ContactCollectionTeamContainer';
import {
  isAppApprovedApplicationSelector,
  isApplicationPendingSelector,
  isEmailVerifiedSelector,
  isLoanPendingSelector,
  isLoanSoldSelector,
  isShowCollectionAlertSelector,
  isSuccessfullyPaymentSelector,
  userSelector,
} from 'store/selectors';

export const AccountLayout = () => {
  const showCollectionAlert = useTypedSelector(isShowCollectionAlertSelector);
  const user = useTypedSelector(userSelector);
  const isEmailVerified = useTypedSelector(isEmailVerifiedSelector);
  const isLoanSold = useTypedSelector(isLoanSoldSelector);
  const isApplicationPending = useTypedSelector(isApplicationPendingSelector);
  const isAppApprovedApplication = useTypedSelector(isAppApprovedApplicationSelector);
  const isLoanPending = useTypedSelector(isLoanPendingSelector);
  const isSuccessfullyPayment = useTypedSelector(isSuccessfullyPaymentSelector);

  const isShowEmailAlert =
    !isLoanSold &&
    user &&
    user.email &&
    !user.is_email_verified &&
    !isEmailVerified &&
    !isApplicationPending &&
    !isAppApprovedApplication &&
    !isLoanPending;

  return (
    <div className='flex-grow-1 w-100 flex-column gap-4'>
      <SubheaderContainer />

      {isSuccessfullyPayment && <SuccessfulPaymentAlert />}
      {isShowEmailAlert && (
        <EmailAlert
          isEmailVerificationSent={user.is_email_verification_sent}
          userEmail={user.email}
        />
      )}
      <ContactCollectionTeamContainer showCollectionAlert={showCollectionAlert || isLoanSold} />

      <SnacksContainer autoHideDuration={5000} maxNotices={7} />
      <NoticesContainer typeNotice={NoticeTypes.Snacks} />

      <div className='account'>
        <Outlet />
      </div>

      {isEmailVerified && <EmailConfirmationModal />}
    </div>
  );
};
