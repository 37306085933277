import Cookies from 'js-cookie';
import { CreateAxiosDefaults } from 'axios';

export interface SettingsApiService {
  apiPath?: string;
  apiPathWp?: string;
  apiPathRefresh?: string;
  baseAxiosConfig: CreateAxiosDefaults;
}

export interface SettingsCore {
  environment: 'development' | 'production' | 'test';
  isDevelop: boolean;
  isDevelopStage: boolean;
  isProduction: boolean;
  sentryDsn?: string;
  gtmId?: string;
  defaultLanguage: string;
  apiService: SettingsApiService;
  dateFormat: string;
}

const ENVIRONMENT = process.env.NODE_ENV;
let API_PATH = process.env.REACT_APP_API_PATH;
const IS_DEVELOP = ENVIRONMENT === 'development';
const IS_PRODUCTION = ENVIRONMENT === 'production';
const IS_DEV_STAGE = !!window.location.origin.match(/(localhost|avgr.it)/);
const API_PATH_WP = IS_DEV_STAGE
  ? process.env.REACT_APP_API_PATH_WP
  : process.env.REACT_APP_API_PATH_WP;

if (IS_DEV_STAGE) {
  const search = new URLSearchParams(window.location.search);
  let apiUrl = search.get('apiUrl') || Cookies.get('dev_api_url');

  if (apiUrl && apiUrl[apiUrl.length - 1] === '/') {
    apiUrl = apiUrl.substring(0, apiUrl.length - 1);
  }

  if (apiUrl) {
    Cookies.set('dev_api_url', apiUrl);
    API_PATH = apiUrl;
  }
}

export const SETTINGS: SettingsCore = {
  environment: ENVIRONMENT,
  isDevelop: IS_DEVELOP,
  isDevelopStage: IS_DEV_STAGE,
  isProduction: IS_PRODUCTION,
  sentryDsn: process.env.REACT_APP_SENTRY_DSN,
  gtmId: process.env.REACT_APP_GTM_ID,
  defaultLanguage: process.env.REACT_APP_DEFAULT_LANGUAGE || 'si',
  dateFormat: process.env.REACT_APP_DATE_FORMAT || 'yyyy.MM.dd',
  apiService: {
    apiPath: API_PATH,
    apiPathWp: API_PATH_WP,
    apiPathRefresh: process.env.REACT_APP_API_PATH_REFRESH, // example => /refresh,
    baseAxiosConfig: {
      baseURL: API_PATH,
    },
  },
};
