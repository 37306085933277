import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { Button } from 'core/components/common';
import { SelectFieldHookForm, TextFieldHookForm } from 'core/components/fields';
import { maxLength } from 'core/utils/parsers';
import { SubmitCoreHandler } from 'core/models';

import { AdditionalDataFields, AdditionalDataFieldsFormModel, HasExternalLoans } from 'models';
import { useHookFormGetCache, useHookFormSetCache, useScrollToFieldError } from 'hooks';
import {
  ENG_REGEX,
  validateDurationWork,
  validateHasExternalLoans,
  validateOnlyEnglishCompanyName,
  validateOnlyEnglishJobPosition,
  validatePhoneFirstZero,
  validateSelect,
  validateText,
} from 'utils/validates/validation';
import { noSymbols, onlyNumbers } from 'utils/parsers';
import { GTM_LABEL_SIGN_UP } from 'utils/analitics/gtmLabel';
import { onSubmitEventTagHandler } from 'utils/analitics';
import { PhoneFieldFocusValidation, RadioFields, Title } from 'components';
import * as Paths from 'constantsLk/paths';
import {
  COUNTRY_PHONE_CODE,
  CURRENCY,
  EmploymentPosition,
  EmploymentType,
  MainSourceOfIncome,
  SELECT_OPTIONS,
} from 'constantsLk';

export interface Step5AdditionalDetailsProps {
  onSubmit: SubmitCoreHandler<AdditionalDataFieldsFormModel>;
}

export const Step5AdditionalDetails = (props: Step5AdditionalDetailsProps) => {
  const { onSubmit } = props;
  const { t, i18n } = useTranslation();
  const lang = i18n.language;
  const defaultValues = useHookFormGetCache(Paths.PATH_REG_STEP_5);

  const {
    handleSubmit,
    watch,
    control,
    setError,
    setValue,
    resetField,
    clearErrors,
    trigger,
    formState: { isSubmitting, errors, submitCount },
  } = useForm<AdditionalDataFieldsFormModel>({
    mode: 'onBlur',
    shouldFocusError: true,
    defaultValues,
    shouldUnregister: true,
  });

  useHookFormSetCache(Paths.PATH_REG_STEP_5, watch);
  useScrollToFieldError({ errors, submitCount });

  const onSubmitHandler = handleSubmit(
    (data) =>
      onSubmit?.(data, {
        setError,
      }),
    (errors) => onSubmitEventTagHandler(errors, GTM_LABEL_SIGN_UP),
  );

  const handlePhoneChange = (name: any, value: string | number) => {
    if (value.toString()[0] === '0') {
      trigger(name).then(() => setValue(name, ''));
    } else {
      clearErrors(name);
    }
  };

  const handleDurationFieldChange = (name: any) => trigger(name);

  const handleSelectChange = () => {
    resetField(AdditionalDataFields.SocialSecurityNumber);
    resetField(AdditionalDataFields.Industry);
    resetField(AdditionalDataFields.Position);
    resetField(AdditionalDataFields.Duration);
    resetField(AdditionalDataFields.CompanyName);
    resetField(AdditionalDataFields.CompanyPhone);
    resetField(AdditionalDataFields.MainSourceIncome);
  };

  const isExternalLoan = watch(AdditionalDataFields.ExternalLoan) === 'yes';
  const employmentType = watch(AdditionalDataFields.EmploymentType);
  const jobPosition = watch(AdditionalDataFields.Position);
  const MainSourceIncome = watch(AdditionalDataFields.MainSourceIncome);

  const isCustomJob = jobPosition === EmploymentPosition.Other;

  const isEmploymentIndustry =
    employmentType === EmploymentType.Employed || employmentType === EmploymentType.SelfEmployed;

  const isEmploymentDurationEmploymentPosition =
    employmentType === EmploymentType.Employed ||
    employmentType === EmploymentType.GovernmentService;

  const isEmploymentCompany =
    employmentType === EmploymentType.Employed ||
    employmentType === EmploymentType.GovernmentService ||
    employmentType === EmploymentType.SelfEmployed;

  const isMainSourceOfIncome =
    employmentType === EmploymentType.Student ||
    employmentType === EmploymentType.Pensioner ||
    employmentType === EmploymentType.MaternityLeave ||
    employmentType === EmploymentType.Housewife ||
    employmentType === EmploymentType.DisabledPerson ||
    employmentType === EmploymentType.TemporarilyUnemployed;

  const handleChangeText = (name: any, value: any) => {
    const isEngVal = ENG_REGEX.test(value);
    if (!isEngVal) {
      trigger(name).then();
    } else {
      clearErrors(name);
    }
  };

  useEffect(() => {
    if (MainSourceIncome && MainSourceIncome === MainSourceOfIncome.NoIncome) {
      setValue(AdditionalDataFields.MonthlyIncome, 0);
    } else {
      resetField(AdditionalDataFields.MonthlyIncome);
    }
  }, [MainSourceIncome]);

  return (
    <>
      <Title classes={'form-title'} name={'additional_details'} />
      <form onSubmit={onSubmitHandler}>
        <SelectFieldHookForm
          control={control}
          label={t`loan_purpose`}
          name={AdditionalDataFields.LoanPurpose}
          options={SELECT_OPTIONS.loan_purpose[lang]}
          rules={validateSelect}
        />
        <RadioFields
          control={control}
          name={AdditionalDataFields.ExternalLoan}
          options={[
            { value: HasExternalLoans.Yes, label: t('yes') },
            { value: HasExternalLoans.No, label: t('no') },
          ]}
          label={t`has_external_loan`}
          rules={validateHasExternalLoans}
        />
        {isExternalLoan && (
          <TextFieldHookForm
            isNumberField
            control={control}
            name={AdditionalDataFields.ExternalLoanMonthlyPayment}
            label={t`external_loan_monthly_payments`}
            parsers={[onlyNumbers, noSymbols, maxLength(9)]}
            rules={validateText}
            rightContent={CURRENCY}
            inputMode='numeric'
          />
        )}

        <SelectFieldHookForm
          control={control}
          label={t`education`}
          name={AdditionalDataFields.Education}
          options={SELECT_OPTIONS.education[lang]}
          rules={validateSelect}
        />
        <SelectFieldHookForm
          control={control}
          label={t`employment_type`}
          name={AdditionalDataFields.EmploymentType}
          options={SELECT_OPTIONS.employment_type[lang]}
          onChange={handleSelectChange}
          rules={validateSelect}
        />
        {isEmploymentIndustry && (
          <SelectFieldHookForm
            control={control}
            label={t`employment_industry`}
            name={AdditionalDataFields.Industry}
            options={SELECT_OPTIONS.employment_industry[lang]}
            rules={validateSelect}
          />
        )}
        {isEmploymentDurationEmploymentPosition && (
          <SelectFieldHookForm
            control={control}
            label={t`employment_position`}
            name={AdditionalDataFields.Position}
            options={SELECT_OPTIONS.employment_position[lang]}
            rules={validateSelect}
          />
        )}
        {isCustomJob && (
          <TextFieldHookForm
            control={control}
            name={AdditionalDataFields.CustomPosition}
            label={t`custom_job_position`}
            parsers={[maxLength(30)]}
            rules={validateOnlyEnglishJobPosition}
            onChange={handleChangeText}
          />
        )}
        {isEmploymentDurationEmploymentPosition && (
          <TextFieldHookForm
            isNumberField
            control={control}
            name={AdditionalDataFields.Duration}
            label={t`employment_duration`}
            parsers={[onlyNumbers, maxLength(3)]}
            rules={validateDurationWork}
            inputMode='numeric'
            rightContent={t`months`}
            classes={{ root: '__no-empty' }}
            onChange={handleDurationFieldChange}
          />
        )}
        {isEmploymentCompany && (
          <TextFieldHookForm
            control={control}
            name={AdditionalDataFields.CompanyName}
            label={t`employment_company_name`}
            parsers={[maxLength(30)]}
            rules={validateOnlyEnglishCompanyName}
            onChange={handleChangeText}
          />
        )}
        {isEmploymentCompany && (
          <PhoneFieldFocusValidation
            leftContent={COUNTRY_PHONE_CODE}
            label={t`employment_company_phone_number`}
            control={control}
            inputMode='numeric'
            placeholder='XXX XXX XXX'
            classes={{ root: '__no-empty' }}
            name={AdditionalDataFields.CompanyPhone}
            rules={validatePhoneFirstZero}
            onChange={handlePhoneChange}
          />
        )}
        {isMainSourceOfIncome && (
          <SelectFieldHookForm
            control={control}
            label={t`main_source_of_income`}
            name={AdditionalDataFields.MainSourceIncome}
            options={SELECT_OPTIONS.main_source_of_income[lang]}
            rules={validateSelect}
          />
        )}
        <TextFieldHookForm
          isNumberField
          control={control}
          name={AdditionalDataFields.MonthlyIncome}
          label={t`monthly_income`}
          parsers={[onlyNumbers, noSymbols, maxLength(9)]}
          rules={validateText}
          classes={{ root: '__no-empty' }}
          rightContent={CURRENCY}
          inputMode='numeric'
        />

        <Button
          disabled={isSubmitting}
          classes={{ root: 'btn--primary mt-1 next-step' }}
        >{t`continue`}</Button>
      </form>
    </>
  );
};
