import { addSeconds } from 'date-fns';
import { WpKeys } from 'core/models';
import { getCookie, setCookie } from 'core/services';
import { KEY_STORAGE_WRITE_TARGET_URL } from 'constantsLk';

const ONE_MINUTE = 60;

export function getTargetUrlParams() {
  const search = new URLSearchParams(window.location.search);

  const url = window.location.href;
  const isNdl = url.includes('&ndl');

  const utmSource = search.get('utm_source');

  const expiresTargetUrl = addSeconds(new Date(), ONE_MINUTE);

  if (utmSource && !isNdl && !getCookie(KEY_STORAGE_WRITE_TARGET_URL)) {
    setCookie(WpKeys.TargetUrl, url, { expires: 30 });
    setCookie(KEY_STORAGE_WRITE_TARGET_URL, '1', { expires: expiresTargetUrl });
  }

  if (!utmSource && !getCookie(WpKeys.TargetUrl)) {
    const refererUrl = getRefererUrl();
    setCookie(WpKeys.TargetUrl, refererUrl, { expires: 30 });
  }
}

const getRefererUrl = () => {
  const url = new URL(document.referrer, 'https://test_url_lk');

  switch (url.hostname) {
    case 'www.google.com':
    case 'www.google.lk':
      return 'https://cashx.lk/?utm_source=google&utm_medium=organic&utm_campaign=organic';

    case 'cashx.lk':
    case 'test_url_lk':
      return 'https://cashx.lk/?utm_source=direct&utm_medium=none&utm_campaign=direct';

    default:
      return `https://cashx.lk/?utm_source=referral&utm_medium=referral&utm_campaign=${url.hostname}`;
  }
};
