import { useTypedSelector } from 'core/hooks';
import { AccountPersonalInformationPage } from './AccountPersonalInformation';
import { userSelector, wpContentHeaderSelector } from 'store/selectors';

const AccountPersonalInformationPageContainer = () => {
  const wpContentHeader = useTypedSelector(wpContentHeaderSelector);
  const user = useTypedSelector(userSelector);

  return (
    <AccountPersonalInformationPage
      user={user}
      phone={wpContentHeader?.contacts.phone}
      schedule={wpContentHeader?.contacts.schedule}
    />
  );
};

export default AccountPersonalInformationPageContainer;
