import { FC, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import { Button } from 'core/components/common';
import { TextFieldHookForm } from 'core/components/fields';
import { SubmitCoreHandler } from 'core/models';
import { CreditBureauReportFields, CreditBureauReportFormModel } from 'models';
import { validateText, validateURL } from 'utils/validates/validation';
import { SuccessIcon } from 'components/icons';
import { ITEM_STATUS } from 'constantsLk';
import { RequestStatus } from '../RequestStatus';

interface CreditBureauReportProps {
  formState: string;
  onSubmit: SubmitCoreHandler<CreditBureauReportFormModel>;
}

const Success = () => {
  const { t } = useTranslation();

  return (
    <RequestStatus
      icon={<SuccessIcon />}
      title={'credit_bureau_report_success_title'}
      text={t`credit_bureau_report_success_description`}
    />
  );
};

export const CreditBureauReport: FC<CreditBureauReportProps> = ({ formState, onSubmit }) => {
  const { t } = useTranslation();

  const {
    handleSubmit,
    control,
    setError,
    setValue,
    formState: { isSubmitting },
  } = useForm<CreditBureauReportFormModel>({
    mode: 'onBlur',
    defaultValues: {},
  });

  const onSubmitHandler = handleSubmit((data) =>
    onSubmit?.(data, {
      setError,
    }),
  );

  useEffect(() => {
    if (formState === ITEM_STATUS.SUCCESS) {
      setValue(CreditBureauReportFields.Link, '');
      setValue(CreditBureauReportFields.Password, '');
    }
  }, [formState]);

  if (formState === ITEM_STATUS.SUCCESS) {
    return <Success />;
  }

  return (
    <RequestStatus
      title={'credit_bureau_report_title'}
      text={
        <Trans
          className='txt txt-base-ellipsis'
          i18nKey='credit_bureau_report_description'
          values={{ linkText: 'https://onlineireport.crib.lk/cap/' }}
          components={{
            a: (
              <a
                href='https://onlineireport.crib.lk/cap/'
                target='_blank'
                rel='noreferrer'
                className='txt-sb'
              />
            ),
          }}
        />
      }
      footer={
        <form className='flex-column gap-6 mt-2 w-100' onSubmit={onSubmitHandler}>
          <div className='flex-column gap-4'>
            <TextFieldHookForm
              control={control}
              name={CreditBureauReportFields.Link}
              label={t`credit_bureau_report_link`}
              rules={validateURL}
            />
            <TextFieldHookForm
              control={control}
              name={CreditBureauReportFields.Password}
              label={t`credit_bureau_report_password`}
              rules={validateText}
            />
          </div>
          <Button classes={{ root: 'btn--primary w-100' }} disabled={isSubmitting}>
            {t`credit_bureau_report_button`}
          </Button>
        </form>
      }
    />
  );
};
