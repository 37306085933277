import { FC, useEffect, useRef } from 'react';
import { FormState } from 'react-hook-form/dist/types/form';

export const useScrollToFieldError: FC<Partial<FormState<any>>> = (props) => {
  const { errors, submitCount } = props;

  const prevSubmitCount = useRef<number | undefined>(-1);

  useEffect(() => {
    if (!errors || submitCount === prevSubmitCount.current) return;

    const form = document.querySelector('form');
    const element = form && form.querySelector('.error-text');
    if (!element) return;

    prevSubmitCount.current = submitCount;
    element.scrollIntoView({ behavior: 'smooth', block: 'center' });
  }, [submitCount, errors]);

  return null;
};
