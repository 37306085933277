import { translationsEN } from './translationsEN';
import { translationsSI } from './translationsSI';

export const resources = {
  resources: {

    en: {
      translations: translationsEN,
    },
    si: {
      translations: translationsSI,
    },
  },
};
