import { createSlice } from '@reduxjs/toolkit';
import { setIsServerError } from 'store/actions';

export interface ILayoutSliceInitialState {
  isServerError: boolean;
}

const initialState: ILayoutSliceInitialState = {
  isServerError: false,
};

export const layoutSlice = createSlice({
  name: 'layoutSlice',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder.addCase(setIsServerError, (state, action) => {
      state.isServerError = action.payload;
    });
  },
});
