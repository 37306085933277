import { ERRORS } from '../constantsLk';

export const getPromoCodeErrorMessage = (message: string) => {
  switch (message) {
    case ERRORS.PROMO_CODE.NOT_APPLICABLE:
      return 'promocode_not_applicable';
    case ERRORS.PROMO_CODE.HAVE_BEEN_USED:
      return 'the_promocode_used';
    case ERRORS.PROMO_CODE.NOT_FOUND:
      return 'promocode_not_found';
    case ERRORS.PROMO_CODE.DISABLED:
      return 'promocode_disabled';
    case ERRORS.PROMO_CODE.EXPIRED:
      return 'the_promocode_has_expired';
    default:
      return message;
  }
};
