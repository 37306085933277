export enum Actions {
  UPDATE_USER = 'UPDATE_USER',
  SEND_OTP_PASSWORD = 'SEND_OTP_PASSWORD',
  CREATE_USER = 'CREATE_USER',
  SEND_CONFIRMATION_CODE = 'SEND_CONFIRMATION_CODE',
  UPLOAD_FILE_BY_TYPE = 'UPLOAD_FILE_BY_TYPE',
  GET_USER_INFO = 'GET_USER_INFO',
  RESEND_CONFIRMATION_CODE = 'RESEND_CONFIRMATION_CODE',
  CALL_CONFIRM = 'CALL_CONFIRM',
  GET_STATE_CALL_CONFIRM = 'GET_STATE_CALL_CONFIRM',
  POST_USER_RESOLVE_MATCH = 'POST_USER_RESOLVE_MATCH',
  POST_SELECT_USER_PHONE_RESOLVE_ACCOUNT = 'POST_SELECT_USER_PHONE_RESOLVE_ACCOUNT',
  POST_FACEBOOK_REGISTRATION = 'POST_FACEBOOK_REGISTRATION',
  GENERATE_TOKEN_HYPER_SNAP = 'GENERATE_TOKEN_HYPER_SNAP',
  SEND_LIVENESS_LOG = 'SEND_LIVENESS_LOG',
  SEND_DUPLICATE_LOG = 'SEND_DUPLICATE_LOG',
  SEND_LIVENESS_IMAGE = 'SEND_LIVENESS_IMAGE',
  GET_LIVENESS_DETECTION = 'GET_LIVENESS_DETECTION',
  GET_PRODUCT = 'GET_PRODUCT',
  GET_PRODUCT_ID = 'GET_PRODUCT_ID',
  GET_PAYMENT_HISTORY = 'GET_PAYMENT_HISTORY',
  GET_USER_LOANS = 'GET_USER_LOANS',
  GET_DATA_BY_SLUG = 'GET_DATA_BY_SLUG',
  GET_DATA_BY_SLUG_ACTION = 'GET_DATA_BY_SLUG_ACTION',
  GET_LOAN_BY_SLUG = 'GET_LOAN_BY_SLUG',
  VERIFIED_NIC_FRONT = 'VERIFIED_NIC_FRONT',
  GET_FILE_BY_TYPE = 'GET_FILE_BY_TYPE',
  OTP_LOGIN = 'OTP_LOGIN',
  GET_AGREEMENTS = 'GET_AGREEMENTS',
  CHANGE_APPLICATION_PRINCIPAL = 'CHANGE_APPLICATION_PRINCIPAL',
  GET_AGREE_DOCUMENTS = 'GET_AGREE_DOCUMENTS',
  RESEND_AGREE_CODE = 'RESEND_AGREE_CODE',
  CONFIRM_AGREE_CODE = 'CONFIRM_AGREE_CODE',
  CREATE_APPLICATION = 'CREATE_APPLICATION',
  GET_USER_APPLICATIONS = 'GET_USER_APPLICATIONS',
  OTP_LOGIN_ACTION = 'OTP_LOGIN_ACTION',
  EVENT_VERIFIED_NIC_FRONT = 'EVENT_VERIFIED_NIC_FRONT',
  GET_FILE_BY_TYPE_ACTION = 'GET_FILE_BY_TYPE_ACTION',
  SIGN_IN = 'SIGN_IN',
  GET_ALL_USER_DATA = 'GET_ALL_USER_DATA',
  CREATE_USER_ACTION = 'CREATE_USER_ACTION',
  POST_USER_RESOLVE_MATCH_ACTION = 'POST_USER_RESOLVE_MATCH_ACTION',
  POST_SELECT_USER_PHONE_RESOLVE_MATCH_ACTION = 'POST_SELECT_USER_PHONE_RESOLVE_MATCH_ACTION',
  GET_DEFAULT_CALCULATOR_CONFIG = 'GET_DEFAULT_CALCULATOR_CONFIG',
  GET_DEFAULT_CALCULATOR_CONFIG_ACTION = 'GET_DEFAULT_CALCULATOR_CONFIG_ACTION',
  GET_ATTEMPTS = 'GET_ATTEMPTS',
  SEND_REPAYMENT_SLIP = 'SEND_REPAYMENT_SLIP',
  SEND_TRANSACTION_ID = 'SEND_TRANSACTION_ID',
  GET_PAYMENT_BANKS = 'GET_PAYMENT_BANKS',
  GET_EMAIL_VERIFY = 'GET_EMAIL_VERIFY',
  SEND_VERIFICATION_EMAIL = 'SEND_VERIFICATION_EMAIL',
  SEND_REFERRAL_LINK = 'SEND_REFERRAL_LINK',
  SEND_AB_TEST_VARIANT_ACTION = 'SEND_AB_TEST_VARIANT_ACTION',
  RESOLVE_SIMPLIFIED_REGISTRATION = 'RESOLVE_SIMPLIFIED_REGISTRATION',
  RESET_SIMPLIFIED_REGISTRATION = 'RESET_SIMPLIFIED_REGISTRATION',
  SEND_SELFIE_FILE_ID = 'SEND_SELFIE_FILE_ID',
  GET_APPLICATION_TRANSITION = 'GET_APPLICATION_TRANSITION',
  APPLY_PROMO_CODE = 'APPLY_PROMO_CODE',
  SEND_CREDIT_BUREAU_REPORT_DATA = 'SEND_CREDIT_BUREAU_REPORT_DATA',
  TOP_UP_TRANSITION = 'TOP_UP_TRANSITION',
  RESEND_TOP_UP_CODE = 'RESEND_TOP_UP_CODE',
  GET_TOP_UP_PRICE_LIST = 'GET_TOP_UP_PRICE_LIST',
}
