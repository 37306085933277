import { useState } from 'react';
import { UseFormSetValue } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { maxLength } from 'core/utils/parsers';
import { UseControllerCoreProps } from 'core/models';
import { SelectFieldHookForm, TextFieldHookForm } from 'core/components/fields';

import { PersonalDataFormFields, PersonalDataFormModel } from 'models';
import {
  validatePersonalIdNew,
  validatePersonalIdOld,
  validateSelect,
} from 'utils/validates/validation';
import { PersonalIdType } from 'models/common';
import { onlyNumbers } from 'utils/parsers';
import { autoDateGenderOfNIC } from 'utils/functions';
import { MASK_PERSONAL_ID_NEW, MASK_PERSONAL_ID_OLD } from 'constantsLk';

interface IbanFieldProps {
  personalIdType?: string;
  setValue: UseFormSetValue<PersonalDataFormModel>;
}

export const listPersonalId = [
  { value: 'V', label: 'V' },
  { value: 'X', label: 'X' },
];

export const TypedId = (props: IbanFieldProps & Partial<UseControllerCoreProps>) => {
  const { setValue, personalIdType, control } = props;
  const { t } = useTranslation();

  const isNewPersonalIdType = personalIdType === PersonalIdType.New;

  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const onChangeIdHandler = (name: string, value: string | number) => {
    let infoNic;
    const onlyNumPersonalId = onlyNumbers(value.toString()).replace(/\s/g, '');
    setValue(PersonalDataFormFields.DateOfBirth, '');
    setValue(PersonalDataFormFields.Gender, '');
    setValue(
      PersonalDataFormFields.PersonalIdType,
      +onlyNumPersonalId.slice(0, 2) < 21 ? PersonalIdType.New : PersonalIdType.Old,
    );

    if (personalIdType === PersonalIdType.Old) {
      infoNic = autoDateGenderOfNIC(`${onlyNumPersonalId}X`);
      setValue(PersonalDataFormFields.PersonalIdNumberOld, onlyNumPersonalId);
      setValue(PersonalDataFormFields.PersonalIdNumNew, '');
    } else {
      infoNic = autoDateGenderOfNIC(onlyNumPersonalId);
      setValue(PersonalDataFormFields.PersonalIdNumNew, onlyNumPersonalId);
      setValue(PersonalDataFormFields.PersonalIdNumberOld, '');
    }
    if (typeof infoNic === 'string') {
      setErrorMessage(infoNic);
    } else if (infoNic && infoNic.gender) {
      setErrorMessage(null);
      setValue(PersonalDataFormFields.Gender, infoNic.gender);
      setValue(PersonalDataFormFields.DateOfBirth, infoNic.dateWidget);
    } else {
      setErrorMessage(null);
    }
  };

  return (
    <div>
      <div className='form-group w-100'>
        <TextFieldHookForm
          control={control}
          name={
            isNewPersonalIdType
              ? PersonalDataFormFields.PersonalIdNumNew
              : PersonalDataFormFields.PersonalIdNumberOld
          }
          label={t`personal_id_number`}
          maskChar='_'
          onChange={onChangeIdHandler}
          mask={isNewPersonalIdType ? MASK_PERSONAL_ID_NEW : MASK_PERSONAL_ID_OLD}
          rules={isNewPersonalIdType ? validatePersonalIdNew : validatePersonalIdOld}
          parsers={[maxLength(isNewPersonalIdType ? 23 : 17)]}
          inputMode='numeric'
        />

        {!isNewPersonalIdType && (
          <SelectFieldHookForm
            control={control}
            name={PersonalDataFormFields.PersonalIdNumberOldSerial}
            options={listPersonalId}
            rules={validateSelect}
          />
        )}
      </div>

      {errorMessage && <p className='txt txt-xs txt-error mt-2'>{t(errorMessage)}</p>}
    </div>
  );
};
