import { useEffect, useState } from 'react';
import { PriceInfo } from './PriceInfo';
import { Prolongation } from './Prolongation';
import { Repayment } from './Repayment';
import { Loan, PromotionModel, User } from 'models';
import {
  AddNotificationModal,
  ExtendedLoanModal,
  HowToGetBonuses,
  NeedMoreMoney,
  ProlongationPromoIcon,
  ProlongationPromoIcon2,
  TopUpContainer,
  TopUpStatus,
} from 'components';
import { Promotion } from './Promotion';
import { ProlongationPromotion } from './ProlongationPromotion';
import { setStorageGoogleNotification } from 'utils/functions';
import { DISCOUNT_STATE, KEY_NOTIFICATION_DATA } from 'constantsLk';

interface ActiveLoanProps {
  loan: Loan;
  user: User | null;
  isProlongable: boolean;
  isShowPartners: boolean | null;
  onClose: () => void;
  activePromotion?: PromotionModel;
  activeProlongationPromotion?: PromotionModel;
}

export const ActiveLoan = ({
  loan,
  user,
  isProlongable,
  isShowPartners,
  onClose,
  activePromotion,
  activeProlongationPromotion,
}: ActiveLoanProps) => {
  const isLoanSequenceNumberOneOrTwo = loan.loan_index < 3;
  const isReferralPromo = Boolean(user?.referral_info?.referral_link);
  const isRepaymentPromoActive = activePromotion?.state === DISCOUNT_STATE.ACTIVE;
  const isProlongationPromoActive = activeProlongationPromotion?.state === DISCOUNT_STATE.ACTIVE;

  const [isOpenTopUp, setIsOpenTopUp] = useState(
    loan?.top_up_info &&
      !!loan?.top_up_info.enabled_transitions.length &&
      loan?.top_up_info.state !== TopUpStatus.Successful,
  );
  const [isOpenNotificationModal, setIsOpenNotificationModal] = useState(false);
  const [isOpenExtendedLoanModal, setIsOpenExtendedLoanModal] = useState(false);

  const handleCloseTopUp = () => setIsOpenTopUp(false);
  const handleCloseNotificationModal = () => {
    if (!localStorage.getItem(KEY_NOTIFICATION_DATA)) {
      setStorageGoogleNotification(loan?.id, loan?.matured_at, 'close');
    }
    setIsOpenNotificationModal(false);
  };
  const handleCloseExtendedLoanModal = () => setIsOpenExtendedLoanModal(false);

  useEffect(() => {
    const notificationData = localStorage.getItem(KEY_NOTIFICATION_DATA);

    if (notificationData) {
      const data = JSON.parse(notificationData);

      if (data.loanId !== loan.id) {
        localStorage.removeItem(KEY_NOTIFICATION_DATA);
        setIsOpenNotificationModal(true);
      } else if (data.maturedAt !== loan.matured_at && data.action === 'click') {
        setIsOpenExtendedLoanModal(true);
        setStorageGoogleNotification(loan?.id, loan?.matured_at, 'click');
      }
    } else {
      setIsOpenNotificationModal(true);
    }
  }, []);

  const renderPromo = (hiddenClass: string) =>
    isRepaymentPromoActive && (
      <div className={`w-100 ${hiddenClass}`}>
        <Promotion activePromo={activePromotion} paymentLinkUri={loan.payment_link_uri} />
      </div>
    );

  const renderProlongationPromo = (icon: JSX.Element, hiddenClass: string) =>
    isProlongable &&
    isProlongationPromoActive && (
      <div className={`w-100 ${hiddenClass}`}>
        <ProlongationPromotion
          activeCreditData={loan}
          isCollapsible={isLoanSequenceNumberOneOrTwo}
          activePromo={activeProlongationPromotion}
          icon={icon}
        />
      </div>
    );

  const renderTopUpBlock = (hiddenClass: string) =>
    isOpenTopUp && (
      <div className={`mb-4 w-100 ${hiddenClass}`}>
        <TopUpContainer
          topUpInfo={loan?.top_up_info}
          userPhone={user?.phone_number}
          handleCloseTopUp={handleCloseTopUp}
        />
      </div>
    );

  return (
    <div className='row align-items-start'>
      {/* left column */}
      <div className='col-12 col-md-6 mb-4'>
        {renderTopUpBlock('hidden-min-lg-tablet')}

        {isReferralPromo && (
          <div className='mb-4 tablet-mb-6 w-100'>
            <HowToGetBonuses user={user} withoutContent />
          </div>
        )}

        {renderPromo(isReferralPromo ? 'hidden-min-lg-tablet' : '')}

        {renderProlongationPromo(
          <ProlongationPromoIcon />,
          isReferralPromo || isRepaymentPromoActive ? 'hidden-min-lg-tablet' : '',
        )}

        {isShowPartners && <NeedMoreMoney screen={2} onClose={onClose} loanId={loan.id} />}

        <PriceInfo activeCreditData={loan} />
      </div>

      {/* right column */}
      <div className='col-12 col-md-6 mb-4'>
        {renderTopUpBlock('hidden-lg-tablet')}

        {renderPromo(isReferralPromo ? 'hidden-lg-tablet' : 'hidden')}

        {renderProlongationPromo(
          <ProlongationPromoIcon2 />,
          isReferralPromo || isRepaymentPromoActive ? 'hidden-lg-tablet' : 'hidden',
        )}

        {isProlongable && !isProlongationPromoActive && !isLoanSequenceNumberOneOrTwo && (
          <div className='tablet-mb-6 mb-4 w-100'>
            <Prolongation activeCreditData={loan} isCollapsible={isLoanSequenceNumberOneOrTwo} />
          </div>
        )}

        <div className='tablet-mb-6 mb-4 w-100'>
          <Repayment activeCreditData={loan} userInfo={user} />
        </div>

        {isProlongable && !isProlongationPromoActive && isLoanSequenceNumberOneOrTwo && (
          <Prolongation activeCreditData={loan} isCollapsible={isLoanSequenceNumberOneOrTwo} />
        )}
      </div>

      <AddNotificationModal
        isOpen={isOpenNotificationModal}
        onClose={handleCloseNotificationModal}
      />
      <ExtendedLoanModal isOpen={isOpenExtendedLoanModal} onClose={handleCloseExtendedLoanModal} />
    </div>
  );
};
