import { useTranslation } from 'react-i18next';
import { UploadIdPhotoModal } from './UploadIdPhotoModal';
import { useForm } from 'react-hook-form';

import { useTypedSelector } from 'core/hooks';

import { IUploadPhotoComponentFormProps, UploadPhotoComponent } from '../../DocumentUploader';
import { loadedIDFrontSelector } from 'store/selectors';
import { useUploadFiles } from 'hooks';
import { getFileByTypeAction } from 'store/actions/fileUploadActions';
import { DocumentsType } from 'models';
import { postUserResolveMatchAction } from 'store/actions';
import { DOCUMENTS } from 'constantsLk';

interface UploadIdPhotoModalContainerProps {
  sendLog: (action: string, additional_data: string, source_user_id?: number) => any;
}

export const UploadIdPhotoModalContainer = (props: UploadIdPhotoModalContainerProps) => {
  const { sendLog } = props;

  const loadedIDFront = useTypedSelector(loadedIDFrontSelector);
  const { t } = useTranslation();
  const { changeTypeFile, sendFile } = useUploadFiles({
    addFileAPI: postUserResolveMatchAction,
    getFileAPI: getFileByTypeAction,
  });

  const { control, setValue } = useForm<IUploadPhotoComponentFormProps>({
    mode: 'onSubmit',
    defaultValues: {
      [DocumentsType.IDCard]: false,
    },
    shouldUnregister: true,
  });

  return (
    <UploadIdPhotoModal
      title={t`verify_your_identity`}
      content={
        <>
          <p className='mb-5'>{t`please_upload_a_photo_of_your_NIC_card`}</p>
          <div className='form'>
            <UploadPhotoComponent
              setValue={setValue}
              control={control}
              loadedFileType={loadedIDFront}
              changeTypeFile={changeTypeFile}
              sendFile={sendFile}
              document={DOCUMENTS.FRONT}
              withoutFAQ
              sendLog={sendLog}
            />
          </div>
        </>
      }
    />
  );
};
