import { useTranslation } from 'react-i18next';
import { CloseIcon } from 'core/components/common';
import { InfoIcon, WhatsappIcon } from 'components';

interface ContactCollectionTeamProps {
  title: string;
  link: string;
  isSendFile?: boolean;
  onOpen: () => void;
  handleCloseAlert: () => void;
}

export const ContactCollectionTeam = ({
  title,
  link,
  isSendFile,
  onOpen,
  handleCloseAlert,
}: ContactCollectionTeamProps) => {
  const { t } = useTranslation();

  const onClose = () => handleCloseAlert();

  return (
    <div className='alerts-item__info flex-column'>
      <div className='alerts-item__info__message w-100'>
        <div className='alerts-item__info__icon__wrapper mr-2'>
          <InfoIcon />
        </div>
        <div className='flex-column w-100 gap-2'>
          <div className='txt-sb w-100'>{title}</div>
          <div className='flex flex-tbl-no-wrap gap-2 w-100'>
            {isSendFile && (
              <a onClick={onOpen} className='pill-primary w-100 h-8'>
                <span className='txt-md-14 txt-sb'>{t`send_photo_of_receipt`}</span>
              </a>
            )}

            <a href={link} target='_blank' rel='noreferrer' className='pill-primary w-100'>
              <div className='align-items-center'>
                <WhatsappIcon />
              </div>
              <div className='txt-sb'>
                {isSendFile ? t`send_photo_of_receipt_whatsapp` : 'Whatsapp'}
              </div>
            </a>
          </div>
        </div>

        <div className='alerts-item__info__icon__wrapper close ml-2' onClick={onClose}>
          <CloseIcon />
        </div>
      </div>
    </div>
  );
};
