export const googleChromeInstruction = [
  'go_to_browser_settings',
  'find_site_settings_section',
  'find_section_with_camera_settings',
];

export const safariInstruction = [
  'go_to_mobile_settings',
  'find_the_safari',
  'find_the_camera_section',
  'choose_allow_in_camera_access',
];
