import { createAsyncThunk } from '@reduxjs/toolkit';

import { $axios, getAccessToken, getCookie, getUserID } from 'core/services';
import { WpKeys } from 'core/models';

import { AccountBankDetailsModel, Actions, Application, NewOfferModel } from 'models';
import { get_ga_ClientId, get_ga_SessionId } from 'utils/analitics';
import { RootState } from 'store';
import { getAdminId } from 'utils';

interface Agreements {
  id: number;
  state: string;
  template: string;
  locale: string;
  created_at: string;
  updated_at: string;
  user_id: number;
  application_id: number;
  loan_id: number;
  enabled_transitions: string[];
}

export const getAgreements = createAsyncThunk<Array<Agreements>, { idApp: number; param?: string }>(
  Actions.GET_AGREEMENTS,
  async ({ idApp, param }, { rejectWithValue, dispatch }) => {
    const config = {
      method: 'GET',
      url: `/applications/${idApp}/agreements${param ? '?' + param : ''}`,
    };

    try {
      return await $axios(config, dispatch);
    } catch (errors) {
      return rejectWithValue(errors);
    }
  },
);

export const getAgreeDocument = createAsyncThunk<Blob, number>(
  Actions.GET_AGREE_DOCUMENTS,
  async (IdAgree, { rejectWithValue, dispatch }) => {
    const config = {
      method: 'GET',
      url: `/agreements/${IdAgree}/download`,
      responseType: 'blob',
    };

    try {
      return await $axios(config, dispatch);
    } catch (errors) {
      return rejectWithValue(errors);
    }
  },
);

export const resendCodeAgree = createAsyncThunk<any, number>(
  Actions.RESEND_AGREE_CODE,
  async (appId, { rejectWithValue, dispatch }) => {
    const config = {
      method: 'GET',
      url: `/applications/${appId}/confirmation_code`,
    };

    try {
      return await $axios(config, dispatch);
    } catch (errors) {
      return rejectWithValue(errors);
    }
  },
);

export const sendConfirmCodeAgree = createAsyncThunk<null, { data: any; appId: number }>(
  Actions.CONFIRM_AGREE_CODE,
  async ({ data, appId }, { rejectWithValue, dispatch }) => {
    const config = {
      method: 'POST',
      url: `/applications/${appId}/confirmation_code`,
      data,
    };

    try {
      return await $axios(config, dispatch);
    } catch (errors) {
      return rejectWithValue(errors);
    }
  },
);

export const createApplication = createAsyncThunk<
  Application,
  {
    amount: number;
    term: number;
    promoCode?: string | null;
    productId: number | null;
    bankDetails?: AccountBankDetailsModel;
    referralAmount?: number | null;
  },
  { state: RootState }
>(Actions.CREATE_APPLICATION, async (data, { rejectWithValue, dispatch }) => {
  const adminId = getAdminId(getAccessToken());
  const target_url = adminId ? null : getCookie(WpKeys.TargetUrl);

  let sendData: { [key: string]: any } = {
    principal: Number(data.amount),
    tenor: Number(data.term),
    product_id: data.productId,
    google_session_id: get_ga_SessionId(),
    google_client_id: get_ga_ClientId(),
    channel: 'client_web',
    user_additional_data: {
      evercookie: getUserID(),
      // evercookie: EC.getItem(false, 'userID') || null,
    },
  };

  if (data.bankDetails && !adminId) {
    sendData = { ...sendData, ...data.bankDetails };
  }

  if (data.referralAmount) {
    sendData.referral_discount_amount = data.referralAmount;
  } else if (data.promoCode) {
    sendData.promo_code = data.promoCode;
  }

  if (target_url) {
    sendData.target_url = target_url;
  }

  const config = {
    method: 'POST',
    url: `/users/${getUserID()}/application`,
    data: sendData,
  };

  try {
    return await $axios(config, dispatch);
  } catch (errors) {
    return rejectWithValue(errors);
  }
});

export const getUserApplications = createAsyncThunk<Application[]>(
  Actions.GET_USER_APPLICATIONS,
  async (_, { rejectWithValue, dispatch }) => {
    const config = {
      method: 'GET',
      url: `/users/${getUserID()}/applications`,
    };

    try {
      return await $axios(config, dispatch);
    } catch (errors) {
      return rejectWithValue(errors);
    }
  },
);

export const changeApplicationPrincipal = createAsyncThunk<
  any,
  { appId: number; selectPrincipal: NewOfferModel }
>(Actions.CHANGE_APPLICATION_PRINCIPAL, async (data, { rejectWithValue, dispatch }) => {
  const config = {
    method: 'POST',
    url: `/applications/${data.appId}/select_principal`,
    data: data.selectPrincipal,
  };

  try {
    return await $axios(config, dispatch);
  } catch (errors) {
    return rejectWithValue(errors);
  }
});

export const getApplicationTransition = createAsyncThunk<any, number>(
  Actions.GET_APPLICATION_TRANSITION,
  async (appId, { rejectWithValue, dispatch }) => {
    const config = {
      method: 'GET',
      url: `/applications/${appId}/transitions`,
    };

    try {
      return await $axios(config, dispatch);
    } catch (errors) {
      return rejectWithValue(errors);
    }
  },
);

export const applyPromoCode = createAsyncThunk<any, { appId: number; promoCode: string }>(
  Actions.APPLY_PROMO_CODE,
  async (data, { rejectWithValue, dispatch }) => {
    const config = {
      method: 'POST',
      url: `/applications/${data.appId}/apply_promo_code`,
      data: { promo_code: data.promoCode },
    };

    try {
      return await $axios(config, dispatch);
    } catch (errors) {
      return rejectWithValue(errors);
    }
  },
);

export const sendCreditBureauReportData = createAsyncThunk<
  any,
  { report_link: string; report_password: string }
>(Actions.SEND_CREDIT_BUREAU_REPORT_DATA, async (data, { rejectWithValue, dispatch }) => {
  const config = {
    method: 'POST',
    url: '/credit_bureau_reports',
    data: {
      report_link: data.report_link,
      report_password: data.report_password,
      user: getUserID(),
    },
  };

  try {
    return await $axios(config, dispatch);
  } catch (errors) {
    return rejectWithValue(errors);
  }
});
