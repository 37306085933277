import { useState } from 'react';

import { SubmitCoreHandler } from 'core/models';
import { useTypedSelector } from 'core/hooks';
import { setRedirect } from 'core/store/actions';
import { removeCookie } from 'core/services';

import { useAppDispatch } from 'hooks';
import { Step2ConfirmPhoneWithSMS, Step2ConfirmPhoneWithSMSForm } from './verificationWay';
import { sendConfirmationPhoneCode, updateUser } from 'store/actions/api';
import { PhoneConfirmFormFields } from 'models';
import { setAuthorized } from 'store/actions';
import {
  authPhoneSelector,
  isDataConsentGivenSelector,
  wpContentFooterSelector,
  usernamePhoneSelector,
} from 'store/selectors';
import { HtmlDocumentModal } from 'components/modals';
import { PhoneSection, Title } from 'components';
import { removeAllTimerCookies } from 'utils';
import { TermsConditionsFormContainer } from './TermsConditionsForm';
import { getRegStepPath, KEY_STORAGE_CALL_IS_PROCESSING } from 'constantsLk';

export enum Confirmation {
  SMS_CONFIRM,
  PHONE_CONFIRM,
}

export const Step2ConfirmPhoneContainer = () => {
  const dispatch = useAppDispatch();

  const usernamePhone = useTypedSelector(usernamePhoneSelector);
  const authPhone = useTypedSelector(authPhoneSelector);
  const siteContent = useTypedSelector(wpContentFooterSelector);
  const isDataConsentGiven = useTypedSelector(isDataConsentGivenSelector);

  const phone = authPhone || usernamePhone;

  const [modal, setModal] = useState<any>({ content: { content: null, title: '' }, isOpen: false });

  const onOpenModalHandler = (e: MouseEvent) => {
    e.stopPropagation();
    e.preventDefault();
    setModal({
      isOpen: true,
      content: {
        content: siteContent?.modal_info && siteContent?.modal_info[1]?.content,
        title: siteContent?.modal_info && siteContent?.modal_info[1]?.title,
      },
    });
  };

  const onCloseModalHandler = () => {
    setModal({ isOpen: false, content: { content: null, title: '' } });
  };

  const handlePhoneReset = () => {
    dispatch(setAuthorized(false));
    dispatch(
      setRedirect({
        to: getRegStepPath(1),
        replace: true,
      }),
    );
    removeCookie(KEY_STORAGE_CALL_IS_PROCESSING);
  };

  const onSubmit: SubmitCoreHandler<Step2ConfirmPhoneWithSMSForm> = async (
    values,
    { setError },
  ) => {
    const dataUser = {
      is_data_processing_consent_given: values[PhoneConfirmFormFields.AgreementTermsConditions],
    };

    const dataCode = { confirmation_code: values[PhoneConfirmFormFields.Code] };

    return dispatch(sendConfirmationPhoneCode(dataCode))
      .unwrap()
      .then(() => {
        return dispatch(updateUser(dataUser))
          .unwrap()
          .then((res) => {
            dispatch(
              setRedirect({
                to: getRegStepPath(res.registration_step),
                replace: true,
              }),
            );
            removeCookie(KEY_STORAGE_CALL_IS_PROCESSING);
            removeAllTimerCookies();
          });
      })
      .catch((e) => {
        setError &&
          setError(PhoneConfirmFormFields.Code, {
            type: 'validate',
            message: e?.message,
          });
      });
  };

  return (
    <div className='confirm-phone'>
      <Title classes='mb-2 phone-mt-3' name={'phone_verification'} />
      {isDataConsentGiven ? (
        <TermsConditionsFormContainer onOpenModalHandler={onOpenModalHandler} />
      ) : (
        <>
          <PhoneSection phone={phone} onPhoneReset={handlePhoneReset} />

          <Step2ConfirmPhoneWithSMS onSubmit={onSubmit} onOpenModalHandler={onOpenModalHandler} />
        </>
      )}

      {modal.isOpen && (
        <HtmlDocumentModal
          onClose={onCloseModalHandler}
          title={modal.content.title}
          htmlContent={modal.content.content}
        />
      )}
    </div>
  );
};
