import { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { SubmitCoreHandler } from 'core/models';
import { useAppDispatch } from 'hooks';
import { setRedirect } from 'core/store/actions';
import { sendOTPassword } from 'store/actions/api';
import { createUserAction } from 'store/actions';
import { setShowPartnerLogin } from 'store/slices';
import { Step1 } from './Step1';
import { AuthFormFields, AuthFormModel } from 'components';
import * as Paths from 'constantsLk/paths';
import { ERRORS, getRegStepPath } from 'constantsLk';

export const Step1Container = () => {
  const dispatch = useAppDispatch();
  const location = useLocation();

  const [oldUserPhone, setOldUserPhone] = useState(null);

  const resetOldUserPhone = () => setOldUserPhone(null);

  const onSubmit: SubmitCoreHandler<AuthFormModel> = async (values, { setError }) => {
    const phone = values[AuthFormFields.Phone];
    const redirect = (path: string, isOpenModal: boolean) =>
      dispatch(
        setRedirect({
          to: path,
          replace: true,
          state: { phone, isOpenModal },
        }),
      );

    if (!location.state?.isOpenModal) {
      return redirect(getRegStepPath(1), true);
    }

    try {
      await dispatch(sendOTPassword({ [AuthFormFields.Phone]: phone })).unwrap();
      return redirect(`/${Paths.URL_PATH_LOGIN}`, false);
    } catch (e: any) {
      switch (e.status) {
        case 403:
          return dispatch(setShowPartnerLogin(true));
        case 451:
          return redirect(getRegStepPath(2), true);
        case 404:
          await dispatch(createUserAction({ username: phone, user_additional_data: {} })).unwrap();
          return redirect(getRegStepPath(2), true);
        default:
          if (e.message === ERRORS.USER.ARCHIVE) {
            setOldUserPhone(e.phone_number);
            return redirect(getRegStepPath(1), false);
          }
          setError?.('root.serverError', { type: '400', message: e?.message });
      }
    }
  };

  return (
    <Step1 onSubmit={onSubmit} oldUserPhone={oldUserPhone} resetOldUserPhone={resetOldUserPhone} />
  );
};
