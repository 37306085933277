import { createAsyncThunk } from '@reduxjs/toolkit';

import { $axios } from 'core/services';
import { Actions, IPrice } from 'models';

interface IParamPrices {
  productId?: number | null;
  promoCode?: string;
  userId?: string;
  isFirstLoan?: boolean;
}

export const getProductId = createAsyncThunk<any>(
  Actions.GET_PRODUCT_ID,
  async (_, { rejectWithValue, dispatch }) => {
    const config = {
      method: 'GET',
      url: '/products/available',
    };

    try {
      return await $axios<IPrice>(config, dispatch);
    } catch (errors) {
      // eslint-disable-next-line no-console
      console.log('errors', errors);
      return rejectWithValue(errors);
    }
  },
);

export const getProduct = createAsyncThunk<IPrice, IParamPrices | void>(
  Actions.GET_PRODUCT,
  async (
    { productId, promoCode, userId, isFirstLoan = true } = {},
    { rejectWithValue, dispatch },
  ) => {
    const baseUrl = `/products/${productId}/price_list`;
    const id = userId ? `user_id=${userId}` : '';
    const endpoint = promoCode ? `${baseUrl}?promo_code=${promoCode}&${id}` : `${baseUrl}?${id}`;

    const config = {
      method: 'GET',
      url: endpoint,
    };

    try {
      const price = await $axios<IPrice>(config, dispatch);

      if (!isFirstLoan) {
        const paramsToRemove = [5000, 6000, 7000, 8000, 9000];

        Object.keys(price).forEach((key) => {
          paramsToRemove.forEach((param) => {
            delete price[key][param];
          });
        });
      }

      return price;
    } catch (errors) {
      // eslint-disable-next-line no-console
      console.log('errors', errors);
      return rejectWithValue(errors);
    }
  },
);
