import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { setInitStateForm } from 'core/services';
import { useTypedSelector } from 'core/hooks';
import { maxLength } from 'core/utils/parsers';
import { Button, ModalLayout } from 'core/components/common';
import { SelectFieldHookForm, TextFieldHookForm } from 'core/components/fields';
import { AccountBankDetailsModel, IbanNumberFields } from 'models';
import { sortOptions } from 'utils';
import { validateAccountNumber, validateSelect } from 'utils/validates/validation';
import { onlyNumbers } from 'utils/parsers';
import { useAppDispatch } from 'hooks';
import { userBankDetailsSelector } from 'store/selectors';
import { setBankDetails } from 'store/slices';
import {
  KEY_BANK_ACCOUNT_DETAILS,
  SELECT_LIST_BANK_BRANCHES,
  SELECT_LIST_BANK_NAME,
} from 'constantsLk';

interface UpdateAccountNumberModalProps {
  onClose: () => void;
}

export const UpdateAccountNumberModal = (props: UpdateAccountNumberModalProps) => {
  const { onClose } = props;
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const userBankDetails = useTypedSelector(userBankDetailsSelector);

  const {
    handleSubmit,
    setValue,
    control,
    watch,
    formState: { isSubmitting },
  } = useForm<AccountBankDetailsModel>({
    mode: 'onBlur',
    defaultValues: userBankDetails,
    shouldUnregister: true,
  });

  const handleSelectChange = () => {
    setValue(IbanNumberFields.BankBranchName, null);
  };

  const bank = watch(IbanNumberFields.BankName);

  const onSubmitHandler = handleSubmit((data) => {
    setInitStateForm(KEY_BANK_ACCOUNT_DETAILS, data);
    dispatch(setBankDetails(data));
    onClose();
  });

  return (
    <ModalLayout
      onClose={onClose}
      isOpenModal
      isShowClose
      isUsePortal
      title={t`bank_account_details`}
      classes={{ wrapper: 'modal-core__wrapper--bank-details', title: 'flex-grow-1' }}
      content={
        <>
          <div className='mb-5'>
            <p className='txt-r'>{t`bank_account_details_modal_subtitle`}</p>
          </div>
          <form onSubmit={onSubmitHandler} className='flex-column gap-5'>
            <SelectFieldHookForm
              control={control}
              label={t`bank_name`}
              name={IbanNumberFields.BankName}
              options={sortOptions(SELECT_LIST_BANK_NAME)}
              rules={validateSelect}
              onChange={handleSelectChange}
              maxMenuHeight={250}
            />

            {bank && SELECT_LIST_BANK_BRANCHES[bank] && (
              <SelectFieldHookForm
                control={control}
                label={t`bank_branch_name`}
                name={IbanNumberFields.BankBranchName}
                options={SELECT_LIST_BANK_BRANCHES[bank]}
                rules={validateSelect}
                maxMenuHeight={200}
              />
            )}

            <TextFieldHookForm
              control={control}
              name={IbanNumberFields.Iban}
              label={t`account_number`}
              parsers={[onlyNumbers, maxLength(20)]}
              rules={validateAccountNumber}
              inputMode='numeric'
            />

            <Button disabled={isSubmitting} classes={{ root: 'btn btn--primary' }}>
              {t`continue`}
            </Button>
          </form>
        </>
      }
    />
  );
};
