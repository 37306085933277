import { useTranslation } from 'react-i18next';
import { User } from 'models';

interface AccountCardProps {
  user: User | null;
}

export const AccountCards = (props: Partial<AccountCardProps>) => {
  const { user } = props;
  const { t } = useTranslation();

  return (
    <div className='bank-account card flex-grow-0 flex-column mw-35 w-100 gap-2 mb-8'>
      <div className='txt-b txt-md-16 txt-uppercase'>{user?.bank_name}</div>
      <div className='txt-primary txt-md txt-sb txt-uppercase'>{user?.bank_account_number}</div>
      <div className='justify-content-between align-items-center mt-2'>
        <p className='txt-sb txt-xs'>{t`bank_branch_name`}</p>
        <div className='info-item'>{user?.bank_branch_name}</div>
      </div>
    </div>
  );
};
