import React, { FC, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { UseFormSetError } from 'react-hook-form';
import { setRedirect } from 'core/store/actions';
import { getCookie, removeCookie, setCookie } from 'core/services';
import { useAppDispatch } from 'hooks';
import { ITimerValue } from 'models/common';
import {
  callConfirmUser,
  getStateCallConfirmUser,
  getUserInfo,
  resendPhoneCode,
} from 'store/actions/api';
import { TimerButtonItem } from 'components';
import { PhoneConfirmFormFields } from 'models';
import { removeRegCallTimer } from 'utils';
import './styles.scss';
import {
  getRegStepPath,
  KEY_STORAGE_CALL_IS_PROCESSING,
  KEY_STORAGE_CONFIRM_WITH_CALL,
} from 'constantsLk';

interface RepeatCallRegContainerProps {
  isShowTimer: boolean;
  showTimer: () => any;
  timerValue: ITimerValue;
  hintText?: string;
  setConfirmWithSMS: () => void;
  hideTimer: () => void;
  setIsShowSpinner: (val: boolean) => void;
  setError: UseFormSetError<any>;
}

export const RepeatCallRegContainer: FC<RepeatCallRegContainerProps> = ({
  timerValue,
  isShowTimer,
  showTimer,
  hideTimer,
  setConfirmWithSMS,
  setIsShowSpinner,
  setError,
}) => {
  const { t, i18n } = useTranslation();
  const lang = i18n.language;
  const dispatch = useAppDispatch();

  const isCallProcessing = getCookie(KEY_STORAGE_CALL_IS_PROCESSING);

  const [codeState, setCodeState] = useState({ isShowHint: false, isDisabledResend: false });
  const [isError, setIsError] = useState(false);

  const errorMessage = useMemo(() => {
    if (isError) {
      return t`confirm_with_call_error`;
    }
  }, [isError, lang]);

  const setCallError = () => {
    setIsError(true);
    setIsShowSpinner(false);
    hideTimer();
  };

  useEffect(() => {
    setCookie(KEY_STORAGE_CONFIRM_WITH_CALL, '1');

    if (!isCallProcessing) {
      dispatch(callConfirmUser())
        .unwrap()
        .then(() => setCookie(KEY_STORAGE_CALL_IS_PROCESSING, '1'))
        .catch((e) => {
          if (e.detail === 'Previous call is still being processed') {
            setCookie(KEY_STORAGE_CALL_IS_PROCESSING, '1');
          } else {
            setCallError();
          }
        });
    }

    return () => removeCookie(KEY_STORAGE_CONFIRM_WITH_CALL);
  }, []);

  useEffect(() => {
    if (!isShowTimer) {
      removeRegCallTimer();
    }
  }, [isShowTimer]);

  useEffect(() => {
    let errorCount = 1;

    const getConfirmCallData = () => {
      dispatch(getStateCallConfirmUser())
        .unwrap()
        .then((res) => {
          if (res.can_create_another) {
            removeCookie(KEY_STORAGE_CALL_IS_PROCESSING);
          }

          if (res.state === 'success') {
            return dispatch(getUserInfo())
              .unwrap()
              .then((res) => {
                dispatch(setRedirect({ to: getRegStepPath(res.registration_step) }));
                removeCookie(KEY_STORAGE_CALL_IS_PROCESSING);
              });
          }

          if (res.state === 'error') {
            if (errorCount !== 3) {
              errorCount += 1;
            } else {
              setCallError();
            }
          }
        })
        .catch(() => {
          setCallError();
        });
    };

    const interval = setInterval(() => {
      getConfirmCallData();
      if (errorCount >= 3) {
        clearInterval(interval);
      }
    }, 5000);

    return () => clearInterval(interval);
  }, []);

  const startTimerHandler = async (e?: React.MouseEvent<HTMLButtonElement>) => {
    e?.stopPropagation();
    await showTimer();
    setCodeState({ isShowHint: true, isDisabledResend: false });
  };

  const confirmWithSMSHandler = () => {
    setConfirmWithSMS();
    startTimerHandler().then();
    dispatch(resendPhoneCode())
      .unwrap()
      .then(() => {})
      .catch((e) => {
        setError(PhoneConfirmFormFields.Code, {
          type: 'validate',
          message: e?.message,
        });
      })
      .finally(() => {});
    removeCookie(KEY_STORAGE_CONFIRM_WITH_CALL);
    removeCookie(KEY_STORAGE_CALL_IS_PROCESSING);
  };

  return (
    <>
      {isError && <p className={'error-text mt-1'}>{errorMessage}</p>}

      <div className='flex align-items-center gap-2 w-100'>
        {!isShowTimer && (
          <button className='btn ml-0 mr-0 mt-3' onClick={confirmWithSMSHandler} type='button'>
            <span className='txt txt-sb txt-white'>{t('resend_code')}</span>
          </button>
        )}

        {!isError && (
          <TimerButtonItem
            isDisabledResend={codeState.isDisabledResend}
            isShowTimer={isShowTimer}
            startTimerHandler={startTimerHandler}
            secondString={timerValue.secondString}
            minutesString={timerValue.minutesString}
            buttonText={'repeat_call_again'}
          />
        )}

        {/*{codeState.isShowHint && <ConnectSupportHint text={t(hintText)} />}*/}
      </div>
    </>
  );
};
