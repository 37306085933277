import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';

import { SubmitCoreHandler } from 'core/models';
import { Button } from 'core/components/common';

import { IPrice, LoanCalculatorFields, LoanCalculatorForm, ServerLoanSettings } from 'models';
import { getLoanSettings } from 'utils';
import { Calculator, ErrorMessageCommon, LoanConditionsValues, Spinner, Title } from 'components';
import { AccountLoanCalculatorPromoCodeContainer } from '../../account/AccountLoan/LoanCalculator/PromoCode';

export interface Step9ConfirmationConditions {
  price: IPrice;
  onSubmit: SubmitCoreHandler<LoanCalculatorForm>;
  defaultPrices: ServerLoanSettings;
}

export const Step9ConfirmationConditions = (props: Step9ConfirmationConditions) => {
  const { onSubmit, price, defaultPrices } = props;
  const { t } = useTranslation();

  const loanSettings = useMemo(() => {
    return getLoanSettings(price, defaultPrices);
  }, [price, defaultPrices]);

  const {
    handleSubmit,
    watch,
    setValue,
    getValues,
    setError,
    clearErrors,
    formState: { isSubmitting, errors },
  } = useForm<LoanCalculatorForm>({
    mode: 'onSubmit',
    defaultValues: {
      term: loanSettings.term,
      amount: loanSettings.amount,
    },
  });

  const formTerm = watch(LoanCalculatorFields.Term);
  const formAmount = watch(LoanCalculatorFields.Amount);

  const returnAmount = useMemo(() => {
    const currentTerm = price?.[formTerm];
    const currentAmount = currentTerm?.[formAmount];
    return (
      currentAmount && currentAmount.settlement_amount - currentAmount.discount.settlement_amount
    );
  }, [formTerm, formAmount, price]);

  const onChangeSlider = (name: any, value: number) => {
    value && setValue(name, value);
  };

  const onSubmitHandler = handleSubmit((data) =>
    onSubmit?.(data, {
      setError,
    }),
  );

  return (
    <>
      <div className='flex-column gap-2 mb-4 tablet-mb-5'>
        <Title classes={'form-title mb-0'} name={'confirmation_conditions'} />
      </div>
      {price ? (
        <>
          <Calculator
            price={price}
            settings={loanSettings}
            conditions={getValues()}
            onChangeSlider={onChangeSlider}
          />

          <div className='flex-column gap-3 loan-conditions-wrapper'>
            <LoanConditionsValues loanSettings={getValues()} returnAmount={returnAmount} />
          </div>

          <form onSubmit={onSubmitHandler} className='gap-0'>
            <AccountLoanCalculatorPromoCodeContainer clearErrors={clearErrors} />

            {errors?.root?.commonError && <ErrorMessageCommon error={errors?.root?.commonError} />}

            <Button
              disabled={isSubmitting}
              classes={{ root: 'btn--primary mt-3 tablet-mt-6 complete-button' }}
            >{t`continue`}</Button>
          </form>
        </>
      ) : (
        <Spinner />
      )}
    </>
  );
};
