import { getCookie, setCookie } from 'core/services';

const prefix = '__dev';
export const SMS_CODE = `${prefix}SMS_CODE`;
export const DEVELOP_INFO = `${prefix}DEVELOP_INFO`;
export const DEVELOP_DISABLE_STEPPER = `${prefix}DEVELOP_DISABLE_STEPPER`;
export const getDevelopmentInfo = () => getCookie(DEVELOP_INFO);
export const setDevelopmentInfo = (val: string) => setCookie(DEVELOP_INFO, val);

export const setStepperDisable = (val: string) => setCookie(DEVELOP_DISABLE_STEPPER, val);
export const getStepperDisable = () => getCookie(DEVELOP_DISABLE_STEPPER);