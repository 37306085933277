import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { SubmitCoreHandler } from 'core/models';
import { useTimer } from 'core/hooks';
import { sendOTPassword } from 'store/actions/api';
import { PhoneConfirmFormFields } from 'models';
import { useAppDispatch } from 'hooks';
import { Confirmation, Step2ConfirmPhoneWithSMSForm } from 'pages/Registration';
import { OtpInput } from 'components/Otp';
import { TimerButtonItem } from '../TimerButtonItem';

interface CodeConfirmFormProps {
  onSubmit: SubmitCoreHandler<Step2ConfirmPhoneWithSMSForm>;
  phone: string;
}

export const CodeConfirmForm = (props: CodeConfirmFormProps) => {
  const { onSubmit, phone } = props;
  const dispatch = useAppDispatch();
  const [otp, setOtp] = useState<string | null>(null);

  const {
    handleSubmit,
    setError,
    setValue,
    clearErrors,
    formState: { errors },
  } = useForm<Step2ConfirmPhoneWithSMSForm>({
    mode: 'onSubmit',
    shouldUnregister: true,
    shouldFocusError: true,
  });

  const { timerValue, isShowTimer, showTimer } = useTimer({
    timerName: 'LoginConfirmPhoneWithSMS',
    isUseUnmount: false,
  });

  const startTimerHandler = async (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    await showTimer();
    const data = {
      phone_number: phone.replace(/ +/g, ''),
    };
    dispatch(sendOTPassword(data))
      .unwrap()
      .then(() => {})
      .catch((e) => {
        setError(PhoneConfirmFormFields.Code, {
          type: 'validate',
          message: e?.message,
        });
      })
      .finally(() => {});
  };

  const onSubmitHandler = handleSubmit((data) =>
    onSubmit?.(data, {
      setError,
    }),
  );

  useEffect(() => {
    otp && setValue(PhoneConfirmFormFields.Code, otp);
  }, [otp]);

  useEffect(() => {
    if ('OTPCredential' in window) {
      const ac = new AbortController();
      navigator.credentials
        .get({
          otp: { transport: ['sms'] },
          signal: ac.signal,
        })
        .then((otp) => {
          if (otp) {
            setValue(PhoneConfirmFormFields.Code, otp.code);
            setOtp(otp.code);
            ac.abort();
          }
        })
        .catch(() => ac.abort());
    }
  }, []);

  return (
    <form className='align-items-start flex-column gap-0' onSubmit={onSubmitHandler}>
      <OtpInput
        setValue={setValue}
        onSubmitHandler={onSubmitHandler}
        errors={errors}
        clearErrors={clearErrors}
        otp={otp}
        confirmation={Confirmation.SMS_CONFIRM}
      />

      <TimerButtonItem
        isDisabledResend={false}
        isShowTimer={isShowTimer}
        startTimerHandler={startTimerHandler}
        secondString={timerValue.secondString}
        minutesString={timerValue.minutesString}
        buttonText={'resend_code'}
        isCodeConfirmation
      />
    </form>
  );
};
