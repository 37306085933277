import { createAsyncThunk } from '@reduxjs/toolkit';
import { WpKeys } from 'core/models';
import { $axios, AxiosRequestConfigExtended, getCookie, getUserID } from 'core/services';
import { CreateUser } from 'core/models/createUser';
import {
  CreateUserValues,
  PhoneConfirmFormFields,
  AuthResp,
  User,
  Actions,
  Loan,
  AuthRespSlug,
  PaymentBankModel,
} from 'models';
import { getFingerprint } from 'utils/getFingerprint';

export const createUser = createAsyncThunk<CreateUser, CreateUserValues>(
  Actions.CREATE_USER,
  async (data, { rejectWithValue, dispatch }) => {
    // const EC = new EverCookie('EverCookie');
    data = {
      ...data,
      user_additional_data: {
        ...data.user_additional_data,
        fingerprint_js: getFingerprint() || {},
        evercookie: getUserID() || null,
        juicy_score_session_id: window?.jslabApi?.getSession
          ? window?.jslabApi?.getSession() || ''
          : '',
      },
    };

    const target_url = getCookie(WpKeys.TargetUrl) || null;

    if (target_url) {
      const urlObj = new URL(target_url);
      const refValue = urlObj.searchParams.get('ref');

      data = {
        ...data,
        target_url,
        ...(refValue ? { referrer: Number(refValue) } : {}),
      };
    }

    const config = {
      method: 'POST',
      url: '/users',
      data,
    };

    try {
      return await $axios(config, dispatch);
    } catch (errors) {
      return rejectWithValue(errors);
    }
  },
);

export const getUserInfo = createAsyncThunk<User>(
  Actions.GET_USER_INFO,
  async (_, { rejectWithValue, dispatch }) => {
    const config = {
      method: 'GET',
      url: `/users/${getUserID()}`,
    };

    try {
      return await $axios(config, dispatch);
    } catch (errors) {
      return rejectWithValue(errors);
    }
  },
);

export const updateUser = createAsyncThunk<User, any>(
  Actions.UPDATE_USER,
  async (data, { rejectWithValue, dispatch }) => {
    const config = {
      method: 'PATCH',
      url: `/users/${getUserID()}`,
      headers: {
        'Content-Type': 'application/merge-patch+json',
      },
      data,
    };

    try {
      return await $axios(config, dispatch);
    } catch (errors) {
      return rejectWithValue(errors);
    }
  },
);

export const sendConfirmationPhoneCode = createAsyncThunk<
  string,
  { [PhoneConfirmFormFields.Code]: string }
>(Actions.SEND_CONFIRMATION_CODE, async (data, { rejectWithValue, dispatch }) => {
  const config = {
    method: 'POST',
    url: `/users/${getUserID()}/confirmation_code`,
    data,
  };

  try {
    return await $axios(config, dispatch);
  } catch (errors) {
    return rejectWithValue(errors);
  }
});

export const resendPhoneCode = createAsyncThunk<string>(
  Actions.RESEND_CONFIRMATION_CODE,
  async (_, { rejectWithValue, dispatch }) => {
    const config = {
      method: 'GET',
      url: `/users/${getUserID()}/confirmation_code`,
    };

    try {
      return await $axios(config, dispatch);
    } catch (errors) {
      return rejectWithValue(errors);
    }
  },
);

export const callConfirmUser = createAsyncThunk<any>(
  Actions.CALL_CONFIRM,
  async (_, { rejectWithValue, dispatch }) => {
    const config = {
      method: 'POST',
      url: `/users/${getUserID()}/init_phone_call_confirmation`,
      data: {},
    };

    try {
      return await $axios(config, dispatch);
    } catch (errors) {
      return rejectWithValue(errors);
    }
  },
);

export const getStateCallConfirmUser = createAsyncThunk<any>(
  Actions.GET_STATE_CALL_CONFIRM,
  async (_, { rejectWithValue, dispatch }) => {
    const config = {
      method: 'GET',
      url: `/users/${getUserID()}/phone_call_confirmation_state`,
    };

    try {
      return await $axios(config, dispatch);
    } catch (errors) {
      return rejectWithValue(errors);
    }
  },
);

export const postUserResolveMatch = createAsyncThunk<
  AuthResp,
  { files?: Blob; type?: string } | void
>(Actions.POST_USER_RESOLVE_MATCH, async (data, { rejectWithValue, dispatch }) => {
  const config: AxiosRequestConfigExtended = {
    method: 'POST',
    url: `/users/${getUserID()}/resolve_match`,
    headers: {
      'Content-Type': 'image/png',
    },
  };
  if (data?.files) config.data = data?.files;

  try {
    return await $axios(config, dispatch);
  } catch (errors) {
    return rejectWithValue(errors);
  }
});

export const getDataBySlug = createAsyncThunk<
  Loan | AuthRespSlug,
  { slug: string; params: string }
>(Actions.GET_DATA_BY_SLUG, async ({ slug, params }, { rejectWithValue, dispatch }) => {
  const config = {
    method: 'GET',
    url: `/functional_link/${slug}/data${params}`,
  };

  try {
    return await $axios(config, dispatch);
  } catch (errors) {
    return rejectWithValue(errors);
  }
});

export const postFacebookRegistration = createAsyncThunk<
  any,
  { code: string; pathRedirect: string }
>(
  Actions.POST_FACEBOOK_REGISTRATION,
  async ({ code, pathRedirect }, { rejectWithValue, dispatch }) => {
    const config = {
      method: 'POST',
      url: `/users/${getUserID()}/facebook_registration`,
      data: {
        code,
        redirect_uri: pathRedirect,
      },
    };

    try {
      return await $axios(config, dispatch);
    } catch (errors) {
      return rejectWithValue(errors);
    }
  },
);

export const getTokenHyperSnap = createAsyncThunk<{
  token: string;
}>(Actions.GENERATE_TOKEN_HYPER_SNAP, async (_, { rejectWithValue, dispatch }) => {
  const config = {
    method: 'POST',
    url: '/hyperverge/generate_jwt_token',
  };

  try {
    return await $axios(config, dispatch);
  } catch (errors) {
    return rejectWithValue(errors);
  }
});

export const sendLivenessLog = createAsyncThunk<
  { id: number },
  { user: number; context: string; engine: string }
>(Actions.SEND_LIVENESS_LOG, async (data, { rejectWithValue, dispatch }) => {
  const config = {
    method: 'POST',
    url: '/liveness_checks',
    data,
  };

  try {
    return await $axios(config, dispatch);
  } catch (errors) {
    return rejectWithValue(errors);
  }
});

export const sendSelfieFileId = createAsyncThunk<
  any,
  { livenessCheckId: number; data: { file_id: number } }
>(Actions.SEND_SELFIE_FILE_ID, async ({ livenessCheckId, data }, { rejectWithValue, dispatch }) => {
  const config = {
    method: 'POST',
    url: `/liveness_checks/${livenessCheckId}/link_to_file`,
    data,
  };

  try {
    return await $axios(config, dispatch);
  } catch (errors) {
    return rejectWithValue(errors);
  }
});

export const getLivenessDetection = createAsyncThunk<any>(
  Actions.GET_LIVENESS_DETECTION,
  async (_, { rejectWithValue, dispatch }) => {
    const config = {
      method: 'GET',
      url: '/global_settings/default_liveness_detection_config/detail',
    };

    try {
      return await $axios(config, dispatch);
    } catch (errors) {
      return rejectWithValue(errors);
    }
  },
);

export const sendLivenessImage = createAsyncThunk<
  {
    state: boolean;
    data: string;
  },
  { file: File }
>(Actions.SEND_LIVENESS_IMAGE, async ({ file }, { rejectWithValue, dispatch }) => {
  const config = {
    method: 'POST',
    url: `/users/${getUserID()}/liveness_detection`,
    data: file,
    headers: {
      'Content-Type': 'image/png',
    },
  };

  try {
    return await $axios(config, dispatch);
  } catch (errors) {
    return rejectWithValue(errors);
  }
});

export const getPaymentBanks = createAsyncThunk<PaymentBankModel[]>(
  Actions.GET_PAYMENT_BANKS,
  async (_, { rejectWithValue, dispatch }) => {
    const config = {
      method: 'GET',
      url: '/payment_banks',
    };

    try {
      return await $axios(config, dispatch);
    } catch (errors) {
      return rejectWithValue(errors);
    }
  },
);

export const sendABTestVariant = createAsyncThunk<string, { source: string; variant: string }>(
  Actions.SEND_AB_TEST_VARIANT_ACTION,
  async (data, { rejectWithValue, dispatch }) => {
    const config = {
      method: 'POST',
      url: `/users/${getUserID()}/a_b_testing`,
      data,
    };

    try {
      return await $axios(config, dispatch);
    } catch (errors) {
      return rejectWithValue(errors);
    }
  },
);

export const emailVerify = createAsyncThunk<null, { token: string; userId: string }>(
  Actions.GET_EMAIL_VERIFY,
  async ({ token, userId }, { rejectWithValue, dispatch }) => {
    const config = {
      method: 'GET',
      url: `/users/${userId}/email_verify?token=${token}`,
    };

    try {
      return await $axios(config, dispatch);
    } catch (errors) {
      return rejectWithValue(errors);
    }
  },
);

export const sendVerificationEmail = createAsyncThunk<string>(
  Actions.SEND_VERIFICATION_EMAIL,
  async (_, { rejectWithValue, dispatch }) => {
    const config = {
      method: 'POST',
      url: `/users/${getUserID()}/email_verification`,
    };

    try {
      return await $axios(config, dispatch);
    } catch (errors) {
      return rejectWithValue(errors);
    }
  },
);

export const sendReferralLink = createAsyncThunk<
  any,
  { email?: string; phone_number?: string; locale: string }
>(Actions.SEND_REFERRAL_LINK, async (data, { rejectWithValue, dispatch }) => {
  const config = {
    method: 'POST',
    url: `/users/${getUserID()}/send_referral_link`,
    data,
  };

  try {
    return await $axios(config, dispatch);
  } catch (errors) {
    return rejectWithValue(errors);
  }
});

export const postSelectUserPhoneResolveAccount = createAsyncThunk<
  AuthResp,
  { id: string; matched_user_id: number }
>(Actions.POST_SELECT_USER_PHONE_RESOLVE_ACCOUNT, async (data, { rejectWithValue, dispatch }) => {
  const config: AxiosRequestConfigExtended = {
    method: 'POST',
    url: `/users/${data.id}/resolve_account`,
    data: { matched_user_id: data.matched_user_id },
  };

  try {
    return await $axios(config, dispatch);
  } catch (errors) {
    return rejectWithValue(errors);
  }
});

export const resolveSimplifiedRegistration = createAsyncThunk<
  { success: boolean },
  { userId: string }
>(Actions.RESOLVE_SIMPLIFIED_REGISTRATION, async ({ userId }, { rejectWithValue, dispatch }) => {
  const config: AxiosRequestConfigExtended = {
    method: 'POST',
    url: `/users/${userId}/resolve_simplified_registration`,
    data: {},
  };

  try {
    return await $axios(config, dispatch);
  } catch (errors) {
    return rejectWithValue(errors);
  }
});

export const resetSimplifiedRegistration = createAsyncThunk<any, { userId: string }>(
  Actions.RESET_SIMPLIFIED_REGISTRATION,
  async ({ userId }, { rejectWithValue, dispatch }) => {
    const config: AxiosRequestConfigExtended = {
      method: 'POST',
      url: `/users/${userId}/reset_simplified_registration`,
      data: {},
    };

    try {
      return await $axios(config, dispatch);
    } catch (errors) {
      return rejectWithValue(errors);
    }
  },
);

export const sendDuplicateLog = createAsyncThunk<
  any,
  {
    duplicate_user_id: number;
    source_user_id: number | null;
    created_at?: string;
    log_data: {
      action: string;
      additional_data: string;
    };
  }
>(Actions.SEND_DUPLICATE_LOG, async (data, { rejectWithValue, dispatch }) => {
  const config = {
    method: 'POST',
    url: '/duplicate_registration_logs',
    data,
  };

  try {
    return await $axios(config, dispatch);
  } catch (errors) {
    return rejectWithValue(errors);
  }
});
