import { MutableRefObject, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AppNavLinkContainer } from 'core/components/common';
import { getCookie } from 'core/services';
import { useTypedSelector } from 'core/hooks';
import { isLoanActiveSelector, userSelector, userShortLinkAuthSelector } from 'store/selectors';
import * as Paths from 'constantsLk/paths';
import { KEY_STORAGE_FROM_SHORT_LINK } from 'constantsLk';

const accLinks = (isLoanActive: boolean, isReferralInfo: boolean) => {
  const linksArray = [
    {
      label: `${isLoanActive ? 'i_menu_credit' : 'apply_for_loan'}`,
      pathname: Paths.URL_ACTIVE_LOAN,
    },
    { label: 'i_menu_history', pathname: Paths.URL_HISTORY_LOAN },
    { label: 'i_menu_bank', pathname: Paths.URL_ACCOUNT_CARDS },
    { label: 'i_menu_payments', pathname: Paths.URL_HISTORY_PAYMENT },
    { label: 'i_menu_information', pathname: Paths.URL_PERSONAL_PAGE },
    { label: 'i_menu_referral', pathname: Paths.URL_REFERRAL_PAGE },
  ];

  return !isReferralInfo
    ? linksArray.filter((item) => item.label !== 'i_menu_referral')
    : linksArray;
};

interface SubHeaderProps {
  onLogout: () => void;
}

export const Subheader = ({ onLogout }: SubHeaderProps) => {
  const { t } = useTranslation();
  const isFromShortLink = getCookie(KEY_STORAGE_FROM_SHORT_LINK);
  const isShortLinkAuth = useTypedSelector(userShortLinkAuthSelector);
  const user = useTypedSelector(userSelector);
  const isLoanActive = useTypedSelector(isLoanActiveSelector);

  const [activeButtonIndex, setActiveButtonIndex] = useState(0);
  const scrollContainerRef = useRef() as MutableRefObject<HTMLDivElement>;

  useEffect(() => {
    scrollContainer();
  }, [activeButtonIndex]);

  const scrollContainer = () => {
    const container = scrollContainerRef.current;
    const activeButtonElement = container.children[activeButtonIndex];
    let activeButtonLeft;

    if (activeButtonElement instanceof HTMLElement) {
      activeButtonLeft = activeButtonElement.offsetLeft - 15;
    }

    container.scrollTo({
      left: activeButtonLeft,
      behavior: 'smooth',
    });
  };

  return (
    <>
      <div className='hidden-mobile'>
        <h1 className='txt-b'>{t`personal_account`}</h1>
      </div>
      <div className='justify-content-start align-items-start'>
        <div className='subheader' ref={scrollContainerRef}>
          <>
            {accLinks(isLoanActive, !!user?.referral_info?.referral_link).map((link, index) => (
              <AppNavLinkContainer
                className={({ isActive }) =>
                  isActive ? 'subheader-link active' : 'subheader-link'
                }
                key={index}
                to={
                  (isFromShortLink || isShortLinkAuth) && link.label !== 'i_menu_credit'
                    ? `/${Paths.URL_PATH_LOGIN}`
                    : link.pathname
                }
                onClick={() => setActiveButtonIndex(index)}
              >
                {t(link.label)}
              </AppNavLinkContainer>
            ))}
            <span className='hidden-mobile' onClick={onLogout}>
              {t`log_out`}
            </span>
          </>
        </div>
      </div>
    </>
  );
};
