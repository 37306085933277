// eslint-disable
export const translationsSI = {
  title: 'CashX හි ලියාපදිංචි වන්න. අන්තර්ජාලය හරහා ඉක්මන් ණය',
  description: 'අන්තර්ජාලයෙන් පහසු මුදල් ණය. සෑම දෙනා සඳහාම ඉක්මන් ක්ෂණික ණය මුදලක් කෑශ් x වෙතින්',
  title_login: 'CashX වෙත පිවිසෙන්න. අන්තර්ජාලය හරහා ඉක්මන් ණය',
  description_login:
    'CashX සමඟින් ඇතුළු වී වේගවත් මුදල් ණයක් ලබා ගන්න. ' +
    'ශ්‍රී ලංකාව තුළ අඩු පොලී, වේගවත් අනුමැතිය.',
  title_step1: 'CashX හි ලියාපදිංචි වන්න. අන්තර්ජාලය හරහා ඉක්මන් ණය',
  description_step1:
    'ඔබට ශ්‍රී ලංකාවේ වේගවත් ණය අවශ්‍ය නම් CashX  ' +
    'සමඟින් ලියාපදිංචි වී අන්තර්ජාලය හරහා පහසු සහ ඉක්මන් ණයක් ලබා ගන්න.',
  loan_amount: 'ණය මුදල',
  payment_date: 'ගෙවීම් දිනය',
  payment_date_tooltip: 'ණය සක්‍රීය වූ පසු ගෙවීමේ දිනය ජනනය කෙරේ.',
  fees: 'ගාස්තු',
  settlement_amount: 'ගෙවිය යුතු මුදල',
  active_loan_amount: 'ක්‍රියාකාරී ණය මුදල',
  additional_amount: 'අමතර මුදල',
  log_in: 'ඇතුල් වන්න',
  language: 'භාෂාව',
  reference_information: 'තෙවන පාර්ශවයන්ගේ තොරතුරු',
  apply_for_loan: 'ණය අයදුම් කරන්න',
  cancel: 'අවලංගු කරන්න',
  already_you_already_registered: 'ඔබ දැනටමත් ලියාපදිංචි වී තිබේද?',
  contact_details: 'ඇමතුම් විස්තර',
  continue: 'ඉදිරියට යන්න',
  sign_the_agreement: 'ගිවිසුම අත්සන් කරන්න',
  phone_number: 'දුරකථන අංකය',
  duration_work_err_first_0: 'මෙම අගය 1 සහ 999 අතර විය යුතුය',
  phone_number_err_first_0: 'දුරකථන අංකය අංක 0 සමඟ ආරම්භ කළ නොහැක',
  phone_number_err_first_7: 'දුරකථන අංකය අංක 7 න් ආරම්භ විය යුතුය',
  phone_number_err_count_num_11: 'දුරකථන අංකය රටේ කේතය ඇතුළුව ඉලක්කම් 11 කින් සමන්විත විය යුතුය',
  field_err_required: 'මෙම විස්තරය පිරවීම අනිවාර්ය වේ',
  phone_verification: 'දුරකථන සත්‍යාපනය',
  sent_you_sms_code: 'අපි ඔබට කේතය සමඟ කෙටි පණිවුඩයක් එවා ඇත',
  please_enter_code: 'කරුණාකර පහත කේතය ඇතුළත් කරන්න',
  resend_code: 'කේතය නැවත ලබාගැනීම',
  re_sending_code: 'පසුව නැවත කේතය යවන්න {time}',
  sms_code: 'කෙටි පණිවුඩ කේතය',
  sms_code_required: 'කේතය හිස්තැනෙහි පුරවන්න',
  min_length_field: 'වැරදි කේතයකි',
  field_min_length_error: 'මෙම ක්ෂේත්‍රය අවම වශයෙන් අක්ෂර 2ක් දිග විය යුතුය',
  field_min_length3_error: 'මෙම ක්ෂේත්‍රය අවම වශයෙන් අක්ෂර 3 ක් දිග විය යුතුය',
  email_min_length3_error: 'ඊමේල් ලිපිනය @ පෙර අක්ෂර 3කට වඩා අඩු විය නොහැක',
  email_err_www_prefix:
    'විද්‍යුත් තැපැල් ලිපිනයෙහි @ අක්ෂරට පෙර ,අක්ෂර 3කට වඩා අඩු විය නොහැක (www. වෙනුවට)',
  field_min_length3_max_length20_error: 'ගිණුම් අංකයේ ඉලක්කම් ගණන ඉලක්කම් 3 සිට 20 දක්වා විය යුතුය',
  this_code_is_incorrect: 'වැරදි කේතයකි',
  is_data_processing_consent_given: 'මම <a> terms_conditions</a> සමඟ එකඟ වෙමි',
  terms_conditions: 'නියමයන් හා කොන්දේසි',
  is_data_processing_consent_given_com: '<a>නියමයන් හා කොන්දේසි</a> සමඟ එකඟ වෙමි',
  checkbox_err_required: 'ඉදිරියට යෑමට පිරික්සුම් කොටුව සක්‍රීය කරන්න',
  entered_invalid_password_call_center:
    'අපගේ කෙටි පණිවිඩ කේතයේ දෝෂයක් සිදුවී ඇත. කරුණාකර අපගේ පාරිභෝගික' +
    ' මද්‍යස්ථානය අමතන්න {phone}!',
  exceeded_code_request_limit: 'ඔබ කේත ඉල්ලීමේ සීමාව ඉක්මවා ඇත',
  sending_code_loading: 'පූරණය',
  liveness_loading: 'පූරණය වෙමින් පවතී. කරුණාකර රැඳී සිටින්න...',
  liveness_loading_error: 'කරුණාකර පිටුව නැවත පූරණය කර නැවත උත්සාහ කරන්න.',
  didnt_get_an_sms_code_yet:
    'තවම කෙටි පණිවුඩ කේතයක් ලැබී නැද්ද? ඔබේ අංක {user_phone} වෙතින් අපගේ ' +
    'පාරිභෝගික සේවා අංකය {wp_phone} අමතන්න.',
  personal_data: 'පෞද්ගලික දත්ත',
  first_name: 'මුල් නම (ඉංග්‍රීසියෙන්)',
  field_err_not_en: 'නම ඉංග්‍රීසියෙන් විය යුතුය',
  company_name_err_not_en: 'සමාගමේ නම ඉංග්‍රීසියෙන් විය යුතුය',
  job_position_err_not_en: 'රැකියා ස්ථානය ඉංග්‍රීසියෙන් විය යුතුය',
  email_err_not_en: 'විද්‍යුත් තැපෑල ඉංග්‍රීසියෙන් විය යුතුය',
  field_err_punctuation_marks: 'නමේ තිබිය යුත්තේ ඉංග්‍රීසි අක්ෂර පමණි',
  last_name: 'අවසාන නම (ඉංග්‍රීසියෙන්)',
  personal_id_issue_date: 'ජාතික හැඳුනුම්පත නිකුත් කල දිනය (වසර/මාසය/දවස)',
  field_date_err_validate: 'දිනය, මාසය, වර්ෂය යන ආකාරයෙන් ඇතුලත් කරන්න.',
  gender: 'ස්ත්‍රී/පුරුෂ',
  male: 'පුරුෂ',
  female: 'ස්ත්',
  birthday: 'උපන් දිනය (වසර/මාසය/දවස)',
  birthday_date: 'උපන් දිනය',
  not_email: 'මට විද්‍යුත් තැපෑලක් නැත',
  email: 'විද්‍යුත් තැපෑල',
  field_email_err_validate: 'ඔබගේ විද්‍යුත් තැපෑල වලංගු නොවේ. කරුණාකර නැවත උත්සාහ කරන්න',
  email_cannot_be_applied: 'මෙම ඊමේල් ලිපිනය යෙදිය නොහැක',
  personal_id_number: 'ජාතික හැඳුනුම්පත් අංකය',
  personal_id_number_old_type: 'පැරණි වර්ගය',
  personal_id_number_old_err_validate: 'ඔබගේ හැඳුනුම්පත අංක 9කින් සමන්විත විය යුතුය.',
  personal_id_number_new_type: 'නව වර්ගය',
  personal_id_number_new_err_validate: 'ඔබගේ හැඳුනුම්පත අංක 12කින් සමන්විත විය යුතුය.',
  residence_address: 'පදිංචි ලිපිනය',
  residence_province: 'පළාත',
  address: 'ලිපිනය',
  field_select_err_required: 'අදාල පිළිතුර ‍තෝරන්න.',
  residence_district: 'දිස්ත්‍රික්කය',
  residence_locality: 'නගරය',
  residence_street: 'වීදිය',
  residence_house: 'නිවස',
  residence_flat: 'මහල් නිවාස (විකල්ප)',
  residence_duration: 'ලිපිනයේ පදිංචි කාලය',
  placeholder_select_default: 'අදාල පිළිතුර ‍තෝරන්න',
  additional_details: 'අතිරේක විස්තර',
  loan_purpose: 'ණය ගැනීමේ අරමුණ',
  has_external_loan: 'වර්තමාන වෙනත් ණය තිබේද?',
  yes: 'ඔව්',
  no: 'නැත',
  yes2: 'ඔව්',
  no2: 'අංක',
  external_loan_monthly_payments: 'වත්මන් මාසික ගෙවීම',
  education: 'අධ්‍යාපන මට්ටම',
  education_err_required: 'ලැයිස්තුවෙන් අදාල පිළිතුර ‍තෝරන්න.',
  employment_type: 'රැකියාවේ ස්වභාවය',
  employment_social_security_number: 'සේවක අර්ථසාධක අරමුදල් අංකය හෝ සේවක අංකය (විකල්ප)',
  employment_industry: 'රැකියාවේ ස්වභාවය',
  employment_position: 'රැකියාවේ තනතුර',
  custom_job_position: 'ඔබගේ රැකියාවේ තනතුර සඳහන් කරන්න',
  employment_duration: 'රැකියා ස්ථානයේ සේවා කාලය.',
  employment_company_name: 'රැකියා ස්ථානයේ නම',
  employment_company_phone_number: 'සේවා ස්ථානයේ දුරකථන අංකය',
  phone_should_consist_11_digits: 'දුරකථන අංකය රටේ කේතය ඇතුළුව ඉලක්කම් 11 කින් සමන්විත විය යුතුය',
  phone_should_consist_10_digits: 'දුරකථන අංකය 0 ඇතුළුව ඉලක්කම් 10 කින් සමන්විත විය යුතුය',
  fixed_line_required: 'ස්ථාවර දුරකතන අංකයක් අවශ්ය වේ.',
  main_source_of_income: 'ආදායම් මාර්ගය',
  monthly_income: 'මාසික වැ‍ටුප',
  marital_status_contact: 'විවාහක තත්ත්වය සහ සම්බන්දීකරණ පුද්ගල තොරතුරු',
  ensure_faster_credit_processing:
    'වේගවත් ණය සැකසුම් සහතික කිරීම සඳහා, ඔබගේ සම්බන්ධතා පුද්ගලයාගේ ' +
    'තොරතුරු පහතින් ඇතුළත් කරන්න',
  additional_contact: 'ඔබගේ අතිරේක නිර්දේශකයා',
  should_20_60_years: 'වයස අවුරුදු 20-60 අතර පුද්ගලයෙකු විය යුතුය',
  marital_status: 'විවාහක/අවිවාහක',
  marital_status_and_contact_info: 'විවාහක තත්ත්වය සහ සම්බන්ධතා පුද්ගල තොරතුරු',
  number_of_children: 'ළමයින් ගණන',
  native_language: 'ස්වදේශීය භාෂාව (ඔබ වඩා හොඳින් කතා කරන භාෂාව)',
  contact_person_hint_text:
    'වේගවත් ණය සැකසීම සහතික කිරීම සඳහා, පහත ඔබේ සම්බන්ධතා පුද්ගල තොරතුරු ඇතුළත් කරන්න',
  contact_name: 'ඇමතිය යුතු පුද්ගලයා/ඥාතියාගේ නම (ඉංග්‍රීසියෙන්)',
  contact_relationship: 'නෑදෑකම',
  contact_phone_number: 'තෙවන පාර්ශවයේ දුරකථන අංකය',
  bank_account_details: 'ඔබගේ බැංකු ගිණුම් විස්තර',
  bank_account_details_modal_subtitle: 'කරුණාකර ඔබගේ බැංකු ගිණුම් විස්තර වෙනස් කරන්න',
  account_number_money: 'කරුණාකර, මුදල් බැරකිරීමට අවශ්‍යය ගිණුම් අංකය සඳහන් කරන්න',
  agree_bank: 'ඉහත බැංකු ගිණුම් විස්තර වල නිරවද්‍යතාවය මම භාර ගනිමි',
  agree_bank_err_required: 'ඔබගේ එකඟතාව අවශ්‍ය වේ.',
  bank_account_number: 'ගිණුම් අංකය',
  bank_account_number_err_required: 'මෙම විස්තරය පිරවීම අනිවාර්ය වේ.',
  bank_account_number_err_validate: 'ගිණුම් අංකයේ ඉලක්කම් ගණන ඉලක්කම් 3 සිට 20 දක්වා විය යුතුය',
  bank_branch_name: 'බැංකු ශාඛාව',
  bank_name: 'බැංකුවේ නම',
  please_select_the_bank: 'කරුණාකර බැංකුව තෝරන්න',
  confirmation_conditions: 'කොන්දේසි තහවුරු කිරීම',
  requested_loan: 'ඔබ පහත සඳහන් කොන්දේසි වලට යටත්ව ණය මුදලක් ඉල්ලුම්කර ඇත',
  loan_term: 'ඉල්ලුම්කල කාලය',
  days: 'දින',
  hours: 'පැය',
  min: 'අවම',
  change_conditions: 'කොන්දේසි වෙනස් කරන්න',
  please_confirm_loan:
    'අයදුම් කිරීමේ ක්‍රියාවලිය සම්පූර්ණ කිරීම සඳහා කරුණාකර ණය කොන්දේසි තහවුරු කරන්න',
  photographs: 'ලේඛන ඡායාරූප',
  upload_selfie: 'ඔබගේ අයදුම් කිරීමේ ක්‍රියාවලිය අවසන් කිරීමට, කරුණාකර ඔබගේ සෙල්ෆිය ගන්න',
  upload_photograph:
    'අයදුම් කිරීමේ ක්‍රියාවලිය සම්පූර්ණ කිරීම සඳහා කරුණාකර ඔබගේ ජාතික හැඳුනුම්පතේ ඡායාරූප ගන්න',
  error_not_access_web_cam: 'කරුණාකර කැමරාවට ප්‍රවේශය ලබා දෙන්න',
  error_not_web_cam: 'කරුණාකර ඡායාරූපයක් ගැනීමට ඔබේ වෙබ් කැමරාව සම්බන්ධ කරන්න',
  selfie_card_upload_title: 'සෙල්ෆි ඡායාරූපය',
  selfie_tips: 'සෙල්ෆි ඉඟි',
  good_lighting_on_your_face: 'ඔබේ මුහුණේ හොඳ ආලෝකය',
  look_directly_into_the_webcam: 'වෙබ් කැමරාව දෙස කෙලින්ම බලන්න',
  bright_light: 'දීප්තිමත් ආලෝකය',
  no_glasses: 'කණ්නාඩි එපා',
  no_hat: 'තොප්පිය එපා',
  selfie_card_upload_title_web: 'ඡායාරූපය ලබාගන්න',
  selfie_card_upload_description: 'ඔබට ඉදිරියෙන් කැමරාව යොමු කර ඡායාරූපයක් ගන්න',
  wait_for_loading: 'පූරණය වන තෙක් රැදී සිටින්න',
  nic_back_card_upload_title: 'ජාතික හැදුනුම්පතේ පිටුපස ඡායාරූපය',
  nic_back_card_upload_subtitle:
    'අයදුම් කිරීමේ ක්‍රියාවලිය සම්පූර්ණ කිරීමට, කරුණාකර ඔබගේ ජාතික හැඳුනුම්පතේ පසුපස ඡායාරූපයක් ගන්න',
  nic_back_card_upload_title_web: 'ජාතික හැඳුනුම්පතේ පසුපස ඡායාරූපය ලබාගන්න',
  nic_back_card_upload_description:
    'සියලු දත්ත පැහැදිලිව පෙනෙන පරිදි ජාතික හැඳුනුම්පතේ පිටුපස ඡායාරූපයක් ගන්න',
  nic_front_card_upload_title: 'ජාතික හැදුනුම්පතේ ඉදිරිපස ඡායාරූපය',
  nic_front_card_upload_subtitle:
    'අයදුම් කිරීමේ ක්‍රියාවලිය සම්පූර්ණ කිරීම සඳහා, කරුණාකර ඔබගේ ජාතික හැඳුනුම්පත ඉදිරිපස ඡායාරූපයක් ගන්න',
  nic_front_card_upload_title_web: 'ජාතික හැඳුනුම්පතේ ඉදිරිපස ඡායාරූපය ලබාගන්න',
  nic_front_card_upload_description:
    'සියලුම දත්ත පැහැදිලිව දැකගත හැකි වන පරිදි ජාතික හැඳුනුම්පතේ ඉදිරිපස ' + 'ඡායාරූපයක් ගන්න',
  validate_loading: 'ඔබේ ඡායාරූපය වලංගු වෙමින් පවතී',
  retake_photo: 'ඡායාරූපය නැවත ගන්න',
  nic_number_used:
    'මෙම පුද්ගලික හැඳුනුම් අංකය සහිත පරිශීලකයා කලින් ලියාපදිංචි වී ඇති අතර අපට එකම හැඳුනුම්පත අංකයකින් ගිණුම් දෙකක් සෑදිය නොහැක, ඔබට +94 { matched_user_phone } දුරකථන අංකයට ප්‍රවේශය තිබේද?',
  error_validate_nic: 'ඡායාරූපය අපහැදිලි. කරුණාකර, ඡායාරූපය නැවත ඇතුලත් කරන්න කරන්න.',
  please_upload_photo: 'කරුණාකර ඡායාරූප උඩුගත කරන්න.',
  account_number_used:
    'මෙම ගිණුම් අංකය දැනටමත් භාවිතා කර ඇත. කරුණාකර අපගේ ඇමතුම් මධ්‍යස්ථානය අමතන්න.',
  register_now: 'දැන්ම ලියාපදිංචි වන්න',
  or_register_now: 'ඇතුල් වන්න හෝ දැන් ලියාපදිංචි වන්න',
  enter_your_phone: 'අපි ඔබට කේතය සමඟ SMS එවන්නෙමු',
  get_the_code: 'කේතය ලබා ගන්න',
  leave_registration: 'ඔබට ලියාපදිංචියෙන් ඉවත් වීමට අවශ්‍යද?',
  processing_personal_data_title: 'පුද්ගලික දත්ත සැකසීමට කැමැත්ත පලකිරිම',
  processing_personal_data_description:
    'මෙම සලකුණු කොටුව සක්‍රිය කිරීමෙන් ඔබ පුද්ගලික දත්ත සැකසීමට එකඟ වේ',
  take_another_photo: 'නැවත ඡායාරෑපයක් ලබාගන්න',
  error_occurred_please_again: 'දෝෂයක් සිදු විය. කරුණාකර නැවත උත්සාහ කරන්න.',
  take_a_photo: 'ඡායාරූපය ලබාගන්න',
  unsupported_image_type: 'චයාරුපයේ වර්ගය වෙනස් ය. කරුණාකර නැවත උත්සාහ කරන්න.',
  log_out: 'ඉවතට',
  personal_information: 'පුද්ගලික තොරතුරු',
  birthday_date_acc: 'උපන් දිනය',
  e_mail_acc: 'විද්‍යුත් තැපැල් ලිපිනය',
  personal_id_acc: 'ජාතික හැඳුනුම්පත් අංකය',
  phone_number_acc: 'දුරකථන අංකය',
  address_acc: 'ලිපිනය',
  change_information: 'ලිපිනය',
  to_change_you_info:
    'දුරකථන අංකය හෝ වෙනත් තොරතුරු වෙනස් කිරීම සඳහා අපගේ පාරිභෝගික සේවා සහය ' + 'අමතන්න',
  branch: 'ශාඛාව',
  personal_account: 'පුද්ගලික ගිණුම',
  account: 'ගිණුම',
  i_menu_credit: 'ණය විස්තර',
  i_menu_history: 'ණය ඉතිහාසය',
  i_menu_bank: 'බැංකු ගිණුම්',
  i_menu_payments: 'ගෙවීම් ඉතිහාසය',
  i_menu_information: 'පුද්ගලික තොරතුරු',
  i_menu_referral: 'යොමු කිරීමේ වැඩසටහන',
  contract_number: 'ගිවිසුම් අංකය',
  payment_amount: 'ගෙවීම් ප්රමාණය',
  not_payments_history: 'ඔබට තවම ගෙවීම් ලැබී නැත',
  payments_history_last_30_days: 'පසුගිය දින 30 සඳහා ගෙවීම් ඉතිහාසය',
  disbursement_date: 'නිකුත් කල දිනය',
  loan_sum: 'ණය මුදල',
  term: 'ණය කාල සීමාව',
  actual_term: 'දැනට ගතවූ කාලය',
  documents: 'ලේඛන',
  loan_agreement: 'ණය ගිවිසුම',
  addendum: 'අතිරේකය',
  ability_submit_new_application_not_available:
    'කනගාටුයි, නව අයදුම්පතක් ඉදිරිපත් කිරීමේ හැකියාව අපගේ ' + 'සේවාවේ නොමැත',
  thanks_greatly_choosing_our_service: 'අපගේ සේවාව තෝරා ගැනීම ගැන බොහෝම ස්තූතියි.',
  sending_money_account: 'අප ඔබගේ බැංකු ගිණුමට මුදල් ගිණුම් ගත කරන්නෙමු.',
  usually_takes_15_minutes: 'ඔබේ ගිණුමට මුදල් තැන්පත් වීමට සාමාන්‍යයෙන් මිනිත්තු 15ක් දක්වා ගත වේ',
  within_15_minutes: 'විනාඩි 15ක් ඇතුළත ඔබේ ගිණුමට මුදල් තැන්පත් කරනු ලැබේ',
  usually_takes_3_4_hours_strong:
    '<p>ඔබේ ගිණුමට මුදල් බැරවීමට සාමාන්‍යයෙන් පැය ' + '<strong>3 සිට 4 දක්වා</strong> ගත වේ.</p>',
  your_application_accepted: 'ඔබගේ අයදුම්පත පිළිගනු ලැබේ',
  your_application_being:
    'ඔබගේ අයදුම්පත සත්‍යාපනය කරන්නා විසින් සමාලෝචනය කෙරේ. ඔබගේ දුරකථනය { phone } සමීපව තබා ගන්න - අපි අමතර ප්‍රශ්න සඳහා ඇමතිය හැක.',
  keep_your_phone_close: 'ඔබගේ ජංගම දුරකථනය ලගින් තබාගන්න - අපි වැඩිදුර තොරතුරු සදහා අමතනු ලැබේ!',
  application_review_usually: 'යෙදුම් සමාලෝචනය සාමාන්‍යයෙන් වැඩ කරන වේලාවේදී මිනිත්තු 15 ක් ගතවේ',
  as_soon_decision: 'තීරණය ගත් වහාම, අපි ඔබට කෙටි පණිවිඩයක් එවන්නෙමු.',
  not_loan_history: 'ඔබට තවම ණයක් ලැබී නැත.',
  unfortunately_your_application: 'කණගා‍ටුයි, ඔබගේ අයදුම්පත ප්‍රතික්ෂේප වී ඇත',
  you_can_apply_new: 'ඔබට නව ණයක් සඳහා අයදුම් කළ හැකිය',
  thanks_greatly_choosing_service: 'අපගේ සේවාව තෝරා ගැනීම ගැන බොහෝම ස්තූතියි',
  time_till_repeat_request_30:
    'නැවත ණය අයදුම් කිරීමට ඇති කාලය දින {days}, පැය {hours}, විනාඩි {minutes}.',
  globalDaysText: 'දින',
  globalDayText: 'දින',
  globalDay2Text: 'දින',
  globalMinuteText: 'විනාඩි',
  globalMinute2Text: 'විනාඩි',
  globalMinute3Text: 'විනාඩි',
  globalHourText: 'පැය',
  globalHour2Text: 'පැය',
  globalHoursText: 'පැය',
  requested_loan_term: 'ඉල්ලුම්කල ගෙවීම් කාලය',
  bank_account_number_changing: 'බැංකු ගිණුම් අංකය වෙනස් කිරීම',
  change_bank_account_contact_support: 'බැංකු ගිණුමක් වෙනස් කිරීමට කරුණාකර සහාය අමතන්න',
  submit: 'ඉදිරිපත් කරන්න',
  choose_loan_amount: 'ණය මුදල තෝරන්න',
  you_have_no_active_loans: 'ඔබට මේ වන විට සක්‍රීය ණය නොමැත',
  repeat_loan_subtitle: 'ඔබට දැන් දින 30 ක් දක්වා ණය සඳහා ඉල්ලුම් කළ හැක',
  to_get_first_loan_5_days:
    '<span>පළමු ණය මුදල දින {{value}} ක් සඳහා වට්ටමක් සහිතව ලබා ගන්න!</span>',
  to_get_first_loan_10_days: '<span>දින {{value}} ක් සඳහා පළමු ණය ලබා ගන්න!</span>',
  to_get_first_loan_15_days:
    '<span>දින {{value}} සඳහා පළමු ණය ලබා ගන්න! ඔබ ඔබේ පළමු ණය ආපසු ගෙවීමෙන් පසු, ' +
    'දිගු ණය කොන්දේසි ඔබට ලබා ගත හැකි වනු ඇත!</span>',
  transfer_account_number: 'ගිණුම් අංකයට මාරු කරන්න:',
  change_account_number: 'ගිණුම් අංකයක් වෙනස් කරන්න',
  signing_loan_agreement: 'ණය ගිවිසුමක් අත්සන් කිරීම',
  sent_sms_code: 'අපි ඔබේ අංකයට කේතය සහිත කෙටි පණිවිඩයක් යවා ඇත්තෙමු',
  please_enter_code_order_sign:
    'කොන්ත්රාත්තුව අත්සන් කිරීම සඳහා කරුණාකර පහත කොටසෙහි කේතය ඇතුළත් කරන්න',
  fist_loan_default_term: 'ණය කාලය දින {days} කි',
  paid: 'ගෙවා ඇත',
  active: 'සක්‍රීය',
  defaulted: 'පැහැර හැරීම',
  pending: 'පොරොත්තු',
  closed: 'වසා ඇත',
  cancelled: 'අවලංගු කළ ඇත',
  sold: 'විකුණා ඇත',
  overdue: 'කාල සීමාව ඉක්මවා ඇත',
  written_off: 'කපා හැර තිබේ',
  days_past: 'ඉක්මවා ගිය දින',
  days_till: 'ගෙවා අවසන් කිරීමට ඇති දින',
  extended_from_date: 'දිගු කර ඇත {date}',
  loan: 'ණය',
  total_repayment: 'ගෙවිය යුතු සම්පූර්ණ මුදල',
  limited_time_offer: 'සීමිත කාල දීමනාවක්',
  offer_is_available: 'පිරිනැමීම තිබේ',
  promo: 'ප්‍රවර්ධන',
  offer_is_valid_till: 'දක්වා මෙම දීමනාව වලංගු වේ',
  repayment_with_discount: 'වට්ටම් සමග ආපසු ගෙවීම',
  body: 'මූලික ණය මුදල',
  service_fee: 'සේවා ගාස්තු',
  service_fee_add: 'සේවා ගාස්තු (ADD)',
  transaction_fee: 'ගනුදෙනු ගාස්තු',
  cons_fee: 'උපදේශන ගාස්තු',
  cons_fee_add: 'උපදේශන ගාස්තු (ADD)',
  fines: 'ප්‍රමාද ගාස්තු',
  prolongation: 'කාලය දීර්ඝ කිරීම',
  prolongation_subtitle: 'ඔබට දැන් ගෙවීමට ප්‍රමාණවත් මුදල් නොමැති නම් ඔබට ඔබේ ණය කාලය දීර්ඝ කළ හැක',
  prolong_30: 'ඔබට ණය මුදල දින 30ක දක්වා කාලය දීර්ඝ කල හැක',
  total_prolong: 'කාලය දීර්ඝ කරගැනීමට ගෙවිය යුතු අවම මුදල',
  total_prolong_with_discount: 'වට්ටමක් සමඟ දීර්ඝ කිරීම සඳහා මුළු එකතුව',
  prolong_date: '{date} දින සිට ලබා ගත හැකිය',
  prolong: 'අලුත් කිරීම සඳහා ගෙවන්න',
  select_extension_period: 'අලුත් කිරීමේ කාලය තෝරන්න',
  prolongation_modal:
    'ණය නැවත ගෙවීම් කාලය දීර්ඝ කරගැනීමට කරුණාකර අපගේ පාරිභෝගික සේවා මධ්‍යස්ථානය ' +
    '{phone} සමඟ සම්බන්ද වන්න',
  please_repay_total_sum: 'කරුණාකර, මුදල් ආපසු ගෙවීම් කාලය දීර්ඝ කිරීමේ සම්පූර්ණ මුදල ආපසු ගෙවන්න',
  how_to_repay: 'ආපසු ගෙවිය යුතු ආකාරය',
  repayment: 'ණය ආපසු ගෙවීම',
  for_information:
    '{ provider } සේවාව භාවිතයෙන් ඔබට ඔබේ ණය ඉක්මනින් හා වඩාත් පහසුවෙන් අන්තර්ජාලය හරහා ' +
    'ආපසු ගෙවිය හැකිය',
  how_repay_online: 'මාර්ගගතව ආපසු ගෙවන්න',
  repay_online_discount: 'ගෙවන්න',
  how_repay_offline: 'සාමන්‍යය මාධ්‍යකින් මුදල් ආපසු ගෙවන්නේ කෙසේද?',
  title_slider_repay: 'ආපසු ගෙවීමේ මුදල',
  discount_code: 'වට්ටම් කේතය',
  i_have_promocode: 'මට ප්‍රවර්ධන කේතයක් ඇත',
  promocode_activated: 'ප්‍රවර්ධන කේතය සක්‍රිය කර ඇත',
  introduce_another: 'තවත් ප්‍රවර්ධන කේතයක් හඳුන්වා දෙන්න',
  enter_promo_code: 'ප්‍රවර්ධන කේතය',
  enter_code_from_sms: 'කේතය SMS මගින්',
  enter_the_code: 'කේතය ඇතුලත් කරන්න',
  apply: 'අයදුම් කරන්න',
  apply_now: 'අයදුම් කරන්න',
  need_more_money: 'තවත් මුදල් අවශ්‍යද?',
  apply_loan_from_partners: 'අපගේ හවුල්කරුවන්ගෙන් ණයක් සඳහා අයදුම් කිරීමට උත්සාහ කරන්න',
  the_promocode_has_expired: 'ප්‍රවර්ධන කේතය කල් ඉකුත් වී ඇත',
  the_promocode_used: 'ප්‍රවර්ධන කේතය දැනටමත් භාවිතා කර ඇත',
  promocode_not_applicable: 'ප්‍රවර්ධන කේතය නොගැලපේ',
  promocode_not_found: 'ප්‍රවර්ධන කේතය ලැබී නොමැත',
  promocode_disabled: 'ප්‍රවර්ධන කේතය අක්‍රිය කර ඇත',
  you_didnt_confirm_promo_code:
    'ඔබේ ප්‍රවර්ධන කේතය වලංගුයි, නමුත් තවමත් යෙදී නැත. ' +
    'ඔබට ප්‍රවර්ධන කේතයක් නොමැතිව අයදුම් කිරීමට අවශ්‍ය බව විශ්වාසද?',
  an_error_occurred_try_again: 'දෝෂයක් සිදුවී ඇත. කරුණාකර නැවත උත්සාහ කරන්න.',
  title_facebook_registration_modal:
    ' Facebook හරහා ලියාපදිංචිය ඉක්මණින් ඔබට අනුමැතිය සඳහා ඇති අවස්ථාව ' + 'දෙගුණ කරයි (විකල්ප)',
  title_link_facebook_registration: 'ෆේස්බුක් හරහා ලියාපදිංචිය Facebook',
  facebook: 'Facebook',
  registration_progress_title_first: 'අංක සත්‍යාපනය',
  registration_progress_title_second: 'පුද්ගලික තොරතුරු',
  registration_progress_title_third: 'මුදල් ලැබීම',
  registration_progress_title_percent: 'ණය අනුමත කිරීමේ සම්භාවිතාවට {percent}%ක්',
  error_hyper_snap:
    'Selfie ඡායාරුපය සත්‍යාපනය කිරීමට හැකියාවක් නැත. කරුණාකර වෙනත් ඡායාරූපයක් යොදන්න.',
  confirm_with_call: 'ඇමතුමක් මගින් තහවුරු කරන්න.',
  we_will_call_you_nearest_time: 'අපි ඔබව ඉක්මනින් අමතන්නෙමු.',
  answer_call_please_follow_instructions: 'ඇමතුමට ප්‍රතිචාර දෙන්න, කරුණාකර උපදෙස් අනුගමනය කරන්න.',
  repeat_call_time: 'නැවත ඇමතුම් {time} ට.',
  repeat_call_again: 'ඇමතුම සමග නැවත සම්බන්ද වන්න.',
  confirm_with_sms: 'SMS මගින් තහවුරු කරන්න.',
  confirm_with_call_error:
    'කනගාටුයි,ඔබට දැනට ඇමතුමක් ගත නොහැක, කරුණාකර නැවත කෙටි පණිවිඩයක් ඉල්ලීමට උත්සාහ කරන්න',
  phone_request_limit_reached:
    'දුරකථන ඉල්ලීම් සීමාව දක්වා ළඟා වී ඇත. <a>{{phone}}</a> යන දුරකතන අංකය ' +
    'මගින් අප හා සම්බන්ධ වන්න.',
  your_phone_number_verified: 'ඔබගේ දුරකථන අංකය {value} සත්‍යාපනය කර ඇත.',
  please_agreee_to_continue: 'කරුණාකර ගනුදෙනුව ඉදිරියට ගෙන යාමට නියම සහ කොන්දේසි වලට එකඟ වන්න.',
  please_make_new_selfie: 'කරුණාකර නැවත සෙල්ෆියක් ගෙන උපදෙස් අනුගමනය කරන්න.',
  plugin_not_ready: 'කරුණාකර තත්පර කිහිපයකින් සෙල්ෆියක් ගැනීමට උත්සාහ කරන්න.',
  please_make_clear_selfie: 'කරුණාකර පැහැදිලි චායාරුපයක් ගන්න.',
  we_sending_money_your_account_number_right_now: 'අපි ඔබේ ගිණුම් අංකයට මුදල් යවනවා',
  leave_feedback_our_service: 'අපට ඔබගේ ප්‍රතිචාරය ලබා දෙන්න',
  are_you_satisfied_with_our_service: 'ඔබ අපගේ සේවය ගැන තෘප්තිමත්ද?',
  maturity_date: 'ණය මුදල පරිනත වන දිනය',
  change: 'වෙනස් කිරීම',
  leave_page_confirm: 'ඔබට පිටුව හැර යාමට අවශ්‍ය බව විශ්වාසද? ඔබට ඔබේ දත්ත අහිමි වනු ඇත',
  confirm: 'තහවුරු',
  // weekdays
  monday: 'සදුදා',
  tuesday: 'අගහරැවාදා',
  wednesday: 'බදාදා',
  thursday: 'බ්‍රහස්පතින්දා',
  friday: 'සිකුරාදා',
  saturday: 'සෙනසුරාදා',
  sunday: 'ඉරිදා',
  check_your_phone_number: 'දුරකථන අංකය පරීක්ෂා කරන්න',
  number_not_registered: 'මෙම අංකය ලියාපදිංචි කර නොමැත',
  please_check_number_again:
    'කරුණාකර <a>{ phone }</a> අංකය වෙනස් කරන්න නැතහොත් ලියාපදිංචිය සඳහා "ඉදිරියට යන්න" ක්ලික් කරන්න',
  is_your_number: 'ඔබේ අංකයයි',
  no_change_number: 'නැහැ, අංකය වෙනස් කරන්න',
  change_number: 'අංකය වෙනස් කරන්න',
  please_grant_access_to_camera: 'කරුණාකර කැමරාවට ප්‍රවේශය දෙන්න',
  it_is_clear: 'එය පැහැදිලියි',
  you_must_grant_access_to_camera_subtitle:
    'ඡායාරූපයක් සාර්ථකව ගැනීමට, ඔබ <strong>"එය පැහැදිලියි"</strong>' +
    ' බොත්තම ක්ලික් කිරීමෙන් පසු කැමරාවට ප්‍රවේශය ලබා දිය යුතුය',
  dont_worry_your_data_is_secure: 'කණගාටු නොවන්න, ඔබගේ දත්ත ආරක්ෂිතයි',
  what_if_i_denied_access: 'මම කැමරාවට ප්‍රවේශය ප්‍රතික්ෂේප කර ඇත්නම් මා කළ යුත්තේ කුමක්ද?',
  how_do_you_use_our_site: 'ඔබ අපගේ වෙබ් අඩවිය භාවිතා කරන්නේ කෙසේද?',
  go_to_browser_settings: 'ඔබගේ බ්‍රව්සර් සැකසුම් වෙත යන්න',
  select_privacy_and_security_section: 'පුද්ගලිකත්වය සහ ආරක්ෂක අංශය තෝරන්න',
  find_site_settings_section: '"අඩවි සැකසුම්" කොටස සොයා ගන්න',
  find_the_name_of_our_site: 'අපගේ cashx.lk වෙබ් අඩවියේ නම සොයාගෙන කැමරාවට ප්‍රවේශය ලබා දෙන්න',
  find_section_with_camera_settings:
    'කැමරා සැකසුම් සහිත කොටස සොයාගෙන අපගේ වෙබ්' + ' අඩවිය cashx.lk වෙත පිවිසීමට ඉඩ දෙන්න',
  try_refreshing_the_page: 'පළමුව පිටුව නැවුම් කිරීමට උත්සාහ කරන්න',
  using_google_chrome: 'Google Chrome භාවිතා කිරීම',
  using_safari: 'සෆාරි භාවිතා කිරීම',
  using_another_browser: 'වෙනත් බ්‍රවුසරයක් භාවිතා කිරීම',
  i_opened_in_third_party_applications: 'මම තෙවන පාර්ශවීය යෙදුමකින් පිටුව විවෘත කළෙමි',
  if_you_havenot_given_access:
    'ඔබ කැමරාවට ප්‍රවේශය ලබා දී නොමැති නම් සහ එය ඔබටම ලබා දිය ' +
    'නොහැකි නම්, කරුණාකර අපගේ සම්බන්ධතා මධ්‍යස්ථානය අමතන්න',
  continue_registration_in_browser: 'බ්රවුසරයේ ලියාපදිංචිය දිගටම කරගෙන යන්න.',
  seven_days_a_week: 'සතියේ දින හත',
  take_photo_instructions_title: 'පහත උපදෙස් අනුව ඡායාරූපයක් ගන්න',
  take_photo_instructions:
    'ලේඛනය සම්පූර්ණයෙන් අල්ලා ගන්න. හොඳින් ආලෝකමත් ස්ථානයක එය රැගෙන ෆ්ලෑෂ් භාවිතා නොකරන්න. පෙළ හොඳින් කියවිය හැකි බවට වග බලා ගන්න',
  indicate_account_to_send_money: 'කරුණාකර මුදල් එවන ගිණුම් අංකය සඳහන් කරන්න',
  account_number: 'ගිණුම් අංකය',
  i_undertake_accuracy:
    'මම ඉහත බැංකු ගිණුම් විස්තර වල නිරවද්‍යතාවය භාරගෙන එය මගේ පුද්ගලික ගිණුම් අංකය බව තහවුරු කරමි',
  probability_of_loan_approval: 'ණය අනුමත කිරීමේ සම්භාවිතාව',
  call: 'අමතන්න',
  status: 'තත්ත්වය',
  see_more: 'තව බලන්න',
  see_less: 'අඩුවෙන් බලන්න',
  not_found_text: 'ඔබ සොයන පිටුව මකා දමා, එහි නම වෙනස් කර හෝ තාවකාලිකව ලබා ගත නොහැක.',
  back_to_main: 'ප්‍රධාන පිටුවට ආපසු යන්න',
  server_error_text: 'සේවාදායකය නොමැත. මිනිත්තු 15කට පසු නැවත උත්සාහ කරන්න.',
  min_date_err: 'අවම දින සීමාව ළඟා විය',
  max_date_err: 'උපරිම දින සීමාව ළඟා විය',
  min_birthdate_err: 'අවම වයස අවුරුදු 20 විය යුතුය',
  max_birthdate_err: 'උපරිම වයස අවුරුදු 60 විය යුතුය',
  your_loan_has_been_sold: 'ඔබේ ණය පවරා දි ඇත',
  bank_details: 'බැංකු තොරතුරු  ',
  sold_at: 'පවරා දි ඇති',
  company_name: 'සමාගම නම',
  email_sold: 'විද්යුත් තැපෑල',
  good_lighting: 'හොඳ ආලෝකය',
  angle: 'කෝණය',
  focus_and_sharpness: 'අවධානය සහ තියුණු බව',
  avoid_reflection: 'පරාවර්තනයෙන් වළකින්න',
  margins_too_far: 'මායිම් (ඉතා දුරින්)',
  margins_too_close: 'මායිම් (ඉතා සමීප)',
  subjection: 'යටත්වීම',
  resolution_of_the_image: 'රූපයේ විභේදනය',
  contrast: 'පරස්පරතාව',
  show_more: 'තව පෙන්වන්න',
  show_less: 'අඩුවෙන් පෙන්වන්න',
  months: 'මාස',
  exit_modal_title: 'ඔබට පිටවීමට අවශ්‍ය බව විශ්වාසද?',
  exit_modal_yes: 'ඔව් පිටවීම',
  exit_modal_no: 'නැත ,රැඳී සිටින්න',
  users_mismatched: 'පරිශීලක නොගැලපේ',
  this_value_is_not_valid: 'මෙම අගය වලංගු නොවේ',
  this_value_is_already_used: 'මෙම අගය දැනටමත් භාවිතා කර ඇත',
  promocode_is_not_valid: 'ප්‍රවර්ධන කේතය වලංගු නැත',
  contact_collection_team: 'ණය එකතු කිරීමේ දෙපාර්තමේන්තුව සම්බන්ධ කර ගැනීමට මෙතැන ක්ලික් කරන්න',
  contact_repayment_slip: 'ඔබ දැනටමත් ණය ආපසු ගෙවා තිබේද?',
  metatag_login_title: 'දැන් අන්තර්ජාලය හරහා මුදල් ණයක් අවශ්‍යයි. CashX වෙත පිවිසෙන්න.',
  metatag_login_description:
    'දැන් ඉක්මන් මුදල් අවශ්‍යයි - CashX වෙත පිවිසෙන්න. වේගවත් අනුමැතියක් සහ අඩු පොලියක් සහිත ශ්‍රී ලංකාවේ හොඳම අන්තර්ජාල ණය ලබාගන්න.',
  metatag_reg_title: 'අන්තර්ජාලය හරහා මුදල් ණයක් අවශ්‍යයි. CashX හි ලියාපදිංචි වන්න.',
  metatag_reg_description:
    'ඉක්මන් මුදල් අවශ්‍යයි - CashX හි ලියාපදිංචි වන්න. වේගවත් අනුමැතියක් සහ අඩු පොලියක් සහිත ශ්‍රී ලංකාවේ හොඳම අන්තර්ජාල ණය ලබාගන්න.',
  january: 'ජනවාරි',
  february: 'පෙබරවාරි',
  march: 'මාර්තු',
  april: 'අප්රේල්',
  may: 'මැයි',
  june: 'ජූනි',
  july: 'ජුලි',
  august: 'අගෝස්තු',
  september: 'සැප්තැම්බර්',
  october: 'ඔක්තෝම්බර්',
  november: 'නොවැම්බර්',
  december: 'දෙසැම්බර්',
  money_our_partners: 'ඔබට අපගේ හවුල්කරුවන්ගෙන් ණයක් ලබාගත හැක',
  verify_your_identity: 'ඔබේ අනන්‍යතාවය තහවුරු කරන්න',
  please_upload_a_photo_of_your_NIC_card: 'කරුණාකර ඔබගේ ජාතික හැඳුනුම්පතේ ඡායාරූපයක් උඩුගත කරන්න',
  NIC_card_description_text:
    'සියලුම දත්ත පැහැදිලිව පෙනෙන පරිදි ජාතික හැඳුනුම්පතේ ඉදිරිපස ඡායාරූපයක් ගන්න',
  ocr_error: 'දත්ත ලැබී නොමැත. කරුණාකර නැවත උත්සාහ කරන්න',
  application_approved: 'ඔබගේ අයදුම්පත අනුමත වී ඇත!',
  send_photo_of_receipt: 'රිසිට් පතෙහි ඡායාරූපය එවන්න',
  send_photo_of_receipt_whatsapp: 'වට්සඇප් වෙත යොමු කරන්න',
  send: 'යොමු කරන්න',
  repayment_slip_title:
    'ඇමුණුමක් ලෙස ඔබගේ ණය #{ loanId } ගෙවීමේ පත්‍රිකාවේ ඡායාරූපයක් උඩුගත කරන්න, එවිට අපගේ මූල්‍ය දෙපාර්තමේන්තුව එය සත්‍යාපනය කර ඔබේ ණය යාවත්කාලීන කරනු ඇත ',
  attach_your_file: 'ඔබගේ ගොනුව අමුණන්න',
  slip_successfully_sent: 'ඔබගේ ආපසු ගෙවීමේ පත්‍රිකාව සාර්ථකව යවා ඇත.',
  get_more_money: 'වැඩිපුර මුදල් ලබා ගන්න',
  can_get_more_money: 'ඔබට වැඩිපුර මුදල් ලබා ගත හැක, පහත ප්‍රමාණය තෝරන්න',
  increase_loan_up: 'ඔබට රුපියල් { amount } දක්වා ණය වැඩි කර ගත හැක',
  get_settlement_amount: 'රුපියල් { amount } ක් ලබා ගන්න',
  sampath_bank_instruction:
    '<p>බැංකුවේ CDM සම්පත් බැංකුව හරහා ගෙවීමක් සිදු කිරීම සඳහා, ඔබ කළ යුත්තේ:</p>' +
    '<p><strong>• පියවර 1:</strong> කරුණාකර භාෂාව තෝරන්න;</p>' +
    '<p><strong>• පියවර 2:</strong> “CASH DEPOSIT” තෝරන්න;</p>' +
    '<p><strong>• පියවර 3:</strong> ඔබගේ ඉලක්කම්-10 ජංගම දුරකථන අංකය ඇතුලත් කරන්න;</p>' +
    '<p><strong>• පියවර 4:</strong> ඔබගේ ජාතික හැඳුනුම්පත් අංකය { nic } ඇතුලත් කරන්න;</p>' +
    '<p><strong>• පියවර 5:</strong> සම්පත් බැංකු ගිණුම් අංකය 017510007263 ලෙස ඇතුළත් කරන්න;</p>' +
    '<p><strong>• පියවර 6:</strong> ගිණුමේ නම “A V BUSINESS SOLUTION (PRIVATE) LIMITED” ලෙස ස්වයංක්‍රීයව දර්ශනය වන බවට වග බලා ගන්න;</p>' +
    '<p><strong>• පියවර 7:</strong> ඔබේ ජා.හැ අංකය { nic } “REFERENCE NO” ලෙස ඇතුලත් කරන්න.</p>' +
    '<p><strong>• පියවර 8:</strong> තැන්පතු තැටියට මුදල් නෝට්ටු තබන්න (රු. 100 සහ ඊට වැඩි මුදල් නෝට්ටු පමණක් භාවිතා කළ හැක);</p>' +
    '<p><strong>• පියවර 9:</strong> ඔබ තැන්පත් කළ මුදල තිරය මත පෙන්වනු ඇත. නිවැරදි නම් “ඉදිරියට යන්න” ඔබන්න;</p>' +
    '<p><strong>• පියවර 10:</strong> යන්ත්‍රයෙන් ගෙවීම් කුවිතාන්සිය ගෙන එය තබා ගන්න.</p>',
  any_bank_instruction:
    '<p>ගෙවීම සිදු කිරීමේදී ඔබගේ ණය ගිවිසුම් අංකය හෝ ජාතික හැඳුනුම්පත් අංකය ඔබගේ යොමු අංකය ලෙස ගෙවීම් පත්‍රිකාවේ සඳහන් කරන්න.</p>' +
    '<p>ඔබ හට ගෙවීමට අවශ්‍ය මුදල සඳහන් කරන්න.</p>' +
    '<p>ගෙවීම මුදල් අයකැමි හට ලබා දෙන්න.</p>',
  account_holder_name: 'ගිණුම් හිමියාගේ නම',
  your_nic_number: 'ඔබගේ ජාතික හැඳුනුම්පත් අංකය',
  your_loan_number: 'ඔබේ ණය අංකය',
  sampath_bank_subtitle:
    'කරුණාකර සම්පත් බැංකු මුදල් තැම්පතු පත්‍රය පහත ගිණුම් විස්තර සමග සම්පූර්ණ කර මුදල් ලබාගන්නා අයකැමියා හට ලබා දෙන්න:',
  cargills_bank_subtitle:
    'කරුණාකර කාර්ගීල්ස් බැංකු මුදල් තැම්පතු පත්‍රය පහත ගිණුම් විස්තර සමග සම්පූර්ණ කර මුදල් ලබාගන්නා අයකැමියා හට ලබා දෙන්න:',
  hatton_bank_subtitle:
    'කරුණාකර හැටන් නැෂනල් බැංකු මුදල් තැම්පතු පත්‍රය පහත ගිණුම් විස්තර සමග සම්පූර්ණ කර මුදල් ලබාගන්නා අයකැමියා හට ලබා දෙන්න:',
  any_bank_title: 'ඕනෑම බැංකුවක් හරහා අරමුදල් මාරු කිරීම',
  any_bank_subtitle: 'ඔබට ඕනෑම බැංකුවක අයකැමි හරහා පහත ගිණුම් අංක වලට මුදල් ප්‍රේශණය කල හැක:',
  note: 'සටහන',
  repay_note_text:
    'ඔබ ආපසු ගෙවීමක් කරන සෑම විටම, ඔබේ ජාතික හැඳුනුම්පත හෝ ණය හැඳුනුම්පත සමඟ WhatsApp හරහා අපගේ දුරකථන අංක 074-2478024 වෙත ඔබේ ගෙවීම් පත්‍රිකාවේ පැහැදිලි ස්ලිප් ඡායාරූපයක් හෝ තිර රුවක් අපට එවන්න.',
  repayment_on_website: 'අපගේ <a>වෙබ් අඩවියෙන්</a> ඔබට ණය ආපසු ගෙවීමේ වෙනත් විකල්ප තෝරා ගත හැකිය.',
  webview_modal_title: 'උපදෙස්',
  webview_modal_subtitle:
    'සාර්ථක හඳුනා ගැනීම සඳහා, අපි ඔබගේ පෙරනිමි ජංගම බ්‍රවුසරයට මාරු වීම නිර්දේශ කරමු',
  webview_android_text:
    '1. ඔබගේ ෆේස්බුක් බ්‍රවුසරයේ ක්ලික් කරන්න { dotsIcon } අයිකනය මත ඉහළ දකුණු කෙළවරේ',
  webview_ios_text:
    '1. ඔබගේ ෆේස්බුක් බ්‍රවුසරයේ ක්ලික් කරන්න { dotsIcon } හි නිරූපකය පහළ දකුණු කෙළවරේ',
  webview_text_2: '2. තෝර { browserIcon } කරන්න පහළ පත්‍ර මෙනුවේ බ්‍රව්සර් විකල්පය',
  another_browser_instruction:
    'පිටුව යාවත්කාලීන කර ඔබගේ බ්‍රවුසරයේ හෝ දුරකථන සැකසුම්  (Phone Settings)  තුළ කැමරාවට ප්‍රවේශය විවෘත කිරීමට උත්සාහ කරන්න',
  go_to_mobile_settings: 'ඔබගේ ජංගම සැකසුම් වෙත යන්න',
  find_the_safari: '"සෆාරි" සොයන්න',
  find_the_camera_section: '"කැමරාව" කොටස සොයා ගන්න',
  choose_allow_in_camera_access: 'කැමරා ප්‍රවේශයේ "ඉඩ දෙන්න" තෝරන්න',
  repay_pay_go:
    'දැන් ඔබට දිවයින පුරා ඕනෑම PaynGo Kiosks යන්ත්‍ර හරහා හෝ Payngo වෙබ් අඩවිය හරහා දවසේ ඕනෑම වේලාවක ඔබේ CashX ණය ඉක්මනින් සහ පහසුවෙන් ගෙවිය හැක.',
  pay_online_pay_go:
    'PaynGo වෙබ් <a>අඩවිය</a> හරහා මාර්ගගතව ගෙවීමට කරුණාකර මෙම සබැඳිය එක භාවිතා කරන්න',
  closest_kiosk_pay_go: 'ආසන්නතම කියෝස්ක් සොයා ගැනීමට කරුණාකර මෙම <a>සබැඳිය</a> භාවිතා කරන්න',
  bank_details_modal_title: 'කරුණාකර ඔබගේ බැංකු විස්තර පරීක්ෂා කරන්න',
  change_bank_details: 'බැංකු විස්තර වෙනස් කරන්න',
  call_support: 'සහාය අමතන්න',
  please_call_support: 'ඔබගේ බැංකු විස්තර වෙනස් කිරීමට කරුණාකර <a>{ phone }</a> සහාය අමතන්න',
  send_loan_agreement: 'අපි ඔබේ ණය ගිවිසුම මෙම ඊමේල් ලිපිනයට එවන්නෙමු.',
  capture_selfie: 'සෙල්ෆි ගන්න',
  add_email: 'විද්‍යුත් තැපෑල එක් කරන්න',
  add_email_subtitle:
    'වට්ටම් සහ තනි දීමනා පිළිබඳ යාවත්කාලීන ලබා ගැනීමට කරුණාකර ඔබගේ විද්‍යුත් තැපෑල එක් කරන්න',
  email_is_verified: 'ඔබගේ විද්‍යුත් තැපෑල සත්‍යාපනය කර ඇත',
  confirm_your_email: 'කරුණාකර ඔබේ ඊමේල් එක තහවුරු කරන්න',
  payment_successful: 'ගෙවීම සාර්ථකයි!',
  payment_successfully_processed: 'ඔබේ ගෙවීම සාර්ථකව සකසා ඇත',
  we_sent_email:
    'විද්‍යුත් තැපැල් සත්‍යාපනය සම්පූර්ණ කිරීම සඳහා අපි ඔබට ඊමේල් එකක් එවා ඇත { userEmail }.',
  open_email: 'කරුණාකර විද්‍යුත් තැපෑල විවෘත කර උපදෙස් අනුගමනය කරන්න',
  we_have_got_your_email_address: 'අපි ඔබගේ ඊමේල් ලිපිනය ලබා ගත්තෙමු!',
  open_the_email: 'කරුණාකර ඊමේල් එක විවෘත කර උපදෙස් පිළිපදින්න',
  please_confirm_your_email:
    'පුද්ගලික දීමනා ලබා ගැනීමට කරුණාකර ඔබගේ ඊමේල් ලිපිනය <a>{ userEmail }</a> තහවුරු කරන්න',
  we_sent_email_and_open:
    'අපි <a>{ userEmail }</a> ට ඔබට ඊමේල් එකක් එවලා ඇත. කරුණාකර ඊමේල් එක විවෘත කර උපදෙස් පිළිපදින්න',
  confirm_an_email: 'ඊමේල් එක තහවුරු කරන්න',
  number_is_registered: 'අංකය ලියාපදිංචි වී ඇත',
  number_is_registered_text:
    'හිතවත් පාරිභෝගිකයිනි, දැනට පවතින ජාතික හැඳුනුම්පතක් සමඟ ගිණුමක් ලියාපදිංචි කිරීමට ඔබ කළ පෙර උත්සාහය සැක සහිත ලෙස සැලකිය හැකිය. එහි ප්‍රතිඵලයක් වශයෙන්, ඔබට මෙම දුරකථන අංකය භාවිතයෙන් ලියාපදිංචිය ඉදිරියට ගෙන යා නොහැක. ඔබගේ පැරණි අංකය – <a>{ phoneNumber }</a> සමඟින් පුරනය වීම අපි නිර්දේශ කරමු.',
  nic_number_is_registered: 'ජාතික හැඳුනුම්පත් අංකය සහිත ගිණුම ලියාපදිංචි වී ඇත',
  has_overdue_loan:
    'ඔබගේ ජාතික හැඳුනුම්පත් අංකය සහිත ගිණුමක් දැනටමත් නිර්මාණය කර ඇත, සහ ඔබට ගෙවීමට පෙර ඇති ණයක් තිබේ. කරුණාකර ඔබේ පෙර අංකය {{matched_user_phone}} පරිහරණය කර <a1>log in</a1> වී ඔබේ ණය ගෙවන්න. නව ගිණුම මිනිත්තුවක් තුළ අවහිර වනු ඇත.',
  has_sold_loan:
    'ඔබගේ ජාතික හැඳුනුම්පත් අංකය සහිත ගිණුමක් දැනටමත් නිර්මාණය කර ඇති අතර, ඔබේ ණය විකුණා ඇත. කරුණාකර වැඩි විස්තර සොයා ගැනීමට ඔබේ පෙර අංකය {{matched_user_phone}} පරිහරණය කර <a1>log in</a1> වන්න. නව ගිණුම මිනිත්තුවක් තුළ අවහිර වනු ඇත.',
  in_black_list:
    'ඔබගේ ජාතික හැඳුනුම්පත් අංකය සහිත ගිණුමක් දැනටමත් නිර්මාණය කර ඇත. මෙම මොහොතේ ඔබට නැවත ණයක් ඉල්ලීමට නොහැක. කරුණාකර අපගේ හවුල්කරුවන්ගෙන් ණයක් ලබා ගැනීමට උත්සාහ කරන්න.',
  has_moratorium:
    'ඔබගේ ජාතික හැඳුනුම්පත් අංකය සහිත ගිණුමක් දැනටමත් නිර්මාණය කර ඇති අතර, ඔබ ඉල්ලුම්පත් යොමු කර ඇත. වැඩි විස්තර සඳහා කරුණාකර ඔබේ පසුගිය දුරකථන අංකය {{matched_user_phone}} භාවිතා කරමින් <a1>පිවිසෙන්න</a1>. මෙම නව ගිණුම විනාඩියකින් අවහිර කරනු ඇත.',
  please_reload_page:
    'කැමරා ප්‍රවේශය අවශ්‍යයි. පිටුව නැවත පූරණය කර, කැමරා ප්‍රවේශය ලබා දෙන්න, සෙල්ෆි එකක් නැවත ගන්න.',
  reload_page: 'පිටුව නැවත පූරණය කරන්න',
  // BioId
  NoFaceFound: 'කරුණාකර ඔබේ මුහුණ පැහැදිලිව පෙනෙන ලෙස රූපරාමුව ගන්න, නැවත උත්සාහ කරන්න.',
  MultipleFacesFound: 'කරුණාකර තොරතුරු ලබා දීමේදී පමණක් ඔබේ මුහුණ පෙන්වන්න, නැවත ඡායාරූප ගන්න.',
  CannotCropImage:
    'ඔබේ මුහුණ රූපරාමුව මැදට ගෙන අවට ප්‍රමාණවත් ඉඩක් ඇතැයි සහතික කරමින් නැවත ඡායාරූප ගන්න.',
  IrisNotFound:
    'ඔබේ මුහුණ සෘජුව හා දෙනෙත් පැහැදිලිව පෙනෙන ආකාරයෙන් රූපරාමුව ගන්න, නැවත උත්සාහ කරන්න.',
  ImageTooSmall:
    'රූපරාමුව තුළ ඔබේ මුහුණ ප්රමාණවත් තරම් විශාල බව සහතික කර, පසුව නැවත ඡායාරූපය ගන්න.',
  WrongViewingDirection: 'සෘජුව කැමරාව දෙස බලා ඔබේ හිස කෙළින් තබා ගන්න, ඉන්පසු ඡායාරූපය නැවත ගන්න.',
  HeadRotatedTooFar: 'ඔබේ හිස සෘජුව තබාගෙන ඉදිරියට මුහුණලා, පසුව නැවත ඡායාරූපය ගන්න.',
  FaceAsymmetry: 'ඔබේ මුහුණේ ආලෝකය ඒකාකාරව තබාගෙන සෘජු ඉරියව්වක් පවත්වා ගෙන නැවත උත්සාහ කරන්න.',
  ImageTooBlurry:
    'ඔබේ කැමරාව ස්ථාවරව තබා ඇති බව සහතික කරමින් රූපරාමුව පැහැදිලිව පෙනෙන විදිහට නැවත ඡායාරූප ගන්න.',
  BadFaceBrightness: 'ඔබේ මුහුණට ඒකාකාරව  ආලෝකය යොමුකරන්න, ඉන්පසු ඡායාරූපය නැවත ගන්න.',
  FaceContrastTooHigh: 'ඔබේ මුහුණට පැහැදිලි  හොඳ ආලෝකයක් ලබා ගන්න, නැවත ඡායාරූප ගන්න.',
  FaceContrastTooLow: 'ආලෝකය වැඩි හෝ මීට වැඩි ආලෝකයක් ඇති ස්ථානයකට ගොස් ඡායාරූප ගන්න.',
  ImageOverExposure: 'ආලෝකය අඩු කර හෝ ඔබේ කැමරාවේ සෙටින්ස් හදා ගැනීමෙන් ඡායාරූප ගන්න.',
  ImageUnderExposure: 'ආලෝකය වැඩි කරන්න හෝ දීප්තිමත් ප්‍රදේශයකට යන්න, ඉන්පසු ඡායාරූපය නැවත ගන්න.',
  FaceTooDark: 'ආලෝකයක් ඇති ස්ථානයකට ගොස් ඔබේ මුහුණ පැහැදිලිව පෙනෙන ලෙස ඡායාරූප ගන්න.',
  FaceTooBright: 'ඔබේ මුහුණට ආලෝකය අඩු කර නැවත ඡායාරූප ගන්න.',
  BadGrayscaleDensity:
    'ඔබේ මුහුණේ වර්ණ තීව්‍රතාවය සමතුලිත වන පරිදි ආලෝකය සකසන්න, ඉන්පසු ඡායාරූපය නැවත ගන්න.',
  ImageTooOld: 'ඔබේ මුහුණ පැහැදිලිව පෙනෙන ලෙස නව ඡායාරූපයක් ගන්න.',
  MissingTimeStamp: 'ඔබේ කැමරාවේ සෙටින්ස් නිවැරදි බවට සහතික කර නව ඡායාරූප ගන්න.',
  ImageWayTooSmall:
    'ඉහළ විභේදනයක් භාවිතා කර රූපරාමුව තුළ ඔබේ මුහුණ ප්‍රමාණවත් බව සහතික කර ගෙන නැවත ඡායාරූපය ගන්න.',
  BadImageBrightness: 'ඡායාරූපය සඳහා නිවැරදි ආලෝකය ලබා දෙන්න, නැවත ඡායාරූප ගන්න.',
  ImageContrastTooHigh: 'පින්තූරයේ විෂමතාව අඩු කර නැවත උත්සාහ කරන්න.',
  ImageContrastTooLow: 'පින්තූරයේ විෂමතාව වැඩි කර නැවත උත්සාහ කරන්න.',
  ImageTooDark: 'දීප්තිමත් ප්‍රදේශයකට ගොස් නැවත ඡායාරූපය ගන්න.',
  ImageTooBright: 'ආලෝකය අඩු කර හෝ  අඩු එලියක් ඇති ප්‍රදේශයකට ගොස් නැවත ඡායාරූපය ගන්න.',
  UnsuitableImage: 'පින්තූරයේ මුව සම්පූර්ණයෙන්ම දිස්වන බවට සහතික කර නැවත උත්සාහ කරන්න.',
  PassiveLiveDetectionFailed: 'ඔබ කැමරාවේ ඉදිරිපිට සිට, ස්වභාවික චලනයක් ඇතිව නැවත උත්සාහ කරන්න.',
  phone_confirmation: 'දුරකථන තහවුරු කිරීම',
  we_sent_confirmation_code:
    'අපි ඔබගේ පැරණි දුරකථන අංකයට තහවුරු කිරීමේ කේතයක් යවා ඇත, ඔබගේ අනන්‍යතාවය තහවුරු කිරීමට එය ඇතුලත් කරන්න.',
  please_contact_call_center:
    'දත්ත තහවුරු කිරීමට සහ යාවත්කාලීන කිරීමට කරුණාකර අපගේ ඇමතුම් මධ්‍යස්ථානය <a>{ support_service_phone }</a> අමතන්න',
  old_number: 'පැරණි අංකය +94 { old_number }',
  new_number: 'නව අංකය +94 { new_number }',
  select_phone_number: 'දුරකථන අංකය තෝරන්න',
  select_which_phone_number: 'ඔබට අනාගතයේදී භාවිතා කිරීමට අවශ්‍ය දුරකථන අංකය තෝරන්න.',

  //referrals
  earn_for_each_friend_you_invite: 'ඔබ ආරාධනා කරන සෑම මිතුරෙකු සදහාම RS 1000ක් උපයන්න!',
  earn_for_each_friend_you_invite_text:
    'ඉතිරි ආරාධනා වලින් එකක් භාවිතා කර ආරාධනා කරන සෑම මිතුරෙකු සදහාම RS 1000ක් දක්වා උපයා ගන්න. ඔබේ අද්විතීය යොමු සබැඳිය ඔවුන් සමඟ බෙදා ගන්න.',
  how_to_get_bonuses: 'නස් ලබා ගන්නේ කෙසේද',
  more_info: 'වැඩි විස්තර',
  referral_modal_title:
    'CashX වෙතින් නව යොමු කිරීමේ වැඩසටහන හඳුන්වා දීමට අපි සතුටු වෙමු අපගේ ගනුදෙනුකරුවන් සඳහා පමණක්!',
  referral_modal_text1:
    '<p>දැන්, ඔබට ඔබේ ධනාත්මක CashX අත්දැකීම මිතුරන් සමඟ බෙදා ගත හැකිය ආපසු ප්රසාද දීමනා ලබා ගන්න.</p>' +
    '<p>පහත කොන්දේසි ප්‍රවේශමෙන් සමාලෝචනය කර මිතුරන්ට ආරාධනා කිරීම ආරම්භ කරන්න.ඔබගේ අද්විතීය යොමු සබැඳිය. ඔබට ප්‍රසාද දීමනා පිරිනැමීමට අපි සූදානම්.</p>',
  campaign_period: 'ප්‍රචාරක කාලය:',
  referral_modal_text2: '<p>සැප්තැම්බර් 1, 2024 - දෙසැම්බර් 31, 2024</p>',
  how_to_invite_a_friend: 'මිතුරෙකුට ආරාධනා කරන්නේ කෙසේද?',
  referral_modal_text3:
    '<p>1. ඔබ අපගේ ගනුදෙනුකරු විය යුතු අතර අවම වශයෙන් එක් සක්‍රීය/සංවෘත ණයක්.</p>' +
    '<p>2. ඔබගේ පුද්ගලික ගිණුමේ, "යොමු කිරීමේ වැඩසටහන" කොටස වෙත ගොස්, ඔබගේ අද්විතීය යොමු සබැඳිය පිටපත් කර, එය ඔබගේ මිතුරා වෙත යවන්න.</p>',
  how_to_earn_a_reward: 'ත්‍යාගයක් උපයා ගන්නේ කෙසේද?',
  referral_modal_text4:
    '<p>ඔබගේ යාළුවා පළමු ණය මුදල ලබාගෙනනියමිත දින ඇතුලත ගෙවීම් සිදු කර යුතු අතර, උපරිම දින 07කට වඩා ගෙවීම් ප්‍රමාද නොවිය යුතුය. මෙයින් පසු, ප්‍රසාද මුදල් ඔබේ ප්‍රසාද ගිණුමට බැර කෙරේ.</p>' +
    '<p>ඔබේ මිතුරාට පළමු ණය සඳහා ප්‍රසාද දීමනාවක් ද ලැබෙනු ඇත - 50% වට්ටමක් සහිත ප්‍රවර්ධන කේතය. ප්‍රවර්ධන කේතය යොමු කිරීමේ සබැඳිය සමඟ යවනු ලැබේ. මෙය සිදු කිරීම සඳහා ඔබ SMS හෝ ඊමේල් හරහා ඔබගේ පුද්ගලික ගිණුමේ ආරාධනය භාවිතා කළ යුතුය.</p>',
  key_points: 'ප්රධාන කරුණු:',
  referral_modal_text5:
    '<p>• ඔබගේ මිතුරා මීට පෙර අප සමඟ ලියාපදිංචි වී නොමැති බවට වග බලා ගන්න.</p>' +
    '<p>• වැඩසටහන සඳහා සුදුසුකම් ලැබීමට සහ ත්‍යාගයක් ලබා ගැනීමට, ඔබගේ යොමු සබැඳිය භාවිතයෙන් ඔබේ මිතුරා ඔවුන්ගේ ලියාපදිංචියආරම්භ කළ යුතුය.</p>' +
    '<p>• ඔබට ඔබගේ පුද්ගලික ගිණුමේ "යොමු කිරීමේ වැඩසටහන" කොටසේ ඔබගේ යවන ලද ආරාධනා නිරීක්ෂණය කර ඔබගේ ප්‍රසාද ගිණුම් ශේෂය පරීක්ෂා කළ හැක.</p>',
  how_to_use_your_bonuses: 'ඔබේ බෝනස් භාවිතා කරන්නේ කෙසේද?',
  referral_modal_text6:
    '<p>• ඔබේ පුද්ගලික ගිණුමේ "ක්රෙඩිට්" හෝ "යොමු කිරීමේ වැඩසටහන" කොටසේ ණයක් සඳහා අයදුම් කිරීමේදී පමණක් බෝනස් භාවිතා කළ හැකිය.</p>' +
    '<p>• ගාස්තු ගෙවීමට ඔබට බෝනස් භාවිතා කළ හැකිය. ණයෙහි මූලික මුදල සඳහා බෝනස් අදාළ නොවේ.</p>',
  important_notes: 'වැදගත් සටහන්:',
  referral_modal_text7:
    '<p>• ඔබට සක්‍රිය ණයක් ඇති අතරතුර ඔබට බෝනස් ලැබෙන්නේ නම්, එම ණය සඳහා ඔබට ඒවා භාවිතා කිරීමට නොහැකි වනු ඇත. ඔබ වත්මන් ණය ආපසු ගෙවිය යුතු අතර පසුව නව ණය අයදුම්පතක් සඳහා බෝනස් අයදුම් කළ යුතුය.</p>' +
    '<p>• ප්‍රසාද දීමනා ප්‍රමාණය ගාස්තු ඉක්මවා ඇත්නම්, ඉතිරි බෝනස් ඊළඟ ණය සඳහා භාවිතා කළ හැකිය.</p>' +
    '<p>• ප්‍රසාද දීමනා සහ ප්‍රවර්ධන කේත එකම ණයකදී එකට භාවිතා කළ නොහැක.</p>' +
    '<p>• ඔබට දින 30කට වඩා කල් ඉකුත් වූ ණයක් තිබේ නම් අදාළ බෝනස් අවලංගු කෙරේ.</p>',
  start_sharing_today: 'අදම බෙදාගැනීම ආරම්භ කර CashX පාරිභෝගිකයෙකු වීමේ ප්‍රතිලාභ භුක්ති විඳින්න!',
  copy_the_link: 'සබැඳිය පිටපත් කර ඔබේ මිතුරන් සමඟ බෙදා ගන්න',
  copy: 'පිටපත්',
  share_via: 'හරහා බෙදාගන්න:',
  share_via_email: 'ඊමේල්',
  your_achievements: 'ඔබේ ජයග්රහණ',
  available_bonuses: 'ප්‍රමාණවත් ත්‍යාග',
  registered_by_invitation: 'ආරාධනයකින් ලියාපදිංචි වී ඇත',
  invited: 'පෙලඹවූ',
  completed: 'සම්පූර්ණයි',
  available_bonuses_text: 'ණයක් සඳහා අයදුම් කිරීමේදී භාවිතා කළ හැකි බෝනස්',
  registered_by_invitation_text: 'අද්විතීය, ලියාපදිංචි ආරාධනා මුළු ගණන',
  completed_text: 'ණය ලබාගත් සහ ගෙවූ මිතුරන් ගණන',
  link_is_copied: 'සබැඳිය පිටපත් කර ඇත',
  add_friend_email: 'ඔබේ මිතුරගේ විද්යුත් තැපැල් ලිපිනය එක් කරන්න',
  add_friend_phone: 'ඔබගේ මිතුරන්ගේ දුරකථන අංකය එක් කරන්න',
  add_friend_email_subtitle:
    'කරුණාකර ඔබගේ මිතුරන්ගේ විද්‍යුත් තැපෑල එක් කරන්න එවිට අපට ආරාධනාවක් එවිය හැක',
  add_friend_phone_subtitle:
    'කරුණාකර ඔබගේ මිතුරන්ගේ දුරකථන අංකය එක් කරන්න එවිට අපට ආරාධනාවක් එවිය හැක.',
  send_an_invite: 'ආරාධනාවක් යවන්න',
  invitation_sent: 'ආරාධනා යවන්න!',
  invitation_sent_sms: 'sms ගැන ඔබේ මිතුරා දැනුවත් කරන්න.',
  invitation_sent_email: 'ඊමේල් ගැන ඔබේ මිතුරා දැනුවත් කරන්න.',
  you_have_on_your_balance: 'ඔබ ඔබේ ශේෂය මත ඇත:',
  apply_loan_and_use_bonuses: 'ණයක් සඳහා අයදුම් කර ඔබේ බෝනස් භාවිතා කරන්න!',
  use_bonuses_for_fees: 'ගාස්තු ගෙවීමට බෝනස් භාවිතා කරන්න',
  or: 'හෝ',
  apply_promocode: 'ප්‍රවර්ධන කේතය',
  use_bonuses: 'බෝනස්',
  nic_error: 'ඔබගේ ජාතික හැඳුනුම්පත් අංකය නිවැරදි බව තහවුරු කරගන්න',
  pay_the_debt_immediately: 'නීතිමය ප්රතිවිපාක වළක්වා ගැනීම සඳහා, වහාම ණය ගෙවන්න',
  photo_example: 'ඡායාරූප උදාහරණය',
  install_app_desktop: 'CashX අඩවි කෙටිමඟ ඔබේ ඩෙස්ක්ටොප් එකට එක් කරන්න',
  install_app_mobile: 'CashX අඩවියේ කෙටිමඟ ඔබගේ ප්‍රධාන තිරයට සුරකින්න',
  month: 'මාසය',
  year: 'වසර',
  agreement_loading: 'පූරණය වෙමින් පවතී. කරුණාකර රැඳී සිටින්න...',
  do_not_have_an_account: 'ගිණුමක් නොමැතිද?',
  please_contact_call_center_login:
    'ඔබගේ පුද්ගලික ගිණුමට පිවිසීමට සහ ප්‍රවේශ වීමට, කරුණාකර අපගේ ඇමතුම් මධ්‍යස්ථානය අමතන්න ',
  you_need_money: 'ඔබට මුදල් අවශ්‍යද?',
  can_get_a_loan: 'ඔබට අපගේ හවුල්කරුවන්ගෙන් ණයක් ලබා ගත හැකිය',
  get_a_loan: 'ණයක් ලබා ගන්න',
  we_sent_sms: 'අපි ඔබට කේතය සමඟ කෙටි පණිවුඩයක් එවා ඇත <span>{ userPhone }</span>',
  increase_your_loan_amount: 'ඔබේ ණය මුදල රුපියල් { amount } දක්වා වැඩි කරන්න!',
  credit_bureau_report_title: 'ණය අනුමැතිය ලබා ගැනීමට ඔබට ඇති අවස්ථා වැඩි දියුණු කරන්න!',
  credit_bureau_report_description:
    'ණය අනුමත කිරීමේ අවස්ථා වැඩි දියුණු කිරීම සඳහා කරුණාකර CRIB ද්වාරයෙන් (<a>{ linkText }</a>) ඔබේ වාර්තාවට සබැඳියක් සහ මුරපදයක් එක් කරන්න!',
  credit_bureau_report_link: 'වාර්තාවට සබැඳිය',
  credit_bureau_report_password: 'වාර්තාව බැලීම සඳහා මුරපදය',
  credit_bureau_report_button: 'වාර්තාවක් යවන්න',
  credit_bureau_report_success_title: 'සාර්ථකව යවා ඇත!',
  credit_bureau_report_success_description:
    'තොරතුරු සඳහා ස්තූතියි! අපගේ කණ්ඩායම ඉක්මනින් ඔබගේ වාර්තාව සමාලෝචනය කරනු ඇත.',
  field_err_invalid_url: "අවලංගු URL ආකෘතියක්. සබැඳිය 'https://' වලින් ආරම්භ වන බව සහතික කර ගන්න",
  loan_has_been_activated: 'ආයුබෝවන්! ඔබේ ණය සක්‍රීය කර ඇත!',
  add_notification_to_calendar:
    'ඔබගේ Google දින දර්ශනයට දැනුම්දීමක් එක් කර නියමිත වේලාවට ණය ගෙවීමට අමතක නොකරන්න.',
  add_notification: 'දැන්වීම එකතු කරන්න',
  paid_early: 'ඔබ කලින් ණය ගෙවා ඇත!',
  remove_the_reminder: 'කරුණාකර ඔබගේ දින දර්ශනයෙන් සිහිකැඳවීම ඉවත් කිරීමට අමතක නොකරන්න.',
  successfully_extended_loan: 'ඔබ ඔබේ ණය මුදල සාර්ථකව දීර්ඝ කර ඇත!',
  remove_the_previous_reminder: 'කරුණාකර ඔබගේ දින දර්ශනයෙන් පෙර සිහිකැඳවීම ඉවත් කරන්න.',
  google_notification_title: 'අවධානය! CashX හි ඔබේ ණය { loanId } හි නියමිත දිනය අදයි! ',
  google_notification_details:
    'ආයුබෝවන්! අද ඔබේ ණය මුදල ගෙවිය යුතු දිනය බව අපි ඔබට මතක් කරමු. රුපියල් { accruedTotal } ප්‍රමාණය ගෙවන්න හෝ ඔබට තව ටිකක් කාලය අවශ්‍ය නම්, ණය කාලය දින 5-30 දක්වා දීර්ඝ කර, රුපියල් { minSumProlongation } ගෙවන්න ! ඔබට වඩාත් ගැලපෙන විකල්පය තෝරන්න!',
};
