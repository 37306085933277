import { useTranslation } from 'react-i18next';
import { TabType } from 'models';

interface BonusButtonsProps {
  bonusTabType: TabType;
  handleChangeBonusType: (type: TabType) => void;
}

export const BonusButtons = (props: BonusButtonsProps) => {
  const { bonusTabType, handleChangeBonusType } = props;

  const { t } = useTranslation();

  return (
    <div className='flex flex-nowrap align-items-center gap-2 pt-3 mb-1 border-top-light'>
      <button
        type='button'
        className={`txt-xs txt-sb cursor-pointer btn-tertiary with-border w-100 ${bonusTabType === TabType.Promo ? 'current' : ''}`}
        onClick={() => handleChangeBonusType(TabType.Promo)}
      >
        {t`apply_promocode`}
      </button>

      <span className='txt txt-sb'>{t`or`}</span>

      <button
        type='button'
        className={`txt-xs txt-sb cursor-pointer btn-tertiary with-border w-100 ${bonusTabType === TabType.Bonuses ? 'current' : ''}`}
        onClick={() => handleChangeBonusType(TabType.Bonuses)}
      >
        {t`use_bonuses`}
      </button>
    </div>
  );
};
