import { createAsyncThunk } from '@reduxjs/toolkit';
import { $axios, getUserID } from 'core/services';
import { Actions } from 'models';

export const verifiedNICFront = createAsyncThunk<{
  status: boolean;
  need_repeat: boolean;
  need_recreate: boolean;
}>(Actions.VERIFIED_NIC_FRONT, async (_, { rejectWithValue, dispatch }) => {
  const config = {
    method: 'GET',
    url: `/users/${getUserID()}/is_nic_verified`,
  };

  try {
    return await $axios(config, dispatch);
  } catch (errors) {
    return rejectWithValue(errors);
  }
});

export const uploadFileByType = createAsyncThunk<any, { files: Blob; type: string }>(
  Actions.UPLOAD_FILE_BY_TYPE,
  async ({ files, type }, { rejectWithValue, dispatch }) => {
    const config = {
      method: 'POST',
      url: `/users/${getUserID()}/${type}`,
      data: files,
      headers: {
        'Content-Type': 'image/png',
      },
    };

    try {
      return await $axios(config, dispatch);
    } catch (errors) {
      return rejectWithValue(errors);
    }
  },
);

export const getFileByType = createAsyncThunk<Blob, string>(
  Actions.GET_FILE_BY_TYPE,
  async (type, { rejectWithValue, dispatch }) => {
    const config = {
      method: 'GET',
      url: `/users/${getUserID()}/${type}`,
      responseType: 'blob' as const,
    };

    try {
      return await $axios(config, dispatch);
    } catch (errors) {
      return rejectWithValue(errors);
    }
  },
);
