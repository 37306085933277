import { useLocation } from 'react-router-dom';
import { Trans, useTranslation } from 'react-i18next';
import { FieldErrors } from 'react-hook-form/dist/types/errors';

import { Button, ModalLayout } from 'core/components/common';
import { setRedirect } from 'core/store/actions';

import { formattingPhone } from 'utils/functions';
import { useAppDispatch } from 'hooks';
import { AuthFormModel, CommonServerErrorMessage } from 'components';
import { ENGLISH_LANGUAGE } from 'constantsLk';

interface ConfirmNumberProps {
  phone: string;
  loading?: boolean;
  errors: FieldErrors<AuthFormModel>;
  isLoginPage?: boolean;
}

export const ConfirmNumberModal = (props: ConfirmNumberProps) => {
  const { phone, loading, errors, isLoginPage } = props;
  const dispatch = useAppDispatch();
  const location = useLocation();
  const { t } = useTranslation();
  const resetPhonePath = location.pathname.includes(ENGLISH_LANGUAGE.value)
    ? location.pathname.slice(ENGLISH_LANGUAGE.value.length + 1)
    : location.pathname;

  const handleClose = () => {
    dispatch(setRedirect({ to: resetPhonePath, replace: true, state: { isOpenModal: false } }));
  };

  return (
    <ModalLayout
      onClose={handleClose}
      isOpenModal={!!location.state?.isOpenModal}
      title={isLoginPage ? t`number_not_registered` : t`check_your_phone_number`}
      isShowClose
      isUsePortal={false}
      content={
        isLoginPage ? (
          <>
            <span className='mb-2 txt txt-r'>
              <Trans
                i18nKey='please_check_number_again'
                values={{ phone: formattingPhone(phone) }}
                components={{
                  a: <span className='txt-primary txt-sb' />,
                }}
              />
            </span>
          </>
        ) : (
          <>
            <span className='txt txt-r flex-column'>
              {phone && (
                <span className='txt-md-18 txt-sb mb-2 txt-primary'>{formattingPhone(phone)}</span>
              )}
              {t('enter_your_phone')}
            </span>
          </>
        )
      }
      footer={
        <>
          <>
            <Button
              disabled={loading}
              classes={{ root: `btn--primary w-100 mb-4 ${!isLoginPage ? 'next-step' : ''}` }}
            >
              {t('continue')}
            </Button>
            <Button type='button' className='link flex-center' onClick={handleClose}>
              {t(`${isLoginPage ? 'change_number' : 'no_change_number'}`)}
            </Button>
          </>
          {errors?.root && (
            <CommonServerErrorMessage
              classError={'mt-2'}
              message={errors?.root?.serverError.message}
            />
          )}
        </>
      }
    />
  );
};
