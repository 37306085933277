import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Slider } from 'core/components/common';
import { getUserID, sendDataLayer } from 'core/services';
import { onlyNumbers } from 'core/utils/parsers';

import { Loan, LoanCalculatorFields, User } from 'models';
import {
  GTM_LABEL_LOAN_PARTIAL_REPAYMENT,
  GTM_LABEL_LOAN_REPAYMENT,
} from 'utils/analitics/gtmLabel';
import { RepayOfflineModalContainer } from 'components/modals/RepayOfflineModal';
import { ORIGIN_LOCATION, SLIDER_THEME } from 'constantsLk';

interface RepaymentProps {
  activeCreditData: Loan;
  userInfo?: User | null;
}

const floor = (a: number, b: number) => Math.floor(a / b) * b;

function createArrayForSlider(leftAmount: number, step: number, rightAmount: number): number[] {
  const arr = [];
  const roundedRightAmount = floor(+rightAmount, step);
  const lastStep = roundedRightAmount / step;

  for (let i = 2; i <= lastStep; i++) {
    arr.push(leftAmount * i);
  }

  return [+leftAmount, ...arr, +rightAmount];
}

const PaymentProviders = {
  pay_and_go: 'Pay&Go',
  marx: 'MARX',
};

export const Repayment = ({ activeCreditData }: RepaymentProps) => {
  const { accrued_total } = activeCreditData;

  const { t, i18n } = useTranslation();
  const lang = i18n.language;

  const [sliderAmount, setSliderAmount] = useState(accrued_total);
  const [isOpenModal, setIsOpenModal] = useState(false);

  const handleModalOpen = () => setIsOpenModal(true);

  const handleModalClose = () => setIsOpenModal(false);

  const onChangeSlider = (name: string, val: number) => {
    setSliderAmount(val);
  };

  const paymentArray = createArrayForSlider(1, 1, accrued_total);

  //analytics
  const handleClickRepayment = () => {
    const UID = getUserID();
    sendDataLayer(
      'click_button',
      sliderAmount === accrued_total ? GTM_LABEL_LOAN_REPAYMENT : GTM_LABEL_LOAN_PARTIAL_REPAYMENT,
      UID,
      { button_name: 'repay_online' },
    );
  };

  const handleLinkFollow = () => {
    window.open(`${ORIGIN_LOCATION}${lang === 'en' ? '/en' : ''}/how-to-repay/`, '_blank');
  };

  return (
    <>
      <div className='card flex-column gap-2'>
        <h2 className='txt-md txt-sb'>{t('repayment')}</h2>
        <p className='mb-2'>
          {t('for_information', {
            provider: PaymentProviders[activeCreditData.payment_link_provider],
          })}
        </p>
        {Number(accrued_total) !== 0 && (
          <Slider
            values={paymentArray}
            name={LoanCalculatorFields.Amount}
            prefix='RS'
            colors={SLIDER_THEME}
            title={t('title_slider_repay')}
            currentValue={accrued_total}
            onChangeSlider={onChangeSlider}
            texFieldProps={{
              name: LoanCalculatorFields.Amount,
              type: 'tel',
              parsers: [onlyNumbers],
            }}
          />
        )}

        <a
          onClick={handleClickRepayment}
          className='btn btn--primary mt-4 mb-2 w-100'
          href={`${activeCreditData.payment_link_uri}/${sliderAmount}`}
          target='_blank'
          rel='noreferrer'
        >
          <span>{t('how_repay_online')}</span>
        </a>
        <div className='btn btn-secondary flex-center min-h-44' onClick={handleModalOpen}>
          <p className='txt txt-sb'>{t('how_repay_offline')}</p>
        </div>
      </div>

      {isOpenModal && (
        <RepayOfflineModalContainer
          isOpenModal={isOpenModal}
          handleModalClose={handleModalClose}
          activeCreditData={activeCreditData}
          handleLinkFollow={handleLinkFollow}
        />
      )}
    </>
  );
};
