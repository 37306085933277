import { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { Button, ErrorMessage, ModalLayout } from 'core/components/common';
import { SubmitCoreHandler } from 'core/models';
import { useTypedSelector } from 'core/hooks';
import { setRedirect } from 'core/store/actions';
import { removeOldAccessToken, removeOldRefreshToken, removeOldUserID } from 'core/services';
import { userSelector } from 'store/selectors';
import { useAppDispatch } from 'hooks';
import { postSelectUserPhoneResolveMatchAction, signIn } from 'store/actions';
import { getRegStepPath, URL_ACTIVE_LOAN, USER_STATE } from 'constantsLk';

export enum SelectedPhoneFields {
  SelectedPhone = 'selected_phone_number',
}

export interface SelectedPhoneModel {
  [SelectedPhoneFields.SelectedPhone]: 'new' | 'old';
}

export const SelectNumberModal = ({ oldUserPhone }: { oldUserPhone?: string }) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const user = useTypedSelector(userSelector);

  const [errorMessage, setErrorMessage] = useState(null);
  const [submitting, setSubmitting] = useState(false);

  const {
    handleSubmit,
    formState: { isSubmitting },
    setValue,
  } = useForm<SelectedPhoneModel>({
    mode: 'onSubmit',
    shouldUnregister: false,
  });

  const removeOldUserCookies = () => {
    removeOldAccessToken();
    removeOldUserID();
    removeOldRefreshToken();
  };

  const onSubmit: SubmitCoreHandler<SelectedPhoneModel> = (values) => {
    setSubmitting(true);
    const selectedPhoneType = values[SelectedPhoneFields.SelectedPhone];

    dispatch(postSelectUserPhoneResolveMatchAction(selectedPhoneType === 'old'))
      .then(() => {
        removeOldUserCookies();

        dispatch(signIn())
          .unwrap()
          .then((res) => {
            let pathRedirect = getRegStepPath(res.registration_step);
            if (res.state === USER_STATE.ACTIVE) {
              pathRedirect = URL_ACTIVE_LOAN;
            }
            dispatch(setRedirect({ to: pathRedirect, replace: true }));
          });
      })
      .catch((e) => setErrorMessage(e.message || e.detail))
      .finally(() => setSubmitting(false));
  };

  const onSubmitHandler = handleSubmit((data) => {
    return onSubmit?.(data, {});
  });

  const handleClick = (selectedPhone: 'new' | 'old') => {
    setValue(SelectedPhoneFields.SelectedPhone, selectedPhone);
    onSubmitHandler().then();
  };

  return (
    <ModalLayout
      isShowClose={false}
      isOpenModal
      showAppHeader
      title={t`select_phone_number`}
      classes={{ wrapper: 'modal-core__wrapper' }}
      isUsePortal
      content={
        <div className='flex-column gap-2'>
          <p className='txt'>{t`select_which_phone_number`}</p>

          {errorMessage && <ErrorMessage message={errorMessage} />}
        </div>
      }
      footer={
        <div className='flex-column w-100'>
          <Button
            disabled={isSubmitting || submitting}
            classes={{ root: 'btn--primary w-100' }}
            onClick={() => handleClick('old')}
          >
            <Trans i18nKey='old_number' values={{ old_number: oldUserPhone }} />
          </Button>
          <Button
            className={`link flex-center m-auto mt-4 ${(isSubmitting || submitting) && 'pointer-events-none'}`}
            onClick={() => handleClick('new')}
          >
            <Trans i18nKey='new_number' values={{ new_number: user?.username }} />
          </Button>
        </div>
      }
    />
  );
};
