import { useState } from 'react';
import { SubmitCoreHandler } from 'core/models';
import { useAppDispatch } from 'hooks';
import { AuthFormFields, AuthFormModel } from '../../../AuthForm';
import { MatchedPhoneConfirmModal } from './MatchedPhoneConfirmModal';
import { MatchedModal } from './MatchedModal';
import { sendOTPassword } from 'store/actions/api';
import { SelectNumberModal } from './SelectNumberModal';

interface MatchedModalsContainerProps {
  onLogout: () => void;
  supportPhone: string;
  userPhone?: string;
}

export const MatchedModalsContainer = (props: MatchedModalsContainerProps) => {
  const { supportPhone, userPhone } = props;

  const dispatch = useAppDispatch();

  const [isOpenModal, setIsOpenModal] = useState(true);
  const [isOpenConfirmModal, setIsOpenConfirmModal] = useState(false);
  const [isOpenSelectNumberModal, setIsOpenSelectNumberModal] = useState(false);
  const [isSentOtp, setIsSentOtp] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);

  const handleAnswerYes: SubmitCoreHandler<AuthFormModel> = () => {
    if (userPhone) {
      return dispatch(sendOTPassword({ [AuthFormFields.Phone]: userPhone }))
        .unwrap()
        .then(() => {
          setIsSentOtp(true);
          handleAnswerNo();
        })
        .catch((e) => setErrorMessage(e.message));
    }
  };

  const handleAnswerNo = () => {
    setIsOpenModal(false);
    setIsOpenConfirmModal(true);
  };

  const handleSelectNumberModalOpen = () => {
    setIsOpenModal(false);
    setIsOpenConfirmModal(false);
    setIsOpenSelectNumberModal(true);
  };

  if (isOpenModal && !isOpenConfirmModal) {
    return (
      <MatchedModal
        handleAnswerNo={handleAnswerNo}
        handleAnswerYes={handleAnswerYes}
        userPhone={userPhone}
        errorMessage={errorMessage}
      />
    );
  }

  if (isOpenConfirmModal && !isOpenModal) {
    return (
      <MatchedPhoneConfirmModal
        supportPhone={supportPhone}
        isSentOtp={isSentOtp}
        userPhone={userPhone}
        handleSelectNumberModalOpen={handleSelectNumberModalOpen}
      />
    );
  }

  if (!isOpenModal && !isOpenConfirmModal && isOpenSelectNumberModal) {
    return <SelectNumberModal oldUserPhone={userPhone} />;
  }

  return null;
};
