import React, { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useController, useForm } from 'react-hook-form';
import { isIOS } from 'react-device-detect';
import { useTimer, useTypedSelector } from 'core/hooks';
import { TextFieldHookForm } from 'core/components/fields';
import { maxLength, onlyNumbers } from 'core/utils/parsers';
import { Button } from 'core/components/common';
import { SubmitCoreHandler } from 'core/models';
import { useAppDispatch, useHookFormGetCache, useHookFormSetCache } from 'hooks';
import { LoanSmsConfirmFormModel, LoanSmsConfirmFields, TopUpModel, ITopUpPrice } from 'models';
import { validatePhoneCode } from 'utils/validates/validation';
import { formattingPhone } from 'utils/functions';
import { Spinner } from '../Spinner';
import { ResendTopUpCode } from '../RequestConfirm';
import { activeApplicationSelector } from 'store/selectors';
import { getAgreeDocument, getAgreements, getUserLoans, topUpTransition } from 'store/actions/api';
import { TopUpPriceInfo } from './TopUpPriceInfo';

export interface TopUpConfirmationProps {
  topUpInfo: TopUpModel;
  userPhone?: string;
  canResendCode: boolean;
  topUpPriceList: ITopUpPrice | null;
}

export const TopUpConfirmation = (props: TopUpConfirmationProps) => {
  const { topUpInfo, userPhone, canResendCode, topUpPriceList } = props;

  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const defaultValues = useHookFormGetCache('ConfirmTopUpCode');
  const activeApplication = useTypedSelector(activeApplicationSelector);

  const [isLoanAgreementLoading, setIsLoanAgreementLoading] = useState(false);

  const {
    handleSubmit,
    watch,
    control,
    setError,
    formState: { isSubmitting },
  } = useForm<LoanSmsConfirmFormModel>({
    mode: 'onSubmit',
    defaultValues,
    shouldUnregister: true,
  });

  const { field: confirmCodeField } = useController({
    name: LoanSmsConfirmFields.Code,
    control,
    rules: validatePhoneCode,
  });

  useHookFormSetCache('ConfirmTopUpCode', watch);

  const onSubmit: SubmitCoreHandler<LoanSmsConfirmFormModel> = async (values, { setError }) => {
    const data = {
      name: 'confirm',
      params: values,
    };
    return dispatch(topUpTransition({ id: topUpInfo.id, data }))
      .unwrap()
      .then(() => dispatch(getUserLoans()))
      .catch((err) => {
        setError &&
          setError(LoanSmsConfirmFields.Code, {
            type: 'validate',
            message: err?.message,
          });
      });
  };

  const handleGetAgreement = () => {
    if (activeApplication?.id) {
      setIsLoanAgreementLoading(true);
      dispatch(getAgreements({ idApp: activeApplication.id, param: 'is_top_up_only=true' }))
        .unwrap()
        .then((resAgreements) => {
          dispatch(getAgreeDocument(resAgreements[resAgreements.length - 1]?.id))
            .unwrap()
            .then((resAgreementsDocument) => {
              const url = window.URL.createObjectURL(resAgreementsDocument);
              window.open(url, isIOS ? '_self' : '_blank');
            })
            .finally(() => setIsLoanAgreementLoading(false));
        });
    }
  };

  const onSubmitHandler = handleSubmit((data) =>
    onSubmit?.(data, {
      setError,
    }),
  );

  const { timerValue, isShowTimer, showTimer, hideTimer } = useTimer({
    timerName: 'ConfirmTopUpCode',
  });

  return (
    <form className='card gap-0' onSubmit={onSubmitHandler}>
      <h2 className='txt-md txt-sb mb-2'>{t`signing_loan_agreement`}</h2>
      <p className='txt mb-4'>
        <Trans
          i18nKey={'we_sent_sms'}
          values={{ userPhone: formattingPhone(userPhone) }}
          components={{ span: <span className='txt-sb txt-primary' /> }}
        />
      </p>

      <TextFieldHookForm
        control={control}
        name={LoanSmsConfirmFields.Code}
        label={t`enter_the_code`}
        parsers={[maxLength(4), onlyNumbers]}
        inputMode='numeric'
        rules={validatePhoneCode}
      />

      {canResendCode && (
        <ResendTopUpCode
          showTimer={showTimer}
          timerValue={timerValue}
          isShowTimer={isShowTimer}
          hideTimer={hideTimer}
          setError={setError}
          onResendCode={() => confirmCodeField.onChange('')}
          topUpId={topUpInfo.id}
        />
      )}

      {topUpPriceList && topUpInfo.applied_amount && (
        <TopUpPriceInfo topUpPriceItem={topUpPriceList[topUpInfo.applied_amount]} />
      )}

      <Button disabled={isSubmitting} classes={{ root: 'btn--primary mt-4 w-100' }}>
        {t('sign_the_agreement')}
      </Button>

      <div className='flex-center gap-2 w-100 mt-4'>
        {isLoanAgreementLoading && (
          <div className='loader-small'>
            <Spinner />
          </div>
        )}
        <span
          className={`link ${isLoanAgreementLoading && 'txt-grey pointer-events-none'}`}
          onClick={handleGetAgreement}
        >
          {t`loan_agreement`}
        </span>
      </div>
    </form>
  );
};
