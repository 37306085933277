import { useEffect, useState } from 'react';
import { useTypedSelector } from 'core/hooks';
import { SubmitCoreHandler } from 'core/models';
import { CreditBureauReportFormModel } from 'models';
import { useAppDispatch } from 'hooks';
import { activeApplicationSelector } from 'store/selectors';
import { getUserApplications, sendCreditBureauReportData } from 'store/actions/api';
import { CreditBureauReport } from './CreditBureauReport';
import { ServerFieldErrorsHandler } from 'utils';
import { ITEM_STATUS } from 'constantsLk';

export const CreditBureauReportContainer = () => {
  const dispatch = useAppDispatch();

  const [formState, setFormState] = useState(ITEM_STATUS.DEFAULT);

  const activeApplication = useTypedSelector(activeApplicationSelector);
  const showCreditBureauReportInput = activeApplication?.show_credit_bureau_report_input;

  const onSubmit: SubmitCoreHandler<CreditBureauReportFormModel> = async (values, { setError }) => {
    return dispatch(sendCreditBureauReportData(values))
      .unwrap()
      .then(() => {
        setFormState(ITEM_STATUS.SUCCESS);
      })
      .catch((err: any) => {
        ServerFieldErrorsHandler(err, setError);
      });
  };

  useEffect(() => {
    if (formState === ITEM_STATUS.SUCCESS) {
      const timeOut = setTimeout(() => {
        setFormState(ITEM_STATUS.DEFAULT);
        dispatch(getUserApplications());
      }, 3000);

      return () => clearTimeout(timeOut);
    }
  }, [formState]);

  if (!showCreditBureauReportInput) {
    return null;
  }

  return <CreditBureauReport onSubmit={onSubmit} formState={formState} />;
};
