export const autoDateGenderOfNIC = (id: any) => {
  let result = null;

  if (id.length > 7) {
    const type = !isNaN(id[id.length - 1]);

    const currentYear = new Date().getFullYear();
    const year = type ? id.slice(0, 4) : `19${id.slice(0, 2)}`;

    if (+year > currentYear || currentYear - +year > 100) return 'nic_error';

    const genderCode = type ? id.slice(4, 7) : id.slice(2, 5);
    const gender =
      genderCode > 0 && genderCode <= 366
        ? 'male'
        : genderCode > 500 && genderCode <= 866
          ? 'female'
          : null;

    const date = new Date(
      new Date('2000/01/01').setDate(genderCode <= 366 ? genderCode : genderCode - 500),
    );

    const d = {
      dd: date.getDate(),
      mm: date.getMonth() + 1,
      yyyy: year,
    };

    const dateWidget = new Date(`${d.mm}/${d.dd}/${d.yyyy}`);

    if (gender) {
      result = {
        gender,
        date,
        genderCode,
        year,
        dateWidget,
      };
    } else {
      return 'nic_error';
    }
  }

  return result;
};
