import { Trans, useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';

import { CheckBoxFieldHookForm } from 'core/components/fields';
import { Button } from 'core/components/common';
import { SubmitCoreHandler } from 'core/models';

import { validateCheckBox } from 'utils/validates/validation';
import { PhoneConfirmFormFields } from 'models';
import { CheckCircleFilledIcon } from 'components';
import { COUNTRY_PHONE_CODE } from 'constantsLk';

export interface TermsConditionsFormFields {
  [PhoneConfirmFormFields.AgreementTermsConditions]: boolean;
}

export interface TermsConditionsFormProps {
  onOpenModalHandler: (doc: any) => any;
  onSubmit: SubmitCoreHandler<TermsConditionsFormFields>;
  phone?: string;
}

export const TermsConditionsForm = (props: TermsConditionsFormProps) => {
  const { onSubmit, onOpenModalHandler, phone } = props;

  const { t } = useTranslation();

  const {
    handleSubmit,
    control,
    setError,
    formState: { isSubmitting },
  } = useForm<TermsConditionsFormFields>({
    mode: 'onSubmit',
    shouldUnregister: true,
  });

  const onSubmitHandler = handleSubmit((data) =>
    onSubmit?.(data, {
      setError,
    }),
  );

  return (
    <form className='justify-content-start' onSubmit={onSubmitHandler}>
      <div className='alerts-item__success'>
        <CheckCircleFilledIcon />
        <div className='flex-grow-1 flex-column gap-1'>
          <p>
            {t`your_phone_number_verified`.replace(
              '{value}',
              `${COUNTRY_PHONE_CODE} ${phone}` || '',
            )}
          </p>
          <p>{t`please_agreee_to_continue`}</p>
        </div>
      </div>
      <CheckBoxFieldHookForm
        control={control}
        name={PhoneConfirmFormFields.AgreementTermsConditions}
        rules={validateCheckBox}
        label={
          <Trans
            i18nKey='is_data_processing_consent_given_com'
            components={{
              a: <span className='ml-1 link' onClick={onOpenModalHandler} />,
            }}
          />
        }
      />

      <Button disabled={isSubmitting} classes={{ root: 'btn--primary' }}>
        {t('continue')}
      </Button>
    </form>
  );
};
