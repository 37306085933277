import { createAsyncThunk } from '@reduxjs/toolkit';
import { getFileByType, verifiedNICFront } from './api/fileUploadApi';
import { Actions } from 'models';

export const eventVerifiedNICFront = createAsyncThunk<Promise<boolean> | unknown, number>(
  Actions.EVENT_VERIFIED_NIC_FRONT,
  async (timeout, { dispatch }) => {
    let intervalId: NodeJS.Timeout;

    return new Promise((resolve, reject) => {
      intervalId = setInterval(async () => {
        try {
          const res = await dispatch(verifiedNICFront()).unwrap();
          if (res.need_repeat === true) {
            return;
          }
          if (res.status === true) {
            intervalId && clearInterval(intervalId);
            await resolve(true);
            return;
          }
        } catch (e: any) {
          // if (e.status === false) {
          //   intervalId && clearInterval(intervalId);
          //   await reject(true);
          //   return;
          // }
          intervalId && clearInterval(intervalId);
          await reject(true);
        }
      }, timeout);
    });
  },
);

export const getFileByTypeAction = createAsyncThunk<Blob, { type: string }>(
  Actions.GET_FILE_BY_TYPE_ACTION,
  async ({ type }, { dispatch, rejectWithValue }) => {
    try {
      return await dispatch(getFileByType(type)).unwrap();
    } catch (errors) {
      return rejectWithValue(errors);
    }
  },
);
