import { useState } from 'react';
import Select, { OnChangeValue, Props } from 'react-select';
import cn from 'classnames';

import { ErrorMessage } from 'core/components/common';
import { SelectOptionType } from 'core/models';
import { useHookFormFieldError } from 'core/hooks';
import { SelectArrowIcon } from 'components';

export interface SelectFieldProps extends Omit<Props, 'onChange' | 'isMulti'> {
  label?: string | JSX.Element;
  options: SelectOptionType[];
  onChange?: (name: string, value: string | number) => void;
  error?: string;
  name: string;
  leftContent?: string | JSX.Element;
  rightContent?: string | JSX.Element;
  classes?: {
    root?: string;
    label?: string;
    error?: string;
    elementLeft?: string;
    elementRight?: string;
  };
}

export const SelectField = (props: SelectFieldProps) => {
  const {
    value,
    name,
    label,
    classes = {},
    error,
    onChange,
    placeholder = '',
    leftContent,
    rightContent,
    ...rest
  } = props;

  const [isOpen, setIsOpen] = useState(false);

  const getFieldError = useHookFormFieldError();

  const handelChange = (v: OnChangeValue<any, false>) =>
    onChange?.(name, v?.value ? v.value : null);

  const errorMessage = getFieldError(error);

  return (
    <div
      className={cn(
        'select-box',
        { 'select-box__no-empty': !!value || isOpen },
        { 'select-box__invalid': !!error },
        { 'select-box__open': isOpen },
        classes.root,
      )}
    >
      {leftContent && (
        <span className={cn('select-box__element_left', classes.elementLeft)}>{leftContent}</span>
      )}
      {label && (
        <label className={cn('select-box__label', classes.label)} htmlFor={name}>
          {label}
        </label>
      )}
      <Select
        isSearchable={false}
        name={name}
        onChange={handelChange}
        isError={!!error}
        placeholder={placeholder}
        value={value}
        maxMenuHeight={200}
        menuIsOpen={isOpen}
        closeMenuOnSelect={true}
        openMenuOnFocus={true}
        onMenuOpen={() => {
          setIsOpen(true);
        }}
        onMenuClose={() => {
          setIsOpen(false);
        }}
        {...rest}
      />
      <SelectArrowIcon />
      {rightContent && (
        <span className={cn('select-box__element_right', classes.elementRight)}>
          {rightContent}
        </span>
      )}
      {error && <ErrorMessage classError={classes.error} message={errorMessage} />}
    </div>
  );
};
