import { useTranslation } from 'react-i18next';
import { useTypedSelector } from 'core/hooks';
import { EmailAlertWrapper, MoneyIcon, NeedMoreMoney, RequestStatus } from 'components';
import { setShowPartner } from 'store/slices/loanSlice';
import { useAppDispatch } from 'hooks';
import { isShowPartnerSelector } from 'store/selectors';
import { KEY_OPENED_PARTNER_LINK } from 'constantsLk';

export const SendingMoneyNotifyContainer = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const isShowPartner = useTypedSelector(isShowPartnerSelector);
  const isOpenedPartnerLink = localStorage.getItem(KEY_OPENED_PARTNER_LINK);

  const onClose = () => dispatch(setShowPartner(false));

  return (
    <EmailAlertWrapper>
      <div className='flex-column w-100 gap-4'>
        <RequestStatus
          icon={<MoneyIcon />}
          title={'we_sending_money_your_account_number_right_now'}
          text={t`usually_takes_15_minutes`}
          isShowPartners
        />

        {isShowPartner && !isOpenedPartnerLink && (
          <div className='max-w-54'>
            <NeedMoreMoney screen={1} onClose={onClose} />
          </div>
        )}
      </div>
    </EmailAlertWrapper>
  );
};
