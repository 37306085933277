import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';

import { Button } from 'core/components/common';
import { SelectFieldHookForm, TextFieldHookForm } from 'core/components/fields';
import { maxLength } from 'core/utils/parsers';
import { SubmitCoreHandler } from 'core/models';

import { MartialRelativesFields, MartialRelativesFieldsFormModel } from 'models';
import { useHookFormGetCache, useHookFormSetCache, useScrollToFieldError } from 'hooks';
import {
  ENG_REGEX,
  validateOnlyEnglishText,
  validatePhone,
  validateSelect,
} from 'utils/validates/validation';
import { onSubmitEventTagHandler } from 'utils/analitics';
import { GTM_LABEL_SIGN_UP } from 'utils/analitics/gtmLabel';
import { PhoneFieldFocusValidation, Title } from 'components';
import * as Paths from 'constantsLk/paths';
import { COUNTRY_PHONE_CODE, SELECT_OPTIONS } from 'constantsLk';

export interface Step6MartialRelativesProps {
  onSubmit: SubmitCoreHandler<MartialRelativesFieldsFormModel>;
}

export const Step6MartialRelatives = (props: Step6MartialRelativesProps) => {
  const { onSubmit } = props;
  const { t, i18n } = useTranslation();
  const lang = i18n.language;

  const defaultValues = useHookFormGetCache(Paths.PATH_REG_STEP_6);

  const {
    handleSubmit,
    watch,
    control,
    setError,
    setValue,
    clearErrors,
    trigger,
    formState: { isSubmitting, errors, submitCount },
  } = useForm<MartialRelativesFieldsFormModel>({
    mode: 'onBlur',
    shouldFocusError: true,
    defaultValues,
  });

  useHookFormSetCache(Paths.PATH_REG_STEP_6, watch);
  useScrollToFieldError({ errors, submitCount });

  const onSubmitHandler = handleSubmit(
    (data) =>
      onSubmit?.(data, {
        setError,
      }),
    (errors) => onSubmitEventTagHandler(errors, GTM_LABEL_SIGN_UP),
  );

  const handlePhoneChange = (name: any, value: string | number) => {
    if (value && value.toString()[0] !== '7') {
      trigger(name).then(() => setValue(name, ''));
    } else {
      clearErrors(name);
    }
  };

  const handleChangeText = (name: any, value: any) => {
    const isEngVal = ENG_REGEX.test(value);
    if (!isEngVal) {
      trigger(name).then();
    } else {
      clearErrors(name);
    }
  };

  return (
    <>
      <Title classes={'form-title'} name={'marital_status_and_contact_info'} />
      <form onSubmit={onSubmitHandler}>
        <SelectFieldHookForm
          control={control}
          label={t`marital_status`}
          name={MartialRelativesFields.MaritalStatus}
          options={SELECT_OPTIONS.marital_status[lang]}
          rules={validateSelect}
        />
        <SelectFieldHookForm
          control={control}
          label={t`number_of_children`}
          name={MartialRelativesFields.NumberChildren}
          options={SELECT_OPTIONS.number_of_children[lang]}
          rules={validateSelect}
        />
        <div className='w-100 flex-column gap-4'>
          <SelectFieldHookForm
            control={control}
            label={t`native_language`}
            name={MartialRelativesFields.NativeLanguage}
            options={SELECT_OPTIONS.native_language[lang]}
            rules={validateSelect}
          />
        </div>
        <div className='flex-column gap-4 mt-1'>
          <div className='flex-column'>
            <Title classes={'form-title mt-0 mb-2'} name={'additional_contact'} />
            <span className='txt-r'>{t`contact_person_hint_text`}</span>
            <p className='txt-r mt-4'>{t`should_20_60_years`}</p>
          </div>
          <TextFieldHookForm
            control={control}
            label={t`contact_name`}
            name={MartialRelativesFields.ContactName}
            parsers={[maxLength(30)]}
            rules={validateOnlyEnglishText}
            onChange={handleChangeText}
          />
        </div>

        <SelectFieldHookForm
          control={control}
          label={t`contact_relationship`}
          name={MartialRelativesFields.ContactRelationship}
          options={SELECT_OPTIONS.contact_relationship[lang]}
          rules={validateSelect}
        />
        <PhoneFieldFocusValidation
          label={t`contact_phone_number`}
          control={control}
          inputMode='numeric'
          leftContent={COUNTRY_PHONE_CODE}
          name={MartialRelativesFields.ContactPhone}
          rules={validatePhone}
          onChange={handlePhoneChange}
          classes={{ root: '__no-empty' }}
        />
        <Button
          disabled={isSubmitting}
          classes={{ root: 'btn--primary mt-1 next-step' }}
        >{t`continue`}</Button>
      </form>
    </>
  );
};
