import { useTranslation } from 'react-i18next';
import { useTypedSelector } from 'core/hooks';
import { isLoanActiveSelector } from 'store/selectors';
import { Loan } from 'models';
import { LoanCardContainer } from './LoanCardContainer';

interface AccountLoanHistoryPageProps {
  loans: Loan[] | null;
}

export const AccountLoanHistoryPage = (props: AccountLoanHistoryPageProps) => {
  const { loans } = props;
  const { t } = useTranslation();

  const isLoanActive = useTypedSelector(isLoanActiveSelector);

  return (
    <>
      {loans && loans.length !== 0 ? (
        <div className='loan-history'>
          {[...loans]
            .sort((a, b) => b.id - a.id)
            .map((item: Loan, key) => (
              <LoanCardContainer
                key={key}
                loan={item}
                canDownloadDocument={isLoanActive && key === 0}
              />
            ))}
        </div>
      ) : (
        <span className='txt-center txt-md txt-b w-100'>{t`not_loan_history`}</span>
      )}
    </>
  );
};
