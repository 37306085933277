import { useEffect, useMemo } from 'react';
import { UseFormReturn } from 'react-hook-form';

import { getInitStateForm, setInitStateForm } from 'core/services';

export const useHookFormSetCache = (key?: string, watch?: UseFormReturn<any>['watch']) => {
  useEffect(() => {
    if (key && watch) {
      const subscription = watch((values) => setInitStateForm(key, values));
      return () => subscription.unsubscribe();
    }
  }, [key, watch]);
};

export const useHookFormGetCache = (key?: string) => {
  return useMemo(() => (
    (key && getInitStateForm<any>(key)) || {}
  ), [key]);
};
