import { getStorageHyperSnap } from '../core/services';

export const initHyperSnap = (userId: number) => {
  if (window.HyperSnapSDK) {
    const { HyperSnapSDK, HyperSnapParams } = window;
    HyperSnapSDK.init(getStorageHyperSnap(), HyperSnapParams.Region.India);
    HyperSnapSDK.startUserSession(userId);
  }
};

export const runLiveModal = (onSuccess = (res: any) => res, onFailed = (error: any) => error) => {
  if (window.HVFaceConfig && window.HVFaceModule) {
    const { HVFaceConfig, HVFaceModule } = window;

    const hvFaceConfig = new HVFaceConfig();
    // hvFaceConfig.setShouldShowInstructionPage(true);
    hvFaceConfig.setLivenessAPIParameters({
      rejectFaceMask: 'yes',
      allowEyesClosed: 'no',
      allowMultipleFaces: 'no',
      rejectBlur: 'yes',
    });
    HVFaceModule.start(hvFaceConfig, (HVError: any, HVResponse: any) => {
      if (HVError) {
        onFailed(HVError);
      } else {
        onSuccess(HVResponse);
      }
    });
  }
};
