import { useTranslation } from 'react-i18next';
import { Button, ModalLayout } from 'core/components/common';
import { IIdCardInstructionsProps } from '../../DocumentUploader';

interface IDCardPhotoInstructionsModalProps {
  onClose: () => void;
  onOpen?: () => void;
  instructions: IIdCardInstructionsProps[];
  type: string;
}

export const IDCardPhotoInstructionsModal = ({
  onClose,
  instructions,
  onOpen,
  type,
}: IDCardPhotoInstructionsModalProps) => {
  const { t } = useTranslation();

  return (
    <ModalLayout
      isOutSideClick
      onClose={onClose}
      isOpenModal
      isUsePortal
      classes={{ wrapper: 'modal-core__wrapper--instructions' }}
      title={t('take_photo_instructions_title')}
      isShowClose
      content={
        <div className='flex instructions-block mt-3'>
          {instructions.map(({ icon, name, success, error }) => (
            <div key={`${name}-${type}`} className='flex-column instructions-item'>
              <div className='flex align-items-center gap-1'>
                <div className='txt-primary'>{icon}</div>
                <div className='txt-b'>{t(name)}</div>
              </div>
              <div className='flex-nowrap gap-2 mb-2'>
                <div className='w-100'>
                  <img className='w-100 border-r-0-5' src={success} />
                </div>
                <div className='w-100'>
                  <img className='w-100 border-r-0-5' src={error} />
                </div>
              </div>
            </div>
          ))}
        </div>
      }
      footer={
        <Button onClick={onOpen} classes={{ root: 'btn--primary' }}>
          <span>{t('take_a_photo')}</span>
        </Button>
      }
    />
  );
};
