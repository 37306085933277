import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { registerLocale } from 'react-datepicker';

import { initialiseGTM } from 'core/services';
import { initialLocalization } from 'core/localization';

import { App } from 'App';
import { store } from 'store';
import { resources } from 'localization';
import en from 'date-fns/locale/en-US';
import si from 'langSI';
import { impersonateAuth } from './utils/impersonateAuth';
import { getTargetUrlParams } from './utils/analitics';

//autologin from admin "impersonate"
impersonateAuth();

//Google Analytics
initialiseGTM();
//target url
getTargetUrlParams();

const container = document.getElementById('root')!;
const root = createRoot(container);
initialLocalization(resources).then();
// initialiseSentry();
registerLocale('en', en);
registerLocale('si', si);

root.render(
  <Provider store={store}>
    <App />
    {/*<Example />*/}
  </Provider>,
);
