import React, { useRef, useState, KeyboardEvent, BaseSyntheticEvent, useEffect } from 'react';
import { UseFormClearErrors } from 'react-hook-form';
import { ErrorMessage } from 'core/components/common';
import { PhoneConfirmFormFields } from 'models';
import { Confirmation } from 'pages/Registration';

interface OtpInputProps {
  setValue: (
    name: PhoneConfirmFormFields.Code | PhoneConfirmFormFields.AgreementTermsConditions,
    value: string,
  ) => void;
  onSubmitHandler: () => void;
  errors: any;
  clearErrors: UseFormClearErrors<any>;
  otp: string | null;
  confirmation: Confirmation.SMS_CONFIRM | Confirmation.PHONE_CONFIRM;
}
export const OtpInput = ({
  setValue,
  onSubmitHandler,
  errors,
  clearErrors,
  otp,
  confirmation,
}: OtpInputProps) => {
  const [arrayValue, setArrayValue] = useState<(string | number)[]>(['', '', '', '']);
  const [isDeleteBtn, setIsDeleteBtn] = useState(false);
  const inputRefs = useRef<(HTMLInputElement | null)[]>([]);

  useEffect(() => {
    if (confirmation !== Confirmation.PHONE_CONFIRM) {
      inputRefs.current[0]?.focus();
    }
  }, [confirmation]);

  useEffect(() => {
    if (otp) {
      setArrayValue((preValue: (string | number)[]) => {
        const newArray = [...preValue];

        otp.split('').forEach((item, index) => {
          if (index < newArray.length) {
            newArray[index] = item;
          }
        });
        return newArray;
      });
      inputRefs.current[arrayValue.length - 1]?.blur();
      onSubmitHandler();
    }
  }, [otp]);

  const submitOtp = () => {
    inputRefs.current[arrayValue.length - 1]?.blur();
    onSubmitHandler();
  };

  const onPaste = (e: React.ClipboardEvent) => {
    e.preventDefault();
    const paste = e.clipboardData.getData('text').split('');
    if (paste.every((item) => !isNaN(Number(item)))) {
      let newInputValue = [...arrayValue];
      let pasteIndex = 0;

      for (let i = 0; i < newInputValue.length && pasteIndex < paste.length; i++) {
        newInputValue[i] = paste[pasteIndex++];
      }

      setArrayValue(newInputValue);
      setValue(PhoneConfirmFormFields.Code, newInputValue.join(''));

      const nextIndex = paste.length;
      if (nextIndex < arrayValue.length) {
        inputRefs.current[nextIndex]?.focus();
      } else {
        inputRefs.current[arrayValue.length - 1]?.focus();
      }

      if (newInputValue.every((value) => !!value.toString().length)) {
        submitOtp();
      }
    }
  };

  const onChange = (e: BaseSyntheticEvent, index: number) => {
    const input = e.target.value;

    if (!isNaN(input) && input.toString().trim().length) {
      setArrayValue((preValue: (string | number)[]) => {
        const newArray = [...preValue];
        newArray[index] = input;
        return newArray;
      });

      if (index < arrayValue.length - 1) {
        inputRefs.current[index + 1]?.focus();
      }

      setValue(
        PhoneConfirmFormFields.Code,
        [...arrayValue.slice(0, index), input, ...arrayValue.slice(index + 1)].join(''),
      );

      if (index === arrayValue.length - 1 && inputRefs.current.every((val) => !!val?.value)) {
        submitOtp();
      }
    }
  };

  const onKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Backspace' || e.key === 'Delete') {
      setIsDeleteBtn(true);
    } else {
      setIsDeleteBtn(false);
    }
  };

  const onKeyUp = (e: KeyboardEvent<HTMLInputElement>, index: number) => {
    if (e.key === 'Backspace' || e.key === 'Delete') {
      if (index > 0) {
        inputRefs.current[index - 1]?.focus();
      }
      setArrayValue((prevValue: (string | number)[]) => {
        const newArray = [...prevValue];
        newArray[index] = '';
        return newArray;
      });
      setIsDeleteBtn(false);
    }
  };

  const resetInputs = () => {
    setArrayValue(['', '', '', '']);
    clearErrors(PhoneConfirmFormFields.Code);
  };

  const onFocus = (index: number) => {
    if (errors.confirmation_code) {
      resetInputs();
      inputRefs.current[0]?.focus();
    }

    setArrayValue((preValue: (string | number)[]) => {
      const newArray = [...preValue];

      if (isDeleteBtn) {
        newArray[index] = '';
      } else {
        let firstEmptyIndex = newArray.findIndex((value) => value === '');
        inputRefs.current[firstEmptyIndex]?.focus();
      }
      return newArray;
    });
  };

  return (
    <>
      <div className='flex align-items-center flex-nowrap gap-2'>
        {arrayValue.map((value: string | number, index: number) => (
          <input
            key={`index-${index}`}
            ref={(el) => el && (inputRefs.current[index] = el)}
            className={`input-otp ${errors.confirmation_code ? 'error' : ''}`}
            inputMode='numeric'
            maxLength={1}
            type='text'
            value={String(value)}
            onChange={(e) => onChange(e, index)}
            onKeyDown={(e) => onKeyDown(e)}
            onKeyUp={(e) => onKeyUp(e, index)}
            onPaste={(e) => onPaste(e)}
            onFocus={() => onFocus(index)}
            autoComplete='one-time-code'
          />
        ))}
      </div>
      {errors.confirmation_code && (
        <div className='flex align-items-center mt-1'>
          <ErrorMessage message={errors.confirmation_code.message} />
        </div>
      )}
    </>
  );
};
