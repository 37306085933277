import { useEffect } from 'react';
import { setRedirect } from 'core/store/actions';
import { useAppDispatch } from 'hooks';
import { NeedMoreMoney, ProlongationPromoIcon, ProlongationPromoIcon2 } from 'components';
import { Loan, PromotionModel } from 'models';
import { PriceInfo } from 'pages/account/AccountLoan/ActiveLoan/PriceInfo';
import { Prolongation } from 'pages/account/AccountLoan/ActiveLoan/Prolongation';
import { Repayment } from 'pages/account/AccountLoan/ActiveLoan/Repayment';
import { Promotion } from 'pages/account/AccountLoan/ActiveLoan/Promotion';
import { ProlongationPromotion } from '../../account/AccountLoan/ActiveLoan/ProlongationPromotion';
import { DISCOUNT_STATE, LOAN_STATE, URL_USER_REGISTRATION } from 'constantsLk';

interface ShortLinkLoanResultProps {
  shortLinkLoan: Loan | null;
  isShowPartners: boolean;
  onClose: () => void;
  isPersonalAccount: boolean;
  activePromotion?: PromotionModel;
  activeProlongationPromotion?: PromotionModel;
}
export const ShortLinkLoanResult = (props: ShortLinkLoanResultProps) => {
  const {
    shortLinkLoan,
    isShowPartners,
    onClose,
    isPersonalAccount,
    activePromotion,
    activeProlongationPromotion,
  } = props;

  const dispatch = useAppDispatch();

  const isLoanSequenceNumberOneOrTwo = shortLinkLoan && shortLinkLoan.loan_index < 3;

  const isCanProlong =
    shortLinkLoan?.state !== LOAN_STATE.DEFAULTED && shortLinkLoan?.state !== LOAN_STATE.SOLD;

  useEffect(() => {
    if (!shortLinkLoan?.loan_id) {
      dispatch(setRedirect({ to: URL_USER_REGISTRATION }));
    }
  }, []);

  const isRepaymentPromoActive = activePromotion?.state === DISCOUNT_STATE.ACTIVE;
  const isProlongationPromoActive = activeProlongationPromotion?.state === DISCOUNT_STATE.ACTIVE;

  const renderProlongationPromo = (icon: JSX.Element, hiddenClass: string) =>
    isCanProlong &&
    isProlongationPromoActive &&
    shortLinkLoan && (
      <div className={`w-100 ${hiddenClass}`}>
        <ProlongationPromotion
          activeCreditData={shortLinkLoan}
          isCollapsible={isLoanSequenceNumberOneOrTwo}
          activePromo={activeProlongationPromotion}
          icon={icon}
        />
      </div>
    );

  return shortLinkLoan?.loan_id ? (
    <div className='row align-items-start'>
      {/* left column */}
      <div className='col-12 col-md-6 mb-4'>
        {isRepaymentPromoActive && (
          <div className='w-100'>
            <Promotion
              activePromo={activePromotion}
              paymentLinkUri={shortLinkLoan.payment_link_uri}
            />
          </div>
        )}

        {renderProlongationPromo(
          <ProlongationPromoIcon />,
          isRepaymentPromoActive ? 'hidden-min-lg-tablet' : '',
        )}

        {isShowPartners && (
          <NeedMoreMoney screen={2} onClose={onClose} loanId={shortLinkLoan?.loan_id} />
        )}
        <PriceInfo activeCreditData={shortLinkLoan} />
      </div>

      {/* right column */}
      <div className='col-12 col-md-6 mb-4'>
        {renderProlongationPromo(
          <ProlongationPromoIcon2 />,
          isRepaymentPromoActive ? 'hidden-lg-tablet' : 'hidden',
        )}

        {isCanProlong && !isProlongationPromoActive && !isLoanSequenceNumberOneOrTwo && (
          <div className='tablet-mb-6 mb-4 w-100'>
            <Prolongation
              activeCreditData={shortLinkLoan}
              isCollapsible={isLoanSequenceNumberOneOrTwo}
              showHowToRepayButton={isCanProlong && !isPersonalAccount}
            />
          </div>
        )}

        {isPersonalAccount && (
          <div className='tablet-mb-6 mb-4 w-100'>
            <Repayment activeCreditData={shortLinkLoan} />
          </div>
        )}

        {isCanProlong && !isProlongationPromoActive && isLoanSequenceNumberOneOrTwo && (
          <Prolongation
            activeCreditData={shortLinkLoan}
            isCollapsible={isLoanSequenceNumberOneOrTwo}
            showHowToRepayButton={isCanProlong && !isPersonalAccount}
          />
        )}
      </div>
    </div>
  ) : null;
};
