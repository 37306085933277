import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { selectStyles } from 'assets/styles/select_style';
import { SubmitCoreHandler } from 'core/models';
import { maxLength } from 'core/utils/parsers';
import { Button } from 'core/components/common';
import { SelectField, TextFieldHookForm } from 'core/components/fields';

import { useHookFormGetCache, useHookFormSetCache, useScrollToFieldError } from 'hooks';
import { PersonalDataFormFields, PersonalDataFormModel } from 'models';
import {
  ENG_REGEX,
  validateBirthDate,
  validateGender,
  validateOnlyEnglishText,
} from 'utils/validates/validation';
import { noNumbers } from 'utils/parsers';
import { GTM_LABEL_SIGN_UP } from 'utils/analitics/gtmLabel';
import { generateYearOptions } from 'utils/generateYearOptions';
import { onSubmitEventTagHandler } from 'utils/analitics';
import { Gender, PersonalIdType } from 'models/common';
import { ErrorMessageCommon, FaceFemale, FaceMale, Title } from 'components';
import { DateField, listPersonalId, RadioFields, TypedId } from 'components/fields';
import { getSubYear } from 'utils/functions';
import * as Paths from 'constantsLk/paths';
import { MONTH_NAMES } from 'constantsLk';

export interface Step3PersDataProps {
  onSubmit: SubmitCoreHandler<PersonalDataFormModel>;
}

export const Step3PersData = (props: Step3PersDataProps) => {
  const { onSubmit } = props;
  const { t } = useTranslation();

  const defaultValues = useHookFormGetCache(Paths.PATH_REG_STEP_3);
  const {
    handleSubmit,
    watch,
    control,
    setError,
    setValue,
    clearErrors,
    trigger,
    formState: { errors, isSubmitting, submitCount },
  } = useForm<PersonalDataFormModel>({
    mode: 'onBlur',
    defaultValues: {
      ...defaultValues,
      [PersonalDataFormFields.PersonalIdType]: defaultValues[PersonalDataFormFields.PersonalIdType]
        ? defaultValues[PersonalDataFormFields.PersonalIdType]
        : PersonalIdType.New,
      [PersonalDataFormFields.PersonalIdNumberOldSerial]: defaultValues[
        PersonalDataFormFields.PersonalIdNumberOldSerial
      ]
        ? defaultValues[PersonalDataFormFields.PersonalIdNumberOldSerial]
        : listPersonalId[0].value,
    },
  });

  useHookFormSetCache(Paths.PATH_REG_STEP_3, watch);
  useScrollToFieldError({ errors, submitCount });

  const onSubmitHandler = handleSubmit(
    (data) =>
      onSubmit?.(data, {
        setError,
      }),
    (errors) => onSubmitEventTagHandler(errors, GTM_LABEL_SIGN_UP),
  );

  const personalIdType = watch(PersonalDataFormFields.PersonalIdType);

  const handleChangeText = (name: any, value: any) => {
    const isEngVal = ENG_REGEX.test(value);
    if (!isEngVal) {
      trigger(name).then();
    } else {
      clearErrors(name);
    }
  };

  return (
    <>
      <Title classes={'form-title'} name={'personal_data'} />
      <form onSubmit={onSubmitHandler}>
        <TextFieldHookForm
          control={control}
          name={PersonalDataFormFields.Name}
          label={t`first_name`}
          parsers={[noNumbers, maxLength(50)]}
          rules={validateOnlyEnglishText}
          onChange={handleChangeText}
        />

        <TextFieldHookForm
          control={control}
          name={PersonalDataFormFields.LastName}
          label={t`last_name`}
          parsers={[noNumbers, maxLength(50)]}
          rules={validateOnlyEnglishText}
          onChange={handleChangeText}
        />

        <TypedId
          control={control}
          personalIdType={personalIdType}
          setValue={setValue}
          // personalIdOldSerial={personalIdOldSerial}
        />

        <RadioFields
          control={control}
          name={PersonalDataFormFields.Gender}
          options={[
            { label: t`male`, icon: <FaceMale />, value: Gender.Male },
            { label: t`female`, icon: <FaceFemale />, value: Gender.Female },
          ]}
          label={t`gender`}
          rules={validateGender}
        />

        <DateField
          control={control}
          name={PersonalDataFormFields.DateOfBirth}
          label={t`birthday`}
          rules={validateBirthDate}
          placeholderText='YYYY.MM.DD'
          classes={{ root: '__no-empty' }}
          maxDate={getSubYear(20)}
          minDate={getSubYear(60)}
          renderCustomHeader={({ date, changeYear, changeMonth }) => {
            const monthValue = MONTH_NAMES[date.getMonth()];
            const yearValue = date.getFullYear().toString();

            return (
              <div className='flex-center gap-2'>
                <SelectField
                  value={{ value: monthValue, label: t(monthValue) }}
                  onChange={(_, val) => changeMonth(MONTH_NAMES.indexOf(val as string))}
                  options={MONTH_NAMES.map((month) => ({ value: month, label: t(month) }))}
                  name='month'
                  label={t`month`}
                  styles={selectStyles}
                />

                <SelectField
                  value={{ value: yearValue, label: yearValue }}
                  onChange={(_, val) => changeYear(Number(val))}
                  options={generateYearOptions()}
                  name='year'
                  label={t`year`}
                  styles={selectStyles}
                />
              </div>
            );
          }}
        />

        <Button
          disabled={isSubmitting}
          classes={{ root: 'btn--primary mt-1 next-step' }}
        >{t`continue`}</Button>
        {errors?.root?.commonError && <ErrorMessageCommon error={errors?.root?.commonError} />}
      </form>
    </>
  );
};
