import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { ModalLayout } from 'core/components/common';
import { RepayOfflineAlert } from './RepayOfflineContent/RepayOfflineAlert';
import { BankInstructionModel } from 'models';
import { PayAndGoIcon } from '../../icons';

interface RepayOfflineModalProps {
  isOpenModal: boolean;
  handleModalClose: () => void;
  bankData: BankInstructionModel;
  handleLinkFollow?: () => void;
}

export const RepayOfflineModal = (props: RepayOfflineModalProps) => {
  const { isOpenModal, handleModalClose, bankData } = props;
  const { t } = useTranslation();

  return (
    <ModalLayout
      isOutSideClick
      onClose={handleModalClose}
      isOpenModal={isOpenModal}
      classes={{ wrapper: 'instruction-repay' }}
      isShowClose
      isUsePortal
      title={
        <div className='align-items-center gap-2'>
          {!bankData.isAnyBank && bankData.data[0].icon}
          <span className='txt-md-16 txt-b'>
            {!bankData.isAnyBank ? bankData.title : t`any_bank_title`}
          </span>
        </div>
      }
      content={
        <>
          {/*<p className='mt-2 mb-4 txt'>*/}
          {/*  <Trans i18nKey={bankData.subtitle} />*/}
          {/*</p>*/}

          <div className='pb-3 mb-3 border-b'>
            <div className='align-items-center gap-2'>
              <PayAndGoIcon />
              <span className='txt-md-16 txt-b'>Pay&Go kiosks</span>
            </div>

            <div className='flex-column gap-2 mt-4'>
              <p>{t`repay_pay_go`}</p>

              <p>
                <Trans
                  i18nKey='pay_online_pay_go'
                  components={{
                    a: <a href='https://paygo.lk/Home/Pay?srv=cashx' />,
                  }}
                />
              </p>

              <p>
                <Trans
                  i18nKey='closest_kiosk_pay_go'
                  components={{
                    a: <a href='https://paygo.lk/KIoskLocations' />,
                  }}
                />
              </p>
            </div>
          </div>

          {/*{bankData.data.map((item: BankAccount) => {*/}
          {/*  if (!item.isWeekend) {*/}
          {/*    return (*/}
          {/*      <BankAccountData bankData={item} isAnyBank={bankData.isAnyBank} key={item.title} />*/}
          {/*    );*/}
          {/*  }*/}
          {/*})}*/}

          {/*{bankData.instruction && (*/}
          {/*  <div className='txt mb-2 instruction-repay__text'>*/}
          {/*    <Trans i18nKey={bankData.instruction} values={{ nic: bankData.data[0].nic }} />*/}
          {/*  </div>*/}
          {/*)}*/}

          {/*{!bankData.isAnyBank && (*/}
          {/*  <div className='txt'>*/}
          {/*    <Trans*/}
          {/*      i18nKey='repayment_on_website'*/}
          {/*      components={{*/}
          {/*        a: (*/}
          {/*          <span*/}
          {/*            className='txt-sb txt-primary cursor-pointer'*/}
          {/*            onClick={handleLinkFollow}*/}
          {/*          />*/}
          {/*        ),*/}
          {/*      }}*/}
          {/*    />*/}
          {/*  </div>*/}
          {/*)}*/}

          <RepayOfflineAlert />
        </>
      }
    />
  );
};
