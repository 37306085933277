import { useTypedSelector } from 'core/hooks';
import { wpContentFooterSelector, wpContentHeaderSelector } from 'store/selectors';
import { Footer } from './Footer';

export const FooterContainer = () => {
  const wpContentHeader = useTypedSelector(wpContentHeaderSelector);
  const wpContentFooter = useTypedSelector(wpContentFooterSelector);

  return (
    <Footer
      footerDocs={wpContentFooter?.modal_info}
      phone={wpContentHeader?.contacts.phone}
      copyright={wpContentFooter?.copyright}
      email={wpContentFooter?.contacts.email}
      workingHours={wpContentHeader?.contacts.schedule}
    />
  );
};
