import React, { ChangeEvent, FC } from 'react';
import { useTranslation } from 'react-i18next';
import { isMobile } from 'react-device-detect';
import { Button } from 'core/components/common';
import { MobileCamera } from '../MobileCamera';
import { FileTakePhotoModal } from '../FileTakePhotoModal';
import { IDCardPhotoInstructionsModal } from 'components/modals';
import { IIdCardInstructionsProps } from '../UploadPhotoComponent';

interface IFileInputProps {
  changeFile: (e: ChangeEvent<HTMLInputElement>) => void;
  error?: string;
  fileState: string;
  onUpload: (file: string) => Promise<any>;
  type: string;
  zIndex?: number;
  instructions: IIdCardInstructionsProps[];
  photoModalsState: { isTakePhotoOpen: boolean; isTakePhotoInstructionOpen: boolean };
  openWebcamHandler: () => void;
  closeWebcamHandler: () => void;
  instructionHandler: () => void;
  sendLog?: (action: string, additional_data: string, source_user_id?: number) => any;
}

export const FileInput: FC<IFileInputProps> = ({
  changeFile,
  fileState,
  onUpload,
  type,
  instructions,
  photoModalsState,
  openWebcamHandler,
  closeWebcamHandler,
  instructionHandler,
  sendLog,
}) => {
  const { t } = useTranslation();

  return (
    <>
      {isMobile ? (
        <MobileCamera
          type={type}
          changeFile={changeFile}
          fileState={fileState}
          instructionHandler={instructionHandler}
          sendLog={sendLog}
        />
      ) : (
        <Button
          onClick={instructionHandler}
          classes={{ root: 'btn--primary mt-0' }}
          disabled={fileState === 'load'}
        >
          {t`take_a_photo`}
        </Button>
      )}
      {photoModalsState.isTakePhotoInstructionOpen && (
        <IDCardPhotoInstructionsModal
          onOpen={openWebcamHandler}
          type={type}
          instructions={instructions}
          onClose={closeWebcamHandler}
        />
      )}
      {photoModalsState.isTakePhotoOpen && (
        <FileTakePhotoModal
          onClose={closeWebcamHandler}
          isOpen={photoModalsState.isTakePhotoOpen}
          onUpload={onUpload}
          fileState={fileState}
          type={type}
          changeFile={changeFile}
        />
      )}
      {/*{error && <ErrorMessageCommon message={error} />}*/}
    </>
  );
};
