import React, { useCallback, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Webcam from 'react-webcam';
import { isMobile } from 'react-device-detect';
import { Button, ModalLayout } from 'core/components/common';
import { DocumentsType } from 'models';
import CameraPermissionImg from 'assets/images/camera_permission.png';
import { onUploadTakenPhoto } from 'utils';
import { sendLivenessImage } from 'store/actions/api';
import { IPhotoUploadInitStateProps } from '../UploadPhotoComponent';
import { livenessProps } from '../LivenessEngines/LivenessEngines';
import { useAppDispatch } from 'hooks';
import { LIVENESS } from 'constantsLk';

export const FileTakeSelfieModal = ({
  handleCloseCameraModal,
  onSubmitHandler,
  ...props
}: livenessProps) => {
  const {
    sendLog,
    setStateComponent,
    defaultUploadFileState,
    documentFile,
    setValue,
    setErrorsArray,
  } = props;
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const [isCameraAllow, setIsCameraAllow] = useState(true);

  const webcamRef = useRef<Webcam>(null);

  const videoConstraints = {
    facingMode: 'user',
    height: { min: isMobile ? 480 : 720 },
    width: { min: isMobile ? 720 : 480 },
    aspectRatio: isMobile ? 1.3 : 0.7666666667,
  };

  const onCapture = useCallback(async () => {
    sendLog({ event: 'Click to Take a photo button (2)' }, LIVENESS.BIOID);

    setStateComponent({ ...defaultUploadFileState, state: 'load', error: '' });
    const imageSrc = webcamRef.current?.getScreenshot();

    if (imageSrc) {
      handleCloseCameraModal && handleCloseCameraModal();

      try {
        const file = await onUploadTakenPhoto(imageSrc);
        const res = await dispatch(sendLivenessImage({ file })).unwrap();
        const state = res.state;

        if (state) {
          const data: IPhotoUploadInitStateProps = {
            ...defaultUploadFileState,
            state: 'done',
            file: imageSrc,
            file_type: documentFile.type,
          };
          setStateComponent(data);
          setValue(DocumentsType.Selfie, imageSrc);
          sendLog(res, LIVENESS.BIOID)
            .then((res) => onSubmitHandler(res.id)())
            .catch(() => onSubmitHandler()());
        } else {
          const result = JSON.parse(res.data);
          let errors: string[];

          if (result.Message) {
            errors = [result.Message];
          } else if (result.Samples[0].Errors.length) {
            errors = result.Samples[0].Errors.map((error: any) => error.Code);
          } else {
            errors = ['please_make_new_selfie'];
          }
          setErrorsArray(errors);
          const data: IPhotoUploadInitStateProps = { ...defaultUploadFileState };
          setStateComponent(data);
          sendLog(res, LIVENESS.BIOID);
        }
      } catch (e) {
        setStateComponent({
          ...defaultUploadFileState,
          error: 'please_make_new_selfie',
        });
        sendLog(e, LIVENESS.BIOID);
      }
    }
  }, [webcamRef]);

  return (
    <ModalLayout
      isOpenModal
      onClose={handleCloseCameraModal}
      title={t`capture_selfie`}
      isShowClose
      isUsePortal
      content={
        isCameraAllow ? (
          <div className='webcam webcam-container'>
            <Webcam
              audio={false}
              ref={webcamRef}
              screenshotFormat='image/jpeg'
              videoConstraints={videoConstraints}
              onUserMediaError={() => setIsCameraAllow(false)}
            />
            <div className='webcam__circle'></div>
          </div>
        ) : (
          <div className='modal-core__header flex-column pt-0'>
            <h2 className='txt-sb'>{t('what_if_i_denied_access')}</h2>
            <div className='flex-center'>
              <img src={CameraPermissionImg} alt='PNG Image' />
            </div>
          </div>
        )
      }
      footer={
        isCameraAllow ? (
          <Button onClick={onCapture} classes={{ root: 'btn--primary w-100' }}>
            <span>{t`take_a_photo`}</span>
          </Button>
        ) : (
          <></>
        )
      }
    />
  );
};
