import { useRef } from 'react';
import { paymentBanksSelector } from 'store/selectors';
import { useTypedSelector } from 'core/hooks';
import { SelectFieldHookForm } from 'core/components/fields';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Button, ModalLayout, CloseIcon } from 'core/components/common';
import { SubmitCoreHandler } from 'core/models';
import { PaymentSlipField, PaymentSlipModel } from 'models';
import { validateSelect } from 'utils/validates/validation';
import { AttachIcon } from '../../icons';

interface RepaymentSlipModalProps {
  onClose: () => void;
  isOpenModal: boolean;
  onSubmit: SubmitCoreHandler<PaymentSlipModel>;
  loanId: number | undefined;
}

export const RepaymentSlipModal = (props: RepaymentSlipModalProps) => {
  const { onClose, isOpenModal, onSubmit, loanId } = props;

  const { t } = useTranslation();
  const fileInput = useRef(null);
  const paymentBanks = useTypedSelector(paymentBanksSelector);

  const {
    formState: { isSubmitting, errors },
    handleSubmit,
    setError,
    setValue,
    control,
    clearErrors,
    watch,
  } = useForm<PaymentSlipModel>({
    mode: 'onSubmit',
    shouldUnregister: true,
    defaultValues: {
      file: null,
    },
  });

  const file = watch(PaymentSlipField.File);
  const bank = watch(PaymentSlipField.Bank);

  const onSubmitHandler = handleSubmit((data) =>
    onSubmit?.(data, {
      setError,
    }),
  );

  const changeUploadFile = (e: any) => {
    const file = e.target.files[0];
    setValue(PaymentSlipField.File, file);
  };

  const resetFile = () => {
    setValue(PaymentSlipField.File, null);
    clearErrors();
  };

  const onCloseModal = () => {
    resetFile();
    onClose();
  };

  return (
    <ModalLayout
      isOpenModal={isOpenModal}
      onClose={onCloseModal}
      title={t('repayment_slip_title', { loanId })}
      classes={{ wrapper: 'gap-0', content: `payment-slip-modal ${!file && 'overflow'}` }}
      isShowClose
      isUsePortal
      isOutSideClick
      content={
        <form onSubmit={onSubmitHandler} className='mt-4 w-100'>
          <SelectFieldHookForm
            control={control}
            label={t`please_select_the_bank`}
            name={PaymentSlipField.Bank}
            options={paymentBanks.map((bank) => ({ label: bank.name, value: bank.id }))}
            rules={validateSelect}
            maxMenuHeight={177}
          />

          <div className='w-100 mt-5'>
            {!file ? (
              <div className='justify-content-center'>
                <label className='btn-dashed'>
                  <AttachIcon />
                  <span>{t('attach_your_file')}</span>
                  <input accept='image/*' type='file' ref={fileInput} onChange={changeUploadFile} />
                </label>
              </div>
            ) : (
              <>
                <div className='align-items-center gap-3 mb-2'>
                  <span className='txt txt-ellipsis'>{file?.name}</span>
                  <span className='cursor-pointer align-items-center' onClick={resetFile}>
                    <CloseIcon />
                  </span>
                </div>
                {errors.root?.slipError && (
                  <span className='error-text'>{errors.root?.slipError.message}</span>
                )}
                <img src={URL.createObjectURL(file)} alt='Uploaded file' />
              </>
            )}
          </div>
        </form>
      }
      footer={
        <Button
          className='btn btn--primary w-100 flex-center'
          disabled={!file || isSubmitting || !!errors.root?.slipError || !bank}
          onClick={onSubmitHandler}
        >
          <span>{t('send')}</span>
        </Button>
      }
    />
  );
};
