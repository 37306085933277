import { Location, useLocation } from 'react-router-dom';
import { RegistrationLayoutProgressBar } from 'layouts';
import * as Paths from 'constantsLk/paths';

const getProgressBarVisibilityByLocation = (location: Location): boolean => {
  const locationsToHide: string[] = [Paths.PATH_REG_STEP_1, Paths.PATH_REG_STEP_2];

  return !locationsToHide.find((item) => location.pathname.includes(item));
};

export const RegistrationProgress = () => {
  const location = useLocation();
  const isVisibleProgressBar = getProgressBarVisibilityByLocation(location);

  const step =
    Object.keys(Paths.PATHS_REGISTRATION_STEPS).find((key) =>
      location.pathname.includes(Paths.PATHS_REGISTRATION_STEPS[key]),
    ) || 1;

  return <>{isVisibleProgressBar && <RegistrationLayoutProgressBar step={step} />}</>;
};
