import { objKeysToNumber } from './objectKeysToNumber';
import { WpKeys } from 'core/models';
import { getCookie } from 'core/services';
import { IPrice, ServerLoanSettings } from 'models';
import { LoanService } from '../services';

export interface ILoanSettings {
  amount: number;
  term: number;
}

export const getLoanSettings = (
  price: IPrice,
  defaultValues: ServerLoanSettings,
): ILoanSettings => {
  const loanTermCookie = getCookie(WpKeys.LoanTerm) ?? '';
  const loanSumCookie = getCookie(WpKeys.LoanSum) ?? '';

  const minTerm = objKeysToNumber(price)[0];
  const maxTerm = objKeysToNumber(price)[objKeysToNumber(price).length - 1];
  const minAmount = objKeysToNumber(price[minTerm])[0];

  const term =
    defaultValues?.default_tenor && defaultValues?.default_tenor > maxTerm ? maxTerm : minTerm;

  const defaultTerm =
    (defaultValues?.default_tenor &&
      objKeysToNumber(price).find((item) => {
        return +item === defaultValues?.default_tenor;
      })) ??
    term;

  const defaultAmount =
    (defaultValues?.default_principal &&
      objKeysToNumber(price[defaultTerm]).find((item) => {
        return +item === defaultValues?.default_principal;
      })) ??
    0;

  const isValidCookieLoanAmount = Object.keys(price[minTerm]).includes(loanSumCookie.toString());
  const isValidCookieLoanTerm = Object.keys(price).includes(loanTermCookie.toString());

  const newAmount =
    isValidCookieLoanAmount && Number(loanSumCookie) >= defaultAmount
      ? Number(loanSumCookie)
      : defaultAmount || minAmount;

  const newTerm =
    isValidCookieLoanTerm && Number(loanTermCookie) >= defaultTerm
      ? Number(loanTermCookie)
      : defaultTerm || minTerm;

  LoanService.setTerm = newTerm;
  LoanService.setSum = newAmount;

  return {
    amount: newAmount,
    term: newTerm,
  };
};
