import { UseFormClearErrors } from 'react-hook-form';
import { useAppDispatch, usePromoCode } from 'hooks';
import { applyPromoCode, getUserApplications } from 'store/actions/api';
import { LoanSmsConfirmFormModel } from 'models';
import { PromoCodeFieldEnterButton } from 'components/PromoCodeField';

interface PromoCodeProps {
  clearErrors: UseFormClearErrors<LoanSmsConfirmFormModel>;
  onPromoCodeApply: () => void;
  activeApplicationId: number;
}

export const SmsConfirmLoanPromoCodeContainer = (props: PromoCodeProps) => {
  const { clearErrors, onPromoCodeApply, activeApplicationId } = props;
  const dispatch = useAppDispatch();

  const { formState, value, onEnterPromoCode, onResetCode, onConfirm } = usePromoCode({
    clearErrors,
    onSuccess: async () => {
      onPromoCodeApply();
      await dispatch(getUserApplications());
    },
    handlePromoCodeAction: async (promoCode) => {
      await dispatch(applyPromoCode({ appId: activeApplicationId, promoCode })).unwrap();
    },
  });

  return (
    <PromoCodeFieldEnterButton
      onResetCode={onResetCode}
      formState={formState}
      value={value}
      onConfirm={onConfirm}
      onToggle={onEnterPromoCode}
    />
  );
};
