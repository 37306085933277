import React from 'react';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';

import { PATH_LOGIN, PATH_REG_STEP_1 } from 'constantsLk';

export const MetaTags = () => {
  const location = useLocation();
  const { t, i18n } = useTranslation();
  const isFirstStep = location.pathname.includes(PATH_REG_STEP_1);
  const isLogin = location.pathname.includes(PATH_LOGIN);

  const lang = i18n.language === 'en' ? '/en' : '';
  const title = isFirstStep ? t`metatag_reg_title` : isLogin ? t`metatag_login_title` : '';

  const description = isFirstStep
    ? t`metatag_reg_description`
    : isLogin
    ? t`metatag_login_description`
    : '';

  const canonical = isFirstStep
    ? `https://cashx.lk${lang}/user/registration/reg1`
    : isLogin
    ? `https://cashx.lk${lang}/user/login`
    : '';

  const isIndex = isLogin || isFirstStep;

  return (
    <Helmet>
      <title>{title}</title>
      {isIndex && <meta name='robots' content='index,follow' />}
      {isIndex && <meta name='description' content={description} />}
      {isIndex && <link rel='canonical' href={canonical} />}
    </Helmet>
  );
};
