import { Logo, UserName } from '../../Header';
import { User } from 'models';
import { PATH_ACCOUNT } from 'constantsLk';

interface SidebarHeadProps {
  workingHours?: string;
  phone?: string;
  onClickLogo: () => void;
  user: User | null;
}

export const SidebarHead = (props: SidebarHeadProps) => {
  const { onClickLogo, user } = props;

  const isAccount = location.pathname.includes(PATH_ACCOUNT);

  return (
    <div className='flex-grow-1 gap-1'>
      <Logo onClickLogo={onClickLogo} />

      {isAccount && <UserName user={user} />}
    </div>
  );
};
