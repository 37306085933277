import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ModalLayout } from 'core/components/common';

export const PaidEarlyModal = () => {
  const { t } = useTranslation();

  const [isOpenModal, setIsOpenModal] = useState(true);

  const onClose = () => setIsOpenModal(false);

  return (
    <ModalLayout
      isOpenModal={isOpenModal}
      onClose={onClose}
      isOutSideClick
      title={t`paid_early`}
      classes={{ wrapper: 'modal-core__wrapper' }}
      isShowClose
      isUsePortal
      content={<p className='txt'>{t`remove_the_reminder`}</p>}
    />
  );
};
