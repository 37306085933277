import { useState } from 'react';
import { ChevronDownIcon, ChevronUpIcon } from 'components/icons';

interface DropdownProps {
  icon?: JSX.Element;
  trigger: string;
  separator?: boolean;
  menu: Array<JSX.Element>;
}

export const Dropdown = ({ trigger, menu, icon, separator = true }: DropdownProps) => {
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(!open);
  };

  return (
    <div className={`dropdown ${open ? 'dropdown-expanded' : ''}`}>
      <div className='dropdown-header' onClick={handleOpen}>
        {icon}
        <span className='dropdown-title'>{trigger}</span>
        {open ? <ChevronUpIcon /> : <ChevronDownIcon />}
      </div>
      <ul className={`dropdown-menu ${open ? 'expanded' : separator ? 'separator' : ''}`}>
        {menu.map((menuItem, index) => (
          <li
            key={index}
            style={{ animationDelay: `${(index + 1) * 60}ms` }}
            className={`menu-item menu-item-${index}`}
          >
            {menuItem}
          </li>
        ))}
      </ul>
    </div>
  );
};
