import { useTranslation } from 'react-i18next';
import { parseISO } from 'date-fns';
import { formatNumber } from 'core/utils/parsers';
import { formatDate } from 'utils/parsers';

interface Income {
  id: string | number;
  amount: number;
  bank_name: string;
  loan_id: number;
  received_at: string;
}

interface PaymentCardProps {
  income: Income;
}

export const PaymentCard = (props: PaymentCardProps) => {
  const { income } = props;
  const { t } = useTranslation();

  return (
    <div className='payment-card mb-6 flex-column'>
      <div className='lined-card-row'>
        <p className='txt-sb'>{t`contract_number`}</p>
        <div className='info-item'>№ {income.loan_id}</div>
      </div>
      <div className='lined-card-row'>
        <p className='txt-sb'>{t`payment_amount`}</p>
        <div className='info-item'>{formatNumber(income.amount)} RS</div>
      </div>
      <div className='lined-card-row'>
        <p className='txt-sb'>{t`payment_date`}</p>
        <div className='info-item'>{formatDate(parseISO(income.received_at))}</div>
      </div>
    </div>
  );
};
