import { useTranslation } from 'react-i18next';
import { AppNavLinkContainer } from 'core/components/common';
import { SubmitCoreHandler } from 'core/models';
import { AuthFormModel, Title } from 'components';
import { AuthFormContainer } from 'components/AuthForm/AuthFormContainer';
import { ArchiveUserModal } from 'components/modals/ArchiveUserModal';
import * as Paths from 'constantsLk/paths';

export interface Step1RegistrationProps {
  onSubmit: SubmitCoreHandler<AuthFormModel>;
  oldUserPhone: string | null;
  resetOldUserPhone: () => void;
}

export const Step1 = (props: Step1RegistrationProps) => {
  const { onSubmit, oldUserPhone, resetOldUserPhone } = props;
  const { t } = useTranslation();

  return (
    <>
      <Title classes={'form-title phone-mt-3'} name={'contact_details'} />
      <AuthFormContainer onSubmit={onSubmit} storageKey={Paths.PATH_REG_STEP_1} />
      <AppNavLinkContainer
        className='flex-center mt-4'
        to={`/${Paths.URL_PATH_LOGIN}`}
      >{t`log_in`}</AppNavLinkContainer>
      {oldUserPhone && (
        <ArchiveUserModal phoneNumber={oldUserPhone} resetOldUserPhone={resetOldUserPhone} />
      )}
    </>
  );
};
