import { PropsWithChildren } from 'react';

import { useTypedSelector } from 'core/hooks';

import { UserGuard } from './UserGuard';
import { isAuthorizedSelector } from 'store/selectors';
import { UserGuardContainerProps } from './UserGuardContainer';
import { URL_USER_REGISTRATION } from 'constantsLk';

export const RegistrationUserGuardContainer = (
  props: PropsWithChildren<UserGuardContainerProps>,
) => {
  const { children, ...rest } = props;

  const isAuthorized = useTypedSelector(isAuthorizedSelector);

  return (
    <UserGuard {...rest} redirectTo={URL_USER_REGISTRATION} isAuthorized={isAuthorized}>
      {children}
    </UserGuard>
  );
};
