import { useEffect } from 'react';
import { useTypedSelector } from 'core/hooks';
import { SubmitCoreHandler, WpKeys } from 'core/models';
import { setRedirect } from 'core/store/actions';
import { getUserID, removeCookie } from 'core/services';
import { INIT_VALUES_FORM_KEY } from 'core/constants';

import { LoanCalculatorForm } from 'models';
import { useAppDispatch } from 'hooks';
import { LoanService } from 'services';
import { getPromoCodeErrorMessage } from 'utils';
import { defaultPricesSelector, pricesSelector, productIdSelector } from 'store/selectors';
import { getCalculatorConfigActions, signIn } from 'store/actions';
import { Spinner } from 'components';
import { createApplication, getProduct, getProductId } from 'store/actions/api';
import { Step9ConfirmationConditions } from './Step9ConfirmationConditions';
import { USER_STATE } from 'constantsLk';
import * as Paths from 'constantsLk/paths';

export const Step9ConfirmationConditionsContainer = () => {
  const dispatch = useAppDispatch();
  const price = useTypedSelector(pricesSelector);
  const productId = useTypedSelector(productIdSelector);
  const defaultPrices = useTypedSelector(defaultPricesSelector);

  useEffect(() => {
    dispatch(getProductId());
  }, []);

  useEffect(() => {
    const data = {
      productId,
      userId: getUserID(),
    };

    if (productId) {
      dispatch(getProduct({ ...data, promoCode: LoanService.promoCode }))
        .unwrap()
        .catch(() => {
          removeCookie(WpKeys.LoanPromoCode);
          dispatch(getProduct(data));
        });
    }
  }, [productId]);

  useEffect(() => {
    if (price) {
      dispatch(getCalculatorConfigActions());
    }
  }, [price]);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const onSubmit: SubmitCoreHandler<LoanCalculatorForm> = async (values, { setError }) => {
    const promoCode = LoanService.promoCode;

    return dispatch(createApplication({ ...values, promoCode, productId }))
      .unwrap()
      .then(() => {
        return dispatch(signIn())
          .unwrap()
          .then((res) => {
            LoanService.setSum = values.amount;
            LoanService.setTerm = values.term;
            removeCookie(WpKeys.LoanPromoCode);
            removeCookie(INIT_VALUES_FORM_KEY);
            if (res?.state === USER_STATE.ACTIVE) {
              dispatch(setRedirect({ to: Paths.URL_ACTIVE_LOAN, replace: true }));
            }
          });
      })

      .catch((err) => {
        removeCookie(WpKeys.LoanPromoCode);
        const message = getPromoCodeErrorMessage(err.message);
        setError && setError('root.commonError', { type: 'validate', message });

        dispatch(getProduct({ userId: getUserID(), productId }));
      });
  };

  return price && !!defaultPrices.default_principal ? (
    <Step9ConfirmationConditions price={price} onSubmit={onSubmit} defaultPrices={defaultPrices} />
  ) : (
    <Spinner />
  );
};
