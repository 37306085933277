import { removeCookie } from 'core/services';
import { TIMER_KEY } from 'core/constants';

export const removeLoginTimer = () => removeCookie(`${TIMER_KEY}_LoginConfirmPhoneWithSMS`);
export const removeRegSmsTimer = () => removeCookie(`${TIMER_KEY}_Step2ConfirmPhoneWithSMS`);
export const removeRegCallTimer = () => removeCookie(`${TIMER_KEY}_Step2ConfirmPhoneWithCall`);

export const removeAllTimerCookies = () => {
  removeRegSmsTimer();
  removeRegCallTimer();
  removeLoginTimer();
};
