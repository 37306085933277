import { CSSObjectWithLabel, GroupBase, OptionProps, StylesConfig } from 'react-select';
import { Option } from 'commander';

export const selectStyles: StylesConfig = {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  option: (
    base: CSSObjectWithLabel,
    state: OptionProps<unknown | boolean | GroupBase<Option>>,
  ) => ({
    ...base,
    backgroundColor: state.isSelected ? '#6c80bb !important' : 'white',
    color: state.isSelected ? 'white !important' : '#0C122A',
  }),
};
