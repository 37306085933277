import { SubmitCoreHandler } from 'core/models';
import { useTypedSelector } from 'core/hooks';
import { setRedirect } from 'core/store/actions';

import {
  PersonalDataProcessingDialogModal,
  PersonalDataProcessingDialogModalForm,
} from './PersonalDataProcessingDialogModal';
import { wpContentFooterSelector } from 'store/selectors';
import { updateUser } from 'store/actions/api';
import { useAppDispatch } from 'hooks';
import { getRegStepPath } from 'constantsLk';

export const PersonalDataProcessingDialogModalContainer = () => {
  const dispatch = useAppDispatch();

  // const { content: { term_conditions } } = useSelector(state => ({
  //   content: state.siteContent.content,
  // }));
  const wpContentFooter = useTypedSelector(wpContentFooterSelector);

  const onSubmit: SubmitCoreHandler<PersonalDataProcessingDialogModalForm> = async (
    values,
    // { setError },
  ) => {
    // console.log(values);

    return (
      dispatch(updateUser(values))
        .unwrap()
        .then((res) => {
          dispatch(setRedirect({ to: getRegStepPath(res.registration_step) }));
        })
        // eslint-disable-next-line no-console
        .catch((err) => console.log(err))
    );
  };

  return (
    <PersonalDataProcessingDialogModal
      onSubmit={onSubmit}
      term_conditions={wpContentFooter?.documents[1]?.text_content}
    />
  );
};
