import React, { useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import { formatNumber, onlyNumbers } from 'core/utils/parsers';
import { Button, Slider } from 'core/components/common';
import { SubmitCoreHandler } from 'core/models';
import { useAppDispatch, usePromotion } from 'hooks';
import {
  ITopUpPrice,
  LoanCalculatorFields,
  TopUpCounterOfferField,
  TopUpCounterOfferModel,
  TopUpModel,
} from 'models';
import { PromotionTimerIcon } from '../icons';
import { Timer } from '../../pages/account/AccountLoan/ActiveLoan/Timer';
import { getUserLoans, topUpTransition } from 'store/actions/api';
import { TopUpPriceInfo } from './TopUpPriceInfo';
import { SLIDER_THEME } from 'constantsLk';

interface TopUpInfoProps {
  topUpInfo: TopUpModel;
  topUpPriceList: ITopUpPrice | null;
}

export const TopUpCalculator = (props: TopUpInfoProps) => {
  const { topUpInfo, topUpPriceList } = props;
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const [conditions, setConditions] = useState({
    amount: topUpInfo.max_amount,
  });

  const {
    handleSubmit,
    setValue,
    setError,
    formState: { isSubmitting },
  } = useForm<TopUpCounterOfferModel>({
    mode: 'onSubmit',
    defaultValues: {
      amount: conditions.amount,
    },
  });

  const { timeLeft, promoStatus, isBlinking } = usePromotion(topUpInfo.expires_at);

  const onChangeSlider = (name: string, value: number) => {
    setConditions((prevState) => ({ ...prevState, [name]: value }));
    setValue(TopUpCounterOfferField.Amount, value);
  };

  const returnSliderData = useMemo(() => {
    const maxAmount = topUpInfo.max_amount;
    const minAmount = topUpInfo.min_amount;

    const amountArray = Array.from(
      { length: (maxAmount - minAmount) / 1000 + 1 },
      (_, i) => minAmount + i * 1000,
    );

    const indexOfAmount = amountArray?.indexOf(Number(conditions?.amount)) || 0;

    return { amountArray, indexOfAmount };
  }, [topUpInfo.max_amount, topUpInfo.min_amount]);

  const onSubmit: SubmitCoreHandler<TopUpCounterOfferModel> = async (values) => {
    const data = {
      name: 'request',
      params: values,
    };
    return (
      dispatch(topUpTransition({ id: topUpInfo.id, data }))
        .unwrap()
        .then(() => dispatch(getUserLoans()))
        // eslint-disable-next-line no-console
        .catch((err) => console.log(err))
    );
  };

  const onSubmitHandler = handleSubmit((data) =>
    onSubmit?.(data, {
      setError,
    }),
  );

  return (
    <div className='card'>
      <div className={`promo-timer ${promoStatus} ${isBlinking ? 'blinking' : ''}`}>
        <span className='flex align-items-center txt-md-14 txt-sb gap-2'>
          <PromotionTimerIcon /> {t`offer_is_available`}
        </span>
        <div className='time'>
          <Timer timeLeft={timeLeft} />
        </div>
      </div>

      <h2 className='txt-md txt-sb mb-2'>
        <Trans
          i18nKey='increase_your_loan_amount'
          values={{ amount: `${formatNumber(topUpInfo.max_amount)}` }}
        />
      </h2>

      <Slider
        colors={SLIDER_THEME}
        values={returnSliderData.amountArray}
        name={LoanCalculatorFields.Amount}
        prefix='RS'
        currentValue={returnSliderData.indexOfAmount}
        onChangeSlider={onChangeSlider}
        texFieldProps={{
          name: TopUpCounterOfferField.Amount,
          type: 'tel',
          parsers: [onlyNumbers],
        }}
      />

      <form onSubmit={onSubmitHandler}>
        <Button disabled={isSubmitting} className='btn btn--primary w-100 mt-5'>
          {t('get_settlement_amount', { amount: formatNumber(conditions.amount) })}
        </Button>
      </form>

      {topUpPriceList && (
        <TopUpPriceInfo topUpPriceItem={topUpPriceList[conditions.amount]} isRequestBlock />
      )}
    </div>
  );
};
