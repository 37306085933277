import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Loan } from 'models';
import { StateLoan } from './StateLoan';
import { ArrowIcon } from 'components';

interface PriceInfoProps {
  activeCreditData: Loan;
}

export const PriceInfo = ({ activeCreditData }: PriceInfoProps) => {
  const {
    accrued_principal,
    accrued_late_payment_fee,
    accrued_consulting_fee,
    accrued_service_fee,
    accrued_consulting_fee_add,
    accrued_service_fee_add,
    accrued_transaction_fee,
    accrued_total,
  } = activeCreditData;

  const { t } = useTranslation();
  const [isContentShow, setIsContentShow] = useState<boolean>(false);

  const handleShowContent = () => setIsContentShow(!isContentShow);

  return (
    <div className='card flex-column justify-content-between'>
      <div className='flex-grow-1 mb-3'>
        <StateLoan activeCreditData={activeCreditData} />
      </div>
      <div className='flex-column gap-3'>
        <div className='lined-card-row'>
          <span className='txt-xs txt-sb'>{t('total_repayment')}</span>
          <span
            className={`${isContentShow ? 'up ' : ''}btn-secondary info-item`}
            onClick={handleShowContent}
          >
            <span>{accrued_total} RS</span>
            <span className='arrow ml-2'>
              <ArrowIcon />
            </span>
          </span>
        </div>
        {isContentShow && (
          <>
            <div className='lined-card-row'>
              <span className='txt-xs txt-sb'>{t('body')}</span>
              <span className='info-item'>{accrued_principal} RS</span>
            </div>
            {!!accrued_transaction_fee && (
              <div className='lined-card-row'>
                <span className='txt-xs txt-sb'>{t('transaction_fee')}</span>
                <span className='info-item'>{accrued_transaction_fee} RS</span>
              </div>
            )}
            <div className='lined-card-row'>
              <span className='txt-xs txt-sb'>{t('service_fee')}</span>
              <span className='info-item'>{accrued_service_fee + accrued_service_fee_add} RS</span>
            </div>
            <div className='lined-card-row'>
              <span className='txt-xs txt-sb'>{t('cons_fee')}</span>
              <span className='info-item'>
                {accrued_consulting_fee + accrued_consulting_fee_add} RS
              </span>
            </div>
            {!!accrued_late_payment_fee && (
              <div className='lined-card-row'>
                <span className='txt-xs txt-sb'>{t('fines')}</span>
                <span className='info-item error'>{accrued_late_payment_fee} RS</span>
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
};
