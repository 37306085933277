import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Slider } from 'core/components/common';
import { onlyNumbers } from 'core/utils/parsers';
import { IReferralCalculator, LoanCalculatorFields } from 'models';
import { SLIDER_THEME } from 'constantsLk';

interface ReferralSliderProps {
  referralAmount: number | null;
  onChangeReferralAmountSlider: (name: string, val: number) => void;
  sliderData: IReferralCalculator;
  title?: string;
}
export const ReferralSlider = (props: ReferralSliderProps) => {
  const { onChangeReferralAmountSlider, sliderData, referralAmount, title } = props;

  const { t } = useTranslation();

  const createArrayForSlider = (start: number, step: number, end: number) => {
    const array = [];
    for (let i = start; i <= end; i += step) {
      array.push(i);
    }

    if (array[array.length - 1] !== end) {
      array.push(end);
    }
    return array;
  };

  const paymentArray = createArrayForSlider(
    sliderData.min_applicable_amount,
    sliderData.amount_step,
    sliderData.max_applicable_amount,
  );

  useEffect(() => {
    onChangeReferralAmountSlider(
      LoanCalculatorFields.Amount,
      sliderData.default_min_applicable_amount,
    );
  }, []);

  useEffect(() => {
    if (referralAmount && referralAmount > paymentArray[paymentArray.length - 1]) {
      onChangeReferralAmountSlider(
        LoanCalculatorFields.Amount,
        paymentArray[paymentArray.length - 1],
      );
    } else if (referralAmount && referralAmount % sliderData.amount_step !== 0) {
      const difference = referralAmount % sliderData.amount_step;

      onChangeReferralAmountSlider(LoanCalculatorFields.Amount, referralAmount - difference);
    }
  }, [sliderData]);

  return (
    <div className='mb-3'>
      {title && (
        <p
          className={`txt txt-md-16 txt-sb mb-2 pt-4 border-top-light`}
        >{t`use_bonuses_for_fees`}</p>
      )}
      <Slider
        colors={SLIDER_THEME}
        values={paymentArray}
        name={LoanCalculatorFields.Amount}
        prefix='RS'
        title={t('payment_amount')}
        currentValue={paymentArray?.indexOf(Number(referralAmount)) || 0}
        onChangeSlider={onChangeReferralAmountSlider}
        texFieldProps={{
          name: LoanCalculatorFields.Amount,
          type: 'tel',
          parsers: [onlyNumbers],
        }}
      />
    </div>
  );
};
