import { useTranslation } from 'react-i18next';
import { useTypedSelector } from 'core/hooks';
import { wpContentHeaderSelector } from 'store/selectors';
import { RequestStatus } from '../RequestStatus';
import { ErrorIcon } from '../icons';
import { PARTNERS_LINK } from 'constantsLk';

export const LoginForbidden = () => {
  const { t } = useTranslation();
  const wpContentHeader = useTypedSelector(wpContentHeaderSelector);

  return (
    <div className='flex-column gap-4 mb-8'>
      <RequestStatus
        icon={<ErrorIcon />}
        text={
          <p>
            {t`please_contact_call_center_login`}
            <a className='phone txt-nowrap' href={`tel:${wpContentHeader?.contacts.phone}`}>
              {wpContentHeader?.contacts.phone}
            </a>
          </p>
        }
        isShowPartners
        isAuthPage
      />

      <RequestStatus
        title={t`you_need_money`}
        text={t`can_get_a_loan`}
        footer={
          <div className='flex-column mt-2 w-100 flex-center tablet-mt-4'>
            <a
              className='btn btn--primary w-100'
              href={PARTNERS_LINK}
              target='_blank'
              rel='noreferrer'
            >
              <span>{t`get_a_loan`}</span>
            </a>
          </div>
        }
        isShowPartners
        isAuthPage
      />
    </div>
  );
};
