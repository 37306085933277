import { useTypedSelector } from 'core/hooks';
import { isSubheaderShowSelector } from 'store/selectors';
import { logOutUtil } from 'utils';
import { Subheader } from './Subheader';

export const SubheaderContainer = () => {
  const isSubheaderShow = useTypedSelector(isSubheaderShowSelector);
  
  const handleLogout = () => {
    logOutUtil();
    window.location.reload();
  };
  
  return isSubheaderShow ? <Subheader onLogout={handleLogout} /> : null;
}
