import React, { ChangeEvent, FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from 'core/components/common';
import { CameraAccessDeniedFAQModalContainer } from 'components/modals';
import { DUPLICATE_ACTION } from '../../../constantsLk/duplicateActions';

interface IMobileCameraProps {
  changeFile: (e: ChangeEvent<HTMLInputElement>) => void;
  type: string;
  fileState: string;
  instructionHandler: () => void;
  sendLog?: (action: string, additional_data: string, source_user_id?: number) => any;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const MobileCamera: FC<IMobileCameraProps> = ({
  // changeFile,
  // type,
  fileState,
  instructionHandler,
  sendLog,
}) => {
  const { t } = useTranslation();
  // const fileInput = useRef(null);

  const [isCameraAllow, setCameraAllow] = useState(true);
  const [isOpenCameraFAQ, setIsOpenCameraFAQ] = useState(false);

  const cameraInstructionHandler = () => {
    setIsOpenCameraFAQ((prev) => !prev);
  };

  useEffect(() => {
    const askCameraPermission = async () =>
      await navigator.mediaDevices.getUserMedia({ video: true });

    askCameraPermission()
      .then(() => {
        sendLog && sendLog(DUPLICATE_ACTION.CAMERA_ACCESS, 'true');
        setCameraAllow(true);
      })
      .catch(() => {
        sendLog && sendLog(DUPLICATE_ACTION.CAMERA_ACCESS, 'false');
        setCameraAllow(false);
      });
  }, []);

  return (
    <>
      <Button
        onClick={isCameraAllow ? instructionHandler : cameraInstructionHandler}
        classes={{ root: 'btn--primary mt-0' }}
        disabled={fileState === 'load'}
      >
        {t`take_a_photo`}
      </Button>

      {isOpenCameraFAQ && (
        <CameraAccessDeniedFAQModalContainer onClose={cameraInstructionHandler} />
      )}
    </>
  );
};
