import { Trans, useTranslation } from 'react-i18next';
import { ModalLayout } from 'core/components/common';
import { ErrorIcon, LoanPlusIcon } from '../../icons';
import { PARTNERS_LINK } from 'constantsLk';

interface NICErrorModalProps {
  onLogout: () => void;
  supportPhone?: string;
  userPhone?: string;
  hasOverdueLoan: boolean | undefined;
  hasSoldLoan: boolean | undefined;
  inBlackList: boolean | undefined;
  hasMoratorium: boolean | undefined;
}

export const NICErrorModal = (props: NICErrorModalProps) => {
  const { userPhone, onLogout, hasOverdueLoan, hasSoldLoan, inBlackList, hasMoratorium } = props;

  const { t } = useTranslation();

  const getErrorMessage = () => {
    const phoneSpan = <span onClick={onLogout} className='link txt-nowrap' />;
    const matchedPhone = `+94 ${userPhone}`;

    const transComponent = (
      i18nKey: string,
      values: { matched_user_phone: string; support_service_phone?: string },
      components: any,
    ) => (
      <p>
        <Trans i18nKey={i18nKey} values={values} components={components} />
      </p>
    );

    if (inBlackList) {
      return (
        <div className='partner-modal flex-column gap-4'>
          <p>{t`in_black_list`}</p>
          <div className='flex-center w-100 info-item gap-3'>
            <span className='icon flex-center'>
              <LoanPlusIcon />
            </span>
            <a
              className='btn btn--primary w-100'
              href={PARTNERS_LINK}
              target='_blank'
              rel='noreferrer'
            >
              <span>{t`apply_now`}</span>
            </a>
          </div>
        </div>
      );
    }

    if (hasSoldLoan) {
      return transComponent(
        'has_sold_loan',
        { matched_user_phone: matchedPhone },
        { a1: phoneSpan },
      );
    }

    if (hasOverdueLoan) {
      return transComponent(
        'has_overdue_loan',
        { matched_user_phone: matchedPhone },
        { a1: phoneSpan },
      );
    }

    if (hasMoratorium) {
      return transComponent(
        'has_moratorium',
        { matched_user_phone: matchedPhone },
        { a1: phoneSpan },
      );
    }
  };
  return (
    <ModalLayout
      isShowClose={false}
      isOpenModal
      title={<ErrorIcon />}
      classes={{ wrapper: 'modal-core__wrapper--user-matched' }}
      showAppHeader
      isUsePortal
      content={
        <div className='flex-column gap-2'>
          <p className='txt-md txt-sb mt-3 txt-black'>{t`nic_number_is_registered`}</p>
          {getErrorMessage()}
        </div>
      }
    />
  );
};
