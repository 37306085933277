// Ref: https://www.unicode.org/cldr/cldr-aux/charts/32/summary/si.html
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import buildFormatLongFn from 'date-fns/locale/_lib/buildFormatLongFn';

const dateFormats = {
  full: 'y MMMM do EEEE',
  long: 'y MMMM d',
  medium: 'y MMM d',
  short: 'y-MM-dd',
};

const timeFormats = {
  full: 'a h:mm:ss zzzz',
  long: 'a h:mm:ss z',
  medium: 'a h:mm:ss',
  short: 'a h:mm',
};

const dateTimeFormats = {
  full: '{{date}}, {{time}}',
  long: '{{date}}, {{time}}',
  medium: '{{date}}, {{time}}',
  short: '{{date}}, {{time}}',
};

const formatLong: any = {
  date: buildFormatLongFn({
    formats: dateFormats,
    defaultWidth: 'full',
  }),

  time: buildFormatLongFn({
    formats: timeFormats,
    defaultWidth: 'full',
  }),

  dateTime: buildFormatLongFn({
    formats: dateTimeFormats,
    defaultWidth: 'full',
  }),
};

export default formatLong;
