import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { getCookie } from 'core/services';
import { KEY_STORAGE_CALL_IS_PROCESSING } from 'constantsLk';

interface TimerButtonItemProps {
  minutesString: string;
  secondString: string;
  startTimerHandler: (e: React.MouseEvent<HTMLButtonElement>) => void;
  isShowTimer: boolean;
  isDisabledResend?: boolean;
  buttonText: string;
  isCodeConfirmation?: boolean;
}

export const TimerButtonItem: FC<TimerButtonItemProps> = ({
  startTimerHandler,
  isShowTimer,
  minutesString,
  secondString,
  isDisabledResend,
  buttonText,
  isCodeConfirmation,
}) => {
  const { t } = useTranslation();
  const isCallProcessing = getCookie(KEY_STORAGE_CALL_IS_PROCESSING);

  return isShowTimer ? (
    <span className='txt-xs pt-0 pb-0 txt-secondary mt-3'>
      {t(isCodeConfirmation ? 're_sending_code' : 'repeat_call_time', {
        time: `${minutesString}:${secondString}`,
      })}
    </span>
  ) : !isCodeConfirmation && isCallProcessing ? (
    <></>
  ) : (
    <div>
      <button
        className='btn-secondary without-icon mt-3'
        disabled={isDisabledResend}
        onClick={startTimerHandler}
        type='button'
      >
        {t(buttonText)}
      </button>
    </div>
  );
};
