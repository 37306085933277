import { useEffect } from 'react';
import { useTypedSelector } from 'core/hooks';
import {
  isShowCollectionAlertSelector,
  isShowPartnerSelector,
  shortLinkLoanSelector,
} from 'store/selectors';
import { ShortLinkLoanResult } from './ShortLinkLoanResult';
import { ContactCollectionTeamContainer } from '../../account/AccountLoan/ActiveLoan/ContactCollectionTeam/ContactCollectionTeamContainer';
import { setShowPartner } from 'store/slices/loanSlice';
import { useAppDispatch } from 'hooks';
import { Spinner } from 'components';
import { LoanSoldPage } from '../../account/AccountLoan/LoanSold/LoanSoldPage';
import { ShortLinkLoanType } from 'models';
import { KEY_OPENED_PARTNER_LINK, LOAN_STATE, PROMOTION_TYPE } from 'constantsLk';

export const ShortLinkLoanResultContainer = () => {
  const dispatch = useAppDispatch();
  const shortLinkLoan = useTypedSelector(shortLinkLoanSelector);
  const isShowPartner = useTypedSelector(isShowPartnerSelector);
  const openedPartnerLink = localStorage.getItem(KEY_OPENED_PARTNER_LINK);
  const showCollectionAlert = useTypedSelector(isShowCollectionAlertSelector);
  const isPersonalAccount = shortLinkLoan?.type === ShortLinkLoanType.PersonalAccount;
  const isLoanSold = shortLinkLoan?.state === LOAN_STATE.SOLD;

  useEffect(() => {
    if (openedPartnerLink) {
      if (openedPartnerLink === 'null' && shortLinkLoan?.loan_id) {
        localStorage.setItem(KEY_OPENED_PARTNER_LINK, `${shortLinkLoan?.loan_id}`);
      } else if (+openedPartnerLink !== shortLinkLoan?.loan_id) {
        dispatch(setShowPartner(true));
        localStorage.removeItem(KEY_OPENED_PARTNER_LINK);
      } else {
        dispatch(setShowPartner(false));
      }
    }
  }, [shortLinkLoan?.loan_id]);

  const onClose = () => dispatch(setShowPartner(false));

  const saleInfo = {
    company_name: shortLinkLoan?.loan?.loan_sale_info?.portfolio_buyer?.company_name,
    email: shortLinkLoan?.loan?.loan_sale_info?.portfolio_buyer?.email,
    phone_number: shortLinkLoan?.loan?.loan_sale_info?.portfolio_buyer?.phone_number,
    sold_at: shortLinkLoan?.loan?.loan_sale_info?.sold_at,
    bank_account_holder_name:
      shortLinkLoan?.loan?.loan_sale_info?.portfolio_buyer?.bank_account_holder_name,
    bank_account_number: shortLinkLoan?.loan?.loan_sale_info?.portfolio_buyer?.bank_account_number,
    bank_branch: shortLinkLoan?.loan?.loan_sale_info?.portfolio_buyer?.bank_branch,
  };

  return shortLinkLoan ? (
    <>
      <ContactCollectionTeamContainer showCollectionAlert={showCollectionAlert} />
      {isLoanSold ? (
        <div>
          <LoanSoldPage
            isPossibleToPay={isPersonalAccount}
            saleInfo={saleInfo}
            loan={shortLinkLoan}
          />
        </div>
      ) : (
        <ShortLinkLoanResult
          shortLinkLoan={shortLinkLoan}
          isShowPartners={shortLinkLoan.days_past_due < -4 && isShowPartner}
          onClose={onClose}
          isPersonalAccount={isPersonalAccount}
          activePromotion={shortLinkLoan?.active_promotions?.find(
            (i) => i.type !== PROMOTION_TYPE.EXTENSION_DISCOUNT,
          )}
          activeProlongationPromotion={shortLinkLoan?.active_promotions?.find(
            (i) => i.type === PROMOTION_TYPE.EXTENSION_DISCOUNT,
          )}
        />
      )}
    </>
  ) : (
    <Spinner />
  );
};
