import { SubmitHandler, useForm, UseFormReturn } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { Button } from 'core/components/common';
import { TextFieldHookForm } from 'core/components/fields';

import { validatePromoCode } from 'utils/validates/validation';
import { Spinner } from 'components';

export interface PromoCodeFieldModel {
  code: string;
}

export interface PromoCodeFieldProps {
  value?: string | null;
  onConfirm: (
    data: PromoCodeFieldModel,
    form: Pick<UseFormReturn<PromoCodeFieldModel>, 'setError'>,
  ) => ReturnType<SubmitHandler<PromoCodeFieldModel>>;
}

export const PromoCodeField = (props: PromoCodeFieldProps) => {
  const { value, onConfirm } = props;

  const { t } = useTranslation();

  const {
    control,
    watch,
    handleSubmit,
    setError,
    formState: { isSubmitting },
  } = useForm<PromoCodeFieldModel>({
    defaultValues: {
      code: value || '',
    },
  });

  const onClickHandler = handleSubmit(async (values) =>
    onConfirm?.(values, {
      setError,
    }),
  );

  const isButtonDisabled = isSubmitting || !watch('code')?.trim?.();

  return (
    <div className='promo-code__wrapper'>
      <TextFieldHookForm
        placeholder={t`enter_promo_code`}
        name='code'
        control={control}
        rules={validatePromoCode}
      />

      <Button
        onClick={onClickHandler}
        type='button'
        disabled={isButtonDisabled}
        classes={{ root: 'btn--primary' }}
      >
        {isSubmitting ? (
          <div className='loader-small'>
            <Spinner />
          </div>
        ) : (
          t`apply`
        )}
      </Button>
    </div>
  );
};
