import { ChangeEvent, FC, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ModalPortal } from 'core/components/common/ModalLayout/ModalPortal';

import { DocumentsType } from 'models';
import { WebcamComponent } from 'components';
import CameraPermissionImg from 'assets/images/camera_permission.png';
import cn from 'classnames';
import { CloseIcon } from '../../../core/components/common';

interface IFileTakePhotoModalProps {
  isOpen: boolean;
  onClose: () => void;
  onUpload: (imgSrc: string) => Promise<any>;
  fileState: string;
  type: string;
  changeFile: (e: ChangeEvent<HTMLInputElement>) => void;
}

export function getPhotoInstruction(type: string) {
  const instructions = { title: '', subtitle: '' };

  switch (type) {
    case DocumentsType.IDCard: {
      instructions.subtitle = 'nic_front_card_upload_description';
      instructions.title = 'nic_front_card_upload_title';
      break;
    }

    case DocumentsType.Selfie: {
      instructions.subtitle = 'selfieWithIdPhotoInstruction';
      instructions.title = 'selfieWithIdPhotoInstructionTitle';
      break;
    }

    case DocumentsType.IDCardBackSide: {
      instructions.subtitle = 'nic_back_card_upload_description';
      instructions.title = 'nic_back_card_upload_title';
      break;
    }

    default:
      return instructions;
  }
  return instructions;
}

export const FileTakePhotoModal: FC<IFileTakePhotoModalProps> = ({
  isOpen,
  onClose,
  onUpload,
  // fileState,
  type,
}) => {
  const { t } = useTranslation();
  const [isCameraAllow, setIsCameraAllow] = useState(true);

  const { title, subtitle } = getPhotoInstruction(type);

  return (
    <ModalPortal isOpen={isOpen}>
      <div className='modal-core'>
        <div className='modal-core__wrapper modal-core__wrapper--webcam'>
          <div className='modal-core__header'>
            <div>
              <div className='modal-core__title'>{t(title)}</div>
              <div className='modal-core__subtitle'>{t(subtitle)}</div>
            </div>
            <span className={cn('modal-core__close')} onClick={onClose}>
              <CloseIcon />
            </span>
          </div>
          {isCameraAllow ? (
            <WebcamComponent
              type={type}
              onUpload={onUpload}
              // fileState={fileState}
              onUserMediaError={() => setIsCameraAllow(false)}
              onClose={onClose}
              // src={src}
            />
          ) : (
            <div className='modal-core__header flex-column pt-0'>
              <h2 className='txt-sb'>{t('what_if_i_denied_access')}</h2>
              <div className='flex-center'>
                <img src={CameraPermissionImg} alt='PNG Image' />
              </div>
            </div>
          )}
        </div>
      </div>
    </ModalPortal>
  );
};
