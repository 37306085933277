export type AddressOptions = {
  residence_duration: Record<string, Array<{ label: string; value: string }>>;
  loan_purpose: Record<string, Array<{ label: string; value: string }>>;
  education: Record<string, Array<{ label: string; value: string }>>;
  employment_type: Record<string, Array<{ label: string; value: string }>>;
  employment_industry: Record<string, Array<{ label: string; value: string }>>;
  employment_position: Record<string, Array<{ label: string; value: string }>>;
  main_source_of_income: Record<string, Array<{ label: string; value: string }>>;
  residence_province: Record<string, Array<{ label: string; value: string }>>;
  residence_district: Record<string, Record<string, Array<{ label: string; value: string }>>>;
  marital_status: Record<string, Array<{ label: string; value: string }>>;
  number_of_children: Record<string, Array<{ label: string; value: string }>>;
  contact_relationship: Record<string, Array<{ label: string; value: string }>>;
  native_language: Record<string, Array<{ label: string; value: string }>>;
  bank_name: Record<string, Array<{ label: string; value: string }>>;
  bank_branch_name: Record<string, Array<{ label: string; value: string }>>;
};

export enum EmploymentType {
  Employed = 'Employed',
  SelfEmployed = 'Self - employed',
  PoliceService = 'Police service',
  MilitaryService = 'Military service',
  GovernmentService = 'Government service',
  Housewife = 'Housewife',
  Student = 'Student',
  Pensioner = 'Pensioner',
  MaternityLeave = 'Maternity leave',
  DisabledPerson = 'Disabled person',
  TemporarilyUnemployed = 'Temporarily Unemployed',
}

export enum EmploymentPosition {
  Staff = 'Staff',
  Manager = 'Manager',
  Director = 'Director',
  BusinessOwner = 'Business Owner',
  Other = 'Other',
}

export enum MainSourceOfIncome {
  PartTimeJob = 'part-time job',
  HelpOfRelatives = 'help of relatives',
  SocialPayments = 'social payments',
  NoIncome = 'no income',
}

export const SELECT_OPTIONS: AddressOptions = {
  residence_duration: {
    en: [
      { label: 'up to three months', value: 'up to three months' },
      { label: 'from 3 to 6 months', value: 'from 3 to 6 months' },
      { label: 'from 6 to 12 months', value: 'from 6 to 12 months' },
      { label: 'more than a year', value: 'more than a year' },
    ],
    si: [
      { label: 'මාස තුනක් දක්වා', value: 'up to three months' },
      { label: 'මාස 3 සිට මාස 6 දක්වා', value: 'from 3 to 6 months' },
      { label: 'මාස 6 සිට මාස 12 දක්වා', value: 'from 6 to 12 months' },
      { label: 'මාස 12ට වැඩි', value: 'more than a year' },
    ],
  },
  loan_purpose: {
    en: [
      { label: 'Business', value: 'Business' },
      { label: 'Consumption', value: 'Consumption' },
      { label: 'Education', value: 'Education' },
      { label: 'Medical', value: 'Medical' },
      { label: 'Other', value: 'Other' },
    ],
    si: [
      { label: 'ව්‍යාපාරික', value: 'Business' },
      { label: 'පරිභෝජනය', value: 'Consumption' },
      { label: 'අධ්‍යාපනය', value: 'Education' },
      { label: 'වෛද්‍ය', value: 'Medical' },
      { label: 'වෙනත්', value: 'Other' },
    ],
  },
  education: {
    en: [
      { label: 'Ordinary Level', value: 'Ordinary Level' },
      { label: 'Advanced Level', value: 'Advanced Level' },
      { label: 'Undergraduate', value: 'Undergraduate' },
      { label: 'University Graduate', value: 'University Graduate' },
      { label: 'Postgraduate', value: 'Postgraduate' },
      { label: 'Tertiary Education', value: 'Tertiary Education' },
      { label: 'Other', value: 'Other' },
    ],
    si: [
      { label: 'සාමාන්‍ය පෙළ', value: 'Ordinary Level' },
      { label: 'උසස් පෙළ', value: 'Advanced Level' },
      { label: 'උපාධි අපේක්ෂක', value: 'Undergraduate' },
      { label: 'උපාධිදාරී', value: 'University Graduate' },
      { label: 'පශ්චාත් උපාධිදාරී', value: 'Postgraduate' },
      { label: 'තෘතියික අධ්‍යාපන', value: 'Tertiary Education' },
      { label: 'වෙනත්', value: 'Other' },
    ],
  },
  employment_type: {
    en: [
      { label: 'Employed', value: EmploymentType.Employed },
      { label: 'Self - employed', value: EmploymentType.SelfEmployed },
      { label: 'Police service', value: EmploymentType.PoliceService },
      { label: 'Military service', value: EmploymentType.MilitaryService },
      { label: 'Government service', value: EmploymentType.GovernmentService },
      { label: 'Housewife', value: EmploymentType.Housewife },
      { label: 'Student', value: EmploymentType.Student },
      { label: 'Pensioner', value: EmploymentType.Pensioner },
      { label: 'Maternity leave', value: EmploymentType.MaternityLeave },
      { label: 'Disabled person', value: EmploymentType.DisabledPerson },
      { label: 'Temporarily Unemployed', value: EmploymentType.TemporarilyUnemployed },
    ],
    si: [
      { label: 'රැකියානියුතු', value: EmploymentType.Employed },
      { label: 'ස්වයං ‍රැකියාවක නියුතු', value: EmploymentType.SelfEmployed },
      { label: 'පොලිස් සේවය', value: EmploymentType.PoliceService },
      { label: 'හමුදා සේවය', value: EmploymentType.MilitaryService },
      { label: 'රාජ්‍ය සේවක', value: EmploymentType.GovernmentService },
      { label: 'ගෘහණිය', value: EmploymentType.Housewife },
      { label: 'ශිෂ්‍ය', value: EmploymentType.Student },
      { label: 'විශ්‍රාමික', value: EmploymentType.Pensioner },
      { label: 'මාතෘ නිවාඩු', value: EmploymentType.MaternityLeave },
      { label: 'ආබාධිත', value: EmploymentType.DisabledPerson },
      { label: 'තාවකාලිකව ‍රැකියා විරහිත', value: EmploymentType.TemporarilyUnemployed },
    ],
  },
  employment_industry: {
    en: [
      { label: 'Agriculture', value: 'Agriculture' },
      { label: 'Trading/Retail/Distributor', value: 'Trading/Retail/Distributor' },
      { label: 'Banking/Finance', value: 'Banking/Finance' },
      { label: 'Human Resource', value: 'Human Resource' },
      { label: 'Media/Communication', value: 'Media/Communication' },
      { label: 'Education/Training', value: 'Education/Training' },
      { label: 'Engineering', value: 'Engineering' },
      { label: 'Information Technology', value: 'Computer/Information Technology' },
      { label: 'Building/Construction', value: 'Building/Construction' },
      { label: 'Healthcare', value: 'Healthcare' },
      { label: 'Hotel/Restaurant', value: 'Hotel/Restaurant' },
      { label: 'Manufacturing', value: 'Manufacturing' },
      { label: 'Sales/Marketing', value: 'Sales Marketing' },
      { label: 'Consumer Goods', value: 'Consumer Goods' },
      { label: 'Services Sector', value: 'Services Sector' },
      { label: 'Oil & Gas', value: 'Oil & Gas' },
      { label: 'Mining', value: 'Mining' },
      { label: 'Fashion / Body care', value: 'Fashion / Body care' },
      { label: 'Other', value: 'Other' },
    ],
    si: [
      { label: 'කෘෂිකර්ම', value: 'Agriculture' },
      { label: 'වෙළඳ / සිල්ලර / බෙදාහැරීම්', value: 'Trading/Retail/Distributor' },
      { label: 'බැංකු/ මූල්‍ය', value: 'Banking/Finance' },
      { label: 'මානව සම්පත්', value: 'Human Resource' },
      { label: 'මාධ්‍ය/ සන්නිවේදන', value: 'Media/Communication' },
      { label: 'අධ්‍යාපන / පුහුණු', value: 'Education/Training' },
      { label: 'ඉංජිනේරු', value: 'Engineering' },
      { label: 'තොරතුරු තාක්ෂණ', value: 'Computer/Information Technology' },
      { label: 'ගොඩනැගිලි ඉදිකිරීම්', value: 'Building/Construction' },
      { label: 'සෞඛ්‍ය සත්කාර', value: 'Healthcare' },
      { label: 'හෝටල් / ආපනශාලා', value: 'Hotel/Restaurant' },
      { label: 'නිෂ්පාදන', value: 'Manufacturing' },
      { label: 'විකුණුම් / අලෙවි', value: 'Sales Marketing' },
      { label: 'පාරිභෝගික භාණ්ඩ', value: 'Consumer Goods' },
      { label: 'සේවා', value: 'Services Sector' },
      { label: 'තෙල් සහ ගෑස්', value: 'Oil & Gas' },
      { label: 'පතල්', value: 'Mining' },
      { label: 'විලාසිතා / රූපලාවන්ය', value: 'Fashion / Body care' },
      { label: 'වෙනත්', value: 'Other' },
    ],
  },
  employment_position: {
    en: [
      { label: 'Staff', value: EmploymentPosition.Staff },
      { label: 'Manager', value: EmploymentPosition.Manager },
      { label: 'Director', value: EmploymentPosition.Director },
      { label: 'Business Owner', value: EmploymentPosition.BusinessOwner },
      { label: 'Other', value: EmploymentPosition.Other },
    ],
    si: [
      { label: 'කාර්ය මණ්ඩල', value: 'Staff' },
      { label: 'කළමණාකරු', value: 'Manager' },
      { label: 'අධ්‍යක්ෂ', value: 'Director' },
      { label: 'ව්‍යාපාර හිමිකරු', value: 'Business Owner' },
      { label: 'වෙනත්', value: 'Other' },
    ],
  },
  main_source_of_income: {
    en: [
      { label: 'Part-time job', value: 'part-time job' },
      { label: 'Help of relatives', value: 'help of relatives' },
      { label: 'Social payments', value: 'social payments' },
      { label: 'No income', value: 'no income' },
    ],
    si: [
      { label: 'අර්ධ කාලීන ‍රැකියාව', value: 'part-time job' },
      { label: 'නෑදෑයන්ගෙන් ලැබීම්', value: 'help of relatives' },
      { label: 'විශ්‍රාම වැ‍ටුප්', value: 'social payments' },
      { label: 'ආදායමක් නොමැත', value: 'no income' },
    ],
  },
  residence_province: {
    en: [
      { label: 'Western', value: 'Western' },
      { label: 'Southern', value: 'Southern' },
      { label: 'Central', value: 'Central' },
      { label: 'Sabaragamuwa', value: 'Sabaragamuwa' },
      { label: 'Uva', value: 'Uva' },
      { label: 'North Central', value: 'North Central' },
      { label: 'North Western', value: 'North Western' },
      { label: 'Eastern', value: 'Eastern' },
      { label: 'Northern', value: 'Northern' },
    ],
    si: [
      { label: 'බස්නාහිර', value: 'Western' },
      { label: 'දකුණ', value: 'Southern' },
      { label: 'මධ්‍යම', value: 'Central' },
      { label: 'සබරගමුව', value: 'Sabaragamuwa' },
      { label: 'ඌව', value: 'Uva' },
      { label: 'උතුරු මැද', value: 'North Central' },
      { label: 'වයඹ', value: 'North Western' },
      { label: 'නැගෙනහිර', value: 'Eastern' },
      { label: 'උතුරු', value: 'Northern' },
    ],
  },
  residence_district: {
    en: {
      Western: [
        { label: 'Colombo', value: 'Colombo' },
        { label: 'Kalutara', value: 'Kalutara' },
        { label: 'Gampaha', value: 'Gampaha' },
      ],
      Southern: [
        { label: 'Galle', value: 'Galle' },
        { label: 'Matara', value: 'Matara' },
        { label: 'Hambantota', value: 'Hambantota' },
      ],
      Central: [
        { label: 'Kandy', value: 'Kandy' },
        { label: 'Matale', value: 'Matale' },
        { label: 'Nuwara Eliya', value: 'Nuwara Eliya' },
      ],
      Sabaragamuwa: [
        { label: 'Ratnapura', value: 'Ratnapura' },
        { label: 'Kegalle', value: 'Kegalle' },
      ],
      Uva: [
        { label: 'Badulla', value: 'Badulla' },
        { label: 'Monaragala', value: 'Monaragala' },
      ],
      'North Central': [
        { label: 'Anuradhapura', value: 'Anuradhapura' },
        { label: 'Polonnaruwa', value: 'Polonnaruwa' },
      ],
      'North Western': [
        { label: 'Kurunegala', value: 'Kurunegala' },
        { label: 'Puttalam', value: 'Puttalam' },
      ],
      Eastern: [
        { label: 'Trincomalee', value: 'Trincomalee' },
        { label: 'Batticaloa', value: 'Batticaloa' },
        { label: 'Ampara', value: 'Ampara' },
      ],
      Northern: [
        { label: 'Jaffna', value: 'Jaffna' },
        { label: 'Mannar', value: 'Mannar' },
        { label: 'Kilinochchi', value: 'Kilinochchi' },
        { label: 'Mullaitivu', value: 'Mullaitivu' },
        { label: 'Vavuniya', value: 'Vavuniya' },
      ],
    },
    si: {
      Western: [
        { label: 'කොළඹ', value: 'Colombo' },
        { label: 'කළුතර', value: 'Kalutara' },
        { label: 'ගම්පහ', value: 'Gampaha' },
      ],
      Southern: [
        { label: 'ගාල්ල', value: 'Galle' },
        { label: 'මාතර', value: 'Matara' },
        { label: 'හම්බන්තොට', value: 'Hambantota' },
      ],
      Central: [
        { label: 'මහනුවර', value: 'Kandy' },
        { label: 'මාතලේ', value: 'Matale' },
        { label: 'නුවරඑළිය', value: 'Nuwara Eliya' },
      ],
      Sabaragamuwa: [
        { label: 'රත්නපුර', value: 'Ratnapura' },
        { label: 'කෑගල්ල', value: 'Kegalle' },
      ],
      Uva: [
        { label: 'බදුල්ල', value: 'Badulla' },
        { label: 'මොනරාගල', value: 'Monaragala' },
      ],
      'North Central': [
        { label: 'අනුරාධපුර', value: 'Anuradhapura' },
        { label: 'පොළොන්නරුව', value: 'Polonnaruwa' },
      ],
      'North Western': [
        { label: 'කුරුණෑගල', value: 'Kurunegala' },
        { label: 'පුත්තලම', value: 'Puttalam' },
      ],
      Eastern: [
        { label: 'ත්‍රිකුණාමලය', value: 'Trincomalee' },
        { label: 'මඩකලපුව', value: 'Batticaloa' },
        { label: 'අම්පාර', value: 'Ampara' },
      ],
      Northern: [
        { label: 'යාපනය', value: 'Jaffna' },
        { label: 'මන්නාරම', value: 'Mannar' },
        { label: 'කිලිනොච්චි', value: 'Kilinochchi' },
        { label: 'මුලතිව්', value: 'Mullaitivu' },
        { label: 'වවුනියාව', value: 'Vavuniya' },
      ],
    },
  },
  marital_status: {
    en: [
      { label: 'married', value: 'married' },
      { label: 'single', value: 'single' },
      { label: 'divorced', value: 'divorced' },
      { label: 'other', value: 'other' },
    ],
    si: [
      { label: 'විවාහක', value: 'married' },
      { label: 'අවිවාහක', value: 'single' },
      { label: 'දික්කසාද විය', value: 'divorced' },
      { label: 'වෙනත්', value: 'other' },
    ],
  },
  number_of_children: {
    en: [
      { label: 'none', value: 'none' },
      { label: 'one', value: 'one' },
      { label: 'two', value: 'two' },
      { label: 'three or more', value: 'three or more' },
    ],
    si: [
      { label: 'කිසිවක් නැත', value: 'none' },
      { label: 'එක', value: 'one' },
      { label: 'දෙක', value: 'two' },
      { label: 'තුනක් හෝ වැඩි', value: 'three or more' },
    ],
  },
  contact_relationship: {
    en: [
      { label: 'mother/father', value: 'mother/father' },
      { label: 'wife/husband', value: 'wife/husband' },
      { label: 'sister/brother', value: 'sister/brother' },
      { label: 'cousin', value: 'cousin' },
      { label: 'colleague', value: 'colleague' },
      { label: 'friend', value: 'friend' },
      { label: 'other', value: 'other' },
    ],
    si: [
      { label: 'මව පියා', value: 'mother/father' },
      { label: 'බිරිඳ / සැමියා ', value: 'wife/husband' },
      { label: 'සහෝදරිය / සහෝදරයා', value: 'sister/brother' },
      { label: 'නෑදෑයා', value: 'cousin' },
      { label: 'සගයා', value: 'colleague' },
      { label: 'මිතුරා', value: 'friend' },
      { label: 'වෙනත්', value: 'other' },
    ],
  },
  native_language: {
    en: [
      { label: 'English', value: 'en_LK' },
      { label: 'Sinhala', value: 'si_LK' },
      { label: 'Tamil', value: 'ta_LK' },
    ],
    si: [
      { label: 'සිංහල', value: 'en_LK' },
      { label: 'දෙමළ', value: 'si_LK' },
      { label: 'ඉංග්රීසි', value: 'ta_LK' },
    ],
  },
  bank_name: {
    en: [
      { label: 'Test 1', value: 'test 1' },
      { label: 'Test 2', value: 'test 2' },
      { label: 'Test 3', value: 'test 3' },
      { label: 'Test 4', value: 'test 4' },
    ],
    si: [
      { label: 'Test 1', value: 'test 1' },
      { label: 'Test 2', value: 'test 2' },
      { label: 'Test 3', value: 'test 3' },
      { label: 'Test 4', value: 'test 4' },
    ],
  },
  bank_branch_name: {
    en: [
      { label: 'Test 1', value: 'test 1' },
      { label: 'Test 2', value: 'test 2' },
      { label: 'Test 3', value: 'test 3' },
      { label: 'Test 4', value: 'test 4' },
    ],
    si: [
      { label: 'Test 1', value: 'test 1' },
      { label: 'Test 2', value: 'test 2' },
      { label: 'Test 3', value: 'test 3' },
      { label: 'Test 4', value: 'test 4' },
    ],
  },
};
