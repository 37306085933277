import { useTranslation } from 'react-i18next';
import { InfoIcon } from 'components/icons';

export const RepayOfflineAlert = () => {
  const { t } = useTranslation();

  return (
    <div className='alerts-item__info flex-column mt-4'>
      <div className='alerts-item__info__message w-100'>
        <div className='alerts-item__icon-wrapper mr-2'>
          <InfoIcon />
        </div>
        <div className='txt-sb w-100'>{t`note`}</div>
      </div>

      <div className='alerts-item__info__message ml-5'>{t`repay_note_text`}</div>
    </div>
  );
};
