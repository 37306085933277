import { RepayOfflineModal } from './RepayOfflineModal';
import { getBankContent } from 'utils/getBankContent';
import { BankInstructionModel, Loan } from 'models';

interface RepayOfflineModalContainerProps {
  isOpenModal: boolean;
  handleModalClose: () => void;
  activeCreditData: Loan;
  handleLinkFollow?: () => void;
}

export const RepayOfflineModalContainer = (props: RepayOfflineModalContainerProps) => {
  const { isOpenModal, handleModalClose, activeCreditData, handleLinkFollow } = props;

  const bankData: BankInstructionModel = getBankContent(
    activeCreditData.user_personal_id_number,
    activeCreditData.id ?? activeCreditData.loan_id,
    // activeCreditData.user_bank,
  );

  return (
    <RepayOfflineModal
      isOpenModal={isOpenModal}
      handleModalClose={handleModalClose}
      bankData={bankData}
      handleLinkFollow={handleLinkFollow}
    />
  );
};
