import React, { FC, useMemo, useState } from 'react';
import { UseFormSetError } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { getCookie, setCookie } from 'core/services';
import { useAppDispatch } from 'hooks';
import { ITimerValue } from 'models/common';
import { callConfirmUser, resendPhoneCode } from 'store/actions/api';
import { TimerButtonItem } from 'components';
import { PhoneConfirmFormFields } from 'models';
import { removeAllTimerCookies } from 'utils';
import { KEY_STORAGE_CALL_IS_PROCESSING } from 'constantsLk';

interface IResendCodeContainerProps {
  setError: UseFormSetError<any>;
  isShowTimer: boolean;
  showTimer: () => any;
  timerValue: ITimerValue;
  hintText?: string;
  setConfirmWithCall: () => void;
  hideTimer: () => void;
  isCodeConfirmation: boolean;
  isShowCallButton: boolean;
  isLogin?: boolean;
}

export const ResendCodeRegContainer: FC<IResendCodeContainerProps> = ({
  setError,
  timerValue,
  isShowTimer,
  showTimer,
  // hintText,
  hideTimer,
  isCodeConfirmation,
  setConfirmWithCall,
  isShowCallButton,
  isLogin,
}) => {
  const dispatch = useAppDispatch();
  const { t, i18n } = useTranslation();
  const lang = i18n.language;

  const isCallProcessing = getCookie(KEY_STORAGE_CALL_IS_PROCESSING);

  const [codeState, setCodeState] = useState({ isShowHint: false, isDisabledResend: false });
  const [isError, setIsError] = useState(false);

  const errorMessage = useMemo(() => {
    if (isError) {
      return t`confirm_with_call_error`;
    }
  }, [isError, lang]);

  const startTimerHandler = async (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    removeAllTimerCookies();
    await showTimer();
    setCodeState({ isShowHint: true, isDisabledResend: false });
    setIsError(false);
    dispatch(resendPhoneCode())
      .unwrap()
      .then(() => {})
      .catch((e) => {
        setError(PhoneConfirmFormFields.Code, {
          type: 'validate',
          message: e?.message,
        });
      })
      .finally(() => {});
  };

  const setCallIsProcessing = () => {
    hideTimer();
    setConfirmWithCall();
    setCookie(KEY_STORAGE_CALL_IS_PROCESSING, '1');
  };

  const confirmWithPhoneHandler = () => {
    dispatch(callConfirmUser())
      .unwrap()
      .then(() => setCallIsProcessing())
      .catch((e) => {
        if (e.detail === 'Previous call is still being processed') {
          setCallIsProcessing();
        } else {
          setIsError(true);
        }
      });
  };

  return (
    <>
      {isError && <p className={'error-text mt-1'}>{errorMessage}</p>}

      <div className={`align-items-center gap-3 ${!isLogin && 'mb-5'}`}>
        {isShowCallButton && !isCallProcessing && (
          <button
            onClick={confirmWithPhoneHandler}
            className='btn-secondary without-icon mt-3'
            type='button'
          >
            {t('confirm_with_call')}
          </button>
        )}

        <TimerButtonItem
          isDisabledResend={codeState.isDisabledResend}
          isShowTimer={isShowTimer}
          startTimerHandler={startTimerHandler}
          secondString={timerValue.secondString}
          minutesString={timerValue.minutesString}
          buttonText={'resend_code'}
          isCodeConfirmation={isCodeConfirmation}
        />

        {/*{codeState.isShowHint && <ConnectSupportHint text={t(hintText)} />}*/}
      </div>
    </>
  );
};
