import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';

interface ITitleProps {
  name: string;
  classes?: string;
}

export const Title: FC<ITitleProps> = (props) => {
  const { name, classes, ...rest } = props;
  const { t } = useTranslation();

  return (
    <h1 className={cn(classes)} {...rest}>
      {t(name)}
    </h1>
  );
};
