import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { isFacebookWebview } from 'utils';
import androidSi from '../../assets/images/gif/webview-android-si.gif';
import iosSi from '../../assets/images/gif/webview-ios-si.gif';
import androidEn from '../../assets/images/gif/webview-android-en.gif';
import iosEn from '../../assets/images/gif/webview-ios-en.gif';
import { isAndroid } from 'constantsLk';

export const WebViewAnimation = () => {
  const { i18n } = useTranslation();
  const lang = i18n.language;

  const animation = useMemo(() => {
    if (isFacebookWebview) {
      if (lang === 'si') {
        return isAndroid ? androidSi : iosSi;
      } else {
        return isAndroid ? androidEn : iosEn;
      }
    }
  }, [lang, isAndroid, isFacebookWebview]);

  return animation ? (
    <div className='webview-animation'>
      <img src={animation + '?' + Date.now()} className='w-100' alt='Instruction' />
    </div>
  ) : null;
};
