import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ErrorMessage } from 'core/components/common';
import { FileInput } from '../FileInput';
import { UploadedPhoto } from '../UploadedPhoto';
import { onUploadTakenPhoto } from 'utils';
import {
  CameraAccessDeniedFAQ,
  defaultUploadFileState,
  IPhotoDocuments,
  IPhotoUploadInitStateProps,
} from 'components/DocumentUploader';
import { CameraAccessDeniedFAQModalContainer } from '../../modals';
import { DUPLICATE_ACTION } from 'constantsLk';

export const UploadPhotoContainer: FC<{
  stateComponent: IPhotoUploadInitStateProps;
  setStateComponent: (state: IPhotoUploadInitStateProps) => void;
  error?: string;
  item: IPhotoDocuments;
  key?: string;
  onUpload: (file: File) => void;
  withoutFAQ?: boolean;
  sendLog?: (action: string, additional_data: string, source_user_id?: number) => any;
}> = ({ error, item, stateComponent, onUpload, setStateComponent, withoutFAQ, sendLog }) => {
  const { type, srcDefault } = item;
  const { t } = useTranslation();

  const [photoModalsState, setPhotoModalsState] = useState({
    isTakePhotoOpen: false,
    isTakePhotoInstructionOpen: false,
  });

  const [isShowCameraAccessDialog, setIsShowCameraAccessDialog] = useState<boolean>(false);

  useEffect(() => {
    if (error) setStateComponent({ ...defaultUploadFileState, error });
  }, [error]);

  const handleShowIfAccessDeniedDialog = () => {
    setIsShowCameraAccessDialog((prevState) => !prevState);
  };

  const openWebcamHandler = () => {
    setPhotoModalsState({ isTakePhotoOpen: true, isTakePhotoInstructionOpen: false });
    sendLog && sendLog(DUPLICATE_ACTION.CLICK, 'Show camera');
  };
  const closeWebcamHandler = () => {
    setPhotoModalsState({ isTakePhotoOpen: false, isTakePhotoInstructionOpen: false });
  };
  const instructionHandler = () => {
    setPhotoModalsState({ isTakePhotoOpen: false, isTakePhotoInstructionOpen: true });
    sendLog && sendLog(DUPLICATE_ACTION.CLICK, 'Show instruction');
  };

  const handleUploadPhoto = async (imgSrc: any) => {
    const file = await onUploadTakenPhoto(imgSrc);
    onUpload(file);
  };

  const changeUploadFile = (e: any) => {
    const file = e.target.files[0];
    onUpload(file);
  };

  return (
    <>
      <div className='card-secondary flex-column w-100 gap-4'>
        <span>{t`photo_example`}</span>
        <div className='flex-center'>
          <UploadedPhoto
            src={srcDefault}
            fileState={stateComponent.state}
            instructionHandler={instructionHandler}
          />
        </div>
      </div>

      {error && (
        <ErrorMessage
          message={
            error ||
            (stateComponent.state === 'error' || stateComponent.state === 'errorFile'
              ? 'errorUploadText'
              : '')
          }
        />
      )}

      <div className='flex-column gap-4 mt-1'>
        <FileInput
          fileState={stateComponent.state}
          changeFile={changeUploadFile}
          onUpload={handleUploadPhoto}
          type={type}
          instructions={item.instructions}
          photoModalsState={photoModalsState}
          openWebcamHandler={openWebcamHandler}
          closeWebcamHandler={closeWebcamHandler}
          instructionHandler={instructionHandler}
          sendLog={sendLog}
        />

        {!withoutFAQ && (
          <CameraAccessDeniedFAQ handleShowIfAccessDeniedDialog={handleShowIfAccessDeniedDialog} />
        )}
      </div>

      {isShowCameraAccessDialog && (
        <CameraAccessDeniedFAQModalContainer onClose={handleShowIfAccessDeniedDialog} />
      )}
    </>
  );
};
