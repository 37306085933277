import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Loan, ServerLoanSettings, SortedIncomesProps } from 'models';
import {
  getDefaultCalculatorConfig,
  getPaymentHistory,
  getUserLoans,
  sendTransactionId,
} from '../actions/api';
import { sortIncomes } from 'utils/functions';
import { getLoanBySlugAction } from '../actions';
import { LOAN_STATE } from 'constantsLk';

interface InitialUserState {
  loans: Loan[] | null;
  activeLoan: Loan | null;
  incomes: SortedIncomesProps | null;
  shortLinkLoan: Loan | null;
  defaultLoanSettings: ServerLoanSettings;
  isShowPartner: boolean;
  isSuccessfullyPayment: boolean;
}

const initialState: InitialUserState = {
  loans: null,
  activeLoan: null,
  incomes: null,
  shortLinkLoan: null,
  defaultLoanSettings: {
    default_tenor: null,
    default_principal: null,
  },
  isShowPartner: true,
  isSuccessfullyPayment: false,
};

export const loanSlice = createSlice({
  name: 'loanSlice',
  initialState,
  reducers: {
    setShowPartner: (state, action: PayloadAction<boolean>) => {
      state.isShowPartner = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getUserLoans.fulfilled, (state, action) => {
      state.loans = action.payload;
      const activeLoans = action.payload.filter(
        (loan) => loan.state === LOAN_STATE.ACTIVE || loan.state === LOAN_STATE.DEFAULTED,
      );

      state.activeLoan =
        activeLoans.length > 0
          ? activeLoans[activeLoans.length - 1] // last active loan
          : action.payload[action.payload.length - 1] || null; // last loan
    });
    builder.addCase(getPaymentHistory.fulfilled, (state, action) => {
      state.incomes = sortIncomes(action.payload);
    });
    builder.addCase(getDefaultCalculatorConfig.fulfilled, (state, action) => {
      state.defaultLoanSettings = action.payload;
    });
    builder.addCase(sendTransactionId.fulfilled, (state) => {
      state.isSuccessfullyPayment = true;
    });

    //loan from short link
    builder.addCase(getLoanBySlugAction.fulfilled, (state, action) => {
      state.shortLinkLoan = action.payload;
    });
  },
});

export const { setShowPartner } = loanSlice.actions;
