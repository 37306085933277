import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { differenceInHours, differenceInMinutes } from 'date-fns';

import { useTypedSelector } from 'core/hooks';

import { CloseCircleFilledIcon, RequestStatus } from 'components';
import { isMoreThenMonthMoratoriumSelector, userSelector } from 'store/selectors';
import { useInterval } from 'hooks';
import { getDaysText, getHoursText, getMinutesText } from 'utils/getTextValues';
import { ASIA_TIMEZONE } from 'constantsLk';
import { StatusWithPartner } from './StatusWithPartner';

const DELAY = 60000;

export const RejectWithMaratoriumContainer = () => {
  const { t } = useTranslation();
  const isMoreThenMonthMoratorium = useTypedSelector(isMoreThenMonthMoratoriumSelector);
  const user = useTypedSelector(userSelector);
  const moratoriumTill = user?.moratorium_till as string;

  const blockedTillDateLk = new Date(moratoriumTill).toLocaleString('en-LK', ASIA_TIMEZONE);
  const dateNowLk = new Date().toLocaleString('en-LK', ASIA_TIMEZONE);
  const [blockedDate, setBlockedDate] = useState(new Date(blockedTillDateLk));

  const days = Math.floor(differenceInHours(blockedDate, new Date(dateNowLk)) / 24);
  const hours = differenceInHours(blockedDate, new Date(dateNowLk)) % 24;
  const mins = differenceInMinutes(blockedDate, new Date(dateNowLk)) % 60;

  useInterval(() => {
    setBlockedDate(new Date(blockedTillDateLk));
  }, DELAY);

  return isMoreThenMonthMoratorium ? (
    <StatusWithPartner />
  ) : (
    <RequestStatus
      icon={<CloseCircleFilledIcon />}
      title={'unfortunately_your_application'}
      text={t`you_can_apply_new`}
      footer={
        <div className='moratorium-wrap flex-center'>
          <div className='flex-column'>
            <span className='timer-block'>{days}</span>
            <p className='txt-center txt-capitalize'>{t(getDaysText(days))}</p>
          </div>
          <div className='flex-column'>
            <span className='timer-block'>{hours}</span>
            <p className='txt-center txt-capitalize'>{t(getHoursText(hours))}</p>
          </div>
          <div className='flex-column'>
            <span className='timer-block'>{mins}</span>
            <p className='txt-center txt-capitalize'>{t(getMinutesText(mins))}</p>
          </div>
        </div>
      }
    />
  );
};
