import { FocusEvent } from 'react';
import { useController } from 'react-hook-form';

import { TextFieldProps, TextField } from './TextField';
import { ChangeCoreEventHandler, UseControllerCoreProps } from 'core/models';

export type TextFieldHookFormProps = TextFieldProps & UseControllerCoreProps;

export const TextFieldHookForm = (props: TextFieldHookFormProps) => {
  const { name, control, rules, onChange, onBlur, ...rest } = props;

  const {
    field,
    fieldState: { error },
  } = useController({
    name,
    control,
    rules,
  });

  const handleChange: ChangeCoreEventHandler = (name, value, e) => {
    field.onChange(value);
    onChange?.(name, value, e);
  };

  const handleBlur = (name: string, value: string, e: FocusEvent<HTMLInputElement>) => {
    field.onBlur();
    onBlur?.(name, value, e);
  };

  return (
    <TextField
      name={name}
      onChange={handleChange}
      onBlur={handleBlur}
      value={field.value}
      error={error?.message}
      {...rest}
    />
  );
};
