import { useEffect, useState } from 'react';

import { SubmitCoreHandler } from 'core/models';
import { setRedirect } from 'core/store/actions';
import { useTypedSelector } from 'core/hooks';

import { useAppDispatch } from 'hooks';
import { PersonalDataFormFields, PersonalDataFormModel, PersonalIdType } from 'models';
import { formatServerDate } from 'utils/parsers';
import { sendDuplicateLog, updateUser } from 'store/actions/api';
import { clearEmptyFormValues } from 'utils';
import { postUserResolveMatchAction, signIn } from 'store/actions';
import {
  NICErrorModalContainer,
  PersonalDataProcessingDialogModalContainer,
  UploadIdPhotoModalContainer,
} from 'components/modals';
import { Step3PersData } from './Step3PersData';
import { userSelector } from 'store/selectors';
import { DUPLICATE_ACTION, getRegStepPath, URL_ACTIVE_LOAN, USER_STATE } from 'constantsLk';

export const Step3PersDataContainer = () => {
  const dispatch = useAppDispatch();
  const user = useTypedSelector(userSelector);

  const [noConsent, setNoConsent] = useState(false);
  const [isUploadIdPhotoModalOpen, setIsUploadIdPhotoModalOpen] = useState(false);
  const [isNICErrorDialogOpen, setIsNICErrorDialogOpen] = useState(false);

  useEffect(() => {
    //open modal for duplicate users with photo when page reload
    if (user?.is_user_matched && user?.is_id_card_image_required_to_resolve_match) {
      setIsUploadIdPhotoModalOpen(true);
    } else if (
      user?.is_user_matched &&
      (!user?.is_id_card_image_required_to_resolve_match ||
        user?.matched_user_has_overdue_loan ||
        user?.matched_user_has_sold_loan ||
        user?.matched_user_in_black_list)
    ) {
      setIsNICErrorDialogOpen(true);
    }
    window.scrollTo({ top: 0 });
  }, [
    user?.is_user_matched,
    user?.is_id_card_image_required_to_resolve_match,
    user?.matched_user_has_overdue_loan,
    user?.matched_user_has_sold_loan,
    user?.matched_user_in_black_list,
  ]);

  const handleNICFrontUploadDialogOpen = () => setIsUploadIdPhotoModalOpen(true);

  const noConsentHandler = () => setNoConsent((prevState) => !prevState);

  const handleNICErrorDialogOpen = () => setIsNICErrorDialogOpen(true);

  const sendLog = (action: string, additional_data: string, source_user_id?: number) => {
    if (user) {
      const data = {
        duplicate_user_id: user.id,
        source_user_id: source_user_id || null,
        log_data: { action, additional_data: additional_data },
      };
      return dispatch(sendDuplicateLog(data)).unwrap();
    }
  };

  const onSubmit: SubmitCoreHandler<PersonalDataFormModel> = async (values, { setError }) => {
    values = clearEmptyFormValues<PersonalDataFormModel>(values);

    const dataNew = {
      ...values,
      first_name: values[PersonalDataFormFields.Name].trim(),
      last_name: values[PersonalDataFormFields.LastName].trim(),
      birthday: formatServerDate(values[PersonalDataFormFields.DateOfBirth] as Date),
      personal_id_number:
        values[PersonalDataFormFields.PersonalIdType] === PersonalIdType.New
          ? values[PersonalDataFormFields.PersonalIdNumNew]
          : `${values[PersonalDataFormFields.PersonalIdNumberOld]}${
              values[PersonalDataFormFields.PersonalIdNumberOldSerial]
            }`,
    };

    delete dataNew[PersonalDataFormFields.PersonalIdNumberOldSerial];
    delete dataNew[PersonalDataFormFields.PersonalIdNumberOld];
    delete dataNew[PersonalDataFormFields.PersonalIdNumNew];
    delete dataNew[PersonalDataFormFields.PersonalIdType];

    return dispatch(updateUser(dataNew))
      .unwrap()
      .then((res) => {
        if (!res.is_data_processing_consent_given) {
          //невозможный кейс. на степе 2 галка была поставлена
          return noConsentHandler();
        }

        if (res.is_user_matched) {
          return dispatch(postUserResolveMatchAction())
            .unwrap()
            .then(() => {
              dispatch(signIn())
                .unwrap()
                .then((res) => {
                  let pathRedirect = getRegStepPath(res.registration_step);
                  if (res.state === USER_STATE.ACTIVE) {
                    pathRedirect = URL_ACTIVE_LOAN;
                  }
                  dispatch(setRedirect({ to: pathRedirect, replace: true }));
                })
                .catch((err) => {
                  sendLog(DUPLICATE_ACTION.SIGN_IN_ERROR, JSON.stringify(err));
                });
            })
            .catch(() => {
              if (res.is_id_card_image_required_to_resolve_match) {
                sendLog(DUPLICATE_ACTION.SHOW_MODAL, 'Show modal with upload photo');
                return handleNICFrontUploadDialogOpen();
              } else {
                sendLog(DUPLICATE_ACTION.SHOW_MODAL, 'Show modal with error text');
                return handleNICErrorDialogOpen();
              }
            });
        }
        dispatch(setRedirect({ to: getRegStepPath(res.registration_step), replace: true }));
      })
      .catch((err) => {
        if (err.code === 'users_mismatched') {
          handleNICErrorDialogOpen();
        } else {
          setError &&
            !err.detail.includes('email') &&
            setError('root.commonError', {
              type: 'validate',
              message: err.message || err.violations[0]?.message || err.detail || 'Unknown error',
            });
        }
      });
  };

  return (
    <>
      <Step3PersData onSubmit={onSubmit} />
      {noConsent && <PersonalDataProcessingDialogModalContainer />}
      {isUploadIdPhotoModalOpen && <UploadIdPhotoModalContainer sendLog={sendLog} />}
      {isNICErrorDialogOpen && <NICErrorModalContainer />}
    </>
  );
};
