import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { UseFormSetError } from 'react-hook-form';
import { useTimer } from 'core/hooks';
import { removeRegCallTimer, removeRegSmsTimer } from 'utils';
import { RepeatCallRegContainer } from '../RepeatCallContainer';
import { SpinnerIcon } from 'components';
import { TIMER_WITH_CALL } from 'constantsLk';

interface Step2ConfirmPhoneWithCallProps {
  setConfirmWithSMS: () => void;
  showCodeTimer: () => void;
  hideCodeTimer: () => void;
  setError: UseFormSetError<any>;
}

export const Step2ConfirmPhoneWithCall = ({
  setConfirmWithSMS,
  showCodeTimer,
  hideCodeTimer,
  setError,
}: Step2ConfirmPhoneWithCallProps) => {
  const { t } = useTranslation();

  const [isShowSpinner, setIsShowSpinner] = useState(true);

  const { timerValue, isShowTimer, showTimer, hideTimer } = useTimer({
    initialTime: TIMER_WITH_CALL,
    timerName: 'Step2ConfirmPhoneWithCall',
  });

  useEffect(() => {
    removeRegSmsTimer();
    hideCodeTimer();
    return () => {
      removeRegCallTimer();
      showCodeTimer();
    };
  }, []);

  return (
    <div className='alerts-item__primary gap-4'>
      {isShowSpinner && (
        <div className='m-auto txt-primary'>
          <SpinnerIcon />
        </div>
      )}

      <div className='flex-grow-1'>
        <p className='txt-b'>{t('we_will_call_you_nearest_time')}</p>
        <p className='txt-secondary'>{t('answer_call_please_follow_instructions')}</p>

        <RepeatCallRegContainer
          setConfirmWithSMS={setConfirmWithSMS}
          showTimer={showTimer}
          timerValue={timerValue}
          isShowTimer={isShowTimer}
          hideTimer={hideTimer}
          setIsShowSpinner={setIsShowSpinner}
          setError={setError}
        />
      </div>
    </div>
  );
};
