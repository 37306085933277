import { useState, FocusEventHandler } from 'react';
import ReactDatePicker, { ReactDatePickerProps } from 'react-datepicker';
import cn from 'classnames';

import { ErrorMessage } from 'core/components/common';
import { useHookFormFieldError } from 'core/hooks';

export interface DatePickerFieldProps extends Omit<ReactDatePickerProps, 'name' | 'className'> {
  label?: string | JSX.Element;
  leftContent?: string | JSX.Element;
  rightContent?: string | JSX.Element;
  error?: string;
  name: string;
  classes?: Partial<
    Record<'root' | 'error' | 'elementLeft' | 'elementRight' | 'label' | 'wrapper', string>
  >;
}

export const DatePickerField = (props: DatePickerFieldProps) => {
  const {
    classes = {},
    label,
    rightContent,
    leftContent,
    error,
    name,
    onBlur,
    onFocus,
    ...rest
  } = props;

  const [isFocus, setIsFocus] = useState<boolean>(false);

  const getFieldError = useHookFormFieldError();

  const handleBlur: FocusEventHandler<HTMLInputElement> = (e) => {
    onBlur && onBlur(e);
    setIsFocus(false);
  };

  const handleFocus: FocusEventHandler<HTMLInputElement> = (e) => {
    onFocus && onFocus(e);
    setIsFocus(true);
  };

  const errorMessage = getFieldError(error);

  return (
    <div
      className={cn(
        'input-box input-box__calendar',
        { 'input-box__no-empty': rest.selected || isFocus },
        { 'input-box__invalid': !!error },
        classes.root,
      )}
    >
      {label && (
        <label className={cn('input-box__label', classes.label)} htmlFor={name}>
          {label}
        </label>
      )}

      <div className={cn('input-box__input-wrapper', classes.wrapper)}>
        {leftContent && (
          <span className={cn('input-box__element_left', classes.elementLeft)}>{leftContent}</span>
        )}
        <ReactDatePicker
          id={name}
          onBlur={handleBlur}
          onFocus={handleFocus}
          name={name}
          selected={rest.selected}
          portalId='modal-root'
          // value={value}
          {...rest}
        />
        {rightContent && (
          <label htmlFor={name}>
            <span className={cn('input-box__element_right', classes.elementRight)}>
              {rightContent}
            </span>
          </label>
        )}
      </div>
      {error && <ErrorMessage classError={classes.error} message={errorMessage} />}
    </div>
  );
};
