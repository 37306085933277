import { useTranslation } from 'react-i18next';
import { ModalLayout } from 'core/components/common';
import { useTypedSelector } from 'core/hooks';
import { AddNotificationButton } from '../../AddNotificationButton';
import { activeLoanSelector } from 'store/selectors';

export const AddNotificationModal = ({
  isOpen,
  onClose,
}: {
  isOpen: boolean;
  onClose: () => void;
}) => {
  const { t } = useTranslation();
  const loan = useTypedSelector(activeLoanSelector);

  return (
    <ModalLayout
      isOpenModal={isOpen}
      onClose={onClose}
      title={t`loan_has_been_activated`}
      classes={{ wrapper: 'modal-core__wrapper' }}
      isShowClose
      isOutSideClick
      isUsePortal
      content={
        <>
          <p className='txt mb-5'>{t`add_notification_to_calendar`}</p>
          {loan && <AddNotificationButton loan={loan} variant='-primary' onClose={onClose} />}
        </>
      }
    />
  );
};
