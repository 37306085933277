// import EverCookie from 'EverCookie';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { $axios, setAccessToken, setRefreshToken, setUserID } from 'core/services';
import { Actions, AuthResp } from 'models';
import { getFingerprint } from 'utils/getFingerprint';
import { setAuthorized } from '../userActions';
import { getUserInfo } from './userApi';

declare global {
  interface Window {
    fingerPrint: any;
    jslabApi: any;
    dataLayer: any;
    HyperSnapSDK: any;
    HVFaceModule: any;
    HVFaceConfig: any;
    HyperSnapParams: any;
    OzLiveness: any;
    Regula: any;
    RegulaDocumentSDK: any;
  }
}

window.fingerPrint = window.fingerPrint || {};

export const sendOTPassword = createAsyncThunk<{ message: string }, { phone_number: string }>(
  Actions.SEND_OTP_PASSWORD,
  async (data, { rejectWithValue, dispatch }) => {
    const config = {
      method: 'POST',
      url: '/one_time_password/get_password',
      data,
    };

    try {
      return await $axios(config, dispatch);
    } catch (errors: any) {
      const { user, token, refresh_token } = errors;
      if (user?.id) {
        setRefreshToken(refresh_token);
        setAccessToken(token);
        setUserID(user.id.toString());
        await dispatch(getUserInfo());
        await dispatch(setAuthorized(true));
      }
      return rejectWithValue(errors);
    }
  },
);

export const OTPLogin = createAsyncThunk<
  AuthResp,
  {
    username: string;
    password: string;
  }
>(Actions.OTP_LOGIN, async (data, { rejectWithValue, dispatch }) => {
  // const EC = new EverCookie('EverCookie');

  const config = {
    method: 'POST',
    url: '/one_time_password/login',
    data,
  };

  try {
    return await $axios(config, dispatch);
  } catch (errors) {
    return rejectWithValue(errors);
  }
});

export const getAttempts = createAsyncThunk<AuthResp, string>(
  Actions.GET_ATTEMPTS,
  async (phone_number, { rejectWithValue, dispatch }) => {
    const { visitor_id } = getFingerprint();

    const data = {
      fingerprint_js: { visitor_id },
      phone_number,
    };

    const config = {
      method: 'POST',
      url: '/recaptcha/get_attempts',
      data,
    };

    try {
      return await $axios(config, dispatch);
    } catch (errors) {
      return rejectWithValue(errors);
    }
  },
);
