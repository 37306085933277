import { SubmitCoreHandler } from 'core/models';
import { setRedirect } from 'core/store/actions';

import { useAppDispatch } from 'hooks';
import { AddressFormModel } from 'models';
import { updateUser } from 'store/actions/api';
import { clearEmptyFormValues, ServerFieldErrorsHandler } from 'utils';
import { Step4Address } from './Step4Address';
import { getRegStepPath } from 'constantsLk';

export const Step4AddressContainer = () => {
  const dispatch = useAppDispatch();

  const onSubmit: SubmitCoreHandler<AddressFormModel> = async (values, { setError }) => {
    values = clearEmptyFormValues<AddressFormModel>(values);

    return dispatch(updateUser(values))
      .unwrap()
      .then((res) => {
        dispatch(setRedirect({ to: getRegStepPath(res.registration_step), replace: true }));
      })
      .catch((err) => {
        ServerFieldErrorsHandler(err, setError);
      });
  };

  return <Step4Address onSubmit={onSubmit} />;
};
