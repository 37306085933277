export const USER_STATE = {
  ACTIVE: 'active',
  CONFIRMED: 'confirmed',
  CREATED: 'created',
};

export const APPLICATION_STATE = {
  PENDING: 'pending',
  PROCESSING: 'processing',
  APPROVED: 'approved',
  REJECTED: 'rejected',
  CONFIRMED: 'confirmed',
};

export const LOAN_STATE = {
  ACTIVE: 'active',
  DEFAULTED: 'defaulted',
  PENDING: 'pending',
  CLOSED: 'closed',
  SOLD: 'sold',
  WRITTEN_OFF: 'written_off',
};

export const INCOME_STATE = {
  CANCELLED: 'cancelled',
  PROCESSED: 'processed',
};

export const DISCOUNT_STATE = {
  ACTIVE: 'active',
  COMPLETED: 'completed',
  EXPIRING: 'expiring',
  EXPIRED: 'expired',
  CANCELLED: 'cancelled',
  CANCELLED_BY_VIOLATION: 'cancelled by violation',
  CANCELLED_BY_EXTENSION: 'cancelled by extension',
};

export const PROMOTION_TYPE = {
  DISCOUNT: 'discount',
  RESTRUCTURING: 'restructuring',
  RESTRUCTURING_WITH_DISCOUNT: 'restructuring_with_discount',
  EXTENSION_DISCOUNT: 'extension_discount',
};
