import { useEffect } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { useTypedSelector } from 'core/hooks';
import { getWpLayout, removeRedirect } from 'core/store/actions';

import { DevelopmentInfoContainer } from 'components/DevelopmentInfoContainer';
import { useAppDispatch } from 'hooks';
import { setLangAttribute } from 'utils';
import { FooterContainer, HeaderContainer } from 'components';
import { MetaTags } from 'components/MetaTags';
import { getRedirectSelector, isServerErrorSelector } from 'store/selectors';
import { ServerErrorContainer } from '../500ServerError/ServerErrorContainer';
import { getStepperDisable } from 'components/DevelopmentInfoContainer/utils/cokisesUtil';
import * as Paths from 'constantsLk';
import { IS_DEVELOPER_MODE } from 'constantsLk';

export const RootPage = () => {
  const dispatch = useAppDispatch();
  const isServerError = useTypedSelector(isServerErrorSelector);
  const { i18n } = useTranslation();
  const lang = i18n.language;
  const navigate = useNavigate();
  const redirect = useTypedSelector(getRedirectSelector);

  useEffect(() => {
    if (!(IS_DEVELOPER_MODE && getStepperDisable())) {
      if (!redirect) return;

      if (typeof redirect === 'number') navigate(redirect);
      else {
        const { to, ...options } = redirect;
        navigate(to, options);
      }

      dispatch(removeRedirect());
    }
  }, [redirect]);

  useEffect(() => {
    dispatch(getWpLayout(lang));
    setLangAttribute(lang);
  }, [lang]);

  if (isServerError) return <ServerErrorContainer />;

  return (
    <>
      <MetaTags />
      <HeaderContainer />
      {Paths.IS_DEVELOPER_MODE && <DevelopmentInfoContainer />}
      <main className='container'>
        <Outlet />
      </main>
      <FooterContainer />
    </>
  );
};
