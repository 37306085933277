import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { isIOS } from 'react-device-detect';

import { SubmitCoreHandler, WpKeys } from 'core/models';
import { useTypedSelector } from 'core/hooks';
import { removeCookie } from 'core/services';

import { useAppDispatch } from 'hooks';
import { activeApplicationSelector, usernamePhoneSelector } from 'store/selectors';
import { LoanSmsConfirmFormModel, LoanSmsConfirmFields } from 'models';
import { Title } from '../Title';
import { PhoneSection } from '../PhoneSection';
import { SmsConfirmLoan } from './SmsConfirmLoan';
import {
  getAgreeDocument,
  getAgreements,
  getApplicationTransition,
  sendConfirmCodeAgree,
} from 'store/actions/api';
import { signIn } from 'store/actions';
import { EmailAlertWrapper } from '../alerts';

import { Spinner } from 'components/Spinner';

export const RequestConfirmContainer = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const usernamePhone = useTypedSelector(usernamePhoneSelector);
  const activeApplication = useTypedSelector(activeApplicationSelector);

  const [isPromoCodeEnable, setIsPromoCodeEnable] = useState<boolean | null>(null);
  const [isLoanAgreementLoading, setIsLoanAgreementLoading] = useState(false);

  useEffect(() => {
    if (activeApplication?.id) {
      dispatch(getApplicationTransition(activeApplication?.id))
        .unwrap()
        .then((loan_transitions) => {
          setIsPromoCodeEnable(loan_transitions.includes('apply_promo_code'));
        });
    }
  }, [activeApplication]);

  const onPromoCodeApply = () => {
    setIsLoanAgreementLoading(true);

    setTimeout(() => {
      setIsLoanAgreementLoading(false);
    }, 2000);
  };

  const onSubmit: SubmitCoreHandler<LoanSmsConfirmFormModel> = async (values, { setError }) => {
    if (activeApplication) {
      const data = { data: values, appId: activeApplication.id };
      return dispatch(sendConfirmCodeAgree(data))
        .unwrap()
        .then((res) => {
          removeCookie(WpKeys.TargetUrl);
          removeCookie(WpKeys.LoanPromoCode);

          if (res === null) {
            return dispatch(signIn()).unwrap();
          }
        })
        .catch(
          (e) =>
            setError &&
            setError(LoanSmsConfirmFields.Code, {
              type: 'validate',
              message: e?.message,
            }),
        );
    }
  };

  const handleGetAgreement = () => {
    if (activeApplication?.id) {
      setIsLoanAgreementLoading(true);

      dispatch(getAgreements({ idApp: activeApplication.id }))
        .unwrap()
        .then((resAgreements) => {
          dispatch(getAgreeDocument(resAgreements[resAgreements.length - 1]?.id))
            .unwrap()
            .then((resAgreementsDocument) => {
              const url = window.URL.createObjectURL(resAgreementsDocument);
              window.open(url, isIOS ? '_self' : '_blank');
            })
            .finally(() => setIsLoanAgreementLoading(false));
        });
    }
  };

  return (
    <EmailAlertWrapper>
      <div className='form__wrapper m-auto'>
        <div className='flex-column'>
          <Title name={'signing_loan_agreement'} />
          <PhoneSection phone={usernamePhone} />
        </div>
        <SmsConfirmLoan
          onSubmit={onSubmit}
          activeApplication={activeApplication}
          isPromoCodeEnable={isPromoCodeEnable}
          onPromoCodeApply={onPromoCodeApply}
        />
        <div className='flex-center gap-2 w-100 mt-4'>
          {isLoanAgreementLoading && (
            <div className='loader-small'>
              <Spinner />
            </div>
          )}
          <span
            className={`link ${isLoanAgreementLoading && 'txt-grey pointer-events-none'}`}
            onClick={handleGetAgreement}
          >
            {t`loan_agreement`}
          </span>
        </div>
      </div>
    </EmailAlertWrapper>
  );
};
