export const ERRORS = {
  USER: {
    NOT_FOUND: 'User not found.',
    AUTH_FAILED: 'Authentication failed',
    OTP_RESENDING_BLOCKED_ONE: 'One time password code resending blocked for 1 minute.',
    OTP_RESENDING_BLOCKED: 'One time password code resending is blocked.',
    ARCHIVE: 'User matches recent created archive client.',
  },
  PROMO_CODE: {
    NOT_APPLICABLE: "Promo code isn't applicable.",
    HAVE_BEEN_USED: 'Promo code have been used.',
    NOT_FOUND: 'Promo code not found.',
    DISABLED: 'Promo code is disabled.',
    EXPIRED: 'The promo-code has expired.',
  },
};
