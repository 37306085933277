import { useTypedSelector } from 'core/hooks';

import { AccountCards } from './AccountCards';
import { userSelector } from 'store/selectors';

const AccountCardsContainer = () => {
  const user = useTypedSelector(userSelector);

  return <AccountCards user={user} />;
};

export default AccountCardsContainer;
