import { LivenessDetectionType } from 'models';
import { HYPERVERGE_URL, LIVENESS, OZFORENCIS_URL } from 'constantsLk';

export const initLivenessSDK = (liveness: LivenessDetectionType) => {
  if (liveness === LIVENESS.BIOID) return;

  const script: HTMLScriptElement = document.createElement('script');
  script.async = true;
  script.id = 'websdk-plugin';
  script.src =
    liveness === LIVENESS.HYPERVERGE
      ? HYPERVERGE_URL
      : process.env.REACT_APP_OZ_FORENSICS || OZFORENCIS_URL;

  document.body.appendChild(script);
};
