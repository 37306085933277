import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { removeCookie } from 'core/services';
import { useTypedSelector } from 'core/hooks';
import { loadedIDBAckSelector } from 'store/selectors';
import { IUploadPhotoComponentFormProps, UploadPhotoComponent } from 'components/DocumentUploader';
import { uploadFileByType } from 'store/actions/api/fileUploadApi';
import { getFileByTypeAction } from 'store/actions';
import { useUploadFiles } from 'hooks';
import { Title } from '../../../components';
import { DOCUMENTS, KEY_CAMERA_REQUEST } from 'constantsLk';

export const Step8_3UploadBackIdContainer = () => {
  const { t } = useTranslation();

  const loadedIDBAck = useTypedSelector(loadedIDBAckSelector);

  const { control, setValue } = useForm<IUploadPhotoComponentFormProps>({
    mode: 'onSubmit',
    shouldUnregister: true,
  });

  useEffect(() => {
    removeCookie(KEY_CAMERA_REQUEST);
  }, []);

  const { changeTypeFile, sendFile } = useUploadFiles({
    addFileAPI: uploadFileByType,

    getFileAPI: getFileByTypeAction,
  });

  return (
    <>
      <div className='flex-column gap-2 mb-5'>
        <Title classes={'form-title mb-0'} name={DOCUMENTS.BACK.title} />
        <p className='txt-r'>{t(DOCUMENTS.BACK.subtitle)}</p>
      </div>
      <div className='form'>
        <UploadPhotoComponent
          setValue={setValue}
          control={control}
          loadedFileType={loadedIDBAck}
          changeTypeFile={changeTypeFile}
          sendFile={sendFile}
          document={DOCUMENTS.BACK}
        />
      </div>
    </>
  );
};
