import { useTypedSelector } from 'core/hooks';
import { HowToGetBonuses } from 'components';
import { User } from 'models';
import { LoanCalculatorContainer } from '../AccountLoan/LoanCalculator';
import { isLoanActiveSelector, isMoratoriumSelector } from 'store/selectors';

interface AccountReferralProgramProps {
  user: User | null;
}

const AccountReferralProgram = (props: AccountReferralProgramProps) => {
  const { user } = props;

  const isLoanActive = useTypedSelector(isLoanActiveSelector);
  const isMoratorium = useTypedSelector(isMoratoriumSelector);

  return (
    <div className='w-100'>
      <div className='row align-items-start'>
        <div className='col-12 col-md-6 mb-4'>
          <HowToGetBonuses user={user} />
        </div>
        {!isLoanActive && !isMoratorium && (
          <div className='col-12 col-md-6 mb-4'>
            <LoanCalculatorContainer isReferralPage />
          </div>
        )}
      </div>
    </div>
  );
};

export default AccountReferralProgram;
