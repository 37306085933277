import { SubmitCoreHandler } from 'core/models';
import { setRedirect } from 'core/store/actions';

import { useAppDispatch } from 'hooks';
import { MartialRelativesFields, MartialRelativesFieldsFormModel } from 'models';
import { clearEmptyFormValues, onlyNumeric, ServerFieldErrorsHandler } from 'utils';
import { updateUser } from 'store/actions/api';
import { Step6MartialRelatives } from './Step6MartialRelatives';
import { getRegStepPath } from 'constantsLk';

export const Step6MartialRelativesContainer = () => {
  const dispatch = useAppDispatch();
  const onSubmit: SubmitCoreHandler<MartialRelativesFieldsFormModel> = async (
    values,
    { setError },
  ) => {
    values = clearEmptyFormValues<MartialRelativesFieldsFormModel>(values);

    const data = {
      ...values,
      contact_name: values[MartialRelativesFields.ContactName].trim(),
      [MartialRelativesFields.ContactPhone]: onlyNumeric(
        values[MartialRelativesFields.ContactPhone],
      ),
    };

    return dispatch(updateUser(data))
      .unwrap()
      .then((res) => {
        dispatch(setRedirect({ to: getRegStepPath(res.registration_step), replace: true }));
      })
      .catch((err) => {
        ServerFieldErrorsHandler(err, setError);
      });
  };

  return <Step6MartialRelatives onSubmit={onSubmit} />;
};
