import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { format } from 'date-fns';
import en from 'date-fns/locale/en-US';
import si from 'langSI';
import { formatNumber } from 'core/utils/parsers';
import { ITopUpPriceItem } from 'models';
import { ArrowIcon } from 'components';
import { formatMaturity } from 'constantsLk';

interface PriceInfoProps {
  topUpPriceItem: ITopUpPriceItem;
  isRequestBlock?: boolean;
}

export const TopUpPriceInfo = ({ topUpPriceItem, isRequestBlock }: PriceInfoProps) => {
  const { additional_amount, settlement_amount, maturity_date, principal, interest } =
    topUpPriceItem;

  const { t, i18n } = useTranslation();
  const selectedLanguage = i18n.language === 'en' ? en : si;

  const [isContentShow, setIsContentShow] = useState<boolean>(false);

  const handleShowContent = () => setIsContentShow(!isContentShow);

  return (
    <div className='flex-column justify-content-between mt-4'>
      <div className='flex-column gap-3'>
        <div className='lined-card-row border-n pt-0'>
          <span className='txt-xs txt-sb'>{`${isRequestBlock ? t('additional_amount') : t('settlement_amount')}`}</span>
          <span
            className={`${isContentShow ? 'up ' : ''}btn-secondary info-item`}
            onClick={handleShowContent}
          >
            <span>
              {formatNumber(`${isRequestBlock ? additional_amount : settlement_amount}`)} RS
            </span>
            <span className='arrow ml-2'>
              <ArrowIcon />
            </span>
          </span>
        </div>
        {isContentShow && (
          <>
            <div className='lined-card-row'>
              <span className='txt-xs txt-sb'>{t`active_loan_amount`}</span>
              <span className='info-item'>{formatNumber(principal)} RS</span>
            </div>
            {!isRequestBlock && (
              <div className='lined-card-row'>
                <span className='txt-xs txt-sb'>{t`additional_amount`}</span>
                <span className='info-item'>{formatNumber(additional_amount)} RS</span>
              </div>
            )}
            <div className='lined-card-row'>
              <span className='txt-xs txt-sb'>{t`fees`}</span>
              <span className='info-item'>{formatNumber(interest)} RS</span>
            </div>
            {isRequestBlock && (
              <div className='lined-card-row'>
                <span className='txt-xs txt-sb'>{t`settlement_amount`}</span>
                <span className='info-item'>{formatNumber(settlement_amount)} RS</span>
              </div>
            )}
            <div className='lined-card-row'>
              <span className='txt-xs txt-sb'>{t`payment_date`}</span>
              <span className='info-item'>
                {format(new Date(maturity_date), formatMaturity, { locale: selectedLanguage })}
              </span>
            </div>
          </>
        )}
      </div>
    </div>
  );
};
