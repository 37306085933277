import { useTypedSelector } from 'core/hooks';
import { LoanSoldPageContainer } from '../LoanSold';
import { isLoanSoldSelector } from 'store/selectors';
import { StatusWithPartner } from '../ApplicationStatuses';

export const LoanBlackListContainer = () => {
  const isLoanSold = useTypedSelector(isLoanSoldSelector);

  if (isLoanSold) return <LoanSoldPageContainer />;
  //black list Page
  return <StatusWithPartner />;
};
