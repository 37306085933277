import React, { FC } from 'react';
import { useAppDispatch } from 'hooks';
import { UseFormSetError } from 'react-hook-form';
import { ITimerValue } from 'models/common';
import { TimerButtonItem } from 'components';
import { resendTopUpCode } from 'store/actions/api';
import '../ResendLoanCodeContainer/styles.scss';

interface ResendTopUpCodeProps {
  setError: UseFormSetError<any>;
  isShowTimer: boolean;
  showTimer: () => any;
  timerValue: ITimerValue;
  hintText?: string;
  hideTimer: () => void;
  onResendCode?: () => void;
  topUpId: number;
}

export const ResendTopUpCode: FC<ResendTopUpCodeProps> = ({
  timerValue,
  isShowTimer,
  showTimer,
  onResendCode,
  topUpId,
}) => {
  const dispatch = useAppDispatch();

  const startTimerHandler = async (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();

    await showTimer();
    dispatch(resendTopUpCode(topUpId))
      .unwrap()
      .then(() => {
        if (onResendCode) onResendCode();
      });
  };

  return (
    <div className='align-items-center gap-4'>
      <TimerButtonItem
        isShowTimer={isShowTimer}
        startTimerHandler={startTimerHandler}
        secondString={timerValue.secondString}
        minutesString={timerValue.minutesString}
        buttonText={'resend_code'}
        isCodeConfirmation
      />
    </div>
  );
};
