import { useEffect } from 'react';
import { getUserID } from 'core/services';
import { useTypedSelector } from 'core/hooks';
import AccountReferralProgram from './AccountReferralProgram';
import {
  isFirstLoanSelector,
  pricesSelector,
  productIdSelector,
  userSelector,
} from 'store/selectors';
import { useAppDispatch } from 'hooks';
import { getProduct } from 'store/actions/api';

const AccountReferralProgramPageContainer = () => {
  const user = useTypedSelector(userSelector);

  const dispatch = useAppDispatch();

  const productId = useTypedSelector(productIdSelector);
  const isFirstLoan = useTypedSelector(isFirstLoanSelector);
  const price = useTypedSelector(pricesSelector);

  useEffect(() => {
    if (productId && !price) {
      const data = {
        productId,
        userId: getUserID(),
        isFirstLoan,
      };

      dispatch(getProduct(data));
    }
  }, [isFirstLoan, productId]);

  return <AccountReferralProgram user={user} />;
};

export default AccountReferralProgramPageContainer;
