import { ChangeEventHandler, ReactNode } from 'react';
import cn from 'classnames';

export interface RadioField {
  label: string | ReactNode;
  value: string | number;
  icon?: JSX.Element;
}

export interface RadioFieldProps {
  className?: string;
  name: string;
  onChange: (name: string, value: string) => void;
  option: RadioField;
  value: string;
}

export const RadioField = (props: RadioFieldProps) => {
  const { option, className, onChange, name, value } = props;

  const handleChange: ChangeEventHandler<HTMLInputElement> = (e) => onChange(name, e.target.value);

  const checked = String(option.value) === String(value);

  return (
    <label className={cn('radio-box__option', checked && 'radio-box__option_checked', className)}>
      <div className='radio-box__option_label align-items-center'>
        {option.icon && <span className='flex-center mr-1'>{option.icon}</span>}
        <span>{option.label}</span>
      </div>
      <input type='radio' onChange={handleChange} value={option.value} checked={checked} />
    </label>
  );
};
