import { SubmitCoreHandler } from 'core/models';
import { setRedirect } from 'core/store/actions';

import { useAppDispatch } from 'hooks';
import { AdditionalDataFields, AdditionalDataFieldsFormModel, HasExternalLoans } from 'models';
import { clearEmptyFormValues, onlyNumeric, ServerFieldErrorsHandler } from 'utils';
import { updateUser } from 'store/actions/api';
import { Step5AdditionalDetails } from './Step5AdditionalDetails';
import { getRegStepPath } from 'constantsLk';

export const Step5AdditionalDetailsContainer = () => {
  const dispatch = useAppDispatch();

  const onSubmit: SubmitCoreHandler<AdditionalDataFieldsFormModel> = async (
    values,
    { setError },
  ) => {
    if (values[AdditionalDataFields.CompanyPhone]) {
      values = {
        ...values,
        [AdditionalDataFields.CompanyPhone]: onlyNumeric(values[AdditionalDataFields.CompanyPhone]),
      };
    }

    if (values[AdditionalDataFields.ExternalLoan] === HasExternalLoans.Yes) {
      values = {
        ...values,
        [AdditionalDataFields.ExternalLoan]: true,
      };
    } else {
      values = {
        ...values,
        [AdditionalDataFields.ExternalLoan]: false,
      };
    }

    values = clearEmptyFormValues<AdditionalDataFieldsFormModel>(values);

    return dispatch(updateUser(values))
      .unwrap()
      .then((res) => {
        dispatch(setRedirect({ to: getRegStepPath(res.registration_step), replace: true }));
      })
      .catch((err) => {
        ServerFieldErrorsHandler(err, setError);
      });
  };

  return <Step5AdditionalDetails onSubmit={onSubmit} />;
};
