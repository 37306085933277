import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { addDays, format, isAfter, isBefore } from 'date-fns';

import { getUserID, sendDataLayer } from 'core/services';

import { Loan, PromotionModel } from 'models';
import { formatDate } from 'utils/parsers';
import si from 'langSI';
import en from 'date-fns/locale/en-US';
import { GTM_LABEL_PROLONGATION } from 'utils/analitics/gtmLabel';
import { ArrowIcon } from 'components';
import { RepayOfflineModalContainer } from 'components/modals/RepayOfflineModal';
import { CURRENCY, formatMaturity } from 'constantsLk';

interface ProlongationProps {
  activeCreditData: Loan;
  isCollapsible: boolean | null;
  activePromo?: PromotionModel;
  formattedDate?: string;
  description?: string;
  showHowToRepayButton?: boolean;
}

export const Prolongation = ({
  activeCreditData,
  isCollapsible,
  activePromo,
  formattedDate,
  description,
  showHowToRepayButton,
}: ProlongationProps) => {
  const {
    allowed_extension_tenors_price,
    allowed_extension_tenors,
    extension_request_allowed_from,
    extension_requested_at,
    matured_at,
    tenor,
    product_name,
    accrued_total,
    accrued_service_fee,
    accrued_consulting_fee,
    payment_link_for_extension_uris,
    accrued_late_payment_fee,
  } = activeCreditData;
  const { t, i18n } = useTranslation();
  const lang = i18n.language;

  const [isContentShow, setIsContentShow] = useState<boolean>(!isCollapsible);
  const [isOpenModal, setIsOpenModal] = useState(false);

  const handleModalOpen = () => setIsOpenModal(true);

  const handleModalClose = () => setIsOpenModal(false);

  const handleShowContent = () => setIsContentShow(!isContentShow);

  const prolongData = useMemo(() => {
    const now = new Date().toUTCString();
    const maturedAt = new Date(matured_at);
    const extensionRequestAllowedFromDate = new Date(extension_request_allowed_from);
    const prolongBtnLabel = isAfter(extensionRequestAllowedFromDate, new Date(now))
      ? t('prolong_date', { date: formatDate(extensionRequestAllowedFromDate) })
      : t('prolong');

    return {
      maturedAt,
      extensionRequestAllowedFromDate,
      isProlongationDisabled:
        isAfter(extensionRequestAllowedFromDate, new Date(now)) || extension_requested_at,
      maturityDateInit: isBefore(maturedAt, new Date(now)) ? new Date(now) : maturedAt,
      prolongBtnLabel,
    };
  }, [matured_at, extension_request_allowed_from, extension_requested_at, lang]);

  const selectedLanguage = lang === 'en' ? en : si;

  const [termProlong, setTermProlong] = useState(tenor || allowed_extension_tenors[0] || 10);
  const [maturityDate, setMaturityDate] = useState(
    addDays(prolongData.maturityDateInit, allowed_extension_tenors[0]),
  );

  const formattedMaturityDate = format(maturityDate, formatMaturity, {
    locale: selectedLanguage,
  });

  const initTotalSum = () => {
    switch (product_name) {
      case 'PDLv1': {
        return (accrued_total * 0.3).toFixed(0);
      }
      case 'PDLv2': {
        return accrued_service_fee + accrued_consulting_fee + accrued_late_payment_fee;
      }
      default: {
        return allowed_extension_tenors_price[termProlong];
      }
    }
  };

  const [totalSum, setTotalSum] = useState<string | number>(initTotalSum());

  const handleProlongLink = (term: number) => () => {
    setTermProlong(term);
    setTotalSum(allowed_extension_tenors_price[term]);
    setMaturityDate(addDays(prolongData.maturityDateInit, term));
  };

  //analytics
  const handleClickProlong = () => {
    const UID = getUserID();
    sendDataLayer('click_button', GTM_LABEL_PROLONGATION, UID, { button_name: 'pay_to_prolong' });
  };

  return (
    <div className={`${!activePromo ? 'card prolongation' : ''} flex-column`}>
      <h2 className='txt-md txt-sb mb-2'>{t(`${activePromo ? description : 'prolongation'}`)}</h2>
      {activePromo ? (
        <span className='txt txt-secondary mb-4'>{`${t`offer_is_valid_till`} ${formattedDate}`}</span>
      ) : (
        <span className='mb-4'>{t`prolongation_subtitle`}</span>
      )}

      {isContentShow && (
        <>
          <p className='txt-sb txt-xs mb-2'>{t`select_extension_period`}</p>

          <div className='w-100 flex-column gap-2'>
            {allowed_extension_tenors.length > 1 && (
              <div className='btn-switch-group'>
                {allowed_extension_tenors.map((item) => (
                  <button
                    className={`flex-grow-1 pt-0 pb-0 ${
                      termProlong === item && !prolongData.isProlongationDisabled
                        ? 'pill-outlined-primary'
                        : 'pill-primary'
                    }`}
                    onClick={handleProlongLink(item)}
                    key={`btn-${item}`}
                    disabled={prolongData.isProlongationDisabled}
                  >
                    <p className='txt-sb txt-xs txt mt-2 mb-2'>
                      {item} {t('days')}
                    </p>
                  </button>
                ))}
              </div>
            )}

            {activePromo ? (
              <div className='flex-column gap-3'>
                <div className='border-top-light lined-card-row'>
                  <span className='txt-xs txt-sb'>{t('total_prolong')}</span>
                  <span className='info-item crossed'>
                    {activePromo.calculation.total_actual_debt} {CURRENCY}
                  </span>
                </div>

                <div className='border-top-light lined-card-row'>
                  <span className='txt-xs txt-sb'>{t('total_prolong_with_discount')}</span>
                  <span className='info-item'>
                    {activePromo.calculation.total_debt_after_discount} {CURRENCY}
                  </span>
                </div>

                <div className='border-top-light lined-card-row'>
                  <span className='txt-xs txt-sb'>{t('maturity_date')}</span>
                  <span className='info-item'>{formattedMaturityDate}</span>
                </div>
              </div>
            ) : (
              <div className='flex-column gap-3'>
                <div className='lined-card-row'>
                  <span className='txt-xs txt-sb'>{t('total_prolong')}</span>
                  <span className='info-item'>{`${totalSum} RS`}</span>
                </div>
                <div className='lined-card-row'>
                  <span className='txt-xs txt-sb'>{t('maturity_date')}</span>
                  <div className='info-item'>{formattedMaturityDate}</div>
                </div>
              </div>
            )}
          </div>

          {termProlong && !prolongData.isProlongationDisabled ? (
            <a
              onClick={handleClickProlong}
              className='btn btn--primary mt-4 w-100'
              target='_blank'
              rel='noreferrer'
              href={payment_link_for_extension_uris[termProlong]}
            >
              <span>{prolongData.prolongBtnLabel}</span>
            </a>
          ) : (
            <span className='btn btn--primary btn--disabled mt-4'>
              <span>{prolongData.prolongBtnLabel}</span>
            </span>
          )}
        </>
      )}

      {isCollapsible && (
        <div
          className={`btn btn-secondary flex-center ${isContentShow ? 'mt-4 up' : ''}`}
          onClick={handleShowContent}
        >
          <span className='arrow flex-center'>
            <ArrowIcon />
          </span>
        </div>
      )}

      {showHowToRepayButton && (
        <>
          <div className='btn btn-secondary flex-center min-h-44 mt-4' onClick={handleModalOpen}>
            <p className='txt txt-sb'>{t('how_repay_offline')}</p>
          </div>

          {isOpenModal && (
            <RepayOfflineModalContainer
              isOpenModal={isOpenModal}
              handleModalClose={handleModalClose}
              activeCreditData={activeCreditData}
            />
          )}
        </>
      )}
    </div>
  );
};
