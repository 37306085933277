import { useEffect } from 'react';
import { useLocation, useParams } from 'react-router-dom';

import { setRedirect } from 'core/store/actions';

import { useAppDispatch } from 'hooks';
import { Spinner } from 'components';
import { getDataBySlugAction } from 'store/actions';
import { getRegStepPath, PATH_SHORT_LINK_LOAN_RESULT, URL_USER_REGISTRATION } from 'constantsLk';

export const ShortLinkDataSlug = () => {
  const { slug } = useParams();
  const dispatch = useAppDispatch();
  const location = useLocation();

  const params = location.search;

  useEffect(() => {
    if (slug) {
      dispatch(getDataBySlugAction({ slug, params }))
        .unwrap()
        .then((res) => {
          if (res && 'registration_step' in res) {
            dispatch(setRedirect({ to: getRegStepPath(res.registration_step), replace: true }));
          } else {
            dispatch(setRedirect({ to: `/${PATH_SHORT_LINK_LOAN_RESULT}` }));
          }
        })
        .catch(() => {
          dispatch(setRedirect({ to: URL_USER_REGISTRATION }));
        });
    } else {
      dispatch(setRedirect({ to: URL_USER_REGISTRATION }));
    }
  }, []);

  return <Spinner />;
};
