import { coreYup, validateFieldSync } from 'core/utils/validators';
import { ValidateMinLengthType } from 'core/models';
import { FIELD_REQUIRED } from 'core/constants';
import { Gender, HasExternalLoans, PersonalIdType } from 'models/common';
import { getSubYear } from '../functions';
import { INCORRECT_DATA, MAX_BIRTH_NIC_ISSUE_DAY, MAX_USER_AGE, MIN_USER_AGE } from 'constantsLk';

export const DNI_CONTROL_LETTERS = 'TRWAGMYFPDXBNJZSQVHLCKE';
export const DNI_REGEX = /^\d{8}[TRWAGMYFPDXBNJZSQVHLCKE]$/i;
export const NIE_REGEX = /^[XYZ]\d{7}[TRWAGMYFPDXBNJZSQVHLCKE]$/i;
export const ENG_REGEX = /^(?=.*[a-zA-Z])[a-zA-Z\s]+$/;

export const EMAIL_SYMBOLS_REGEX = /^[a-zA-Z0-9@!#$%^&*_+{}.?\s/~-]+$/;

const FORBIDDEN_EMAIL = [
  'cashx.lk',
  'loanplus.lk',
  'gamil.com',
  'gmali.com',
  'gamail.com',
  'gmaill.com',
  'gimal.com',
];

/**
 * Checks if the dni control code (letter) provided is valid.
 */
const isValidDniLetter = (dni: string | null): boolean => {
  if (!dni) return false;

  const letterIndex = +dni.replace(/\D/g, '') % 23;

  const letter = dni.slice(-1);
  return DNI_CONTROL_LETTERS.charAt(letterIndex) === letter;
};

/**
 * Returns a new string with the nie letter (XYZ) replaced.
 */
const replaceNieLetter = (nie: string): string | null => {
  const nieLetter: string | number = nie.charAt(0).toUpperCase();

  switch (nieLetter) {
    case 'X':
      return 0 + nie.substring(1);
    case 'Y':
      return 1 + nie.substring(1);
    case 'Z':
      return 2 + nie.substring(1);
    default:
      return null;
  }
};

/**
 * Checks if the given dni is valid.
 */
export const isValidDni = (dni: string): boolean => {
  return DNI_REGEX.test(dni) && isValidDniLetter(dni);
};

/**
 * Checks if the given nie is valid.
 */
export const isValidNie = (nie: string): boolean => {
  return NIE_REGEX.test(nie) && isValidDniLetter(replaceNieLetter(nie));
};

const dynamicalMessage = {
  phone: {
    from: 0,
    to: 9,
    transform: { regular: /\D/gi },
    message: 'phone_should_consist_11_digits',
  },
  code: {
    from: 0,
    to: 4,
    transform: { regular: /\D/gi },
    message: 'min_length_field',
  },
  dniNie: { from: 0, to: 9 },
  postalCode: { from: 5, to: 5 },
  personalIdOld: {
    from: 9,
    to: 9,
    message: 'personal_id_number_old_err_validate',
    transform: { regular: /\s/g },
  },
  personalIdNew: {
    from: 12,
    to: 12,
    message: 'personal_id_number_new_err_validate',
    transform: { regular: /\s/g },
  },
  employmentCompanyPhone: {
    from: 0,
    to: 10,
    message: 'phone_should_consist_10_digits',
    transform: { regular: /\D/gi },
  },
} satisfies Record<string, ValidateMinLengthType>;

export const validatePhone = {
  validate: validateFieldSync(
    coreYup
      .string()
      .required('field_err_required')
      // all numbers in order (e.g. +34 123 45 67 89) & all numbers are the same (e.g. +34 111 11 11 11)
      // .test('all numbers in order & all numbers are the same', FIELD_INCORRECT_PHONE, (value): any => {
      //   const onlyNumbersValue = value?.replace(/\D+/gim, '') || '';
      //
      //   return (
      //     onlyNumbersValue !== '123456789' &&
      //     /^(?=.*(.)(?!$|.*\1))[0-9]+$/.test(onlyNumbersValue)
      //   );
      // })
      .test('first 7', 'phone_number_err_first_7', (value): any => {
        return value && value[0] === '7';
      })
      .minLength(dynamicalMessage.phone),
  ),
};

export const validatePhoneFirstZero = {
  validate: validateFieldSync(
    coreYup
      .string()
      .required('field_err_required')
      .test('first 0', 'phone_number_err_first_0', (value): any => {
        return value && value[0] !== '0';
      })
      .minLength(dynamicalMessage.phone),
  ),
};

export const validateDurationWork = {
  validate: validateFieldSync(
    coreYup
      .string()
      .required('field_err_required')
      .test('first 0', 'duration_work_err_first_0', (value): any => {
        return value && value[0] !== '0';
      }),
  ),
};

export const validateHasExternalLoans = {
  validate: validateFieldSync(
    coreYup
      .mixed()
      .required(FIELD_REQUIRED)
      .oneOf([HasExternalLoans.Yes, HasExternalLoans.No], FIELD_REQUIRED),
  ),
};

export const validateCheckBox = {
  validate: validateFieldSync(
    coreYup.boolean().required('field_err_required').oneOf([true], 'checkbox_err_required'),
  ),
};

export const validateRequiredPhoto = {
  validate: validateFieldSync(coreYup.boolean().oneOf([true], 'please_upload_photo')),
};

export const validatePersonalIdOld = {
  validate: validateFieldSync(
    coreYup.string().required('field_err_required').minLength(dynamicalMessage.personalIdOld),
  ),
};

export const validatePersonalIdNew = {
  validate: validateFieldSync(
    coreYup.string().required('field_err_required').minLength(dynamicalMessage.personalIdNew),
  ),
};

export const validatePhoneCode = {
  validate: validateFieldSync(
    coreYup.string().required('sms_code_required').isNumbers().minLength(dynamicalMessage.code),
  ),
};

export const validateText = {
  validate: validateFieldSync(coreYup.string().required('field_err_required')),
};

export const validateURL = {
  validate: validateFieldSync(
    coreYup
      .string()
      .required('field_err_required')
      .matches(/^https:\/\/.*/, 'field_err_invalid_url'),
  ),
};

export const createEnglishValidator = (errorKey: string) => ({
  validate: validateFieldSync(
    coreYup
      .string()
      .required('field_err_required')
      .matches(/^(?=.*[a-zA-Z])[a-zA-Z\s]+$/, errorKey)
      .min(2, 'field_min_length_error'),
  ),
});

export const validateOnlyEnglishText = createEnglishValidator('field_err_not_en');
export const validateOnlyEnglishCompanyName = createEnglishValidator('company_name_err_not_en');
export const validateOnlyEnglishJobPosition = createEnglishValidator('job_position_err_not_en');

export const validateTextMinLength = {
  validate: validateFieldSync(
    coreYup.string().required('field_err_required').min(2, 'field_min_length_error'),
  ),
};

export const validateAccountNumber = {
  validate: validateFieldSync(
    coreYup.string().required('field_err_required').min(3, 'field_min_length3_max_length20_error'),
  ),
};

export const validateDniNie = {
  validate: validateFieldSync(
    coreYup
      .string()
      .required(FIELD_REQUIRED)
      .test('it is a real dni/nie code', INCORRECT_DATA, (value = '') => {
        return isValidDni(value) || isValidNie(value);
      })
      .minLength(dynamicalMessage.dniNie),
  ),
};

export const validateGender = {
  validate: validateFieldSync(
    coreYup.mixed().required(FIELD_REQUIRED).oneOf([Gender.Male, Gender.Female], FIELD_REQUIRED),
  ),
};

export const validatePersonalIdType = {
  validate: validateFieldSync(
    coreYup
      .mixed()
      .required(FIELD_REQUIRED)
      .oneOf([PersonalIdType.Old, PersonalIdType.New], FIELD_REQUIRED),
  ),
};

export const validateSelect = {
  validate: validateFieldSync(coreYup.string().nullable().required('field_select_err_required')),
};

export const validateSelectEducation = {
  validate: validateFieldSync(coreYup.string().nullable().required('education_err_required')),
};

export const validateTillDate = {
  validate: validateFieldSync(
    coreYup
      .date()
      .typeError('field_err_required')
      .required('field_err_required')
      .max(MAX_BIRTH_NIC_ISSUE_DAY, 'max_date_err')
      // .min(getSubDay(1), 'min_date_err')
      .typeError('field_date_err_validate'),
  ),
};

export const validateBirthDate = {
  validate: validateFieldSync(
    coreYup
      .date()
      .typeError('field_err_required')
      .required('field_err_required')
      .max(getSubYear(MIN_USER_AGE), 'min_birthdate_err')
      .min(getSubYear(MAX_USER_AGE), 'max_birthdate_err')
      .typeError('field_date_err_validate'),
  ),
};

const validateEmailDomain = (email: string) => {
  const domain = email.split('@')[1];
  return !FORBIDDEN_EMAIL.includes(domain);
};

export const validateEmail = {
  validate: validateFieldSync(
    coreYup
      .string()
      .required('field_err_required')
      .trim()
      .email('field_email_err_validate')
      .matches(EMAIL_SYMBOLS_REGEX, {
        message: 'email_err_not_en',
        excludeEmptyString: true,
      })
      .test('email-prefix-check', (val: any) => {
        if (!val) return true;
        const emailPrefix = val.split('@')[0];

        const hasWWW = emailPrefix.startsWith('www.');
        const cleanedPrefix = hasWWW ? emailPrefix.replace(/^www\./, '') : emailPrefix;

        if (cleanedPrefix.length < 3) {
          return hasWWW
            ? new coreYup.ValidationError('email_err_www_prefix')
            : new coreYup.ValidationError('email_min_length3_error');
        }

        return true;
      })
      .test('is-forbidden-domain', 'email_cannot_be_applied', (val) => {
        if (!val) return true;
        return validateEmailDomain(val);
      }),
  ),
};

export const validateEmailWithoutDomain = {
  validate: validateFieldSync(
    coreYup
      .string()
      .required('field_err_required')
      .trim()
      .matches(EMAIL_SYMBOLS_REGEX, {
        message: 'email_err_not_en',
        excludeEmptyString: true,
      })
      .test('email-prefix-check', (val: any) => {
        if (!val) return true;
        const hasWWW = val.startsWith('www.');
        const emailPrefix = hasWWW ? val.replace(/^www\./, '') : val;

        if (emailPrefix.length < 3) {
          return hasWWW
            ? new coreYup.ValidationError('email_err_www_prefix')
            : new coreYup.ValidationError('email_min_length3_error');
        }

        return true;
      }),
  ),
};

export const validatePromoCode = {
  validate: validateFieldSync(coreYup.string().trim().min(3, 'field_min_length3_error').max(50)),
};
