import { format } from 'date-fns';
import { DATE_FORMAT, SERVER_DATE_FORMAT } from 'constantsLk';

export const onlyNumbers = (value: string) => value && value?.replace(/[^0-9]/g, '');
export const phoneSevenFirst = (value: string) => value && value?.replace(/^[^7]*/, '');
export const notCyrillic = (value: string) =>
  value && value?.replace(/[а-яА-Яё][а-яА-Яё\s-]*$/, '');
export const noSymbols = (value: string) => value && value?.replace(/[^\w-\s]/gi, '');
export const noNumbers = (value: string) => value && value?.replace(/[0-9]/g, '');

export const onlyEnSiTaSymbols = (value: string) =>
  value ? value.replace(/[^\u0020-\u007F\u0D80-\u0DFF\u0B80-\u0BFF]/g, '') : '';

export const formatDate = (v: Date) => format(v, DATE_FORMAT);
export const formatServerDate = (v: Date) => format(v, SERVER_DATE_FORMAT);
