import { useEffect, useState } from 'react';
import { TopUpCalculator } from './TopUpCalculator';
import { ITopUpPrice, TopUpModel } from '../../models';
import { TopUpConfirmation } from './TopUpConfirmation';
import { TopUpSuccessfulStatus } from './TopUpSuccessfulStatus';
import { getTopUpPriceList } from 'store/actions/api';
import { useAppDispatch } from 'hooks';

export enum TopUpTransitions {
  Confirm = 'confirm',
  Request = 'request',
  ResendConfirmationCode = 'resend_confirmation_code',
}

export enum TopUpStatus {
  Pending = 'pending',
  Processing = 'processing',
  Processed = 'processed',
  Successful = 'successful',
  Error = 'error',
}

interface TopUpContainerProps {
  topUpInfo: TopUpModel;
  userPhone?: string;
  handleCloseTopUp: () => void;
}

export const TopUpContainer = (props: TopUpContainerProps) => {
  const { topUpInfo, userPhone, handleCloseTopUp } = props;

  const dispatch = useAppDispatch();

  const [topUpPriceList, setTopUpPriceList] = useState<ITopUpPrice | null>(null);

  const isPending = topUpInfo.state === TopUpStatus.Pending;
  const isProcessing = topUpInfo.state === TopUpStatus.Processing;

  useEffect(() => {
    if (isPending || isProcessing) {
      dispatch(getTopUpPriceList(topUpInfo.id))
        .unwrap()
        .then((res) => setTopUpPriceList(res));
    }
  }, [isPending, isProcessing]);

  const getTransition = (transition: string) => {
    return topUpInfo.enabled_transitions.includes(transition);
  };

  if (isPending && getTransition(TopUpTransitions.Request)) {
    return <TopUpCalculator topUpInfo={topUpInfo} topUpPriceList={topUpPriceList} />;
  }

  if (isProcessing && getTransition(TopUpTransitions.Confirm)) {
    return (
      <TopUpConfirmation
        topUpInfo={topUpInfo}
        userPhone={userPhone}
        canResendCode={getTransition(TopUpTransitions.ResendConfirmationCode)}
        topUpPriceList={topUpPriceList}
      />
    );
  }

  return <TopUpSuccessfulStatus handleCloseTopUp={handleCloseTopUp} />;
};
