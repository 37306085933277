import { Trans, useTranslation } from 'react-i18next';
import { ModalLayout } from 'core/components/common';
import { SubmitCoreHandler } from 'core/models';
import { formatNumber } from 'core/utils/parsers';
import { ConfirmPhoneSection } from '../../../Otp';
import { Step2ConfirmPhoneWithSMSForm } from 'pages/Registration';
import { OTPLoginAction } from 'store/actions';
import { PhoneConfirmFormFields } from 'models';
import { useAppDispatch } from 'hooks';
import { WHATSAPP_PHONE } from 'constantsLk';

interface PhoneConfirmModalProps {
  supportPhone: string;
  isSentOtp: boolean;
  userPhone?: string;
  handleSelectNumberModalOpen: () => void;
}
export const MatchedPhoneConfirmModal = (props: PhoneConfirmModalProps) => {
  const { supportPhone, isSentOtp, userPhone, handleSelectNumberModalOpen } = props;

  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const onSubmit: SubmitCoreHandler<Step2ConfirmPhoneWithSMSForm> = (values, { setError }) => {
    if (userPhone) {
      const username = userPhone?.replace(/\s/g, '');
      const data = {
        username,
        password: values[PhoneConfirmFormFields.Code],
        isOldUser: true,
      };
      return dispatch(OTPLoginAction(data))
        .unwrap()
        .then(() => handleSelectNumberModalOpen())
        .catch((e) => {
          setError?.(PhoneConfirmFormFields.Code, {
            type: 'validate',
            message: e?.message,
          });
        });
    }
  };

  return (
    <ModalLayout
      isShowClose={false}
      isOpenModal
      title={t`phone_confirmation`}
      classes={{ wrapper: 'modal-core__wrapper--user-matched' }}
      isUsePortal
      showAppHeader
      content={
        isSentOtp ? (
          <>
            <p className='txt mb-5'>{t`we_sent_confirmation_code`}</p>
            <ConfirmPhoneSection onSubmit={onSubmit} phone={userPhone} />
          </>
        ) : (
          <p className='txt'>
            <Trans
              i18nKey='please_contact_call_center'
              values={{
                support_service_phone: formatNumber(supportPhone),
              }}
              components={{
                a: <a href={`https://wa.me/${WHATSAPP_PHONE}`} target='_blank' rel='noreferrer' />,
              }}
            />
          </p>
        )
      }
    />
  );
};
