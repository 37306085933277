import { AppNavLinkContainer } from 'core/components/common';
import cn from 'classnames';

import { useTypedSelector } from 'core/hooks';
import { isAppApprovedApplicationSelector } from 'store/selectors';
import { User } from 'models';
import { UserIcon } from '../icons';
import * as Paths from 'constantsLk/paths';

interface UserNameProps {
  user: User | null;
}

export const UserName = (props: UserNameProps) => {
  const { user } = props;

  const username = user && `${user.first_name} ${user.last_name}`;
  const isAppApprovedApplication = useTypedSelector(isAppApprovedApplicationSelector);

  return (
    <AppNavLinkContainer
      className={cn(isAppApprovedApplication ? 'btn-user--disabled' : 'btn-user')}
      to={Paths.URL_PERSONAL_PAGE}
      replace={true}
      isDisable={isAppApprovedApplication}
    >
      <>
        <UserIcon />
        <span className='txt-ellipsis'>{username && username.substring(0, 25)}</span>
      </>
    </AppNavLinkContainer>
  );
};
