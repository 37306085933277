import { createSlice } from '@reduxjs/toolkit';
import { getPaymentBanks } from '../actions/api';
import { PaymentBankModel } from 'models';

interface InitialPaymentBanksState {
    paymentBanks: PaymentBankModel[];
  }

const initialState: InitialPaymentBanksState = {
  paymentBanks: [],
  };

export const dictionarySlice = createSlice({
    name: 'dictionarySlice',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
      builder.addCase(getPaymentBanks.fulfilled, (state, action) => {
          state.paymentBanks = action.payload;
        })
    },
  });
