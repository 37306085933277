import { createAction, createAsyncThunk } from '@reduxjs/toolkit';

import {
  getOldAccessToken,
  getOldRefreshToken,
  getOldUserID,
  getUserID,
  setAccessToken,
  setCookie,
  setRefreshToken,
  setUserID,
} from 'core/services';

import {
  createUser,
  getDataBySlug,
  getPaymentHistory,
  getUserApplications,
  getUserInfo,
  getUserLoans,
  postSelectUserPhoneResolveAccount,
  postUserResolveMatch,
} from './api';
import { RootState } from 'store';
import { getLoanBySlugAction } from './loanActions';
import { Actions, AuthRespSlug, AuthRespUser, CreateUserValues, Loan, User } from 'models';
import { APPLICATION_STATE, KEY_STORAGE_FROM_SHORT_LINK, USER_STATE } from 'constantsLk';

export const signIn = createAsyncThunk<User, boolean | undefined, { state: RootState }>(
  Actions.SIGN_IN,
  async (isSetInitValueFromUser, { dispatch, rejectWithValue }) => {
    try {
      const user = await dispatch(getAllUserData(isSetInitValueFromUser)).unwrap();
      return user;
    } catch (errors) {
      dispatch(setAuthorized(false));
      return rejectWithValue(errors);
    }
  },
);

export const getAllUserData = createAsyncThunk<User, boolean | undefined, { state: RootState }>(
  Actions.GET_ALL_USER_DATA,
  async (isSetInitValueFromUser, { dispatch, getState, rejectWithValue }) => {
    try {
      const user = await dispatch(getUserInfo()).unwrap();
      if (user.state === USER_STATE.ACTIVE) {
        await dispatch(getUserApplications()).unwrap();
        const state = getState();
        const activeApplication = state.applicationReducer.activeApplication;
        if (activeApplication?.state !== APPLICATION_STATE.PROCESSING) {
          await dispatch(getUserLoans()).unwrap();
          await dispatch(getPaymentHistory()).unwrap();
        }
      }
      await dispatch(setAuthorized(true));
      return user;
    } catch (errors) {
      return rejectWithValue(errors);
    }
  },
);

export const createUserAction = createAsyncThunk<AuthRespUser | unknown, CreateUserValues>(
  Actions.CREATE_USER_ACTION,
  async (data, { dispatch, rejectWithValue }) => {
    try {
      const res = await dispatch(createUser(data)).unwrap();
      if (res) {
        const { user, token, refresh_token } = res;
        setRefreshToken(refresh_token);
        setAccessToken(token);
        setUserID(user.id.toString());
        await dispatch(setAuthorized(true));
      }
      return res;
    } catch (errors) {
      return rejectWithValue(errors);
    }
  },
);

export const postUserResolveMatchAction = createAsyncThunk<
  AuthRespUser | unknown,
  { files?: Blob; type?: string } | void
>(Actions.POST_USER_RESOLVE_MATCH_ACTION, async (data, { dispatch, rejectWithValue }) => {
  try {
    const res = await dispatch(postUserResolveMatch(data)).unwrap();
    if (res) {
      const { user, token, refresh_token } = res;
      setRefreshToken(refresh_token);
      setAccessToken(token);
      setUserID(user.id.toString());
      await dispatch(setAuthorized(true));
    }
    return res;
  } catch (errors) {
    return rejectWithValue(errors);
  }
});

export const getDataBySlugAction = createAsyncThunk<
  Loan | AuthRespSlug,
  { slug: string; params: string }
>(Actions.GET_DATA_BY_SLUG_ACTION, async (data, { dispatch, rejectWithValue }) => {
  try {
    const res = await dispatch(getDataBySlug(data)).unwrap();

    if ('registration_step' in res) {
      const { user, token, refresh_token } = res;

      setRefreshToken(refresh_token);
      setAccessToken(token);
      setUserID(user.id.toString());
      await dispatch(setAuthorized(true));
      setCookie(KEY_STORAGE_FROM_SHORT_LINK, '1');
    } else {
      dispatch(getLoanBySlugAction(res));
    }
    return res;
  } catch (errors) {
    return rejectWithValue(errors);
  }
});

export const postSelectUserPhoneResolveMatchAction = createAsyncThunk<
  AuthRespUser | unknown,
  boolean
>(
  Actions.POST_SELECT_USER_PHONE_RESOLVE_MATCH_ACTION,
  async (isOldPhoneNumber, { dispatch, rejectWithValue }) => {
    try {
      const newData = {
        id: isOldPhoneNumber ? getOldUserID() : getUserID(),
        matched_user_id: isOldPhoneNumber ? Number(getUserID()) : Number(getOldUserID()),
      };

      const res = await dispatch(postSelectUserPhoneResolveAccount(newData)).unwrap();

      if (res) {
        setRefreshToken(getOldRefreshToken());
        setAccessToken(getOldAccessToken());
        setUserID(getOldUserID());
      }
      return res;
    } catch (errors) {
      return rejectWithValue(errors);
    }
  },
);

export const setAuthorized = createAction<boolean>('SET_AUTHORIZE');
