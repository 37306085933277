import { getUserID, sendDataLayerError } from 'core/services';
import { findFormNameByField } from './findFormNameByField';

export const onSubmitEventTagHandler = (
  errors: { [key: string]: { [key: string]: any } },
  label: string,
  stage?: string,
) => {
  const UID = getUserID();

  for (const field_name in errors) {
    if (errors[field_name]) {
      const field_error = errors[field_name].message as string;
      if (stage) {
        sendDataLayerError(label, UID, stage, field_name, field_error);
      } else {
        const event = findFormNameByField(field_name);
        if (event) sendDataLayerError(label, UID, event, field_name, field_error);
      }
    }
  }
};
