import { useEffect } from 'react';

import { setRedirect } from 'core/store/actions';
import { getAccessToken, getCookie, getUserID } from 'core/services';

import { useAppDispatch } from 'hooks';
import { LoginLayout } from './LoginLayout';
import { KEY_STORAGE_FROM_SHORT_LINK, URL_USER_REGISTRATION } from 'constantsLk';

export const LoginLayoutContainer = () => {
  const dispatch = useAppDispatch();
  const isFromShortLink = getCookie(KEY_STORAGE_FROM_SHORT_LINK);

  useEffect(() => {
    if (!isFromShortLink && getAccessToken() && getUserID()) {
      dispatch(setRedirect({ to: URL_USER_REGISTRATION, replace: true }));
    }
  }, []);

  return <LoginLayout />;
};
