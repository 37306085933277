import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { SubmitCoreHandler } from 'core/models';
import { useTypedSelector } from 'core/hooks';
import { setRedirect } from 'core/store/actions';
import { getUserID } from 'core/services';
import { useAppDispatch, useUploadFiles } from 'hooks';
import { loadedSelfieWithIdTypeFileSelector } from 'store/selectors';
import { DocumentsType } from 'models';
import { eventVerifiedNICFront, getFileByTypeAction } from 'store/actions/fileUploadActions';
import { uploadFileByType } from 'store/actions/api/fileUploadApi';
import {
  getUserInfo,
  sendSelfieFileId,
  resolveSimplifiedRegistration,
  resetSimplifiedRegistration,
} from 'store/actions/api';
import { dataURItoBlob } from 'utils/dataURItoBlob';
import {
  IUploadPhotoSelfieComponentFormProps,
  UploadPhotoSelfieComponent,
} from 'components/DocumentUploader';
import { Title } from 'components';
import { GrantAccessCameraModal } from 'components/modals';
import { DOCUMENTS, getRegStepPath } from 'constantsLk';

export const Step8_1UploadSelfieContainer = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const [selfieLoadError, setSelfieLoadError] = useState(null);

  const loadedSelfieWithIdTypeFile = useTypedSelector(
    loadedSelfieWithIdTypeFileSelector,
  ) as DocumentsType;

  const { sendFile } = useUploadFiles({
    addFileAPI: uploadFileByType,
    getFileAPI: getFileByTypeAction,
  });

  const onSubmit: SubmitCoreHandler<IUploadPhotoSelfieComponentFormProps> = async (values) => {
    try {
      const data = {
        file: dataURItoBlob(values[DocumentsType.Selfie]),
        type: DocumentsType.Selfie,
      };

      // send file
      const fileResponse = await sendFile(data);

      // check liveness id
      if (values.livenessCheckId) {
        try {
          await dispatch(
            sendSelfieFileId({ livenessCheckId: values.livenessCheckId, data: fileResponse }),
          ).unwrap();
        } catch (e) {
          // eslint-disable-next-line no-console
          console.log(e);
        }
      }

      // simplified registration
      let registrationResponse;
      try {
        registrationResponse = await dispatch(
          resolveSimplifiedRegistration({ userId: getUserID() }),
        ).unwrap();
      } catch (e) {
        // eslint-disable-next-line no-console
        console.log(e);
      }

      // check is_nic_verified
      if (registrationResponse?.success) {
        try {
          await dispatch(eventVerifiedNICFront(3000)).unwrap();
        } catch (e) {
          await dispatch(resetSimplifiedRegistration({ userId: getUserID() }));
        }
      }

      // get user info
      try {
        const userInfo = await dispatch(getUserInfo()).unwrap();
        dispatch(
          setRedirect({
            to: getRegStepPath(userInfo.registration_step),
            replace: true,
          }),
        );
      } catch (e) {
        // eslint-disable-next-line no-console
        console.log(e);
      }
    } catch (e: any) {
      setSelfieLoadError(e);
    }
  };

  return (
    <>
      <div className='flex-column gap-2 mb-5'>
        <Title classes={'form-title mb-0'} name={'selfie_card_upload_title'} />
        <p className='txt-r'>{t`upload_selfie`}</p>
      </div>
      <div className='form'>
        <UploadPhotoSelfieComponent
          onSubmit={onSubmit}
          loadedFileType={loadedSelfieWithIdTypeFile}
          sendFile={sendFile}
          documentFile={DOCUMENTS.SELFIE}
          selfieLoadError={selfieLoadError}
        />
        <GrantAccessCameraModal />
      </div>
    </>
  );
};
