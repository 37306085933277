import axios, { AxiosError, AxiosRequestConfig, InternalAxiosRequestConfig } from 'axios';
import { Dispatch } from '@reduxjs/toolkit';
import { SETTINGS } from 'core/settings';
import * as CookieService from 'core/services/CookieService';
import {
  getI18language,
  getRefreshToken,
  getUserID,
  setAccessToken,
  setRefreshToken,
} from 'core/services/CookieService';
import { setAuthorized, setIsServerError } from 'store/actions';
import { logOutUtil } from 'utils';
import { setIsShortLinkAuth } from 'store/slices';

export interface AxiosRequestConfigExtended extends AxiosRequestConfig {
  isRetryOnce?: boolean;
  responseType?: any;
}

type OriginalAxiosError = AxiosError & {
  config: AxiosRequestConfigExtended;
};

export const $api = axios.create({
  ...SETTINGS.apiService.baseAxiosConfig,
  headers: {
    'Content-Type': 'application/json',
    ...SETTINGS.apiService.baseAxiosConfig?.headers,
  },
});

const requestInterceptorSuccess = async (config: InternalAxiosRequestConfig) => {
  const { data } = config;

  let token = CookieService.getAccessToken();

  if (data?.matched_user_id === Number(getUserID())) {
    token = CookieService.getOldAccessToken();
  }

  if (config.headers && token) {
    // config.headers.Authorization = `Bearer ${token}`;
    config.headers['x-auth-token'] = token;
  }
  return config;
};

const responseInterceptorError = async (error: OriginalAxiosError) => {
  if (
    error.response?.status === 401 &&
    error.config &&
    !error.config.isRetryOnce &&
    SETTINGS.apiService.apiPathRefresh
  ) {
    error.config.isRetryOnce = true;

    try {
      const res = await axios.post(
        `${SETTINGS.apiService.apiPath}${SETTINGS.apiService.apiPathRefresh}`,
        {
          user_id: +getUserID(),
          refresh_token: getRefreshToken(),
        },
      );
      setRefreshToken(res.data.refresh_token);
      setAccessToken(res.data.token);

      return $api.request(error.config);
    } catch (e) {
      return Promise.reject(error);
    }
  }

  return Promise.reject(error);
};

$api.interceptors.request.use(requestInterceptorSuccess);

$api.interceptors.response.use(null, responseInterceptorError);

export const $axios = <T>(config: AxiosRequestConfigExtended, dispatch: Dispatch): Promise<T> =>
  $api
    .request<T>({ ...config, headers: { ...config.headers, 'x-locale': getI18language() } })
    .then((res) => {
      if (res.headers['x-auth-short-link']) {
        dispatch(setIsShortLinkAuth(!!+res.headers['x-auth-short-link']));
      }
      return res.data;
    })
    .catch((error) => {
      // eslint-disable-next-line no-console
      console.log('axioserror', error);
      if (!error.response) return Promise.reject('error_not_found');

      let errors = error.response.data;

      if (error.response.status) {
        errors = { ...errors, status: error.response.status };
      }

      if (error.response.status === 401) {
        dispatch(setAuthorized(false));
        logOutUtil();
      }

      if (error.response.status === 500) {
        dispatch(setIsServerError(true));
      }

      return Promise.reject(errors);
    });
