import { useEffect, useState } from 'react';
import { Trans } from 'react-i18next';
import { useForm } from 'react-hook-form';

import { useTimer } from 'core/hooks';
import { CheckBoxFieldHookForm } from 'core/components/fields';
import { SubmitCoreHandler } from 'core/models';
import { getCookie } from 'core/services';

import { useHookFormSetCache } from 'hooks';
import { ResendCodeRegContainer } from '../ResendCodeContainer';
import { validateCheckBox } from 'utils/validates/validation';
import { PhoneConfirmFormFields } from 'models';
import { KEY_STORAGE_CONFIRM_WITH_CALL, TIMER_WITH_CALL } from 'constantsLk';
import { OtpInput } from 'components/Otp';
import { Step2ConfirmPhoneWithCall } from './Step2ConfirmPhoneWithCall';
import { Confirmation } from '../Step2ConfirmPhoneContainer';
import * as Paths from 'constantsLk/paths';

export interface Step2ConfirmPhoneWithSMSForm {
  [PhoneConfirmFormFields.Code]: string;
  [PhoneConfirmFormFields.AgreementTermsConditions]: boolean;
}

export interface Step2ConfirmPhoneWithSMSProps {
  onOpenModalHandler: (doc: any) => any;
  onSubmit: SubmitCoreHandler<Step2ConfirmPhoneWithSMSForm>;
}

export const Step2ConfirmPhoneWithSMS = (props: Step2ConfirmPhoneWithSMSProps) => {
  const { onSubmit, onOpenModalHandler } = props;
  const isConfirmWithCall = getCookie(KEY_STORAGE_CONFIRM_WITH_CALL);

  const [otp, setOtp] = useState<string | null>(null);
  const [confirmation, setConfirmation] = useState(
    isConfirmWithCall ? Confirmation.PHONE_CONFIRM : Confirmation.SMS_CONFIRM,
  );

  const confirmWayHandler = () => {
    setConfirmation(
      confirmation === Confirmation.PHONE_CONFIRM
        ? Confirmation.SMS_CONFIRM
        : Confirmation.PHONE_CONFIRM,
    );
  };

  const {
    handleSubmit,
    watch,
    control,
    setError,
    setValue,
    clearErrors,
    formState: { isSubmitting, errors },
  } = useForm<Step2ConfirmPhoneWithSMSForm>({
    mode: 'onSubmit',
    shouldFocusError: true,
    shouldUnregister: true,
  });

  useHookFormSetCache(Paths.PATH_REG_STEP_2, watch);

  const agreementTermsConditions = watch(PhoneConfirmFormFields.AgreementTermsConditions);
  const code = watch(PhoneConfirmFormFields.Code);

  const onSubmitHandler = handleSubmit((data) =>
    onSubmit?.(data, {
      setError,
    }),
  );

  const { timerValue, isShowTimer, showTimer, hideTimer } = useTimer({
    timerName: 'Step2ConfirmPhoneWithSMS',
    isUseUnmount: false,
  });

  useEffect(() => {
    otp && setValue(PhoneConfirmFormFields.Code, otp);
  }, [otp]);

  useEffect(() => {
    if (agreementTermsConditions && code?.length === 4) {
      onSubmitHandler().then();
    }
  }, [agreementTermsConditions]);

  useEffect(() => {
    if ('OTPCredential' in window) {
      const ac = new AbortController();
      navigator.credentials
        .get({
          otp: { transport: ['sms'] },
          signal: ac.signal,
        })
        .then((otp) => {
          if (otp) {
            setValue(PhoneConfirmFormFields.Code, otp.code);
            setOtp(otp.code);
            ac.abort();
          }
        })
        .catch((err) => {
          // eslint-disable-next-line no-console
          console.log(err);
          ac.abort();
        });
    }
  }, []);

  return (
    <form
      className='flex-column gap-0 phone-mb-2'
      onSubmit={onSubmitHandler}
      style={{ pointerEvents: `${isSubmitting ? 'none' : 'auto'}` }}
    >
      <OtpInput
        setValue={setValue}
        onSubmitHandler={onSubmitHandler}
        errors={errors}
        clearErrors={clearErrors}
        otp={otp}
        confirmation={confirmation}
      />

      {confirmation === Confirmation.PHONE_CONFIRM ? (
        <div className='mt-5 mb-5'>
          <Step2ConfirmPhoneWithCall
            setConfirmWithSMS={confirmWayHandler}
            showCodeTimer={showTimer}
            hideCodeTimer={hideTimer}
            setError={setError}
          />
        </div>
      ) : (
        <ResendCodeRegContainer
          showTimer={showTimer}
          timerValue={timerValue}
          isShowTimer={isShowTimer}
          isShowCallButton={timerValue.secondsNumber <= TIMER_WITH_CALL}
          setConfirmWithCall={confirmWayHandler}
          hideTimer={hideTimer}
          setError={setError}
          isCodeConfirmation={confirmation === Confirmation.SMS_CONFIRM}
        />
      )}

      <CheckBoxFieldHookForm
        control={control}
        name={PhoneConfirmFormFields.AgreementTermsConditions}
        // id={PhoneConfirmFormFields.AgreementTermsConditions}
        rules={validateCheckBox}
        defaultValue={'true'}
        label={
          <Trans
            i18nKey='is_data_processing_consent_given_com'
            components={{
              a: (
                <span
                  className='ml-1 mr-1 txt-primary cursor-pointer'
                  onClick={onOpenModalHandler}
                />
              ),
            }}
          />
        }
      />
    </form>
  );
};
