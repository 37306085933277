import { useTranslation } from 'react-i18next';
import { SubmitHandler, UseFormReturn } from 'react-hook-form';
import { LoanService } from 'services';
import { ChevronDownIcon, ChevronUpIcon, EditIcon, TagIcon } from 'components/icons';
import { PromoCodeField, PromoCodeFieldModel } from './PromoCodeField';
import { ITEM_STATUS } from 'constantsLk';

export interface PromoCodeFieldEnterButtonProps {
  onToggle?: () => void;
  value?: string | null;
  onConfirm: (
    data: PromoCodeFieldModel,
    form: Pick<UseFormReturn<PromoCodeFieldModel>, 'setError'>,
  ) => ReturnType<SubmitHandler<PromoCodeFieldModel>>;
  formState: string;
  onResetCode?: () => void | Promise<void>;
}

export const PromoCodeFieldEnterButton = (props: PromoCodeFieldEnterButtonProps) => {
  const { onToggle, value, onConfirm, formState, onResetCode } = props;

  const { t } = useTranslation();

  const isAlertExpanded = formState === ITEM_STATUS.ACTIVE;
  const isCodeSent = formState === ITEM_STATUS.SUCCESS;
  const isPromoCode = !!LoanService.promoCode;
  const lblColor = isPromoCode ? 'txt-success' : 'txt';
  const lbl = isPromoCode ? t`promocode_activated` : t`i_have_promocode`;

  return (
    <div className='promo-code flex-column'>
      <div
        onClick={onToggle}
        className='align-items-center w-100 gap-1 justify-content-between cursor-pointer'
      >
        <div className='alerts-icon__wrapper'>
          <TagIcon />
        </div>
        <div className={`flex-grow-1 txt-sb ${lblColor}`}>{lbl}</div>

        {isPromoCode ? (
          <span onClick={onResetCode} className='txt-secondary align-items-center gap-1'>
            <EditIcon />
            {t`change`}
          </span>
        ) : isAlertExpanded ? (
          <div>
            <ChevronUpIcon />
          </div>
        ) : (
          <div>
            <ChevronDownIcon />
          </div>
        )}
      </div>
      {!isCodeSent && isAlertExpanded && <PromoCodeField value={value} onConfirm={onConfirm} />}
    </div>
  );
};
