import { useController } from 'react-hook-form';

import { CheckBoxField, CheckBoxFieldProps } from './CheckBoxField';
import { UseControllerCoreProps } from 'core/models';

export type CheckBoxFieldHookFormProps = CheckBoxFieldProps & UseControllerCoreProps;

export const CheckBoxFieldHookForm = (props: CheckBoxFieldHookFormProps) => {
  const { name, control, rules, defaultValue, ...rest } = props;

  const {
    field: { onChange, value },
    fieldState: { error },
  } = useController({
    name,
    control,
    rules,
    defaultValue: !!defaultValue || false,
  });

  const handleChange = (name: string, value: boolean) => {
    onChange(value);
  };

  return (
    <CheckBoxField
      error={error?.message}
      onChange={handleChange}
      checked={!!value}
      name={name}
      {...rest}
    />
  );
};
