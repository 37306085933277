import {
  AdditionalDataFields,
  AddressFormFields,
  IbanNumberFields,
  MartialRelativesFields,
  PersonalDataFormFields,
  step1FormFields,
} from 'models';

export const enum EventName {
  phone_number = 'phone_number',
  personal_data = 'personal_data',
  residence_address = 'residence_address',
  additional_detail = 'additional_detail',
  marital_status = 'marital_status',
  bank_card = 'bank_card',
  phone_confirmation = 'phone_confirmation',
  confirmation_conditions = 'confirmation_conditions',
  upload_doc_selfie = 'upload_docs_selfie',
  sign_contract = 'sign_contact',
  view_ab_test = 'view_ab_test',
}

export function findFormNameByField(value: string): string | undefined {
  for (const key in step1FormFields) {
    if (step1FormFields[key as keyof typeof step1FormFields] === value) {
      return EventName.phone_number;
    }
  }

  for (const key in PersonalDataFormFields) {
    if (PersonalDataFormFields[key as keyof typeof PersonalDataFormFields] === value) {
      return EventName.personal_data;
    }
  }

  for (const key in AddressFormFields) {
    if (AddressFormFields[key as keyof typeof AddressFormFields] === value) {
      return EventName.residence_address;
    }
  }

  for (const key in AdditionalDataFields) {
    if (AdditionalDataFields[key as keyof typeof AdditionalDataFields] === value) {
      return EventName.additional_detail;
    }
  }

  for (const key in MartialRelativesFields) {
    if (MartialRelativesFields[key as keyof typeof MartialRelativesFields] === value) {
      return EventName.marital_status;
    }
  }

  for (const key in IbanNumberFields) {
    if (IbanNumberFields[key as keyof typeof IbanNumberFields] === value) {
      return EventName.bank_card;
    }
  }

  return undefined;
}
