import { useTypedSelector } from 'core/hooks';
import { EmailAlert } from 'components';
import { isEmailVerifiedSelector, userSelector } from 'store/selectors';

export const EmailAlertWrapper = ({ children }: { children: JSX.Element | null }) => {
  const user = useTypedSelector(userSelector);
  const isEmailVerified = useTypedSelector(isEmailVerifiedSelector);

  const isOpenAlert = user?.email && !user?.is_email_verified && !isEmailVerified;

  const returnEmailAlert = (additionalClasses: string) => (
    <div className={`max-w-54 ${additionalClasses}`}>
      <EmailAlert
        isEmailVerificationSent={user?.is_email_verification_sent}
        userEmail={user?.email}
      />
    </div>
  );

  if (!isOpenAlert) return children;

  return (
    <div className='email-alert__wrapper flex-column gap-2 gap-sm-4 m-auto mt-0'>
      {isOpenAlert && returnEmailAlert('hidden-tablet')}

      {children}

      {isOpenAlert && returnEmailAlert('hidden-sm-tablet mb-8')}
    </div>
  );
};
