export enum WpKeys {
  LoanTerm = 'loan_term',
  LoanSum = 'loan_sum',
  LoanPromoCode = 'loan_promocode',
  AccessToken = 'access_token',
  OldUserAccessToken = 'old_user_access_token',
  RefreshToken = 'refresh_token',
  OldUserRefreshToken = 'old_user_refresh_token',
  TargetUrl = 'target_url',
  CurrentLanguage = 'current_language',
  DevApiUrl = 'dev_api_url',
}

export interface WpContactsHeader {
  schedule: string;
  phone: string;
  phone_text: string;
}

export interface WpContactsFooter extends WpContactsHeader {
  email: string;
}

export interface WpMenu {
  title: string;
  url: string;
}

export interface WpSocial {
  icon: string;
  url: string;
}

export interface WpModalInfo {
  title: string;
  content: string;
}

export interface WpDocument {
  title: string;
  content: string;
  url: string;
}

export interface FooterDoc {
  title: string;
  text_content: string;
}

export interface WpContent {
  header: {
    contacts: WpContactsHeader;
    menu?: WpMenu[];
    // mobile_menu?: WpMenu[];
    // mobile_menu_title?: string;
    // stocks_count?: number;
  };
  footer: {
    contacts: WpContactsFooter;
    // contacts_2?: WpContactsFooter;
    btn_text?: string;
    btn_link?: string;
    menu_title?: string;
    menu?: WpMenu[];
    menu_title_2?: string;
    menu2?: WpMenu[];
    btn_more?: string;
    btn_less?: string;
    socials_title?: string;
    socials?: WpSocial[];
    apps_title?: string;
    apps_description?: string;
    apps_links?: WpSocial[];
    license?: string;
    note?: string;
    copyright?: string;
    documents: FooterDoc[];
    modal_info?: WpModalInfo[];
  };
}
