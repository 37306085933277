import { PromotionModel } from 'models';
import { useTranslation } from 'react-i18next';
import { Timer } from './Timer';
import { PromotionIcon, PromotionTimerIcon } from 'components';
import { usePromotion } from 'hooks';
import { CURRENCY } from 'constantsLk';

interface PromotionProps {
  activePromo: PromotionModel;
  paymentLinkUri: string;
}
export const Promotion = ({ activePromo, paymentLinkUri }: PromotionProps) => {
  const { t } = useTranslation();

  const { total_max_discount_amount } = activePromo.calculation;
  const agreedPaymentAmount = activePromo.agreed_payment_amount;

  const { promotionTranslate, timeLeft, promoStatus, isBlinking, formattedDate } = usePromotion(
    activePromo.available_to,
    activePromo,
  );

  return (
    <div className='card flex-column tablet-mb-6 mb-4 promotion'>
      <div className='promotion-label'>{t('promo')}</div>
      <div className='promotion-title flex align-items-center justify-content-center w-100 gap-2'>
        <div className='promotion-icon'>
          <PromotionIcon />
        </div>
        <h2 className='txt-md txt-b'>{promotionTranslate.header}</h2>
      </div>

      {activePromo.available_to && (
        <div
          className={`flex-center flex-column pill-${promoStatus} mt-4 mb-4 pt-2 pb-2 ${isBlinking ? 'blinking' : ''}`}
        >
          <span className='flex-center txt-md-14 txt-sb gap-2'>
            <PromotionTimerIcon /> {t`offer_is_available`}
          </span>
          <Timer timeLeft={timeLeft} />
        </div>
      )}

      <p className='txt-md txt-sb mb-2'>{promotionTranslate.description}</p>
      <div className='txt txt-secondary'>{`${t`offer_is_valid_till`} ${formattedDate}`}</div>

      <div className='flex-column gap-3 mt-3'>
        <div className='border-top-light lined-card-row'>
          <span className='txt-xs txt-sb'>{t('total_repayment')}</span>
          <span className='info-item crossed'>
            {total_max_discount_amount + agreedPaymentAmount} {CURRENCY}
          </span>
        </div>

        <div className='border-top-light lined-card-row'>
          <span className='txt-xs txt-sb'>{t('repayment_with_discount')}</span>
          <span className='info-item'>
            {agreedPaymentAmount} {CURRENCY}
          </span>
        </div>
      </div>

      <a
        className='btn btn--primary mt-4 w-100'
        href={`${paymentLinkUri}/${agreedPaymentAmount}`}
        target='_blank'
        rel='noreferrer'
      >
        <span>
          {t('repay_online_discount')} {agreedPaymentAmount} {CURRENCY}
        </span>
      </a>
    </div>
  );
};
