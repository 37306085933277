const KEY_S_STORAGE = '__LK_2';

export const KEY_STORAGE_USER_ID = `${KEY_S_STORAGE}user_id`;
export const KEY_STORAGE_OLD_USER_ID = `${KEY_S_STORAGE}old_user_id`;

export const KEY_STORAGE_HYPER_SNAP_TOKEN = `${KEY_S_STORAGE}HYPER_SNAP_TOKEN`;

export const KEY_STORAGE_WRITE_TARGET_URL = 'target_url_write';

export const KEY_STORAGE_FROM_SHORT_LINK = 'from_short_link';

export const KEY_STORAGE_CONFIRM_WITH_CALL = 'confirm_with_a_call';
export const KEY_STORAGE_CALL_IS_PROCESSING = 'call_is_processing';
export const KEY_REPEAT_USER = 'repeat_user';
export const KEY_CONFIRM_BANK_DETAILS = 'confirm_bank_details';
export const KEY_CAMERA_REQUEST = 'camera_request';
export const KEY_BANK_ACCOUNT_DETAILS = 'bank_account_details';
export const KEY_TEST_VERIFICATION_VARIANT = 'test_verification_variant';
export const KEY_CLOSE_EMAIL_MODAL = 'close_email_modal';
export const KEY_IMPERSONATE_AUTH = 'impersonate_auth';
export const KEY_OPENED_PARTNER_LINK = 'opened_partner_link';
export const KEY_NOTIFICATION_DATA = 'notification_data';
export const KEY_CAPTCHA_IGNORE = 'captcha_ignore';
