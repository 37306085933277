import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { getAccessToken, getUserID } from 'core/services';
import { setRedirect } from 'core/store/actions';
import { useTypedSelector } from 'core/hooks';
import { RegistrationLayout } from './RegistrationLayout';
import { useAppDispatch } from 'hooks';
import { Spinner } from 'components';
import { signIn } from 'store/actions';
import { livenessEngineSelector } from 'store/selectors';
import { getLivenessDetection } from 'store/actions/api';
import { setLivenessValue } from 'store/slices';
import { store } from 'store';
import { checkPathForLivenessInit, initLivenessSDK, lastKeyOfSteps } from 'utils';
import * as Paths from 'constantsLk/paths';
import { getRegStepPath, LIVENESS, LIVENESS_DETECTIONS, USER_STATE } from 'constantsLk';

export const RegistrationLayoutContainer = () => {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const navigate = useNavigate();

  const [isPending, setIsPending] = useState<boolean>(true);
  const livenessEngine = useTypedSelector(livenessEngineSelector);

  const noGoBack = () => navigate(1);

  const updateUserRegistration = () => {
    if (getAccessToken() && getUserID()) {
      return dispatch(signIn())
        .unwrap()
        .then((user) => {
          if (user.state === USER_STATE.ACTIVE) {
            const activeApplication = store.getState().applicationReducer.activeApplication;

            if (
              user.registration_step < lastKeyOfSteps ||
              (user.registration_step === lastKeyOfSteps && !activeApplication)
            ) {
              dispatch(setRedirect({ to: getRegStepPath(user.registration_step), replace: true }));
            } else {
              dispatch(setRedirect({ to: Paths.URL_ACTIVE_LOAN, replace: true }));
            }
          } else if (!user.registration_step || user.registration_step === 1) {
            dispatch(setRedirect({ to: getRegStepPath(2), replace: true }));
          } else {
            dispatch(setRedirect({ to: getRegStepPath(user.registration_step), replace: true }));
          }
          setIsPending(false);
        })
        .catch(() => {
          dispatch(setRedirect({ to: getRegStepPath(1), replace: true }));
          setIsPending(false);
        });
    } else {
      dispatch(setRedirect({ to: getRegStepPath(1), replace: true }));
      setIsPending(false);
    }
  };

  useEffect(() => {
    updateUserRegistration();
  }, []);

  useEffect(() => {
    if (checkPathForLivenessInit(location.pathname) && !livenessEngine) {
      dispatch(getLivenessDetection())
        .unwrap()
        .then((res) => {
          const livenessValue = LIVENESS_DETECTIONS[res.value - 1];
          dispatch(setLivenessValue(livenessValue));
          initLivenessSDK(livenessValue);
        })
        .catch(() => {
          dispatch(setLivenessValue(LIVENESS.OZFORENCIS));
          initLivenessSDK(LIVENESS.OZFORENCIS);
        });
    }
  }, [location.pathname]);

  useEffect(() => {
    window.addEventListener('popstate', noGoBack);

    return () => {
      window.removeEventListener('popstate', noGoBack);
    };
  }, []);

  useEffect(() => {
    if (window.scrollY > 0) {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }
  }, [location.pathname]);

  if (isPending) return <Spinner />;

  return <RegistrationLayout />;
};
