import { useState } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { ModalLayout } from 'core/components/common';
import { setRedirect } from 'core/store/actions';
import { ErrorIcon } from '../../icons';
import { sendOTPassword } from 'store/actions/api';
import { useAppDispatch } from 'hooks';
import { AuthFormFields } from '../../AuthForm';
import { formattingPhone } from 'utils/functions';
import * as Paths from 'constantsLk/paths';

interface ArchiveUserModalProps {
  phoneNumber: string;
  resetOldUserPhone: () => void;
}

export const ArchiveUserModal = ({ phoneNumber, resetOldUserPhone }: ArchiveUserModalProps) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const [isOpenModal, setIsOpenModal] = useState(true);

  const onClose = () => {
    setIsOpenModal(false);
    resetOldUserPhone();
  };

  const handleClick = () => {
    return dispatch(sendOTPassword({ [AuthFormFields.Phone]: phoneNumber }))
      .unwrap()
      .then(() => {
        onClose();
        dispatch(
          setRedirect({
            to: `/${Paths.URL_PATH_LOGIN}`,
            replace: true,
            state: { phone: phoneNumber, isOpenModal: false },
          }),
        );
      });
  };

  return (
    <ModalLayout
      isOpenModal={isOpenModal}
      onClose={onClose}
      title={<ErrorIcon />}
      classes={{ wrapper: 'modal-core__wrapper--email-verified' }}
      isShowClose
      isUsePortal
      content={
        <div className='flex-column gap-2'>
          <p className='txt-md txt-sb mt-3'>{t`number_is_registered`}</p>
          <p>
            <Trans
              i18nKey={'number_is_registered_text'}
              values={{ phoneNumber: formattingPhone(phoneNumber).trim() }}
              components={{
                a: (
                  <span
                    className='txt-sb txt-primary txt-underline cursor-pointer'
                    onClick={handleClick}
                  />
                ),
              }}
            />
          </p>
        </div>
      }
    />
  );
};
