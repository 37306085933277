import { createSlice } from '@reduxjs/toolkit';

import { Alert, NoticeTypes, RedirectParams, Snack, WpContent } from 'core/models';
import * as CoreActions from 'core/store/actions';

import { wpDataHardCode } from 'constantsLk';

interface InitialCoreState {
  [NoticeTypes.Alerts]: Alert[];
  [NoticeTypes.Snacks]: Snack[];
  progress: string[] | null;
  languagePath: string;
  isLogout: boolean;
  wpContent: WpContent | null;
  redirect: RedirectParams | null;
}

const initialState: InitialCoreState = {
  [NoticeTypes.Alerts]: [],
  [NoticeTypes.Snacks]: [],
  progress: null,
  languagePath: '',
  isLogout: true,
  wpContent: wpDataHardCode,
  redirect: null,
};

export const coreSlice = createSlice({
  name: 'core',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(CoreActions.progressStart, (state, action) => {
        if (state.progress) state.progress.push(action.payload);
        else state.progress = [action.payload];
      })
      .addCase(CoreActions.progressEnd, (state, action) => {
        const ids = state.progress?.filter((id) => id !== action.payload);
        state.progress = ids?.length ? ids : null;
      })
      .addCase(CoreActions.setAlert.fulfilled, (state, action) => {
        action.payload && state.alerts.push(action.payload);
      })
      .addCase(CoreActions.removeAlert, (state, action) => {
        state.alerts = state.alerts.filter((alert) => alert.id !== action.payload);
      })
      .addCase(CoreActions.setSnack.fulfilled, (state, action) => {
        action.payload && state.snacks.push(action.payload);
      })
      .addCase(CoreActions.removeSnack, (state, action) => {
        state.snacks = state.snacks.filter((snack) => snack.id !== action.payload);
      })
      .addCase(CoreActions.setLanguagePath, (state, action) => {
        state.languagePath = action.payload;
      })
      .addCase(CoreActions.logOut, (state, action) => {
        state.isLogout = action.payload;
      })
      .addCase(CoreActions.getWpLayout.fulfilled, (state, action) => {
        state.wpContent = action.payload;
      })
      .addCase(CoreActions.setRedirect.fulfilled, (state, action) => {
        state.redirect = action.payload;
      })
      .addCase(CoreActions.removeRedirect, (state) => {
        state.redirect = null;
      });
  },
});
