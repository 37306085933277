export interface IErrorsHandlerCallback {
  (e: any): void;
}

export interface IFormError {
  code?: string;
  propertyPath: string;
  message?: string;
}

export const ServerFieldErrorsHandler = (
  e: object = {},
  setError: any,
  callback?: IErrorsHandlerCallback,
) => {
  if ('violations' in e) {
    const arrayOfErrors = e.violations as Array<IFormError>;

    arrayOfErrors.forEach((fieldError) => {
      if (fieldError?.propertyPath && fieldError?.message) {
        setError(fieldError.propertyPath, {
          type: 'validate',
          message: fieldError?.message,
        });
      }
    });
  }
  if (callback) callback(e);

  return null;
};