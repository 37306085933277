import i18next, { InitOptions, Resource, TFunction } from 'i18next';
import ICU from 'i18next-icu';
import { initReactI18next } from 'react-i18next';
import backend from 'i18next-http-backend';
import languageDetector from 'i18next-browser-languagedetector';
import { SETTINGS } from 'core/settings';
import { WpKeys } from 'core/models';
import { getStorageLanguage } from 'utils/functions';

interface InitialLocalizationOptions extends InitOptions {
  resources: Resource;
}

type InitialLocalizationHandler = (data: InitialLocalizationOptions) => Promise<TFunction>;

export const initialLocalization: InitialLocalizationHandler = (data) =>
  i18next
    .use(backend)
    .use(languageDetector)
    .use(initReactI18next)
    .use(ICU)
    .init({
      ns: ['translations'],
      defaultNS: 'translations',
      react: {
        useSuspense: false,
      },
      lng: getStorageLanguage(),
      fallbackLng: SETTINGS.defaultLanguage,
      detection: {
        caches: ['cookie'],
        lookupCookie: WpKeys.CurrentLanguage,
        cookieOptions: {
          path: '/',
          domain: `.${location.hostname}`,
          sameSite: 'strict',
          secure: true,
          expires: new Date(new Date().setDate(new Date().getDate() + 30)),
        },
      },
      ...data,
    });
