import { createSelector } from '@reduxjs/toolkit';
import { differenceInDays, isAfter, startOfToday } from 'date-fns';
import { RootState } from 'store/store';
import { DocumentsType } from 'models';
import { APPLICATION_STATE, LOAN_STATE, TODAY, USER_STATE } from 'constantsLk';

export const isAuthorizedSelector = (state: RootState) => state.authReducer.isAuthorized;

export const userSelector = (state: RootState) => state.userReducer?.user;
export const userBankDetailsSelector = (state: RootState) => state.userReducer?.bankAccountDetails;
export const userShortLinkAuthSelector = (state: RootState) => state.userReducer?.isShortLinkAuth;
export const userErrorCameraSelector = (state: RootState) => state.userReducer?.isErrorCamera;
export const userClickedItsClearButtonSelector = (state: RootState) =>
  state.userReducer?.isClickedItsClearButton;
export const isDataConsentGivenSelector = (state: RootState) =>
  !state.userReducer?.user?.is_data_processing_consent_given &&
  state.userReducer?.user?.state === USER_STATE.CONFIRMED;

export const livenessEngineSelector = (state: RootState) => state.userReducer?.liveness;

export const uploadedFilesSelector = (state: RootState) => state.userReducer?.user?.uploaded_files;
export const usernamePhoneSelector = (state: RootState) => state.userReducer?.user?.username;
export const isEmailVerifiedSelector = (state: RootState) =>
  state.userReducer?.isEmailSuccessfullyVerified;
export const authPhoneSelector = (state: RootState) => state.authReducer?.username;
export const isShowPartnerAuthSelector = (state: RootState) => state.authReducer.isShowPartner;
export const wpContentFooterSelector = (state: RootState) => state.coreReducer.wpContent?.footer;
export const wpContentHeaderSelector = (state: RootState) => state.coreReducer.wpContent?.header;
export const isServerErrorSelector = (state: RootState) => state.layoutReducer.isServerError;
export const getRedirectSelector = (state: RootState) => state.coreReducer.redirect;
//application
export const activeApplicationSelector = (state: RootState) =>
  state.applicationReducer.activeApplication;

export const isApplicationPendingSelector = createSelector(
  activeApplicationSelector,
  (app) => app?.state === APPLICATION_STATE.PENDING || app?.state === APPLICATION_STATE.PROCESSING,
);

export const isAppApprovedApplicationSelector = createSelector(
  activeApplicationSelector,
  (app) => app?.state === APPLICATION_STATE.APPROVED,
);

export const isAppConfirmedApplicationSelector = createSelector(
  activeApplicationSelector,
  (app) => app?.state === APPLICATION_STATE.CONFIRMED,
);

//loans
export const activeLoanSelector = (state: RootState) => state.loanReducer.activeLoan;
export const shortLinkLoanSelector = (state: RootState) => state.loanReducer.shortLinkLoan;

export const isShowPartnerSelector = (state: RootState) => state.loanReducer.isShowPartner;

export const paymentBanksSelector = (state: RootState) => state.dictionary.paymentBanks;

export const loansSelector = (state: RootState) => state.loanReducer.loans;

export const incomesSelector = (state: RootState) => state.loanReducer.incomes;
export const isSuccessfullyPaymentSelector = (state: RootState) =>
  state.loanReducer.isSuccessfullyPayment;

export const isFirstLoanSelector = createSelector(loansSelector, (loans) => {
  if (!loans) return true;
  return loans?.every((loan) => loan.state !== LOAN_STATE.CLOSED);
});

export const isLoanActiveSelector = createSelector(
  activeLoanSelector,
  (loan) => loan?.state === LOAN_STATE.ACTIVE || loan?.state === LOAN_STATE.DEFAULTED,
);

export const isSelectedPrincipalSelector = createSelector(
  activeApplicationSelector,
  (loan) => !!loan?.selected_principal,
);

export const isShortLinkLoanActiveSelector = createSelector(
  shortLinkLoanSelector,
  (loan) => loan?.state === LOAN_STATE.ACTIVE || loan?.state === LOAN_STATE.DEFAULTED,
);

export const isLoanPendingSelector = createSelector(
  activeLoanSelector,
  (loan) => loan?.state === LOAN_STATE.PENDING,
);

export const isCanProlongSelector = createSelector(
  activeLoanSelector,
  (loan) => loan?.state !== LOAN_STATE.DEFAULTED && loan?.state !== LOAN_STATE.SOLD,
);

export const isLoanSoldSelector = createSelector(
  activeLoanSelector,
  (loan) => loan?.state == LOAN_STATE.SOLD,
);

export const defaultPricesSelector = (state: RootState) => state.loanReducer.defaultLoanSettings;

//prices
export const pricesSelector = (state: RootState) => state.pricesReducer.prices;
export const productIdSelector = (state: RootState) => state.pricesReducer.productId;

export const loadedSelfieWithIdTypeFileSelector = createSelector(uploadedFilesSelector, (photo) =>
  photo?.find((doc: DocumentsType) => doc === DocumentsType.Selfie),
);
export const loadedIDFrontSelector = createSelector(uploadedFilesSelector, (photo) =>
  photo?.find((doc: DocumentsType) => doc === DocumentsType.IDCard),
);

export const loadedIDBAckSelector = createSelector(uploadedFilesSelector, (photo) =>
  photo?.find((doc: DocumentsType) => doc === DocumentsType.IDCardBackSide),
);

export const isLastAppRejSelector = createSelector(
  userSelector,
  activeApplicationSelector,
  (user, activeApplication) => {
    return activeApplication?.state === APPLICATION_STATE.REJECTED && !user?.moratorium_till;
  },
);

export const isSubheaderShowSelector = createSelector(
  isLastAppRejSelector,
  activeLoanSelector,
  activeApplicationSelector,
  isLoanActiveSelector,
  isFirstLoanSelector,
  isLoanSoldSelector,
  (isLastAppRejected, activeLoan, activeApp, isLoanActive, isFirstLoan, isSoldLoan) => {
    return (
      (activeLoan?.state === LOAN_STATE.CLOSED &&
        activeApp?.state === APPLICATION_STATE.CONFIRMED) ||
      isLastAppRejected ||
      isLoanActive ||
      !isFirstLoan ||
      isSoldLoan
    );
  },
);

export const isApplicationRejWithMoratoriumSelector = createSelector(
  userSelector,
  activeApplicationSelector,
  (user, activeApplication) => {
    if (user) {
      const otherDate = new Date(user.moratorium_till);
      if (isAfter(otherDate, TODAY) && activeApplication?.state === APPLICATION_STATE.REJECTED) {
        return true;
      } else {
        return false;
      }
    } else return false;
  },
);

export const isMoratoriumSelector = createSelector(userSelector, (user) => {
  if (user) {
    const otherDate = new Date(user.moratorium_till);
    return isAfter(otherDate, TODAY);
  } else return false;
});

export const isMoreThenMonthMoratoriumSelector = createSelector(userSelector, (user) => {
  if (user) {
    const today = startOfToday(); // Get the start of today's date
    const otherDate = new Date(user.moratorium_till);
    return differenceInDays(otherDate, today) > 30;
  } else return false;
});

export const isInBlackListSelector = createSelector(
  userSelector,
  activeLoanSelector,
  (user, loan) => {
    return user?.is_in_black_list && loan?.state !== LOAN_STATE.ACTIVE;
  },
);

export const isShowCollectionAlertSelector = createSelector(
  isLoanActiveSelector,
  isShortLinkLoanActiveSelector,
  activeLoanSelector,
  shortLinkLoanSelector,
  (isLoanActive, isShortLinkLoanActive, activeLoan, shortLinkLoan) => {
    const loanData = activeLoan?.id ? activeLoan : shortLinkLoan;
    const isActive = activeLoan?.id ? isLoanActive : isShortLinkLoanActive;

    return loanData && isActive;
  },
);
