export const PATH_USER = 'user';
export const PATH_REGISTRATION = 'registration';
export const URL_USER_REGISTRATION = `/${PATH_USER}/registration`;

export const PATH_REG_STEP_1 = 'reg1';
export const PATH_REG_STEP_2 = 'reg2';
export const PATH_REG_STEP_3 = 'reg3';
export const PATH_REG_STEP_4 = 'reg4';
export const PATH_REG_STEP_5 = 'reg5';
export const PATH_REG_STEP_6 = 'reg6';
export const PATH_REG_STEP_7 = 'reg7';
export const PATH_REG_STEP_8_1 = 'reg8_1';
export const PATH_REG_STEP_8_2 = 'reg8_2';
export const PATH_REG_STEP_8_3 = 'reg8_3';
export const PATH_REG_STEP_9_FIRST = 'request-confirm-1new';

export const PATHS_REGISTRATION_STEPS: { [key: string]: string } = {
  1: PATH_REG_STEP_1,
  2: PATH_REG_STEP_2,
  3: PATH_REG_STEP_3,
  4: PATH_REG_STEP_4,
  5: PATH_REG_STEP_5,
  6: PATH_REG_STEP_6,
  7: PATH_REG_STEP_7,
  8.1: PATH_REG_STEP_8_1,
  8.2: PATH_REG_STEP_8_2,
  8.3: PATH_REG_STEP_8_3,
  9: PATH_REG_STEP_9_FIRST,
};

export const PATH_LOGIN = 'login';
export const PATH_LOGOUT = 'logout';

export const URL_PATH_LOGIN = `${PATH_USER}/login`;

export const PATH_ACCOUNT = 'account';
export const PATH_ACTIVE_LOAN = 'loans';
export const PATH_HISTORY_LOAN = 'history';
export const PATH_HISTORY_PAYMENT = 'payments';
export const PATH_ACCOUNT_CARDS = 'cards';
export const PATH_PERSONAL_PAGE = 'personal_data';
export const PATH_REFERRAL_PAGE = 'referral_program';
export const PATH_500 = 'server-error';

export const PATH_SHORT_LINK_LOAN = 'f';
export const PATH_SHORT_LINK_LOAN_RESULT = 'short-link-loan';

export const getAccountPath = (path: string) => `/${PATH_ACCOUNT}/${path}`;

export const URL_ACTIVE_LOAN = getAccountPath(PATH_ACTIVE_LOAN);
export const URL_HISTORY_LOAN = getAccountPath(PATH_HISTORY_LOAN);
export const URL_HISTORY_PAYMENT = getAccountPath(PATH_HISTORY_PAYMENT);
export const URL_ACCOUNT_CARDS = getAccountPath(PATH_ACCOUNT_CARDS);
export const URL_PERSONAL_PAGE = getAccountPath(PATH_PERSONAL_PAGE);
export const URL_REFERRAL_PAGE = getAccountPath(PATH_REFERRAL_PAGE);

export const getRegStepPath = (step: number | string) => {
  return `${URL_USER_REGISTRATION}/${PATHS_REGISTRATION_STEPS[step]}`;
};

export const URL_REG_STEP_1 = getRegStepPath(1);
export const URL_REG_STEP_2 = getRegStepPath(2);
export const URL_REG_STEP_3 = getRegStepPath(3);
export const URL_REG_STEP_4 = getRegStepPath(4);
export const URL_REG_STEP_5 = getRegStepPath(5);
export const URL_REG_STEP_6 = getRegStepPath(6);
export const URL_REG_STEP_7 = getRegStepPath(7);
export const URL_REG_STEP_8_1 = getRegStepPath(8.1);
export const URL_REG_STEP_8_2 = getRegStepPath(8.2);
export const URL_REG_STEP_8_3 = getRegStepPath(8.3);
export const URL_REG_STEP_9 = getRegStepPath(9);
