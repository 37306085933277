import { useTranslation } from 'react-i18next';
import { ModalLayout } from 'core/components/common';
import { SuccessIcon } from '../../icons';

interface SuccessShareModalProps {
  openModalType: 'sms' | 'email' | null;
  handleCloseSuccessModal: () => void;
}

export const SuccessShareModal = (props: SuccessShareModalProps) => {
  const { openModalType, handleCloseSuccessModal } = props;

  const { t } = useTranslation();

  return (
    <ModalLayout
      isOpenModal
      onClose={handleCloseSuccessModal}
      title={<SuccessIcon />}
      classes={{ wrapper: 'modal-core__wrapper--email-verified' }}
      isShowClose
      isUsePortal
      content={
        <div className='flex-column gap-2 mt-3'>
          <p className='txt-md txt-sb'>{t`invitation_sent`}</p>
          <p className='txt'>
            {t(`${openModalType === 'email' ? 'invitation_sent_email' : 'invitation_sent_sms'}`)}
          </p>
        </div>
      }
    />
  );
};
