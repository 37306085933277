import React, { FC } from 'react';
import Cookies from 'js-cookie';

import { getAccessToken, getUserID, removeCookie, setCookie } from 'core/services';
import { SETTINGS } from 'core/settings';
import { AppNavLinkContainer } from 'core/components/common';

import {
  DEVELOP_DISABLE_STEPPER,
  DEVELOP_INFO,
  getDevelopmentInfo,
  getStepperDisable,
  setDevelopmentInfo,
  setStepperDisable,
} from './utils/cokisesUtil';
import './style.scss';
import {
  PATH_LOGOUT,
  URL_ACCOUNT_CARDS,
  URL_ACTIVE_LOAN,
  URL_HISTORY_LOAN,
  URL_HISTORY_PAYMENT,
  URL_PATH_LOGIN,
  URL_PERSONAL_PAGE,
  URL_REG_STEP_1,
  URL_REG_STEP_2,
  URL_REG_STEP_3,
  URL_REG_STEP_4,
  URL_REG_STEP_5,
  URL_REG_STEP_6,
  URL_REG_STEP_7,
  URL_REG_STEP_8_1,
  URL_REG_STEP_8_2,
  URL_REG_STEP_8_3,
  URL_REG_STEP_9,
} from 'constantsLk';
import { CloseIcon, LogoutIcon, ProfileIcon } from './icons';
import { useTypedSelector } from 'core/hooks';
import { activeApplicationSelector, activeLoanSelector } from 'store/selectors';
import { logOutUtil } from 'utils/logout';
import { DocumentsType } from 'models';
import { uploadFileByType } from 'store/actions/api/fileUploadApi';
import { signIn } from 'store/actions';
import { useAppDispatch } from 'hooks';
import defImg from 'assets/images/logo.png';

const PATHS_FOR_DEV = [
  URL_PATH_LOGIN,
  PATH_LOGOUT,
  URL_REG_STEP_1,
  URL_REG_STEP_2,
  URL_REG_STEP_3,
  URL_REG_STEP_4,
  URL_REG_STEP_5,
  URL_REG_STEP_6,
  URL_REG_STEP_7,
  URL_REG_STEP_8_1,
  URL_REG_STEP_8_2,
  URL_REG_STEP_8_3,
  URL_REG_STEP_9,
  URL_ACTIVE_LOAN,
  URL_HISTORY_LOAN,
  URL_HISTORY_PAYMENT,
  URL_ACCOUNT_CARDS,
  URL_PERSONAL_PAGE,
  '/forms',
];

export async function copyTokenToClipboard() {
  const token = getAccessToken();
  if ('clipboard' in navigator) {
    return await navigator.clipboard.writeText(token);
  } else {
    return document.execCommand('copy', true, token);
  }
}

export async function copyCNPToClipboard() {
  // const cnp = generateCNP();
  const cnp = 'generateCNP()';
  if ('clipboard' in navigator) {
    return await navigator.clipboard.writeText(cnp);
  } else {
    return document.execCommand('copy', true, cnp);
  }
}

export const DevelopmentInfo: FC = () => {
  const activeApplication = useTypedSelector(activeApplicationSelector);
  const loan = useTypedSelector(activeLoanSelector);
  const dispatch = useAppDispatch();
  const [isOpen, setIsOpen] = React.useState(!getDevelopmentInfo());
  const [showPaths, setShowPaths] = React.useState(false);
  const [url, setUrlVal] = React.useState('');
  const [isDisabledStepper, setIsDisabledStepper] = React.useState(getStepperDisable());
  const stepperDisablerHandler = (e: any) => {
    setIsDisabledStepper(e.target.checked);
    if (e.target.checked) setStepperDisable('true');
    else removeCookie(DEVELOP_DISABLE_STEPPER);
    location.reload();
  };

  const logOutHandler = () => {
    logOutUtil();
    window.location.href = '/';
  };

  const onOpen = () => {
    removeCookie(DEVELOP_INFO);
    setIsOpen(true);
  };

  const onClose = () => {
    setDevelopmentInfo('closed');
    setIsOpen(false);
  };
  const showPathsHandler = () => {
    setShowPaths((prev) => !prev);
  };

  const baseUrlHandler = (e: any) => {
    setUrlVal(e.target.value);
  };

  const apiHandler = () => {
    setCookie('dev_api_url', url, { expires: 7 });
    location.reload();
  };

  const resetApiHandler = () => {
    Cookies.remove('dev_api_url');
    location.reload();
  };

  const devSkipHandler = () => {
    const binary = atob(defImg.split(',')[1]),
      array = [];
    for (let i = 0; i < binary.length; i++) array.push(binary.charCodeAt(i));
    const blob = new Blob([new Uint8Array(array)], { type: 'image/png' });

    // const fileID_CARD = new File([blob], DocumentsType.IDCard, { type: 'image/png' });
    // const fileSELFIE_WITH_ID_CARD = new File([blob], DocumentsType.Selfie, {
    //   type: 'image/png',
    // });

    dispatch(uploadFileByType({ files: blob, type: DocumentsType.IDCard })).then(() => {
      dispatch(uploadFileByType({ files: blob, type: DocumentsType.Selfie })).then(() => {
        dispatch(uploadFileByType({ files: blob, type: DocumentsType.IDCardBackSide })).then(() => {
          dispatch(signIn());
        });
      });
    });
  };

  return isOpen ? (
    <div className='development-info flex-col'>
      <div className='development-info__links'>
        <LogoutIcon onClick={logOutHandler} className='development-info__icon fill' />
        <ProfileIcon onClick={showPathsHandler} className='development-info__icon fill' />
        <span>{SETTINGS.apiService.baseAxiosConfig.baseURL}</span>

        <CloseIcon
          className='development-info__icon development-info__close stroke'
          onClick={onClose}
        />
      </div>

      {showPaths && (
        <div className='paths'>
          <span className='base-url__wrapper'>
            <input
              className='base-url-input'
              defaultValue={SETTINGS.apiService.baseAxiosConfig.baseURL}
              onChange={baseUrlHandler}
            />
            <button style={{ background: '#d7c906' }} onClick={apiHandler}>
              set
            </button>
            <button style={{ background: 'pink' }} onClick={resetApiHandler}>
              reset
            </button>
          </span>
          <a
            href={`
            ${SETTINGS.apiService.baseAxiosConfig.baseURL?.slice(
              0,
              -3,
            )}admin#/users/${getUserID()}`}
            target='_blank'
            rel='noreferrer'
          >
            <span>userID: {getUserID()}</span>
          </a>
          <a
            href={`
            ${SETTINGS.apiService.baseAxiosConfig.baseURL?.slice(0, -3)}admin#/applications/${
              activeApplication?.id
            }/show`}
            target='_blank'
            rel='noreferrer'
          >
            <span>applicationID: {activeApplication?.id}</span>
          </a>
          <a
            href={`
            ${SETTINGS.apiService.baseAxiosConfig.baseURL?.slice(0, -3)}admin#/loans/${
              loan?.id
            }/show`}
            target='_blank'
            rel='noreferrer'
          >
            <span>loanID: {loan?.id}</span>
          </a>
          <button onClick={copyTokenToClipboard}> copy token</button>
          <button onClick={copyCNPToClipboard}> copy CNP</button>
          <button onClick={devSkipHandler}>Skip 8_1 8_2 8_3</button>
          <label className='stepper-btn' htmlFor='step'>
            <input
              className='ml5 mr10 '
              type={'checkbox'}
              id='step'
              checked={isDisabledStepper}
              onClick={stepperDisablerHandler}
            />
            Disable stepper
          </label>
          {PATHS_FOR_DEV.map((path) => (
            <AppNavLinkContainer key={path} to={path}>
              {path}
            </AppNavLinkContainer>
          ))}
        </div>
      )}
    </div>
  ) : (
    <button type='button' className='development-info__empty' onClick={onOpen}>
      empty
    </button>
  );
};
