import { createSlice } from '@reduxjs/toolkit';
import { IPrice } from 'models';
import { getProduct, getProductId } from '../actions/api';

interface IInitialState {
  prices: IPrice | null;
  productId: number | null;
}

const initialState: IInitialState = {
  prices: null,
  productId: null,
};

export const productsSlice = createSlice({
  name: 'productsSlice',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getProduct.fulfilled, (state, action) => {
      state.prices = action.payload;
    });
    builder.addCase(getProductId.fulfilled, (state, action) => {
      state.productId = action.payload[0].id;
    });
  },
});
