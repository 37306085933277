import { useState } from 'react';
import { useTypedSelector } from 'core/hooks';
import { SharingLink } from './SharingLink';
import { Achievements } from './Achievements';
import { isMoreThenMonthMoratoriumSelector } from 'store/selectors';
import { ReferralInfo } from 'models';
import { ErrorShareModal, ShareModalContainer, SuccessShareModal } from '../modals';

export const ReferralInfoBox = (props: { info: ReferralInfo }) => {
  const { info } = props;

  const isMoreThenMonthMoratorium = useTypedSelector(isMoreThenMonthMoratoriumSelector);

  const [openModalType, setOpenModalType] = useState<'sms' | 'email' | null>(null);
  const [openSuccessModal, setOpenSuccessModal] = useState(false);
  const [openErrorModal, setOpenErrorModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const handleOpenModal = (val: 'sms' | 'email') => setOpenModalType(val);
  const handleCloseModal = () => setOpenModalType(null);
  const handleOpenSuccessModal = () => setOpenSuccessModal(true);
  const handleCloseSuccessModal = () => {
    setOpenSuccessModal(false);
    handleCloseModal();
  };
  const handleOpenErrorModal = () => setOpenErrorModal(true);
  const handleCloseErrorModal = () => {
    setOpenErrorModal(false);
    handleCloseModal();
  };

  return (
    <>
      {!isMoreThenMonthMoratorium && (
        <SharingLink link={info.referral_link} handleOpenModal={handleOpenModal} />
      )}
      <Achievements referralInfo={info} />

      {!!openModalType && !openSuccessModal && !openErrorModal && (
        <ShareModalContainer
          handleCloseModal={handleCloseModal}
          openModalType={openModalType}
          handleOpenSuccessModal={handleOpenSuccessModal}
          handleOpenErrorModal={handleOpenErrorModal}
          setErrorMessage={setErrorMessage}
        />
      )}
      {openSuccessModal && (
        <SuccessShareModal
          openModalType={openModalType}
          handleCloseSuccessModal={handleCloseSuccessModal}
        />
      )}

      {openErrorModal && (
        <ErrorShareModal
          handleCloseErrorModal={handleCloseErrorModal}
          errorMessage={errorMessage}
        />
      )}
    </>
  );
};
