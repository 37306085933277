import { useState } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { CloseIcon } from 'core/components/common';
import { InfoIcon } from '../icons';
import { sendVerificationEmail } from 'store/actions/api';
import { useAppDispatch } from 'hooks';

interface EmailAlertProps {
  isEmailVerificationSent?: boolean;
  userEmail?: string;
}

export const EmailAlert = ({ isEmailVerificationSent, userEmail }: EmailAlertProps) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const [isOpen, setIsOpen] = useState(true);
  const [isSentEmail, setIsSentEmail] = useState(isEmailVerificationSent);

  const onClose = () => setIsOpen(false);

  const handleClick = () => {
    dispatch(sendVerificationEmail())
      .unwrap()
      .then(() => {
        setIsSentEmail(true);
      });
  };

  return isOpen ? (
    <div className='alerts-item__info flex-column'>
      <div className='alerts-item__info__message w-100'>
        <div className='alerts-item__info__icon__wrapper mr-2'>
          <InfoIcon />
        </div>
        <div className='flex-column w-100 gap-2'>
          <p className='txt-sb '>{t`confirm_your_email`}</p>
          <p>
            <Trans
              i18nKey={isSentEmail ? 'we_sent_email_and_open' : 'please_confirm_your_email'}
              values={{ userEmail }}
              components={{
                a: <span className='txt-sb' />,
              }}
            />
          </p>
          {!isSentEmail && (
            <a onClick={handleClick} className='pill-primary w-100 h-8'>
              <span>{t`confirm_an_email`}</span>
            </a>
          )}
        </div>

        <div className='alerts-item__info__icon__wrapper close ml-2' onClick={onClose}>
          <CloseIcon />
        </div>
      </div>
    </div>
  ) : null;
};
