import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { getUserID, sendDataLayer } from 'core/services';
import { GoogleCalendarIcon } from '../icons';
import { Loan } from '../../models';
import { formatToUTC } from 'utils';
import { setStorageGoogleNotification } from 'utils/functions';

interface AddNotificationButtonProps {
  loan: Loan;
  variant: string;
  onClose?: () => void;
}

export const AddNotificationButton = ({ loan, variant, onClose }: AddNotificationButtonProps) => {
  const { t, i18n } = useTranslation();
  const maturedAt = loan && new Date(loan.matured_at);
  const dates = `${formatToUTC(maturedAt, 9, 0)}/${formatToUTC(maturedAt, 19, 0)}`;

  const url = useMemo(() => {
    const text = t(`google_notification_title`, { loanId: loan?.id });
    const details = t(`google_notification_details`, {
      minSumProlongation: loan?.allowed_extension_tenors_price[15],
      accruedTotal: loan?.accrued_total,
    });
    return `https://calendar.google.com/calendar/r/eventedit?text=${text}&ctz=Asia/Colombo&details=${details}&dates=${dates}&location=https://${loan?.loan_personal_account_short_link_uri}&trp=false`;
  }, [i18n.language]);

  const handleClick = () => {
    const UID = getUserID();
    setStorageGoogleNotification(loan?.id, loan?.matured_at, 'click');
    onClose && onClose();

    sendDataLayer('click_calendar', '', UID);
  };

  return (
    <a
      className={`btn btn-${variant} w-100 align-items-center gap-2`}
      href={url}
      target='_blank'
      rel='noreferrer'
      onClick={handleClick}
    >
      <span className='flex align-items-center'>
        <GoogleCalendarIcon />
      </span>
      <span>{t`add_notification`}</span>
    </a>
  );
};
