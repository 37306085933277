import TagManager from 'react-gtm-module';
import { SETTINGS } from 'core/settings';
import { getUserID } from './CookieService';

export const GTM_EVENT_ERROR_DATA = 'error_data';
export const GTM_EVENT_DECISION_APPLICATION = 'decision_application';
export const GTM_EVENT_PRE_APPROVE = 'pre_approve';
export const GTM_EVENT_PRE_APPROVE_REPEATED = 'pre_approve_repeated';

export const initialiseGTM = (): void => {
  if (SETTINGS.isDevelop) return;

  const tagManagerArgs = {
    gtmId: SETTINGS.gtmId || 'GTM-P27L7S4',
    dataLayerName: 'dataLayer',
  };

  TagManager.initialize(tagManagerArgs);
};

export const sendDataLayer = (
  eventName: string,
  label: string,
  UID: string = getUserID(),
  data?: any,
): void => {
  const tagManagerArgs = {
    dataLayer: {
      event: eventName,
      label,
      UID,
      ...data,
    },
  };

  if (SETTINGS.isDevelopStage) {
    // eslint-disable-next-line no-console
    console.log('TagManager.dataLayer:', tagManagerArgs);
    return;
  }

  TagManager.dataLayer(tagManagerArgs);
};

export const sendDataLayerError = (
  label: string,
  UID: string,
  stage: string,
  field_name: string,
  error_text: string,
): void => {
  const data = {
    stage,
    field_name,
    error_text,
  };

  sendDataLayer(GTM_EVENT_ERROR_DATA, label, UID, data);
};
