export const SELECT_LIST_BANK_BRANCHES: Record<string, Array<{ label: string; value: string }>> = {
  'BANK OF CEYLON': [
    { label: '001|CITY OFFICE', value: '001|CITY OFFICE' },
    { label: '002|KANDY', value: '002|KANDY' },
    { label: '003|GALLE', value: '003|GALLE' },
    { label: '004|PETTAH', value: '004|PETTAH' },
    { label: '005|JAFFNA', value: '005|JAFFNA' },
    { label: '006|TRINCOMALEE', value: '006|TRINCOMALEE' },
    { label: '007|PANADURA', value: '007|PANADURA' },
    { label: '009|KURUNEGALA', value: '009|KURUNEGALA' },
    { label: '010|SAVING DEP', value: '010|SAVING DEP' },
    { label: '011|BADULLA', value: '011|BADULLA' },
    { label: '012|BATTICALOA', value: '012|BATTICALOA' },
    { label: '015|CENTRAL OFFICE', value: '015|CENTRAL OFFICE' },
    { label: '016|KALUTARA', value: '016|KALUTARA' },
    { label: '018|NEGAMBO', value: '018|NEGAMBO' },
    { label: '020|CHILAW', value: '020|CHILAW' },
    { label: '021|AMPARA', value: '021|AMPARA' },
    { label: '022|ANURADHAPURA', value: '022|ANURADHAPURA' },
    { label: '023|WELLAWATTE', value: '023|WELLAWATTE' },
    { label: '024|MATARA', value: '024|MATARA' },
    { label: '026|MAIN STREET', value: '026|MAIN STREET' },
    { label: '027|KEGALLE', value: '027|KEGALLE' },
    { label: '028|POINT PEDRO', value: '028|POINT PEDRO' },
    { label: '029|NUWARA ELIYA', value: '029|NUWARA ELIYA' },
    { label: '030|KATUBEDDA', value: '030|KATUBEDDA' },
    { label: '031|RATNAPURA', value: '031|RATNAPURA' },
    { label: '032|HULFTSDORF', value: '032|HULFTSDORF' },
    { label: '034|KOLLUPITIYA', value: '034|KOLLUPITIYA' },
    { label: '035|HAPUTALE', value: '035|HAPUTALE' },
    { label: '037|BAMBALAPITIYA', value: '037|BAMBALAPITIYA' },
    { label: '038|BORELLA', value: '038|BORELLA' },
    { label: '039|JA ELA', value: '039|JA ELA' },
    { label: '040|HATTON', value: '040|HATTON' },
    { label: '041|MARADANA', value: '041|MARADANA' },
    { label: '042|PELIYAGODA', value: '042|PELIYAGODA' },
    { label: '043|UNION PLACE', value: '043|UNION PLACE' },
    { label: '044|VAVUNIYA', value: '044|VAVUNIYA' },
    { label: '045|GAMPAHA', value: '045|GAMPAHA' },
    { label: '046|MANNAR', value: '046|MANNAR' },
    { label: '047|AMBALANGODA', value: '047|AMBALANGODA' },
    { label: '048|PUTTALAM', value: '048|PUTTALAM' },
    { label: '049|NUGEGODA SUPER GREAT', value: '049|NUGEGODA SUPER GREAT' },
    { label: '050|NATTANDIYA', value: '050|NATTANDIYA' },
    { label: '051|DEHIWELA', value: '051|DEHIWELA' },
    { label: '052|KULIYAPITIYA', value: '052|KULIYAPITIYA' },
    { label: '053|CHUNNAKAM', value: '053|CHUNNAKAM' },
    { label: '054|HORANA', value: '054|HORANA' },
    { label: '055|MAHARAGAMA', value: '055|MAHARAGAMA' },
    { label: '056|TANGALLE', value: '056|TANGALLE' },
    { label: '057|EHELIYAGODA', value: '057|EHELIYAGODA' },
    { label: '058|BERUWALA', value: '058|BERUWALA' },
    { label: '059|KADAWATA', value: '059|KADAWATA' },
    { label: '060|FIFTH CITY BRANCH', value: '060|FIFTH CITY BRANCH' },
    { label: '061|MORATUWA', value: '061|MORATUWA' },
    { label: '063|KAYTS', value: '063|KAYTS' },
    { label: '068|MATALE', value: '068|MATALE' },
    { label: '082|MONERAGALA', value: '082|MONERAGALA' },
    { label: '083|POLONNARUWA NEW TOWN', value: '083|POLONNARUWA NEW TOWN' },
    { label: '085|HAMBANTOTA', value: '085|HAMBANTOTA' },
    { label: '087|INTERNATIONAL DIVISION', value: '087|INTERNATIONAL DIVISION' },
    { label: '088|MIRIGAMA', value: '088|MIRIGAMA' },
    { label: '089|GALLE BAZAAR', value: '089|GALLE BAZAAR' },
    { label: '092|NAULA', value: '092|NAULA' },
    { label: '093|KILINOCHCHI', value: '093|KILINOCHCHI' },
    { label: '098|NEW TOWN ANURADHAPURA', value: '098|NEW TOWN ANURADHAPURA' },
    { label: '099|PRIMARY DEALER UNIT', value: '099|PRIMARY DEALER UNIT' },
    { label: '101|GALAHA', value: '101|GALAHA' },
    { label: '102|BENTOTA', value: '102|BENTOTA' },
    { label: '104|WELPALLA', value: '104|WELPALLA' },
    { label: '118|MUTTUR', value: '118|MUTTUR' },
    { label: '122|GALENBINDUNUWEWA', value: '122|GALENBINDUNUWEWA' },
    { label: '127|PADAVI PARAKRAMAPU', value: '127|PADAVI PARAKRAMAPU' },
    { label: '135|IMADUWA', value: '135|IMADUWA' },
    { label: '139|WEERAKETIYA', value: '139|WEERAKETIYA' },
    { label: '144|YATAWATTE', value: '144|YATAWATTE' },
    { label: '152|PEMADUWA', value: '152|PEMADUWA' },
    { label: '157|TIRAPPENE', value: '157|TIRAPPENE' },
    { label: '162|MEDAWACHCHIYA', value: '162|MEDAWACHCHIYA' },
    { label: '167|RIKILLAGASKADA', value: '167|RIKILLAGASKADA' },
    { label: '172|KOBEIGANE', value: '172|KOBEIGANE' },
    { label: '179|WILGAMUWA', value: '179|WILGAMUWA' },
    { label: '183|SEWAGAMA', value: '183|SEWAGAMA' },
    { label: '217|HOROWPATHANA', value: '217|HOROWPATHANA' },
    { label: '236|IPALOGAMA', value: '236|IPALOGAMA' },
    { label: '238|MEDAGAMAA', value: '238|MEDAGAMAA' },
    { label: '250|THAWALAMA', value: '250|THAWALAMA' },
    { label: '255|MALKADAWAWA', value: '255|MALKADAWAWA' },
    { label: '256|THANTHIRIMALE', value: '256|THANTHIRIMALE' },
    { label: '257|MAWATHGAMA', value: '257|MAWATHGAMA' },
    { label: '258|ELAKANDA', value: '258|ELAKANDA' },
    { label: '259|RATHGAMA', value: '259|RATHGAMA' },
    { label: '260|DIYATALAWA', value: '260|DIYATALAWA' },
    { label: '261|KATUWANA', value: '261|KATUWANA' },
    { label: '262|KEKANADURA', value: '262|KEKANADURA' },
    { label: '263|KOSMODERA', value: '263|KOSMODERA' },
    { label: '264|KUDAWELLA', value: '264|KUDAWELLA' },
    { label: '265|LUNUGAMVEHERA', value: '265|LUNUGAMVEHERA' },
    { label: '266|MAHA EDANDA', value: '266|MAHA EDANDA' },
    { label: '267|MAKANDURA-MATARA', value: '267|MAKANDURA-MATARA' },
    { label: '268|MALIMBADA', value: '268|MALIMBADA' },
    { label: '270|MORAWAKA', value: '270|MORAWAKA' },
    { label: '271|PASGODA', value: '271|PASGODA' },
    { label: '272|PITABEDDARA', value: '272|PITABEDDARA' },
    { label: '273|DIGINA', value: '273|DIGINA' },
    { label: '274|WELI OYA', value: '274|WELI OYA' },
    { label: '276|AHANGAMA', value: '276|AHANGAMA' },
    { label: '277|ALUTHWALA', value: '277|ALUTHWALA' },
    { label: '278|BARAWAKUMBUKA', value: '278|BARAWAKUMBUKA' },
    { label: '280|KARAPITIYA', value: '280|KARAPITIYA' },
    { label: '281|MANIPAY', value: '281|MANIPAY' },
    { label: '282|KITULGALA', value: '282|KITULGALA' },
    { label: '283|KOLONNA', value: '283|KOLONNA' },
    { label: '284|KOTIYAKUMBURA', value: '284|KOTIYAKUMBURA' },
    { label: '285|MORONTOTA', value: '285|MORONTOTA' },
    { label: '286|SABARAGAMUWA UNIVERSITY', value: '286|SABARAGAMUWA UNIVERSITY' },
    { label: '287|PINNAWALA', value: '287|PINNAWALA' },
    { label: '288|SABARAGAMUWA PROVINCIAL COUNCI', value: '288|SABARAGAMUWA PROVINCIAL COUNCI' },
    { label: '290|SEETHAWAKAPURA', value: '290|SEETHAWAKAPURA' },
    { label: '291|UDAWALAWE', value: '291|UDAWALAWE' },
    { label: '292|WELIGEPOLA', value: '292|WELIGEPOLA' },
    { label: '293|DODANGODA', value: '293|DODANGODA' },
    { label: '294|KARAWANELLA', value: '294|KARAWANELLA' },
    { label: '295|KARAWITA', value: '295|KARAWITA' },
    { label: '297|KEGALLE HOSPITAL', value: '297|KEGALLE HOSPITAL' },
    { label: '298|URUBOKKA', value: '298|URUBOKKA' },
    { label: '299|MAKANDURA', value: '299|MAKANDURA' },
    { label: '300|MARAWILA', value: '300|MARAWILA' },
    { label: '301|PALAVIYA', value: '301|PALAVIYA' },
    { label: '302|PALLAMA', value: '302|PALLAMA' },
    { label: '303|PARAGAHADENIYA', value: '303|PARAGAHADENIYA' },
    { label: '305|THODUWAWA', value: '305|THODUWAWA' },
    { label: '306|UDAPPUWA', value: '306|UDAPPUWA' },
    { label: '307|WAYAMBA UNIVERSITY', value: '307|WAYAMBA UNIVERSITY' },
    { label: '308|WEERAPOKUNA', value: '308|WEERAPOKUNA' },
    { label: '309|WELLAWA', value: '309|WELLAWA' },
    { label: '311|BULATHKOHUPITIYA', value: '311|BULATHKOHUPITIYA' },
    { label: '312|EMBILIPITIYA CITY', value: '312|EMBILIPITIYA CITY' },
    { label: '313|ENDANA', value: '313|ENDANA' },
    { label: '314|GALIGAMUWA', value: '314|GALIGAMUWA' },
    { label: '315|RATNAPURA HOSPITAL', value: '315|RATNAPURA HOSPITAL' },
    { label: '316|GONAGALDENIYA', value: '316|GONAGALDENIYA' },
    { label: '317|IDDANGODA', value: '317|IDDANGODA' },
    { label: '318|POTUVIL', value: '318|POTUVIL' },
    { label: '319|MAHAWEWA', value: '319|MAHAWEWA' },
    { label: '320|BALLAKATUWA', value: '320|BALLAKATUWA' },
    { label: '322|THANAMALWILA', value: '322|THANAMALWILA' },
    { label: '323|KOCHCHIKADE', value: '323|KOCHCHIKADE' },
    { label: '324|KUMBUKGETE', value: '324|KUMBUKGETE' },
    { label: '325|KURUWITA', value: '325|KURUWITA' },
    { label: '326|THIRUMURUKANDI', value: '326|THIRUMURUKANDI' },
    { label: '328|VISUVAMADU', value: '328|VISUVAMADU' },
    { label: '329|AMBANPOLA', value: '329|AMBANPOLA' },
    { label: '330|ANAWILUNDAWA', value: '330|ANAWILUNDAWA' },
    { label: '331|DAMBADENIYA', value: '331|DAMBADENIYA' },
    { label: '332|KATUNERIYA', value: '332|KATUNERIYA' },
    { label: '333|KATUPOTHA', value: '333|KATUPOTHA' },
    { label: '334|KIRIMETIYANA', value: '334|KIRIMETIYANA' },
    { label: '335|MIHINTHALE', value: '335|MIHINTHALE' },
    { label: '336|THALAIMANNAR PIER', value: '336|THALAIMANNAR PIER' },
    { label: '337|PUSSELLAWA', value: '337|PUSSELLAWA' },
    { label: '338|SAVALKADDU', value: '338|SAVALKADDU' },
    { label: '339|SIRUPIDDY', value: '339|SIRUPIDDY' },
    { label: '340|WATTEGAMA', value: '340|WATTEGAMA' },
    { label: '341|PUTHUKUDIERUPPU', value: '341|PUTHUKUDIERUPPU' },
    { label: '342|PUTHUKULAM', value: '342|PUTHUKULAM' },
    { label: '343|UVA PARANAGAMA', value: '343|UVA PARANAGAMA' },
    { label: '344|PESALAI', value: '344|PESALAI' },
    { label: '345|POONAGARY', value: '345|POONAGARY' },
    { label: '346|POOVARASANKULAM', value: '346|POOVARASANKULAM' },
    { label: '347|PUNNALAIKADDUVAN', value: '347|PUNNALAIKADDUVAN' },
    { label: '348|PADIYATALAWA', value: '348|PADIYATALAWA' },
    { label: '349|MALLAVI', value: '349|MALLAVI' },
    { label: '351|MANTHIKAI', value: '351|MANTHIKAI' },
    { label: '352|MARUTHANKERNY', value: '352|MARUTHANKERNY' },
    { label: '353|MULANKAVIL', value: '353|MULANKAVIL' },
    { label: '355|MULLATIVU', value: '355|MULLATIVU' },
    { label: '356|MURUNGAN', value: '356|MURUNGAN' },
    { label: '357|NAINATIVU', value: '357|NAINATIVU' },
    { label: '358|NALLUR', value: '358|NALLUR' },
    { label: '359|NANATAN', value: '359|NANATAN' },
    { label: '360|NEDUNKERNY', value: '360|NEDUNKERNY' },
    { label: '361|ODDUSUDAN', value: '361|ODDUSUDAN' },
    { label: '362|OMANTHAI', value: '362|OMANTHAI' },
    { label: '363|PALLAI', value: '363|PALLAI' },
    { label: '364|PARANTHAN', value: '364|PARANTHAN' },
    { label: '365|HASALAKA', value: '365|HASALAKA' },
    { label: '366|JAFFNA BUS STAND', value: '366|JAFFNA BUS STAND' },
    { label: '368|JAFFNA MAIN STREET', value: '368|JAFFNA MAIN STREET' },
    { label: '369|JAFFNA UNIVERSITY', value: '369|JAFFNA UNIVERSITY' },
    { label: '370|KAITHADY', value: '370|KAITHADY' },
    { label: '371|KALVIYANKADU', value: '371|KALVIYANKADU' },
    { label: '372|KARANAVAI', value: '372|KARANAVAI' },
    { label: '373|KAYTS', value: '373|KAYTS' },
    { label: '375|KODIKAMAM', value: '375|KODIKAMAM' },
    { label: '376|KOKUVIL', value: '376|KOKUVIL' },
    { label: '377|ILLAVALAI', value: '377|ILLAVALAI' },
    { label: '378|MADHU', value: '378|MADHU' },
    { label: '379|WARIYAPOLA', value: '379|WARIYAPOLA' },
    { label: '380|ALAVEDDY', value: '380|ALAVEDDY' },
    { label: '381|ANDANKULAM', value: '381|ANDANKULAM' },
    { label: '382|CHEDDIKULAM', value: '382|CHEDDIKULAM' },
    { label: '383|DELFT', value: '383|DELFT' },
    { label: '384|MEEGAHAKIWULA', value: '384|MEEGAHAKIWULA' },
    { label: '385|VAVUNATHIVU', value: '385|VAVUNATHIVU' },
    { label: '386|VELLAVELI', value: '386|VELLAVELI' },
    { label: '388|DIYABEDUMA', value: '388|DIYABEDUMA' },
    { label: '389|DIYASENPURA', value: '389|DIYASENPURA' },
    { label: '390|DORAMADALAWA', value: '390|DORAMADALAWA' },
    { label: '391|GALAMUNA', value: '391|GALAMUNA' },
    { label: '392|GENERAL HOSPITAL ANURADHAPURA', value: '392|GENERAL HOSPITAL ANURADHAPURA' },
    { label: '393|HABARANA', value: '393|HABARANA' },
    { label: '394|MINNERIYA', value: '394|MINNERIYA' },
    { label: '395|PADAVIYA', value: '395|PADAVIYA' },
    { label: '396|RAJANGANAYA', value: '396|RAJANGANAYA' },
    { label: '397|RAJINA JUNCTION', value: '397|RAJINA JUNCTION' },
    { label: '398|RANAJAYAPURA', value: '398|RANAJAYAPURA' },
    { label: '399|SEVANAPITIYA', value: '399|SEVANAPITIYA' },
    { label: '400|THALAWA', value: '400|THALAWA' },
    { label: '401|AYAGAMA', value: '401|AYAGAMA' },
    { label: '402|ODDAMAVADY', value: '402|ODDAMAVADY' },
    { label: '403|OLUWIL', value: '403|OLUWIL' },
    { label: '404|PALUGAMAMY', value: '404|PALUGAMAMY' },
    { label: '405|POLWATTE', value: '405|POLWATTE' },
    { label: '406|PULMUDDAI', value: '406|PULMUDDAI' },
    { label: '407|SAINTHAMARATHU', value: '407|SAINTHAMARATHU' },
    { label: '408|SERUNUWARA', value: '408|SERUNUWARA' },
    { label: '409|THAMBILUVIL', value: '409|THAMBILUVIL' },
    { label: '410|THAMPALAKAMAM', value: '410|THAMPALAKAMAM' },
    { label: '411|THOPPUR', value: '411|THOPPUR' },
    { label: '413|UHANA', value: '413|UHANA' },
    { label: '414|UPPUVELY', value: '414|UPPUVELY' },
    { label: '415|VAKARAI', value: '415|VAKARAI' },
    { label: '416|SIYAMBALANDUWA', value: '416|SIYAMBALANDUWA' },
    { label: '417|MOLLIPOTHANA', value: '417|MOLLIPOTHANA' },
    { label: '418|MORAWEWA', value: '418|MORAWEWA' },
    { label: '419|NAVITHANVELY', value: '419|NAVITHANVELY' },
    { label: '420|NILAVELY', value: '420|NILAVELY' },
    { label: '421|SEEDUWA', value: '421|SEEDUWA' },
    { label: '422|MALWATTE', value: '422|MALWATTE' },
    { label: '423|MAMANGAM', value: '423|MAMANGAM' },
    { label: '424|MARUTHAMUNAI', value: '424|MARUTHAMUNAI' },
    { label: '425|PUNDALUOYA', value: '425|PUNDALUOYA' },
    { label: '426|KALLADY', value: '426|KALLADY' },
    { label: '427|KALLAR', value: '427|KALLAR' },
    { label: '428|KARADIYANARU', value: '428|KARADIYANARU' },
    { label: '429|KARAITIVU', value: '429|KARAITIVU' },
    { label: '430|KIRAN', value: '430|KIRAN' },
    { label: '431|KOKKADICHOLAI', value: '431|KOKKADICHOLAI' },
    { label: '432|GALEWELA', value: '432|GALEWELA' },
    { label: '433|DIVULAPITIYA', value: '433|DIVULAPITIYA' },
    { label: '434|WELLAWAYA', value: '434|WELLAWAYA' },
    { label: '436|CHINA BAY', value: '436|CHINA BAY' },
    { label: '437|EASTERN UNIVERSITY', value: '437|EASTERN UNIVERSITY' },
    { label: '438|GONAGOLLA', value: '438|GONAGOLLA' },
    { label: '439|IRAKKAMAM', value: '439|IRAKKAMAM' },
    { label: '440|SAMANTURAI', value: '440|SAMANTURAI' },
    { label: '441|PUJAPITIYA', value: '441|PUJAPITIYA' },
    { label: '442|RAGALA', value: '442|RAGALA' },
    { label: '443|SIGIRIYA', value: '443|SIGIRIYA' },
    { label: '444|UKUWELA', value: '444|UKUWELA' },
    { label: '445|UNIVERSITY OF PERADENIYA', value: '445|UNIVERSITY OF PERADENIYA' },
    { label: '446|UPCOTT', value: '446|UPCOTT' },
    { label: '447|WILGAMUWA', value: '447|WILGAMUWA' },
    { label: '448|ADDALACHCHENAI', value: '448|ADDALACHCHENAI' },
    { label: '449|ALANKERNY', value: '449|ALANKERNY' },
    { label: '451|ARAIYAMPATHY', value: '451|ARAIYAMPATHY' },
    { label: '452|BATTICALOA TOWN', value: '452|BATTICALOA TOWN' },
    { label: '453|TORRINGTON SQUARE', value: '453|TORRINGTON SQUARE' },
    { label: '454|KANDY HOSPITAL', value: '454|KANDY HOSPITAL' },
    { label: '455|KOTAGALA', value: '455|KOTAGALA' },
    { label: '456|MARASSANA', value: '456|MARASSANA' },
    { label: '458|MEEPILIMANA', value: '458|MEEPILIMANA' },
    { label: '459|MENIKHINNA', value: '459|MENIKHINNA' },
    { label: '461|PALAPATHWELA', value: '461|PALAPATHWELA' },
    { label: '462|BOTANICAL GARDENS PERADENIYA', value: '462|BOTANICAL GARDENS PERADENIYA' },
    { label: '463|HALDUMULLA', value: '463|HALDUMULLA' },
    { label: '464|BOI PALLEKELLE', value: '464|BOI PALLEKELLE' },
    { label: '465|BOKKAWALA', value: '465|BOKKAWALA' },
    { label: '466|DANTURE', value: '466|DANTURE' },
    { label: '467|DAULAGALA', value: '467|DAULAGALA' },
    { label: '469|DIGANA VILLAGE', value: '469|DIGANA VILLAGE' },
    { label: '470|GAMPOLA CITY', value: '470|GAMPOLA CITY' },
    { label: '471|GINIGATHHENA', value: '471|GINIGATHHENA' },
    { label: '472|HATHARALIYADDA', value: '472|HATHARALIYADDA' },
    { label: '473|KANDY CITY CENTRE', value: '473|KANDY CITY CENTRE' },
    { label: '474|COURT COMPLEX KANDY', value: '474|COURT COMPLEX KANDY' },
    { label: '476|ETTAMPITIYA', value: '476|ETTAMPITIYA' },
    { label: '477|YATIYANTOTA', value: '477|YATIYANTOTA' },
    { label: '487|ADIKARIGAMA', value: '487|ADIKARIGAMA' },
    { label: '488|AGARAPATHANA', value: '488|AGARAPATHANA' },
    { label: '489|AKURANA', value: '489|AKURANA' },
    { label: '490|ANKUMBURA', value: '490|ANKUMBURA' },
    { label: '491|BOGAWANTALAWA', value: '491|BOGAWANTALAWA' },
    { label: '492|PADIYAPALLALLA', value: '492|PADIYAPALLALLA' },
    { label: '494|ANDIAMBALAMA', value: '494|ANDIAMBALAMA' },
    { label: '497|DANKOTUWA', value: '497|DANKOTUWA' },
    { label: '498|ALAWWA', value: '498|ALAWWA' },
    { label: '499|WIJERAMA JUNCTION', value: '499|WIJERAMA JUNCTION' },
    { label: '500|JAFFNA 2NDBRANCH', value: '500|JAFFNA 2ND BRANCH' },
    { label: '501|CHAVAKACHCHERI', value: '501|CHAVAKACHCHERI' },
    { label: '502|KADURUWELA', value: '502|KADURUWELA' },
    { label: '503|PASSARA', value: '503|PASSARA' },
    { label: '504|DEVINUWARA', value: '504|DEVINUWARA' },
    { label: '505|WATTALA', value: '505|WATTALA' },
    { label: '506|MASKELIYA', value: '506|MASKELIYA' },
    { label: '507|KAHAWATTE', value: '507|KAHAWATTE' },
    { label: '508|WENNAPPUWA', value: '508|WENNAPPUWA' },
    { label: '509|HINGURANA', value: '509|HINGURANA' },
    { label: '510|KALMUNAI', value: '510|KALMUNAI' },
    { label: '511|MULLIAYAWALAI', value: '511|MULLIAYAWALAI' },
    { label: '512|THIMBIRIGASYAYA', value: '512|THIMBIRIGASYAYA' },
    { label: '513|KURUNEGALA BAZAAR', value: '513|KURUNEGALA BAZAAR' },
    { label: '514|GALNEWA', value: '514|GALNEWA' },
    { label: '515|BANDARAWELA', value: '515|BANDARAWELA' },
    { label: '516|THALAWATHUGODA', value: '516|THALAWATHUGODA' },
    { label: '517|WALASMULLA', value: '517|WALASMULLA' },
    { label: '518|MIDDENIYA', value: '518|MIDDENIYA' },
    { label: '520|SRI JAYAWARDENAPURA HOSPITAL', value: '520|SRI JAYAWARDENAPURA HOSPITAL' },
    { label: '521|HYDE PARK', value: '521|HYDE PARK' },
    { label: '522|BATAPOLA', value: '522|BATAPOLA' },
    { label: '524|GELIOYA', value: '524|GELIOYA' },
    { label: '525|BADDEGAMA', value: '525|BADDEGAMA' },
    { label: '526|POLGAHAWELA', value: '526|POLGAHAWELA' },
    { label: '527|WELISARA', value: '527|WELISARA' },
    { label: '528|DENIYAYA', value: '528|DENIYAYA' },
    { label: '529|KAMBURUPITIYA', value: '529|KAMBURUPITIYA' },
    { label: '530|AVISSAWELLA', value: '530|AVISSAWELLA' },
    { label: '531|TALAWAKELLE', value: '531|TALAWAKELLE' },
    { label: '532|RIDIGAMA', value: '532|RIDIGAMA' },
    { label: '533|PITAKOTTE', value: '533|PITAKOTTE' },
    { label: '534|NARAMMALA', value: '534|NARAMMALA' },
    { label: '535|EMBILIPITIYA', value: '535|EMBILIPITIYA' },
    { label: '536|KEGALLE BAZAAR', value: '536|KEGALLE BAZAAR' },
    { label: '537|AMBALANTOTA', value: '537|AMBALANTOTA' },
    { label: '538|TISSAMAHARAMA', value: '538|TISSAMAHARAMA' },
    { label: '539|BELIATTA', value: '539|BELIATTA' },
    { label: '540|BADALKUBURA', value: '540|BADALKUBURA' },
    { label: '541|PELAWATTE CITY KALUTARA', value: '541|PELAWATTE CITY KALUTARA' },
    { label: '542|MAHIYANGANA', value: '542|MAHIYANGANA' },
    { label: '543|KIRIBATHGODA', value: '543|KIRIBATHGODA' },
    { label: '544|MADAMPE', value: '544|MADAMPE' },
    { label: '545|MINUWANGODA', value: '545|MINUWANGODA' },
    { label: '546|PANNALA', value: '546|PANNALA' },
    { label: '547|NIKAWERATIYA', value: '547|NIKAWERATIYA' },
    { label: '548|ANAMADUWA', value: '548|ANAMADUWA' },
    { label: '549|GALGAMUWA', value: '549|GALGAMUWA' },
    { label: '550|WELIGAMA', value: '550|WELIGAMA' },
    { label: '551|ANURADHAPURA BAZAAR', value: '551|ANURADHAPURA BAZAAR' },
    { label: '552|NAT INSTITUTE OF EDUCATION', value: '552|NAT INSTITUTE OF EDUCATION' },
    { label: '553|GIRIULLA', value: '553|GIRIULLA' },
    { label: '554|BINGIRIYA', value: '554|BINGIRIYA' },
    { label: '555|MELSIRIPURA', value: '555|MELSIRIPURA' },
    { label: '556|MATUGAMA', value: '556|MATUGAMA' },
    { label: '557|MORATUMULLA', value: '557|MORATUMULLA' },
    { label: '558|WAIKKAL', value: '558|WAIKKAL' },
    { label: '559|MAWANELLA', value: '559|MAWANELLA' },
    { label: '560|BUTTALA', value: '560|BUTTALA' },
    { label: '561|DEMATAGODA', value: '561|DEMATAGODA' },
    { label: '562|WARAKAPOLA', value: '562|WARAKAPOLA' },
    { label: '563|DARGA TOWN', value: '563|DARGA TOWN' },
    { label: '564|MAHO', value: '564|MAHO' },
    { label: '565|MADURANKULIYA', value: '565|MADURANKULIYA' },
    { label: '566|ARANAYAKE', value: '566|ARANAYAKE' },
    { label: '567|D.ECONOMICCENTRE MEEGODA', value: '567|D.ECONOMICCENTRE MEEGODA' },
    { label: '568|HOMAGAMA', value: '568|HOMAGAMA' },
    { label: '569|HIRIPITIYA', value: '569|HIRIPITIYA' },
    { label: '570|HETTIPOLA', value: '570|HETTIPOLA' },
    { label: '571|KIRINDIWELA', value: '571|KIRINDIWELA' },
    { label: '572|NEGOMBO BAZZAR', value: '572|NEGOMBO BAZZAR' },
    { label: '573|CENTRAL BUS STAND', value: '573|CENTRAL BUS STAND' },
    { label: '574|MANKULAM', value: '574|MANKULAM' },
    { label: '575|GAMPOLA', value: '575|GAMPOLA' },
    { label: '576|DAMBULLA', value: '576|DAMBULLA' },
    { label: '577|LUNUGALA', value: '577|LUNUGALA' },
    { label: '578|YAKKALAMULLA', value: '578|YAKKALAMULLA' },
    { label: '579|BIBILE', value: '579|BIBILE' },
    { label: '580|DUMMALASURIYA', value: '580|DUMMALASURIYA' },
    { label: '581|MADAWALA', value: '581|MADAWALA' },
    { label: '582|RAMBUKKANA', value: '582|RAMBUKKANA' },
    { label: '583|MATTEGODA', value: '583|MATTEGODA' },
    { label: '584|WADDUWA', value: '584|WADDUWA' },
    { label: '585|RUWANWELLA', value: '585|RUWANWELLA' },
    { label: '587|PILIMATALAWA', value: '587|PILIMATALAWA' },
    { label: '588|PERADENIYA', value: '588|PERADENIYA' },
    { label: '589|KALPITIYA', value: '589|KALPITIYA' },
    { label: '590|AKKARAIPATTU', value: '590|AKKARAIPATTU' },
    { label: '591|NINTAVUR', value: '591|NINTAVUR' },
    { label: '592|DICKWELLA', value: '592|DICKWELLA' },
    { label: '593|MILAGIRIYA', value: '593|MILAGIRIYA' },
    { label: '594|RAKWANA', value: '594|RAKWANA' },
    { label: '595|KOLONNAWA', value: '595|KOLONNAWA' },
    { label: '596|TALGASWELA', value: '596|TALGASWELA' },
    { label: '597|NIVITIGALA', value: '597|NIVITIGALA' },
    { label: '598|NAWALAPITIYA', value: '598|NAWALAPITIYA' },
    { label: '599|ARALAGANWILA', value: '599|ARALAGANWILA' },
    { label: '600|JAYANTHIPURA', value: '600|JAYANTHIPURA' },
    { label: '601|HINGURAKGODA', value: '601|HINGURAKGODA' },
    { label: '602|KIRULAPANA', value: '602|KIRULAPANA' },
    { label: '603|LANKA HOSPITAL', value: '603|LANKA HOSPITAL' },
    { label: '604|INGIRIYA', value: '604|INGIRIYA' },
    { label: '605|KANKESANTHURAI', value: '605|KANKESANTHURAI' },
    { label: '606|UDADUMBARA', value: '606|UDADUMBARA' },
    { label: '607|PANADURA BAZAAR', value: '607|PANADURA BAZAAR' },
    { label: '608|KADUWELA', value: '608|KADUWELA' },
    { label: '609|HIKKADUWA', value: '609|HIKKADUWA' },
    { label: '610|PITIGALA', value: '610|PITIGALA' },
    { label: '611|KALUWANCHIKUDY', value: '611|KALUWANCHIKUDY' },
    { label: '612|LAKE VIEW', value: '612|LAKE VIEW' },
    { label: '613|AKURESSA', value: '613|AKURESSA' },
    { label: '614|MATARA CITY', value: '614|MATARA CITY' },
    { label: '615|GALAGEDARA', value: '615|GALAGEDARA' },
    { label: '616|KATARAGAMA', value: '616|KATARAGAMA' },
    { label: '617|KESELWATTE', value: '617|KESELWATTE' },
    { label: '618|METROPOLITAN', value: '618|METROPOLITAN' },
    { label: '619|ELPITIYA', value: '619|ELPITIYA' },
    { label: '620|KESBEWA', value: '620|KESBEWA' },
    { label: '621|KEBITHIGOLLAWA', value: '621|KEBITHIGOLLAWA' },
    { label: '622|KAHATAGASDIGILIYA', value: '622|KAHATAGASDIGILIYA' },
    { label: '623|KANTALI BAZAAR', value: '623|KANTALI BAZAAR' },
    { label: '624|TRINCOMALEE BAZAAR', value: '624|TRINCOMALEE BAZAAR' },
    { label: '625|KATUKURUNDA', value: '625|KATUKURUNDA' },
    { label: '626|VALAICHCHENAI', value: '626|VALAICHCHENAI' },
    { label: '627|REGENT STREET', value: '627|REGENT STREET' },
    { label: '628|GRANDPASS', value: '628|GRAND PASS' },
    { label: '629|KOSLANDA', value: '629|KOSLANDA' },
    { label: '630|CHENKALADI', value: '630|CHENKALADI' },
    { label: '631|KATUBEDDA CAMPUS', value: '631|KATUBEDDA CAMPUS' },
    { label: '633|KANDAPOLA', value: '633|KANDAPOLA' },
    { label: '634|DEHIOWITA', value: '634|DEHIOWITA' },
    { label: '636|LAKE HOUSE', value: '636|LAKE HOUSE' },
    { label: '638|NELLIADY', value: '638|NELLIADY' },
    { label: '639|RATTOTA', value: '639|RATTOTA' },
    { label: '640|PALLEPOLA', value: '640|PALLEPOLA' },
    { label: '641|MEDIRIGIRIYA', value: '641|MEDIRIGIRIYA' },
    { label: '642|DERANIYAGALA', value: '642|DERANIYAGALA' },
    { label: '643|GONAPOLA', value: '643|GONAPOLA' },
    { label: '644|PARLIMENTARY COMPLEX', value: '644|PARLIMENTARY COMPLEX' },
    { label: '645|KALAWANA', value: '645|KALAWANA' },
    { label: '646|BORALESGAMUWA', value: '646|BORALESGAMUWA' },
    { label: '647|LUNUWATTE', value: '647|LUNUWATTE' },
    { label: '648|KATTANKUDY', value: '648|KATTANKUDY' },
    { label: '649|KANDY 2ND CITY', value: '649|KANDY 2ND CITY' },
    { label: '650|TALATUOYA', value: '650|TALATUOYA' },
    { label: '651|BOMBUWELA', value: '651|BOMBUWELA' },
    { label: '652|BAKAMUNA', value: '652|BAKAMUNA' },
    { label: '653|GALKIRIYAGAMA', value: '653|GALKIRIYAGAMA' },
    { label: '654|MADATUGAMA', value: '654|MADATUGAMA' },
    { label: '655|TAMBUTTEGAMA', value: '655|TAMBUTTEGAMA' },
    { label: '656|NOCHCHIYAGAMA', value: '656|NOCHCHIYAGAMA' },
    { label: '657|AGALAWATTA', value: '657|AGALAWATTA' },
    { label: '658|KATUNAYAKE IPZ', value: '658|KATUNAYAKE IPZ' },
    { label: '659|GURUGODA', value: '659|GURUGODA' },
    { label: '660|CORPORATE BRANCH', value: '660|CORPORATE BRANCH' },
    { label: '662|BADURALIYA', value: '662|BADURALIYA' },
    { label: '663|KOTAHENA', value: '663|KOTAHENA' },
    { label: '664|POTHUHERA', value: '664|POTHUHERA' },
    { label: '665|BANDARAGAMA', value: '665|BANDARAGAMA' },
    { label: '666|KATUGASTOTA', value: '666|KATUGASTOTA' },
    { label: '667|NELUWA', value: '667|NELUWA' },
    { label: '668|BORELLA 2ND', value: '668|BORELLA 2ND' },
    { label: '669|GIRANDURUKOTTE', value: '669|GIRANDURUKOTTE' },
    { label: '670|KOLLUPITIYTA 2ND', value: '670|KOLLUPITIYTA 2ND' },
    { label: '671|ISURUPAYA BRANCH', value: '671|ISURUPAYA BRANCH' },
    { label: '672|COLOMBO GOLD CENTER', value: '672|COLOMBO GOLD CENTER' },
    { label: '673|BULATHSINHALA', value: '673|BULATHSINHALA' },
    { label: '674|ENDERAMULLA', value: '674|ENDERAMULLA' },
    { label: '675|NITTABUWA', value: '675|NITTABUWA' },
    { label: '676|KEKIRAWA', value: '676|KEKIRAWA' },
    { label: '677|WELIWERIYA', value: '677|WELIWERIYA' },
    { label: '678|PADUKKA', value: '678|PADUKKA' },
    { label: '679|BATTARAMULLA', value: '679|BATTARAMULLA' },
    { label: '680|ALUTHGAMA', value: '680|ALUTHGAMA' },
    { label: '681|PERSONAL', value: '681|PERSONAL' },
    { label: '682|VEYANGODA', value: '682|VEYANGODA' },
    { label: '683|PELMADULLA', value: '683|PELMADULLA' },
    { label: '684|RATNAPURA BAZAAR', value: '684|RATNAPURA BAZAAR' },
    { label: '685|WARD PLACE', value: '685|WARD PLACE' },
    { label: '686|DEHIATTAKANDIYA', value: '686|DEHIATTAKANDIYA' },
    { label: '687|RADDOLUGAMA', value: '687|RADDOLUGAMA' },
    { label: '688|BALANGODA', value: '688|BALANGODA' },
    { label: '689|RATMALANA', value: '689|RATMALANA' },
    { label: '690|PELAWATTE', value: '690|PELAWATTE' },
    { label: '691|HAKMANA', value: '691|HAKMANA' },
    { label: '692|EPPAWALA', value: '692|EPPAWALA' },
    { label: '693|RUHUNU CAMPUS', value: '693|RUHUNU CAMPUS' },
    { label: '699|BOGAHAKUMBURA', value: '699|BOGAHAKUMBURA' },
    { label: '700|DAMBAGALLA', value: '700|DAMBAGALLA' },
    { label: '701|ELLA', value: '701|ELLA' },
    { label: '702|ETHILIWEWA', value: '702|ETHILIWEWA' },
    { label: '703|KEPPETIPOLA', value: '703|KEPPETIPOLA' },
    { label: '705|MONERAGALA TOWN', value: '705|MONERAGALA TOWN' },
    { label: '706|OKKAMPITIYA', value: '706|OKKAMPITIYA' },
    { label: '707|PELAWATTASUGAR INDUSTRIES COX', value: '707|PELAWATTA SUGAR INDUSTRIES COX' },
    { label: '708|BATUWATTE', value: '708|BATUWATTE' },
    { label: '711|BOPITIYA', value: '711|BOPITIYA' },
    { label: '713|ASIRI CENTRAL', value: '713|ASIRI CENTRAL' },
    { label: '714|KATUWELLEGAMA COURTAULDS CLOTH', value: '714|KATUWELLEGAMA COURTAULDS CLOTH' },
    { label: '715|DALUGAMA', value: '715|DALUGAMA' },
    { label: '716|DELGODA', value: '716|DELGODA' },
    { label: '717|DEMANHANDIYA', value: '717|DEMANHANDIYA' },
    { label: '718|FISH MARKET PELIYAGODA', value: '718|FISH MARKET PELIYAGODA' },
    { label: '720|GANEMULLA', value: '720|GANEMULLA' },
    { label: '721|GOTHATUWA', value: '721|GOTHATUWA' },
    { label: '722|KATANA', value: '722|KATANA' },
    { label: '723|MULLERIYAWA NEW TOWN', value: '723|MULLERIYAWA NEW TOWN' },
    { label: '724|NAIWALA', value: '724|NAIWALA' },
    { label: '728|MEEGALEWA', value: '728|MEEGALEWA' },
    { label: '729|BADULLA CITY', value: '729|BADULLA CITY' },
    { label: '730|WELIMADA', value: '730|WELIMADA' },
    { label: '731|CEYBANK CREDIT CARD CENTRE', value: '731|CEYBANK CREDIT CARD CENTRE' },
    { label: '732|BIYAGAMA', value: '732|BIYAGAMA' },
    { label: '733|WARAPITIYA', value: '733|WARAPITIYA' },
    { label: '735|KINNIYA', value: '735|KINNIYA' },
    { label: '736|PILIYANDALA', value: '736|PILIYANDALA' },
    { label: '741|HANWELLA', value: '741|HANWELLA' },
    { label: '743|WALAPAN', value: '743|WALAPAN' },
    { label: '744|WALGAMA', value: '744|WALGAMA' },
    { label: '746|RAJAGIRIYA', value: '746|RAJAGIRIYA' },
    { label: '747|TAPROBANE', value: '747|TAPROBANE' },
    { label: '748|URAGASMANHANDIYA', value: '748|URAGASMANHANDIYA' },
    { label: '749|KARAINAGAR', value: '749|KARAINAGAR' },
    { label: '750|KOGGALA EPZ BRANCH', value: '750|KOGGALA EPZ BRANCH' },
    { label: '751|SURIYAWEWA', value: '751|SURIYAWEWA' },
    { label: '752|THIHAGODA', value: '752|THIHAGODA' },
    { label: '753|UDUGAMA', value: '753|UDUGAMA' },
    { label: '754|AHUNGALLA', value: '754|AHUNGALLA' },
    { label: '757|ATURUGIRIYA', value: '757|ATURUGIRIYA' },
    { label: '760|TREASURY', value: '760|TREASURY' },
    { label: '761|THIRUNELVELY', value: '761|THIRUNELVELY' },
    { label: '762|NARAHENPITA', value: '762|NARAHENPITA' },
    { label: '763|MALABE', value: '763|MALABE' },
    { label: '764|RAGAMA', value: '764|RAGAMA' },
    { label: '765|PUGODA', value: '765|PUGODA' },
    { label: '766|MOUNT LAVINIA', value: '766|MOUNT LAVINIA' },
    { label: '767|RANNA', value: '767|RANNA' },
    { label: '768|ALAWATUGODA', value: '768|ALAWATUGODA' },
    { label: '769|YAKKALA', value: '769|YAKKALA' },
    { label: '770|IBBAGAMUWA', value: '770|IBBAGAMUWA' },
    { label: '771|KANDANA', value: '771|KANDANA' },
    { label: '772|HEMMATHAGAMA', value: '772|HEMMATHAGAMA' },
    { label: '773|KOTTAWA', value: '773|KOTTAWA' },
    { label: '774|ANGUNAKOLAPELESSA', value: '774|ANGUNAKOLAPELESSA' },
    { label: '775|VISHAKA BRANCH', value: '775|VISHAKA BRANCH' },
    { label: '776|ISLAMIC BANKING UNIT', value: '776|ISLAMIC BANKING UNIT' },
    { label: '777|ELECTRONIC BANKING UNIT', value: '777|ELECTRONIC BANKING UNIT' },
    { label: '778|ATCHUVELY', value: '778|ATCHUVELY' },
    { label: '779|NOROCHCHOLAI', value: '779|NOROCHCHOLAI' },
    { label: '780|KADAWATHA 2ND CITY', value: '780|KADAWATHA 2ND CITY' },
    { label: '781|TELDENIYA', value: '781|TELDENIYA' },
    { label: '782|RAMBEWA', value: '782|RAMBEWA' },
    { label: '783|POLPITHIGAMA', value: '783|POLPITHIGAMA' },
    { label: '784|DEIYANDARA', value: '784|DEIYANDARA' },
    { label: '785|HALIELA', value: '785|HALIELA' },
    { label: '786|GODAKAWELA', value: '786|GODAKAWELA' },
    { label: '787|KOPAY', value: '787|KOPAY' },
    { label: '788|BOC PREMIER', value: '788|BOC PREMIER' },
    { label: '789|MAKOLA', value: '789|MAKOLA' },
    { label: '790|ERAVUR', value: '790|ERAVUR' },
    { label: '791|VALVETTITHURAI', value: '791|VALVETTITHURAI' },
    { label: '792|CHANKANAI', value: '792|CHANKANAI' },
    { label: '793|VAVUNIA CITY', value: '793|VAVUNIA CITY' },
    { label: '794|URUMPIRAI', value: '794|URUMPIRAI' },
    { label: '795|BORAGAS', value: '795|BORAGAS' },
    { label: '796|MATTALA AIRPORT', value: '796|MATTALA AIRPORT' },
    { label: '797|MEDAWALA', value: '797|MEDAWALA' },
    { label: '798|THALDUWA', value: '798|THALDUWA' },
    { label: '799|NELUNDENIYA', value: '799|NELUNDENIYA' },
    { label: '800|WANDURAMBA', value: '800|WANDURAMBA' },
    { label: '802|P C COMPLEX PALLAKELLE', value: '802|P C COMPLEX PALLAKELLE' },
    { label: '803|WELIOYA- SAMPATH NUWARA', value: '803|WELIOYA- SAMPATH NUWARA' },
    { label: '804|VADDUKODDAI', value: '804|VADDUKODDAI' },
    { label: '805|MADAWAKKULAMA', value: '805|MADAWAKKULAMA' },
    { label: '806|MAHAOYA', value: '806|MAHAOYA' },
    { label: '808|BOGASWEWA', value: '808|BOGASWEWA' },
    { label: '809|KURUNDUWATTE', value: '809|KURUNDUWATTE' },
    { label: '810|ETHIMALE', value: '810|ETHIMALE' },
    { label: '811|CENTRAL CAMP', value: '811|CENTRAL CAMP' },
    { label: '812|ALADENIYA', value: '812|ALADENIYA' },
    { label: '813|KOTAHALAWALA DEFENCE UNIVERSIT', value: '813|KOTAHALAWALA DEFENCE UNIVERSIT' },
    { label: '814|SALIYAWEWA', value: '814|SALIYAWEWA' },
    { label: '815|WAHALKADA', value: '815|WAHALKADA' },
    { label: '816|PALLEBEDDA', value: '816|PALLEBEDDA' },
    { label: '817|WELIKANDA', value: '817|WELIKANDA' },
    { label: '818|NAGODA', value: '818|NAGODA' },
    { label: '819|OLD MOOR STREET', value: '819|OLD MOOR STREET' },
    { label: '820|UNAWATUNA', value: '820|UNAWATUNA' },
    { label: '821|DOMBAGAHAWELA', value: '821|DOMBAGAHAWELA' },
    { label: '822|CORPORATE 2ND', value: '822|CORPORATE 2ND' },
    { label: '823|SEWANAGALA', value: '823|SEWANAGALA' },
    { label: '824|ANDIYAGALA', value: '824|ANDIYAGALA' },
    { label: '825|LAGGALA', value: '825|LAGGALA' },
    { label: '826|DUNAGAHA', value: '826|DUNAGAHA' },
    { label: '827|KANDAKETIYA', value: '827|KANDAKETIYA' },
    { label: '999|RUPEE DRAFT CLEARING', value: '999|RUPEE DRAFT CLEARING' },
  ],
  'STANDARD CHARTED BANK': [
    { label: '001|COLOMBO', value: '001|COLOMBO' },
    { label: '002|ONE GALLE FACE', value: '002|ONE GALLE FACE' },
    { label: '003|WELAWATTA', value: '003|WELAWATTA' },
    { label: '004|KIRIBATHGODA', value: '004|KIRIBATHGODA' },
    { label: '005|KIRILOPONE', value: '005|KIRILOPONE' },
    { label: '006|MORATUWA', value: '006|MORATUWA' },
    { label: '007|RAJAGIRIYA', value: '007|RAJAGIRIYA' },
    { label: '011|PRIORITY BANKING CENTRE', value: '011|PRIORITY BANKING CENTRE' },
    { label: '015|KOTAHENA/RUPEE DRAFT CLEARING', value: '015|KOTAHENA/RUPEE DRAFT CLEARING' },
    { label: '999|SCB HEAD OFFICE', value: '999|SCB HEAD OFFICE' },
  ],
  'CITI BANK N. A.': [
    { label: '001|HEAD OFFICE', value: '001|HEAD OFFICE' },
    { label: '100|CASH MGMT UNIT', value: '100|CASH MGMT UNIT' },
    { label: '111|RUPEE DRAFT CLEARING', value: '111|RUPEE DRAFT CLEARING' },
    { label: '999|HEAD OFFICE', value: '999|HEAD OFFICE' },
  ],
  'COMMERCIAL BANK OF CEYLON PLC': [
    { label: '001|HEAD OFFICE', value: '001|HEAD OFFICE' },
    { label: '002|CITY OFFICE', value: '002|CITY OFFICE' },
    { label: '003|FOREIGN', value: '003|FOREIGN' },
    { label: '004|KANDY', value: '004|KANDY' },
    { label: '005|GALLE', value: '005|GALLE' },
    { label: '006|JAFFNA', value: '006|JAFFNA' },
    { label: '007|MATARA', value: '007|MATARA' },
    { label: '008|MATALE', value: '008|MATALE' },
    { label: '009|GALEWELA', value: '009|GALEWELA' },
    { label: '010|WELLAWATTE', value: '010|WELLAWATTE' },
    { label: '011|KOLLUPITIYA', value: '011|KOLLUPITIYA' },
    { label: '012|KOTAHENA', value: '012|KOTAHENA' },
    { label: '013|NEGOMBO', value: '013|NEGOMBO' },
    { label: '014|HIKKADUWA', value: '014|HIKKADUWA' },
    { label: '015|HINGURAKKGODA', value: '015|HINGURAKKGODA' },
    { label: '016|KURUNEGALA', value: '016|KURUNEGALA' },
    { label: '017|OLD MOOR STREET', value: '017|OLD MOOR STREET' },
    { label: '018|MAHARAGAMA', value: '018|MAHARAGAMA' },
    { label: '019|BORELLA', value: '019|BORELLA' },
    { label: '020|NUGEGODA', value: '020|NUGEGODA' },
    { label: '021|KEGALLE', value: '021|KEGALLE' },
    { label: '022|NARAHENPITA', value: '022|NARAHENPITA' },
    { label: '023|MUTUWAL', value: '023|MUTUWAL' },
    { label: '024|PETTAH', value: '024|PETTAH' },
    { label: '025|KATUNAYAKA', value: '025|KATUNAYAKA' },
    { label: '026|WENNAPPUWA', value: '026|WENNAPPUWA' },
    { label: '027|GALLE CITY', value: '027|GALLE CITY' },
    { label: '028|KOGGALA', value: '028|KOGGALA' },
    { label: '029|BATTARAMULLA', value: '029|BATTARAMULLA' },
    { label: '030|EMBILIPITIYA', value: '030|EMBILIPITIYA' },
    { label: '031|KANDANA', value: '031|KANDANA' },
    { label: '032|MARADANA', value: '032|MARADANA' },
    { label: '033|MINUWANGODA', value: '033|MINUWANGODA' },
    { label: '034|NUWARA ELIYA', value: '034|NUWARA ELIYA' },
    { label: '035|AKURESSA', value: '035|AKURESSA' },
    { label: '036|KALUTARA', value: '036|KALUTARA' },
    { label: '037|TRINCOMALEE', value: '037|TRINCOMALEE' },
    { label: '038|PANCHIKAWATTE', value: '038|PANCHIKAWATTE' },
    { label: '039|KEYZER STREET', value: '039|KEYZER STREET' },
    { label: '040|ALUTHGAMA', value: '040|ALUTHGAMA' },
    { label: '041|PANADURA', value: '041|PANADURA' },
    { label: '042|KADUWELA', value: '042|KADUWELA' },
    { label: '043|CHILAW', value: '043|CHILAW' },
    { label: '044|GAMPAHA', value: '044|GAMPAHA' },
    { label: '045|KATUGASTOTA', value: '045|KATUGASTOTA' },
    { label: '046|RATMALANA', value: '046|RATMALANA' },
    { label: '047|KIRULAPANA', value: '047|KIRULAPANA' },
    { label: '048|UNION PLACE', value: '048|UNION PLACE' },
    { label: '049|RATNAPURA', value: '049|RATNAPURA' },
    { label: '050|COLOMBO 7', value: '050|COLOMBO 7' },
    { label: '051|KULIYAPITIYA', value: '051|KULIYAPITIYA' },
    { label: '052|BADULLA', value: '052|BADULLA' },
    { label: '053|ANURADHAPURA', value: '053|ANURADHAPURA' },
    { label: '054|DABULLA', value: '054|DABULLA' },
    { label: '055|NATTANDIYA', value: '055|NATTANDIYA' },
    { label: '056|WATTALA', value: '056|WATTALA' },
    { label: '057|GRANDPASS', value: '057|GRANDPASS' },
    { label: '058|DEHIWALA', value: '058|DEHIWALA' },
    { label: '059|MORATUWA', value: '059|MORATUWA' },
    { label: '060|NARAMMALA', value: '060|NARAMMALA' },
    { label: '061|VAVUNIYA', value: '061|VAVUNIYA' },
    { label: '062|RAJAGIRIYA', value: '062|RAJAGIRIYA' },
    { label: '063|AMBALANTOTA', value: '063|AMBALANTOTA' },
    { label: '064|SEEDUWA', value: '064|SEEDUWA' },
    { label: '065|NITTAMBUWA', value: '065|NITTAMBUWA' },
    { label: '066|MEERIGAMA', value: '066|MEERIGAMA' },
    { label: '067|KADAWATHA', value: '067|KADAWATHA' },
    { label: '068|DUPLICATION ROAD', value: '068|DUPLICATION ROAD' },
    { label: '069|KIRIBATHGODA', value: '069|KIRIBATHGODA' },
    { label: '070|AVISSAWELLA', value: '070|AVISSAWELLA' },
    { label: '071|EKALA', value: '071|EKALA' },
    { label: '072|PETTAH MAIN STREET', value: '072|PETTAH MAIN STREET' },
    { label: '073|PERADENIYA', value: '073|PERADENIYA' },
    { label: '074|KOCHCHIKADE', value: '074|KOCHCHIKADE' },
    { label: '075|HOMAGAMA', value: '075|HOMAGAMA' },
    { label: '076|HORANA', value: '076|HORANA' },
    { label: '077|PILIYANDALA', value: '077|PILIYANDALA' },
    { label: '078|THALAWATHUGODA', value: '078|THALAWATHUGODA' },
    { label: '079|MAWANELLA', value: '079|MAWANELLA' },
    { label: '080|BANDARAWELA', value: '080|BANDARAWELA' },
    { label: '081|JA-ELA', value: '081|JA-ELA' },
    { label: '082|BALANGODA', value: '082|BALANGODA' },
    { label: '083|NIKAWERATIYA', value: '083|NIKAWERATIYA' },
    { label: '084|BANDARAGAMA', value: '084|BANDARAGAMA' },
    { label: '085|YAKKALA', value: '085|YAKKALA' },
    { label: '086|MALABE', value: '086|MALABE' },
    { label: '087|KOHUWALA', value: '087|KOHUWALA' },
    { label: '088|KADURUWELA', value: '088|KADURUWELA' },
    { label: '089|NAWALAPITIYA', value: '089|NAWALAPITIYA' },
    { label: '093|MOUNT LAVINIA', value: '093|MOUNT LAVINIA' },
    { label: '095|CARD CENTER', value: '095|CARD CENTER' },
    { label: '096|MATHUGAMA', value: '096|MATHUGAMA' },
    { label: '097|AMBALANGODA', value: '097|AMBALANGODA' },
    { label: '098|BADDEGAMA', value: '098|BADDEGAMA' },
    { label: '100|AMPARA', value: '100|AMPARA' },
    { label: '101|NAWALA', value: '101|NAWALA' },
    { label: '102|GAMPOLA', value: '102|GAMPOLA' },
    { label: '103|ELPITIYA', value: '103|ELPITIYA' },
    { label: '104|KAMBURUPITIYA', value: '104|KAMBURUPITIYA' },
    { label: '105|BATTICALOA', value: '105|BATTICALOA' },
    { label: '106|BAMBALAPITIYA', value: '106|BAMBALAPITIYA' },
    { label: '107|CHUNAKKAM', value: '107|CHUNAKKAM' },
    { label: '108|NELLIADY', value: '108|NELLIADY' },
    { label: '109|PILIMATHALAWA', value: '109|PILIMATHALAWA' },
    { label: '110|KEKIRAWA', value: '110|KEKIRAWA' },
    { label: '111|DENIYAYA', value: '111|DENIYAYA' },
    { label: '112|WELIGAMA', value: '112|WELIGAMA' },
    { label: '113|BASELINE ROAD', value: '113|BASELINE ROAD' },
    { label: '114|KATUBEDDA SERVICE POINT', value: '114|KATUBEDDA SERVICE POINT' },
    { label: '115|HATTON', value: '115|HATTON' },
    { label: '116|REID AVENUE', value: '116|REID AVENUE' },
    { label: '117|PITA KOTTE', value: '117|PITA KOTTE' },
    { label: '118|NEGOMBO SECOND', value: '118|NEGOMBO SECOND' },
    { label: '119|KOTIKAWATTA', value: '119|KOTIKAWATTA' },
    { label: '120|MONARAGALA', value: '120|MONARAGALA' },
    { label: '121|KOTTAWA', value: '121|KOTTAWA' },
    { label: '122|KURUNEGALA CITY OFFICE', value: '122|KURUNEGALA CITY OFFICE' },
    { label: '123|TANGALLE', value: '123|TANGALLE' },
    { label: '124|TISSAMAHARAMA', value: '124|TISSAMAHARAMA' },
    { label: '125|NELUWA', value: '125|NELUWA' },
    { label: '126|CHAVAKACHCHERI', value: '126|CHAVAKACHCHERI' },
    { label: '127|JAFFNA CUSTOMER SERVICE', value: '127|JAFFNA CUSTOMER SERVICE' },
    { label: '128|WARAKAPOLA', value: '128|WARAKAPOLA' },
    { label: '129|UDUGAMA', value: '129|UDUGAMA' },
    { label: '130|ATHURUGIRIYA', value: '130|ATHURUGIRIYA' },
    { label: '131|RADDOLUGAMAA', value: '131|RADDOLUGAMAA' },
    { label: '132|BORALESGAMUWA (LAUGFS SUPER)', value: '132|BORALESGAMUWA (LAUGFS SUPER)' },
    { label: '133|KAHAWATTA', value: '133|KAHAWATTA' },
    { label: '134|DELKANDA', value: '134|DELKANDA' },
    { label: '135|KARAPITIYA', value: '135|KARAPITIYA' },
    { label: '136|WELIMADA', value: '136|WELIMADA' },
    { label: '137|MAHIYANGANAYA', value: '137|MAHIYANGANAYA' },
    { label: '138|KALAWANA', value: '138|KALAWANA' },
    { label: '139|KIRIDIWELA', value: '139|KIRIDIWELA' },
    { label: '140|DIGANA', value: '140|DIGANA' },
    { label: '141|POLGAHAWELA', value: '141|POLGAHAWELA' },
    { label: '142|BORALESGAMUWA', value: '142|BORALESGAMUWA' },
    { label: '143|HANWELLA', value: '143|HANWELLA' },
    { label: '144|PANNALA', value: '144|PANNALA' },
    { label: '145|WARD PLACE', value: '145|WARD PLACE' },
    { label: '146|WADDUWA', value: '146|WADDUWA' },
    { label: '147|BIYAGAMA', value: '147|BIYAGAMA' },
    { label: '148|PUTTALAM', value: '148|PUTTALAM' },
    { label: '149|PELMADULLA', value: '149|PELMADULLA' },
    { label: '150|KANDY CITY OFFICE', value: '150|KANDY CITY OFFICE' },
    { label: '151|MATARA CITY OFFICE', value: '151|MATARA CITY OFFICE' },
    { label: '152|KALMUNAI', value: '152|KALMUNAI' },
    { label: '153|MANIPAY', value: '153|MANIPAY' },
    { label: '154|MANNAR', value: '154|MANNAR' },
    { label: '155|KILINOCHCHI', value: '155|KILINOCHCHI' },
    { label: '156|ATCHCHUVELY', value: '156|ATCHCHUVELY' },
    { label: '157|THIRUNELVELY', value: '157|THIRUNELVELY' },
    { label: '158|EHELIYAGODA', value: '158|EHELIYAGODA' },
    { label: '159|VALACHCHENAI', value: '159|VALACHCHENAI' },
    { label: '160|WELLAWAYA', value: '160|WELLAWAYA' },
    { label: '161|MAWATHAGAMA', value: '161|MAWATHAGAMA' },
    { label: '162|THAMBUTTEGAMA', value: '162|THAMBUTTEGAMA' },
    { label: '163|RUWANWELLA', value: '163|RUWANWELLA' },
    { label: '164|DANKOTUWA', value: '164|DANKOTUWA' },
    { label: '165|PELIYAGODA', value: '165|PELIYAGODA' },
    { label: '166|HAMBANTOTA', value: '166|HAMBANTOTA' },
    { label: '167|KATUBEDDA (MINICOM)', value: '167|KATUBEDDA (MINICOM)' },
    { label: '168|ERAVUR', value: '168|ERAVUR' },
    { label: '169|ELITE BRANCH', value: '169|ELITE BRANCH' },
    { label: '170|VELANAI', value: '170|VELANAI' },
    { label: '171|VAVUNIA EXTENSION OFFICE', value: '171|VAVUNIA EXTENSION OFFICE' },
    { label: '172|AKKARAIPATTU', value: '172|AKKARAIPATTU' },
    { label: '173|KATARAGAMA', value: '173|KATARAGAMA' },
    { label: '174|WARIYAPOLA', value: '174|WARIYAPOLA' },
    { label: '175|KURUWITA', value: '175|KURUWITA' },
    { label: '176|MIDDENIYA', value: '176|MIDDENIYA' },
    { label: '177|GANEMULLA', value: '177|GANEMULLA' },
    { label: '178|WORLD TRADE CENTRE', value: '178|WORLD TRADE CENTRE' },
    { label: '179|WELLAWATTE CSP', value: '179|WELLAWATTE CSP' },
    { label: '180|DIVULAPITIYA', value: '180|DIVULAPITIYA' },
    { label: '181|BELIATTA', value: '181|BELIATTA' },
    { label: '182|GIRIULLA', value: '182|GIRIULLA' },
    { label: '183|MARAWILA CSP', value: '183|MARAWILA CSP' },
    { label: '184|THALAWAKELLE', value: '184|THALAWAKELLE' },
    { label: '185|ANURADHAPURA NEW TOWN', value: '185|ANURADHAPURA NEW TOWN' },
    { label: '186|PASSARA', value: '186|PASSARA' },
    { label: '187|PADUKKA CSP', value: '187|PADUKKA CSP' },
    { label: '188|ALAWWA', value: '188|ALAWWA' },
    { label: '189|PANADURA SECOND BRANCH', value: '189|PANADURA SECOND BRANCH' },
    { label: '190|KATUNAYAKE 24/7 CENTRE', value: '190|KATUNAYAKE 24/7 CENTRE' },
    { label: '193|KATTANKUDY', value: '193|KATTANKUDY' },
    { label: '194|POTTUVIL', value: '194|POTTUVIL' },
    { label: '195|MASKELIYA', value: '195|MASKELIYA' },
    { label: '196|KOLLUPITIYA (LIBERTY PLAZA)', value: '196|KOLLUPITIYA (LIBERTY PLAZA)' },
    { label: '197|GODAKAWELA', value: '197|GODAKAWELA' },
    { label: '198|KODIKAMAM', value: '198|KODIKAMAM' },
    { label: '199|MAKOLA CSP', value: '199|MAKOLA CSP' },
    { label: '200|MEDAWACHCHIYA', value: '200|MEDAWACHCHIYA' },
    { label: '204|SLIC CSP', value: '204|SLIC CSP' },
    { label: '206|PALAVI', value: '206|PALAVI' },
    { label: '208|NAWALA (MINICOM)', value: '208|NAWALA (MINICOM)' },
    { label: '209|MAHARAGAMA (LAUGFS SUPER)', value: '209|MAHARAGAMA (LAUGFS SUPER)' },
    { label: '210|MORATUWA (LAUGFS SUPER)', value: '210|MORATUWA (LAUGFS SUPER)' },
    { label: '211|UNION PLACE(KEELS SUPER)', value: '211|UNION PLACE(KEELS SUPER)' },
    { label: '212|KURUNEGALA (MINICOM)', value: '212|KURUNEGALA (MINICOM)' },
    { label: '213|RATNAPURA (MINICOM)', value: '213|RATNAPURA (MINICOM)' },
    { label: '214|HOLIDAY BANKING CENTRE-MC', value: '214|HOLIDAY BANKING CENTRE-MC' },
    { label: '215|COMMERCIAL RUPEE DRAFT CLEARIN', value: '215|COMMERCIAL RUPEE DRAFT CLEARIN' },
    { label: '216|RAMANAYAKA MAWATHA', value: '216|RAMANAYAKA MAWATHA' },
    { label: '217|KIRULAPONE MINICOM', value: '217|KIRULAPONE MINICOM' },
    { label: '218|GAMPAHA (MINICOM)', value: '218|GAMPAHA (MINICOM)' },
    { label: '219|AKURANA (MINICOM)', value: '219|AKURANA (MINICOM)' },
    { label: '220|BERUWELA (MINICOM)', value: '220|BERUWELA (MINICOM)' },
    { label: '221|RAGAMA MINICOM', value: '221|RAGAMA MINICOM' },
    { label: '222|MATARA (KEELLS SUPER)', value: '222|MATARA (KEELLS SUPER)' },
    { label: '223|PANADURA (KEELLS SUPER)', value: '223|PANADURA (KEELLS SUPER)' },
    { label: '224|HORANA (WIJEMANNA SUPER)', value: '224|HORANA (WIJEMANNA SUPER)' },
    { label: '225|WATTALA MINICOM', value: '225|WATTALA MINICOM' },
    { label: '226|BOKUNDARA (MINICOM)', value: '226|BOKUNDARA (MINICOM)' },
    { label: '227|KATUKURUNDA (MINICOM)', value: '227|KATUKURUNDA (MINICOM)' },
    { label: '228|WELIWERIYA CSP', value: '228|WELIWERIYA CSP' },
    { label: '229|MULLIYAWALAI', value: '229|MULLIYAWALAI' },
    { label: '230|JA ELA (MINICOM)', value: '230|JA ELA (MINICOM)' },
    { label: '231|ATTIDIYA', value: '231|ATTIDIYA' },
    { label: '232|HULFTSDORP', value: '232|HULFTSDORP' },
    { label: '233|RAJAGIRIYA(KEELS SUPER)', value: '233|RAJAGIRIYA(KEELS SUPER)' },
    { label: '234|KADAWATHA(ARPICO SUPER)', value: '234|KADAWATHA(ARPICO SUPER)' },
    { label: '236|URUBOKKA', value: '236|URUBOKKA' },
    { label: '237|COLOMBO GOLD CENTRE', value: '237|COLOMBO GOLD CENTRE' },
    { label: '238|NAWINNA (ARPICO SUPER)', value: '238|NAWINNA (ARPICO SUPER)' },
    { label: '239|CHANKANAI', value: '239|CHANKANAI' },
    { label: '240|BATAPOLA', value: '240|BATAPOLA' },
    { label: '241|NOCHCHIYAGAMA', value: '241|NOCHCHIYAGAMA' },
    { label: '242|BATTICALOA SECOND', value: '242|BATTICALOA SECOND' },
    { label: '244|BADULLA SECOND', value: '244|BADULLA SECOND' },
    { label: '245|KATUGASTOTA (MINICOM)', value: '245|KATUGASTOTA (MINICOM)' },
    { label: '246|WATTEGAMA', value: '246|WATTEGAMA' },
    { label: '247|BANDARAWATTA (LAUGFS SUPER)', value: '247|BANDARAWATTA (LAUGFS SUPER)' },
    { label: '248|WENNAPUWA(ARPICO)SUPER', value: '248|WENNAPUWA(ARPICO)SUPER' },
    { label: '249|BIA COUNTER', value: '249|BIA COUNTER' },
    { label: '250|VEYANGODA', value: '250|VEYANGODA' },
    { label: '251|DEHIWELA ARP.S.CENTRE', value: '251|DEHIWELA ARP.S.CENTRE' },
    { label: '252|BATTARAMULLA ARP.S.CENTRE', value: '252|BATTARAMULLA ARP.S.CENTRE' },
    { label: '253|HYDE PARK ARP.S.CENTRE', value: '253|HYDE PARK ARP.S.CENTRE' },
    { label: '254|ANIWATTA (NIHALS SUPER)', value: '254|ANIWATTA (NIHALS SUPER)' },
    { label: '255|KUNDASALE', value: '255|KUNDASALE' },
    { label: '256|NEGOMBO - ARPICO', value: '256|NEGOMBO - ARPICO' },
    { label: '257|KIRIBATHGODA (LAUGFS SUPER)', value: '257|KIRIBATHGODA (LAUGFS SUPER)' },
    { label: '258|BAMBALAPITIYA (MAJESTIC CITY)', value: '258|BAMBALAPITIYA (MAJESTIC CITY)' },
    { label: '259|NAWAM MAWATHA CSP', value: '259|NAWAM MAWATHA CSP' },
    { label: '260|WATTALA ARP.S.CENTRE', value: '260|WATTALA ARP.S.CENTRE' },
    { label: '261|NITTAMBUWA (NIHAL SUPER)', value: '261|NITTAMBUWA (NIHAL SUPER)' },
    { label: '262|PELAWATTE', value: '262|PELAWATTE' },
    { label: '264|HENDALA', value: '264|HENDALA' },
    { label: '265|GELIOYA (ARPICO SUPER)', value: '265|GELIOYA (ARPICO SUPER)' },
    { label: '266|KOHUWALA (KEELLS SUPER)', value: '266|KOHUWALA (KEELLS SUPER)' },
    { label: '267|KALUTARA (ARPICO SUPER)', value: '267|KALUTARA (ARPICO SUPER)' },
    { label: '268|MATTEGODA LAUGFS SUPER BRANCH', value: '268|MATTEGODA LAUGFS SUPER BRANCH' },
    { label: '269|DELGODA LAUGFS SUPER', value: '269|DELGODA LAUGFS SUPER' },
    { label: '270|ORION CITY ABC', value: '270|ORION CITY ABC' },
    { label: '271|GALLE MAIN STREET', value: '271|GALLE MAIN STREET' },
    { label: '272|KILINOCHCHI  MINICOM', value: '272|KILINOCHCHI  MINICOM' },
    { label: '274|POINT PEDRO', value: '274|POINT PEDRO' },
    { label: '275|KELANIYA', value: '275|KELANIYA' },
    { label: '276|DEIYANDARA', value: '276|DEIYANDARA' },
    { label: '277|MORAWAKA', value: '277|MORAWAKA' },
    { label: '278|RAMBUKKANA', value: '278|RAMBUKKANA' },
    { label: '279|KATANA', value: '279|KATANA' },
    { label: '280|BOPITIYA', value: '280|BOPITIYA' },
    { label: '281|YATIYANTOTA', value: '281|YATIYANTOTA' },
    { label: '282|KOLONNAWA', value: '282|KOLONNAWA' },
    { label: '283|PITIGALA', value: '283|PITIGALA' },
    { label: '284|ENDERAMULLA', value: '284|ENDERAMULLA' },
    { label: '286|IBBAGAMUWA', value: '286|IBBAGAMUWA' },
    { label: '287|EMBULDENIYA', value: '287|EMBULDENIYA' },
    { label: '288|TELDENIYA', value: '288|TELDENIYA' },
    { label: '289|HETTIPOLA', value: '289|HETTIPOLA' },
    { label: '293|MADAMPE', value: '293|MADAMPE' },
    { label: '901|ISLAMIC BANKING UNIT', value: '901|ISLAMIC BANKING UNIT' },
    { label: '999|HEAD OFFICE', value: '999|HEAD OFFICE' },
  ],
  'HABIB BANK LIMITED': [
    { label: '001|MAIN', value: '001|MAIN' },
    { label: '002|LAKE VIEW', value: '002|LAKE VIEW' },
    { label: '003|KALMUNAI', value: '003|KALMUNAI' },
    { label: '005|KANDY', value: '005|KANDY' },
    { label: '006|GALLE', value: '006|GALLE' },
    { label: '007|WELLAWATTE', value: '007|WELLAWATTE' },
    { label: '008|RUPEE DRAFT CLEARING', value: '008|RUPEE DRAFT CLEARING' },
    { label: '021|ISLAMIC BANKING', value: '021|ISLAMIC BANKING' },
    { label: '999|LAKE VIEW', value: '999|LAKE VIEW' },
  ],
  'HATTON NATIONAL BANK PLC': [
    { label: '001|ALUTHKADE', value: '001|ALUTHKADE' },
    { label: '002|CITY OFFICE', value: '002|CITY OFFICE' },
    { label: '003|HEAD OFFICE', value: '003|HEAD OFFICE' },
    { label: '004|HEAD OFFICE', value: '004|HEAD OFFICE' },
    { label: '005|GRREN PATHH', value: '005|GRREN PATHH' },
    { label: '006|MALIGAWATTA', value: '006|MALIGAWATTA' },
    { label: '007|PETTAH', value: '007|PETTAH' },
    { label: '009|WELLAWATTE', value: '009|WELLAWATTE' },
    { label: '010|ANURADHAPURA', value: '010|ANURADHAPURA' },
    { label: '011|BADULLA', value: '011|BADULLA' },
    { label: '012|BANDARAWELA', value: '012|BANDARAWELA' },
    { label: '013|GALLE', value: '013|GALLE' },
    { label: '014|GAMPOLA', value: '014|GAMPOLA' },
    { label: '015|HATTON', value: '015|HATTON' },
    { label: '016|JAFFNA METRO', value: '016|JAFFNA METRO' },
    { label: '017|KAHAWATTE', value: '017|KAHAWATTE' },
    { label: '018|KANDY', value: '018|KANDY' },
    { label: '019|KURUNEGALA', value: '019|KURUNEGALA' },
    { label: '020|MANNAR', value: '020|MANNAR' },
    { label: '021|MASKELIYA', value: '021|MASKELIYA' },
    { label: '022|MORATUWA', value: '022|MORATUWA' },
    { label: '023|NAWALAPITIYA', value: '023|NAWALAPITIYA' },
    { label: '024|NEGOMBO', value: '024|NEGOMBO' },
    { label: '025|NITTAMBUWA', value: '025|NITTAMBUWA' },
    { label: '026|NOCHIYAGAMA', value: '026|NOCHIYAGAMA' },
    { label: '027|NUGEGODA', value: '027|NUGEGODA' },
    { label: '028|NUWARA ELIYA', value: '028|NUWARA ELIYA' },
    { label: '029|PUSSELLAWA', value: '029|PUSSELLAWA' },
    { label: '030|RATNAPURA', value: '030|RATNAPURA' },
    { label: '031|TRINCOMALEE', value: '031|TRINCOMALEE' },
    { label: '032|VAVUNIYA', value: '032|VAVUNIYA' },
    { label: '033|WELIMADA', value: '033|WELIMADA' },
    { label: '034|KALUTARA', value: '034|KALUTARA' },
    { label: '035|WATTALA', value: '035|WATTALA' },
    { label: '036|RAJAGIRIYA', value: '036|RAJAGIRIYA' },
    { label: '038|PILIYANDALA CUSTOMER CENTRE', value: '038|PILIYANDALA CUSTOMER CENTRE' },
    { label: '039|BAMBALAPITIYA', value: '039|BAMBALAPITIYA' },
    { label: '040|CHILAW', value: '040|CHILAW' },
    { label: '041|KEGALLE', value: '041|KEGALLE' },
    { label: '042|MATARA', value: '042|MATARA' },
    { label: '043|KIRILLAPONE', value: '043|KIRILLAPONE' },
    { label: '044|POLONNARUWA', value: '044|POLONNARUWA' },
    { label: '045|AMBALANTHOTA', value: '045|AMBALANTHOTA' },
    { label: '046|GRANDPASS', value: '046|GRANDPASS' },
    { label: '047|BIYAGAMA', value: '047|BIYAGAMA' },
    { label: '048|DAMBULLA', value: '048|DAMBULLA' },
    { label: '049|KATUNAYAKE', value: '049|KATUNAYAKE' },
    { label: '050|EMBILIPITIYA', value: '050|EMBILIPITIYA' },
    { label: '051|GAMPAHA', value: '051|GAMPAHA' },
    { label: '052|HORANA', value: '052|HORANA' },
    { label: '053|MONERAGALA', value: '053|MONERAGALA' },
    { label: '054|RUPEE DRAFT CLEARING', value: '054|RUPEE DRAFT CLEARING' },
    { label: '055|BORELLA', value: '055|BORELLA' },
    { label: '056|KIRIBATHGODA', value: '056|KIRIBATHGODA' },
    { label: '057|BATIICALOA', value: '057|BATIICALOA' },
    { label: '058|AMPARA', value: '058|AMPARA' },
    { label: '059|PANCHIKAWATTA', value: '059|PANCHIKAWATTA' },
    { label: '060|BOGAWANTALAWA', value: '060|BOGAWANTALAWA' },
    { label: '061|MOUNT LAVINIA', value: '061|MOUNT LAVINIA' },
    { label: '063|HULFTSDORP', value: '063|HULFTSDORP' },
    { label: '064|MAHARAGAMA', value: '064|MAHARAGAMA' },
    { label: '065|MATALE', value: '065|MATALE' },
    { label: '066|PINNAWELLA', value: '066|PINNAWELLA' },
    { label: '067|SURIYAWEWA', value: '067|SURIYAWEWA' },
    { label: '068|HAMBANTOTA', value: '068|HAMBANTOTA' },
    { label: '069|PANADURA', value: '069|PANADURA' },
    { label: '070|DANKOTUWA', value: '070|DANKOTUWA' },
    { label: '071|BALANGODA', value: '071|BALANGODA' },
    { label: '072|SEA STREET PETTAH', value: '072|SEA STREET PETTAH' },
    { label: '073|MORATUMULLA', value: '073|MORATUMULLA' },
    { label: '074|KULIYAPITIYA', value: '074|KULIYAPITIYA' },
    { label: '075|BUTTALA', value: '075|BUTTALA' },
    { label: '076|CINNAMON GARDENS', value: '076|CINNAMON GARDENS' },
    { label: '077|HOMAGAMA', value: '077|HOMAGAMA' },
    { label: '078|AKKARAIPATTU', value: '078|AKKARAIPATTU' },
    { label: '079|MARAGAHAMULLA', value: '079|MARAGAHAMULLA' },
    { label: '080|MARAWILA', value: '080|MARAWILA' },
    { label: '081|AMBALANGODA', value: '081|AMBALANGODA' },
    { label: '082|KADUWELA', value: '082|KADUWELA' },
    { label: '083|PUTTALAM', value: '083|PUTTALAM' },
    { label: '084|KADAWATHA', value: '084|KADAWATHA' },
    { label: '085|TALANGAMA', value: '085|TALANGAMA' },
    { label: '086|TANGALLE', value: '086|TANGALLE' },
    { label: '087|JA-ELA', value: '087|JA-ELA' },
    { label: '088|THABUTTEGAMA', value: '088|THABUTTEGAMA' },
    { label: '089|MAWANELLA', value: '089|MAWANELLA' },
    { label: '090|TISSAMAHARAMA', value: '090|TISSAMAHARAMA' },
    { label: '091|KALMUNAI', value: '091|KALMUNAI' },
    { label: '092|THIMBIRIGASYAYA', value: '092|THIMBIRIGASYAYA' },
    { label: '093|DEHIWELA', value: '093|DEHIWELA' },
    { label: '094|MINUWANGODA', value: '094|MINUWANGODA' },
    { label: '095|KANTALE', value: '095|KANTALE' },
    { label: '096|KOTAHENA', value: '096|KOTAHENA' },
    { label: '097|MUTWAL', value: '097|MUTWAL' },
    { label: '098|KOTTAWA', value: '098|KOTTAWA' },
    { label: '099|KIRINDIWELA', value: '099|KIRINDIWELA' },
    { label: '100|KATUGASTOTA', value: '100|KATUGASTOTA' },
    { label: '101|PELMADULLA', value: '101|PELMADULLA' },
    { label: '102|RAGAMA', value: '102|RAGAMA' },
    { label: '103|DEMATAGODA', value: '103|DEMATAGODA' },
    { label: '104|NARAHENPITA', value: '104|NARAHENPITA' },
    { label: '105|TREASURY', value: '105|TREASURY' },
    { label: '106|WELLAWAYA', value: '106|WELLAWAYA' },
    { label: '107|ELPITIYA', value: '107|ELPITIYA' },
    { label: '108|MARADANA', value: '108|MARADANA' },
    { label: '109|ALUTHGAMA', value: '109|ALUTHGAMA' },
    { label: '110|WENNAPPUWA', value: '110|WENNAPPUWA' },
    { label: '111|AVISSAWELLA', value: '111|AVISSAWELLA' },
    { label: '112|BORALESGAMUWA', value: '112|BORALESGAMUWA' },
    { label: '113|CARD CENTRE', value: '113|CARD CENTRE' },
    { label: '114|HNB CENTRAL COLOMBO', value: '114|HNB CENTRAL COLOMBO' },
    { label: '115|KOLLUPITIYA CUSTOMER CENTRE', value: '115|KOLLUPITIYA CUSTOMER CENTRE' },
    { label: '117|CHUNNAKAM BRANCH', value: '117|CHUNNAKAM BRANCH' },
    { label: '118|NELLIADY', value: '118|NELLIADY' },
    { label: '119|KANDANA', value: '119|KANDANA' },
    { label: '120|DENIYAYA CUSTOMER CENTRE', value: '120|DENIYAYA CUSTOMER CENTRE' },
    { label: '121|NIKAWERATIYA', value: '121|NIKAWERATIYA' },
    { label: '122|DELGODA', value: '122|DELGODA' },
    { label: '123|ALAWWA', value: '123|ALAWWA' },
    { label: '124|MAHIYANGANAYA', value: '124|MAHIYANGANAYA' },
    { label: '125|MATUGAMA', value: '125|MATUGAMA' },
    { label: '126|WARAKAPOLA', value: '126|WARAKAPOLA' },
    { label: '127|MIDDENIYA', value: '127|MIDDENIYA' },
    { label: '128|GALGAMUWA', value: '128|GALGAMUWA' },
    { label: '129|KOHUWELA', value: '129|KOHUWELA' },
    { label: '130|WELIWERIYA', value: '130|WELIWERIYA' },
    { label: '131|HENDALA', value: '131|HENDALA' },
    { label: '132|POINT PEDRO', value: '132|POINT PEDRO' },
    { label: '133|NOROCHCHOLE', value: '133|NOROCHCHOLE' },
    { label: '134|THIRUKOVILE', value: '134|THIRUKOVILE' },
    { label: '135|ERAVUR', value: '135|ERAVUR' },
    { label: '136|GANEMULLA', value: '136|GANEMULLA' },
    { label: '137|CHAVAKACHCHERI', value: '137|CHAVAKACHCHERI' },
    { label: '138|KELANIYA', value: '138|KELANIYA' },
    { label: '139|HANWELLA', value: '139|HANWELLA' },
    { label: '140|PADUKKA', value: '140|PADUKKA' },
    { label: '141|PILIMATALAWA', value: '141|PILIMATALAWA' },
    { label: '142|THALAWATHUGODA', value: '142|THALAWATHUGODA' },
    { label: '143|MEDAWACHCHIYA', value: '143|MEDAWACHCHIYA' },
    { label: '144|THIRUNELVELY', value: '144|THIRUNELVELY' },
    { label: '145|NEGOMBO', value: '145|NEGOMBO' },
    { label: '146|KILINOCHCHI', value: '146|KILINOCHCHI' },
    { label: '147|NAWALA', value: '147|NAWALA' },
    { label: '148|GIRIULLA', value: '148|GIRIULLA' },
    { label: '149|GALEWELA', value: '149|GALEWELA' },
    { label: '150|MANIPAY', value: '150|MANIPAY' },
    { label: '151|AKURESSA', value: '151|AKURESSA' },
    { label: '152|HETTIPOLA', value: '152|HETTIPOLA' },
    { label: '153|WARIYAPOLA', value: '153|WARIYAPOLA' },
    { label: '154|ATHURUGIRIYA', value: '154|ATHURUGIRIYA' },
    { label: '155|KOCHCHIKADE', value: '155|KOCHCHIKADE' },
    { label: '156|MALABE', value: '156|MALABE' },
    { label: '157|CHANKANAI', value: '157|CHANKANAI' },
    { label: '158|POTTUVIL', value: '158|POTTUVIL' },
    { label: '159|NINTHAVUR', value: '159|NINTHAVUR' },
    { label: '160|BERUWELA', value: '160|BERUWELA' },
    { label: '161|VELANAI', value: '161|VELANAI' },
    { label: '162|RIKILLAGASKADA', value: '162|RIKILLAGASKADA' },
    { label: '163|YAKKALA', value: '163|YAKKALA' },
    { label: '164|THANDENWELI', value: '164|THANDENWELI' },
    { label: '165|KALUWANCHIKUDY', value: '165|KALUWANCHIKUDY' },
    { label: '166|PUGODA', value: '166|PUGODA' },
    { label: '167|VALACHCHENAI', value: '167|VALACHCHENAI' },
    { label: '168|MADAMPE', value: '168|MADAMPE' },
    { label: '169|KINNIYA', value: '169|KINNIYA' },
    { label: '170|SIYABALANDUWA', value: '170|SIYABALANDUWA' },
    { label: '171|UDAPPUWA', value: '171|UDAPPUWA' },
    { label: '172|NANATTAN', value: '172|NANATTAN' },
    { label: '173|GINIGATHHENA', value: '173|GINIGATHHENA' },
    { label: '174|MULLIPOTHANAI', value: '174|MULLIPOTHANAI' },
    { label: '175|UPPUVELLI', value: '175|UPPUVELLI' },
    { label: '176|DIGANA', value: '176|DIGANA' },
    { label: '177|ANAMADUWA', value: '177|ANAMADUWA' },
    { label: '178|DIKWELLA', value: '178|DIKWELLA' },
    { label: '179|MEDIRIGIRIYA', value: '179|MEDIRIGIRIYA' },
    { label: '180|MIRIGAMA', value: '180|MIRIGAMA' },
    { label: '181|PADAVI PARAKRAMAPURA', value: '181|PADAVI PARAKRAMAPURA' },
    { label: '182|UHANA', value: '182|UHANA' },
    { label: '183|MULLATIVU', value: '183|MULLATIVU' },
    { label: '184|KARAITIVU', value: '184|KARAITIVU' },
    { label: '185|MARUTHAMUNAI', value: '185|MARUTHAMUNAI' },
    { label: '186|SERUNUWARA', value: '186|SERUNUWARA' },
    { label: '187|PITIGALA', value: '187|PITIGALA' },
    { label: '188|KUNDASALE', value: '188|KUNDASALE' },
    { label: '189|ATCHCHUVELY', value: '189|ATCHCHUVELY' },
    { label: '190|KODIKAMAM', value: '190|KODIKAMAM' },
    { label: '191|MUTHUR', value: '191|MUTHUR' },
    { label: '192|KALLADY', value: '192|KALLADY' },
    { label: '193|ARALAGANWILA', value: '193|ARALAGANWILA' },
    { label: '194|KOLONNAWA', value: '194|KOLONNAWA' },
    { label: '195|KILINOCHCHI 2ND BRANCH', value: '195|KILINOCHCHI 2ND BRANCH' },
    { label: '196|DEHIATTAKANDIYA', value: '196|DEHIATTAKANDIYA' },
    { label: '197|KALAWANA', value: '197|KALAWANA' },
    { label: '198|GALAHA', value: '198|GALAHA' },
    { label: '199|URUBOKKA', value: '199|URUBOKKA' },
    { label: '200|HAKMANA', value: '200|HAKMANA' },
    { label: '201|BANDARAGAMA', value: '201|BANDARAGAMA' },
    { label: '202|HIKKADUWA', value: '202|HIKKADUWA' },
    { label: '203|WADDUWA', value: '203|WADDUWA' },
    { label: '204|MIRIHANA', value: '204|MIRIHANA' },
    { label: '205|MULLIYAWALAI', value: '205|MULLIYAWALAI' },
    { label: '206|KURUMANKADU', value: '206|KURUMANKADU' },
    { label: '207|JAMPETTAH STREET', value: '207|JAMPETTAH STREET' },
    { label: '208|RATMALANA', value: '208|RATMALANA' },
    { label: '209|SEEDUWA', value: '209|SEEDUWA' },
    { label: '210|PAMUNUGAMA', value: '210|PAMUNUGAMA' },
    { label: '211|KATTANKUDY', value: '211|KATTANKUDY' },
    { label: '212|MALLAVI', value: '212|MALLAVI' },
    { label: '213|WELIGAMA', value: '213|WELIGAMA' },
    { label: '214|VEYANGODA', value: '214|VEYANGODA' },
    { label: '215|BATAPOLA', value: '215|BATAPOLA' },
    { label: '216|YAKKALAMULLA', value: '216|YAKKALAMULLA' },
    { label: '217|WALASMULLA', value: '217|WALASMULLA' },
    { label: '218|GELIOYA', value: '218|GELIOYA' },
    { label: '219|JAFFNA', value: '219|JAFFNA' },
    { label: '220|PASSARA', value: '220|PASSARA' },
    { label: '221|PAMANKADA', value: '221|PAMANKADA' },
    { label: '222|SAMMANTHURAI', value: '222|SAMMANTHURAI' },
    { label: '223|PERADENIYA', value: '223|PERADENIYA' },
    { label: '224|KURUNEGALA METRO', value: '224|KURUNEGALA METRO' },
    { label: '225|TRINCOMALEE METRO', value: '225|TRINCOMALEE METRO' },
    { label: '226|WIJERAMA', value: '226|WIJERAMA' },
    { label: '227|KARAPITIYA', value: '227|KARAPITIYA' },
    { label: '228|PELIYAGODA', value: '228|PELIYAGODA' },
    { label: '229|DIVULAPITIYA', value: '229|DIVULAPITIYA' },
    { label: '230|EKALA', value: '230|EKALA' },
    { label: '231|KEKIRAWA', value: '231|KEKIRAWA' },
    { label: '232|ANURADHAPURA METRO', value: '232|ANURADHAPURA METRO' },
    { label: '233|BIBILE', value: '233|BIBILE' },
    { label: '234|HAPUTALE CUSTOMER CENTRE', value: '234|HAPUTALE CUSTOMER CENTRE' },
    { label: '235|GODAKAWELA', value: '235|GODAKAWELA' },
    { label: '236|AKURANA', value: '236|AKURANA' },
    { label: '237|KOGGALA', value: '237|KOGGALA' },
    { label: '238|DEVINUWARA', value: '238|DEVINUWARA' },
    { label: '239|AGUNUKOLAPELASSA', value: '239|AGUNUKOLAPELASSA' },
    { label: '240|KATARAGAMA', value: '240|KATARAGAMA' },
    { label: '241|KAITHADY', value: '241|KAITHADY' },
    { label: '243|KURUNDUWATTE', value: '243|KURUNDUWATTE' },
    { label: '244|RAGALA', value: '244|RAGALA' },
    { label: '245|INGIRIYA', value: '245|INGIRIYA' },
    { label: '246|THALAWAKELE', value: '246|THALAWAKELE' },
    { label: '247|PETTAH METRO', value: '247|PETTAH METRO' },
    { label: '248|LOTUS ROAD', value: '248|LOTUS ROAD' },
    { label: '249|ASIRI SURGICAL HOSPITAL', value: '249|ASIRI SURGICAL HOSPITAL' },
    { label: '250|ISLAMIC BANKING UNIT', value: '250|ISLAMIC BANKING UNIT' },
    { label: '251|LANKA HOSPITAL', value: '251|LANKA HOSPITAL' },
    { label: '300|HNB RUPEE DRAFT CLEARING', value: '300|HNB RUPEE DRAFT CLEARING' },
    { label: '500|DIGITAL BRANCH', value: '500|DIGITAL BRANCH' },
    { label: '701|NAWAM MAWATHA', value: '701|NAWAM MAWATHA' },
    { label: '703|WORLD TRADE CENTRE', value: '703|WORLD TRADE CENTRE' },
  ],
  'HONGKONG AND SHANGHAI BANKING CORPORATION': [
    { label: '001|HEAD OFFICE', value: '001|HEAD OFFICE' },
    { label: '002|KANDY', value: '002|KANDY' },
    { label: '003|COLPETTY', value: '003|COLPETTY' },
    { label: '004|WELLAWATHA', value: '004|WELLAWATHA' },
    { label: '005|NUGEGODA', value: '005|NUGEGODA' },
    { label: '006|WORLD TRADE CENTRE', value: '006|WORLD TRADE CENTRE' },
    { label: '007|BAMBALAPITIYA', value: '007|BAMBALAPITIYA' },
    { label: '008|PELAWATTE', value: '008|PELAWATTE' },
    { label: '012|UNION PLACE', value: '012|UNION PLACE' },
    { label: '014|WATTALA', value: '014|WATTALA' },
    { label: '015|PREMIER', value: '015|PREMIER' },
    { label: '017|NEGOMBO', value: '017|NEGOMBO' },
    { label: '018|MORATUWA', value: '018|MORATUWA' },
    { label: '020|KOHUWALA BRANCH HSBC', value: '020|KOHUWALA BRANCH HSBC' },
    { label: '021|JAFFNA', value: '021|JAFFNA' },
    { label: '022|GALLE', value: '022|GALLE' },
    { label: '777|RUPEE DRAFT CLEARING', value: '777|RUPEE DRAFT CLEARING' },
    { label: '999|HEAD OFFICE', value: '999|HEAD OFFICE' },
  ],
  'INDIAN BANK': [
    { label: '001|INDIAN BANK', value: '001|INDIAN BANK' },
    { label: '002|INDIAN BANK JAFFAN', value: '002|INDIAN BANK JAFFAN' },
    { label: '100|IB RUPEE DRAFT CLEARING', value: '100|IB RUPEE DRAFT CLEARING' },
    { label: '999|HEAD OFFICE', value: '999|HEAD OFFICE' },
  ],
  'INDIAN OVERSEAS BANK': [
    { label: '000|COLOMBO', value: '000|COLOMBO' },
    { label: '001|IOB MATARA', value: '001|IOB MATARA' },
    { label: '999|IOB RUPEE DRAFT CLEARING', value: '999|IOB RUPEE DRAFT CLEARING' },
  ],
  'PEOPLES BANK': [
    { label: '001|DUKE STREET', value: '001|DUKE STREET' },
    { label: '002|MATALE', value: '002|MATALE' },
    { label: '003|KANDY', value: '003|KANDY' },
    { label: '004|CORPORATE BANKING DIVISION', value: '004|CORPORATE BANKING DIVISION' },
    { label: '005|POLONNARUWA', value: '005|POLONNARUWA' },
    { label: '006|HINGURAKGODA', value: '006|HINGURAKGODA' },
    { label: '007|HAMBANTOTA', value: '007|HAMBANTOTA' },
    { label: '008|ANURADHAPURA', value: '008|ANURADHAPURA' },
    { label: '009|PUTTLAM', value: '009|PUTTLAM' },
    { label: '010|BADULLA', value: '010|BADULLA' },
    { label: '011|BIBILE', value: '011|BIBILE' },
    { label: '012|KURUNEGALA', value: '012|KURUNEGALA' },
    { label: '013|GALLE FORT', value: '013|GALLE FORT' },
    { label: '014|UNION PLACE', value: '014|UNION PLACE' },
    { label: '015|AMPARA', value: '015|AMPARA' },
    { label: '016|WELIMADA', value: '016|WELIMADA' },
    { label: '017|BALANGODA', value: '017|BALANGODA' },
    { label: '018|GAMPOLA', value: '018|GAMPOLA' },
    { label: '019|DEHIWALA', value: '019|DEHIWALA' },
    { label: '020|MULLAITIVU', value: '020|MULLAITIVU' },
    { label: '021|MINUWANGODA', value: '021|MINUWANGODA' },
    { label: '022|HANGURANKETA', value: '022|HANGURANKETA' },
    { label: '023|KALMUNAI', value: '023|KALMUNAI' },
    { label: '024|CHILAW', value: '024|CHILAW' },
    { label: '025|HYDE PARK', value: '025|HYDE PARK' },
    { label: '026|GAMPAHA', value: '026|GAMPAHA' },
    { label: '027|KEGALLE', value: '027|KEGALLE' },
    { label: '028|KULIYAPITIYA', value: '028|KULIYAPITIYA' },
    { label: '029|AVISSAWELLA', value: '029|AVISSAWELLA' },
    { label: '030|JAFFNA STANLEY ROAD', value: '030|JAFFNA STANLEY ROAD' },
    { label: '031|KANKESANTHURAI', value: '031|KANKESANTHURAI' },
    { label: '032|MATARA UYANAWATTE', value: '032|MATARA UYANAWATTE' },
    { label: '033|QUEEN STREET', value: '033|QUEEN STREET' },
    { label: '034|NEGOMBO', value: '034|NEGOMBO' },
    { label: '035|AMBALANGODA', value: '035|AMBALANGODA' },
    { label: '036|RAGALA', value: '036|RAGALA' },
    { label: '037|BANDARAWELA', value: '037|BANDARAWELA' },
    { label: '038|TALAWAKELLE', value: '038|TALAWAKELLE' },
    { label: '039|KALUTHARA', value: '039|KALUTHARA' },
    { label: '040|VAVUNIYA', value: '040|VAVUNIYA' },
    { label: '041|HORANA', value: '041|HORANA' },
    { label: '042|KEKIRAWA', value: '042|KEKIRAWA' },
    { label: '043|PADAVIYA', value: '043|PADAVIYA' },
    { label: '044|MANNAR', value: '044|MANNAR' },
    { label: '045|EMBILIPITIYA', value: '045|EMBILIPITIYA' },
    { label: '046|FIRST CITY BRANCH', value: '046|FIRST CITY BRANCH' },
    { label: '047|YATIYANTOTA', value: '047|YATIYANTOTA' },
    { label: '048|KILINOCHCHI', value: '048|KILINOCHCHI' },
    { label: '049|HOMAGAMA', value: '049|HOMAGAMA' },
    { label: '051|KAHATAGASDIGILIYA', value: '051|KAHATAGASDIGILIYA' },
    { label: '052|MAHO', value: '052|MAHO' },
    { label: '053|NAWALAPITIYA', value: '053|NAWALAPITIYA' },
    { label: '054|WARAKAPOLA', value: '054|WARAKAPOLA' },
    { label: '055|KELANIYA', value: '055|KELANIYA' },
    { label: '056|SRI SANGARAJA MAW', value: '056|SRI SANGARAJA MAW' },
    { label: '057|PERADENIYA', value: '057|PERADENIYA' },
    { label: '058|MAHIYANGANA', value: '058|MAHIYANGANA' },
    { label: '059|POLGAHAWELA', value: '059|POLGAHAWELA' },
    { label: '060|MORAWAKA', value: '060|MORAWAKA' },
    { label: '061|TISSAMAHARAMA', value: '061|TISSAMAHARAMA' },
    { label: '062|WELLAWAYA', value: '062|WELLAWAYA' },
    { label: '063|AKKARAIPATTU', value: '063|AKKARAIPATTU' },
    { label: '064|SAMMANTHURAI', value: '064|SAMMANTHURAI' },
    { label: '065|KATTANKUDY', value: '065|KATTANKUDY' },
    { label: '066|TRINCOMALEE', value: '066|TRINCOMALEE' },
    { label: '067|TANGALLE', value: '067|TANGALLE' },
    { label: '068|MONARAGALA', value: '068|MONARAGALA' },
    { label: '069|MAWANELLA', value: '069|MAWANELLA' },
    { label: '070|MATUGAMA', value: '070|MATUGAMA' },
    { label: '071|DEMATAGODA', value: '071|DEMATAGODA' },
    { label: '072|AMBALANTOTA', value: '072|AMBALANTOTA' },
    { label: '073|ELPITIYA', value: '073|ELPITIYA' },
    { label: '074|WATTEGAMA', value: '074|WATTEGAMA' },
    { label: '075|BATTICALOA', value: '075|BATTICALOA' },
    { label: '076|WENNAPPUWA', value: '076|WENNAPPUWA' },
    { label: '077|WELIGAMA', value: '077|WELIGAMA' },
    { label: '078|BORELLA', value: '078|BORELLA' },
    { label: '079|VEYANGODA', value: '079|VEYANGODA' },
    { label: '080|RATMALANA', value: '080|RATMALANA' },
    { label: '081|RUWANWELLA', value: '081|RUWANWELLA' },
    { label: '082|NARAMMALA', value: '082|NARAMMALA' },
    { label: '083|NATTANDIYA', value: '083|NATTANDIYA' },
    { label: '084|ALUTHGAMA', value: '084|ALUTHGAMA' },
    { label: '085|EHELIYAGODA', value: '085|EHELIYAGODA' },
    { label: '086|THIBIRIGASYAYA', value: '086|THIBIRIGASYAYA' },
    { label: '087|BADDEGAMA', value: '087|BADDEGAMA' },
    { label: '088|RATNAPURA', value: '088|RATNAPURA' },
    { label: '089|KATUGASTOTA', value: '089|KATUGASTOTA' },
    { label: '090|KANTALAI', value: '090|KANTALAI' },
    { label: '091|MORATUWA', value: '091|MORATUWA' },
    { label: '092|GIRIULLA', value: '092|GIRIULLA' },
    { label: '093|PUGODA', value: '093|PUGODA' },
    { label: '094|KINNIYA', value: '094|KINNIYA' },
    { label: '095|MUTTUR', value: '095|MUTTUR' },
    { label: '096|MEDAWACHCHIYA', value: '096|MEDAWACHCHIYA' },
    { label: '097|GANGODAWILA', value: '097|GANGODAWILA' },
    { label: '098|KOTIKAWATTE', value: '098|KOTIKAWATTE' },
    { label: '100|MARANDAGAHAMULLA', value: '100|MARANDAGAHAMULLA' },
    { label: '101|RAMBUKKANA', value: '101|RAMBUKKANA' },
    { label: '102|VALAICHCHENAI', value: '102|VALAICHCHENAI' },
    { label: '103|PILIYANDALA', value: '103|PILIYANDALA' },
    { label: '104|JAFFNA MAIN STREET', value: '104|JAFFNA MAIN STREET' },
    { label: '105|KAYTS', value: '105|KAYTS' },
    { label: '106|NELLIADY', value: '106|NELLIADY' },
    { label: '107|ATCHUVELY', value: '107|ATCHUVELY' },
    { label: '108|CHANKANAI', value: '108|CHANKANAI' },
    { label: '109|CHUNNAKAM', value: '109|CHUNNAKAM' },
    { label: '110|CHAVAKACHCHERI', value: '110|CHAVAKACHCHERI' },
    { label: '111|PARANTHAN', value: '111|PARANTHAN' },
    { label: '112|TELDENIYA', value: '112|TELDENIYA' },
    { label: '113|BATTICALOA TOWN', value: '113|BATTICALOA TOWN' },
    { label: '114|GALAGEDERA', value: '114|GALAGEDERA' },
    { label: '115|GALAWELA', value: '115|GALAWELA' },
    { label: '116|PASSARA', value: '116|PASSARA' },
    { label: '117|AKURESSA', value: '117|AKURESSA' },
    { label: '118|DELGODA', value: '118|DELGODA' },
    { label: '119|NARAHENPITA', value: '119|NARAHENPITA' },
    { label: '120|WALASMULLA', value: '120|WALASMULLA' },
    { label: '121|BANDARAGAMA', value: '121|BANDARAGAMA' },
    { label: '122|WILAGAMUWA', value: '122|WILAGAMUWA' },
    { label: '123|ERAVUR', value: '123|ERAVUR' },
    { label: '124|NIKAWERATIYA', value: '124|NIKAWERATIYA' },
    { label: '125|KALPITIYA', value: '125|KALPITIYA' },
    { label: '126|GRANDPASS', value: '126|GRANDPASS' },
    { label: '127|NILDANDAHINNA', value: '127|NILDANDAHINNA' },
    { label: '128|RATTOTA', value: '128|RATTOTA' },
    { label: '129|RAKWANA', value: '129|RAKWANA' },
    { label: '130|HAKMANA', value: '130|HAKMANA' },
    { label: '131|UDUGAMA', value: '131|UDUGAMA' },
    { label: '132|DENIYAYA', value: '132|DENIYAYA' },
    { label: '133|KAMBURUPITIYA', value: '133|KAMBURUPITIYA' },
    { label: '134|NUWARA ELIYA', value: '134|NUWARA ELIYA' },
    { label: '135|DIKWELLA', value: '135|DIKWELLA' },
    { label: '136|HIKKADUWA', value: '136|HIKKADUWA' },
    { label: '137|MAKANDURA', value: '137|MAKANDURA' },
    { label: '138|DAMBULLA', value: '138|DAMBULLA' },
    { label: '139|PETTAH', value: '139|PETTAH' },
    { label: '140|HASALAKA', value: '140|HASALAKA' },
    { label: '141|VELVETITURAI', value: '141|VELVETITURAI' },
    { label: '142|KOCHCHIKADE', value: '142|KOCHCHIKADE' },
    { label: '143|SUDUWELLA', value: '143|SUDUWELLA' },
    { label: '144|HETTIPOLA', value: '144|HETTIPOLA' },
    { label: '145|WELLAWATTE', value: '145|WELLAWATTE' },
    { label: '146|NAULA', value: '146|NAULA' },
    { label: '147|BUTTALA', value: '147|BUTTALA' },
    { label: '148|PANADURA', value: '148|PANADURA' },
    { label: '149|ALAWWA', value: '149|ALAWWA' },
    { label: '150|KEBITHIGOLLEWA', value: '150|KEBITHIGOLLEWA' },
    { label: '151|DIYATALAWA', value: '151|DIYATALAWA' },
    { label: '152|MATARA DHARMAPALA', value: '152|MATARA DHARMAPALA' },
    { label: '153|AKURANA', value: '153|AKURANA' },
    { label: '154|BALAPITIYA', value: '154|BALAPITIYA' },
    { label: '155|KAHAWATTE', value: '155|KAHAWATTE' },
    { label: '156|UVA PARANAGAMA', value: '156|UVA PARANAGAMA' },
    { label: '157|MENIKHINNA', value: '157|MENIKHINNA' },
    { label: '158|SENKADAGALA', value: '158|SENKADAGALA' },
    { label: '159|KADUGANNAWA', value: '159|KADUGANNAWA' },
    { label: '160|PELMADULLA', value: '160|PELMADULLA' },
    { label: '161|BULTHSINHALA', value: '161|BULTHSINHALA' },
    { label: '162|JAFFNA UNIVERSITY', value: '162|JAFFNA UNIVERSITY' },
    { label: '163|WARIYAPOLA', value: '163|WARIYAPOLA' },
    { label: '164|POTUVIL', value: '164|POTUVIL' },
    { label: '165|MANKULAM', value: '165|MANKULAM' },
    { label: '166|MURUNKAN', value: '166|MURUNKAN' },
    { label: '167|TOWNHALL COLOMBO', value: '167|TOWNHALL COLOMBO' },
    { label: '168|KATARAGAMA', value: '168|KATARAGAMA' },
    { label: '169|GALLE MAIN STREET.', value: '169|GALLE MAIN STREET.' },
    { label: '170|EPPAWELA', value: '170|EPPAWELA' },
    { label: '171|NOCHCHIYAGAMA', value: '171|NOCHCHIYAGAMA' },
    { label: '172|BINGIRIYA', value: '172|BINGIRIYA' },
    { label: '173|PUNDALUOYA', value: '173|PUNDALUOYA' },
    { label: '174|NUGEGODA', value: '174|NUGEGODA' },
    { label: '175|KANDANA', value: '175|KANDANA' },
    { label: '176|MID CITY BRANCH', value: '176|MID CITY BRANCH' },
    { label: '177|GALENBINDUNUWEWA', value: '177|GALENBINDUNUWEWA' },
    { label: '178|MASKELIYA', value: '178|MASKELIYA' },
    { label: '179|GALNEWA', value: '179|GALNEWA' },
    { label: '180|DERANIYAGALA', value: '180|DERANIYAGALA' },
    { label: '181|MAHAOYA', value: '181|MAHAOYA' },
    { label: '183|ANKUMBURA', value: '183|ANKUMBURA' },
    { label: '184|GALGAMUWA', value: '184|GALGAMUWA' },
    { label: '185|GALIGAMUWA/KEGALLE', value: '185|GALIGAMUWA/KEGALLE' },
    { label: '186|HATTON', value: '186|HATTON' },
    { label: '187|PALLAI', value: '187|PALLAI' },
    { label: '188|AHANGAMA', value: '188|AHANGAMA' },
    { label: '189|UHANA', value: '189|UHANA' },
    { label: '190|KALAWANCHIKUDY', value: '190|KALAWANCHIKUDY' },
    { label: '191|MALWANA', value: '191|MALWANA' },
    { label: '192|NIVITHIGALA', value: '192|NIVITHIGALA' },
    { label: '193|RIDIGAMA', value: '193|RIDIGAMA' },
    { label: '194|KOLONNAWA', value: '194|KOLONNAWA' },
    { label: '195|HALDUMULLA', value: '195|HALDUMULLA' },
    { label: '196|KADUWELA', value: '196|KADUWELA' },
    { label: '197|URAGASMANHANDIYA', value: '197|URAGASMANHANDIYA' },
    { label: '198|MIRIGAMA', value: '198|MIRIGAMA' },
    { label: '199|MAWATHAGAMA', value: '199|MAWATHAGAMA' },
    { label: '200|MAJECTIC CITY BR', value: '200|MAJECTIC CITY BR' },
    { label: '201|UKUWELA', value: '201|UKUWELA' },
    { label: '202|KIRINDIWELA', value: '202|KIRINDIWELA' },
    { label: '203|HABARANA', value: '203|HABARANA' },
    { label: '204|H. QUARTERS BRANCH', value: '204|H. QUARTERS BRANCH' },
    { label: '205|ANGUNAKOLAPALASSA', value: '205|ANGUNAKOLAPALASSA' },
    { label: '206|DAVULAGALA', value: '206|DAVULAGALA' },
    { label: '207|IBBAGAMUWA', value: '207|IBBAGAMUWA' },
    { label: '208|BATTARAMULLA', value: '208|BATTARAMULLA' },
    { label: '209|BORALANDA', value: '209|BORALANDA' },
    { label: '210|KOLLUPITIYA COOP', value: '210|KOLLUPITIYA COOP' },
    { label: '211|PANWILA', value: '211|PANWILA' },
    { label: '213|PUNGUDUTIVU', value: '213|PUNGUDUTIVU' },
    { label: '214|MUTWAL', value: '214|MUTWAL' },
    { label: '215|MADAMPE', value: '215|MADAMPE' },
    { label: '216|HAPUTALE', value: '216|HAPUTALE' },
    { label: '217|MAHARA', value: '217|MAHARA' },
    { label: '218|HOROWPATHANA', value: '218|HOROWPATHANA' },
    { label: '219|TAMBUTTEGAMA', value: '219|TAMBUTTEGAMA' },
    { label: '220|ANURADHAPURA NUWA', value: '220|ANURADHAPURA NUWA' },
    { label: '221|HEMMATHAGAMA', value: '221|HEMMATHAGAMA' },
    { label: '222|WATTALA', value: '222|WATTALA' },
    { label: '223|KARAITIVU', value: '223|KARAITIVU' },
    { label: '224|THIRUKKOVIL', value: '224|THIRUKKOVIL' },
    { label: '225|HALIELA', value: '225|HALIELA' },
    { label: '226|KURUNEGALA MALIYADEVA', value: '226|KURUNEGALA MALIYADEVA' },
    { label: '227|CHENKALADI', value: '227|CHENKALADI' },
    { label: '228|ADDALACHCHENAI', value: '228|ADDALACHCHENAI' },
    { label: '229|HANWELLA', value: '229|HANWELLA' },
    { label: '230|THANAMALWILA', value: '230|THANAMALWILA' },
    { label: '231|MEDIRIGIRIYA', value: '231|MEDIRIGIRIYA' },
    { label: '232|POLONNARUWA TOWN', value: '232|POLONNARUWA TOWN' },
    { label: '233|SERUNUWARA', value: '233|SERUNUWARA' },
    { label: '234|BATAPOLA', value: '234|BATAPOLA' },
    { label: '235|KALAWANA', value: '235|KALAWANA' },
    { label: '236|MARADANA', value: '236|MARADANA' },
    { label: '237|KIRIBATHGODA', value: '237|KIRIBATHGODA' },
    { label: '238|GONAGALDENIYA', value: '238|GONAGALDENIYA' },
    { label: '239|JA ELA', value: '239|JA ELA' },
    { label: '240|KEPPITIPOLA', value: '240|KEPPITIPOLA' },
    { label: '241|PALLEPOLA', value: '241|PALLEPOLA' },
    { label: '242|BAKAMUNA', value: '242|BAKAMUNA' },
    { label: '243|DEVINUWARA', value: '243|DEVINUWARA' },
    { label: '244|BELIATTA', value: '244|BELIATTA' },
    { label: '245|GODAKAWELA', value: '245|GODAKAWELA' },
    { label: '246|MEEGALEWA', value: '246|MEEGALEWA' },
    { label: '247|IMADUWA', value: '247|IMADUWA' },
    { label: '248|ARANAYAKE', value: '248|ARANAYAKE' },
    { label: '249|NEBODDA', value: '249|NEBODDA' },
    { label: '250|KANDEKETIYA', value: '250|KANDEKETIYA' },
    { label: '251|LUNUGALA', value: '251|LUNUGALA' },
    { label: '252|BULATHKOHUPITIYA', value: '252|BULATHKOHUPITIYA' },
    { label: '253|ARALAGANWILA', value: '253|ARALAGANWILA' },
    { label: '254|WELIKANDA', value: '254|WELIKANDA' },
    { label: '255|TRINCOMALEE TOWN', value: '255|TRINCOMALEE TOWN' },
    { label: '256|PILIMATALAWA', value: '256|PILIMATALAWA' },
    { label: '257|DELTOTA', value: '257|DELTOTA' },
    { label: '258|MEDAGAMA', value: '258|MEDAGAMA' },
    { label: '259|KEHELWATTE', value: '259|KEHELWATTE' },
    { label: '260|KOSLANDE', value: '260|KOSLANDE' },
    { label: '261|PELWATTE', value: '261|PELWATTE' },
    { label: '262|WADDUWA', value: '262|WADDUWA' },
    { label: '263|KURUWITA', value: '263|KURUWITA' },
    { label: '264|SURIYA WEWA', value: '264|SURIYA WEWA' },
    { label: '265|MIDDENIYA', value: '265|MIDDENIYA' },
    { label: '266|KIRIELLA', value: '266|KIRIELLA' },
    { label: '267|ANAMADUWA', value: '267|ANAMADUWA' },
    { label: '268|GIRANDURUKOTTE', value: '268|GIRANDURUKOTTE' },
    { label: '269|MUTHIYANG', value: '269|MUTHIYANG' },
    { label: '270|THULHIRIYA', value: '270|THULHIRIYA' },
    { label: '271|URUBOKKA', value: '271|URUBOKKA' },
    { label: '272|TALGASWELA', value: '272|TALGASWELA' },
    { label: '273|KADAWATA', value: '273|KADAWATA' },
    { label: '274|PUSSELLAWA', value: '274|PUSSELLAWA' },
    { label: '275|OLCOTT MAWATHA', value: '275|OLCOTT MAWATHA' },
    { label: '276|KATUNAYAKE', value: '276|KATUNAYAKE' },
    { label: '277|SEA STREET', value: '277|SEA STREET' },
    { label: '278|NITTAMBUWA', value: '278|NITTAMBUWA' },
    { label: '279|PITTAKOTTE', value: '279|PITTAKOTTE' },
    { label: '280|POTHUHERA', value: '280|POTHUHERA' },
    { label: '281|KOBEIGANE', value: '281|KOBEIGANE' },
    { label: '282|MAGGONA', value: '282|MAGGONA' },
    { label: '283|BADURELIYA', value: '283|BADURELIYA' },
    { label: '284|JAFFNA KANNATHIDY', value: '284|JAFFNA KANNATHIDY' },
    { label: '285|POINT PEDURO', value: '285|POINT PEDURO' },
    { label: '286|LAKE SIDE FOREIGN', value: '286|LAKE SIDE FOREIGN' },
    { label: '288|KUDAWELLA', value: '288|KUDAWELLA' },
    { label: '289|KALTOTA', value: '289|KALTOTA' },
    { label: '290|MORATUMULLA', value: '290|MORATUMULLA' },
    { label: '291|DANKOTUWA', value: '291|DANKOTUWA' },
    { label: '292|UDAPUSSELLAWA', value: '292|UDAPUSSELLAWA' },
    { label: '293|DEHIOWITA', value: '293|DEHIOWITA' },
    { label: '294|ALAWATHUGODA', value: '294|ALAWATHUGODA' },
    { label: '295|UDAWALAWE', value: '295|UDAWALAWE' },
    { label: '296|NINTHAVUR', value: '296|NINTHAVUR' },
    { label: '297|DAM STREET', value: '297|DAM STREET' },
    { label: '298|GINTUPITIYA', value: '298|GINTUPITIYA' },
    { label: '299|KEGALLE BAZZAR', value: '299|KEGALLE BAZZAR' },
    { label: '300|INGIRIYA', value: '300|INGIRIYA' },
    { label: '301|GALKIRIYAGAMA', value: '301|GALKIRIYAGAMA' },
    { label: '302|GINIGATHHENA', value: '302|GINIGATHHENA' },
    { label: '303|MAHAWEWA', value: '303|MAHAWEWA' },
    { label: '304|WALASGALA', value: '304|WALASGALA' },
    { label: '305|TRINCOMALEE INNER HABOUR', value: '305|TRINCOMALEE INNER HABOUR' },
    { label: '306|MAHARAGAMA', value: '306|MAHARAGAMA' },
    { label: '307|GANDARA', value: '307|GANDARA' },
    { label: '308|KOTAHENA', value: '308|KOTAHENA' },
    { label: '309|LIBERTY PLAZA', value: '309|LIBERTY PLAZA' },
    { label: '310|BAMBALAPITIYA', value: '310|BAMBALAPITIYA' },
    { label: '311|BERUWELA', value: '311|BERUWELA' },
    { label: '312|MALWATTA ROAD', value: '312|MALWATTA ROAD' },
    { label: '313|KATUBEDDA', value: '313|KATUBEDDA' },
    { label: '315|TALAWA', value: '315|TALAWA' },
    { label: '316|RAGAMA', value: '316|RAGAMA' },
    { label: '317|RATNAPURA TOWN', value: '317|RATNAPURA TOWN' },
    { label: '318|PAMUNUGAMA', value: '318|PAMUNUGAMA' },
    { label: '319|KIRULAPONE', value: '319|KIRULAPONE' },
    { label: '320|GOLDEN JUBILEE', value: '320|GOLDEN JUBILEE' },
    { label: '321|PANADURA TOWN', value: '321|PANADURA TOWN' },
    { label: '322|MARAWILA', value: '322|MARAWILA' },
    { label: '324|SEEDUWA', value: '324|SEEDUWA' },
    { label: '325|WANDURAMBA', value: '325|WANDURAMBA' },
    { label: '326|CAPRICORN', value: '326|CAPRICORN' },
    { label: '327|KESBEWA', value: '327|KESBEWA' },
    { label: '328|KOTTAWA', value: '328|KOTTAWA' },
    { label: '329|KOGGALA', value: '329|KOGGALA' },
    { label: '330|DEHIATHTHAKADIYA', value: '330|DEHIATHTHAKADIYA' },
    { label: '331|LUCKY PLAZA', value: '331|LUCKY PLAZA' },
    { label: '332|GANEMULLA', value: '332|GANEMULLA' },
    { label: '333|YAKKALA', value: '333|YAKKALA' },
    { label: '334|KURUNEGALA ETHUGALPURA.', value: '334|KURUNEGALA ETHUGALPURA.' },
    { label: '335|NUGEGODA CITY BR.', value: '335|NUGEGODA CITY BR.' },
    { label: '336|MOUNT LAVINIA', value: '336|MOUNT LAVINIA' },
    { label: '337|DEHIWALA', value: '337|DEHIWALA' },
    { label: '338|SAINTHAMARUTHU', value: '338|SAINTHAMARUTHU' },
    { label: '339|KALLAR', value: '339|KALLAR' },
    { label: '340|ODDAMADAVY', value: '340|ODDAMADAVY' },
    { label: '341|HATHARALIYADDA', value: '341|HATHARALIYADDA' },
    { label: '342|KOKKADDICHCHOLAI', value: '342|KOKKADDICHCHOLAI' },
    { label: '343|KARAPITIYA', value: '343|KARAPITIYA' },
    { label: '344|MELSIRIPURA', value: '344|MELSIRIPURA' },
    { label: '345|RANNA', value: '345|RANNA' },
    { label: '346|MARUTHAMUNAI', value: '346|MARUTHAMUNAI' },
    { label: '347|BADALKUMBURA', value: '347|BADALKUMBURA' },
    { label: '348|BORALESGAMUWA', value: '348|BORALESGAMUWA' },
    { label: '349|PALLEBEDDA', value: '349|PALLEBEDDA' },
    { label: '350|WEERAKETIYA', value: '350|WEERAKETIYA' },
    { label: '351|THAMBALA', value: '351|THAMBALA' },
    { label: '352|PULMUDAI', value: '352|PULMUDAI' },
    { label: '353|RIKILLAGASKADA', value: '353|RIKILLAGASKADA' },
    { label: '354|BOGAWANTHALAWA', value: '354|BOGAWANTHALAWA' },
    { label: '355|KOTIYAKUMBURA', value: '355|KOTIYAKUMBURA' },
    { label: '356|CHEDDIKULAM', value: '356|CHEDDIKULAM' },
    { label: '357|KANDY CITY CENTRE', value: '357|KANDY CITY CENTRE' },
    { label: '358|POOJAPITIYA', value: '358|POOJAPITIYA' },
    { label: '359|PILIYANDALA CITY', value: '359|PILIYANDALA CITY' },
    { label: '360|POLPITHIGAMA', value: '360|POLPITHIGAMA' },
    { label: '361|KODIKAMAM', value: '361|KODIKAMAM' },
    { label: '362|PEOPLES ELEGANCE ? PREMIER', value: '362|PEOPLES ELEGANCE ? PREMIER' },
    { label: '600|PB CARD CENTRE', value: '600|PB CARD CENTRE' },
    { label: '796|PB OCU', value: '796|PB OCU' },
    { label: '888|PB RUPEE DRAFT CLEARING', value: '888|PB RUPEE DRAFT CLEARING' },
    { label: '990|HEAD OFFICE', value: '990|HEAD OFFICE' },
    { label: '999|PB CENTRAL CLEARING', value: '999|PB CENTRAL CLEARING' },
  ],
  'STATE BANK OF INDIA': [
    { label: '001|HEAD OFFICE', value: '001|HEAD OFFICE' },
    { label: '002|KANDY', value: '002|KANDY' },
    { label: '032|KOHUWALA', value: '032|KOHUWALA' },
    { label: '999|RUPEE DRAFT CLEARING', value: '999|RUPEE DRAFT CLEARING' },
  ],
  'NATIONS TRUST BANK PLC': [
    { label: '000|HEAD OFFICE', value: '000|HEAD OFFICE' },
    { label: '001|SUPER POLA -CITY', value: '001|SUPER POLA -CITY' },
    { label: '002|COLPETTY', value: '002|COLPETTY' },
    { label: '003|SRI SANGARAJA MAWATHA', value: '003|SRI SANGARAJA MAWATHA' },
    { label: '004|KANDY', value: '004|KANDY' },
    { label: '005|WELLAWATTE', value: '005|WELLAWATTE' },
    { label: '006|CORPORATE', value: '006|CORPORATE' },
    { label: '007|NEGAMBO', value: '007|NEGAMBO' },
    { label: '008|PETTAH MAIN STREET', value: '008|PETTAH MAIN STREET' },
    { label: '009|MAHABAGE', value: '009|MAHABAGE' },
    { label: '010|BATTARAMULA', value: '010|BATTARAMULA' },
    { label: '011|CINNAMON GARDENS', value: '011|CINNAMON GARDENS' },
    { label: '012|KURUNEGALA', value: '012|KURUNEGALA' },
    { label: '013|MAHARAGAMA', value: '013|MAHARAGAMA' },
    { label: '014|MORATUWA', value: '014|MORATUWA' },
    { label: '015|BORELLA', value: '015|BORELLA' },
    { label: '016|KIRIBATHGODA', value: '016|KIRIBATHGODA' },
    { label: '017|PANADURA', value: '017|PANADURA' },
    { label: '018|GAMPAHA', value: '018|GAMPAHA' },
    { label: '019|KOTAHENA', value: '019|KOTAHENA' },
    { label: '020|WARD PL', value: '020|WARD PL' },
    { label: '021|KADAWATHA', value: '021|KADAWATHA' },
    { label: '022|CRESCAT', value: '022|CRESCAT' },
    { label: '023|DEHIWALA', value: '023|DEHIWALA' },
    { label: '024|NAWAM MAWATHA', value: '024|NAWAM MAWATHA' },
    { label: '025|HAVELOCK', value: '025|HAVELOCK' },
    { label: '026|PERADENIA', value: '026|PERADENIA' },
    { label: '027|NAWALA', value: '027|NAWALA' },
    { label: '028|MATARA', value: '028|MATARA' },
    { label: '029|GALLE', value: '029|GALLE' },
    { label: '030|THALAWATHUGODA', value: '030|THALAWATHUGODA' },
    { label: '031|HOMAGAMA', value: '031|HOMAGAMA' },
    { label: '032|BANDARAWELA', value: '032|BANDARAWELA' },
    { label: '033|VAVUNIYA', value: '033|VAVUNIYA' },
    { label: '034|BATTICALOA', value: '034|BATTICALOA' },
    { label: '035|JAFFNA', value: '035|JAFFNA' },
    { label: '036|HORANA', value: '036|HORANA' },
    { label: '037|KALMUNAI', value: '037|KALMUNAI' },
    { label: '038|MALABE', value: '038|MALABE' },
    { label: '039|ANURADHAPURA', value: '039|ANURADHAPURA' },
    { label: '040|PILIYANDALA', value: '040|PILIYANDALA' },
    { label: '041|RATNAPURA', value: '041|RATNAPURA' },
    { label: '042|NUWARAELIYA', value: '042|NUWARAELIYA' },
    { label: '043|CHILAW', value: '043|CHILAW' },
    { label: '044|NELLIADY', value: '044|NELLIADY' },
    { label: '045|KADURUWELA', value: '045|KADURUWELA' },
    { label: '046|PETTAH BANKSHALL STREET', value: '046|PETTAH BANKSHALL STREET' },
    { label: '047|ALUTHGAMA', value: '047|ALUTHGAMA' },
    { label: '048|WENNAPPUWA', value: '048|WENNAPPUWA' },
    { label: '049|TRINCOMALEE', value: '049|TRINCOMALEE' },
    { label: '050|WORLD TRADE CENTRE', value: '050|WORLD TRADE CENTRE' },
    { label: '051|WATTALA', value: '051|WATTALA' },
    { label: '052|MOUNT LAVINIA', value: '052|MOUNT LAVINIA' },
    { label: '053|NUGEGODA', value: '053|NUGEGODA' },
    { label: '054|KOHUWALA', value: '054|KOHUWALA' },
    { label: '055|KULIYAPITIYA', value: '055|KULIYAPITIYA' },
    { label: '056|AMBALANGODA', value: '056|AMBALANGODA' },
    { label: '057|AKKARAIPATTU', value: '057|AKKARAIPATTU' },
    { label: '058|HAMBANTOTA', value: '058|HAMBANTOTA' },
    { label: '059|BADULLA', value: '059|BADULLA' },
    { label: '060|JA ELA', value: '060|JA ELA' },
    { label: '061|EMBILIPITIYA', value: '061|EMBILIPITIYA' },
    { label: '062|AMBALANTOTA', value: '062|AMBALANTOTA' },
    { label: '063|AKURESSA', value: '063|AKURESSA' },
    { label: '064|BALANGODA', value: '064|BALANGODA' },
    { label: '065|DENIYAYA', value: '065|DENIYAYA' },
    { label: '066|MATUGAMA', value: '066|MATUGAMA' },
    { label: '067|KARAPITIYA', value: '067|KARAPITIYA' },
    { label: '068|RAJAGIRIYA', value: '068|RAJAGIRIYA' },
    { label: '069|KATUGASTOTA', value: '069|KATUGASTOTA' },
    { label: '070|KADUWELA', value: '070|KADUWELA' },
    { label: '071|OLD MOOR STREET', value: '071|OLD MOOR STREET' },
    { label: '072|BANDARAGAMA', value: '072|BANDARAGAMA' },
    { label: '073|DIGANA', value: '073|DIGANA' },
    { label: '074|MONARAGALA', value: '074|MONARAGALA' },
    { label: '075|BORALASGAMUWA', value: '075|BORALASGAMUWA' },
    { label: '076|KOTTAWA', value: '076|KOTTAWA' },
    { label: '077|GOTHATUWA', value: '077|GOTHATUWA' },
    { label: '078|WARIYAPOLA', value: '078|WARIYAPOLA' },
    { label: '079|KEGALLE', value: '079|KEGALLE' },
    { label: '080|TISSAMAHARAMA', value: '080|TISSAMAHARAMA' },
    { label: '081|NARAHENPITA', value: '081|NARAHENPITA' },
    { label: '082|ELPITIYA', value: '082|ELPITIYA' },
    { label: '083|GIRIULLA', value: '083|GIRIULLA' },
    { label: '084|WELIGAMA', value: '084|WELIGAMA' },
    { label: '085|NITTAMBUWA', value: '085|NITTAMBUWA' },
    { label: '086|MINUWANGODA', value: '086|MINUWANGODA' },
    { label: '087|HIKKADUWA', value: '087|HIKKADUWA' },
    { label: '088|GAMPOLA', value: '088|GAMPOLA' },
    { label: '089|TANGALLE', value: '089|TANGALLE' },
    { label: '090|MAWATHAGAMA', value: '090|MAWATHAGAMA' },
    { label: '091|AVISSAWELLA', value: '091|AVISSAWELLA' },
    { label: '092|MATALE', value: '092|MATALE' },
    { label: '093|KANDY CITY CENTRE', value: '093|KANDYCITY CENTRE' },
    { label: '094|DAMBULLA', value: '094|DAMBULLA' },
    { label: '095|PILIMATHALAWA', value: '095|PILIMATHALAWA' },
    { label: '096|KALUTHARA', value: '096|KALUTHARA' },
    { label: '400|CARD CENTER', value: '400|CARD CENTER' },
    { label: '500|LIBERTY PLAZA', value: '500|LIBERTY PLAZA' },
    { label: '501|WATTALA', value: '501|WATTALA' },
    { label: '502|MT. LAVINIA', value: '502|MT. LAVINIA' },
    { label: '503|NUGEGODA', value: '503|NUGEGODA' },
    { label: '504|KOHUWALA', value: '504|KOHUWALA' },
    { label: '999|NTB RUPEE DRAFT CLEARING', value: '999|NTB RUPEE DRAFT CLEARING' },
  ],
  'DEUTSCHE BANK AG': [
    { label: '000|COLOMBO', value: '000|COLOMBO' },
    { label: '001|MAIN BRANCH', value: '001|MAIN BRANCH' },
    { label: '050|RUPEE DRAFT CLEARING', value: '050|RUPEE DRAFT CLEARING' },
    { label: '999|HEAD OFFICE', value: '999|HEAD OFFICE' },
  ],
  'NATIONAL DEVELOPMENT BANK PLC': [
    { label: '000|HEAD OFFICE', value: '000|HEAD OFFICE' },
    { label: '001|NAVAM MAWATHA', value: '001|NAVAM MAWATHA' },
    { label: '002|KANDY', value: '002|KANDY' },
    { label: '003|JAWATTE', value: '003|JAWATTE' },
    { label: '004|NUGEGODA', value: '004|NUGEGODA' },
    { label: '005|RAJAGIRIYA', value: '005|RAJAGIRIYA' },
    { label: '006|MATARA', value: '006|MATARA' },
    { label: '007|KURUNAGALA', value: '007|KURUNAGALA' },
    { label: '008|WELLAWATTA', value: '008|WELLAWATTA' },
    { label: '009|NEGAMBO', value: '009|NEGAMBO' },
    { label: '010|CHILAW', value: '010|CHILAW' },
    { label: '011|WATTALA', value: '011|WATTALA' },
    { label: '012|MAHARAGAMA', value: '012|MAHARAGAMA' },
    { label: '013|RATNAPURA', value: '013|RATNAPURA' },
    { label: '014|KOLLUPITIYA', value: '014|KOLLUPITIYA' },
    { label: '015|MORATUWA', value: '015|MORATUWA' },
    { label: '016|KALUTARA', value: '016|KALUTARA' },
    { label: '017|KEGALLE', value: '017|KEGALLE' },
    { label: '018|BADULLA', value: '018|BADULLA' },
    { label: '019|ANURADHAPURA', value: '019|ANURADHAPURA' },
    { label: '020|MT LAVINIA', value: '020|MT LAVINIA' },
    { label: '021|GALLE', value: '021|GALLE' },
    { label: '022|PELAWATTE', value: '022|PELAWATTE' },
    { label: '023|PILIYANDALA', value: '023|PILIYANDALA' },
    { label: '024|KOTAHENA', value: '024|KOTAHENA' },
    { label: '025|KIRIBATHGODA', value: '025|KIRIBATHGODA' },
    { label: '026|KADAWATHA', value: '026|KADAWATHA' },
    { label: '027|HORANA', value: '027|HORANA' },
    { label: '028|KANDANA', value: '028|KANDANA' },
    { label: '029|GAMPAHA', value: '029|GAMPAHA' },
    { label: '030|HOMAGAMA', value: '030|HOMAGAMA' },
    { label: '031|MALABE', value: '031|MALABE' },
    { label: '032|KOHUWALA', value: '032|KOHUWALA' },
    { label: '033|PUTTALAM', value: '033|PUTTALAM' },
    { label: '034|AWISSAWELLA', value: '034|AWISSAWELLA' },
    { label: '035|PANADURA', value: '035|PANADURA' },
    { label: '036|WENNAPPUWA', value: '036|WENNAPPUWA' },
    { label: '037|JAFFNA', value: '037|JAFFNA' },
    { label: '038|TRINCOMALEE', value: '038|TRINCOMALEE' },
    { label: '039|BATTICALOA', value: '039|BATTICALOA' },
    { label: '040|AMPARA', value: '040|AMPARA' },
    { label: '041|VAVUNIA', value: '041|VAVUNIA' },
    { label: '042|KULIYAPITIYA', value: '042|KULIYAPITIYA' },
    { label: '043|PETTAH', value: '043|PETTAH' },
    { label: '044|JA ELA', value: '044|JA ELA' },
    { label: '045|MATUGAMA', value: '045|MATUGAMA' },
    { label: '046|MATALE', value: '046|MATALE' },
    { label: '047|AMBALANGODA', value: '047|AMBALANGODA' },
    { label: '048|AMBALANTOTA', value: '048|AMBALANTOTA' },
    { label: '049|PILIMATALAWA', value: '049|PILIMATALAWA' },
    { label: '050|GAMPOLA', value: '050|GAMPOLA' },
    { label: '051|BANDARAWELA', value: '051|BANDARAWELA' },
    { label: '052|BORELLA', value: '052|BORELLA' },
    { label: '053|KALMUNAI', value: '053|KALMUNAI' },
    { label: '054|NITTAMBUWA', value: '054|NITTAMBUWA' },
    { label: '055|KADUWELA', value: '055|KADUWELA' },
    { label: '056|WARIYAPOLA', value: '056|WARIYAPOLA' },
    { label: '057|HAMBANTOTA', value: '057|HAMBANTOTA' },
    { label: '058|EMBILIPITIYA', value: '058|EMBILIPITIYA' },
    { label: '059|ALUTHGAMA', value: '059|ALUTHGAMA' },
    { label: '060|KADURUWELA', value: '060|KADURUWELA' },
    { label: '061|NAWALAPITIYA', value: '061|NAWALAPITIYA' },
    { label: '062|CHUNNAKAM', value: '062|CHUNNAKAM' },
    { label: '063|MINUWANGODA', value: '063|MINUWANGODA' },
    { label: '064|DAMBULLA', value: '064|DAMBULLA' },
    { label: '065|AKURESSA', value: '065|AKURESSA' },
    { label: '066|NELLIADY', value: '066|NELLIADY' },
    { label: '067|ELPITIYA', value: '067|ELPITIYA' },
    { label: '068|YAKKALA', value: '068|YAKKALA' },
    { label: '069|NIKAWERATIYA', value: '069|NIKAWERATIYA' },
    { label: '070|MONARAGALA', value: '070|MONARAGALA' },
    { label: '071|ATHURUGIRIYA', value: '071|ATHURUGIRIYA' },
    { label: '072|BORALESGAMUWA', value: '072|BORALESGAMUWA' },
    { label: '073|RATMALANA', value: '073|RATMALANA' },
    { label: '074|NARAHENPITA', value: '074|NARAHENPITA' },
    { label: '075|MAHIYANGANA', value: '075|MAHIYANGANA' },
    { label: '076|NUWARA ELIYA', value: '076|NUWARA ELIYA' },
    { label: '077|BALANGODA', value: '077|BALANGODA' },
    { label: '078|EHELIYAGODA', value: '078|EHELIYAGODA' },
    { label: '079|OLD MOOR STREET', value: '079|OLD MOOR STREET' },
    { label: '080|KANDY CITY CENTRE', value: '080|KANDY CITY CENTRE' },
    { label: '081|URAGASMANHANDIYA', value: '081|URAGASMANHANDIYA' },
    { label: '082|KATUNAYAKE', value: '082|KATUNAYAKE' },
    { label: '083|KEKIRAWA', value: '083|KEKIRAWA' },
    { label: '084|KAHAWATTA', value: '084|KAHAWATTA' },
    { label: '086|GIRIULLA', value: '086|GIRIULLA' },
    { label: '087|PITAKOTTE', value: '087|PITAKOTTE' },
    { label: '088|HANDALA', value: '088|HANDALA' },
    { label: '089|KOCHCHIKADE', value: '089|KOCHCHIKADE' },
    { label: '090|BATTARAMULLA', value: '090|BATTARAMULLA' },
    { label: '091|NARAMMALA', value: '091|NARAMMALA' },
    { label: '092|TISSAMAHARAMA', value: '092|TISSAMAHARAMA' },
    { label: '093|CHENKALADY', value: '093|CHENKALADY' },
    { label: '094|AKKARAIPATTU', value: '094|AKKARAIPATTU' },
    { label: '095|NATTHANDIYA', value: '095|NATTHANDIYA' },
    { label: '096|HANWELLA', value: '096|HANWELLA' },
    { label: '097|MANIPAI', value: '097|MANIPAI' },
    { label: '098|THIRUNELVELY', value: '098|THIRUNELVELY' },
    { label: '099|CHAVAKACHCHERI', value: '099|CHAVAKACHCHERI' },
    { label: '100|HEAD OFFICE BRANCH', value: '100|HEAD OFFICE BRANCH' },
    { label: '101|WARAKAPOLA', value: '101|WARAKAPOLA' },
    { label: '102|HINGURAKGODA', value: '102|HINGURAKGODA' },
    { label: '103|MARINE DRIVE', value: '103|MARINE DRIVE' },
    { label: '104|THAMBUTTEGAMA', value: '104|THAMBUTTEGAMA' },
    { label: '105|MAHAWEWA', value: '105|MAHAWEWA' },
    { label: '106|HIKKADUWA', value: '106|HIKKADUWA' },
    { label: '109|KATANA', value: '109|KATANA' },
    { label: '110|KATUGASTOTA', value: '110|KATUGASTOTA' },
    { label: '111|KOTTAWA', value: '111|KOTTAWA' },
    { label: '112|BAMBALAPITIYA', value: '112|BAMBALAPITIYA' },
    { label: '113|COLOMBO FORT', value: '113|COLOMBO FORT' },
    { label: '114|DIGANA', value: '114|DIGANA' },
    { label: '115|SHANGRI-LA COLOMBO MALL', value: '115|SHANGRI-LA COLOMBO MALL' },
    { label: '116|TANGALLE', value: '116|TANGALLE' },
    { label: '601|ISLAMIC BANKING UNIT', value: '601|ISLAMIC BANKING UNIT' },
    { label: '700|NDB RUPEE DRAFT CLEARING', value: '700|NDB RUPEE DRAFT CLEARING' },
    { label: '900|HEAD OFFICE', value: '900|HEAD OFFICE' },
    { label: '999|ABN AMRO CINNOMON GARDENS', value: '999|ABN AMRO CINNOMON GARDENS' },
  ],
  'MCB BANK LIMITED': [
    { label: '001|HEAD OFFICE', value: '001|HEAD OFFICE' },
    { label: '002|PETTAH', value: '002|PETTAH' },
    { label: '003|MARADANA', value: '003|MARADANA' },
    { label: '004|ISLAMIC BANKING DIVISION', value: '004|ISLAMIC BANKING DIVISION' },
    { label: '005|WELLAWATTE', value: '005|WELLAWATTE' },
    { label: '006|KANDY', value: '006|KANDY' },
    { label: '007|BATTICALOA', value: '007|BATTICALOA' },
    { label: '008|GALLE', value: '008|GALLE' },
    { label: '009|KOLLUPITIYA', value: '009|KOLLUPITIYA' },
    { label: '999|MUSLIM COMMERCIAL BANK', value: '999|MUSLIM COMMERCIAL BANK' },
  ],
  'SAMPATH BANK PLC': [
    { label: '001|CITY', value: '001|CITY' },
    { label: '002|PETTAH', value: '002|PETTAH' },
    { label: '003|NUGEGODA', value: '003|NUGEGODA' },
    { label: '004|BORELLA', value: '004|BORELLA' },
    { label: '005|KIRIBATHGODA', value: '005|KIRIBATHGODA' },
    { label: '006|KURUNEGALA SUPER', value: '006|KURUNEGALA SUPER' },
    { label: '007|KANDY SUPER', value: '007|KANDY SUPER' },
    { label: '008|WATTALA', value: '008|WATTALA' },
    { label: '009|NAWAM MAWATHA', value: '009|NAWAM MAWATHA' },
    { label: '010|MATARA SUPER', value: '010|MATARA SUPER' },
    { label: '011|BAMBALAPITIYA', value: '011|BAMBALAPITIYA' },
    { label: '012|FORT', value: '012|FORT' },
    { label: '013|MAHARAGAMA', value: '013|MAHARAGAMA' },
    { label: '014|DENIYAYA', value: '014|DENIYAYA' },
    { label: '015|MORAWAKA', value: '015|MORAWAKA' },
    { label: '016|GAMPAHA', value: '016|GAMPAHA' },
    { label: '017|DEHIWALA', value: '017|DEHIWALA' },
    { label: '018|RATMALANA', value: '018|RATMALANA' },
    { label: '019|PILIYANDALA', value: '019|PILIYANDALA' },
    { label: '020|EHELIYAGODA', value: '020|EHELIYAGODA' },
    { label: '021|ANURADHAPURA SUPER', value: '021|ANURADHAPURA SUPER' },
    { label: '022|AVISSAWELLA', value: '022|AVISSAWELLA' },
    { label: '023|KULIYAPITIYA', value: '023|KULIYAPITIYA' },
    { label: '024|NEGOMBO', value: '024|NEGOMBO' },
    { label: '025|MATALE', value: '025|MATALE' },
    { label: '026|PANADURA SUPER', value: '026|PANADURA SUPER' },
    { label: '027|OLD MOOR STREET', value: '027|OLD MOOR STREET' },
    { label: '028|TISSAMAHARAMAYA', value: '028|TISSAMAHARAMAYA' },
    { label: '029|HEADQUARTERS', value: '029|HEADQUARTERS' },
    { label: '030|WENNAPPUWA', value: '030|WENNAPPUWA' },
    { label: '031|MORATUWA', value: '031|MORATUWA' },
    { label: '032|KATUGASTOTA', value: '032|KATUGASTOTA' },
    { label: '033|RATNAPURA', value: '033|RATNAPURA' },
    { label: '034|THIMBIRIGASYAYA', value: '034|THIMBIRIGASYAYA' },
    { label: '035|GALLE SUPER', value: '035|GALLE SUPER' },
    { label: '036|WELLAWATTHA SUPER', value: '036|WELLAWATTHA SUPER' },
    { label: '037|KOTAHENA', value: '037|KOTAHENA' },
    { label: '038|KADURUWELA', value: '038|KADURUWELA' },
    { label: '039|MALABE', value: '039|MALABE' },
    { label: '040|NARAHENPITA', value: '040|NARAHENPITA' },
    { label: '041|KALAWANA', value: '041|KALAWANA' },
    { label: '042|MAIN STREET', value: '042|MAIN STREET' },
    { label: '043|EMBILIPITIYA', value: '043|EMBILIPITIYA' },
    { label: '044|WARIYAPOLA', value: '044|WARIYAPOLA' },
    { label: '045|WELLAMPITIYA', value: '045|WELLAMPITIYA' },
    { label: '046|BANDARAWELA', value: '046|BANDARAWELA' },
    { label: '047|PANADURA - WEKADA', value: '047|PANADURA - WEKADA' },
    { label: '048|THAMBUTTEGAMA', value: '048|THAMBUTTEGAMA' },
    { label: '049|DERANIYAGALA', value: '049|DERANIYAGALA' },
    { label: '050|KALUTARA', value: '050|KALUTARA' },
    { label: '051|PERADENIYA', value: '051|PERADENIYA' },
    { label: '052|KOTTAWA', value: '052|KOTTAWA' },
    { label: '053|ALAWWA', value: '053|ALAWWA' },
    { label: '054|NELUWA', value: '054|NELUWA' },
    { label: '055|VAVUNIYA', value: '055|VAVUNIYA' },
    { label: '056|MAHIYANGANAYA', value: '056|MAHIYANGANAYA' },
    { label: '057|HORANA', value: '057|HORANA' },
    { label: '058|HARBOUR VIEW', value: '058|HARBOUR VIEW' },
    { label: '059|BANDARAGAMA', value: '059|BANDARAGAMA' },
    { label: '060|KADAWATHA', value: '060|KADAWATHA' },
    { label: '061|BATTARAMULLA', value: '061|BATTARAMULLA' },
    { label: '062|AMPARA', value: '062|AMPARA' },
    { label: '063|PELAWATTA', value: '063|PELAWATTA' },
    { label: '064|KEGALLE', value: '064|KEGALLE' },
    { label: '065|MINUWANGODA', value: '065|MINUWANGODA' },
    { label: '066|TRINCOMALEE', value: '066|TRINCOMALEE' },
    { label: '067|ATHURUGIRIYA', value: '067|ATHURUGIRIYA' },
    { label: '068|YAKKALA', value: '068|YAKKALA' },
    { label: '069|HOMAGAMA', value: '069|HOMAGAMA' },
    { label: '070|GREGORY ROAD', value: '070|GREGORY ROAD' },
    { label: '071|NITTAMBUWA', value: '071|NITTAMBUWA' },
    { label: '072|AMBALANGODA', value: '072|AMBALANGODA' },
    { label: '073|RAGAMA', value: '073|RAGAMA' },
    { label: '074|MONARAGALA', value: '074|MONARAGALA' },
    { label: '075|WADDUWA', value: '075|WADDUWA' },
    { label: '076|KANDANA', value: '076|KANDANA' },
    { label: '077|VEYANGODA', value: '077|VEYANGODA' },
    { label: '078|GANEMULLA', value: '078|GANEMULLA' },
    { label: '079|ALUTHGAMA', value: '079|ALUTHGAMA' },
    { label: '080|HATTON', value: '080|HATTON' },
    { label: '081|WELIMADA', value: '081|WELIMADA' },
    { label: '082|NAWALA', value: '082|NAWALA' },
    { label: '083|KIRINDIWELA', value: '083|KIRINDIWELA' },
    { label: '084|NUWARAELIYA', value: '084|NUWARAELIYA' },
    { label: '085|DIGANA', value: '085|DIGANA' },
    { label: '086|MIRIGAMA', value: '086|MIRIGAMA' },
    { label: '087|PANNIPITIYA', value: '087|PANNIPITIYA' },
    { label: '088|NEGOMBO II', value: '088|NEGOMBO II' },
    { label: '089|ATTIDIYA', value: '089|ATTIDIYA' },
    { label: '090|DAMBULLA', value: '090|DAMBULLA' },
    { label: '091|PITAKOTTE', value: '091|PITAKOTTE' },
    { label: '092|MAHARAGAMA SUPER', value: '092|MAHARAGAMA SUPER' },
    { label: '093|BADULLA', value: '093|BADULLA' },
    { label: '094|KOHUWALA', value: '094|KOHUWALA' },
    { label: '095|GIRIULLA', value: '095|GIRIULLA' },
    { label: '096|HENDALA', value: '096|HENDALA' },
    { label: '097|BALANGODA', value: '097|BALANGODA' },
    { label: '098|JA-ELA', value: '098|JA-ELA' },
    { label: '099|NARAMMALA', value: '099|NARAMMALA' },
    { label: '100|KANDY METRO', value: '100|KANDY METRO' },
    { label: '101|GAMPOLA', value: '101|GAMPOLA' },
    { label: '102|NIKAWERATIYA', value: '102|NIKAWERATIYA' },
    { label: '103|PELMADULLA', value: '103|PELMADULLA' },
    { label: '104|AMBALANTOTA', value: '104|AMBALANTOTA' },
    { label: '105|WATTEGAMA', value: '105|WATTEGAMA' },
    { label: '106|MATUGAMA', value: '106|MATUGAMA' },
    { label: '107|BATTICALOA', value: '107|BATTICALOA' },
    { label: '108|CHILAW', value: '108|CHILAW' },
    { label: '109|MAWATHAGAMA', value: '109|MAWATHAGAMA' },
    { label: '110|HINGURAKGODA', value: '110|HINGURAKGODA' },
    { label: '111|AKKARAIPATTU', value: '111|AKKARAIPATTU' },
    { label: '112|KALMUNAI', value: '112|KALMUNAI' },
    { label: '113|WELLAWAYA', value: '113|WELLAWAYA' },
    { label: '114|EMBULDENIYA', value: '114|EMBULDENIYA' },
    { label: '115|KATTANKUDY', value: '115|KATTANKUDY' },
    { label: '116|TANGALLE', value: '116|TANGALLE' },
    { label: '117|KIRULAPONE', value: '117|KIRULAPONE' },
    { label: '118|BADDEGAMA', value: '118|BADDEGAMA' },
    { label: '119|MANNAR', value: '119|MANNAR' },
    { label: '120|JAFFNA', value: '120|JAFFNA' },
    { label: '121|CHENKALADY', value: '121|CHENKALADY' },
    { label: '122|RAJAGIRIYA', value: '122|RAJAGIRIYA' },
    { label: '123|KANDY CITY CENTRE', value: '123|KANDY CITY CENTRE' },
    { label: '124|ODDAMAVADI', value: '124|ODDAMAVADI' },
    { label: '125|KALUWANCHIKUDY', value: '125|KALUWANCHIKUDY' },
    { label: '126|SAINTHAMARUTHU', value: '126|SAINTHAMARUTHU' },
    { label: '127|GRANDPASS', value: '127|GRANDPASS' },
    { label: '128|CHUNNAKAM', value: '128|CHUNNAKAM' },
    { label: '129|NELLIADY', value: '129|NELLIADY' },
    { label: '130|POTTUVIL', value: '130|POTTUVIL' },
    { label: '131|PLATINUM PLUS', value: '131|PLATINUM PLUS' },
    { label: '132|NATTANDIYA', value: '132|NATTANDIYA' },
    { label: '133|KUNDASALE', value: '133|KUNDASALE' },
    { label: '134|KOLLUPITIYA', value: '134|KOLLUPITIYA' },
    { label: '135|GANGODAWILA', value: '135|GANGODAWILA' },
    { label: '136|PELIYAGODA', value: '136|PELIYAGODA' },
    { label: '137|HANWELLA', value: '137|HANWELLA' },
    { label: '138|NOCHCHIYAGAMA', value: '138|NOCHCHIYAGAMA' },
    { label: '139|BATTICALOA II', value: '139|BATTICALOA II' },
    { label: '140|INGIRIYA', value: '140|INGIRIYA' },
    { label: '141|KARAPITIYA', value: '141|KARAPITIYA' },
    { label: '142|BORALESGAMUWA', value: '142|BORALESGAMUWA' },
    { label: '143|ANAMADUWA', value: '143|ANAMADUWA' },
    { label: '144|MARADANA', value: '144|MARADANA' },
    { label: '145|BUTTALA', value: '145|BUTTALA' },
    { label: '146|PASSARA', value: '146|PASSARA' },
    { label: '147|MANIPAY', value: '147|MANIPAY' },
    { label: '148|KILLINOCHCHI', value: '148|KILLINOCHCHI' },
    { label: '149|KEKIRAWA', value: '149|KEKIRAWA' },
    { label: '150|PILIMATALAWA', value: '150|PILIMATALAWA' },
    { label: '151|KESELWATTA', value: '151|KESELWATTA' },
    { label: '152|PUSSELLAWA', value: '152|PUSSELLAWA' },
    { label: '153|MATARA BAZAAR', value: '153|MATARA BAZAAR' },
    { label: '154|ARALAGANWILA', value: '154|ARALAGANWILA' },
    { label: '155|MORATUMULLA', value: '155|MORATUMULLA' },
    { label: '156|PUTTALAM', value: '156|PUTTALAM' },
    { label: '157|SOORIYAWEWA', value: '157|SOORIYAWEWA' },
    { label: '158|MIDDENIYA', value: '158|MIDDENIYA' },
    { label: '159|GALLE BAZAAR', value: '159|GALLE BAZAAR' },
    { label: '160|MAWANELLA', value: '160|MAWANELLA' },
    { label: '161|BIBILE', value: '161|BIBILE' },
    { label: '162|KADUWELA', value: '162|KADUWELA' },
    { label: '163|RIKILLAGASKADA', value: '163|RIKILLAGASKADA' },
    { label: '164|CHANKANAI', value: '164|CHANKANAI' },
    { label: '165|KOCHCHIKADE', value: '165|KOCHCHIKADE' },
    { label: '166|PANNALA', value: '166|PANNALA' },
    { label: '167|DEHIATTAKANDIYA', value: '167|DEHIATTAKANDIYA' },
    { label: '168|ANURADHAPURA NEW TOWN', value: '168|ANURADHAPURA NEW TOWN' },
    { label: '169|CHAVAKACHCHERI', value: '169|CHAVAKACHCHERI' },
    { label: '170|VAVUNIYA METRO', value: '170|VAVUNIYA METRO' },
    { label: '171|KAYTS', value: '171|KAYTS' },
    { label: '172|KANTALE', value: '172|KANTALE' },
    { label: '173|GOTHATUWA NEW TOWN', value: '173|GOTHATUWA NEW TOWN' },
    { label: '174|MALLAVI', value: '174|MALLAVI' },
    { label: '175|COLOMBO SUPER', value: '175|COLOMBO SUPER' },
    { label: '176|MATTEGODA', value: '176|MATTEGODA' },
    { label: '177|KINNIYA', value: '177|KINNIYA' },
    { label: '178|THALAWATHUGODA', value: '178|THALAWATHUGODA' },
    { label: '179|AKURESSA', value: '179|AKURESSA' },
    { label: '180|BELIATTA', value: '180|BELIATTA' },
    { label: '181|HABARADUWA', value: '181|HABARADUWA' },
    { label: '182|AHANGAMA', value: '182|AHANGAMA' },
    { label: '183|MARANDAGAHAMULA', value: '183|MARANDAGAHAMULA' },
    { label: '184|MENIKHINNA', value: '184|MENIKHINNA' },
    { label: '185|NINTHAVUR', value: '185|NINTHAVUR' },
    { label: '186|THIRUNELVELI', value: '186|THIRUNELVELI' },
    { label: '187|HETTIPOLA', value: '187|HETTIPOLA' },
    { label: '188|RAMBUKKANA', value: '188|RAMBUKKANA' },
    { label: '189|MADAMPE', value: '189|MADAMPE' },
    { label: '190|GALEWELA', value: '190|GALEWELA' },
    { label: '191|PANCHIKAWATTE', value: '191|PANCHIKAWATTE' },
    { label: '192|PADUKKA', value: '192|PADUKKA' },
    { label: '193|MUTWAL', value: '193|MUTWAL' },
    { label: '194|MARAWILA', value: '194|MARAWILA' },
    { label: '195|NAWALAPITIYA', value: '195|NAWALAPITIYA' },
    { label: '196|DANKOTUWA', value: '196|DANKOTUWA' },
    { label: '197|MAHO', value: '197|MAHO' },
    { label: '198|DIVULAPITIYA', value: '198|DIVULAPITIYA' },
    { label: '199|MOUNT LAVINIA', value: '199|MOUNT LAVINIA' },
    { label: '200|KIRIBATHGODA SUPER', value: '200|KIRIBATHGODA SUPER' },
    { label: '201|RUWANWELLA', value: '201|RUWANWELLA' },
    { label: '202|DELGODA', value: '202|DELGODA' },
    { label: '203|KAHATAGASDIGILIYA', value: '203|KAHATAGASDIGILIYA' },
    { label: '204|ELPITIYA', value: '204|ELPITIYA' },
    { label: '205|WARAKAPOLA', value: '205|WARAKAPOLA' },
    { label: '206|KAMBURUPITIYA', value: '206|KAMBURUPITIYA' },
    { label: '207|MAKOLA', value: '207|MAKOLA' },
    { label: '208|MUTTUR', value: '208|MUTTUR' },
    { label: '209|WELIGAMA', value: '209|WELIGAMA' },
    { label: '210|KARAGAMPITIYA', value: '210|KARAGAMPITIYA' },
    { label: '211|TALAHENA', value: '211|TALAHENA' },
    { label: '212|GAMPAHA SUPER', value: '212|GAMPAHA SUPER' },
    { label: '213|SEEDUWA', value: '213|SEEDUWA' },
    { label: '214|ORUGODAWATTE', value: '214|ORUGODAWATTE' },
    { label: '215|KURUNEGALA METRO', value: '215|KURUNEGALA METRO' },
    { label: '216|DICKWELLA', value: '216|DICKWELLA' },
    { label: '217|PRINCE STREET', value: '217|PRINCE STREET' },
    { label: '218|BORELLA SUPER', value: '218|BORELLA SUPER' },
    { label: '219|WORLD TRADE CENTER', value: '219|WORLD TRADE CENTER' },
    { label: '220|HEERASSAGALA', value: '220|HEERASSAGALA' },
    { label: '221|POOJAPITIYA', value: '221|POOJAPITIYA' },
    { label: '222|TALAWAKELE', value: '222|TALAWAKELE' },
    { label: '223|NAWALA KOSWATTA', value: '223|NAWALA KOSWATTA' },
    { label: '224|KESBEWA', value: '224|KESBEWA' },
    { label: '225|GODAKAWELA', value: '225|GODAKAWELA' },
    { label: '226|RATNAPURA METRO', value: '226|RATNAPURA METRO' },
    { label: '227|KELANIYA', value: '227|KELANIYA' },
    { label: '228|POLGAHAWELA', value: '228|POLGAHAWELA' },
    { label: '229|HIKKADUWA', value: '229|HIKKADUWA' },
    { label: '500|DUMMY BRANCH AT MIGARTION', value: '500|DUMMY BRANCH AT MIGARTION' },
    { label: '929|VISHWA', value: '929|VISHWA' },
    { label: '960|CARD CENTER', value: '960|CARD CENTER' },
  ],
  'SEYLAN BANK PLC': [
    { label: '000|HEAD OFFICE', value: '000|HEAD OFFICE' },
    { label: '001|CITY OFFICE', value: '001|CITY OFFICE' },
    { label: '002|MATARA', value: '002|MATARA' },
    { label: '003|MOUNT LAVINIA', value: '003|MOUNT LAVINIA' },
    { label: '004|MAHARAGAMA', value: '004|MAHARAGAMA' },
    { label: '005|PANADURA', value: '005|PANADURA' },
    { label: '006|KIRIBATHGODA', value: '006|KIRIBATHGODA' },
    { label: '007|RATNAPURA', value: '007|RATNAPURA' },
    { label: '008|KOLLUPITIYA', value: '008|KOLLUPITIYA' },
    { label: '009|MORATUWA', value: '009|MORATUWA' },
    { label: '010|KEGALLE', value: '010|KEGALLE' },
    { label: '011|GAMPAHA', value: '011|GAMPAHA' },
    { label: '012|NUGEGODA', value: '012|NUGEGODA' },
    { label: '013|NEGOMBO', value: '013|NEGOMBO' },
    { label: '014|DEHIWELA', value: '014|DEHIWELA' },
    { label: '015|CHILAW', value: '015|CHILAW' },
    { label: '016|GALLE', value: '016|GALLE' },
    { label: '017|KANDY', value: '017|KANDY' },
    { label: '018|KURUNEGALA', value: '018|KURUNEGALA' },
    { label: '019|NUWARAELIYA', value: '019|NUWARAELIYA' },
    { label: '020|BALANGODA', value: '020|BALANGODA' },
    { label: '021|ANURADHAPURA', value: '021|ANURADHAPURA' },
    { label: '022|GRANDPASS', value: '022|GRANDPASS' },
    { label: '023|HORANA', value: '023|HORANA' },
    { label: '024|AMBALANGODA', value: '024|AMBALANGODA' },
    { label: '025|GAMPOLA', value: '025|GAMPOLA' },
    { label: '026|BADULLA', value: '026|BADULLA' },
    { label: '027|JAELA', value: '027|JAELA' },
    { label: '028|KADAWATHA', value: '028|KADAWATHA' },
    { label: '029|DEHIATTAKANDIYA', value: '029|DEHIATTAKANDIYA' },
    { label: '030|COLOMBO FORT', value: '030|COLOMBO FORT' },
    { label: '031|KATUNAYAKE', value: '031|KATUNAYAKE' },
    { label: '032|CINNAMON GARDENS', value: '032|CINNAMON GARDENS' },
    { label: '033|KOTTAWA', value: '033|KOTTAWA' },
    { label: '034|BORALESGAMUWA', value: '034|BORALESGAMUWA' },
    { label: '035|YAKKALA', value: '035|YAKKALA' },
    { label: '036|KALUTARA', value: '036|KALUTARA' },
    { label: '037|TISSAMAHARAMA', value: '037|TISSAMAHARAMA' },
    { label: '038|MATALE', value: '038|MATALE' },
    { label: '039|HATTON', value: '039|HATTON' },
    { label: '040|SARIKKAMULLA', value: '040|SARIKKAMULLA' },
    { label: '041|ATTIDIYA', value: '041|ATTIDIYA' },
    { label: '042|KALUBOWILA', value: '042|KALUBOWILA' },
    { label: '043|HOMAGAMA', value: '043|HOMAGAMA' },
    { label: '044|KULIYAPITIYA', value: '044|KULIYAPITIYA' },
    { label: '045|EMBILIPITIYA', value: '045|EMBILIPITIYA' },
    { label: '046|BANDARAWELA', value: '046|BANDARAWELA' },
    { label: '047|MARADANA', value: '047|MARADANA' },
    { label: '048|MAWANELLA', value: '048|MAWANELLA' },
    { label: '049|PUTTALAM', value: '049|PUTTALAM' },
    { label: '050|OLD MOOR STREET', value: '050|OLD MOOR STREET' },
    { label: '051|HINGURAKGODA', value: '051|HINGURAKGODA' },
    { label: '052|NAWALA', value: '052|NAWALA' },
    { label: '053|MANAMPITIYA', value: '053|MANAMPITIYA' },
    { label: '054|BANDARAGAMA', value: '054|BANDARAGAMA' },
    { label: '055|KATUNERIYA', value: '055|KATUNERIYA' },
    { label: '056|KOGGALA', value: '056|KOGGALA' },
    { label: '057|WELIMADA', value: '057|WELIMADA' },
    { label: '058|KOCHCHIKADE', value: '058|KOCHCHIKADE' },
    { label: '059|BOGAWANTALAWA', value: '059|BOGAWANTALAWA' },
    { label: '060|GANEMULLA', value: '060|GANEMULLA' },
    { label: '061|THALAWAKELE', value: '061|THALAWAKELE' },
    { label: '062|RADDOLUGAMA', value: '062|RADDOLUGAMA' },
    { label: '063|WELIVERIYA', value: '063|WELIVERIYA' },
    { label: '064|PETTAH', value: '064|PETTAH' },
    { label: '065|BELIATTA', value: '065|BELIATTA' },
    { label: '066|MATUGAMA', value: '066|MATUGAMA' },
    { label: '067|MALABE', value: '067|MALABE' },
    { label: '068|COLOMBO SOUTH', value: '068|COLOMBO SOUTH' },
    { label: '069|DAM STREET', value: '069|DAM STREET' },
    { label: '070|WARAKAPOLA', value: '070|WARAKAPOLA' },
    { label: '071|WATTALA', value: '071|WATTALA' },
    { label: '072|VAVUNIYA', value: '072|VAVUNIYA' },
    { label: '073|BATTICALOA', value: '073|BATTICALOA' },
    { label: '074|KATHTHANKUDY', value: '074|KATHTHANKUDY' },
    { label: '075|AVISSAWELLA', value: '075|AVISSAWELLA' },
    { label: '076|NAWALAPITIYA', value: '076|NAWALAPITIYA' },
    { label: '077|KEKIRAWA', value: '077|KEKIRAWA' },
    { label: '078|MIRIGAMA', value: '078|MIRIGAMA' },
    { label: '079|SOYSA PURA', value: '079|SOYSA PURA' },
    { label: '080|RUWANWELLA', value: '080|RUWANWELLA' },
    { label: '081|HAMBANTOTA', value: '081|HAMBANTOTA' },
    { label: '082|BORELLA', value: '082|BORELLA' },
    { label: '083|HAVELOCK TOWN', value: '083|HAVELOCK TOWN' },
    { label: '084|MARANSDAGAHAMULA', value: '084|MARANSDAGAHAMULA' },
    { label: '085|JAFFNA', value: '085|JAFFNA' },
    { label: '086|MILLENIUM', value: '086|MILLENIUM' },
    { label: '087|NITTAMBUWA', value: '087|NITTAMBUWA' },
    { label: '088|TRINCOMALEE', value: '088|TRINCOMALEE' },
    { label: '089|MEEGODA', value: '089|MEEGODA' },
    { label: '090|PALMADULLA', value: '090|PALMADULLA' },
    { label: '091|AMPARA', value: '091|AMPARA' },
    { label: '092|NELLIYADI', value: '092|NELLIYADI' },
    { label: '093|KILINOCHCHI', value: '093|KILINOCHCHI' },
    { label: '094|MANNAR', value: '094|MANNAR' },
    { label: '095|CHAVAKACHCHERI', value: '095|CHAVAKACHCHERI' },
    { label: '096|MULLATIVU', value: '096|MULLATIVU' },
    { label: '097|KALMUNAI', value: '097|KALMUNAI' },
    { label: '098|CHENKALADY', value: '098|CHENKALADY' },
    { label: '099|PILIYANDALA', value: '099|PILIYANDALA' },
    { label: '100|AKURESSA', value: '100|AKURESSA' },
    { label: '101|BATTARAMULLA', value: '101|BATTARAMULLA' },
    { label: '102|KADURUWELA', value: '102|KADURUWELA' },
    { label: '103|DAMBULLA', value: '103|DAMBULLA' },
    { label: '104|MONARAGALA', value: '104|MONARAGALA' },
    { label: '105|AMBALANTOTA', value: '105|AMBALANTOTA' },
    { label: '106|NARAMMALA', value: '106|NARAMMALA' },
    { label: '107|MAHIYANGANAYA', value: '107|MAHIYANGANAYA' },
    { label: '108|VEYANGODA', value: '108|VEYANGODA' },
    { label: '109|MAWATHAGAMA', value: '109|MAWATHAGAMA' },
    { label: '110|PUSSELLAWA', value: '110|PUSSELLAWA' },
    { label: '111|DUMMALASOORIYA', value: '111|DUMMALASOORIYA' },
    { label: '112|GODAGAMA', value: '112|GODAGAMA' },
    { label: '113|GALENBIDUNUWEWA', value: '113|GALENBIDUNUWEWA' },
    { label: '114|PITAKOTTE', value: '114|PITAKOTTE' },
    { label: '115|KANTHALE', value: '115|KANTHALE' },
    { label: '116|AKKARAIPATTHU', value: '116|AKKARAIPATTHU' },
    { label: '117|CHANKANAI', value: '117|CHANKANAI' },
    { label: '118|KOTAHENA', value: '118|KOTAHENA' },
    { label: '119|UDAPPU', value: '119|UDAPPU' },
    { label: '120|INGIRIYA', value: '120|INGIRIYA' },
    { label: '121|BERUWALA', value: '121|BERUWALA' },
    { label: '122|KIRULAPONE', value: '122|KIRULAPONE' },
    { label: '123|GOTHATUWA', value: '123|GOTHATUWA' },
    { label: '124|MINUWANGODA', value: '124|MINUWANGODA' },
    { label: '125|MORATUMULLA', value: '125|MORATUMULLA' },
    { label: '126|KIRINDIWELA', value: '126|KIRINDIWELA' },
    { label: '127|YATIYANTOTA', value: '127|YATIYANTOTA' },
    { label: '128|RANPOKUNAGAMA', value: '128|RANPOKUNAGAMA' },
    { label: '129|ARALAGANWILA', value: '129|ARALAGANWILA' },
    { label: '130|KALAWANCHIKUDY', value: '130|KALAWANCHIKUDY' },
    { label: '131|MANIPAY', value: '131|MANIPAY' },
    { label: '132|MEDAWACHCHIYA', value: '132|MEDAWACHCHIYA' },
    { label: '133|NOCHCHIYAGAMA', value: '133|NOCHCHIYAGAMA' },
    { label: '134|PALLEKELLE', value: '134|PALLEKELLE' },
    { label: '135|WIJERAMA', value: '135|WIJERAMA' },
    { label: '136|KOTIYAKUMBURA', value: '136|KOTIYAKUMBURA' },
    { label: '137|BOWATTA', value: '137|BOWATTA' },
    { label: '138|EPPAWALA', value: '138|EPPAWALA' },
    { label: '139|BAMBALAPITIYA', value: '139|BAMBALAPITIYA' },
    { label: '140|HIKKADUWA', value: '140|HIKKADUWA' },
    { label: '141|RIDEEGAMA', value: '141|RIDEEGAMA' },
    { label: '142|WADDUWA', value: '142|WADDUWA' },
    { label: '143|KELANIYA', value: '143|KELANIYA' },
    { label: '144|COLOMBO GOLD CENTRE', value: '144|COLOMBO GOLD CENTRE' },
    { label: '145|BADURALIYA', value: '145|BADURALIYA' },
    { label: '146|KAMBURUPITIYA', value: '146|KAMBURUPITIYA' },
    { label: '147|KALPITIYA', value: '147|KALPITIYA' },
    { label: '148|KATARAGAMA', value: '148|KATARAGAMA' },
    { label: '149|KATUGASTOTA', value: '149|KATUGASTOTA' },
    { label: '151|POTHUVIL', value: '151|POTHUVIL' },
    { label: '152|SAMANTHURAI', value: '152|SAMANTHURAI' },
    { label: '153|SIYAMBALANDUWA', value: '153|SIYAMBALANDUWA' },
    { label: '154|PADAVIYA', value: '154|PADAVIYA' },
    { label: '157|PERADENIYA', value: '157|PERADENIYA' },
    { label: '158|PILIMATHALAWA', value: '158|PILIMATHALAWA' },
    { label: '159|POLONNARUWA', value: '159|POLONNARUWA' },
    { label: '160|WENNAPPUWA', value: '160|WENNAPPUWA' },
    { label: '161|DANKOTUWA', value: '161|DANKOTUWA' },
    { label: '162|PANNALA', value: '162|PANNALA' },
    { label: '163|ATHURUGIRIYA', value: '163|ATHURUGIRIYA' },
    { label: '164|KARAPITIYA', value: '164|KARAPITIYA' },
    { label: '165|MATARA BAZZAR', value: '165|MATARA BAZZAR' },
    { label: '166|CHUNNAKAM', value: '166|CHUNNAKAM' },
    { label: '167|MUTWAL', value: '167|MUTWAL' },
    { label: '168|KANDANA', value: '168|KANDANA' },
    { label: '169|WARIYAPOLA', value: '169|WARIYAPOLA' },
    { label: '170|ALUTHGAMA', value: '170|ALUTHGAMA' },
    { label: '171|GIRIULLA', value: '171|GIRIULLA' },
    { label: '172|HETTIPOLA', value: '172|HETTIPOLA' },
    { label: '173|EHELIYAGODA', value: '173|EHELIYAGODA' },
    { label: '174|GODAKAWELA', value: '174|GODAKAWELA' },
    { label: '175|UNION PLACE', value: '175|UNION PLACE' },
    { label: '176|PADUKKA', value: '176|PADUKKA' },
    { label: '988|BANCASSURANCE UNIT', value: '988|BANCASSURANCE UNIT' },
    { label: '996|CENTRAL PROCESSING UNIT', value: '996|CENTRAL PROCESSING UNIT' },
    { label: '997|SEYLAN CARD CENTRE', value: '997|SEYLAN CARD CENTRE' },
    { label: '998|RETAIL REMITTANCE CENTRE', value: '998|RETAIL REMITTANCE CENTRE' },
  ],
  'PUBLIC BANK BERHAD': [
    { label: '001|ALL BRANCHES', value: '001|ALL BRANCHES' },
    { label: '099|PBB RUPEE DRAFT CLEARING', value: '099|PBB RUPEE DRAFT CLEARING' },
    { label: '999|PUBLIC BANK BERHAD', value: '999|PUBLIC BANK BERHAD' },
  ],
  'UNION BANK OF COLOMBO PLC': [
    { label: '001|UB MAIN OFFICE', value: '001|UB MAIN OFFICE' },
    { label: '002|COLPETTY', value: '002|COLPETTY' },
    { label: '003|KANDY', value: '003|KANDY' },
    { label: '004|NAWALA', value: '004|NAWALA' },
    { label: '005|WELLAWATTE', value: '005|WELLAWATTE' },
    { label: '006|PETTAH', value: '006|PETTAH' },
    { label: '007|OLD MOOR STREET', value: '007|OLD MOOR STREET' },
    { label: '008|HEAD OFFICE BRANCH', value: '008|HEAD OFFICE BRANCH' },
    { label: '009|KURUNEGALA', value: '009|KURUNEGALA' },
    { label: '010|NEGOMBO', value: '010|NEGOMBO' },
    { label: '011|MATARA', value: '011|MATARA' },
    { label: '012|KOTAHENA', value: '012|KOTAHENA' },
    { label: '014|JA ELA', value: '014|JA ELA' },
    { label: '015|NUGEGODA', value: '015|NUGEGODA' },
    { label: '016|MORATUWA', value: '016|MORATUWA' },
    { label: '017|WENNAPPUWA', value: '017|WENNAPPUWA' },
    { label: '018|JAFFNA', value: '018|JAFFNA' },
    { label: '019|MANNAR', value: '019|MANNAR' },
    { label: '020|VAVUNIYA', value: '020|VAVUNIYA' },
    { label: '021|CHUNNAKKAM', value: '021|CHUNNAKKAM' },
    { label: '022|ATCHUVELY', value: '022|ATCHUVELY' },
    { label: '023|WATTALA', value: '023|WATTALA' },
    { label: '024|GAMPAHA', value: '024|GAMPAHA' },
    { label: '025|BATTICALOA', value: '025|BATTICALOA' },
    { label: '026|TRINCOMALEE', value: '026|TRINCOMALEE' },
    { label: '027|GALLE', value: '027|GALLE' },
    { label: '028|PILIYANDALA', value: '028|PILIYANDALA' },
    { label: '029|KADAWATA', value: '029|KADAWATA' },
    { label: '030|RATNAPURA', value: '030|RATNAPURA' },
    { label: '031|ANURADHAPURA', value: '031|ANURADHAPURA' },
    { label: '032|DAMBULLA', value: '032|DAMBULLA' },
    { label: '033|BADULLA', value: '033|BADULLA' },
    { label: '034|HORANA', value: '034|HORANA' },
    { label: '035|AMBALANGODA', value: '035|AMBALANGODA' },
    { label: '036|CHILAW', value: '036|CHILAW' },
    { label: '037|MARAWILA', value: '037|MARAWILA' },
    { label: '038|PANADURA', value: '038|PANADURA' },
    { label: '039|NARAMMALA', value: '039|NARAMMALA' },
    { label: '040|PELAWATTE', value: '040|PELAWATTE' },
    { label: '041|ANGUNAKOLAPELESSA', value: '041|ANGUNAKOLAPELESSA' },
    { label: '042|KEBITHIGOLLEWA', value: '042|KEBITHIGOLLEWA' },
    { label: '043|GAMPOLA', value: '043|GAMPOLA' },
    { label: '044|EMBILIPITIYA', value: '044|EMBILIPITIYA' },
    { label: '045|KEGALLE', value: '045|KEGALLE' },
    { label: '046|WARAKAPOLA', value: '046|WARAKAPOLA' },
    { label: '047|KEKIRAWA', value: '047|KEKIRAWA' },
    { label: '048|HOROWPATHANA', value: '048|HOROWPATHANA' },
    { label: '049|AMBALANTHOTA', value: '049|AMBALANTHOTA' },
    { label: '050|MINUWANGODA', value: '050|MINUWANGODA' },
    { label: '051|BALANGODA', value: '051|BALANGODA' },
    { label: '052|MAHARAGAMA', value: '052|MAHARAGAMA' },
    { label: '053|MATUGAMA', value: '053|MATUGAMA' },
    { label: '054|NAWALAPITIYA', value: '054|NAWALAPITIYA' },
    { label: '055|IBBAGAMUWA', value: '055|IBBAGAMUWA' },
    { label: '056|KULIYAPITIYA', value: '056|KULIYAPITIYA' },
    { label: '057|PILIMATHALAWA', value: '057|PILIMATHALAWA' },
    { label: '058|MONARAGALA', value: '058|MONARAGALA' },
    { label: '059|GANEMULLA', value: '059|GANEMULLA' },
    { label: '060|ELPITIYA', value: '060|ELPITIYA' },
    { label: '061|MEDAWACHCHIYA', value: '061|MEDAWACHCHIYA' },
    { label: '062|RATMALANA', value: '062|RATMALANA' },
    { label: '063|AKURESSA', value: '063|AKURESSA' },
    { label: '064|RAJAGIRIAYA', value: '064|RAJAGIRIAYA' },
    { label: '065|ATTIDIYA', value: '065|ATTIDIYA' },
    { label: '066|KOHUWALA', value: '066|KOHUWALA' },
    { label: '067|NEW BORELLA', value: '067|NEW BORELLA' },
    { label: '068|PERADENIYA', value: '068|PERADENIYA' },
    { label: '069|BANDARAWELA', value: '069|BANDARAWELA' },
    { label: '997|UB CORPORATE', value: '997|UB CORPORATE' },
    { label: '999|UNION BANK COLOMBO', value: '999|UNION BANK COLOMBO' },
  ],
  'PAN ASIA BANKING CORPORATION PLC': [
    { label: '001|METRO', value: '001|METRO' },
    { label: '002|PANCHIKAWATTE', value: '002|PANCHIKAWATTE' },
    { label: '003|KOLLUPITIYA', value: '003|KOLLUPITIYA' },
    { label: '004|PETTAH', value: '004|PETTAH' },
    { label: '005|KANDY', value: '005|KANDY' },
    { label: '006|RAJAGIRIYA', value: '006|RAJAGIRIYA' },
    { label: '007|RATNAPURA', value: '007|RATNAPURA' },
    { label: '008|NUGEGODA', value: '008|NUGEGODA' },
    { label: '009|BAMBALAPITIYA', value: '009|BAMBALAPITIYA' },
    { label: '010|NEGOMBO', value: '010|NEGOMBO' },
    { label: '011|GAMPHA', value: '011|GAMPHA' },
    { label: '012|KURUNEGALA', value: '012|KURUNEGALA' },
    { label: '013|MATARA', value: '013|MATARA' },
    { label: '014|KOTAHENA', value: '014|KOTAHENA' },
    { label: '015|DEHIWELA', value: '015|DEHIWELA' },
    { label: '016|WATTALAA', value: '016|WATTALAA' },
    { label: '017|PANADURA', value: '017|PANADURA' },
    { label: '018|OLD MOOR STREET', value: '018|OLD MOOR STREET' },
    { label: '019|DAM STREET', value: '019|DAM STREET' },
    { label: '020|KATUGASTOTA', value: '020|KATUGASTOTA' },
    { label: '021|NARAHEPITIYA', value: '021|NARAHEPITIYA' },
    { label: '022|KIRULAPANA', value: '022|KIRULAPANA' },
    { label: '023|MAHARAGAMA', value: '023|MAHARAGAMA' },
    { label: '024|MORATUWA', value: '024|MORATUWA' },
    { label: '025|GALLE', value: '025|GALLE' },
    { label: '026|KADAWATHA', value: '026|KADAWATHA' },
    { label: '027|KEGALLE', value: '027|KEGALLE' },
    { label: '028|WENNAPPUWA', value: '028|WENNAPPUWA' },
    { label: '029|WELLAWATTA', value: '029|WELLAWATTA' },
    { label: '030|GAMPOLA', value: '030|GAMPOLA' },
    { label: '031|BORELLA', value: '031|BORELLA' },
    { label: '032|ANURADHAPURA', value: '032|ANURADHAPURA' },
    { label: '033|KALUTARA', value: '033|KALUTARA' },
    { label: '034|VAVUNIA', value: '034|VAVUNIA' },
    { label: '035|MALABE', value: '035|MALABE' },
    { label: '036|CHILAW', value: '036|CHILAW' },
    { label: '037|JAFFNA', value: '037|JAFFNA' },
    { label: '038|EMBILIPITIYA', value: '038|EMBILIPITIYA' },
    { label: '039|MATALE', value: '039|MATALE' },
    { label: '040|BATTICALOA', value: '040|BATTICALOA' },
    { label: '041|AMBALANGODA', value: '041|AMBALANGODA' },
    { label: '042|KALMUNAI', value: '042|KALMUNAI' },
    { label: '043|KILINOCHCHI', value: '043|KILINOCHCHI' },
    { label: '044|KANDY CITY CENTRE', value: '044|KANDY CITY CENTRE' },
    { label: '045|BADULLA', value: '045|BADULLA' },
    { label: '046|KULIYAPITIYA', value: '046|KULIYAPITIYA' },
    { label: '047|KALUBOWILA', value: '047|KALUBOWILA' },
    { label: '048|BANDARAWELA', value: '048|BANDARAWELA' },
    { label: '049|DAMBULLA', value: '049|DAMBULLA' },
    { label: '050|RATMALANA', value: '050|RATMALANA' },
    { label: '051|PERADENIYA', value: '051|PERADENIYA' },
    { label: '052|KADURUWELA', value: '052|KADURUWELA' },
    { label: '053|AMBALANTHOTA', value: '053|AMBALANTHOTA' },
    { label: '054|KIRIBATHGODA', value: '054|KIRIBATHGODA' },
    { label: '055|PILIYANDALA', value: '055|PILIYANDALA' },
    { label: '056|NELLIADY', value: '056|NELLIADY' },
    { label: '057|KANTHANKUDY', value: '057|KANTHANKUDY' },
    { label: '058|KUNDASALE', value: '058|KUNDASALE' },
    { label: '059|MONARAGALA', value: '059|MONARAGALA' },
    { label: '060|AKKARAIPATHTHU', value: '060|AKKARAIPATHTHU' },
    { label: '061|CHUNNAKAM', value: '061|CHUNNAKAM' },
    { label: '062|BALANGODA', value: '062|BALANGODA' },
    { label: '063|BATTARAMULLA', value: '063|BATTARAMULLA' },
    { label: '064|PUTTALAM', value: '064|PUTTALAM' },
    { label: '065|PILIMATHALAWA', value: '065|PILIMATHALAWA' },
    { label: '066|JA-ELA', value: '066|JA-ELA' },
    { label: '067|KEKIRAWA', value: '067|KEKIRAWA' },
    { label: '068|THALAWATHUGODA', value: '068|THALAWATHUGODA' },
    { label: '069|MINUWANGODA', value: '069|MINUWANGODA' },
    { label: '070|WARAKAPOLA', value: '070|WARAKAPOLA' },
    { label: '071|GALEWELA', value: '071|GALEWELA' },
    { label: '072|AKURESSA', value: '072|AKURESSA' },
    { label: '073|TRINCOMALEE', value: '073|TRINCOMALEE' },
    { label: '074|TANGALLE', value: '074|TANGALLE' },
    { label: '075|HATTON', value: '075|HATTON' },
    { label: '076|HOMAGAMA', value: '076|HOMAGAMA' },
    { label: '077|HORANA', value: '077|HORANA' },
    { label: '078|COLOMBO GOLD CENTRE', value: '078|COLOMBO GOLD CENTRE' },
    { label: '079|NAWALA', value: '079|NAWALA' },
    { label: '080|KOTTAWA', value: '080|KOTTAWA' },
    { label: '081|KADUWELA', value: '081|KADUWELA' },
    { label: '082|MAWANELLA', value: '082|MAWANELLA' },
    { label: '083|DANKOTUWA', value: '083|DANKOTUWA' },
    { label: '084|HIKKADUWA', value: '084|HIKKADUWA' },
    { label: '085|WELIGAMA', value: '085|WELIGAMA' },
    { label: '814|INT.REMITTANCE (VIRTUAL BR)', value: '814|INT.REMITTANCE (VIRTUAL BR)' },
    { label: '999|RUPEE DRAFT CLEARING', value: '999|RUPEE DRAFT CLEARING' },
  ],
  'DFCC BANK PLC': [
    { label: '001|HEAD OFFICE', value: '001|HEAD OFFICE' },
    { label: '002|NUGEGODA', value: '002|NUGEGODA' },
    { label: '003|MALABE', value: '003|MALABE' },
    { label: '004|MATARA', value: '004|MATARA' },
    { label: '005|KURUNEGALA', value: '005|KURUNEGALA' },
    { label: '006|KANDY', value: '006|KANDY' },
    { label: '007|CITY', value: '007|CITY' },
    { label: '008|RATNAPURA', value: '008|RATNAPURA' },
    { label: '009|ANURADHAPURA', value: '009|ANURADHAPURA' },
    { label: '010|GAMPAHA', value: '010|GAMPAHA' },
    { label: '011|BADULLA', value: '011|BADULLA' },
    { label: '012|BORELLA', value: '012|BORELLA' },
    { label: '014|KADURUWELA', value: '014|KADURUWELA' },
    { label: '015|BANDARANAYAKE MAWATHA', value: '015|BANDARANAYAKE MAWATHA' },
    { label: '016|MAHARAGAMA', value: '016|MAHARAGAMA' },
    { label: '017|BADARAWELA', value: '017|BADARAWELA' },
    { label: '018|NEGOMBO', value: '018|NEGOMBO' },
    { label: '019|KOTTAWA', value: '019|KOTTAWA' },
    { label: '020|DABULLA', value: '020|DABULLA' },
    { label: '021|WATTALA', value: '021|WATTALA' },
    { label: '022|KULIYAPITIYA', value: '022|KULIYAPITIYA' },
    { label: '023|PANADURA', value: '023|PANADURA' },
    { label: '024|PILIYANDALA', value: '024|PILIYANDALA' },
    { label: '025|DENIYAYA', value: '025|DENIYAYA' },
    { label: '026|KALUTARA', value: '026|KALUTARA' },
    { label: '027|KIRIBATHGODA', value: '027|KIRIBATHGODA' },
    { label: '028|NAWALA', value: '028|NAWALA' },
    { label: '029|KADAWATHA', value: '029|KADAWATHA' },
    { label: '030|GAMPOLA', value: '030|GAMPOLA' },
    { label: '031|MATALE', value: '031|MATALE' },
    { label: '032|CHILAW', value: '032|CHILAW' },
    { label: '033|WELLAWATTE', value: '033|WELLAWATTE' },
    { label: '034|HORANA', value: '034|HORANA' },
    { label: '035|GALLE', value: '035|GALLE' },
    { label: '036|NUWARAELIYA', value: '036|NUWARAELIYA' },
    { label: '037|KALAWANA', value: '037|KALAWANA' },
    { label: '038|AMBALANGODA', value: '038|AMBALANGODA' },
    { label: '039|AVISSAWELLA', value: '039|AVISSAWELLA' },
    { label: '040|BATTICALOA', value: '040|BATTICALOA' },
    { label: '041|AMPARA', value: '041|AMPARA' },
    { label: '042|JAFFNA', value: '042|JAFFNA' },
    { label: '043|MORATUWA', value: '043|MORATUWA' },
    { label: '044|TRINCOMALEE', value: '044|TRINCOMALEE' },
    { label: '045|EMBILIPITIYA', value: '045|EMBILIPITIYA' },
    { label: '046|PETTAH PEOPLES PARK', value: '046|PETTAH PEOPLES PARK' },
    { label: '047|VAVUNIA', value: '047|VAVUNIA' },
    { label: '048|KATUGASTOTA', value: '048|KATUGASTOTA' },
    { label: '049|KEGALLE', value: '049|KEGALLE' },
    { label: '050|MONARAGALA', value: '050|MONARAGALA' },
    { label: '051|KALMUNAI', value: '051|KALMUNAI' },
    { label: '052|KILINOCHCHI', value: '052|KILINOCHCHI' },
    { label: '053|ELPITIYA', value: '053|ELPITIYA' },
    { label: '054|AKURESSA', value: '054|AKURESSA' },
    { label: '055|KATTANKUDY', value: '055|KATTANKUDY' },
    { label: '056|TANGALLE', value: '056|TANGALLE' },
    { label: '057|ODDAMAVADI', value: '057|ODDAMAVADI' },
    { label: '058|AKKARAIPATTU', value: '058|AKKARAIPATTU' },
    { label: '059|CHUNNAKAM', value: '059|CHUNNAKAM' },
    { label: '060|MANIPAI', value: '060|MANIPAI' },
    { label: '061|NELLIADY', value: '061|NELLIADY' },
    { label: '062|HAMBANTOTA', value: '062|HAMBANTOTA' },
    { label: '063|JA-ELA', value: '063|JA-ELA' },
    { label: '064|KOTAHENA', value: '064|KOTAHENA' },
    { label: '065|DIGANA', value: '065|DIGANA' },
    { label: '066|THAMBUTTEGAMA', value: '066|THAMBUTTEGAMA' },
    { label: '067|GALEWELA', value: '067|GALEWELA' },
    { label: '068|EHELIYAGODA', value: '068|EHELIYAGODA' },
    { label: '069|MATHUGAMA', value: '069|MATHUGAMA' },
    { label: '070|NARAMMALA', value: '070|NARAMMALA' },
    { label: '071|NIKAWERATIYA', value: '071|NIKAWERATIYA' },
    { label: '072|MOUNT LAVINIA', value: '072|MOUNT LAVINIA' },
    { label: '073|HATTON', value: '073|HATTON' },
    { label: '074|WELIMADA', value: '074|WELIMADA' },
    { label: '075|IBBAGAMUWA', value: '075|IBBAGAMUWA' },
    { label: '076|GANGODAWILA', value: '076|GANGODAWILA' },
    { label: '077|WELIGAMA', value: '077|WELIGAMA' },
    { label: '078|KADUWELA', value: '078|KADUWELA' },
    { label: '079|HINGURAKGODA', value: '079|HINGURAKGODA' },
    { label: '080|BALANGODA', value: '080|BALANGODA' },
    { label: '081|BANDARAGAMA', value: '081|BANDARAGAMA' },
    { label: '082|PERADENIYA', value: '082|PERADENIYA' },
    { label: '083|KANDY CITY CENTRE', value: '083|KANDY CITY CENTRE' },
    { label: '084|DEHIATTAKANDIYA', value: '084|DEHIATTAKANDIYA' },
    { label: '085|ARALAGANWILA', value: '085|ARALAGANWILA' },
    { label: '086|MEDIRIGIRIYA', value: '086|MEDIRIGIRIYA' },
    { label: '087|PETTAH MAIN STREET', value: '087|PETTAH MAIN STREET' },
    { label: '088|KAHAWATTA', value: '088|KAHAWATTA' },
    { label: '089|KOCHCHIKADE', value: '089|KOCHCHIKADE' },
    { label: '090|GIRIULLA', value: '090|GIRIULLA' },
    { label: '091|WENNAPPUWA', value: '091|WENNAPPUWA' },
    { label: '092|HIKKADUWA', value: '092|HIKKADUWA' },
    { label: '093|NAWALAPITIYA', value: '093|NAWALAPITIYA' },
    { label: '094|DANKOTUWA', value: '094|DANKOTUWA' },
    { label: '095|AMBALANTOTA', value: '095|AMBALANTOTA' },
    { label: '096|WATTEGAMA', value: '096|WATTEGAMA' },
    { label: '097|TISSAMAHARAMA', value: '097|TISSAMAHARAMA' },
    { label: '098|MORAWAKA', value: '098|MORAWAKA' },
    { label: '099|BADDEGAMA', value: '099|BADDEGAMA' },
    { label: '100|MATARA CITY', value: '100|MATARA CITY' },
    { label: '101|NIVITHIGALA', value: '101|NIVITHIGALA' },
    { label: '102|URUBOKKA', value: '102|URUBOKKA' },
    { label: '103|BUTTALA', value: '103|BUTTALA' },
    { label: '104|WELLAWAYA', value: '104|WELLAWAYA' },
    { label: '105|EPPAWALA', value: '105|EPPAWALA' },
    { label: '106|MARAWILLA', value: '106|MARAWILLA' },
    { label: '107|MAWANELLA', value: '107|MAWANELLA' },
    { label: '108|RUWANWELLA', value: '108|RUWANWELLA' },
    { label: '109|GODAKAWELA', value: '109|GODAKAWELA' },
    { label: '110|MAHIYANGANAYA', value: '110|MAHIYANGANAYA' },
    { label: '111|FORT SUPER GRADE BRANCH', value: '111|FORT SUPER GRADE BRANCH' },
    { label: '112|KURUWITA', value: '112|KURUWITA' },
    { label: '113|KEKIRAWA', value: '113|KEKIRAWA' },
    { label: '114|MINUWANGODA', value: '114|MINUWANGODA' },
    { label: '115|ATHURUGIRIYA', value: '115|ATHURUGIRIYA' },
    { label: '116|AGALAWATTA', value: '116|AGALAWATTA' },
    { label: '117|BELIATTA', value: '117|BELIATTA' },
    { label: '118|MAHA OYA', value: '118|MAHA OYA' },
    { label: '119|UHANA', value: '119|UHANA' },
    { label: '120|KARANDENIYA', value: '120|KARANDENIYA' },
    { label: '121|DICKWELLA', value: '121|DICKWELLA' },
    { label: '122|VEYANGODA', value: '122|VEYANGODA' },
    { label: '123|BADURALIYA', value: '123|BADURALIYA' },
    { label: '124|BIBILE', value: '124|BIBILE' },
    { label: '501|SOUTHERN PROVINCE SLP UNITS', value: '501|SOUTHERN PROVINCE SLP UNITS' },
    { label: '511|WESTERNPROVINCE SLP UNITS', value: '511|WESTERNPROVINCE SLP UNITS' },
    { label: '521|NORTH WESTERN PROVINCE SLP UNI', value: '521|NORTH WESTERN PROVINCE SLP UNI' },
    { label: '531|CENTRAL PROVINCE SLP UNITS', value: '531|CENTRAL PROVINCE SLP UNITS' },
    { label: '541|SABARAGAMUWA PROVINCE SLP UNIS', value: '541|SABARAGAMUWA PROVINCE SLP UNIS' },
    { label: '551|NORTH CENTRAL PROVINCE SLP UNI', value: '551|NORTH CENTRAL PROVINCE SLP UNI' },
    { label: '561|EASTERNPROVINCE SLP UNITS', value: '561|EASTERNPROVINCE SLP UNITS' },
    { label: '571|UVA PROVINCE SLP UNITS', value: '571|UVA PROVINCE SLP UNITS' },
    { label: '700|PREMIER BANKINGCENTRE', value: '700|PREMIER BANKINGCENTRE' },
    { label: '800|VIRTUAL BRANCH', value: '800|VIRTUAL BRANCH' },
    { label: '888|DFCC RUPEE DRAFT CLEARING', value: '888|DFCC RUPEE DRAFT CLEARING' },
    { label: '999|HEAD OFFICE', value: '999|HEAD OFFICE' },
  ],
  'AMANA BANK PLC': [
    { label: '001|HEAD OFFICE', value: '001|HEAD OFFICE' },
    { label: '002|PETTAH', value: '002|PETTAH' },
    { label: '003|KANDY', value: '003|KANDY' },
    { label: '004|KATTANKUDY', value: '004|KATTANKUDY' },
    { label: '005|LADIES', value: '005|LADIES' },
    { label: '006|KALMUNAI', value: '006|KALMUNAI' },
    { label: '008|GALLE', value: '008|GALLE' },
    { label: '009|ODDAMAVADI', value: '009|ODDAMAVADI' },
    { label: '010|AKURANA', value: '010|AKURANA' },
    { label: '011|GAMPOLA', value: '011|GAMPOLA' },
    { label: '012|SAMANTHURAI', value: '012|SAMANTHURAI' },
    { label: '013|MAWANELLA', value: '013|MAWANELLA' },
    { label: '014|KURUNEGALA', value: '014|KURUNEGALA' },
    { label: '015|AKKARAIPATTU', value: '015|AKKARAIPATTU' },
    { label: '016|DEHIWELA', value: '016|DEHIWELA' },
    { label: '017|NINTAVUR', value: '017|NINTAVUR' },
    { label: '018|KULIYAPITIYA', value: '018|KULIYAPITIYA' },
    { label: '019|ERAVUR', value: '019|ERAVUR' },
    { label: '020|NEGOMBO', value: '020|NEGOMBO' },
    { label: '021|BADULLA', value: '021|BADULLA' },
    { label: '022|KADURUWELA', value: '022|KADURUWELA' },
    { label: '023|PUTTALAM', value: '023|PUTTALAM' },
    { label: '024|KINNIYA', value: '024|KINNIYA' },
    { label: '025|RATNAPURA', value: '025|RATNAPURA' },
    { label: '026|KALMUNAI UNITY SQUARE', value: '026|KALMUNAI UNITY SQUARE' },
    { label: '027|OLD MOOR STREET', value: '027|OLD MOOR STREET' },
    { label: '028|DEMATAGODA', value: '028|DEMATAGODA' },
    { label: '029|KIRULAPONE', value: '029|KIRULAPONE' },
    { label: '030|KATUGASTOTA', value: '030|KATUGASTOTA' },
    { label: '031|BERUWALA', value: '031|BERUWALA' },
    { label: '032|PANADURA', value: '032|PANADURA' },
    { label: '033|MATALE', value: '033|MATALE' },
    { label: '999|ABL RUPEE DRAFT CLEARING', value: '999|ABL RUPEE DRAFT CLEARING' },
  ],
  'CARGILLS BANK LIMITED': [
    { label: '001|CORPORATE BRANCH', value: '001|CORPORATE BRANCH' },
    { label: '002|MAITLAND CRESCENT', value: '002|MAITLANDCRESCENT' },
    { label: '003|OLD MOOR STREET', value: '003|OLD MOOR STREET' },
    { label: '004|MATARA', value: '004|MATARA' },
    { label: '005|MAHARAGAMA', value: '005|MAHARAGAMA' },
    { label: '006|VAVUNIA', value: '006|VAVUNIA' },
    { label: '007|HAWAELIYA', value: '007|HAWAELIYA' },
    { label: '008|THANAMALWILA', value: '008|THANAMALWILA' },
    { label: '009|KURUNAGALA', value: '009|KURUNAGALA' },
    { label: '010|JAFFNA CITY', value: '010|JAFFNA CITY' },
    { label: '011|CHUNNAKKAM', value: '011|CHUNNAKKAM' },
    { label: '012|GALLE', value: '012|GALLE' },
    { label: '013|NUWARA ELIYA', value: '013|NUWARA ELIYA' },
    { label: '014|KANDY', value: '014|KANDY' },
    { label: '015|PERADENIYA', value: '015|PERADENIYA' },
    { label: '016|RATNAPURA', value: '016|RATNAPURA' },
    { label: '017|CHILAW', value: '017|CHILAW' },
    { label: '018|WATTALA', value: '018|WATTALA' },
    { label: '019|KADURUWELA', value: '019|KADURUWELA' },
    { label: '020|RAJAGIRIYA', value: '020|RAJAGIRIYA' },
    { label: '021|FORT', value: '021|FORT' },
    { label: '022|NAWALAPITIYA', value: '022|NAWALAPITIYA' },
    { label: '925|CENTRAL PROCESSING OFFICE', value: '925|CENTRAL PROCESSING OFFICE' },
  ],
  'SOFTLOGIC FINANCE PLC': [{ label: '001|HEAD OFFICE', value: '001|HEAD OFFICE' }],
  'REGIONAL RURAL DEV. BANK': [{ label: '001|RRDB POLLONNARUWA', value: '001|RRDB POLLONNARUWA' }],
  'BANK OF CHINA LIMITED': [{ label: '888|COLOMBO BRANCH', value: '888|COLOMBO BRANCH' }],
  'NATIONAL SAVINGS BANK': [
    { label: '001|HEAD OFFICE', value: '001|HEAD OFFICE' },
    { label: '002|CITY OFFICE', value: '002|CITY OFFICE' },
    { label: '003|GALLE', value: '003|GALLE' },
    { label: '004|MATARA', value: '004|MATARA' },
    { label: '005|ANURADHAPURA', value: '005|ANURADHAPURA' },
    { label: '006|JAFFNA', value: '006|JAFFNA' },
    { label: '007|CHILAW', value: '007|CHILAW' },
    { label: '008|KULIYAPITIYA', value: '008|KULIYAPITIYA' },
    { label: '009|NEGOMBO', value: '009|NEGOMBO' },
    { label: '010|RATNAPURA', value: '010|RATNAPURA' },
    { label: '011|AMBALANTOTA', value: '011|AMBALANTOTA' },
    { label: '012|KALUTARA', value: '012|KALUTARA' },
    { label: '013|EMBILIPITIYA', value: '013|EMBILIPITIYA' },
    { label: '014|KEKIRAWA', value: '014|KEKIRAWA' },
    { label: '015|KANDY', value: '015|KANDY' },
    { label: '016|MATALE', value: '016|MATALE' },
    { label: '017|KURUNEGALA', value: '017|KURUNEGALA' },
    { label: '018|KEGALLE', value: '018|KEGALLE' },
    { label: '019|KILINOCHCHI', value: '019|KILINOCHCHI' },
    { label: '020|MORATUWA', value: '020|MORATUWA' },
    { label: '021|BATTICALOA', value: '021|BATTICALOA' },
    { label: '022|BADULLA', value: '022|BADULLA' },
    { label: '023|BAMBALAPITIYA', value: '023|BAMBALAPITIYA' },
    { label: '024|DEHIWALA', value: '024|DEHIWALA' },
    { label: '025|PELIYAGODA', value: '025|PELIYAGODA' },
    { label: '026|NUGEGODA', value: '026|NUGEGODA' },
    { label: '027|HOMAGAMA', value: '027|HOMAGAMA' },
    { label: '028|BERUWALA', value: '028|BERUWALA' },
    { label: '029|WENNAPPUWA', value: '029|WENNAPPUWA' },
    { label: '030|AMPARA', value: '030|AMPARA' },
    { label: '031|KOCHCHIKADE', value: '031|KOCHCHIKADE' },
    { label: '032|POINT PEDRO', value: '032|POINT PEDRO' },
    { label: '033|AMBALANGODA', value: '033|AMBALANGODA' },
    { label: '034|NATTANDIYA', value: '034|NATTANDIYA' },
    { label: '035|RUWANWELLA', value: '035|RUWANWELLA' },
    { label: '036|JA-ELA', value: '036|JA-ELA' },
    { label: '037|GAMPAHA', value: '037|GAMPAHA' },
    { label: '038|NIL BRANCH', value: '038|NIL BRANCH' },
    { label: '039|DEVINUWARA', value: '039|DEVINUWARA' },
    { label: '040|NIKAWERATIYA', value: '040|NIKAWERATIYA' },
    { label: '041|MAHIYANGANAYA', value: '041|MAHIYANGANAYA' },
    { label: '042|WARAKAPOLA', value: '042|WARAKAPOLA' },
    { label: '043|PANADURA', value: '043|PANADURA' },
    { label: '044|PUTTALAM', value: '044|PUTTALAM' },
    { label: '045|MATHUGAMA', value: '045|MATHUGAMA' },
    { label: '046|MONARAGALA', value: '046|MONARAGALA' },
    { label: '047|KALMUNAI', value: '047|KALMUNAI' },
    { label: '048|BELIATTA', value: '048|BELIATTA' },
    { label: '049|MANNAR', value: '049|MANNAR' },
    { label: '050|NAWALAPITIYA', value: '050|NAWALAPITIYA' },
    { label: '051|PETTAH', value: '051|PETTAH' },
    { label: '052|KATUNAYAKE', value: '052|KATUNAYAKE' },
    { label: '053|MAHARAGAMA', value: '053|MAHARAGAMA' },
    { label: '054|DENIYAYA', value: '054|DENIYAYA' },
    { label: '055|AKURESSA', value: '055|AKURESSA' },
    { label: '056|NUWARAELIYA', value: '056|NUWARAELIYA' },
    { label: '057|AVISSAWELLA', value: '057|AVISSAWELLA' },
    { label: '058|GALNEWA', value: '058|GALNEWA' },
    { label: '059|MAWANELLA', value: '059|MAWANELLA' },
    { label: '060|BANDARAWELA', value: '060|BANDARAWELA' },
    { label: '061|BORELLA', value: '061|BORELLA' },
    { label: '062|HAKMANA', value: '062|HAKMANA' },
    { label: '063|HORANA', value: '063|HORANA' },
    { label: '064|NARAHENPITA', value: '064|NARAHENPITA' },
    { label: '065|KOLLUPITIYA 2ND', value: '065|KOLLUPITIYA 2ND' },
    { label: '066|WELIGAMA', value: '066|WELIGAMA' },
    { label: '067|KIRIBATHGODA', value: '067|KIRIBATHGODA' },
    { label: '068|MOUNT LAVINIA', value: '068|MOUNT LAVINIA' },
    { label: '069|MARAWILA', value: '069|MARAWILA' },
    { label: '070|WELLAWATTA', value: '070|WELLAWATTA' },
    { label: '071|PILIYANDALA', value: '071|PILIYANDALA' },
    { label: '072|CHUNNAKAM', value: '072|CHUNNAKAM' },
    { label: '073|CHAVAKACHCHERI', value: '073|CHAVAKACHCHERI' },
    { label: '074|GAMPOLA', value: '074|GAMPOLA' },
    { label: '075|KADAWATHA', value: '075|KADAWATHA' },
    { label: '076|HINGURAKGODA', value: '076|HINGURAKGODA' },
    { label: '077|MALIGAWATTA', value: '077|MALIGAWATTA' },
    { label: '078|THALAWAKELE', value: '078|THALAWAKELE' },
    { label: '079|MIRIGAMA', value: '079|MIRIGAMA' },
    { label: '080|BATTARAMULLA', value: '080|BATTARAMULLA' },
    { label: '081|KANDY 2ND', value: '081|KANDY 2ND' },
    { label: '082|DIKWELLA', value: '082|DIKWELLA' },
    { label: '083|MAHABAGE', value: '083|MAHABAGE' },
    { label: '084|PILIMATALAWA', value: '084|PILIMATALAWA' },
    { label: '085|WATTALA', value: '085|WATTALA' },
    { label: '086|KAMBURUPITIYA', value: '086|KAMBURUPITIYA' },
    { label: '087|KOTAHENA', value: '087|KOTAHENA' },
    { label: '088|VAVUNIA', value: '088|VAVUNIA' },
    { label: '089|TRINCOMALEE', value: '089|TRINCOMALEE' },
    { label: '090|MORAWAKA', value: '090|MORAWAKA' },
    { label: '091|BALANGODA', value: '091|BALANGODA' },
    { label: '092|VEYANGODA', value: '092|VEYANGODA' },
    { label: '093|KATUBEDDA', value: '093|KATUBEDDA' },
    { label: '094|ELPITIYA', value: '094|ELPITIYA' },
    { label: '095|KADUWELA', value: '095|KADUWELA' },
    { label: '096|DIVULAPITIYA', value: '096|DIVULAPITIYA' },
    { label: '097|TISSAMAHARAMAYA', value: '097|TISSAMAHARAMAYA' },
    { label: '098|MINUWANGODA', value: '098|MINUWANGODA' },
    { label: '099|KIRINDIWELA', value: '099|KIRINDIWELA' },
    { label: '100|NITTAMBUWA', value: '100|NITTAMBUWA' },
    { label: '101|WELIMADA', value: '101|WELIMADA' },
    { label: '102|KOTTAWA', value: '102|KOTTAWA' },
    { label: '103|DAMBULLA', value: '103|DAMBULLA' },
    { label: '104|KAHATUDUWA', value: '104|KAHATUDUWA' },
    { label: '105|ALUTHGAMA', value: '105|ALUTHGAMA' },
    { label: '106|MEEGODA', value: '106|MEEGODA' },
    { label: '107|MANIPAY', value: '107|MANIPAY' },
    { label: '108|THIRUNAVELI', value: '108|THIRUNAVELI' },
    { label: '109|CHENKALADI', value: '109|CHENKALADI' },
    { label: '110|URAGASMANHANDIYA', value: '110|URAGASMANHANDIYA' },
    { label: '111|NAWALA', value: '111|NAWALA' },
    { label: '112|DERANIYAGALA', value: '112|DERANIYAGALA' },
    { label: '113|HIKKADUWA', value: '113|HIKKADUWA' },
    { label: '114|KALAWANCHIKUDY', value: '114|KALAWANCHIKUDY' },
    { label: '115|KALUBOWILA', value: '115|KALUBOWILA' },
    { label: '116|HATTON', value: '116|HATTON' },
    { label: '117|WELIKADA', value: '117|WELIKADA' },
    { label: '118|SAMANTHURAI', value: '118|SAMANTHURAI' },
    { label: '119|DELKANDA', value: '119|DELKANDA' },
    { label: '120|YAKKALA', value: '120|YAKKALA' },
    { label: '121|KARAPITIYA', value: '121|KARAPITIYA' },
    { label: '122|KADURUWELA', value: '122|KADURUWELA' },
    { label: '123|MALABE', value: '123|MALABE' },
    { label: '124|BORALESGAMUWA', value: '124|BORALESGAMUWA' },
    { label: '125|MORATUMULLA', value: '125|MORATUMULLA' },
    { label: '126|BANDARAGAMA', value: '126|BANDARAGAMA' },
    { label: '127|MULGAMPOLA', value: '127|MULGAMPOLA' },
    { label: '128|BADALKUMBURA', value: '128|BADALKUMBURA' },
    { label: '129|DANKOTUWA', value: '129|DANKOTUWA' },
    { label: '130|MULLATIVE', value: '130|MULLATIVE' },
    { label: '131|KODIKAMAM', value: '131|KODIKAMAM' },
    { label: '132|ALAWATHUGODA', value: '132|ALAWATHUGODA' },
    { label: '133|GODAKAWELA', value: '133|GODAKAWELA' },
    { label: '134|PERIYAKALLAR', value: '134|PERIYAKALLAR' },
    { label: '135|THAMABILUVIL', value: '135|THAMABILUVIL' },
    { label: '136|HORAWPATHANA', value: '136|HORAWPATHANA' },
    { label: '137|GALENBINDUNUWEWA', value: '137|GALENBINDUNUWEWA' },
    { label: '138|PITAKOTTE', value: '138|PITAKOTTE' },
    { label: '139|PITIGALA', value: '139|PITIGALA' },
    { label: '140|URUBOKKA', value: '140|URUBOKKA' },
    { label: '141|KANDANA', value: '141|KANDANA' },
    { label: '142|MAHO', value: '142|MAHO' },
    { label: '143|UDUGAMA', value: '143|UDUGAMA' },
    { label: '144|UHANA', value: '144|UHANA' },
    { label: '145|GALAGEDARA', value: '145|GALAGEDARA' },
    { label: '146|SIYAMBALANDUWA', value: '146|SIYAMBALANDUWA' },
    { label: '147|KOPAY', value: '147|KOPAY' },
    { label: '148|PERADENIYA', value: '148|PERADENIYA' },
    { label: '149|CITY PLUS', value: '149|CITY PLUS' },
    { label: '150|PILIYANDALA 2', value: '150|PILIYANDALA 2' },
    { label: '151|DEIYANDARA', value: '151|DEIYANDARA' },
    { label: '152|PANNALA', value: '152|PANNALA' },
    { label: '153|MAKOLA', value: '153|MAKOLA' },
    { label: '155|KATUWANA', value: '155|KATUWANA' },
    { label: '701|RAGAMA', value: '701|RAGAMA' },
    { label: '702|WORLD TRADE CENTRE NSB PIYASA', value: '702|WORLD TRADE CENTRE NSB PIYASA' },
    { label: '703|ATHURUGIRIYA', value: '703|ATHURUGIRIYA' },
    { label: '704|NELUWA PIYASA', value: '704|NELUWA PIYASA' },
    { label: '705|GANEMULLA PIYASA', value: '705|GANEMULLA PIYASA' },
    { label: '706|WELLAMPITIYA', value: '706|WELLAMPITIYA' },
    { label: '707|NARAMMALA PIYASA', value: '707|NARAMMALA PIYASA' },
    { label: '708|BIBILE', value: '708|BIBILE' },
    { label: '709|HETTIPOLA PIYASA', value: '709|HETTIPOLA PIYASA' },
    { label: '710|BULATHKOHUPITIYA', value: '710|BULATHKOHUPITIYA' },
    { label: '711|KANDY CITY CENTER', value: '711|KANDY CITY CENTER' },
    { label: '712|WEERAKETIYA PIYASA', value: '712|WEERAKETIYA PIYASA' },
    { label: '713|TANGALLE PIYASA', value: '713|TANGALLE PIYASA' },
    { label: '714|WALASMULLA PIYASA', value: '714|WALASMULLA PIYASA' },
    { label: '715|WADDUWA', value: '715|WADDUWA' },
    { label: '716|KELANIYA PIYASA', value: '716|KELANIYA PIYASA' },
    { label: '717|ATCHUVELY PIYASA', value: '717|ATCHUVELY PIYASA' },
    { label: '718|NELLIADY', value: '718|NELLIADY' },
    { label: '719|PUWAKARAMBA PIYASA', value: '719|PUWAKARAMBA PIYASA' },
    { label: '720|VALACHCHENA', value: '720|VALACHCHENA' },
    { label: '721|ARANAYAKA', value: '721|ARANAYAKA' },
    { label: '722|RIKILLAGASKADA', value: '722|RIKILLAGASKADA' },
    { label: '723|KATUGASTOTA', value: '723|KATUGASTOTA' },
    { label: '724|MIDDENIYA PIYASA', value: '724|MIDDENIYA PIYASA' },
    { label: '725|CHANKANAI', value: '725|CHANKANAI' },
    { label: '726|POLGAHAWELA', value: '726|POLGAHAWELA' },
    { label: '727|ARAYAMPATHY', value: '727|ARAYAMPATHY' },
    { label: '728|RADDOLUGAMA', value: '728|RADDOLUGAMA' },
    { label: '729|GALGAMUWA', value: '729|GALGAMUWA' },
    { label: '730|KAYTS', value: '730|KAYTS' },
    { label: '731|KARAINAGAR', value: '731|KARAINAGAR' },
    { label: '732|MUTWAL', value: '732|MUTWAL' },
    { label: '733|MULLERIYAWA', value: '733|MULLERIYAWA' },
    { label: '734|BADDEGAMA', value: '734|BADDEGAMA' },
    { label: '735|HABARADUWA', value: '735|HABARADUWA' },
    { label: '736|PELMADULLA', value: '736|PELMADULLA' },
    { label: '737|KAHAWATTA', value: '737|KAHAWATTA' },
    { label: '738|BULATHSINHALA', value: '738|BULATHSINHALA' },
    { label: '739|KALAWANA', value: '739|KALAWANA' },
    { label: '740|WELLAWAYA', value: '740|WELLAWAYA' },
    { label: '741|BUTTALA', value: '741|BUTTALA' },
    { label: '742|ALAWWA', value: '742|ALAWWA' },
    { label: '743|YATIYANTHOTA', value: '743|YATIYANTHOTA' },
    { label: '744|PUNDALUOYA', value: '744|PUNDALUOYA' },
    { label: '745|NOCHCHIYAGAMA', value: '745|NOCHCHIYAGAMA' },
    { label: '746|ANAMADUWA', value: '746|ANAMADUWA' },
    { label: '747|DELGODA', value: '747|DELGODA' },
    { label: '748|DIGANA', value: '748|DIGANA' },
    { label: '749|MAWATHAGAMA', value: '749|MAWATHAGAMA' },
    { label: '750|HANWELLA', value: '750|HANWELLA' },
    { label: '751|HALI ELA', value: '751|HALI ELA' },
    { label: '752|AKKARAIPATTU', value: '752|AKKARAIPATTU' },
    { label: '753|PALUGAMAMA', value: '753|PALUGAMAMA' },
    { label: '754|SURIYAWEWA', value: '754|SURIYAWEWA' },
    { label: '755|GALEWELA', value: '755|GALEWELA' },
    { label: '756|THAMBUTTEGAMA', value: '756|THAMBUTTEGAMA' },
    { label: '757|IMADUWA', value: '757|IMADUWA' },
    { label: '758|PASSARA', value: '758|PASSARA' },
    { label: '759|KAHATAGASDIGILIYA', value: '759|KAHATAGASDIGILIYA' },
    { label: '760|AGUNAKOLAPELESSA', value: '760|AGUNAKOLAPELESSA' },
    { label: '761|MADAMPE', value: '761|MADAMPE' },
    { label: '762|KANTALE', value: '762|KANTALE' },
    { label: '763|MADAWACHCHIYA', value: '763|MADAWACHCHIYA' },
    { label: '764|INGIRIYA', value: '764|INGIRIYA' },
    { label: '765|RAMBUKKANA', value: '765|RAMBUKKANA' },
    { label: '766|WARIYAPOLA', value: '766|WARIYAPOLA' },
    { label: '767|KEBITHIGOLLEWA', value: '767|KEBITHIGOLLEWA' },
    { label: '768|WATHTHEGAMA', value: '768|WATHTHEGAMA' },
    { label: '769|PUSSELLAWA', value: '769|PUSSELLAWA' },
    { label: '770|POTUVIL', value: '770|POTUVIL' },
    { label: '771|IBBAGAMUWA', value: '771|IBBAGAMUWA' },
    { label: '772|KURUWITA', value: '772|KURUWITA' },
    { label: '773|VANKALAI', value: '773|VANKALAI' },
    { label: '774|MALLAVI', value: '774|MALLAVI' },
    { label: '775|KEKANADURA', value: '775|KEKANADURA' },
    { label: '776|MEDIRIGIRIYA', value: '776|MEDIRIGIRIYA' },
    { label: '777|NIVITHIGALA', value: '777|NIVITHIGALA' },
    { label: '778|KIRIELLA', value: '778|KIRIELLA' },
    { label: '779|NAULA', value: '779|NAULA' },
    { label: '780|MELSIRIPURA', value: '780|MELSIRIPURA' },
    { label: '781|HAMBANTOTA', value: '781|HAMBANTOTA' },
    { label: '782|RIDEEGAMA', value: '782|RIDEEGAMA' },
    { label: '783|DELTOTA', value: '783|DELTOTA' },
    { label: '784|GINIGATHHENA', value: '784|GINIGATHHENA' },
    { label: '785|BINGIRIYA', value: '785|BINGIRIYA' },
    { label: '786|BADURALIYA', value: '786|BADURALIYA' },
    { label: '787|GIRIULLA', value: '787|GIRIULLA' },
    { label: '788|EPPAWALA', value: '788|EPPAWALA' },
    { label: '789|THALAWATHUGODA', value: '789|THALAWATHUGODA' },
    { label: '790|PUGODA', value: '790|PUGODA' },
    { label: '791|GELIOYA', value: '791|GELIOYA' },
    { label: '792|RAGALA', value: '792|RAGALA' },
    { label: '901|HEAD OFFICE-PBU', value: '901|HEAD OFFICE-PBU' },
    { label: '902|KANDY-PBU', value: '902|KANDY-PBU' },
    { label: '903|GALLE-PBU', value: '903|GALLE-PBU' },
    { label: '904|MATARA-PBU', value: '904|MATARA-PBU' },
    { label: '905|KEGALLE-PBU', value: '905|KEGALLE-PBU' },
    { label: '906|ANURADHAPURA-PBU', value: '906|ANURADHAPURA-PBU' },
    { label: '907|KALUTARA', value: '907|KALUTARA' },
    { label: '908|GAMPAHA', value: '908|GAMPAHA' },
    { label: '909|AMPARAA', value: '909|AMPARAA' },
    { label: '910|CREDIT DIVISION', value: '910|CREDIT DIVISION' },
    { label: '911|IBU(NRFC)', value: '911|IBU(NRFC)' },
    { label: '912|BR MANAGENENT DIVISION', value: '912|BR MANAGENENT DIVISION' },
    { label: '915|PBU-KURUNEGALA', value: '915|PBU-KURUNEGALA' },
    { label: '916|JAFFNA POSTAL', value: '916|JAFFNA POSTAL' },
    { label: '917|EHELIYAGODA POSTAL', value: '917|EHELIYAGODA POSTAL' },
  ],
  'SANASA DEVELOPMENT BANK LTD': [
    { label: '001|1 ST COLOMBO CITY', value: '001|1 ST COLOMBO CITY' },
    { label: '002|KEGALLE', value: '002|KEGALLE' },
    { label: '003|BATTARAMULLA', value: '003|BATTARAMULLA' },
    { label: '004|EMBILIPITIYA', value: '004|EMBILIPITIYA' },
    { label: '005|HORANA', value: '005|HORANA' },
    { label: '006|KIRIBATHGODA', value: '006|KIRIBATHGODA' },
    { label: '007|KARAPITIYA', value: '007|KARAPITIYA' },
    { label: '008|AKURESSA', value: '008|AKURESSA' },
    { label: '009|MATALE', value: '009|MATALE' },
    { label: '010|KANDY', value: '010|KANDY' },
    { label: '011|CHILAW', value: '011|CHILAW' },
    { label: '012|VAVUNIA', value: '012|VAVUNIA' },
    { label: '013|MONARAGALA', value: '013|MONARAGALA' },
    { label: '014|RUWANWELLA', value: '014|RUWANWELLA' },
    { label: '015|RATNAPURA', value: '015|RATNAPURA' },
    { label: '016|WARAKAPOLA', value: '016|WARAKAPOLA' },
    { label: '017|ANURADHAPURA', value: '017|ANURADHAPURA' },
    { label: '018|SAHASAPURA', value: '018|SAHASAPURA' },
    { label: '019|RIKILLAGASKADA', value: '019|RIKILLAGASKADA' },
    { label: '020|KURUNEGALA', value: '020|KURUNEGALA' },
    { label: '021|TRINCOMALEE', value: '021|TRINCOMALEE' },
    { label: '022|KALMUNAI', value: '022|KALMUNAI' },
    { label: '023|AMBALANTHOTA', value: '023|AMBALANTHOTA' },
    { label: '025|KALUTARA', value: '025|KALUTARA' },
    { label: '026|KULIYAPITIYA', value: '026|KULIYAPITIYA' },
    { label: '027|NEGOMBO', value: '027|NEGOMBO' },
    { label: '028|POLONNARUWA', value: '028|POLONNARUWA' },
    { label: '029|BATTICALOA', value: '029|BATTICALOA' },
    { label: '030|AMBALANGODA', value: '030|AMBALANGODA' },
    { label: '031|MATARA', value: '031|MATARA' },
    { label: '032|GALLE', value: '032|GALLE' },
    { label: '033|GIRIULLA', value: '033|GIRIULLA' },
    { label: '034|RAMBUKKANA', value: '034|RAMBUKKANA' },
    { label: '035|DAMBULLA', value: '035|DAMBULLA' },
    { label: '036|THAMBUTHTHEGAMA', value: '036|THAMBUTHTHEGAMA' },
    { label: '037|MAHO', value: '037|MAHO' },
    { label: '038|WENNAPPUWA', value: '038|WENNAPPUWA' },
    { label: '039|AMPARA', value: '039|AMPARA' },
    { label: '040|MEDAWACHCHIYA', value: '040|MEDAWACHCHIYA' },
    { label: '041|MUTHUR', value: '041|MUTHUR' },
    { label: '042|JAFFNA', value: '042|JAFFNA' },
    { label: '043|NANATTAN', value: '043|NANATTAN' },
    { label: '044|BADULLA', value: '044|BADULLA' },
    { label: '045|POTHUVIL', value: '045|POTHUVIL' },
    { label: '046|MAPALAGAMA', value: '046|MAPALAGAMA' },
    { label: '047|MATHUGAMA', value: '047|MATHUGAMA' },
    { label: '048|GAMPOLA', value: '048|GAMPOLA' },
    { label: '049|DENIYAYA', value: '049|DENIYAYA' },
    { label: '050|ANAMADUWA', value: '050|ANAMADUWA' },
    { label: '051|ANGUNUKOLAPALASSA', value: '051|ANGUNUKOLAPALASSA' },
    { label: '052|ARALAGANVILA', value: '052|ARALAGANVILA' },
    { label: '053|GALENBINDUNUWEWA', value: '053|GALENBINDUNUWEWA' },
    { label: '054|WARIYAPOLA', value: '054|WARIYAPOLA' },
    { label: '055|PILIMATHALAWA', value: '055|PILIMATHALAWA' },
    { label: '056|KIRULAPONA', value: '056|KIRULAPONA' },
    { label: '057|DERANIYAGALA', value: '057|DERANIYAGALA' },
    { label: '058|DEHIATHTHAKANDIYA', value: '058|DEHIATHTHAKANDIYA' },
    { label: '059|KALAWANCHCHIKUDI', value: '059|KALAWANCHCHIKUDI' },
    { label: '060|SAMANTHUREI', value: '060|SAMANTHUREI' },
    { label: '061|SIYABALANDUWA', value: '061|SIYABALANDUWA' },
    { label: '062|BUTTALA', value: '062|BUTTALA' },
    { label: '063|VALACHCHENAI', value: '063|VALACHCHENAI' },
    { label: '064|ELPITIYA', value: '064|ELPITIYA' },
    { label: '065|NOCHCHIYAGAMA', value: '065|NOCHCHIYAGAMA' },
    { label: '066|YAKKALAMULLAA', value: '066|YAKKALAMULLAA' },
    { label: '067|KATUWANA', value: '067|KATUWANA' },
    { label: '068|MAWANELLA', value: '068|MAWANELLA' },
    { label: '069|KILINOCHCHI', value: '069|KILINOCHCHI' },
    { label: '070|PADAVI PARAKRAMAPURA', value: '070|PADAVI PARAKRAMAPURA' },
    { label: '071|KEKIRAWA', value: '071|KEKIRAWA' },
    { label: '072|UHANA', value: '072|UHANA' },
    { label: '073|KANTHALE', value: '073|KANTHALE' },
    { label: '074|AKKERIPATTU', value: '074|AKKERIPATTU' },
    { label: '075|MORATUWA', value: '075|MORATUWA' },
    { label: '076|HATHARALIYADDA', value: '076|HATHARALIYADDA' },
    { label: '077|HINGURANA', value: '077|HINGURANA' },
    { label: '078|KADUWELA', value: '078|KADUWELA' },
    { label: '079|NARAMMALA', value: '079|NARAMMALA' },
    { label: '080|ALUTHGAMA', value: '080|ALUTHGAMA' },
    { label: '081|MAHARAGAMA', value: '081|MAHARAGAMA' },
    { label: '082|GAMPAHA', value: '082|GAMPAHA' },
    { label: '083|THALAWA', value: '083|THALAWA' },
    { label: '084|MALABE', value: '084|MALABE' },
    { label: '085|MAHABAGE', value: '085|MAHABAGE' },
    { label: '086|DEHIWALA', value: '086|DEHIWALA' },
    { label: '087|EKALA', value: '087|EKALA' },
    { label: '088|NUWARA ELIYA', value: '088|NUWARA ELIYA' },
    { label: '089|CHUNNAKAM', value: '089|CHUNNAKAM' },
    { label: '090|MANNAR', value: '090|MANNAR' },
    { label: '091|THISSAMAHARAMA', value: '091|THISSAMAHARAMA' },
    { label: '092|BANDARAWELA', value: '092|BANDARAWELA' },
    { label: '093|GALEWELA', value: '093|GALEWELA' },
    { label: '094|PILIYANDALA', value: '094|PILIYANDALA' },
    { label: '095|PANADURA', value: '095|PANADURA' },
    { label: '999|HEAD OFFICE', value: '999|HEAD OFFICE' },
  ],
  'HDFC BANK': [{ label: '001|HEAD OFFICE', value: '001|HEAD OFFICE' }],
  'CITIZEN DEVELOPMENT BUSINESS FINANCE PLC': [
    { label: '002|MORATUWA', value: '002|MORATUWA' },
    { label: '003|NEGAMBO', value: '003|NEGAMBO' },
    { label: '004|KURUNEGALA', value: '004|KURUNEGALA' },
    { label: '005|KELANIYA', value: '005|KELANIYA' },
    { label: '006|KANDY', value: '006|KANDY' },
    { label: '007|GAMPAHA', value: '007|GAMPAHA' },
    { label: '008|RATNAPURA', value: '008|RATNAPURA' },
    { label: '009|BADULLA', value: '009|BADULLA' },
    { label: '010|CHILAW', value: '010|CHILAW' },
    { label: '011|ANURADHAPURA', value: '011|ANURADHAPURA' },
    { label: '012|WELLAWATTA', value: '012|WELLAWATTA' },
    { label: '013|WATTALA', value: '013|WATTALA' },
    { label: '014|JA-ELA', value: '014|JA-ELA' },
    { label: '015|KADUWELA', value: '015|KADUWELA' },
    { label: '016|KEGALLE', value: '016|KEGALLE' },
    { label: '017|NITTAMBUWA', value: '017|NITTAMBUWA' },
    { label: '018|WENNAPUWA', value: '018|WENNAPUWA' },
    { label: '019|MATHUGAMA', value: '019|MATHUGAMA' },
    { label: '020|KALUTHARA', value: '020|KALUTHARA' },
    { label: '021|MAHARAGAMA', value: '021|MAHARAGAMA' },
    { label: '022|MATARA', value: '022|MATARA' },
    { label: '023|EMBILIPITIYA', value: '023|EMBILIPITIYA' },
    { label: '024|BATTARAMULLA', value: '024|BATTARAMULLA' },
    { label: '025|TISSAMAHARAMA', value: '025|TISSAMAHARAMA' },
    { label: '026|MAHARA', value: '026|MAHARA' },
    { label: '027|GALLE', value: '027|GALLE' },
    { label: '028|DAMBULLA', value: '028|DAMBULLA' },
    { label: '029|KADURUWELA', value: '029|KADURUWELA' },
    { label: '030|KOTAHENA', value: '030|KOTAHENA' },
    { label: '031|COLOMBO OFFICE', value: '031|COLOMBO OFFICE' },
    { label: '032|JAFFNA', value: '032|JAFFNA' },
    { label: '033|VAVUNIA', value: '033|VAVUNIA' },
    { label: '034|BATTICALOA', value: '034|BATTICALOA' },
    { label: '035|TRINCOMALEE', value: '035|TRINCOMALEE' },
    { label: '036|BANDARAWELA', value: '036|BANDARAWELA' },
    { label: '038|HEAD OFFICE', value: '038|HEAD OFFICE' },
    { label: '039|KANDANA', value: '039|KANDANA' },
    { label: '040|RAGAMA', value: '040|RAGAMA' },
    { label: '041|ELA KANDA', value: '041|ELA KANDA' },
    { label: '042|EHELIYAGODA', value: '042|EHELIYAGODA' },
    { label: '043|BORALESGAMUWA', value: '043|BORALESGAMUWA' },
    { label: '044|MARAWILA', value: '044|MARAWILA' },
    { label: '045|KULIYAPITIYA', value: '045|KULIYAPITIYA' },
    { label: '046|RATMALANA', value: '046|RATMALANA' },
    { label: '047|PANADURA', value: '047|PANADURA' },
    { label: '048|NUGEGODA', value: '048|NUGEGODA' },
    { label: '049|PREMIER CENTER', value: '049|PREMIER CENTER' },
    { label: '050|HORANA', value: '050|HORANA' },
    { label: '051|PELMADULLA', value: '051|PELMADULLA' },
    { label: '052|KOCHCHIKADE', value: '052|KOCHCHIKADE' },
    { label: '053|RAJAGIRIYA', value: '053|RAJAGIRIYA' },
    { label: '054|KATUGASTHOTA', value: '054|KATUGASTHOTA' },
    { label: '055|MATHALE', value: '055|MATHALE' },
    { label: '056|PILIYANDALA', value: '056|PILIYANDALA' },
    { label: '057|AMBALANGODA', value: '057|AMBALANGODA' },
    { label: '058|MALABE', value: '058|MALABE' },
    { label: '059|MINUWANGODA', value: '059|MINUWANGODA' },
    { label: '060|DEHIWALA', value: '060|DEHIWALA' },
    { label: '061|WARAKAPOLA', value: '061|WARAKAPOLA' },
    { label: '062|KOTTAWA', value: '062|KOTTAWA' },
    { label: '063|NARAMMALA', value: '063|NARAMMALA' },
    { label: '064|MAWATHAGAMA', value: '064|MAWATHAGAMA' },
    { label: '065|AVISSAWELLA', value: '065|AVISSAWELLA' },
    { label: '066|WARIYAPOLA', value: '066|WARIYAPOLA' },
    { label: '067|NIKAWERATIYA', value: '067|NIKAWERATIYA' },
    { label: '068|KURUVITA', value: '068|KURUVITA' },
    { label: '069|ALUTHGAMA', value: '069|ALUTHGAMA' },
    { label: '070|MAHIYANGANAYA', value: '070|MAHIYANGANAYA' },
    { label: '071|THALAWATHUGODA', value: '071|THALAWATHUGODA' },
    { label: '072|GIRIULLA', value: '072|GIRIULLA' },
    { label: '104|NUWARA-ELIYA', value: '104|NUWARA-ELIYA' },
    { label: '106|AMPARA', value: '106|AMPARA' },
  ],
  'REGIONAL DEVELOPMENT BANK': [
    { label: '001|HEAD OFFICE', value: '001|HEAD OFFICE' },
    { label: '002|PANNALA', value: '002|PANNALA' },
    { label: '003|PROVINCIAL OFFICE CENTRAL', value: '003|PROVINCIAL OFFICE CENTRAL' },
    { label: '100|PROVINCIAL OFFICE WESTERN', value: '100|PROVINCIAL OFFICE WESTERN' },
    { label: '101|BULATHSINGHLA', value: '101|BULATHSINGHLA' },
    { label: '102|WALAGEDARAHLA', value: '102|WALAGEDARAHLA' },
    { label: '103|AGALAWATHTHAA', value: '103|AGALAWATHTHAA' },
    { label: '104|MILLANIYA', value: '104|MILLANIYA' },
    { label: '105|GONAPOLA', value: '105|GONAPOLA' },
    { label: '106|MORANTHUDUWA', value: '106|MORANTHUDUWA' },
    { label: '107|BERUWALA', value: '107|BERUWALA' },
    { label: '108|PANADURA', value: '108|PANADURA' },
    { label: '109|HORANA', value: '109|HORANA' },
    { label: '110|WARAKAGODA', value: '110|WARAKAGODA' },
    { label: '111|INGIRIYA', value: '111|INGIRIYA' },
    { label: '112|DODANGODA', value: '112|DODANGODA' },
    { label: '113|MEEGAHATHENNA', value: '113|MEEGAHATHENNA' },
    { label: '114|BADURALIYA', value: '114|BADURALIYA' },
    { label: '115|KALUTHARA', value: '115|KALUTHARA' },
    { label: '116|GAMPAHA', value: '116|GAMPAHA' },
    { label: '117|MAWARAMANDIYA', value: '117|MAWARAMANDIYA' },
    { label: '118|MINUWANGODA', value: '118|MINUWANGODA' },
    { label: '119|MEERIGAMA', value: '119|MEERIGAMA' },
    { label: '120|MORAGAHAHENA', value: '120|MORAGAHAHENA' },
    { label: '121|MATHUGAMA', value: '121|MATHUGAMA' },
    { label: '122|NEGAMBO', value: '122|NEGAMBO' },
    { label: '123|DIVULAPITIYA', value: '123|DIVULAPITIYA' },
    { label: '124|NITTAMBUWA', value: '124|NITTAMBUWA' },
    { label: '125|HOMAGAMA', value: '125|HOMAGAMA' },
    { label: '126|KOLONNAWA', value: '126|KOLONNAWA' },
    { label: '127|AWISSAWELLA', value: '127|AWISSAWELLA' },
    { label: '128|PILIYANDALA', value: '128|PILIYANDALA' },
    { label: '129|RAGAMA', value: '129|RAGAMA' },
    { label: '130|WADDUWA', value: '130|WADDUWA' },
    { label: '131|KIRINDIWELA', value: '131|KIRINDIWELA' },
    { label: '132|JAELA', value: '132|JAELA' },
    { label: '133|MIRISWATHTHA', value: '133|MIRISWATHTHA' },
    { label: '134|KELANIYA-HEAD QUARTER', value: '134|KELANIYA-HEAD QUARTER' },
    { label: '135|NUGEGODA', value: '135|NUGEGODA' },
    { label: '198|GAMPAHA DISTRICT OFFICE', value: '198|GAMPAHA DISTRICT OFFICE' },
    { label: '199|KALUTARA DISTRICT OFFICE', value: '199|KALUTARA DISTRICT OFFICE' },
    { label: '200|PROVINCIAL OFFICE SOUTHERN', value: '200|PROVINCIAL OFFICE SOUTHERN' },
    { label: '201|HAKMANA', value: '201|HAKMANA' },
    { label: '202|URUBOKKA', value: '202|URUBOKKA' },
    { label: '203|DEIYANDARA', value: '203|DEIYANDARA' },
    { label: '204|AKURESSA', value: '204|AKURESSA' },
    { label: '205|WELIGAMA', value: '205|WELIGAMA' },
    { label: '206|GANDARA', value: '206|GANDARA' },
    { label: '207|KEKANADURA', value: '207|KEKANADURA' },
    { label: '208|AMBALANTOTA', value: '208|AMBALANTOTA' },
    { label: '209|ANGUNAKOLAPALASSA', value: '209|ANGUNAKOLAPALASSA' },
    { label: '210|KATUWANA', value: '210|KATUWANA' },
    { label: '211|BELIATTA', value: '211|BELIATTA' },
    { label: '212|ELPITIYA', value: '212|ELPITIYA' },
    { label: '213|BATAPOLA', value: '213|BATAPOLA' },
    { label: '214|PITIGALA', value: '214|PITIGALA' },
    { label: '215|GONAGALAPURA', value: '215|GONAGALAPURA' },
    { label: '216|IMADUWA', value: '216|IMADUWA' },
    { label: '217|BADDEGAMA', value: '217|BADDEGAMA' },
    { label: '218|TISSAMAHARAMA', value: '218|TISSAMAHARAMA' },
    { label: '219|LUNUGAMWEHERA', value: '219|LUNUGAMWEHERA' },
    { label: '220|PITABADDERA', value: '220|PITABADDERA' },
    { label: '221|THALGASWALA', value: '221|THALGASWALA' },
    { label: '222|AKMEEMANA', value: '222|AKMEEMANA' },
    { label: '223|KARANDENIYA', value: '223|KARANDENIYA' },
    { label: '224|SOORIYAWEWA', value: '224|SOORIYAWEWA' },
    { label: '225|KAMBURUGAMUWA', value: '225|KAMBURUGAMUWA' },
    { label: '226|DENIYAYA', value: '226|DENIYAYA' },
    { label: '227|KAMBURUPITIYA', value: '227|KAMBURUPITIYA' },
    { label: '228|GALLE', value: '228|GALLE' },
    { label: '229|URAGASMANHANDIYA', value: '229|URAGASMANHANDIYA' },
    { label: '230|YAKKALAMULLA', value: '230|YAKKALAMULLA' },
    { label: '231|WEERAKATIYA', value: '231|WEERAKATIYA' },
    { label: '232|THIHAGODA', value: '232|THIHAGODA' },
    { label: '233|CITY MATARA', value: '233|CITY MATARA' },
    { label: '234|TANGALLE', value: '234|TANGALLE' },
    { label: '235|NELUWA', value: '235|NELUWA' },
    { label: '236|MAWARALA', value: '236|MAWARALA' },
    { label: '237|MORAWAKA', value: '237|MORAWAKA' },
    { label: '238|HAMBANTOTA', value: '238|HAMBANTOTA' },
    { label: '239|WALASMULLA', value: '239|WALASMULLA' },
    { label: '240|BARAWAKUMBUKA', value: '240|BARAWAKUMBUKA' },
    { label: '241|UDUGAMA', value: '241|UDUGAMA' },
    { label: '242|RANNA', value: '242|RANNA' },
    { label: '243|AHANGAMA', value: '243|AHANGAMA' },
    { label: '244|HIKKADUWA', value: '244|HIKKADUWA' },
    { label: '245|KIRINDA', value: '245|KIRINDA' },
    { label: '246|MIDDENIYA', value: '246|MIDDENIYA' },
    { label: '247|DIKWELLA', value: '247|DIKWELLA' },
    { label: '248|KARAPITIYA', value: '248|KARAPITIYA' },
    { label: '249|BALAPITIYA', value: '249|BALAPITIYA' },
    { label: '250|PAMBURANA', value: '250|PAMBURANA' },
    { label: '251|MIRISSA', value: '251|MIRISSA' },
    { label: '252|KALUWELLA', value: '252|KALUWELLA' },
    { label: '253|WARAPITIYA', value: '253|WARAPITIYA' },
    { label: '254|DEVINUWARA', value: '254|DEVINUWARA' },
    { label: '297|DISTRICT OFFICE HAMBANTOTA', value: '297|DISTRICT OFFICE HAMBANTOTA' },
    { label: '298|DISTRICT OFFICE GALLE', value: '298|DISTRICT OFFICE GALLE' },
    { label: '299|DISTRICT OFFICE MATARA', value: '299|DISTRICT OFFICE MATARA' },
    { label: '300|WAYAMBA PROVINCIAL OFFICE', value: '300|WAYAMBA PROVINCIAL OFFICE' },
    { label: '301|KULIYAPITIYA', value: '301|KULIYAPITIYA' },
    { label: '302|PANNALA', value: '302|PANNALA' },
    { label: '303|PANDUWASNUWARA', value: '303|PANDUWASNUWARA' },
    { label: '304|ALAWWA', value: '304|ALAWWA' },
    { label: '305|DUMMALASOORIYA', value: '305|DUMMALASOORIYA' },
    { label: '306|POTHUHERA', value: '306|POTHUHERA' },
    { label: '307|NIKAWERATIYA', value: '307|NIKAWERATIYA' },
    { label: '308|RIDEEGAMA', value: '308|RIDEEGAMA' },
    { label: '309|MAWATHAGAMA', value: '309|MAWATHAGAMA' },
    { label: '310|WARIYAPOLA', value: '310|WARIYAPOLA' },
    { label: '311|KURUNEGALA', value: '311|KURUNEGALA' },
    { label: '312|GALGAMUWA', value: '312|GALGAMUWA' },
    { label: '313|CHILAW', value: '313|CHILAW' },
    { label: '314|PALAKUDA', value: '314|PALAKUDA' },
    { label: '315|ANAMADUWA', value: '315|ANAMADUWA' },
    { label: '316|NATTANDIYA', value: '316|NATTANDIYA' },
    { label: '317|KIRIMETIYANA', value: '317|KIRIMETIYANA' },
    { label: '318|PUTTALAM', value: '318|PUTTALAM' },
    { label: '319|MAHO', value: '319|MAHO' },
    { label: '320|GIRIULLA', value: '320|GIRIULLA' },
    { label: '321|IBBAGAMUWA', value: '321|IBBAGAMUWA' },
    { label: '322|MUNDEL', value: '322|MUNDEL' },
    { label: '323|NAWAGATTEGAMA', value: '323|NAWAGATTEGAMA' },
    { label: '324|MAMPURI', value: '324|MAMPURI' },
    { label: '325|MAHAWEWA', value: '325|MAHAWEWA' },
    { label: '326|NARAMMALA', value: '326|NARAMMALA' },
    { label: '327|POLPITHIGAMA', value: '327|POLPITHIGAMA' },
    { label: '328|BOWATTE', value: '328|BOWATTE' },
    { label: '329|HQB', value: '329|HQB' },
    { label: '330|MELSIRIPURA', value: '330|MELSIRIPURA' },
    { label: '331|AMBANPOLA', value: '331|AMBANPOLA' },
    { label: '332|SANTHA ANAPURA', value: '332|SANTHA ANAPURA' },
    { label: '336|WENNAPPUWA', value: '336|WENNAPPUWA' },
    { label: '398|PUTTLAM DISTRICT OFFICE', value: '398|PUTTLAM DISTRICT OFFICE' },
    { label: '399|KURUNEGALA DISTRICT OFFICE', value: '399|KURUNEGALA DISTRICT OFFICE' },
    { label: '400|PROVINCIAL OFFICE NORTH', value: '400|PROVINCIAL OFFICE NORTH' },
    { label: '401|MIHINTHALE', value: '401|MIHINTHALE' },
    { label: '402|TOWN BRANCH', value: '402|TOWN BRANCH' },
    { label: '403|THAMBUTHTHEGAMA', value: '403|THAMBUTHTHEGAMA' },
    { label: '404|KAHATAGASDIGILIYA', value: '404|KAHATAGASDIGILIYA' },
    { label: '405|GALNEWA', value: '405|GALNEWA' },
    { label: '406|THALAWA', value: '406|THALAWA' },
    { label: '407|MADAWACHCHIYA', value: '407|MADAWACHCHIYA' },
    { label: '408|THIRAPPANE', value: '408|THIRAPPANE' },
    { label: '409|RAMBEWA', value: '409|RAMBEWA' },
    { label: '410|POLONNARUWA', value: '410|POLONNARUWA' },
    { label: '411|MEDIRIGIRIYA', value: '411|MEDIRIGIRIYA' },
    { label: '412|PULASTHIGAMA', value: '412|PULASTHIGAMA' },
    { label: '413|HINGURAKGODA', value: '413|HINGURAKGODA' },
    { label: '414|BAKAMOONA', value: '414|BAKAMOONA' },
    { label: '415|GALENBINDUNUWEWA', value: '415|GALENBINDUNUWEWA' },
    { label: '416|GONAPATHIRAWA', value: '416|GONAPATHIRAWA' },
    { label: '417|MANAMPITIYA', value: '417|MANAMPITIYA' },
    { label: '418|GALAMUNA', value: '418|GALAMUNA' },
    { label: '419|NEW TOWN BRANCH', value: '419|NEW TOWN BRANCH' },
    { label: '420|SIRIPURA', value: '420|SIRIPURA' },
    { label: '421|KADURUWELA', value: '421|KADURUWELA' },
    { label: '422|KEKIRAWA', value: '422|KEKIRAWA' },
    { label: '423|ARALAGANWILA', value: '423|ARALAGANWILA' },
    { label: '424|ECONOMIC CENTRE', value: '424|ECONOMIC CENTRE' },
    { label: '425|SEVANAPITIYA', value: '425|SEVANAPITIYA' },
    { label: '498|DISTRICT OFFICE POLONNARUWA', value: '498|DISTRICT OFFICE POLONNARUWA' },
    { label: '500|PROVINCIAL OFFICE RATNAPURA', value: '500|PROVINCIAL OFFICE RATNAPURA' },
    { label: '501|KEGALLE', value: '501|KEGALLE' },
    { label: '502|PITAGALDENIYA', value: '502|PITAGALDENIYA' },
    { label: '503|RAMBUKKANA', value: '503|RAMBUKKANA' },
    { label: '504|MAWANELLA', value: '504|MAWANELLA' },
    { label: '505|WARAKAPOLA', value: '505|WARAKAPOLA' },
    { label: '506|ARANAYAKA', value: '506|ARANAYAKA' },
    { label: '507|KITHULGALA', value: '507|KITHULGALA' },
    { label: '508|RUWANWELLA', value: '508|RUWANWELLA' },
    { label: '509|DEWALEGAMA', value: '509|DEWALEGAMA' },
    { label: '510|BULATHKOHUPITIYA', value: '510|BULATHKOHUPITIYA' },
    { label: '511|DERANIYAGALA', value: '511|DERANIYAGALA' },
    { label: '512|RATHNAPURA', value: '512|RATHNAPURA' },
    { label: '513|PELMADULLA', value: '513|PELMADULLA' },
    { label: '514|BALANGODA', value: '514|BALANGODA' },
    { label: '515|EMBILIPITIYA', value: '515|EMBILIPITIYA' },
    { label: '516|HEMMATHAGAMA', value: '516|HEMMATHAGAMA' },
    { label: '517|KOLONNA', value: '517|KOLONNA' },
    { label: '518|EHELIYAGODA', value: '518|EHELIYAGODA' },
    { label: '519|NELUNDENIYA', value: '519|NELUNDENIYA' },
    { label: '520|KALAWANA', value: '520|KALAWANA' },
    { label: '521|YATIYANTOTA', value: '521|YATIYANTOTA' },
    { label: '522|GODAKAWELA', value: '522|GODAKAWELA' },
    { label: '523|ERATHNA', value: '523|ERATHNA' },
    { label: '524|KURUWITA', value: '524|KURUWITA' },
    { label: '525|NIVITHIGALA', value: '525|NIVITHIGALA' },
    { label: '526|KAHAWATTA', value: '526|KAHAWATTA' },
    { label: '527|KOTIYAKUMBURA', value: '527|KOTIYAKUMBURA' },
    { label: '528|RAKWANA', value: '528|RAKWANA' },
    { label: '529|DEHIOVITA', value: '529|DEHIOVITA' },
    { label: '530|KIRIELLA', value: '530|KIRIELLA' },
    { label: '531|POTHUPITIYA', value: '531|POTHUPITIYA' },
    { label: '532|WELIGEPOLA', value: '532|WELIGEPOLA' },
    { label: '533|SRI PALABADDALA', value: '533|SRI PALABADDALA' },
    { label: '534|PULUNGUPITIYA', value: '534|PULUNGUPITIYA' },
    { label: '599|KEGALLE DISTRICT OFFICE', value: '599|KEGALLE DISTRICT OFFICE' },
    { label: '600|PROVINCIAL OFFICE CENTRAL', value: '600|PROVINCIAL OFFICE CENTRAL' },
    { label: '601|GAMPOLA', value: '601|GAMPOLA' },
    { label: '602|UDAWELA', value: '602|UDAWELA' },
    { label: '603|HATARALIYADDA', value: '603|HATARALIYADDA' },
    { label: '604|MARASSANA', value: '604|MARASSANA' },
    { label: '605|DANTURE', value: '605|DANTURE' },
    { label: '606|WATTEGAMA', value: '606|WATTEGAMA' },
    { label: '607|MORAYAYA', value: '607|MORAYAYA' },
    { label: '608|TELDENIYA', value: '608|TELDENIYA' },
    { label: '609|PUJAPITIYA', value: '609|PUJAPITIYA' },
    { label: '610|NUWARA ELIYA', value: '610|NUWARA ELIYA' },
    { label: '611|RIKILLAGASKADA', value: '611|RIKILLAGASKADA' },
    { label: '612|KANDY MARKETING CENTRE', value: '612|KANDY MARKETING CENTRE' },
    { label: '613|GINIGATHENA', value: '613|GINIGATHENA' },
    { label: '614|POONDALUOYA', value: '614|POONDALUOYA' },
    { label: '615|KATUGASTOTA', value: '615|KATUGASTOTA' },
    { label: '616|NILDANDAHINNA', value: '616|NILDANDAHINNA' },
    { label: '617|AGARAPATHANA', value: '617|AGARAPATHANA' },
    { label: '618|UDUDUMBARA', value: '618|UDUDUMBARA' },
    { label: '619|MATALE', value: '619|MATALE' },
    { label: '620|DAMBULLA', value: '620|DAMBULLA' },
    { label: '621|GALEWELA', value: '621|GALEWELA' },
    { label: '622|LAGGALA', value: '622|LAGGALA' },
    { label: '623|RATTOTA', value: '623|RATTOTA' },
    { label: '624|KOTAGALA', value: '624|KOTAGALA' },
    { label: '625|MENIKHINNA', value: '625|MENIKHINNA' },
    { label: '626|HANGURANKETHA', value: '626|HANGURANKETHA' },
    { label: '627|DAULAGALA', value: '627|DAULAGALA' },
    { label: '628|NAULA', value: '628|NAULA' },
    { label: '629|NAWALAPITIYA', value: '629|NAWALAPITIYA' },
    { label: '630|HEDENIYA', value: '630|HEDENIYA' },
    { label: '631|WILGAMUWA', value: '631|WILGAMUWA' },
    { label: '632|KANDY', value: '632|KANDY' },
    { label: '633|PERADENIYA', value: '633|PERADENIYA' },
    { label: '635|KANDY 02ND CITY', value: '635|KANDY 02ND CITY' },
    { label: '700|PROVINCIAL OFFICE UVA', value: '700|PROVINCIAL OFFICE UVA' },
    { label: '701|BUTTALA', value: '701|BUTTALA' },
    { label: '702|MEDAGAMA', value: '702|MEDAGAMA' },
    { label: '703|MONARAGALA', value: '703|MONARAGALA' },
    { label: '704|THANAMALVILA', value: '704|THANAMALVILA' },
    { label: '705|BADULLA', value: '705|BADULLA' },
    { label: '706|PASSARA', value: '706|PASSARA' },
    { label: '707|WELIMADA', value: '707|WELIMADA' },
    { label: '708|KANDAKETIYA', value: '708|KANDAKETIYA' },
    { label: '709|MAHIYANGANAYA', value: '709|MAHIYANGANAYA' },
    { label: '710|WELLAWAYA', value: '710|WELLAWAYA' },
    { label: '711|BADALKUMBURA', value: '711|BADALKUMBURA' },
    { label: '712|HAPUTHALE', value: '712|HAPUTHALE' },
    { label: '713|RIDEEMALIYADDA', value: '713|RIDEEMALIYADDA' },
    { label: '714|UVAPARANAGAMA', value: '714|UVAPARANAGAMA' },
    { label: '715|BANDARAWELA', value: '715|BANDARAWELA' },
    { label: '716|MEEGAHAKIULA', value: '716|MEEGAHAKIULA' },
    { label: '717|LUNUGALA', value: '717|LUNUGALA' },
    { label: '718|HALDUMMULLA', value: '718|HALDUMMULLA' },
    { label: '719|GIRANDURUKOTTE', value: '719|GIRANDURUKOTTE' },
    { label: '720|BOGAHAKUMBURA', value: '720|BOGAHAKUMBURA' },
    { label: '721|BIBILE', value: '721|BIBILE' },
    { label: '722|UVA MALIGATHENNA', value: '722|UVA MALIGATHENNA' },
    { label: '723|SIYAMBALANDUWA', value: '723|SIYAMBALANDUWA' },
    { label: '724|DIYATHALAWA', value: '724|DIYATHALAWA' },
    { label: '725|SEWANAGALA', value: '725|SEWANAGALA' },
    { label: '726|MADULLA', value: '726|MADULLA' },
    { label: '801|AMPARA', value: '801|AMPARA' },
    { label: '802|DEHIATTHAKANDIYA', value: '802|DEHIATTHAKANDIYA' },
    { label: '803|SAMMANTHURAI', value: '803|SAMMANTHURAI' },
    { label: '804|HINGURANA', value: '804|HINGURANA' },
    { label: '805|AKKARAIPATHTHU', value: '805|AKKARAIPATHTHU' },
    { label: '806|KALMUNAI', value: '806|KALMUNAI' },
    { label: '807|MAHAOYA', value: '807|MAHAOYA' },
    { label: '808|POTHUVIL', value: '808|POTHUVIL' },
    { label: '809|UHANA', value: '809|UHANA' },
    { label: '810|NINTAVUR', value: '810|NINTAVUR' },
    { label: '811|BATTICALOA', value: '811|BATTICALOA' },
    { label: '812|ERAUR', value: '812|ERAUR' },
    { label: '813|CHENKALADY', value: '813|CHENKALADY' },
    { label: '814|KANTHALE', value: '814|KANTHALE' },
    { label: '815|VALACHENAI', value: '815|VALACHENAI' },
    { label: '816|KATHANKUDI', value: '816|KATHANKUDI' },
    { label: '817|TRINCOMALEE', value: '817|TRINCOMALEE' },
    { label: '818|KALUWANCHIKUDI', value: '818|KALUWANCHIKUDI' },
    { label: '819|KOKKADICHCHOLAI', value: '819|KOKKADICHCHOLAI' },
    { label: '820|MUTHTUR', value: '820|MUTHTUR' },
    { label: '821|BATTICALOA 02ND CITY', value: '821|BATTICALOA 02ND CITY' },
    { label: '901|VAUNIYA', value: '901|VAUNIYA' },
    { label: '902|KANAGARAYANKULAM', value: '902|KANAGARAYANKULAM' },
    { label: '903|MANNAR', value: '903|MANNAR' },
    { label: '904|CHUNNAKAM', value: '904|CHUNNAKAM' },
    { label: '905|JAFFNA', value: '905|JAFFNA' },
    { label: '906|KILINOCHCHI', value: '906|KILINOCHCHI' },
    { label: '907|BOGASWEWA', value: '907|BOGASWEWA' },
  ],
  'LB FINANCE PLC': [
    { label: '001|HEAD OFFICE', value: '001|HEAD OFFICE' },
    { label: '002|CORPORATE OFFICE', value: '002|CORPORATE OFFICE' },
    { label: '003|NUGEGODA', value: '003|NUGEGODA' },
    { label: '004|NEGOMBO', value: '004|NEGOMBO' },
    { label: '005|KANDY', value: '005|KANDY' },
    { label: '006|BADULLA', value: '006|BADULLA' },
    { label: '007|PANADURA', value: '007|PANADURA' },
    { label: '008|ANURADHAPURA', value: '008|ANURADHAPURA' },
    { label: '009|GALLE', value: '009|GALLE' },
    { label: '010|KIRIBATHGODA', value: '010|KIRIBATHGODA' },
    { label: '011|KURUNEGALA', value: '011|KURUNEGALA' },
    { label: '012|RATNAPURA', value: '012|RATNAPURA' },
    { label: '013|MATARA', value: '013|MATARA' },
    { label: '014|KALUTARA', value: '014|KALUTARA' },
    { label: '015|AMBALANGODA', value: '015|AMBALANGODA' },
    { label: '016|AMPARA', value: '016|AMPARA' },
    { label: '017|AVISSAWELLA', value: '017|AVISSAWELLA' },
    { label: '018|GAMPAHA', value: '018|GAMPAHA' },
    { label: '019|CHILAW', value: '019|CHILAW' },
    { label: '020|POLONNARUWA', value: '020|POLONNARUWA' },
    { label: '021|PILIYANDALA', value: '021|PILIYANDALA' },
    { label: '022|BORALESGAMUWA', value: '022|BORALESGAMUWA' },
    { label: '023|DAMBULLA', value: '023|DAMBULLA' },
    { label: '024|BALANGODA', value: '024|BALANGODA' },
    { label: '025|KEGALLE', value: '025|KEGALLE' },
    { label: '026|BANDARAWELA', value: '026|BANDARAWELA' },
    { label: '027|DEHIWALA', value: '027|DEHIWALA' },
    { label: '028|EMBILIPITIYA', value: '028|EMBILIPITIYA' },
    { label: '029|BATTICALOA', value: '029|BATTICALOA' },
    { label: '030|ELPITIYA', value: '030|ELPITIYA' },
    { label: '031|MARADANA', value: '031|MARADANA' },
    { label: '032|KANDANA', value: '032|KANDANA' },
    { label: '033|MAHIYANGANAYA', value: '033|MAHIYANGANAYA' },
    { label: '034|TISSAMAHARAMA', value: '034|TISSAMAHARAMA' },
    { label: '035|MATALE', value: '035|MATALE' },
    { label: '036|MAHARAGAMA', value: '036|MAHARAGAMA' },
    { label: '037|KULIYAPITIYA', value: '037|KULIYAPITIYA' },
    { label: '038|PUTTALAM', value: '038|PUTTALAM' },
    { label: '039|TRINCOMALE', value: '039|TRINCOMALE' },
    { label: '040|GAMPOLA', value: '040|GAMPOLA' },
    { label: '041|HORANA', value: '041|HORANA' },
    { label: '042|KADAWATHA', value: '042|KADAWATHA' },
    { label: '043|JAFFNA', value: '043|JAFFNA' },
    { label: '044|CHUNNAKAM', value: '044|CHUNNAKAM' },
    { label: '045|MANIPAY', value: '045|MANIPAY' },
    { label: '046|CHAVAKACHCHERI', value: '046|CHAVAKACHCHERI' },
    { label: '047|KILINOCHCHI', value: '047|KILINOCHCHI' },
    { label: '048|VAVUNIYA', value: '048|VAVUNIYA' },
    { label: '049|NUWARAELIYA', value: '049|NUWARAELIYA' },
    { label: '050|HATTON', value: '050|HATTON' },
    { label: '051|NELLIADY', value: '051|NELLIADY' },
    { label: '052|MATUGAMA', value: '052|MATUGAMA' },
    { label: '053|WENNAPPUWA', value: '053|WENNAPPUWA' },
    { label: '054|THAMBUTHTHEGAMA', value: '054|THAMBUTHTHEGAMA' },
    { label: '055|AKKARAIPATTU', value: '055|AKKARAIPATTU' },
    { label: '056|POTHUVIL', value: '056|POTHUVIL' },
    { label: '057|KOCHCHIKADE', value: '057|KOCHCHIKADE' },
    { label: '058|KOTAHENA', value: '058|KOTAHENA' },
    { label: '059|ANURADHAPURA 02', value: '059|ANURADHAPURA 02' },
    { label: '060|RAJAGIRIYA', value: '060|RAJAGIRIYA' },
    { label: '061|WELIMADA', value: '061|WELIMADA' },
    { label: '062|PILIMATHALAWA', value: '062|PILIMATHALAWA' },
    { label: '063|KATUGASTHOTA', value: '063|KATUGASTHOTA' },
    { label: '064|KALMUNAI', value: '064|KALMUNAI' },
    { label: '065|MORATUMULLA', value: '065|MORATUMULLA' },
    { label: '066|KALAWANCHIKUDY', value: '066|KALAWANCHIKUDY' },
    { label: '067|SEA STREET', value: '067|SEA STREET' },
    { label: '068|MATARA CITY', value: '068|MATARA CITY' },
    { label: '069|ALUTHGAMA', value: '069|ALUTHGAMA' },
    { label: '070|KANDY CITY', value: '070|KANDY CITY' },
    { label: '071|WARAKAPOLA', value: '071|WARAKAPOLA' },
    { label: '072|MALABE', value: '072|MALABE' },
    { label: '073|KADUWELA', value: '073|KADUWELA' },
    { label: '074|NAWALAPITIYA', value: '074|NAWALAPITIYA' },
    { label: '075|HOMAGAMA', value: '075|HOMAGAMA' },
    { label: '076|KURUNEGALA CITY', value: '076|KURUNEGALA CITY' },
    { label: '077|MASKELIYA', value: '077|MASKELIYA' },
    { label: '078|PITIGALA', value: '078|PITIGALA' },
    { label: '079|SAMANTUREI', value: '079|SAMANTUREI' },
    { label: '080|SAINDAMARTHU', value: '080|SAINDAMARTHU' },
    { label: '081|KOTTE', value: '081|KOTTE' },
    { label: '082|BADULLA CITY', value: '082|BADULLA CITY' },
    { label: '083|JA ELA', value: '083|JA ELA' },
    { label: '084|DELKADA', value: '084|DELKADA' },
    { label: '085|KATHTHANKUDI', value: '085|KATHTHANKUDI' },
    { label: '086|DAM STREET', value: '086|DAM STREET' },
    { label: '087|AKURESSA', value: '087|AKURESSA' },
    { label: '088|MONARAGALA', value: '088|MONARAGALA' },
    { label: '089|TANGALLE', value: '089|TANGALLE' },
    { label: '090|MOUNT LAVINIA', value: '090|MOUNT LAVINIA' },
    { label: '091|NELUWA', value: '091|NELUWA' },
    { label: '092|BATTARAMULLA', value: '092|BATTARAMULLA' },
    { label: '093|KURUNEGALA PREMIER CENTRE', value: '093|KURUNEGALA PREMIER CENTRE' },
    { label: '094|MORATUWA', value: '094|MORATUWA' },
    { label: '095|MEDAWACHCHIYA', value: '095|MEDAWACHCHIYA' },
    { label: '096|CHENKALADY', value: '096|CHENKALADY' },
    { label: '097|PUSSELLAWA', value: '097|PUSSELLAWA' },
    { label: '098|DENIYAYA', value: '098|DENIYAYA' },
    { label: '099|JAFFNA CITY', value: '099|JAFFNA CITY' },
    { label: '100|JAFFNA PREMIER', value: '100|JAFFNA PREMIER' },
    { label: '101|VALAICHCHENAI', value: '101|VALAICHCHENAI' },
    { label: '102|WATTALA', value: '102|WATTALA' },
    { label: '103|GAMPOLA CITY', value: '103|GAMPOLA CITY' },
    { label: '104|KOTTAWA', value: '104|KOTTAWA' },
    { label: '105|DEHIATTAKANDIYA', value: '105|DEHIATTAKANDIYA' },
    { label: '106|KOKKADICHOLAI', value: '106|KOKKADICHOLAI' },
    { label: '107|GELIOYA', value: '107|GELIOYA' },
    { label: '109|KINNIYA', value: '109|KINNIYA' },
    { label: '110|ANAMADUWA', value: '110|ANAMADUWA' },
    { label: '111|PUTHUKKUDIYIRUPPU', value: '111|PUTHUKKUDIYIRUPPU' },
    { label: '112|DELGODA', value: '112|DELGODA' },
    { label: '113|EHELIYAGODA', value: '113|EHELIYAGODA' },
    { label: '114|KEKIRAWA', value: '114|KEKIRAWA' },
    { label: '115|DIVULAPITIYA', value: '115|DIVULAPITIYA' },
    { label: '116|NARAMMALA', value: '116|NARAMMALA' },
    { label: '117|MEDIRIGIRIYA', value: '117|MEDIRIGIRIYA' },
    { label: '119|MANNAR', value: '119|MANNAR' },
    { label: '120|CITY OFFICE', value: '120|CITY OFFICE' },
    { label: '121|WADDUWA', value: '121|WADDUWA' },
    { label: '122|DHARGA TOWN', value: '122|DHARGA TOWN' },
    { label: '123|KARAPITIYA', value: '123|KARAPITIYA' },
    { label: '125|MULLAITIVU', value: '125|MULLAITIVU' },
    { label: '126|THIRUNELWELY', value: '126|THIRUNELWELY' },
    { label: '127|KOHUWALA', value: '127|KOHUWALA' },
    { label: '128|PREMIER COLOMBO 07', value: '128|PREMIER COLOMBO 07' },
    { label: '129|MARAWILA', value: '129|MARAWILA' },
    { label: '130|MALLAVI', value: '130|MALLAVI' },
  ],
  'SENKADAGALA FINANCE PLC': [{ label: '001|HEAD OFFICE', value: '001|HEAD OFFICE' }],
  'VALLIBEL FINANCE PLC': [{ label: '001|HEAD OFFICE', value: '001|HEAD OFFICE' }],
  'CENTRAL FINANCE PLC': [
    { label: '001|COLOMBO', value: '001|COLOMBO' },
    { label: '002|KANDY', value: '002|KANDY' },
    { label: '003|JAFFNA', value: '003|JAFFNA' },
    { label: '009|GAMPAHA', value: '009|GAMPAHA' },
    { label: '016|NUGEGODA', value: '016|NUGEGODA' },
    { label: '025|NUWARA ELIYA', value: '025|NUWARA ELIYA' },
    { label: '029|VAVUNIYA', value: '029|VAVUNIYA' },
    { label: '031|KANTALE', value: '031|KANTALE' },
    { label: '035|AMPARA', value: '035|AMPARA' },
    { label: '038|KIRIBATHGODA', value: '038|KIRIBATHGODA' },
    { label: '044|NIKAWERATIYA', value: '044|NIKAWERATIYA' },
    { label: '046|WARAKAPOLA', value: '046|WARAKAPOLA' },
    { label: '047|MONERAGALA', value: '047|MONERAGALA' },
    { label: '048|HORANA', value: '048|HORANA' },
    { label: '053|PILIYANDALA', value: '053|PILIYANDALA' },
    { label: '054|MELSIRIPURA', value: '054|MELSIRIPURA' },
    { label: '056|NITTAMBUWA', value: '056|NITTAMBUWA' },
    { label: '057|WENNAPPUWA', value: '057|WENNAPPUWA' },
    { label: '059|BANDARAWELA', value: '059|BANDARAWELA' },
    { label: '061|GIRIULLA', value: '061|GIRIULLA' },
    { label: '062|RATMALANA', value: '062|RATMALANA' },
    { label: '064|HOMAGAMA', value: '064|HOMAGAMA' },
    { label: '065|BATTICALOA', value: '065|BATTICALOA' },
    { label: '070|MINUWANGODA', value: '070|MINUWANGODA' },
    { label: '071|THALAWATHUGODA', value: '071|THALAWATHUGODA' },
    { label: '072|KADAWATHA', value: '072|KADAWATHA' },
    { label: '073|RJAGIRIYA', value: '073|RJAGIRIYA' },
    { label: '074|ATHURUGIRIYA', value: '074|ATHURUGIRIYA' },
    { label: '078|MARADANA', value: '078|MARADANA' },
    { label: '079|KALUTARA', value: '079|KALUTARA' },
    { label: '083|KILLINOCHCHI', value: '083|KILLINOCHCHI' },
    { label: '084|WATTALA', value: '084|WATTALA' },
    { label: '092|HAMBANTHOTA', value: '092|HAMBANTHOTA' },
    { label: '093|PUTTALAM', value: '093|PUTTALAM' },
    { label: '094|KALMUNAI', value: '094|KALMUNAI' },
    { label: '095|TRINCOMALEE', value: '095|TRINCOMALEE' },
    { label: '096|BALANGODA', value: '096|BALANGODA' },
    { label: '097|ELPITIYA', value: '097|ELPITIYA' },
  ],
  'LOLC FINANCE PLC': [{ label: '000|HEAD OFFICE', value: '000|HEAD OFFICE' }],
  'MERCHANT BANK OF SRI LANKA & FINANCE PLC': [
    { label: '100|UNION PLACE', value: '100|UNION PLACE' },
    { label: '101|KADAWATHA', value: '101|KADAWATHA' },
    { label: '102|GAMPAHA', value: '102|GAMPAHA' },
    { label: '103|NEGOMBO', value: '103|NEGOMBO' },
    { label: '104|KOTTAWA', value: '104|KOTTAWA' },
    { label: '105|KALUTARA', value: '105|KALUTARA' },
    { label: '106|MORATUWA', value: '106|MORATUWA' },
    { label: '107|GALLE', value: '107|GALLE' },
    { label: '108|NUGEGODA', value: '108|NUGEGODA' },
    { label: '109|KOTAHENA', value: '109|KOTAHENA' },
    { label: '110|KURUNEGALA', value: '110|KURUNEGALA' },
    { label: '112|ANURADHAPURA', value: '112|ANURADHAPURA' },
    { label: '113|RATNAPURA', value: '113|RATNAPURA' },
    { label: '114|AMBALANGODA', value: '114|AMBALANGODA' },
    { label: '115|AMPARA', value: '115|AMPARA' },
    { label: '116|AMBALANTOTA', value: '116|AMBALANTOTA' },
    { label: '117|AVISSAWELLA', value: '117|AVISSAWELLA' },
    { label: '118|BANDARAWELA', value: '118|BANDARAWELA' },
    { label: '119|BATTICALOA', value: '119|BATTICALOA' },
    { label: '120|CHILAW', value: '120|CHILAW' },
    { label: '121|CITY OFFICE', value: '121|CITY OFFICE' },
    { label: '122|DAMBULLA', value: '122|DAMBULLA' },
    { label: '123|DENIYAYA', value: '123|DENIYAYA' },
    { label: '124|EMBILIPITIYA', value: '124|EMBILIPITIYA' },
    { label: '125|HAMBANTHOTA', value: '125|HAMBANTHOTA' },
    { label: '126|HINGURAKGODA', value: '126|HINGURAKGODA' },
    { label: '127|HORANA', value: '127|HORANA' },
    { label: '128|HATTON', value: '128|HATTON' },
    { label: '129|JA-ELA', value: '129|JA-ELA' },
    { label: '130|JAFFNA', value: '130|JAFFNA' },
    { label: '131|KANDY', value: '131|KANDY' },
    { label: '132|KIRIBATHGODA', value: '132|KIRIBATHGODA' },
    { label: '133|KANDY CITY OFFICE', value: '133|KANDY CITY OFFICE' },
    { label: '134|KILINOCHCHI', value: '134|KILINOCHCHI' },
    { label: '135|KAKIRAWA', value: '135|KAKIRAWA' },
    { label: '136|KANDANA', value: '136|KANDANA' },
    { label: '137|KULIYAPITIYA', value: '137|KULIYAPITIYA' },
    { label: '138|MAHARAGAMA', value: '138|MAHARAGAMA' },
    { label: '140|MONARAGALA', value: '140|MONARAGALA' },
    { label: '141|METROPOLITAN', value: '141|METROPOLITAN' },
    { label: '142|MATARA', value: '142|MATARA' },
    { label: '143|MATUGAMA', value: '143|MATUGAMA' },
    { label: '144|NITTAMBUWA', value: '144|NITTAMBUWA' },
    { label: '145|PANADURA', value: '145|PANADURA' },
    { label: '146|PUTTLAM', value: '146|PUTTLAM' },
    { label: '147|TRINCOMALEE', value: '147|TRINCOMALEE' },
    { label: '148|VAVUNIYA', value: '148|VAVUNIYA' },
    { label: '149|WENNAPPUWA', value: '149|WENNAPPUWA' },
    { label: '150|WATTALA', value: '150|WATTALA' },
    { label: '151|HEADOFFICE', value: '151|HEAD OFFICE' },
  ],
  'HNB FINANCE PLC': [
    { label: '001|CITY', value: '001|CITY' },
    { label: '002|MORATUWA', value: '002|MORATUWA' },
    { label: '003|JAFFNA', value: '003|JAFFNA' },
    { label: '004|TRINCOMALEE', value: '004|TRINCOMALEE' },
    { label: '005|BANDARAGAMA', value: '005|BANDARAGAMA' },
    { label: '006|VAVUNIYA', value: '006|VAVUNIYA' },
    { label: '007|AMPARA', value: '007|AMPARA' },
    { label: '008|KURUNEGALA', value: '008|KURUNEGALA' },
    { label: '009|WATTALA', value: '009|WATTALA' },
    { label: '010|KANDY', value: '010|KANDY' },
    { label: '011|ANURADHAPURA', value: '011|ANURADHAPURA' },
    { label: '012|NUWARA ELIYA', value: '012|NUWARA ELIYA' },
    { label: '013|MATALE', value: '013|MATALE' },
    { label: '014|BADULLA', value: '014|BADULLA' },
    { label: '015|CHILAW', value: '015|CHILAW' },
    { label: '016|PUTTALAM', value: '016|PUTTALAM' },
    { label: '017|KEGALLE', value: '017|KEGALLE' },
    { label: '018|KALUTARA', value: '018|KALUTARA' },
    { label: '019|BATTICOLOA', value: '019|BATTICOLOA' },
    { label: '020|JA-ELA', value: '020|JA-ELA' },
    { label: '021|MATARA', value: '021|MATARA' },
    { label: '022|WELIMADA', value: '022|WELIMADA' },
    { label: '023|DAMBULLA', value: '023|DAMBULLA' },
    { label: '024|KANTALE', value: '024|KANTALE' },
    { label: '025|AMBALANGODA', value: '025|AMBALANGODA' },
    { label: '026|HOMAGAMA', value: '026|HOMAGAMA' },
    { label: '027|NEGOMBO', value: '027|NEGOMBO' },
    { label: '028|BELIATTHA', value: '028|BELIATTHA' },
    { label: '029|EMBILIPITIYA', value: '029|EMBILIPITIYA' },
    { label: '030|POLONNARUWA', value: '030|POLONNARUWA' },
    { label: '031|MAHIYANGANAYA', value: '031|MAHIYANGANAYA' },
    { label: '032|MONERAGALA', value: '032|MONERAGALA' },
    { label: '033|HAMBANTOTA', value: '033|HAMBANTOTA' },
    { label: '034|GALLE', value: '034|GALLE' },
    { label: '035|MATHUGAMA', value: '035|MATHUGAMA' },
    { label: '036|MEDAWACHCHIYA', value: '036|MEDAWACHCHIYA' },
    { label: '037|HORANA', value: '037|HORANA' },
    { label: '038|RATHNAPURA', value: '038|RATHNAPURA' },
    { label: '039|NIKAWERATIYA', value: '039|NIKAWERATIYA' },
    { label: '040|GALGAMUWA', value: '040|GALGAMUWA' },
    { label: '041|NITTAMBUWA', value: '041|NITTAMBUWA' },
    { label: '042|GAMPAHA', value: '042|GAMPAHA' },
    { label: '043|NAWALAPITIYA', value: '043|NAWALAPITIYA' },
    { label: '044|WENNAPPUWA', value: '044|WENNAPPUWA' },
    { label: '045|AVISSAWELLA', value: '045|AVISSAWELLA' },
    { label: '046|KADUWELA', value: '046|KADUWELA' },
    { label: '047|MANNAR', value: '047|MANNAR' },
    { label: '048|PADAVIYA', value: '048|PADAVIYA' },
    { label: '049|BALANGODA', value: '049|BALANGODA' },
    { label: '050|KALMUNAI', value: '050|KALMUNAI' },
    { label: '051|NELLYADI', value: '051|NELLYADI' },
    { label: '052|MULLAITIVU', value: '052|MULLAITIVU' },
    { label: '053|POTHUVIL', value: '053|POTHUVIL' },
    { label: '054|MALLAVI', value: '054|MALLAVI' },
    { label: '055|NOROCHCHOLE', value: '055|NOROCHCHOLE' },
    { label: '056|MORAWAKA', value: '056|MORAWAKA' },
    { label: '057|KULIYAPITIYA', value: '057|KULIYAPITIYA' },
    { label: '058|NEDUNKERNI', value: '058|NEDUNKERNI' },
    { label: '059|NOCHCHIYAGAMA', value: '059|NOCHCHIYAGAMA' },
    { label: '060|KILINOCHCHIYA', value: '060|KILINOCHCHIYA' },
    { label: '061|KEKIRAWA', value: '061|KEKIRAWA' },
    { label: '062|GALENBIDUNUWEWA', value: '062|GALENBIDUNUWEWA' },
    { label: '063|RIKILLAGASKADA', value: '063|RIKILLAGASKADA' },
    { label: '064|BANDARAWELA', value: '064|BANDARAWELA' },
    { label: '065|WALAPANE', value: '065|WALAPANE' },
    { label: '066|THISSAMAHARAMAYA', value: '066|THISSAMAHARAMAYA' },
    { label: '067|HATTON', value: '067|HATTON' },
    { label: '068|PITIGALA', value: '068|PITIGALA' },
    { label: '069|DEHIATTAKANDIYA', value: '069|DEHIATTAKANDIYA' },
    { label: '999|HEAD OFFICE', value: '999|HEAD OFFICE' },
  ],
  'MERCANTILE INVESTMENT & FINANCE PLC': [{ label: '001|HEAD OFFICE', value: '001|HEAD OFFICE' }],
  "PEOPLE'S LEASING & FINANCE PLC": [
    { label: '001|HEAD OFFICE', value: '001|HEAD OFFICE' },
    { label: '002|AKURESSA', value: '002|AKURESSA' },
    { label: '003|AMBALANGODA', value: '003|AMBALANGODA' },
    { label: '004|AMBALANTOTA', value: '004|AMBALANTOTA' },
    { label: '005|AMPARA', value: '005|AMPARA' },
    { label: '006|ANURADHAPURA', value: '006|ANURADHAPURA' },
    { label: '007|AWISSAWELLA', value: '007|AWISSAWELLA' },
    { label: '008|BADULLA', value: '008|BADULLA' },
    { label: '009|BALANGODA', value: '009|BALANGODA' },
    { label: '010|BANDARAWELA', value: '010|BANDARAWELA' },
    { label: '011|BATTARAMULLA', value: '011|BATTARAMULLA' },
    { label: '012|BATTICALOA', value: '012|BATTICALOA' },
    { label: '013|BENTOTA', value: '013|BENTOTA' },
    { label: '014|CHILAW', value: '014|CHILAW' },
    { label: '015|CHUNNAKAM', value: '015|CHUNNAKAM' },
    { label: '016|COLLPITY', value: '016|COLLPITY' },
    { label: '017|DAMBULLA', value: '017|DAMBULLA' },
    { label: '018|DEHIWALA', value: '018|DEHIWALA' },
    { label: '019|DENIYAYA', value: '019|DENIYAYA' },
    { label: '020|DIGANA', value: '020|DIGANA' },
    { label: '021|ELPITIYA', value: '021|ELPITIYA' },
    { label: '022|EMBILIPITIYA', value: '022|EMBILIPITIYA' },
    { label: '023|GALLE', value: '023|GALLE' },
    { label: '024|GAMPAHA', value: '024|GAMPAHA' },
    { label: '025|GAMPOLA', value: '025|GAMPOLA' },
    { label: '026|GIRIULLA', value: '026|GIRIULLA' },
    { label: '027|GRANDPASS', value: '027|GRANDPASS' },
    { label: '028|HAMBANTHOTA', value: '028|HAMBANTHOTA' },
    { label: '029|HATTON', value: '029|HATTON' },
    { label: '030|HAVELOCK CITY', value: '030|HAVELOCK CITY' },
    { label: '031|HOMAGAMA', value: '031|HOMAGAMA' },
    { label: '032|HORANA', value: '032|HORANA' },
    { label: '033|JA-ELA', value: '033|JA-ELA' },
    { label: '034|JAFFNA', value: '034|JAFFNA' },
    { label: '035|KADAWATHA', value: '035|KADAWATHA' },
    { label: '036|KADUWELA', value: '036|KADUWELA' },
    { label: '037|KALAWANA', value: '037|KALAWANA' },
    { label: '038|KALMUNAI', value: '038|KALMUNAI' },
    { label: '039|KALUTHARA', value: '039|KALUTHARA' },
    { label: '040|KALAWANCHIKKUDY', value: '040|KALAWANCHIKKUDY' },
    { label: '041|KANDY', value: '041|KANDY' },
    { label: '042|KANDY ALSAFA', value: '042|KANDY ALSAFA' },
    { label: '043|KANTALE', value: '043|KANTALE' },
    { label: '044|KATTANKUDY', value: '044|KATTANKUDY' },
    { label: '045|KEGALLE', value: '045|KEGALLE' },
    { label: '046|KEKIRAWA', value: '046|KEKIRAWA' },
    { label: '047|KELANIYA', value: '047|KELANIYA' },
    { label: '048|KILINOCHCHI', value: '048|KILINOCHCHI' },
    { label: '049|KIRINDIWELA', value: '049|KIRINDIWELA' },
    { label: '050|KULIYAPITIYA', value: '050|KULIYAPITIYA' },
    { label: '051|KURUNEGALA', value: '051|KURUNEGALA' },
    { label: '052|MAHAIYAWA', value: '052|MAHAIYAWA' },
    { label: '053|MAHARAGAMA', value: '053|MAHARAGAMA' },
    { label: '054|MAHIYANGANAYA', value: '054|MAHIYANGANAYA' },
    { label: '055|MANNAR', value: '055|MANNAR' },
    { label: '056|MATALE', value: '056|MATALE' },
    { label: '057|MATARA', value: '057|MATARA' },
    { label: '058|MATHUGAMA', value: '058|MATHUGAMA' },
    { label: '059|MEDAWACHCHIYA', value: '059|MEDAWACHCHIYA' },
    { label: '060|MELSIRIPURA', value: '060|MELSIRIPURA' },
    { label: '061|METROPOLITON OFFICE', value: '061|METROPOLITON OFFICE' },
    { label: '062|MINUWANGODA', value: '062|MINUWANGODA' },
    { label: '063|MIRIGAMA', value: '063|MIRIGAMA' },
    { label: '064|MONARAGALA', value: '064|MONARAGALA' },
    { label: '065|MORATUWA', value: '065|MORATUWA' },
    { label: '066|MUTTUR (KINNIYA)', value: '066|MUTTUR (KINNIYA)' },
    { label: '067|NAWALAPITIYA', value: '067|NAWALAPITIYA' },
    { label: '068|NEGOMBO', value: '068|NEGOMBO' },
    { label: '069|NELUWA', value: '069|NELUWA' },
    { label: '070|NITTAMBUWA', value: '070|NITTAMBUWA' },
    { label: '071|NUGEGODA', value: '071|NUGEGODA' },
    { label: '072|NUWARA ELIYA', value: '072|NUWARA ELIYA' },
    { label: '073|PANADURA', value: '073|PANADURA' },
    { label: '074|PELMADULLA', value: '074|PELMADULLA' },
    { label: '075|PETTAH', value: '075|PETTAH' },
    { label: '076|PILIMATHALAWA', value: '076|PILIMATHALAWA' },
    { label: '077|PILIYANDALA', value: '077|PILIYANDALA' },
    { label: '078|POLONNARUWA', value: '078|POLONNARUWA' },
    { label: '079|PUTTALAM', value: '079|PUTTALAM' },
    { label: '080|RATHNAPURA', value: '080|RATHNAPURA' },
    { label: '081|TANGALLE', value: '081|TANGALLE' },
    { label: '082|THAMBUTTEGAMA', value: '082|THAMBUTTEGAMA' },
    { label: '083|TISSAMAHARAMA', value: '083|TISSAMAHARAMA' },
    { label: '084|TRINCOMALEE', value: '084|TRINCOMALEE' },
    { label: '085|UNION PLACE-ALSAFA', value: '085|UNION PLACE-ALSAFA' },
    { label: '086|VAVUNIYA', value: '086|VAVUNIYA' },
    { label: '087|WARD PLACE', value: '087|WARD PLACE' },
    { label: '088|WARIYAPOLA', value: '088|WARIYAPOLA' },
    { label: '089|WATTALA', value: '089|WATTALA' },
    { label: '090|WELIMADA', value: '090|WELIMADA' },
    { label: '091|WELLAWATTA', value: '091|WELLAWATTA' },
    { label: '092|WELLAWAYA', value: '092|WELLAWAYA' },
    { label: '093|WENNAPPUWA', value: '093|WENNAPPUWA' },
  ],
  'BIMPUTH FINANCE PLC': [{ label: '001|HEAD OFFICE', value: '001|HEAD OFFICE' }],
  'LOLC DEVELOPMENT FINANCE PLC': [{ label: '001|HEAD OFFICE', value: '001|HEAD OFFICE' }],
  'DIALOG FINANCE PLC': [{ label: '001|HEAD OFFICE', value: '001|HEAD OFFICE' }],
  'SINGER FINANCE (LANKA) PLC': [{ label: '001|HEAD OFFICE', value: '001|HEAD OFFICE' }],
  'CBC FINANCE LIMITED': [{ label: '001|HEAD OFFICE', value: '001|HEAD OFFICE' }],
  'ASIA ASSET FINANCE PLC': [{ label: '001|HEAD OFFICE', value: '001|HEAD OFFICE' }],
  'RICHARD PIERIS FINANCE LIMITED': [{ label: '001|HEAD OFFICE', value: '001|HEAD OFFICE' }],
  'SARVODHAYA DEVELOPMENT FINANCE LIMITED': [
    { label: '001|HEAD OFFICE', value: '001|HEAD OFFICE' },
  ],
  'FINTREX FINANCE LIMITED': [{ label: '001|HEAD OFFICE', value: '001|HEAD OFFICE' }],
};
