import { removeCookies, setAccessToken, setRefreshToken, setUserID } from 'core/services';
import { WpKeys } from '../core/models';

export const impersonateAuth = () => {
  const params = new URL(window.location.href).searchParams;
  const authParams = JSON.parse(params.get('impersonate') || '{}');
  if (authParams.user) {
    removeCookies(WpKeys.LoanPromoCode);
    setAccessToken(authParams.token);
    setRefreshToken(authParams.refresh_token);
    setUserID(authParams.user.id);

    location.href = location.href.split('?')[0];
  }
};
