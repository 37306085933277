import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { getUserID, removeCookie, sendDataLayer, sendDataLayerError } from 'core/services';
import { SubmitCoreHandler } from 'core/models';
import { useTypedSelector } from 'core/hooks';
import { setRedirect } from 'core/store/actions';
import { useAppDispatch, useUploadFiles } from 'hooks';
import { loadedIDFrontSelector } from 'store/selectors';
import { DocumentsType } from 'models';
import { eventVerifiedNICFront, getFileByTypeAction } from 'store/actions/fileUploadActions';
import { uploadFileByType } from 'store/actions/api/fileUploadApi';
import { getUserInfo } from 'store/actions/api';
import { IUploadPhotoComponentFormProps, UploadPhotoComponent } from 'components/DocumentUploader';
import { Title } from 'components';
import { DOCUMENTS, getRegStepPath, KEY_CAMERA_REQUEST } from 'constantsLk';
import { GTM_LABEL_SIGN_UP } from 'utils/analitics/gtmLabel';

export const Step8_2UploadFrontIdContainer = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const loadedIDFront = useTypedSelector(loadedIDFrontSelector);

  const { handleSubmit, control, setError, setValue } = useForm<IUploadPhotoComponentFormProps>({
    mode: 'onSubmit',
    defaultValues: {
      [DocumentsType.IDCard]: false,
    },

    shouldUnregister: true,
  });

  useEffect(() => {
    removeCookie(KEY_CAMERA_REQUEST);
  }, []);

  const { changeTypeFile, sendFile } = useUploadFiles({
    addFileAPI: uploadFileByType,

    getFileAPI: getFileByTypeAction,
  });

  const onSubmit: SubmitCoreHandler<IUploadPhotoComponentFormProps> = async (
    values,
    { setError },
  ) => {
    return dispatch(eventVerifiedNICFront(3000))
      .unwrap()
      .then(() => {
        return dispatch(getUserInfo())
          .unwrap()
          .then((res) => {
            sendDataLayer('upload_docs_id_front', GTM_LABEL_SIGN_UP);

            dispatch(setRedirect({ to: getRegStepPath(res.registration_step), replace: true }));
          });
      })
      .catch(() => {
        const UID = getUserID();
        sendDataLayerError(
          GTM_LABEL_SIGN_UP,
          UID,
          'upload_docs_id',
          DocumentsType.IDCard,
          'NIC validating error. Please repeat.',
        );
        setError &&
          setError(DocumentsType.IDCard, { type: 'validate', message: t('error_validate_nic') });
      });
  };

  const onSubmitHandler = handleSubmit((data) => {
    return onSubmit?.(data, {
      setError,
    });
  });

  return (
    <>
      <div className='flex-column gap-2 mb-5'>
        <Title classes={'form-title mb-0'} name={DOCUMENTS.FRONT.title} />
        <p className='txt-r'>{t(DOCUMENTS.FRONT.subtitle)}</p>
      </div>
      <div className='form'>
        <UploadPhotoComponent
          setValue={setValue}
          control={control}
          loadedFileType={loadedIDFront}
          changeTypeFile={changeTypeFile}
          sendFile={sendFile}
          document={DOCUMENTS.FRONT}
          onSubmitHandler={onSubmitHandler}
        />
      </div>
    </>
  );
};
