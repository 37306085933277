import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AppNavLinkContainer } from 'core/components/common';
import { useTypedSelector } from 'core/hooks';
import { EditIcon, UpdateAccountNumberModal } from 'components';
import { logOutUtil } from 'utils';
import { userBankDetailsSelector, userShortLinkAuthSelector } from 'store/selectors';
import * as Paths from 'constantsLk/paths';

export const BankDetails = () => {
  const { t } = useTranslation();

  const userBankDetails = useTypedSelector(userBankDetailsSelector);
  const isShortLinkAuth = useTypedSelector(userShortLinkAuthSelector);

  const [isChangeAccNumberModalOpen, setIsChangeAccNumberModalOpen] = useState(false);

  const handleChangeAccountNumberModalOpen = () => {
    if (isShortLinkAuth) {
      logOutUtil();
      window.location.reload();
    } else {
      setIsChangeAccNumberModalOpen(true);
    }
  };
  const handleChangeAccountNumberModalClose = () => setIsChangeAccNumberModalOpen(false);

  return (
    <>
      <div className='account-number justify-content-between align-items-start'>
        <div className='flex-column gap-1'>
          <div className='flex-column'>
            <p className='txt-xs txt-sb account-number__title'>{t`transfer_account_number`}</p>
            <p className='txt-xs txt-sb txt-primary'>{userBankDetails?.bank_account_number}</p>
          </div>
          <div className='flex'>
            <p className='txt-xs txt-sb account-number__title mr-1'>{t`bank_name`}:</p>
            <p className='txt-xs'>{userBankDetails?.bank_name}</p>
          </div>
          <div className='flex'>
            <p className='txt-xs txt-sb account-number__title mr-1'>{t`bank_branch_name`}:</p>
            <p className='txt-xs'>{userBankDetails?.bank_branch_name}</p>
          </div>
        </div>
        {isShortLinkAuth ? (
          <div className='flex'>
            <AppNavLinkContainer
              className='flex align-items-center btn-tertiary dark gap-1 flex-nowrap border-n'
              to={`/${Paths.URL_PATH_LOGIN}`}
            >
              <EditIcon />
              <span className='txt-nowrap txt-xs txt-sb'>{t`change`}</span>
            </AppNavLinkContainer>
          </div>
        ) : (
          <button
            className='flex align-items-center btn-tertiary dark gap-1 flex-nowrap'
            type='button'
            onClick={handleChangeAccountNumberModalOpen}
          >
            <EditIcon />
            <span className='txt-nowrap txt-xs txt-sb'>{t`change`}</span>
          </button>
        )}
      </div>

      {isChangeAccNumberModalOpen && !isShortLinkAuth && (
        <UpdateAccountNumberModal onClose={handleChangeAccountNumberModalClose} />
      )}
    </>
  );
};
