import { FC, useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { LangOption, PlacementLangLocationType, SetLanguagePath } from 'core/models';
import { SINHALESE_LANGUAGE } from 'constantsLk';

export interface LanguageSwitcherProps {
  languageOptions: Array<Omit<LangOption, 'isActive'>>;
  excludeLanguage?: string;
  isUseLocationLanguage?: boolean;
  placementLangLocation?: PlacementLangLocationType;
  setLanguagePath: SetLanguagePath;
  LanguageSwitcherComponent?: FC<{
    options: Array<LangOption>;
    changeLanguage(lang: string): () => void;
  }>;
}

export const LanguageSwitcher = (props: LanguageSwitcherProps) => {
  const {
    languageOptions,
    excludeLanguage,
    isUseLocationLanguage = false,
    setLanguagePath,
    LanguageSwitcherComponent,
    placementLangLocation = PlacementLangLocationType.Start,
  } = props;

  const { i18n } = useTranslation();
  const navigate = useNavigate();
  const { pathname, state, search } = useLocation();
  const { lang: langParam } = useParams<{ lang: string }>();

  const [options, setOptions] = useState<Array<LangOption>>([]);

  useEffect(() => {
    i18n.changeLanguage(langParam ? langParam : SINHALESE_LANGUAGE.value).then();
  }, []);

  useEffect(() => {
    isUseLocationLanguage && changeLocation(i18n.language);
    setOptions(
      languageOptions.map((option) => ({ ...option, isActive: i18n.language === option.value })),
    );
  }, []);

  const changeLanguage = (lng: string) => () => {
    i18n.changeLanguage(lng).then(() => {
      const options = languageOptions.map((o) => ({ ...o, isActive: o.value === lng }));
      setOptions(options);

      isUseLocationLanguage && changeLocation(lng);
    });
  };

  const getPathLang = (newLangPath: string, emptyPath: string) => {
    const path = emptyPath === '/' ? '' : emptyPath;

    switch (placementLangLocation) {
      case PlacementLangLocationType.Start:
        return `${newLangPath}${path}`;
      case PlacementLangLocationType.End:
        return `${path}${newLangPath}`;
      default:
        return `${newLangPath}${path}`;
    }
  };

  const changeLocation = (language: string) => {
    const newLangPath = excludeLanguage?.includes(language) ? '' : `/${language}`;
    const emptyPath = pathname.replace(langParam ? `/${langParam}` : '', '');
    const path = getPathLang(newLangPath, emptyPath);
    navigate(`${path}${search}`, { state });
    setLanguagePath(newLangPath);
  };

  return LanguageSwitcherComponent ? (
    <LanguageSwitcherComponent options={options} changeLanguage={changeLanguage} />
  ) : (
    <div className='lang-switcher'>
      {options.map((item, key) => (
        <span
          key={key}
          className={`txt-uppercase cursor-pointer ${
            item.isActive ? 'txt-black' : 'txt-secondary'
          }`}
          onClick={changeLanguage(item.value)}
        >
          {item.label}
        </span>
      ))}
    </div>
  );
};
