import { PropsWithChildren } from 'react';
import { Navigate } from 'react-router-dom';

import * as Paths from 'constantsLk/paths';

export interface UserGuardProps {
  isAuthorized?: boolean;
  redirectTo?: string;
}

export const UserGuard = (props: PropsWithChildren<UserGuardProps>) => {
  const { isAuthorized, redirectTo = `/${Paths.URL_PATH_LOGIN}`, children } = props;

  return <>{isAuthorized ? children : <Navigate to={redirectTo} />}</>;
};
