import { useTranslation } from 'react-i18next';

import { Button, ModalLayout } from 'core/components/common';
import { useTypedSelector } from 'core/hooks';

import { wpContentHeaderSelector } from 'store/selectors';
import { LockIcon } from 'components/icons';
import { Dropdown } from 'components';

interface InfoDialogProps {
  title?: any;
  content?: any;
  onClose?: () => void;
}

export const CameraAccessDeniedFAQModal = (props: InfoDialogProps) => {
  const { onClose, content } = props;
  const { t } = useTranslation();

  const wpContentHeader = useTypedSelector(wpContentHeaderSelector);

  return (
    <ModalLayout
      onClose={onClose}
      // isOpenModal={!!location.state?.isOpenModal}
      isOpenModal
      title={t`what_if_i_denied_access`}
      isShowClose
      isUsePortal
      isOutSideClick
      classes={{ content: 'gap-5 flex-column' }}
      content={
        <>
          <p className='txt'>{t`how_do_you_use_our_site`}</p>
          <div className='hidden-desktop'>
            <div className='flex-column gap-3'>
              <Dropdown trigger={content.trigger} menu={content.menuItem} icon={content.icon} />
            </div>
          </div>
          <div className='hidden-mobile'>
            <div className='flex-column gap-3'>
              <p>1. {t`go_to_browser_settings`}</p>
              <p>2. {t`select_privacy_and_security_section`}</p>
              <p>3. {t`find_the_name_of_our_site`}</p>
              <Button
                classes={{ root: 'btn--primary mt-2' }}
                onClick={onClose}
              >{t`it_is_clear`}</Button>
            </div>
          </div>
          <div className='flex-center gap-2 txt-sb txt-info'>
            <LockIcon />
            <p>{t`dont_worry_your_data_is_secure`}</p>
          </div>
        </>
      }
      footer={
        <div className='border-top-light pt-3'>
          <div className='txt-xs txt-secondary'>
            {t`if_you_havenot_given_access`}
            &nbsp;
            <a
              className='link txt-xs txt-secondary txt-ellipsis'
              href={`tel:${wpContentHeader?.contacts.phone}`}
            >
              {wpContentHeader?.contacts.phone}
            </a>
          </div>
        </div>
      }
    />
  );
};
