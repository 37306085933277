import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { addDays } from 'date-fns';

import { formatNumber } from 'core/utils/parsers';

import { ILoanSettings } from 'utils';
import { formatDate } from 'utils/parsers';
import { TooltipItem } from 'components/Tooltip';
import { EditIcon, TooltipInfoIcon2 } from 'components/icons';
import { CURRENCY, TODAY } from 'constantsLk';

interface LoanConditionsValuesProps {
  loanSettings: ILoanSettings | { term: string; amount: string };
  returnAmount: number | undefined;
  hasControl?: boolean;
  onActivate?: () => void;
}

export const LoanConditionsValues = ({
  loanSettings,
  returnAmount,
  hasControl = false,
  onActivate,
}: LoanConditionsValuesProps) => {
  const { t, i18n } = useTranslation();
  const lang = i18n.language;

  const loanValues = useMemo(
    () => [
      {
        label: 'loan_amount',
        value: `${CURRENCY} ${formatNumber(loanSettings?.amount)}`,
      },
      {
        label: 'payment_date',
        value: formatDate(addDays(TODAY, Number(loanSettings?.term))),
      },
    ],
    [loanSettings, returnAmount, lang],
  );

  return (
    <div className='flex-grow-0 w-100 mb-3 tablet-md-6'>
      {loanValues.map((item: { label: string; value: string }) => (
        <div
          key={item.label}
          className={`justify-content-between align-items-center ${item.label === 'loan_amount' && 'mb-3'}`}
        >
          <div className='flex-nowrap'>
            <span className='txt-sb txt-xs'>{t(item.label)}</span>
            {item.label === 'payment_date' && (
              <TooltipItem
                wrapperClassName={'payment_date-tooltip'}
                title={t(item.label)}
                content={t`payment_date_tooltip`}
                icon={<TooltipInfoIcon2 />}
              />
            )}
          </div>
          <div className='info-item'>{item.value}</div>
        </div>
      ))}
      {hasControl && (
        <span className='cursor-pointer flex-center' onClick={onActivate}>
          <EditIcon />
          <span className='hidden-mobile txt txt-base txt-underline ml-1'>{t`change`}</span>
        </span>
      )}
    </div>
  );
};
