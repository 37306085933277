import { useTranslation } from 'react-i18next';
import { Loan, PromotionModel } from 'models';
import { ProlongationPromoBg, PromotionTimerIcon } from 'components';
import { Prolongation } from './Prolongation';
import { Timer } from './Timer';
import { usePromotion } from 'hooks';

interface ProlongationPromotionProps {
  activePromo: PromotionModel;
  activeCreditData: Loan;
  isCollapsible: boolean | null;
  icon: JSX.Element;
}
export const ProlongationPromotion = (props: ProlongationPromotionProps) => {
  const { activePromo, activeCreditData, isCollapsible, icon } = props;

  const { t } = useTranslation();

  const { promotionTranslate, timeLeft, promoStatus, isBlinking, formattedDate } = usePromotion(
    activePromo.available_to,
    activePromo,
  );

  return (
    <div className='card flex-column tablet-mb-6 mb-4 promotion'>
      <div className='promotion-label'>{t('promo')}</div>
      <div className='promotion-title promotion-title-bg flex align-items-center w-100 gap-1'>
        {icon}
        <h2 className='txt-md txt-b'>{promotionTranslate.header}</h2>
        <span>
          <ProlongationPromoBg />
        </span>
      </div>

      {activePromo.available_to && (
        <div
          className={`flex-center flex-column pill-${promoStatus} mb-4 pt-2 pb-2 ${isBlinking ? 'blinking' : ''}`}
        >
          <span className='flex-center txt-md-14 txt-sb gap-2'>
            <PromotionTimerIcon /> {t`offer_is_available`}
          </span>
          <Timer timeLeft={timeLeft} />
        </div>
      )}

      <Prolongation
        activeCreditData={activeCreditData}
        isCollapsible={isCollapsible}
        activePromo={activePromo}
        formattedDate={formattedDate}
        description={promotionTranslate.description}
      />
    </div>
  );
};
