import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { Button } from 'core/components/common';
import { SelectFieldHookForm, TextFieldHookForm } from 'core/components/fields';
import { maxLength } from 'core/utils/parsers';
import { SubmitCoreHandler } from 'core/models';

import { AddressFormFields, AddressFormModel } from 'models';
import { useHookFormGetCache, useHookFormSetCache, useScrollToFieldError } from 'hooks';
import { validateSelect, validateText, validateTextMinLength } from 'utils/validates/validation';
import { notCyrillic, onlyEnSiTaSymbols } from 'utils/parsers';
import { onSubmitEventTagHandler } from 'utils/analitics';
import { GTM_LABEL_SIGN_UP } from 'utils/analitics/gtmLabel';
import { sortOptions } from 'utils/sortOptions';
import { Title } from 'components';
import * as Paths from 'constantsLk/paths';
import { SELECT_OPTIONS } from 'constantsLk';

export interface Step4AddressProps {
  onSubmit: SubmitCoreHandler<AddressFormModel>;
}

export const Step4Address = (props: Step4AddressProps) => {
  const { onSubmit } = props;

  const { t, i18n } = useTranslation();
  const language = i18n.language;
  const defaultValues = useHookFormGetCache(Paths.PATH_REG_STEP_4);

  const {
    handleSubmit,
    watch,
    control,
    setError,
    resetField,
    trigger,
    formState: { isSubmitting, submitCount, errors },
  } = useForm<AddressFormModel>({
    mode: 'onSubmit',
    defaultValues,
    shouldUnregister: true,
  });

  useHookFormSetCache(Paths.PATH_REG_STEP_4, watch);
  useScrollToFieldError({ errors, submitCount });

  const residenceProvince = watch(AddressFormFields.Province);

  const onSubmitHandler = handleSubmit(
    (data) =>
      onSubmit?.(data, {
        setError,
      }),
    (errors) => onSubmitEventTagHandler(errors, GTM_LABEL_SIGN_UP),
  );

  const onChangeProvinceHandler = () => {
    resetField(AddressFormFields.District);
  };

  const handleBlur = (name: any) => trigger(name);

  return (
    <>
      <Title classes={'form-title'} name={'residence_address'} />
      <form onSubmit={onSubmitHandler}>
        <SelectFieldHookForm
          control={control}
          label={t`residence_province`}
          name={AddressFormFields.Province}
          options={sortOptions(SELECT_OPTIONS.residence_province[language])}
          rules={validateSelect}
          onChange={onChangeProvinceHandler}
        />
        {residenceProvince && (
          <SelectFieldHookForm
            control={control}
            label={t`residence_district`}
            name={AddressFormFields.District}
            options={sortOptions(SELECT_OPTIONS.residence_district[language][residenceProvince])}
            rules={validateSelect}
          />
        )}
        <TextFieldHookForm
          control={control}
          name={AddressFormFields.Locality}
          label={t`residence_locality`}
          parsers={[onlyEnSiTaSymbols, notCyrillic, maxLength(60)]}
          rules={validateTextMinLength}
          onBlur={handleBlur}
        />
        <TextFieldHookForm
          control={control}
          name={AddressFormFields.Street}
          label={t`residence_street`}
          parsers={[notCyrillic, onlyEnSiTaSymbols, maxLength(60)]}
          rules={validateTextMinLength}
          onBlur={handleBlur}
        />
        <TextFieldHookForm
          control={control}
          name={AddressFormFields.House}
          label={t`residence_house`}
          parsers={[notCyrillic, onlyEnSiTaSymbols, maxLength(20)]}
          rules={validateText}
        />
        <TextFieldHookForm
          control={control}
          name={AddressFormFields.Flat}
          label={t`residence_flat`}
          parsers={[notCyrillic, onlyEnSiTaSymbols, maxLength(9)]}
          // rules={validateText}
        />
        <SelectFieldHookForm
          control={control}
          label={t`residence_duration`}
          name={AddressFormFields.Duration}
          options={SELECT_OPTIONS.residence_duration[language]}
          rules={validateSelect}
        />
        <Button
          disabled={isSubmitting}
          classes={{ root: 'btn--primary mt-1 next-step' }}
        >{t`continue`}</Button>
      </form>
    </>
  );
};
