import { useTranslation } from 'react-i18next';
import { useTypedSelector } from 'core/hooks';
import {
  CreditBureauReportContainer,
  EmailAlertWrapper,
  RequestStatus,
  SpinnerIcon,
} from 'components';
import { userSelector } from 'store/selectors';

export const ApplicationPendingContainer = () => {
  const { t } = useTranslation();
  const user = useTypedSelector(userSelector);

  return (
    <EmailAlertWrapper>
      <div className='flex-column gap-4 m-auto'>
        <RequestStatus
          icon={<SpinnerIcon />}
          title={'your_application_accepted'}
          text={t('your_application_being', { phone: '+94' + user?.phone_number })}
          footer={t`application_review_usually`}
        />
        <CreditBureauReportContainer />
      </div>
    </EmailAlertWrapper>
  );
};
