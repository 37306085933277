import { WpKeys } from 'core/models';
import { getCookie, removeCookies, setCookie } from 'core/services';
import { COUNT_DAYS_SAVE_COOKIE, DEFAULT_SUM_LOAN, DEFAULT_TERM_LOAN } from 'constantsLk';

export const LoanService = {
  get defaultTerms() {
    return DEFAULT_TERM_LOAN;
  },

  get defaultSum() {
    return DEFAULT_SUM_LOAN;
  },

  get sum(): number {
    return Number(getCookie(WpKeys.LoanSum)) || this.defaultSum;
  },

  get terms(): number {
    return Number(getCookie(WpKeys.LoanTerm)) || this.defaultTerms;
  },

  get promoCode(): string | undefined {
    return getCookie(WpKeys.LoanPromoCode);
  },

  set setSum(sum: null | undefined | number) {
    if (sum) {
      setCookie(WpKeys.LoanSum, String(sum), { expires: COUNT_DAYS_SAVE_COOKIE });
    } else {
      removeCookies(WpKeys.LoanSum);
    }
  },

  set setTerm(term: null | undefined | number) {
    if (term) {
      setCookie(WpKeys.LoanTerm, String(term), { expires: COUNT_DAYS_SAVE_COOKIE });
    } else {
      removeCookies(WpKeys.LoanTerm);
    }
  },

  set promoCode(value: string | null | undefined) {
    if (value) {
      setCookie(WpKeys.LoanPromoCode, value, { expires: 1 });
    } else {
      removeCookies(WpKeys.LoanPromoCode);
    }
  },
};
