import { useTranslation } from 'react-i18next';
import { useSetNotice } from 'core/hooks';
import { NoticeType, NoticeTypes } from 'core/models';
import { CopyIcon, ShareViaEmailIcon, ShareViaSmsIcon } from '../icons';

interface SharingLinkProps {
  link: string;
  handleOpenModal: (val: 'sms' | 'email') => void;
}

export const SharingLink = (props: SharingLinkProps) => {
  const { link, handleOpenModal } = props;

  const { t } = useTranslation();
  const setSnack = useSetNotice(NoticeTypes.Snacks);

  const handleSetSnack = () => {
    setSnack(NoticeType.Success, t`link_is_copied`);
  };

  const handleCopy = () => {
    navigator.clipboard.writeText(link).then(() => handleSetSnack());
  };

  return (
    <div className='flex-column gap-3 mt-6'>
      <p className='txt-md-16 txt-sb'>{t`copy_the_link`}</p>

      <div className='copy flex-nowrap align-items-center justify-content-between'>
        <p className='txt-ellipsis'>{link}</p>
        <div className='flex-nowrap copy-link align-items-center cursor-pointer'>
          <span className='flex-center'>
            <CopyIcon />
          </span>
          <span className='txt txt-sb txt-primary ml-1' onClick={handleCopy}>{t`copy`}</span>
        </div>
      </div>

      <div className='flex align-items-center'>
        <span className='txt txt-sb mr-4'>{t`share_via`}</span>
        <div className='flex align-items-center gap-1'>
          <div
            className='flex-center btn-tertiary cursor-pointer'
            onClick={() => handleOpenModal('sms')}
          >
            <ShareViaSmsIcon />
            <span className='txt txt-sb txt-primary ml-1'>SMS</span>
          </div>
          <div
            className='flex-center btn-tertiary cursor-pointer'
            onClick={() => handleOpenModal('email')}
          >
            <ShareViaEmailIcon />
            <span className='txt txt-sb txt-primary ml-1'>{t`share_via_email`}</span>
          </div>
        </div>
      </div>
    </div>
  );
};
