import { useTranslation } from 'react-i18next';
import { useSetNotice, useTypedSelector } from 'core/hooks';
import { NoticeType, NoticeTypes, SubmitCoreHandler } from 'core/models';
import { RepaymentSlipModal } from './RepaymentSlipModal';
import { activeLoanSelector, shortLinkLoanSelector } from 'store/selectors';
import { useAppDispatch } from 'hooks';
import { sendRepaymentSlip } from 'store/actions/api';
import { PaymentSlipModel } from 'models';

interface RepaymentSlipModalContainerProps {
  onClose: () => void;
  isOpenModal: boolean;
}

export const RepaymentSlipModalContainer = (props: RepaymentSlipModalContainerProps) => {
  const { onClose, isOpenModal } = props;
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const activeLoan = useTypedSelector(activeLoanSelector);
  const shortLinkLoan = useTypedSelector(shortLinkLoanSelector);
  const setSnack = useSetNotice(NoticeTypes.Snacks);

  const handleSetSnack = () => {
    setSnack(NoticeType.Success, t`slip_successfully_sent`);
  };

  const onSubmit: SubmitCoreHandler<PaymentSlipModel> = async (values, { setError }) => {
    if (values.file && values.bank_id) {
      const data = {
        paymentData: {
          payment_slip_photo: values.file,
          bank_id: values.bank_id,
        },
        loanId: activeLoan?.id || activeLoan?.loan_id || shortLinkLoan?.loan_id,
      };

      return dispatch(sendRepaymentSlip(data))
        .unwrap()
        .then(() => {
          onClose();
          handleSetSnack();
        })
        .catch((e) => {
          setError &&
            setError('root.slipError', {
              message: e?.detail,
            });
        });
    }
  };

  return (
    <RepaymentSlipModal
      onClose={onClose}
      isOpenModal={isOpenModal}
      onSubmit={onSubmit}
      loanId={activeLoan?.id || activeLoan?.loan_id || shortLinkLoan?.loan_id}
    />
  );
};
