import { removeCookie, setCookie } from 'core/services';
import { SubmitCoreHandler } from 'core/models';
import { AddEmailModal } from './AddEmailModal';
import { useAppDispatch } from 'hooks';
import { EmailFormModel, IbanNumberFields } from 'models';
import { clearEmptyFormValues, ServerFieldErrorsHandler } from 'utils';
import { sendVerificationEmail, updateUser } from 'store/actions/api';
import { KEY_CLOSE_EMAIL_MODAL } from 'constantsLk';

interface AddEmailModalContainerProps {
  isFirstLoan: boolean;
  handleCloseEmailModal: () => void;
  handleClearEmailError: () => void;
  handleOpenVerificationEmailModal: () => void;
}

export const AddEmailModalContainer = (props: AddEmailModalContainerProps) => {
  const {
    isFirstLoan,
    handleCloseEmailModal,
    handleClearEmailError,
    handleOpenVerificationEmailModal,
  } = props;

  const dispatch = useAppDispatch();

  const handleClose = () => {
    !isFirstLoan && setCookie(KEY_CLOSE_EMAIL_MODAL, '1');
    handleCloseEmailModal();
  };

  const onSubmit: SubmitCoreHandler<EmailFormModel> = async (values, { setError }) => {
    values = clearEmptyFormValues<EmailFormModel>(values);

    if (values[IbanNumberFields.Email]?.trim()) {
      values = {
        ...values,
        email:
          values[IbanNumberFields.EmailDomain] === 'other'
            ? values[IbanNumberFields.Email]
                ?.replaceAll(' ', '')
                .toLowerCase()
                .replace(/^www\./, '')
            : values[IbanNumberFields.Email]
                ?.replaceAll(' ', '')
                .toLowerCase()
                .replace(/^www\./, '') + values[IbanNumberFields.EmailDomain],
      };
    }

    delete values[IbanNumberFields.EmailDomain];

    return dispatch(updateUser(values))
      .unwrap()
      .then(() => {
        handleCloseEmailModal();
        handleClearEmailError();
        removeCookie(KEY_CLOSE_EMAIL_MODAL);
        return dispatch(sendVerificationEmail())
          .unwrap()
          .then(() => handleOpenVerificationEmailModal());
      })
      .catch((err) => {
        ServerFieldErrorsHandler(err, setError);
      });
  };

  return <AddEmailModal isOpenModal={true} handleClose={handleClose} onSubmit={onSubmit} />;
};
