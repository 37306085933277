import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { SubmitCoreHandler } from 'core/models';
import { Button, ModalLayout } from 'core/components/common';
import { validatePhone } from 'utils/validates/validation';
import { AuthFormFields, AuthFormModel } from '../../AuthForm';
import { PhoneFieldFocusValidation } from '../../fields';
import { COUNTRY_PHONE_CODE } from 'constantsLk';

interface ShareViaSmsModalProps {
  isOpenModal: boolean;
  handleClose: () => void;
  onSubmit: SubmitCoreHandler<AuthFormModel>;
}

export const ShareViaSmsModal = (props: ShareViaSmsModalProps) => {
  const { handleClose, isOpenModal, onSubmit } = props;

  const { t } = useTranslation();

  const {
    handleSubmit,
    control,
    setError,
    formState: { isSubmitting },
    setValue,
    clearErrors,
    trigger,
  } = useForm<AuthFormModel>({
    mode: 'onSubmit',
  });

  const handlePhoneChange = (name: any, value: string | number) => {
    if (value && value.toString()[0] !== '7') {
      trigger(name).then(() => setValue(name, ''));
    } else {
      clearErrors(name);
    }
  };

  const onSubmitHandler = handleSubmit((data) =>
    onSubmit?.(data, {
      setError,
    }),
  );

  return (
    <ModalLayout
      isOpenModal={isOpenModal}
      title={t`add_friend_phone`}
      onClose={handleClose}
      classes={{ wrapper: 'email_modal' }}
      isShowClose
      isUsePortal
      content={
        <div className='flex-column gap-5'>
          <div className='txt'>{t`add_friend_phone_subtitle`}</div>

          <form onSubmit={onSubmitHandler}>
            <PhoneFieldFocusValidation
              leftContent={COUNTRY_PHONE_CODE}
              label={t(AuthFormFields.Phone)}
              control={control}
              inputMode='numeric'
              name={AuthFormFields.Phone}
              rules={validatePhone}
              onChange={handlePhoneChange}
            />

            <Button classes={{ root: 'btn--primary w-100 mt-5' }} disabled={isSubmitting}>
              {t`send_an_invite`}
            </Button>
          </form>
        </div>
      }
    />
  );
};
