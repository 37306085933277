import { useTranslation } from 'react-i18next';
import { CheckIcon } from 'components';

const PROGRESS_STEPS: { [key: number | string]: { width: number; label: number } } = {
  1: { width: 0, label: 0 },
  2: { width: 22, label: 15 },
  3: { width: 22, label: 30 },
  4: { width: 35, label: 40 },
  5: { width: 50, label: 50 },
  6: { width: 66, label: 60 },
  7: { width: 76, label: 70 },
  8.1: { width: 80, label: 80 },
  8.2: { width: 85, label: 85 },
  8.3: { width: 90, label: 90 },
  9: { width: 100, label: 98 },
};

export const RegistrationLayoutProgressBar = (props: any) => {
  const { step } = props;
  const { t } = useTranslation();

  return (
    <div className='flex-column gap-1 mb-6'>
      <div className='progress-bar__wrap mb-1'>
        <span className={`progress-bar__control ${step >= 2 ? 'active' : ''}`}>
          <CheckIcon />
        </span>
        <span className={`progress-bar__control ${step >= 5 ? 'active' : ''}`}>
          <CheckIcon />
        </span>
        <span className={`progress-bar__control ${step > 8 ? 'active' : ''}`}>
          <CheckIcon />
        </span>
        <div className='progress-bar'>
          <div
            className='progress-bar__track'
            style={{ width: `${PROGRESS_STEPS[step].width}%` }}
          />
        </div>
      </div>
      <span className='txt-base txt-success'>
        {t`probability_of_loan_approval`}: {PROGRESS_STEPS[step].label}%
      </span>
    </div>
  );
};
