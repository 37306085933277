import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import { differenceInDays, format } from 'date-fns';
import { Loan } from 'models';
import { LOAN_STATE } from 'constantsLk';

interface LoanCardProps {
  loan: Loan;
  onGetAgrees?: any;
  canDownloadDocument: boolean;
  canDownloadAdditionalDocument: boolean;
}

export const LoanCard = (props: LoanCardProps) => {
  const { loan, onGetAgrees, canDownloadDocument, canDownloadAdditionalDocument } = props;
  const { t } = useTranslation();

  const disbursmentDate = format(new Date(loan.disbursed_at), 'dd.MM.yyyy');

  const [isCardCollapsed, setIsCardCollapsed] = useState(true);

  const handleCardExpand = () => setIsCardCollapsed(!isCardCollapsed);

  const loanStateLbl =
    loan.state === LOAN_STATE.CLOSED
      ? 'paid'
      : loan.state === LOAN_STATE.DEFAULTED
        ? 'overdue'
        : loan.state;

  return (
    <div className={`loan-history-card ${!isCardCollapsed ? 'show' : ''}`}>
      <div className='align-items-center justify-content-between'>
        <h6 className='txt-b'>
          {t`loan`} №{loan.id}
        </h6>
        <div className={`txt-capitalize pill-loan-${loan.state}`}>{t(loanStateLbl)}</div>
      </div>
      {loan.disbursed_at && (
        <p className={`txt-r txt-xs txt-secondary ${isCardCollapsed ? '' : 'mb-2'}`}>{`${t(
          'disbursement_date',
        )} ${disbursmentDate}`}</p>
      )}
      <div className={`loan-history-card-body ${isCardCollapsed ? 'collapsed' : ''}`}>
        <div className='align-items-center justify-content-between'>
          <p className='txt-sb'>{t`contract_number`}</p>
          <p className='txt-secondary'>#{loan.id}</p>
        </div>
        <div className='align-items-center justify-content-between'>
          <p className='txt-sb'>{t`status`}</p>
          <p className='txt-primary txt-capitalize txt-sb'>{t(loanStateLbl)}</p>
        </div>
        <div className='align-items-center justify-content-between'>
          <p className='txt-sb'>{t`loan_sum`}</p>
          <p className='txt-secondary'>{loan.principal} RS</p>
        </div>
        <div className='align-items-center justify-content-between'>
          <p className='txt-sb'>{t`term`}</p>
          <p className='txt-secondary'>
            {loan.tenor} {t`days`}
          </p>
        </div>
        {loan.closed_at &&
          differenceInDays(new Date(loan.closed_at), new Date(loan.disbursed_at)) > 0 && (
            <div className='align-items-center justify-content-between'>
              <p className='txt-sb'>{t`actual_term`}</p>
              <p className='txt-secondary'>
                {differenceInDays(new Date(loan.closed_at), new Date(loan.disbursed_at))} {t`days`}
              </p>
            </div>
          )}
        {canDownloadDocument && (
          <div className='align-items-start justify-content-between'>
            <p className='txt-sb'>{t`documents`}</p>
            <div className='flex-column gap-2 justify-content-end'>
              <span
                className='txt-center link txt-r txt-secondary'
                onClick={() => onGetAgrees('is_loan_only=true')}
              >{t`loan_agreement`}</span>
              {canDownloadAdditionalDocument && (
                <span
                  className='txt-end link txt-r txt-secondary'
                  onClick={() => onGetAgrees('is_top_up_only=true')}
                >{t`addendum`}</span>
              )}
            </div>
          </div>
        )}
      </div>
      <span className={`link ${isCardCollapsed ? '' : 'mt-2'}`} onClick={handleCardExpand}>
        {t(isCardCollapsed ? 'see_more' : 'see_less')}
      </span>
    </div>
  );
};
